import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { PartnerInvitationFe } from '../../../model/data-suppliers/company/PartnerInvitationFe'
import COUNTRIES_DATA from '../../../model/COUNTRIES.json'
import INDUSTRIES_DATA from '../../../model/INDUSTRIES.json'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { PartnerInvitation_AddFe } from 'src/app/model/data-suppliers/company/PartnerInvitation_AddFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { InvitationLinkFe } from 'src/app/model/email/InvitationLinkFe'

import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { Subscription } from 'rxjs'
import { Language } from 'src/app/utils/language/Language'
import { Router } from '@angular/router'
import { RoutesFe } from 'src/app/route/RoutesFe'

@Component({
  selector: 'data-supplier-company-entry',
  templateUrl: './data-supplier-company-entry.component.html',
  styleUrls: ['./data-supplier-company-entry.component.scss', '../data-suppliers.component.scss']
})
export class DataSupplierCompanyEntryComponent extends AbstractLanguageComponent {
  countries = Object.entries(COUNTRIES_DATA)
  industries = INDUSTRIES_DATA
  industriesList: string[]
  form1: FormGroup
  form2 = new FormGroup({
    primaryContactFirstName: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationRegex.NonWhitespaceRegExp),
      Validators.minLength(2),
      Validators.maxLength(30)
    ]),
    primaryContactLastName: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationRegex.NonWhitespaceRegExp),
      Validators.minLength(2),
      Validators.maxLength(30)
    ]),
    primaryContactEmail: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)])
  })

  form3 = new FormGroup({
    emailSubject: new FormControl('', [Validators.required]),
    emailContent: new FormControl('', [Validators.required])
  })
  activeFormNo = 1
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  companyNameMessage = this.locale('locale_key.general.validation_message.company_name_required')
  companyCountry = this.locale('locale_key.general.validation_message.country_required')
  companyIndustryGroup = this.locale('locale_key.general.validation_message.industry_group_required')
  companyIndustry = this.locale('locale_key.general.validation_message.industry_required')
  companyWebAddress = this.locale('locale_key.general.validation_message.company_web_address_required')
  companyWebFromatMessage = this.locale('locale_key.general.validation_message.invalid_web_address')
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  firstNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_first_name')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  lastNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_last_name')
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  lowerCaseEmail = this.locale('locale_key.general.validation_message.invalid_email')
  industryGroupChangeVal: boolean = false
  industryChangeVal: boolean = false

  @Output() supplierInfo = new Subject<PartnerInvitationFe>()
  @Input() selectedInvitation: PartnerInvitationFe
  invitationLink: InvitationLinkFe
  invitationLinkGenerated = false
  newPartner: PartnerInvitation_AddFe
  isLoading = false
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  languages = this.languageService.availableLanguages
  selectedLang: Language
  private languageSubscription: Subscription
  activeLanguage: Language
  lang: string
  disableEnglish: boolean
  includeEnglish: boolean = false
  showNavigateModal = false

  modalHeaderText = this.locale('locale_key.pages.dashboard.email_template.message.title')
  modalBodyText = this.locale('locale_key.pages.dashboard.email_template.message.body')
  cancelButtonText = this.locale('locale_key.delete_confirmation_modal.button.cancel')
  actionButtonText = this.locale('locale_key.pages.dashboard.email_template.action.button')

  steps = [
    {
      title: this.locale('locale_key.pages.network.add_business_partner_wizard.company_information'),
      completed: false
    },
    { title: this.locale('locale_key.pages.network.add_business_partner_wizard.contact_person'), completed: false },
    { title: this.locale('locale_key.pages.network.add_business_partner_wizard.invitation_email'), completed: false }
  ]

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    private stateService: StateServiceFe,
    private loginService: LoginServiceFe,
    private routerFe: RouterFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private router: Router
  ) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    if (this.selectedInvitation) {
      this.form1.controls.name.patchValue(this.selectedInvitation.partnerName)
      this.form1.controls.country.patchValue(this.selectedInvitation.partnerCountryCode)
      this.form1.controls.industryGroup.patchValue(this.selectedInvitation.partnerIndustryGroup)
      this.industryGroupChanged()
      this.form1.controls.industry.patchValue(this.selectedInvitation.partnerIndustry)
      this.form1.controls.website.patchValue(this.selectedInvitation.partnerWebsite)
      this.form2.controls.primaryContactFirstName.patchValue(this.selectedInvitation.primaryContactFirstName)
      this.form2.controls.primaryContactLastName.patchValue(this.selectedInvitation.primaryContactLastName)
      this.form2.controls.primaryContactEmail.patchValue(this.selectedInvitation.primaryContactEmail)
    }

    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    let langCode: string = await this.getCompanyEmailLanguage()

    this.selectedLang = this.languageService.getLanguageObject(langCode)
    this.updateDisableEnglish(this.selectedLang.code)

    this.industriesList = [this.industries[0].industry[0][this.activeLanguage.code]]
    this.form1 = new FormGroup({
      name: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      industryGroup: new FormControl(this.industries[0].industryGroup[this.activeLanguage.code], [
        Validators.required,
        Validators.pattern(`^((?!Select).)*$`)
      ]),
      industry: new FormControl(this.industries[0].industry[0][this.activeLanguage.code], [
        Validators.required,
        Validators.pattern(`^((?!Select).)*$`)
      ]),
      website: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      ])
    })
  }

  resolveLabel(label: any): string {
    if (!label) {
      return ''
    }

    return label[this.activeLanguage?.code] || label['en'] || label
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  async getCompanyEmailLanguage(): Promise<any> {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode ? langCode : 'en'
    } catch (err) {}
  }

  onIncludeEnglishChange(event: Event) {
    this.includeEnglish = event.target['checked']
  }

  navigateToEditTemplate() {
    this.onCancel()
    this.closeModal()
    this.router.navigate([RoutesFe.PROFILE.fullPath()], { queryParams: { tab: 'company-settings' } })
  }

  openModal() {
    this.showNavigateModal = true
  }

  onCancel() {
    this.showNavigateModal = false
  }

  async addNewPartner() {
    this.isLoading = true
    this.steps[this.currentActive - 1].completed = true
    this.newPartner.includeEnglish = this.includeEnglish
    this.newPartner.emailLanguage = this.selectedLang.code
    await this.stateService.inviteNewPartner(this.newPartner)
    this.isLoading = false
    this.closeModal()
  }

  async getLink() {
    this.invitationLinkGenerated = false

    let responseGroup, responseIndustry
    this.industries.forEach((data, index) => {
      if (index != 0 && !responseGroup && !responseIndustry) {
        if (data.industryGroup[this.activeLanguage.code] == this.form1.value.industryGroup) {
          responseGroup = data.industryGroup['en']

          data.industry.forEach((ind) => {
            if (ind[this.activeLanguage.code] == this.form1.value.industry) {
              responseIndustry = ind['en']
            }
          })
        }
      }
    })

    this.invitationLink = await this.routerFe.generateInvitation()
    this.newPartner = new PartnerInvitation_AddFe(
      this.invitationLink.uuid,
      this.stateService.activeWorkspace.companyId,
      this.form1.value.name,
      'DEMO',
      this.form1.value.country,
      responseGroup,
      responseIndustry,
      this.form1.value.website,
      this.form2.value.primaryContactFirstName,
      this.form2.value.primaryContactLastName,
      this.form2.value.primaryContactEmail,
      `${this.invitationLink.serverAddress}/op/register/partner/${this.invitationLink.uuid}`,
      this.selectedLang.code,
      this.includeEnglish
    )
    this.invitationLinkGenerated = true
    this.next()
  }

  closeModal(): void {
    this.modalService.hide()
    document.body.classList.remove('modal-open')
  }

  next(): void {
    this.steps[this.currentActive - 1].completed = true
    const circles = this._document.querySelectorAll('.circle1')
    const steps = this._document.querySelectorAll('.stepper')
    this.currentActive++
    if (this.currentActive > circles.length) {
      if (this.currentActive > steps.length) this.currentActive = circles.length
    }
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.steps[this.currentActive - 1].completed = false // current
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.steps[this.currentActive - 1].completed = false // previous
    this.activeFormNo--
    this.update()
  }

  isCompleted(index: number) {
    return this.steps[index - 1].completed
  }

  updateIncludeEnglish(langCode) {
    this.includeEnglish = langCode !== 'en'
  }

  updateDisableEnglish(langCode) {
    this.disableEnglish = langCode === 'en'
  }

  updateLanguage(lang: Language) {
    this.selectedLang = lang
    this.updateDisableEnglish(lang.code)
  }

  update() {
    this.circles = this._document.querySelectorAll('.circle1')
    this.prevButton = this._document.getElementById('prev') as HTMLButtonElement
    this.nextButton = this._document.getElementById('next') as HTMLButtonElement

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add('active-circle1')
      } else {
        circle.classList.remove('active-circle1')
      }
    })
  }

  industryGroupChanged() {
    let data = this.industries.find(
      (data, index) =>
        data.industryGroup[this.activeLanguage.code] == this.form1.controls.industryGroup.value && index != 0
    )
    this.industriesList = [this.industries[0].industry[0][this.activeLanguage.code]]
    if (data) {
      const indList = data.industry.map((ind) => ind[this.activeLanguage.code])
      this.industriesList.push(...indList)
    }
  }
}
