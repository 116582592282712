import { CsrdDisclosureRequirementFe } from './CsrdDisclosureRequirementFe'

export class CsrdEsrsFe {
  code: string
  name: string
  subTopics: string[]
  subSubTopics: string[]
  disclosures: CsrdDisclosureRequirementFe[]
  id: string
  isAssessed: boolean
  isMaterial: boolean
  isMandatory: boolean

  constructor(
    code: string,
    name: string,
    subTopics: string[],
    subSubTopics: string[],
    disclosures: CsrdDisclosureRequirementFe[],
    id: string,
    isAssessed: boolean,
    isMaterial: boolean,
    isMandatory: boolean
  ) {
    this.code = code
    this.name = name
    this.subTopics = subTopics
    this.subSubTopics = subSubTopics
    this.disclosures = disclosures
    this.id = id
    this.isAssessed = isAssessed
    this.isMaterial = isMaterial
    this.isMandatory = isMandatory
  }

  public static fromJson(json: any): CsrdEsrsFe {
    const disclosures = json.disclosures.map((d) => CsrdDisclosureRequirementFe.fromJson(d))
    const esrs = new CsrdEsrsFe(
      json['code'],
      json['name'],
      json['subTopics'],
      json['subSubTopics'],
      disclosures,
      json['id'],
      json['isAssessed'],
      json['isMaterial'],
      json['isMandatory']
    )
    return esrs
  }
}
