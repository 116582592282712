<div class="modal-header p-3" style="border-radius: 0px; border: none">
  <h4 class="modal-title mt-2 text-uppercase" id="myModalLabel99">
    {{ locale('locale_key.pages.task_portal.details.task_info.title') }}
  </h4>
</div>
<div class="m-0 p-0">
  <div class="details" *ngIf="task">
    <div class="modal-body p-2" style="height: 70vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="form-group" style="overflow-x: hidden">
        <div class="d-flex flex-row align-items-center">
          <div class="col-4 text-center">
            <i class="las la-list" style="font-size: 5rem"></i>
          </div>
          <div class="col-8 text-start">
            <h3 class="text-start mb-0 pb-1 ps-3 pt-1">
              {{ task.getTitle() }}
              <i class="la la-calendar-o" style="font-size: 1.5rem" *ngIf="isRecurring()"></i>
            </h3>
          </div>
        </div>
        <div class="mt-2">
          <h4 class="ms-2 mt-2 mb-0">
            {{ locale('locale_key.pages.task_portal.details.description.title') | titlecase }}
          </h4>
          <div class="mx-2 multiline">{{ task.description }}</div>
        </div>
        <h4 class="ms-2 mt-2 mb-0">{{ locale('locale_key.pages.task_portal.details.data_requester.title') }}</h4>
        <div class="d-flex flex-row px-1 mt-2">
          <div class="col-2 text-center">
            <div class="circle-icon mt-1 me-1 mx-auto"><i class="las la-user fs-4 text-white"></i></div>
          </div>
          <div class="col-10 text-start">
            <h5 class="text-start inline-block mb-0 pb-1 ps-3 pt-1 cursor-arrow">
              {{ task.requesterFirstName + ' ' + task.requesterLastName }}
            </h5>
            <p class="text-start inline-block mb-0 pb-1 ps-3 text-muted cursor-arrow">
              {{ task.requesterCompanyName }}
            </p>
          </div>
        </div>
        <h4 class="ms-2 mt-2 mb-0">{{ locale('locale_key.pages.data_request.title.current_request_period') }}</h4>
        <div class="ms-4 mt-3">
          <div class="row">
            <span class="col-6">{{ locale('locale_key.general.state.created') }}</span>
            <span class="col-6">{{ locale('locale_key.heappening.deadline') }}</span>
          </div>
          <div class="row">
            <span class="col-6">{{ requestGroup.getCreated() }}</span>
            <span class="col-6">{{ requestGroup.getDeadline() }}</span>
          </div>
        </div>
        <div class="ms-3 mt-3" *ngIf="isRecurring()">
          <div class="d-flex mt-3 flex-row justify-content-between">
            <div class="d-flex flex-row">
              <h4 class="pe-2 ms-0 ps-0">{{ locale('locale_key.pages.data_request.details.title.recurrence') }}</h4>
            </div>
            <span class="ms-4 me-auto">
              <a class="underline dark-text" (click)="showIntervals()">
                {{ morePeriods ? locale('locale_key.button.see_less') : locale('locale_key.button.see_more') }}
              </a>
            </span>
          </div>
          <div class="border mx-0">
            <div class="m-0 p-0" *ngIf="morePeriods">
              <h6 class="ms-2 mt-3" *ngIf="getPreviousRequestPeriods().length > 0">
                {{ locale('locale_key.pages.data_request.title.previous_request_periods') }}
              </h6>
              <ng-container *ngFor="let period of getPreviousRequestPeriods()">
                <div class="ms-4 mt-3">
                  <p class="mb-0 pb-0">{{ period.title }}</p>
                  <p class="mt-0 pt-0">
                    <b>{{ period.start }} - {{ period.end }}</b>
                  </p>
                </div>
              </ng-container>
              <h6 class="ms-2 mt-3" *ngIf="getCurrentRequestPeriod().length > 0">
                {{ locale('locale_key.pages.data_request.title.current_request_period') }}
              </h6>
              <ng-container *ngFor="let period of getCurrentRequestPeriod()">
                <div class="ms-4 mt-3">
                  <p class="mb-0 pb-0">{{ period.title }}</p>
                  <p class="mt-0 pt-0">
                    <b>{{ period.start }} - {{ period.end }}</b>
                  </p>
                </div>
              </ng-container>
            </div>
            <h6 class="ms-2 mt-3" *ngIf="getUpcomingRequestPeriods().length > 0">
              {{ locale('locale_key.pages.data_request.title.upcoming_request_periods') }}
            </h6>
            <ng-container *ngFor="let period of getUpcomingRequestPeriods()">
              <div class="ms-4 mt-3">
                <p class="mb-0 pb-0">{{ period.title }}</p>
                <p class="mt-0 pt-0">
                  <b>{{ period.start }} - {{ period.end }}</b>
                </p>
              </div>
            </ng-container>
            <div class="m-0 p-0" *ngIf="morePeriods">
              <h6 class="ms-2 mt-3" *ngIf="getLastRequestPeriod().length > 0">
                {{ locale('locale_key.pages.data_request.details.title.end_recurrence') }}
              </h6>
              <ng-container *ngIf="requestGroup.recurrence.ends != 'NEVER'">
                <ng-container *ngFor="let period of getLastRequestPeriod()">
                  <div class="ms-4 mt-3">
                    <p class="mb-0 pb-0">{{ period.title }}</p>
                    <p class="mt-0 pt-0">
                      <b>{{ period.start }} - {{ period.end }}</b>
                    </p>
                  </div>
                </ng-container>
              </ng-container>
              <div class="ms-4 mt-3" *ngIf="requestGroup.recurrence.ends == 'NEVER'">
                <p class="mt-0 pt-0">
                  <b>{{ locale('locale_key.pages.data_request.details.title.doesnt_end') }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
