import { CsrdEsrsFe } from './CsrdEsrsFe'

export class CsrdTopicFe {
  name: string
  esrs: CsrdEsrsFe[]
  id: string

  constructor(name: string, esrs: CsrdEsrsFe[], id: string) {
    this.name = name
    this.esrs = esrs
    this.id = id
  }

  public static fromJson(json: any): CsrdTopicFe {
    const esrs = json.esrs.map((e) => CsrdEsrsFe.fromJson(e))
    const topic = new CsrdTopicFe(json['name'], esrs, json['id'])
    return topic
  }
}
