import { DateUtil } from 'src/app/utils/DateUtil'
import { AutomatedFeedInfoFe } from '../automatedFeed/AutomatedFeedInfoFe'
import { RequestGroupFe } from '../data-suppliers/request/RequestGroupFe'
import { DirectEntryInfoFe } from '../directEntry/DirectEntryInfoFe'
import { FileDataFe } from '../file/FileDataFe'
import { EntityFe } from '../taxonomy/EntityFe'

export class RecentlyOpenedItemTypeFe {
  public static readonly REQUEST = 'REQUEST'
  public static readonly DIRECT_ENTRY = 'DIRECT_ENTRY'
  public static readonly AUTOMATED_FEED = 'AUTOMATED_FEED'
  public static readonly FILE = 'FILE'
  public static readonly MASTER_TABLE = 'MASTER_TABLE'
}

export const MAX_RECENTLY_OPENED_ITEMS = 50

export class RecentlyOpenedItemFe {
  id: string
  type: string
  lastOpened: Date

  item: RequestGroupFe | DirectEntryInfoFe | FileDataFe | AutomatedFeedInfoFe | EntityFe

  constructor(id: string, type: string, lastOpened: Date) {
    this.id = id
    this.type = type
    this.lastOpened = lastOpened
  }

  public static fromTransfer(transfer: any): RecentlyOpenedItemFe {
    let item = new RecentlyOpenedItemFe(transfer.id, transfer.type, new Date(transfer.lastOpened))
    return item
  }

  isItemRg() {
    return this.type == RecentlyOpenedItemTypeFe.REQUEST
  }

  isItemDirectEntry() {
    return this.type == RecentlyOpenedItemTypeFe.DIRECT_ENTRY
  }

  isItemAutomatedFeed() {
    return this.type == RecentlyOpenedItemTypeFe.AUTOMATED_FEED
  }

  isItemFile() {
    return this.type == RecentlyOpenedItemTypeFe.FILE
  }

  isItemMasterTable() {
    return this.type == RecentlyOpenedItemTypeFe.MASTER_TABLE
  }

  getLastOpened() {
    if (this.lastOpened) {
      return DateUtil.toString3(this.lastOpened)
    }
  }
}
