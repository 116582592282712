<div [class]="'formula_builder formula_builder_' + componentID">
  <!-- EDIT MODE -->
  <div *ngIf="showEditMode" class="token_editor">
    <ng-container *ngIf="showEditMode && calculation.formula.length == 0">
      <div
        class="token tokens_control create_formula_control"
        [attr.data-tokenIdx]="0"
        (click)="toggleTokenDropdown({ tokenIdx: 0, componentID })"
      >
        <i class="icon las la-plus-circle"></i>
        <div class="help_text">{{ locale('locale_key.pages.data_categories_formula.start_building.calculation') }}</div>
      </div>
      <div class="dropdown" [attr.data-tokenIdx]="0">
        <formula-operand-dropdown
          class="dropdown_content"
          [includeMeasurementTypes]="includeMeasurementTypes"
          [showDatapoints]="showDatapoints"
          [showAddNewDataPointBtn]="showAddNewDataPointBtn"
          [selectedDataCategory]="selectedDataCategory"
          [selectedDatapoint]="selectedDatapoint"
          [tokenIdx]="'0'"
          [calculation]="calculation"
        ></formula-operand-dropdown>
      </div>
    </ng-container>

    <ng-container *ngFor="let token of calculation.formula; let tokenIdx = index">
      <!-- SELECT DATA INPUT OPERAND -->
      <ng-container *ngIf="token.type == 'operand' && token.operandType == 'selectDataInput'">
        <div
          class="token operand"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
        >
          <div class="name">
            <em>{{ token.value }}</em>
          </div>
          <div class="spacer"></div>
        </div>
        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operand-dropdown
            class="dropdown_content"
            [includeMeasurementTypes]="includeMeasurementTypes"
            [showDatapoints]="showDatapoints"
            [showAddNewDataPointBtn]="showAddNewDataPointBtn"
            [selectedDataCategory]="selectedDataCategory"
            [selectedDatapoint]="selectedDatapoint"
            [tokenIdx]="tokenIdx"
            [token]="token"
            [calculation]="calculation"
          ></formula-operand-dropdown>
        </div>
      </ng-container>

      <!-- DATAPOINT OPERAND -->
      <ng-container *ngIf="token.type == 'operand' && token.operandType == 'dataPoint'">
        <div
          class="token operand"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
        >
          <div class="name">
            <ng-container *ngIf="token.datapoint.datatype == 'EMISSION_FACTOR'">
              <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
              <span>{{ resolveLabel({ token, deployed: true }) }}</span>
              <b>({{ resolveUnit({ token, deployed: true }) }})</b>
            </ng-container>
            <ng-container *ngIf="token.datapoint.datatype != 'EMISSION_FACTOR'">
              {{ resolveLabel({ token, deployed: true }) }}
              <b *ngIf="token.datapoint?.unit?.symbol">({{ resolveUnit({ token, deployed: true }) }})</b>
            </ng-container>
          </div>
          <div class="spacer"></div>
        </div>
        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operand-dropdown
            class="dropdown_content"
            [showDatapoints]="showDatapoints"
            [showAddNewDataPointBtn]="showAddNewDataPointBtn"
            [selectedDataCategory]="selectedDataCategory"
            [selectedDatapoint]="selectedDatapoint"
            [tokenIdx]="tokenIdx"
            [calculation]="calculation"
          ></formula-operand-dropdown>
        </div>
      </ng-container>

      <!-- EMISSION FACTOR OPERAND -->

      <ng-container *ngIf="token.type == 'operand' && token.operandType == 'emissionFactor'">
        <div
          class="token operand"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
        >
          <div class="name">
            <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
            <span
              [innerHTML]="
                sanitizeHtml(
                  locale('locale_key.pages.data_categories_formula.source_unit_name_to_conversion_factor', {
                    sourceUnit: token.emissionFactor.sourceUnit,
                    sourceName: token.emissionFactor.sourceName,
                    conversionFactor: token.emissionFactor.conversionFactor,
                    conversionUnit: token.emissionFactor.conversionUnit
                  })
                )
              "
            ></span>
          </div>
          <div class="spacer"></div>
          <div *ngIf="token.datapoint?.unit?.symbol" class="unit">{{ resolveUnit({ token, deployed: false }) }}</div>
        </div>
        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operand-dropdown
            class="dropdown_content"
            [includeMeasurementTypes]="includeMeasurementTypes"
            [showDatapoints]="showDatapoints"
            [showAddNewDataPointBtn]="showAddNewDataPointBtn"
            [selectedDataCategory]="selectedDataCategory"
            [selectedDatapoint]="selectedDatapoint"
            [tokenIdx]="tokenIdx"
            [token]="token"
            [calculation]="calculation"
          ></formula-operand-dropdown>
        </div>
      </ng-container>

      <!-- FIXED NUMBER OPERAND -->
      <ng-container *ngIf="token.type == 'operand' && token.operandType == 'fixedNumber'">
        <div
          class="token operand"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
        >
          <div class="name">{{ token.value }}</div>
          <div class="spacer"></div>
          <div *ngIf="token.unit?.symbol" class="unit">{{ token.unit?.symbol }}</div>
        </div>
        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operand-dropdown
            class="dropdown_content"
            [includeMeasurementTypes]="includeMeasurementTypes"
            [showDatapoints]="showDatapoints"
            [showAddNewDataPointBtn]="showAddNewDataPointBtn"
            [selectedDataCategory]="selectedDataCategory"
            [selectedDatapoint]="selectedDatapoint"
            [showAddFixedNumberInput]="true"
            [addFixedNumberValue]="token.value"
            [tokenIdx]="tokenIdx"
            [token]="token"
            [calculation]="calculation"
          ></formula-operand-dropdown>
        </div>
      </ng-container>

      <!-- CONTROL OPERATOR -->
      <ng-container *ngIf="token.type == 'operator' && token.operatorType == 'control'">
        <div
          class="token operator operator_control"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
        >
          <i class="icon las la-plus-circle"></i>
        </div>
        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operator-dropdown
            class="dropdown_content"
            [includeMeasurementTypes]="includeMeasurementTypes"
            [showDatapoints]="showDatapoints"
            [tokenIdx]="tokenIdx"
            [token]="token"
            [calculation]="calculation"
          ></formula-operator-dropdown>
        </div>
      </ng-container>

      <!-- OTHER OPERATORS -->
      <ng-container *ngIf="token.type == 'operator' && token.operatorType != 'control'">
        <!-- PLUS OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'plus'"
        >
          <svg
            class="operator_icon operator_icon_plus"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M11.25 3.75V11.25H3.75V12.75H11.25V20.25H12.75V12.75H20.25V11.25H12.75V3.75H11.25Z" fill="white" />
          </svg>
        </div>

        <!-- MINUS OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'minus'"
        >
          <svg
            class="operator_icon operator_icon_minus"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M3.75 11.25V12.75H20.25V11.25H3.75Z" fill="white" />
          </svg>
        </div>

        <!-- TIMES OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'times'"
        >
          <svg
            class="operator_icon operator_icon_times"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <!-- DIVIDE OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'divide'"
        >
          <svg
            class="operator_icon operator_icon_divide"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 4.5C10.7666 4.5 9.75 5.5166 9.75 6.75C9.75 7.9834 10.7666 9 12 9C13.2334 9 14.25 7.9834 14.25 6.75C14.25 5.5166 13.2334 4.5 12 4.5ZM12 6C12.4219 6 12.75 6.32812 12.75 6.75C12.75 7.17188 12.4219 7.5 12 7.5C11.5781 7.5 11.25 7.17188 11.25 6.75C11.25 6.32812 11.5781 6 12 6ZM3.75 11.25V12.75H20.25V11.25H3.75ZM12 15C10.7666 15 9.75 16.0166 9.75 17.25C9.75 18.4834 10.7666 19.5 12 19.5C13.2334 19.5 14.25 18.4834 14.25 17.25C14.25 16.0166 13.2334 15 12 15ZM12 16.5C12.4219 16.5 12.75 16.8281 12.75 17.25C12.75 17.6719 12.4219 18 12 18C11.5781 18 11.25 17.6719 11.25 17.25C11.25 16.8281 11.5781 16.5 12 16.5Z"
              fill="white"
            />
          </svg>
        </div>

        <!-- OPEN BRACKET OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'openBracket'"
        >
          <svg
            class="icon operator_open_bracket"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7.99609 12.1075C7.99609 10.6114 8.13943 9.15118 8.4261 7.72676C8.71278 6.29339 9.15623 4.92721 9.75645 3.62822C10.3567 2.32027 11.1182 1.11086 12.0409 0H15.4003C14.1461 1.72004 13.1921 3.61926 12.5381 5.69765C11.8931 7.77604 11.5706 9.9037 11.5706 12.0806C11.5706 13.4961 11.7139 14.9071 12.0006 16.3135C12.2872 17.7111 12.7128 19.0594 13.2772 20.3583C13.8505 21.6573 14.5493 22.8712 15.3735 24H12.0409C11.1182 22.925 10.3567 21.7469 9.75645 20.4658C9.15623 19.1937 8.71278 17.8544 8.4261 16.4479C8.13943 15.0325 7.99609 13.5857 7.99609 12.1075Z"
              fill="white"
            />
          </svg>
        </div>

        <!-- CLOSE BRACKET OPERATOR -->
        <div
          class="token operator"
          [attr.data-tokenIdx]="tokenIdx"
          (click)="toggleTokenDropdown({ tokenIdx, componentID })"
          [class.highlight_error]="showCalculationErrors && token.highlightError"
          *ngIf="token.operatorType == 'closeBracket'"
        >
          <svg
            class="operator_icon operator_icon_close_bracket"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15.4043 12.1075C15.4043 10.6114 15.261 9.15118 14.9743 7.72676C14.6876 6.29339 14.2442 4.92721 13.6439 3.62822C13.0437 2.32027 12.2822 1.11086 11.3595 0H8.00004C9.25424 1.72004 10.2083 3.61926 10.8623 5.69765C11.5073 7.77604 11.8298 9.9037 11.8298 12.0806C11.8298 13.4961 11.6865 14.9071 11.3998 16.3135C11.1131 17.7111 10.6876 19.0594 10.1232 20.3583C9.54987 21.6573 8.85111 22.8712 8.02692 24H11.3595C12.2822 22.925 13.0437 21.7469 13.6439 20.4658C14.2442 19.1937 14.6876 17.8544 14.9743 16.4479C15.261 15.0325 15.4043 13.5857 15.4043 12.1075Z"
              fill="white"
            />
          </svg>
        </div>

        <div class="dropdown" [attr.data-tokenIdx]="tokenIdx">
          <formula-operator-dropdown
            class="dropdown_content"
            [includeMeasurementTypes]="includeMeasurementTypes"
            [showDatapoints]="showDatapoints"
            [tokenIdx]="tokenIdx"
            [token]="token"
            [calculation]="calculation"
          ></formula-operator-dropdown>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- ERRORS -->
  <div class="error_msgs" *ngIf="showEditMode && showCalculationErrors">
    <ul>
      <li *ngFor="let errorMsg of errorMsgs">{{ errorMsg }}</li>
    </ul>
  </div>

  <!-- VIEW MODE -->
  <div class="token_viewer">
    <ng-container *ngFor="let token of calculation.formula">
      <!-- DATAPOINT OPERAND -->
      <div *ngIf="token.type == 'operand' && token.operandType == 'dataPoint'" class="token">
        <ng-container *ngIf="token.datapoint.datatype == 'EMISSION_FACTOR'">
          <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
          <span>{{ resolveLabel({ token, deployed: true }) }}</span>
          <b>({{ resolveUnit({ token, deployed: true }) }})</b>
        </ng-container>
        <ng-container *ngIf="token.datapoint.datatype != 'EMISSION_FACTOR'">
          {{ resolveLabel({ token, deployed: true }) }}
          <b *ngIf="token.datapoint?.unit?.symbol">({{ resolveUnit({ token, deployed: true }) }})</b>
        </ng-container>
      </div>

      <!-- EMISSION FACTOR OPERAND -->
      <div *ngIf="token.type == 'operand' && token.operandType == 'emissionFactor'" class="token">
        <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
        <span
          [innerHTML]="
            sanitizeHtml(
              locale(
                'locale_key.pages.data_categories_formula.source_unit_name_to_span_conversion_factor_emission_factor',
                {
                  sourceUnit: token.emissionFactor.sourceUnit,
                  sourceName: token.emissionFactor.sourceName,
                  conversionFactor: token.emissionFactor.conversionFactor,
                  conversionUnit: token.emissionFactor.conversionUnit
                }
              )
            )
          "
        ></span>
      </div>

      <!-- FIXED NUMBER OPERAND -->
      <div *ngIf="token.type == 'operand' && token.operandType == 'fixedNumber'" class="token">
        {{ token.value }}{{ token.unit?.symbol }}
      </div>

      <!-- OTHER OPERATORS -->
      <ng-container *ngIf="token.type == 'operator' && token.operatorType != 'control'">
        <div class="token" *ngIf="token.operatorType == 'plus'">+</div>

        <div class="token" *ngIf="token.operatorType == 'minus'">-</div>

        <div class="token operator" *ngIf="token.operatorType == 'times'">*</div>

        <div class="token" *ngIf="token.operatorType == 'divide'">/</div>

        <div class="token" *ngIf="token.operatorType == 'openBracket'">(</div>

        <div class="token operator" *ngIf="token.operatorType == 'closeBracket'">)</div>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #emissionFactorFireIcon>
  <svg
    style="margin-right: 0.2rem"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59912 0.292969L6.35205 4.04004L4.96484 2.6543L4.4375 3.25342C1.82525 6.21442 0.5 8.90475 0.5 11.25C0.5 14.9715 3.8645 18 8 18C12.1355 18 15.5 14.9715 15.5 11.25C15.5 7.6785 11.6023 3.27455 9.27881 0.966797L8.59912 0.292969ZM8.89355 2.71729C10.8256 4.73179 14 8.466 14 11.25C14 13.0434 12.9652 14.6282 11.3926 15.5757C11.6165 15.0406 11.75 14.4506 11.75 13.8223C11.75 12.0365 10.4865 9.91829 9.42676 8.45654L8.79395 7.58203L7.13281 10.0737L6.07812 9.01904L5.58301 9.83643C4.69876 11.2959 4.25 12.6365 4.25 13.8223C4.25 14.4506 4.38349 15.0406 4.60742 15.5757C3.0348 14.6282 2 13.0434 2 11.25C2 9.45825 3.04823 7.25753 5.04248 4.85303L6.64795 6.45996L8.89355 2.71729ZM8.82764 10.2363C9.75014 11.6741 10.25 12.9253 10.25 13.8223C10.25 15.299 9.2405 16.5 8 16.5C6.7595 16.5 5.75 15.299 5.75 13.8223C5.75 13.1308 5.96768 12.3392 6.39893 11.4609L7.36719 12.4277L8.82764 10.2363Z"
      fill="#374659"
    />
  </svg>
</ng-template>
