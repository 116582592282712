<div class="modal-header" style="border-bottom: none">
  <div
    *ngIf="type == 'info'"
    class="icon-background d-inline"
    style="
      height: 18px;
      width: 18px;
      background-color: var(--orange);
      border-radius: 50%;
      border: 2px solidtext-white;
      margin-top: 0.35rem;
    "
  >
    <i
      class="la la-exclamation-circle text-white p-0"
      style="
        font-size: 1.5rem;
        background-color: transparent;
        border-radius: 50%;
        position: relative;
        left: -2px;
        top: -1px;
      "
    ></i>
  </div>
  <div
    *ngIf="type == 'error' || type =='warning'"
    class="icon-background d-inline"
    style="height: 18px; width: 18px; background-color: #c65c5c; border-radius: 50%"
  >
    <i
      class="la la-times text-white p-0"
      style="
        font-size: 1.25rem;
        background-color: transparent;
        border-radius: 50%;
        position: relative;
        left: 0px;
        top: -2px;
      "
    ></i>
  </div>
  <span *ngIf="type == 'success'" class="d-inline" style="font-size: 1.4rem; position: relative; top: -2px">
    <i class="ft-check-circle success"></i>
  </span>
  <h4 class="modal-title ms-2 me-auto">{{title}}!</h4>
  <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <div id="swal2-content" style="display: block">{{message}}</div>
</div>
<div class="modal-footer" style="border-top: none">
  <button
    type="button"
    class="btn primary-button btn-block custom-button"
    *ngIf="error?.name != 'INVITATION_LINK_EXPIRED_ERROR'"
    (click)="closeModal()"
  >
    {{locale('locale_key.general.buttons.ok')}}
  </button>
  <button
    type="button"
    class="btn primary-button btn-block custom-button"
    *ngIf="error?.name == 'INVITATION_LINK_EXPIRED_ERROR'"
    (click)="routeToLogin()"
  >
    {{locale('locale_key.pages.clients.login_modal.button.login')}}
  </button>
</div>
