<div id="drDatapointsOverviewComponent">
  <div class="statusRow">
    <div class="status">
      <div class="name">{{ slocale('Status') }}</div>
      <ng-container *ngIf="drStatus == DR_STATUSES.NOT_STARTED">
        <div class="value notStarted">
          {{ slocale('Not Started') }}
        </div>
      </ng-container>
      <ng-container *ngIf="drStatus == DR_STATUSES.ASSESSING">
        <div class="value assessing">
          {{ slocale('Assessing') }}
        </div>
      </ng-container>
      <ng-container *ngIf="drStatus == DR_STATUSES.ASSESSED">
        <div class="value assessed">
          {{ slocale('Assessed') }}
        </div>
      </ng-container>
      <ng-container *ngIf="drStatus == DR_STATUSES.DRAFTING">
        <div class="value drafting">
          {{ slocale('Drafting') }}
        </div>
      </ng-container>
      <ng-container *ngIf="drStatus == DR_STATUSES.COLLECTING">
        <div class="value collecting">
          {{ slocale('Collecting') }}
        </div>
      </ng-container>
    </div>
    <div class="materiality">
      <div class="name">
        {{ slocale('Materiality') }}
      </div>
      <ng-container *ngIf="activeEsrs.isMaterial && !activeEsrs.isMandatory">
        <div class="value material">
          {{ slocale('Material') }}
        </div>
      </ng-container>
      <ng-container *ngIf="!activeEsrs.isMaterial && !activeEsrs.isMandatory">
        <div class="value notMaterial">{{ slocale('Not material') }}</div>
      </ng-container>
      <ng-container *ngIf="activeEsrs.isMandatory">
        <div class="value mandatory">{{ slocale('Mandatory') }}</div>
      </ng-container>
    </div>
  </div>
  <div class="ctaRow">
    <ng-container *ngIf="drStatus != DR_STATUSES.COLLECTING">
      <div class="lhs"></div>
    </ng-container>

    <ng-container *ngIf="drStatus == DR_STATUSES.COLLECTING">
      <div class="mhs collecting">
        <div class="info">
          <div class="name">
            {{ slocale('ORGANIZATION UNITS CONTACTED') }}
          </div>
          <div class="value">3</div>
        </div>
        <div class="info">
          <div class="name">
            {{ slocale('SUBMISSIONS TO REVIEW') }}
          </div>
          <div class="value">-</div>
        </div>
        <div class="info">
          <div class="name">
            {{ slocale('COMPLETED REQUESTS') }}
          </div>
          <div class="value">0%</div>
        </div>
        <div class="next">
          <i class="la la-arrow-right icon"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="drStatus != DR_STATUSES.COLLECTING">
      <div class="mhs">
        <div class="logo">
          <ng-container *ngTemplateOutlet="euLogo"></ng-container>
        </div>
        <ng-container *ngIf="drStatus == DR_STATUSES.NOT_STARTED">
          <div class="message">
            {{ slocale("Let's start by assessing the data points.") }}
          </div>
          <button class="btn primary-button" (click)="startDrAssessment()">{{ slocale('Start') }}</button>
        </ng-container>
        <ng-container *ngIf="drStatus == DR_STATUSES.ASSESSING">
          <div class="message">
            {{ slocale('You have drafted data point assessments.') }}
            <br />
            {{ slocale('Resume to complete the assessment.') }}
          </div>
          <button class="btn primary-button" (click)="resumeDrAssessment()">{{ slocale('Resume') }}</button>
        </ng-container>
        <ng-container *ngIf="drStatus == DR_STATUSES.ASSESSED">
          <div class="message">
            {{ slocale('The data points are assessed.') }}
            <br />
            {{ slocale('Ready to create data requests for data collection?') }}
          </div>
          <button class="btn primary-button" (click)="createRequests()" *ngIf="drService.hasQuestionnaire(activeDr.id)">
            {{ slocale('Create requests') }}
          </button>
        </ng-container>
        <ng-container *ngIf="drStatus == DR_STATUSES.DRAFTING">
          <div class="message">
            {{ slocale('You have saved a request draft.') }}
            <br />
            {{ slocale('Resume the process to send the request.') }}
          </div>
          <button
            class="btn primary-button"
            (click)="resumeCreateRequest()"
            *ngIf="drService.hasQuestionnaire(activeDr.id)"
          >
            {{ slocale('Resume') }}
          </button>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="drStatus != DR_STATUSES.COLLECTING">
      <div class="rhs"></div>
    </ng-container>
  </div>
  <div class="statsRow">
    <div class="lhs mainStat">
      <div
        class="stat totalDatapoints"
        [class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"
      >
        <div class="name">
          {{ slocale('TOTAL DATA POINTS TO BE COLLECTED') }}
        </div>
        <div class="value" *ngIf="drStatus != DR_STATUSES.NOT_STARTED && drStatus != DR_STATUSES.ASSESSING">
          {{ stats.totalDatapoints }}
        </div>
        <div class="value" *ngIf="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING">0</div>
      </div>
    </div>
    <div class="rhs otherStats">
      <div class="lhs">
        <div
          class="stat materialDatapoints"
          [class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"
        >
          <div class="name">{{ slocale('MATERIAL') }}</div>
          <div class="value" *ngIf="drStatus != DR_STATUSES.NOT_STARTED && drStatus != DR_STATUSES.ASSESSING">
            {{ stats.materialDatapoints }}
          </div>
          <div class="value" *ngIf="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING">0</div>
        </div>
        <div
          class="stat nonMaterialDatapoints"
          [class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"
        >
          <div class="name">{{ slocale('NOT MATERIAL') }}</div>
          <div class="value" *ngIf="drStatus != DR_STATUSES.NOT_STARTED && drStatus != DR_STATUSES.ASSESSING">
            {{ stats.nonMaterialDatapoints }}
          </div>
          <div class="value" *ngIf="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING">0</div>
        </div>
      </div>
      <div class="rhs">
        <div
          class="stat materialDatapointsOmitted"
          [class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"
        >
          <div class="name">{{ slocale('MATERIAL - OMITTED') }}</div>
          <div class="value" *ngIf="drStatus != DR_STATUSES.NOT_STARTED && drStatus != DR_STATUSES.ASSESSING">
            {{ stats.materialOmittedDatapoints }}
          </div>
          <div class="value" *ngIf="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING">0</div>
        </div>
        <div
          class="stat nonMaterialDatapointsVoluntary"
          [class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"
        >
          <div class="name">{{ slocale('NOT MATERIAL - VOLUNTARY') }}</div>
          <div class="value" *ngIf="drStatus != DR_STATUSES.NOT_STARTED && drStatus != DR_STATUSES.ASSESSING">
            {{ stats.nonMaterialVoluntaryDatapoints }}
          </div>
          <div class="value" *ngIf="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING">0</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #euLogo>
  <svg width="104" height="68" viewBox="0 0 104 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.3763 0L51.2093 3.60522H47.4336L50.4881 5.83308L49.3217 9.43769L52.3761 7.20983L55.4312 9.43769L54.2642 5.83308L57.3193 3.60522H53.543L52.3763 0Z"
      fill="url(#paint0_linear_1800_283951)"
    />
    <path
      d="M67.3099 3.50977L66.1429 7.11438H62.3672L65.4216 9.34224L64.2547 12.9475L67.3097 10.719L70.3648 12.9475L69.1978 9.34224L72.2529 7.11438H68.4766L67.3099 3.50977Z"
      fill="url(#paint1_linear_1800_283951)"
    />
    <path
      d="M74.8923 23.7443L77.9474 21.5165L81.0019 23.7443L79.8349 20.1397L82.89 17.9119H79.1136L77.9472 14.3066L76.7802 17.9119H73.0039L76.059 20.1397L74.8923 23.7443Z"
      fill="url(#paint2_linear_1800_283951)"
    />
    <path
      d="M86.6438 32.7902H82.8681L81.7011 29.1855L80.5342 32.7902H76.7578L79.8129 35.018L78.6459 38.6232L81.701 36.3954L84.756 38.6232L83.5891 35.018L86.6438 32.7902Z"
      fill="url(#paint3_linear_1800_283951)"
    />
    <path
      d="M79.1175 47.4542L77.9511 43.8496L76.7842 47.4542H73.0078L76.0629 49.6821L74.8959 53.2873L77.951 51.0594L81.0054 53.2873L79.8385 49.6821L82.8935 47.4542H79.1175Z"
      fill="url(#paint4_linear_1800_283951)"
    />
    <path
      d="M68.4769 58.3839L67.3099 54.7793L66.1429 58.3839H62.3672L65.4217 60.6118L64.2547 64.2164L67.3098 61.9885L70.3648 64.2164L69.1978 60.6118L72.2529 58.3839H68.4769Z"
      fill="url(#paint5_linear_1800_283951)"
    />
    <path
      d="M53.5433 62.1671L52.3763 58.5625L51.2093 62.1671H47.4336L50.4881 64.395L49.3217 68.0002L52.3761 65.7723L55.4312 68.0002L54.2642 64.395L57.3193 62.1671H53.5433Z"
      fill="url(#paint6_linear_1800_283951)"
    />
    <path
      d="M38.79 58.3839L37.623 54.7793L36.456 58.3839H32.6797L35.7348 60.6118L34.5678 64.2164L37.6229 61.9885L40.6773 64.2164L39.5103 60.6118L42.5654 58.3839H38.79Z"
      fill="url(#paint7_linear_1800_283951)"
    />
    <path
      d="M27.9072 47.4542L26.7402 43.8496L25.5732 47.4542H21.7969L24.8519 49.6821L23.685 53.2873L26.74 51.0594L29.7951 53.2873L28.6281 49.6821L31.6826 47.4542H27.9072Z"
      fill="url(#paint8_linear_1800_283951)"
    />
    <path
      d="M22.814 36.3954L25.869 38.6232L24.7021 35.018L27.7571 32.7902H23.9808L22.8138 29.1855L21.6468 32.7902H17.8711L20.9256 35.018L19.7586 38.6232L22.814 36.3954Z"
      fill="url(#paint9_linear_1800_283951)"
    />
    <path
      d="M26.7402 14.3086L25.5732 17.9138H21.7969L24.8519 20.1417L23.685 23.7463L26.74 21.5184L29.7951 23.7463L28.6281 20.1417L31.6826 17.9138H27.9069L26.7402 14.3086Z"
      fill="url(#paint10_linear_1800_283951)"
    />
    <path
      d="M37.623 3.50977L36.456 7.11438H32.6797L35.7348 9.34224L34.5678 12.9475L37.6229 10.719L40.6773 12.9475L39.5103 9.34224L42.5654 7.11438H38.7897L37.623 3.50977Z"
      fill="url(#paint11_linear_1800_283951)"
    />
    <path
      d="M50.4072 39.8242H44.6513V29.8301H50.4072V31.5664H46.7704V33.7607H50.1542V35.4971H46.7704V38.0742H50.4072V39.8242ZM60.6337 29.8301V36.2969C60.6337 36.985 60.4811 37.6071 60.1757 38.1631C59.8749 38.7145 59.4169 39.152 58.8017 39.4756C58.191 39.7992 57.4208 39.9609 56.4911 39.9609C55.1695 39.9609 54.1624 39.6237 53.4697 38.9492C52.777 38.2747 52.4306 37.3815 52.4306 36.2695V29.8301H54.5429V35.9482C54.5429 36.7731 54.7115 37.3519 55.0488 37.6846C55.386 38.0173 55.885 38.1836 56.5458 38.1836C57.0107 38.1836 57.3867 38.1038 57.6738 37.9443C57.9654 37.7848 58.1796 37.5387 58.3163 37.2061C58.4531 36.8734 58.5214 36.4495 58.5214 35.9346V29.8301H60.6337Z"
      fill="url(#paint12_linear_1800_283951)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1800_283951"
        x1="52.3764"
        y1="0"
        x2="52.3764"
        y2="9.43769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1800_283951"
        x1="67.3101"
        y1="3.50977"
        x2="67.3101"
        y2="12.9475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1800_283951"
        x1="77.9469"
        y1="14.3066"
        x2="77.9469"
        y2="23.7443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1800_283951"
        x1="81.7008"
        y1="29.1855"
        x2="81.7008"
        y2="38.6232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1800_283951"
        x1="77.9507"
        y1="43.8496"
        x2="77.9507"
        y2="53.2873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1800_283951"
        x1="67.3101"
        y1="54.7793"
        x2="67.3101"
        y2="64.2164"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1800_283951"
        x1="52.3764"
        y1="58.5625"
        x2="52.3764"
        y2="68.0002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1800_283951"
        x1="37.6226"
        y1="54.7793"
        x2="37.6226"
        y2="64.2164"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1800_283951"
        x1="26.7397"
        y1="43.8496"
        x2="26.7397"
        y2="53.2873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1800_283951"
        x1="22.8141"
        y1="29.1855"
        x2="22.8141"
        y2="38.6232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1800_283951"
        x1="26.7397"
        y1="14.3086"
        x2="26.7397"
        y2="23.7463"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1800_283951"
        x1="37.6225"
        y1="3.50977"
        x2="37.6225"
        y2="12.9475"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1800_283951"
        x1="52.6083"
        y1="20.8242"
        x2="52.6083"
        y2="48.3941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#82C19D" />
        <stop offset="1" stop-color="#59BCCF" />
      </linearGradient>
    </defs>
  </svg>
</ng-template>
