import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ManagerFe } from 'src/app/model/user/ManagerFe'
import { CompanyFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { ManagerInvitationFe } from 'src/app/model/invitation/ManagerInvitationFe'
import { ActionFe } from 'src/app/model/subject/ActionFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { AffiliationStatus } from 'src/app/model/user/AffiliationStatusFe'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { InvitationStatusFe } from 'src/app/model/invitation/InvitationStatusFe'
import { ContactInvitationFe } from 'src/app/model/data-suppliers/company/ContactInvitationFe'
import { AbstractAffiliationFe } from 'src/app/model/user/AbstractAffiliationFe'
import { SignInMethodProviderFe } from 'src/app/model/signInMethod/SignInMethodProviderFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'

interface Tenant {
  id: string
  description: string
  removeConfirm: boolean
}

@Component({
  selector: 'clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('inviteManagerModal', { static: true }) inviteManagerModal: TemplateRef<any>
  @ViewChild('inviteClientModal', { static: true }) inviteClientModal: TemplateRef<any>
  @ViewChild('confirmCancellation', { static: true }) confirmCancellation: TemplateRef<any>

  managementCompanies: CompanyFe[]
  filteredMgmtCompanies: CompanyFe[] = []
  selectedIndex = -1
  isCollapsed = false
  selected: ManagerFe | ContactFe | ManagerInvitationFe | ContactInvitationFe
  selectedUser: ManagerFe | ContactFe
  selectedUserIdx = -1
  selectedCompany: CompanyFe
  selectedCompanyIdx = -1
  loadInProgress: boolean = false
  searchKeyword: string
  inProgress: boolean = false
  userOptions = [
    { label: this.locale('locale_key.general.user_role.sustainability_managers'), value: 'SM' },
    { label: this.locale('locale_key.general.user_role.data_reporters'), value: 'DO' },
    { label: this.locale('locale_key.general.user_role.deactivated_data_reporters'), value: 'DEACTIVATED_DO' },
    { label: this.locale('locale_key.general.user_role.deactivated_managers'), value: 'DEACTIVATED_SM' }
  ]
  url = ''
  isLoading = false
  signInSettingsType: string
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode
  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.pages.clients.toolbar.button.add_manager'),
        longLabel: this.locale('locale_key.pages.clients.invite_manager_wizard.button.add_manager'),
        tooltip: this.locale('locale_key.pages.clients.invite_manager_wizard.button.add_manager'),
        icon: 'la la-plus',
        visible: () => true,
        actionName: 'add_manager'
      },
      {
        shortLabel: this.locale('locale_key.pages.clients.toolbar.button.add_company'),
        longLabel: this.locale('locale_key.pages.clients.modal.button.add_company'),
        tooltip: this.locale('locale_key.pages.clients.modal.button.add_company'),
        icon: 'la la-plus',
        visible: () => true,
        actionName: 'add_company'
      }
    ]
  ]

  constructor(
    private modalRef: BsModalRef,
    private stateService: StateServiceFe,
    private modalService: BsModalService,
    languageService: LanguageService,
    private displayService: DisplayServiceFe,
    private loginService: LoginServiceFe,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })
    this.menuCollapsed = responsive.menuCollapsed

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    await this.renderNewData()

    this.stateService.managerInvitationUpdatedSubject.subscribe((subject) => {
      let company = this.managementCompanies.find((company) => subject.invitation.clientCompanyId)
      let managerInvitations = company.managerInvitations
      if (subject.action == ActionFe.CREATED) {
        if (!managerInvitations.find((i) => i.uuid == subject.invitation.uuid)) {
          managerInvitations.push(subject.invitation)
        }
      } else if (subject.action == ActionFe.UPDATED) {
        let index = managerInvitations.findIndex((i) => i.uuid == subject.invitation.uuid)
        managerInvitations[index] = subject.invitation
      }
    })

    this.stateService.mgmtCompaniesUpdatedSubject.subscribe((c) => {
      this.managementCompanies.push(c)
    })
  }

  async renderNewData() {
    this.isLoading = true
    this.managementCompanies = await this.stateService.getAllManagementCompanies()
    this.filteredMgmtCompanies = [...this.managementCompanies]
    let allManagers = await this.stateService.getAllManagers()
    let allDataOwners = await this.stateService.getAllContacts()
    let allManagerInvitations = await this.stateService.getAllManagerInvitations()
    let allDOInvitations = await this.stateService.getAllContactInvitations()
    this.filteredMgmtCompanies.forEach((company) => {
      company.managers = allManagers.filter(
        (m) => m.affiliationCompanyId == company.id && m.affiliationStatus == AffiliationStatus.ACTIVE
      )
      company.dataOwners = allDataOwners.filter(
        (c) => c.managementCompanyId == company.id && c.affiliationStatus == AffiliationStatus.ACTIVE
      )
      company.deactivatedManagers = allManagers.filter(
        (m) => m.affiliationCompanyId == company.id && m.affiliationStatus != AffiliationStatus.ACTIVE
      )
      company.deactivatedDOs = allDataOwners.filter(
        (c) => c.managementCompanyId == company.id && c.affiliationStatus != AffiliationStatus.ACTIVE
      )
      company.managerInvitations = allManagerInvitations.filter(
        (i) => i.clientCompanyId == company.id && i.invitationStatus != InvitationStatusFe.ACCEPTED
      )
      company.doInvitations = allDOInvitations.filter(
        (i) => i.managementCompanyId == company.id && i.invitationStatus != InvitationStatusFe.ACCEPTED
      )
    })
    this.url = window.location.href
    this.isLoading = false
  }

  openModal(templateRef: TemplateRef<any> | string, size: string = '') {
    this.modalRef = this.modalService.show(templateRef, { class: size, ignoreBackdropClick: true })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  async deactivateAff() {
    this.loadInProgress = true
    const deactivated = await this.stateService.deactivateAff({ affiliationId: this.selectedUser.affiliationId })
    this.selectedUser.deactivationDate = new Date()
    // move user from wherever they are to deactivated user
    if (this.selectedUser instanceof ManagerFe) {
      this.filteredMgmtCompanies[this.selectedCompanyIdx].managers.splice(this.selectedUserIdx, 1)
      this.filteredMgmtCompanies[this.selectedCompanyIdx].deactivatedManagers.push(this.selectedUser)
    } else if (this.selectedUser instanceof ContactFe) {
      this.filteredMgmtCompanies[this.selectedCompanyIdx].dataOwners.splice(this.selectedUserIdx, 1)
      this.filteredMgmtCompanies[this.selectedCompanyIdx].deactivatedDOs.push(this.selectedUser)
    }
    this.loadInProgress = false
    this.closeModal()
  }

  async loginAs() {
    this.closeModal()
    await this.stateService.loginAs(
      this.selectedUser.affiliationId,
      this.selectedUser.affiliationRole,
      this.selectedUser.affiliationCompanyId
    )
  }

  onSelectedChange(index) {
    this.selectedIndex = index
  }

  setSelectedCompany({ company, idx }) {
    this.selectedCompany = company
    this.selectedCompanyIdx = idx
  }

  setSelectedUser({ user, idx }) {
    this.selected = user
    this.selectedUser = user
    this.selectedUserIdx = idx
  }

  inviteAgain(user: ManagerFe | ContactFe) {
    const role = user.affiliationRole
    switch (role) {
      case 'SUSTAINABILITY_MANAGER':
        this.stateService.setInviteAgainManager(user as ManagerFe)
        this.openModal(this.inviteManagerModal, 'modal-lg')
        break
    }
  }

  async cancelInvitation() {
    let invitation = this.selected
    this.loadInProgress = true
    if (invitation instanceof ManagerInvitationFe) {
      await this.stateService.cancelManagerInvitation(invitation.uuid)
    } else if (invitation instanceof ContactInvitationFe) {
      await this.stateService.cancelContactInvitation(invitation.uuid)
    }
    this.loadInProgress = false
    this.closeModal()
  }

  async assignMainManager() {
    this.loadInProgress = true
    if (this.selectedUser instanceof ManagerFe) {
      await this.stateService.assignMainManager(this.selectedUser.affiliationId)
      this.selectedUser.isMainManager = true
    }
    this.loadInProgress = false
    this.closeModal()
  }

  async removeMainManager() {
    this.loadInProgress = true
    if (this.selectedUser instanceof ManagerFe) {
      await this.stateService.removeMainManager(this.selectedUser.affiliationId)
      this.selectedUser.isMainManager = false
    }
    this.loadInProgress = false
    this.closeModal()
  }

  filterCompanies() {
    let keyword = this.searchKeyword.toLowerCase()
    this.filteredMgmtCompanies = this.managementCompanies.filter(
      (c) =>
        c.name.toLowerCase().includes(keyword) ||
        c.dataOwners.find(
          (dr) =>
            dr.userFirstName.toLowerCase().includes(keyword) ||
            dr.userLastName.toLowerCase().includes(keyword) ||
            dr.supplierCompanyname?.toLowerCase().includes(keyword)
        ) ||
        c.managers.find(
          (m) => m.userFirstName.toLowerCase().includes(keyword) || m.userLastName.toLowerCase().includes(keyword)
        ) ||
        c.managerInvitations.find(
          (iv) =>
            iv.managerFirstName.toLowerCase().includes(keyword) || iv.managerLastName.toLowerCase().includes(keyword)
        )
    )
  }

  openSignInSettings(signInSettings: TemplateRef<any>, type: string) {
    this.signInSettingsType = type
    this.openModal(signInSettings, 'modal-lg')
  }

  async closeSignInSettings() {
    this.closeModal()
    await this.renderNewData()
  }

  isGoogleEnabled(user: AbstractAffiliationFe) {
    let method = user.signInMethods.find((method) => method.provider == SignInMethodProviderFe.GOOGLE)
    return method && method.isEnabled
  }

  isMicrosoftEnabled(user: AbstractAffiliationFe) {
    let method = user.signInMethods.find((method) => method.provider == SignInMethodProviderFe.MICROSOFT)
    return method && method.isEnabled
  }

  isPasswordEnabled(user: AbstractAffiliationFe) {
    let method = user.signInMethods.find((method) => method.provider == SignInMethodProviderFe.PASSWORD)
    return method && method.isEnabled
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'add_manager':
        this.openModal(this.inviteManagerModal, 'modal-lg')
        break
      case 'add_company':
        this.openModal(this.inviteClientModal, 'modal-lg')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }
}
