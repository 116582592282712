import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractActivityFe } from './AbstractActivityFe'
import { ActivityTypeFe } from './ActivityTypeFe'

export class CreateRequestActivityFe extends AbstractActivityFe {
  noOfTotalRequests = 1
  icon = 'la la-envelope'

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.CREATE_REQUEST,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,
      requestId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.created_data_request`
    this.homePageLocaleKeyDO = `locale_key.pages.data_request.activity.created_data_request`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.request_created`
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): CreateRequestActivityFe {
    let activity = new CreateRequestActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      languageService
    )
    return activity
  }

  public homePageText(): string {
    return this.locale(this.homePageLocaleKey, { noOfTotalRequests: this.noOfTotalRequests })
  }
}
