<ng-container>
  <div class="main-modal-header modal-header py-0 px-3">
    <h6 class="modal-title text-white text-truncate bold">
      {{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.title') }}
    </h6>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close" *ngIf="!isLoading">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
</ng-container>
<div class="modal-body scroll-example modal-min-h" style="border-top: none">
  <div class="col-lg-12">
    <div class="row mt-3">
      <div class="container">
        <div
          class="progress-container mb-3 justify-content-center"
          [ngClass]="screenSize.isXSmallSize() ? 'd-flex flex-column' : ''"
        >
          <ng-container *ngIf="!screenSize.isXSmallSize()">
            <div class="text-center d-flex flox-row align-items-center">
              <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(1) }">
                <span *ngIf="!isCompleted(1)">1</span>
                <span *ngIf="isCompleted(1)" class="fs-4 la la-check text-white"></span>
              </div>
              <h6 class="me-2 mt-2">
                {{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.personal_information') }}
              </h6>
              <div>
                <span class="me-2 fs-4 la la-angle-right pt-1"></span>
              </div>
              <div class="text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(2) }">
                  <span *ngIf="!isCompleted(2)">2</span>
                  <span *ngIf="isCompleted(2)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="mt-2">
                  {{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.invitation_email') }}
                </h6>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="screenSize.isXSmallSize()">
            <div class="d-flex flex-row justify-content-center mb-1">
              <div class="d-block text-center">
                <div
                  class="col stepper text-center"
                  *ngFor="let step of steps; let i = index"
                  [class.active-stepper]="currentActive === i + 1 || step.completed"
                ></div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center mb-1">
              <h6 class="text-center my-auto">
                {{ steps[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})
              </h6>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-0 pt-0 mx-3" />
  <form [formGroup]="form1" *ngIf="activeFormNo == 1">
    <div class="modal-body">
      <div class="content clearfix">
        <div class="form-group">
          <div class="row px-1">
            <div class="col-12">
              <div class="d-flex align-items-center">
                <i class="la la-user me-2 fs-5 my-auto"></i>
                <h6 class="me-2 my-auto text-truncate bold">
                  {{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.personal_information') }}
                </h6>
                <i
                  class="fs-4 la la-info-circle info-popover-arrow"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  class="dropdown-menu arrow p-2 info-popover"
                  x-placement="bottom-start"
                  style="
                    position: absolute;
                    will-change: transform;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(213px, 17px, 0px);
                  "
                >
                  <p>
                    {{
                      locale(
                        'locale_key.pages.data_reporters.add_data_reporter_wizard.personal_information.description'
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-1 mt-3">
            <div class="col-12 mt-1">
              <div class="form-group">
                <label>{{ locale('locale_key.general.user_information.first_name') }}*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.general.user_information.first_name') }}"
                  required
                  formControlName="firstName"
                  [ngClass]="{
                    'is-invalid':
                      form1.get('firstName').errors && (form1.get('firstName').touched || form1.get('firstName').dirty)
                  }"
                />

                <div
                  *ngIf="
                    form1.get('firstName').errors && (form1.get('firstName').touched || form1.get('firstName').dirty)
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="form1.get('firstName').errors.required">
                    {{ firstNameMessage }}
                  </div>
                  <div *ngIf="form1.get('firstName').hasError('pattern')">
                    {{ firstNameMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-1">
              <div class="form-group">
                <label class="bold">{{ locale('locale_key.general.user_information.last_name') }}*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.general.user_information.last_name') }}"
                  required
                  formControlName="lastName"
                  [ngClass]="{
                    'is-invalid':
                      form1.get('lastName').errors && (form1.get('lastName').touched || form1.get('lastName').dirty)
                  }"
                />
                <div
                  *ngIf="form1.get('lastName').errors && (form1.get('lastName').touched || form1.get('lastName').dirty)"
                  class="invalid-feedback"
                >
                  <div *ngIf="form1.get('lastName').errors.required">
                    {{ lastNameMessage }}
                  </div>
                  <div *ngIf="form1.get('lastName').hasError('pattern')">
                    {{ lastNameMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-1">
              <div class="form-group">
                <label class="bold">
                  {{ locale('locale_key.pages.network.add_business_partner_wizard.primary-contact.email') }}*
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    locale('locale_key.pages.network.add_business_partner_wizard.primary-contact.email')
                  }}"
                  required
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid': form1.get('email').errors && (form1.get('email').touched || form1.get('email').dirty)
                  }"
                />
                <div
                  *ngIf="form1.get('email').errors && (form1.get('email').touched || form1.get('email').dirty)"
                  class="invalid-feedback"
                >
                  <div *ngIf="form1.get('email').errors.required">
                    {{ emailMessage }}
                  </div>
                  <div *ngIf="form1.get('email').hasError('pattern')">
                    {{ emailFormatMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-1">
              <div class="form-group">
                <label class="bold">
                  {{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.partner') }}*
                </label>

                <select
                  class="form-select form-control"
                  formControlName="companyId"
                  required
                  [ngClass]="{
                    'is-invalid': form1.get('companyId').errors && form1.get('companyId').touched
                  }"
                >
                  <option class="dropdown-header highlight" disabled>
                    <h4>{{ locale('locale_key.pages.datahub.data_hub_home.filter_text.internal_contacts') }}</h4>
                  </option>
                  <option [value]="internalCompanyId">{{ internalCompanyName }}</option>
                  <option class="dropdown-header highlight" disabled>
                    <h4>{{ locale('locale_key.pages.datahub.data_hub_home.filter_text.external_contacts') }}</h4>
                  </option>
                  <ng-container *ngFor="let company of companyNetwork; let i = index">
                    <option [value]="company.supplierCompanyId">{{ company.supplierCompanyname }}</option>
                  </ng-container>
                </select>
                <div *ngIf="form1.get('companyId').errors && form1.get('companyId').touched" class="invalid-feedback">
                  <div *ngIf="form1.get('companyId').errors.required">
                    {{ companyMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="form2" *ngIf="activeFormNo == 2">
    <div class="modal-body">
      <div class="content clearfix">
        <div class="form-group">
          <div class="row px-1">
            <div class="col-12">
              <div class="d-flex flex-row align-items-center">
                <i class="fs-3 la la-envelope-o me-1"></i>
                <h6 class="m-0">{{ locale('locale_key.pages.data_reporters.add_data_reporter_wizard.email') }}</h6>
                <i
                  class="fs-5 la la-info-circle m-1"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div class="dropdown-menu arrow p-2 info-popover">
                  <p>{{ locale('locale_key.pages.data.supplier.invite_partner.message') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-1 mt-3">
            <div class="col-12 mt-1 mb-5">
              <div class="form-group d-flex flex-row align-items-center">
                <div id="lang-dropdown" class="btn-group me-3 mb-1" style="width: 150px">
                  <button
                    class="form-select border border-secondary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <span *ngIf="selectedLang">
                      <i class="{{ selectedLang.icon }} me-2"></i>
                      {{ selectedLang.label }}
                    </span>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let language of languages">
                      <a
                        class="dropdown-item m-0 px-3"
                        style="color: var(--main-dark-color); cursor: pointer"
                        *ngIf="selectedLang?.code != language.code"
                        (click)="updateLanguage(language)"
                      >
                        <i class="{{ language.icon }} me-2"></i>
                        {{ language.label }}
                      </a>
                    </ng-container>
                  </div>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="includeEnglish"
                    (change)="onIncludeEnglishChange($event)"
                    [disabled]="disableEnglish"
                    [checked]="includeEnglish"
                  />
                  <label class="form-check-label ml-2" style="font-size: 12px">
                    {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3 p-3">
              <div class="form-group row">
                <div class="col-12">
                  <p>{{ locale('locale_key.pages.data.supplier.invite_partner.email_template.message') }}</p>
                  <button (click)="openModal()" class="text-decoration-underline bg-white border-0 p-0">
                    {{ locale('locale_key.pages.dashboard.email_template.edit.link') }}
                  </button>
                </div>
                <confirm-cancel-modal
                  (actionClicked)="navigateToEditTemplate()"
                  (cancelClicked)="onCancel()"
                  [isVisible]="showNavigateModal"
                  [headerText]="modalHeaderText"
                  [bodyText]="modalBodyText"
                  [cancelButtonText]="cancelButtonText"
                  [actionButtonText]="actionButtonText"
                ></confirm-cancel-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <ng-container *ngIf="!isLoading">
    <button type="button" class="btn border border-secondary custom-button" (click)="closeModal()">
      {{ locale('locale_key.general.buttons.cancel') }}
    </button>
    <button class="btn secondary-button custom-button" (click)="prev()" [hidden]="activeFormNo == 1">
      {{ locale('locale_key.general.buttons.previous') }}
    </button>
    <button
      class="btn btn-add text-white"
      [ngClass]="{ 'custom-button': activeFormNo == 1 }"
      [disabled]="!form1.valid"
      (click)="activeFormNo == 1 ? getLink() : addNewUser()"
    >
      {{
        activeFormNo == 2
          ? locale('locale_key.pages.data_reporters.add_data_reporter_wizard.button.add_contact')
          : locale('locale_key.general.buttons.next')
      }}
    </button>
  </ng-container>
  <div class="ball-pulse loader-primary" *ngIf="isLoading">
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
  </div>
</div>
