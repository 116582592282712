<table width="100%" cellspacing="0" cellpadding="0" border="0" style="padding-bottom: 15px">
  <tr>
    <td>
      <!-- Back button -->
      <table width="100%" cellspacing="0" cellpadding="0" border="0">
        <tr>
          <td style="padding: 20px 0">
            <button (click)="cancel()" class="btn border border-secondary border-2" style="border-radius: 0">
              <i class="fs-4 la la-arrow-left"></i>
              {{ locale('locale_key.pages.data_request.manage_group_modal.button.back') }}
            </button>
          </td>
        </tr>
      </table>
      <!-- Template header -->
      <table width="100%" cellspacing="0" cellpadding="0" border="0" style="background-color: #374659">
        <tr>
          <td style="padding: 15px; filter: brightness(120%)">
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td>
                  <i class="fs-3 {{ template.transitLanguage.icon }}"></i>
                </td>
                <td align="right">
                  <span
                    *ngIf="isTemplateEdited()"
                    class="p-1 m-2"
                    style="background-color: #e066ba; border-radius: 5px; color: white"
                  >
                    {{ locale('locale_key.pages.data_category.edited') }}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" border="0" style="margin-top: 15px">
        <tr>
          <td>
            <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
              <div style="background-color: #405369"></div>
              <div style="background-color: #405369"></div>
              <div style="background-color: #405369"></div>
            </div>
          </td>
        </tr>
      </table>

      <table
        width="100%"
        cellspacing="0"
        cellpadding="0"
        border="0"
        style="margin-top: 15px"
        *ngIf="!loadingInProgress"
      >
        <tr>
          <td>
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td>
                  <div class="col border w-fit-content sticky-tooltip">
                    <div *ngIf="inEditing" class="d-flex flex-row align-items-center bg-gray p-1">
                      <div class="flex-item m-1">
                        <select
                          id="fontFamily"
                          class="border-0 bg-white form-select"
                          [(ngModel)]="selectedFontFamily"
                          (change)="changeFontFamily()"
                        >
                          <option value="Arial, sans-serif">Arial</option>
                          <option value="Times New Roman, serif">Times New Roman</option>
                          <option value="Courier New, monospace">Courier New</option>
                          <option value="Verdana, sans-serif">Verdana</option>
                          <option value="Georgia, serif">Georgia</option>
                          <option value="Tahoma, sans-serif">Tahoma</option>
                          <option value="Comic Sans MS, cursive">Comic Sans MS</option>
                        </select>
                      </div>
                      <div class="flex-item m-1">
                        <select
                          id="fontSize"
                          class="border-0 bg-white form-select"
                          [(ngModel)]="selectedFontSize"
                          (change)="changeFontSize()"
                        >
                          <option value="12px">12 px</option>
                          <option value="14px">14 px</option>
                          <option value="16px">16 px</option>
                          <option value="18px">18 px</option>
                          <option value="20px">20 px</option>
                          <option value="22px">22 px</option>
                          <option value="24px">24 px</option>
                          <option value="32px">32 px</option>
                          <option value="36px">36 px</option>
                        </select>
                      </div>
                      <div class="flex-item m-1">
                        <button class="border-0"><span class="fs-4 la la-bold" (click)="toggleBold()"></span></button>
                      </div>
                      <div class="flex-item m-1">
                        <button class="border-0">
                          <span class="fs-4 la la-italic" (click)="toggleItalic()"></span>
                        </button>
                      </div>
                      <div class="flex-item m-1">
                        <button class="border-0">
                          <span class="fs-4 la la-underline" (click)="toggleUnderline()"></span>
                        </button>
                      </div>
                      <div class="flex-item m-1">
                        <button class="border-0">
                          <span class="fs-4 la la-align-center" (click)="alignCenter()"></span>
                        </button>
                      </div>
                      <div class="flex-item m-1 vertical-line">
                        <div></div>
                      </div>
                      <div class="flex-item m-1">
                        <button class="border-0"><span class="fs-4 la la-link" (click)="insertLink()"></span></button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" cellspacing="0" cellpadding="0" border="0" style="margin-top: 15px">
              <tr>
                <td align="right">
                  <button
                    *ngIf="!inProgress"
                    (click)="toggleEditing()"
                    class="btn border border-secondary border-2"
                    style="width: 200px; border-radius: 0"
                    [disabled]="diableEditButton"
                  >
                    <i class="fs-4 la la-edit"></i>
                    {{ buttonText }}
                  </button>
                </td>
              </tr>
              <tr>
                <td height="15"></td>
              </tr>
              <tr>
                <td align="right">
                  <button
                    (click)="openModal()"
                    class="btn border border-secondary border-2"
                    style="width: 200px; border-radius: 0"
                    [disabled]="isResetButtonDisabled()"
                  >
                    <i class="fs-4 la la-redo-alt"></i>
                    {{ locale('locale_key.pages.view_template.reset_button') }}
                  </button>
                  <confirm-cancel-modal
                    (actionClicked)="resetTemplate()"
                    (cancelClicked)="onCancel()"
                    [isVisible]="showModal"
                    [inProgress]="inProgress"
                    [headerText]="modalHeaderText"
                    [bodyText]="modalBodyText"
                    [cancelButtonText]="cancelButtonText"
                    [actionButtonText]="actionButtonText"
                  ></confirm-cancel-modal>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <!-- Template iframe -->
      <table width="100%" cellspacing="0" cellpadding="0" border="0" style="margin-top: 15px">
        <tr>
          <td>
            <div
              *ngIf="safeHtml"
              style="width: 100%; height: fit-content !important; overflow-y: scroll; overflow-x: hidden"
            >
              <iframe
                #iframeTemplate
                width="100%"
                height="fit-content !important"
                [srcdoc]="safeHtml"
                frameborder="0"
                style="overflow-y: scroll; overflow-x: hidden"
                (paste)="handlePaste($event)"
              ></iframe>
            </div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
