export class GlobalDatabaseFe {
  constructor(
    public id: string,
    public name: string,
    public releaseDate: Date | undefined,
    public uploadedInPlatformDate: Date,
    public publisher: string,
    public appliedGeography: string,
    public appliedTimeFrom: Date,
    public appliedTimeTo: Date,
    public license: string
  ) {}

  public static fromTransfer(transfer: any): GlobalDatabaseFe {
    let ef = new GlobalDatabaseFe(
      transfer.id,
      transfer.name,
      transfer.releaseDate ? new Date(transfer.releaseDate) : undefined,
      new Date(transfer.uploadedInPlatformDate),
      transfer.publisher,
      transfer.appliedGeography,
      new Date(transfer.appliedTimeFrom),
      new Date(transfer.appliedTimeTo),
      transfer.license
    )

    return ef
  }
}
