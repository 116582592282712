import { ActivityTypeFe } from '../ActivityTypeFe'
import { AbstractActivity_AddFe } from './AbstractActivity_AddFe'

export class SubmitMessageActivity_AddFe extends AbstractActivity_AddFe {
  managementCompanyId: string

  constructor(
    id: string,
    managementCompanyId: string,
    message: string | null,
    draft: boolean,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string
  ) {
    super(id, ActivityTypeFe.SUBMIT_MESSAGE, message, draft, requestTaskId, requestGroupId, requestGroupLabel)
    this.managementCompanyId = managementCompanyId
  }
}
