export class ActivityTypeFe {
  public static ACCEPT_SUBMISSION: string = 'ACCEPT_SUBMISSION'
  public static CLOSE_REQUEST: string = 'CLOSE_REQUEST'
  public static CREATE_REQUEST: string = 'CREATE_REQUEST'
  public static REJECT_SUBMISSION: string = 'REJECT_SUBMISSION'
  public static SUBMIT_ANSWERS: string = 'SUBMIT_ANSWERS'
  public static SUBMIT_MESSAGE: string = 'SUBMIT_MESSAGE'
  public static CONNECT_SECTIONS: string = 'CONNECT_SECTIONS'
  public static DISCONNECT_SECTIONS: string = 'DISCONNECT_SECTIONS'
}
