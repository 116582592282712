<div class="card main-card m-0 scroll">
  <div *ngIf="automatedFeed.loadInProgress">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="overflow: hidden" *ngIf="automatedFeed.dataGridService && !automatedFeed.loadInProgress">
    <div class="d-flex flex-row mx-0 px-0" style="overflow: hidden">
      <div class="card col-12 m-auto" style="overflow: hidden">
        <div class="card-head mx-0 px-0">
          <div class="card-header p-0 pt-1">
            <div class="btn-toolbar d-flex justify-content-between">
              <div class="d-flex flex-row p-0 flex-grow-1 m-0">
                <ul class="list-inline m-0 ps-3 d-flex flex-row flex-grow">
                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.duration.title') }}:</a>
                  <li class="list-inline-item m-0">
                    <a class="badge btn-info text-white">
                      <span class="m-0">{{ automatedFeed.timeFromString }}</span>
                    </a>
                  </li>
                  <a class="m-0">-</a>
                  <li class="list-inline-item m-0">
                    <a class="badge btn-info text-white">
                      <span class="m-0">{{ automatedFeed.timeToString }}</span>
                    </a>
                  </li>
                </ul>
                <ul class="list-inline m-0 ps-3">
                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.status.title') }}:</a>
                  <li class="list-inline-item m-0">
                    <a
                      class="badge text-white"
                      [ngStyle]="{ 'background-color': automatedFeed.statusColor() }"
                      style="color: white"
                    >
                      <i class="la {{ automatedFeed.statusIcon() }} margin-ft"></i>
                      <span class="m-0">{{ locale(automatedFeed.statusLocaleKey()) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header p-2" style="max-height: 81vh; overflow-y: auto">
          <file-upload-picker
            *ngIf="isDataExtractor"
            [destTableService]="toCopyFeedTableService"
            (dataExtractedEvent)="dataExtracted($event)"
            (closeEvent)="closeFileExtractor(automatedFeed)"
          ></file-upload-picker>
          <datagrid-table-editor
            [ngClass]="{ hidden: isDataExtractor }"
            [dataGrid]="automatedFeed.dataGridService"
          ></datagrid-table-editor>
        </div>
      </div>
    </div>
  </div>
</div>
