import { DateUtil } from 'src/app/utils/DateUtil'
import { AccessibleCompanyFe } from '../org/AccessibleCompanyFe'
import { AbstractSignInMethodFe } from '../signInMethod/AbstractSignInMethodFe'
import { SignInMethodConversionFe } from '../signInMethod/SignInMethodConversionFe'
import { AffiliationRoleFe } from './AffiliationRoleFe'

export class CustomerSuccessFe {
  userId: string
  userFirstName: string
  userLastName: string
  email: string
  status: string
  signInMethods: AbstractSignInMethodFe[]
  mfaEnabled: boolean

  affiliationRole: string
  accessibleCompanies: AccessibleCompanyFe[] = []
  joinedOn: Date
  deactivationDate: Date
  lastLoggedAt: Date
  showAllCompanies = false

  constructor(
    userId: string,
    userFirstName: string,
    userLastName: string,
    email: string,
    status: string,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    this.userId = userId
    this.userFirstName = userFirstName
    this.userLastName = userLastName
    this.email = email
    this.status = status
    this.affiliationRole = AffiliationRoleFe.CUSTOMER_SUCCESS
    this.signInMethods = signInMethods
    this.mfaEnabled = mfaEnabled
  }

  public static fromTransfer(transfer: any): CustomerSuccessFe {
    let signInMethods = transfer['signInMethods']?.map((signInMethod) =>
      SignInMethodConversionFe.fromTransfer(signInMethod)
    )
    let cs = new CustomerSuccessFe(
      transfer['userId'],
      transfer['userFirstName'],
      transfer['userLastName'],
      transfer['affiliationEmail'],
      transfer['affiliationStatus'],
      signInMethods,
      transfer['mfaEnabled']
    )
    return cs
  }

  getName() {
    return `${this.userFirstName} ${this.userLastName}`
  }

  setJoinedOn() {
    let minDate = new Date()
    this.accessibleCompanies.forEach((company) => {
      if (minDate.getTime() > company.accessReceivedAt.getTime()) {
        minDate = company.accessReceivedAt
      }
    })
    this.joinedOn = minDate
  }

  getJoinedOn() {
    return DateUtil.toString3(this.joinedOn)
  }

  setLastLoggedAt() {
    let maxDate = null
    this.accessibleCompanies.forEach((company) => {
      if (!maxDate || (company.lastLoggedAt && maxDate.getTime() < new Date(company.lastLoggedAt).getTime())) {
        maxDate = company.lastLoggedAt
      }
    })
    if (maxDate) {
      this.lastLoggedAt = maxDate
    }
  }

  getDeactivationDate() {
    if (this.deactivationDate) {
      let date = new Date(this.deactivationDate)
      return DateUtil.toString3(date)
    }
  }

  getLastLoggedAt() {
    if (this.lastLoggedAt) {
      return DateUtil.toString3(this.lastLoggedAt)
    }
  }
}
