import {
  Component,
  ViewChildren,
  QueryList,
  ElementRef,
  TemplateRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef
} from '@angular/core'
import { RouterFe } from 'src/app/route/RouterFe'
import { ChartBuilderServiceFeNew } from 'src/app/services/ChartBuilderServiceFeNew'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { AbstractDashboardComponent } from './abstract.dashboard.component'
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe'
import { DashboardSubjectFe } from 'src/app/model/subject/DashboardSubjectFe'
import { KpiSubjectFe } from 'src/app/model/subject/KpiSubjectFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { LoginUserFe } from 'src/app/model/org/LoginUserFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { ChartSettingTypeFe } from 'src/app/model/subject/ChartSettingSubjectFe'
import { jsPDF } from 'jspdf'
import { NgxCaptureService } from 'ngx-capture'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { HostBinding, HostListener } from '@angular/core'
import { SharedDashboardFe } from 'src/app/model/shared/SharedDashboardFe'
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { RecipientInfo_AddFe } from 'src/app/model/shared/add/RecipientInfo_AddFe'
import { SharedResourceAccessTypeFe } from 'src/app/model/shared/SharedResourceAccessTypeFe'
import { SharedDashboard_AddFe } from 'src/app/model/shared/add/SharedDashboard_AddFe'
import { RecipientInfoFe } from 'src/app/model/dashboard/RecipientInfoFe'
import { RecipientInfo_DeleteFe } from 'src/app/model/shared/edit/RecipientInfo_DeleteFe'
import { RecipientInfo_ModifyFe } from 'src/app/model/shared/edit/RecipientInfo_ModifyFe'
import { AffiliationRoleFe } from 'src/app/model/data-suppliers/company/AffiliationRoleFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { IdUtil } from 'src/app/utils/IdUtil'
import { ActionFe } from 'src/app/model/subject/ActionFe'
import { DragulaService } from 'ng2-dragula'
import { filter, Subject, Subscription, takeUntil } from 'rxjs'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew'
import { InsightFe } from 'src/app/model/insight/InsightFe'
import { Language } from 'src/app/utils/language/Language'
import { max } from 'lodash'
import { NavigationEnd, Router } from '@angular/router'
import { RoutesFe } from 'src/app/route/RoutesFe'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends AbstractDashboardComponent implements OnInit {
  @ViewChild('createDashboardModal', { static: true }) createDashboardModal: TemplateRef<any>
  @ViewChild('shareDashboardModal', { static: true }) shareDashboardModal: TemplateRef<any>
  @ViewChild('editDashboardModal', { static: true }) editDashboardModal: TemplateRef<any>
  @ViewChild('deleteDashboardModal', { static: true }) deleteDashboardModal: TemplateRef<any>
  @ViewChild('addKpiDashboardModal', { static: true }) addKpiDashboardModal: TemplateRef<any>
  @ViewChild('duplicateDashboardModal', { static: true }) duplicateDashboardModal: TemplateRef<any>
  @ViewChild('exportDashboard', { static: true }) exportDashboard: TemplateRef<any>
  private destroy$ = new Subject<void>()
  private originalChartSettings: Map<string, { width: number; height: number }> = new Map()

  hostClass: string

  @HostBinding('class')
  get classBinding() {
    return this.hostClass
  }
  @HostListener('window:resize')
  onResize() {
    this.updateContainerWidth()
    this.refreshAllCharts()
  }

  @ViewChild('chartsContainer') chartsContainer: ElementRef

  containerWidth: number

  myOptions = {
    placement: 'bottom',
    showDelay: 100,
    zIndex: 1000,
    positionFixed: true
  }
  slideOpen: boolean = false
  userInfo: LoginUserFe
  selectedDashboard: DashboardFe | SharedDashboardFe
  data: any = { name: this.selectDashboard.name }
  dashboards: DashboardFe[] = []
  sharedDashboards: SharedDashboardFe[] = []
  filteredContacts: ContactFe[] = []
  contacts: ContactFe[] = []
  filterText: string = ''
  editDOTable: boolean = false
  rightModalTitle = 'Chart settings'
  rightModalBody = true
  modalRef: BsModalRef
  insights: InsightFe[] = []
  dahboardKPIArray = []
  loadingCompanyData = undefined
  inProgress = false
  selectedChartWrapper: ChartWrapperFeNew
  @ViewChild('dashboardEl') dashboardEl!: ElementRef
  typeOfExport = 'img'
  recipientsForm = new FormGroup({
    existing: new FormArray([]),
    users: new FormArray([])
  })
  isUserASM = false
  companyNameMessage = this.locale('locale_key.general.validation_message.company_name_required')
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  firstNamePatternMessage = this.locale('locale_key.general.validation_message.invalid_first_name')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  lastNamePatternMessage = this.locale('locale_key.general.validation_message.invalid_last_name')
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  lowerCaseEmail = this.locale('locale_key.general.validation_message.invalid_email')
  recipientsMessage = this.locale('locale_key.general.validation_message.one_recipient_required')
  sameEmailMessage = this.locale('locale_key.general.validation_message.two_recipients_with_same_email')
  confirmShare = false
  subscriptions = new Subscription()
  url: string = ''
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_XLARGE
  initCacheInProgress: boolean
  menuCollapsed: boolean
  languages = this.languageService.availableLanguages
  newLangList: Language[] = []
  newIncludeEnList: boolean[] = []
  defaultEmailLang: Language
  activeLanguage: Language
  existingEmailLangs: Language[] = []
  lang: string
  private languageSubscription: Subscription

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.analytics.dashboard'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.create_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.create_dashboard'),
        icon: 'la la-plus',
        actionName: 'create_dashboard',
        visible: () => this.isUserASM
      },
      {
        shortLabel: this.locale('locale_key.pages.dashboard.toolbar.share'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.share_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.share_dashboard'),
        icon: 'la la-share',
        actionName: 'share_dashboard',
        visible: () => this.isUserASM && this.selectedDashboard && !this.isSelectedDashboardSharedDashboard()
      },
      {
        shortLabel: this.locale('locale_key.pages.kpi_library.toolbar.add'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.add_kpis'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.add_kpis'),
        icon: 'la la-plus',
        actionName: 'add_kpi',
        visible: () =>
          this.isUserASM && this.selectedDashboard && !this.isSelectedDashboardSharedDashboard() && !this.inProgress
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.edit'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.edit_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.edit_dashboard'),
        icon: 'la la-edit',
        actionName: 'edit_dashboard',
        visible: () =>
          this.isUserASM && this.selectedDashboard && !this.isSelectedDashboardSharedDashboard() && !this.inProgress
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.save'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.save_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.save_dashboard'),
        icon: 'la la-save',
        actionName: 'save_dashboard',
        visible: () =>
          this.isUserASM &&
          this.selectedDashboard &&
          !this.isSelectedDashboardSharedDashboard() &&
          !this.inProgress &&
          this.selectedDashboard['isDirty']
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.duplicate'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.duplicate_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.duplicate_dashboard'),
        icon: 'la la-copy',
        actionName: 'duplicate_dashboard',
        visible: () =>
          this.isUserASM && this.selectedDashboard && !this.isSelectedDashboardSharedDashboard() && !this.inProgress
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.export'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.export_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.export_dashboard'),
        icon: 'las la-download',
        actionName: 'export_dashboard',
        visible: () =>
          this.isUserASM &&
          this.selectedDashboard &&
          (!this.isSelectedDashboardSharedDashboard() || this.isExportAllowed()) &&
          !this.inProgress
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.delete'),
        longLabel: this.locale('locale_key.pages.dashboard.toolbar.delete_dashboard'),
        tooltip: this.locale('locale_key.pages.dashboard.toolbar.delete_dashboard'),
        icon: 'la la-trash',
        actionName: 'delete_dashboard',
        visible: () =>
          this.isUserASM && this.selectedDashboard && !this.isSelectedDashboardSharedDashboard() && !this.inProgress
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info',
        actionName: 'toggle_quick_tips',
        visible: () => true
      }
    ]
  ]

  constructor(
    backendService: RouterFe,
    chartBuilderService: ChartBuilderServiceFeNew,
    public stateService: StateServiceFe,
    modalService: BsModalService,
    private loginService: LoginServiceFe,
    private displayService: DisplayServiceFe,
    public captureService: NgxCaptureService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private dragulaService: DragulaService,
    private responsive: ResponsiveService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    super(backendService, chartBuilderService, stateService, modalService, captureService)
    // this.hostClass = window.innerWidth > 1808 ? 'col-4' : 'col-12' ;

    this.subscriptions.add(
      stateService.dashboardSubject.subscribe((subject: DashboardSubjectFe) => {
        if (subject.action == ActionFe.SELECT) {
          this.selectedDashboard = subject.obj
          if (this.selectedDashboard) {
            this.selectedDashboard.charts.forEach((chart) => {
              let kd = this.insights.find((kd) => kd.id == chart.kpiId)
              let wrapper = ChartWrapperFeNew.build(kd, chart.setting, this.stateService)
              chart.wrapper = wrapper
              chart.setting = wrapper.setting
              chart.setting.firstTimeDraw = true
            })
            this.chartWrappers = this.selectedDashboard.charts.map((chart) => chart.wrapper)
            this.refreshView(this.htmlCanvases)
          } else {
            this.chartWrappers = []
          }
        }
      })
    )

    this.subscriptions.add(
      stateService.insightSubject.subscribe((subject: KpiSubjectFe) => {
        this.chartWrappers = []
        this.loadCompanyData()
        this.refreshView(this.htmlCanvases)
      })
    )

    this.subscriptions.add(
      displayService.closeDetailsSubject.subscribe((close) => {
        this.removeSelectedClass()
      })
    )

    dragulaService.createGroup('CHARTS', {
      moves: (el, container, handle) => {
        return !handle.classList.contains('isResizable')
      }
    })

    this.subscriptions.add(
      dragulaService
        .dropModel('CHARTS')
        .subscribe(({ name, el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
          this.selectedDashboard.updateChartsOrdinal(sourceIndex, targetIndex)
          if (this.selectedDashboard instanceof DashboardFe) this.selectedDashboard.isDirty = true
        })
    )

    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
      this.updateContainerWidth()
      this.refreshAllCharts()
    })
    this.screenSize = responsive.currentScreenWidthSize

    this.stateService.insightCacheInvalidated.subscribe(async (insights) => {
      if (
        this.loginService.loginUser &&
        this.stateService.activeWorkspace.affiliationRole != AffiliationRoleFe.DATA_OWNER
      ) {
        this.isUserASM = true
        this.dashboards = await this.stateService.getDashboards(true)
        this.contacts = await this.stateService.getActiveContacts(true)
        this.filteredContacts = this.contacts
      }
      this.sharedDashboards = await this.stateService.getSharedDashboards(true)
      await this.loadCompanyData()
    })

    this.subscriptions.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.checkAndRefresh()
      })
    )
  }

  @ViewChildren('canvas', { read: ElementRef }) htmlCanvases: QueryList<ElementRef>

  async ngOnInit() {
    if (
      this.loginService.loginUser &&
      this.stateService.activeWorkspace.affiliationRole != AffiliationRoleFe.DATA_OWNER
    ) {
      this.isUserASM = true
      this.dashboards = await this.stateService.getDashboards()
      this.contacts = await this.stateService.getActiveContacts()
      this.filteredContacts = this.contacts
    }
    this.sharedDashboards = await this.stateService.getSharedDashboards()

    await super.init()

    this.subscriptions.add(
      this.displayService.chartSettingsChanged.subscribe((data) => {
        if (data.type == ChartSettingTypeFe.SETTINGS) {
          this.onChangedSetting(data.selectedChartIndex)
        } else if (data.type == ChartSettingTypeFe.DELETE) {
          this.deleteChart(data.selectedChartIndex)
        } else if (data.type == ChartSettingTypeFe.DOWNLOAD_XLSX) {
          this.downloadSheet(data.selectedChartIndex, 'xlsx')
        } else if (data.type == ChartSettingTypeFe.DOWNLOAD_CSV) {
          this.downloadSheet(data.selectedChartIndex, 'csv')
        } else if (data.type == ChartSettingTypeFe.DOWNLOAD_IMAGE) {
          this.downloadImage(data.selectedChartIndex)
        } else if (data.type == ChartSettingTypeFe.DOWNLOAD_PDF) {
          this.downloadPDF(data.selectedChartIndex)
        }
      })
    )

    if (this.selectedDashboard instanceof DashboardFe) this.selectedDashboard.isDirty = false

    if (this.dashboards.length == 0 && this.sharedDashboards.length == 0) {
      this.displayService.openTips()
    }

    // AutoScroll
    this.subscriptions.add(
      this.dragulaService.drag('CHARTS').subscribe((value) => {
        let container = this.dashboardEl.nativeElement
        const scrollStep = 15
        let scrollDirection = 0

        document.onmousemove = (e) => {
          if (e.clientY <= container.offsetTop) {
            scrollDirection = -1
            requestAnimationFrame(autoScroll)
          } else if (e.clientY >= container.offsetTop + container.offsetHeight) {
            scrollDirection = 1
            requestAnimationFrame(autoScroll)
          } else {
            scrollDirection = 0
          }
        }

        function autoScroll() {
          container.scrollTop += scrollStep * scrollDirection
          if (
            (scrollDirection < 0 && container.scrollTop > 0) ||
            (scrollDirection > 0 && container.scrollTop < container.scrollHeight - container.clientHeight)
          ) {
            requestAnimationFrame(autoScroll)
          }
        }
      })
    )

    this.subscriptions.add(
      this.dragulaService.dragend('CHARTS').subscribe((value) => {
        document.onmousemove = null
      })
    )

    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    let langCode: string = await this.getCompanyEmailLanguage()
    this.defaultEmailLang = this.languageService.getLanguageObject(langCode)

    this.updateContainerWidth()
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.initializeDashboard()
      })
  }

  ngOnDestroy() {
    this.dragulaService.destroy('CHARTS')
    this.subscriptions.unsubscribe()
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngAfterViewInit() {
    this.initializeDashboard()
  }

  private async initializeDashboard() {
    await this.loadCompanyData()
    this.updateContainerWidth()
    this.refreshAllCharts()
    this.cd.detectChanges()
  }

  private checkAndRefresh() {
    const currentUrl = window.location.href
    if (currentUrl.includes(RoutesFe.DASHBOARD.toString())) {
      // Check if we're on the dashboard page
      this.refreshComponent()
    }
    this.url = currentUrl
  }

  private refreshComponent() {
    this.loadCompanyData().then(() => {
      if (this.selectedDashboard) {
        this.selectedDashboard.charts.forEach((chart) => {
          let kd = this.insights.find((kd) => kd.id == chart.kpiId)
          let wrapper = ChartWrapperFeNew.build(kd, chart.setting, this.stateService)
          chart.wrapper = wrapper
          chart.setting = wrapper.setting
          chart.setting.firstTimeDraw = true
        })
        this.chartWrappers = this.selectedDashboard.charts.map((chart) => chart.wrapper)
      }
      this.refreshView(this.htmlCanvases)
      this.cd.detectChanges()
    })
  }

  updateContainerWidth() {
    this.containerWidth = this.chartsContainer.nativeElement.offsetWidth
  }

  getChartClass(wrapper: ChartWrapperFeNew): string {
    const baseClass = 'px-0 pe-2 mb-3'
    if (this.screenSize.isSmallerSize() || this.screenSize.isMediumSize()) {
      return `${baseClass} col-12` // Full width for small/medium screens
    }
    return `${baseClass} flex-grow-${wrapper.setting.width}`
  }

  getChartWidth(wrapper: ChartWrapperFeNew): number {
    // Store original dimensions if not stored
    if (!this.originalChartSettings.has(wrapper.tempId)) {
      this.originalChartSettings.set(wrapper.tempId, {
        width: wrapper.setting.width,
        height: wrapper.setting.height
      })
    }

    if (this.screenSize.isSmallerSize() || this.screenSize.isMediumSize()) {
      return this.containerWidth - 40 // Account for padding
    }

    // For large screens, use original width if it fits
    const original = this.originalChartSettings.get(wrapper.tempId)
    return Math.min(original.width, this.containerWidth - 20)
  }

  // Modify the getChartHeight method
  getChartHeight(wrapper: ChartWrapperFeNew): number {
    if (this.screenSize.isSmallerSize() || this.screenSize.isMediumSize()) {
      return Math.max(400, this.getChartWidth(wrapper) * 0.75) // Minimum height 400px
    }

    const original = this.originalChartSettings.get(wrapper.tempId)
    if (!original) return wrapper.setting.height

    const width = this.getChartWidth(wrapper)
    const aspectRatio = original.height / original.width
    return width * aspectRatio
  }

  refreshAllCharts() {
    this.chartWrappers.forEach((wrapper, index) => {
      this.refreshChart(wrapper, index)
    })
  }

  refreshChart(wrapper: ChartWrapperFeNew, index: number) {
    const newWidth = this.getChartWidth(wrapper)
    const newHeight = this.getChartHeight(wrapper)
    wrapper.setting.width = newWidth
    wrapper.setting.height = newHeight
    this.onChangedSetting(index)
  }

  chartResized(event, wrapper: ChartWrapperFeNew) {
    if (this.selectedDashboard instanceof DashboardFe) {
      if (!this.selectedDashboard.isDirty) {
        this.selectedDashboard.isDirty = !wrapper.setting.firstTimeDraw
      }
    }
    wrapper.setting.onResized(event)
    this.refreshChart(wrapper, this.chartWrappers.indexOf(wrapper))
  }

  onChangedSetting(index): void {
    super.onChangedSetting(index)
    this.selectedDashboard.charts[index].setting = this.chartWrappers[index].setting
    if (this.selectedDashboard instanceof DashboardFe) this.selectedDashboard.isDirty = true
  }

  async getCompanyEmailLanguage(): Promise<any> {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode ? langCode : 'en'
    } catch (err) {}
  }

  public async loadCompanyData(): Promise<void> {
    if (this.loadingCompanyData != true) {
      this.loadingCompanyData = true
      if (this.isUserASM) {
        let kpis = await this.stateService.getInsights()
        this.insights = [...kpis]
        this.selectedDashboard = await this.stateService.getSelectedDashbaord()
      }
      let sharedDashboardKpis = await this.stateService.getSharedDashboardInsights()
      this.insights.push(...sharedDashboardKpis)

      if (!this.selectedDashboard && this.sharedDashboards.length > 0) {
        this.selectedDashboard = this.sharedDashboards[0]
      }

      if (this.selectedDashboard) {
        this.selectedDashboard.charts.forEach((chart) => {
          let kd = this.insights.find((kd) => kd.id == chart.kpiId)
          let wrapper = ChartWrapperFeNew.build(kd, chart.setting, this.stateService)
          chart.wrapper = wrapper
          chart.setting = wrapper.setting
          chart.setting.firstTimeDraw = true
        })
        this.chartWrappers = this.selectedDashboard.charts.map((chart) => chart.wrapper)
      }
      this.loadingCompanyData = false
      this.refreshView(this.htmlCanvases)
    }
  }

  public openModal(modalTemplateRef: TemplateRef<any>, className: string = '') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: className
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open')
    }
  }

  async saveDashboard() {
    if (this.selectedDashboard instanceof DashboardFe) {
      this.inProgress = true
      await this.stateService.modifyDashboard(this.selectedDashboard)
      ;(this.selectedDashboard as DashboardFe).isDirty = false
      this.inProgress = false
    }
  }

  async deleteDashboard() {
    if (this.selectedDashboard instanceof DashboardFe) {
      this.inProgress = true
      let id = this.selectedDashboard.id
      if (this.selectedDashboard.sharedId) {
        await this.stateService.deleteSharedDashboard(this.selectedDashboard.sharedId)
      }
      await this.stateService.deleteDashboard(id)
      this.dashboards = this.dashboards.filter((d) => d.id != id)
      this.inProgress = false
      this.closeModal(true)
    }
  }

  refreshDashboard(dashboard: DashboardFe) {
    if (this.selectedDashboard instanceof DashboardFe && this.selectedDashboard.id == dashboard.id) {
      this.selectedDashboard.isDirty = true
      this.selectedDashboard.charts = dashboard.charts
      this.chartWrappers = dashboard.charts.map((c) => c.wrapper)
      this.refreshView(this.htmlCanvases)
    }
  }

  deleteChart(index: number) {
    if (this.selectedDashboard instanceof DashboardFe) {
      let wrapper = this.chartWrappers[index]
      this.chartWrappers.splice(index, 1)
      this.selectedDashboard.charts = this.selectedDashboard.charts.filter((c) => c.wrapper.tempId != wrapper.tempId)
      this.selectedDashboard.isDirty = true
      this.removeSelectedClass()
    }
  }

  onSelect(event: any) {
    if (event.target.checked) {
      this.dahboardKPIArray.push(event.target.id)
    } else {
      this.dahboardKPIArray.splice(this.dahboardKPIArray.indexOf(event.target.id), 1)
    }
  }

  onSelectRow(event: any) {
    if (event.target.checked) {
      event.currentTarget.className = 'bg-teal bg-lighten-4 p-25 kpiItems'
    } else {
      event.currentTarget.className = 'p-25 kpiItems'
    }
  }

  updateDetailsData(selectedChartIndex: number, selectedChartWrapper: ChartWrapperFeNew) {
    const chartElements = document.querySelectorAll('[id*="item-"]')
    Array.from(chartElements).forEach((el) => {
      el.children[0].children[0].classList.remove('selected-dash')
    })
    this.displayService.updateDetailsData({
      selectedChartIndex,
      dashboard: this.selectedDashboard,
      selectedChartWrapper
    })
    let chart = document.getElementById('item-' + selectedChartIndex)
    chart.children[0].children[0].classList.add('selected-dash')
  }

  //Remove 'selected-dash' class from all charts when app-details is closed
  removeSelectedClass() {
    const chartElements = document.querySelectorAll('[id*="item-"]')
    Array.from(chartElements).forEach((el) => {
      el.children[0].children[0].classList.remove('selected-dash')
    })
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  async exportDashobard() {
    this.inProgress = true
    let src = await this.captureService.getImage(this.dashboardEl.nativeElement, true).toPromise()

    if (this.typeOfExport == 'img') {
      let link = document.createElement('a')
      link.href = src
      link.download = this.selectedDashboard.name
      link.click()
    } else if (this.typeOfExport == 'layoutPDF') {
      var pdf = new jsPDF('p', 'mm', 'a4')
      const a4Size = { height: 300, width: 210 }
      let leftMargin = 10,
        topMargin = 20,
        rightMargin = 10,
        maxWidthDif = 100
      let height = this.dashboardEl.nativeElement.clientHeight
      let width = this.dashboardEl.nativeElement.clientWidth
      let ratio = height / width
      // if(height > a4Size.height - leftMargin || width > a4Size.width - leftMargin){
      if (height > width) {
        height = a4Size.height - topMargin
        width = height * (1 / ratio)
      } else if (width > height) {
        width = a4Size.width - leftMargin - rightMargin
        height = width * ratio
      }
      // }
      pdf.addImage(src, 'png', leftMargin, topMargin, width, height)
      pdf.save(`${this.selectedDashboard.name}.pdf`)
    } else {
      var pdf = new jsPDF()
      const a4Size = { height: 297, width: 210 }
      let leftMargin = 10,
        topMargin = 20,
        rightMargin = 10
      let indx = 0
      for await (let canvas of this.canvases) {
        if (indx != 0) {
          pdf.addPage()
        }
        let el = document.getElementById(`item-${indx}`)
        let img = await this.captureService.getImage(el, true).toPromise()
        let height = this.pixelTOmm(el.clientHeight)
        let width = this.pixelTOmm(el.clientWidth)
        let ratio = height / width

        let maxHeight = a4Size.height - topMargin
        let maxWidth = a4Size.width - leftMargin - rightMargin
        if (height > maxHeight) {
          height = maxHeight
          width = height * (1 / ratio)
        }
        if (width > maxWidth) {
          width = maxWidth
          height = width * ratio
        }

        pdf.addImage(img, leftMargin, topMargin, width, height)
        indx++
      }
      pdf.save(`${this.selectedDashboard.name}.pdf`)
    }

    this.closeModal(true)
    this.inProgress = false
  }

  public locale(str: string, kv: any = undefined): string {
    return this.languageService.getLocale(str, kv)
  }

  showMenu(id: string) {
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    menu.classList.toggle('show')
    if (menuBtn.id === 'btn-arrange') {
      menu.classList.toggle('hide')
    }
  }

  updateEmailLanguage(index: number, lang: Language) {
    if (this.newLangList.length > index) {
      this.newLangList[index] = lang
    } else {
      this.newLangList.push(lang)
    }
  }

  removeEmailLanguage(index: number) {
    this.newLangList.splice(index, 1)
  }

  removeIncludeEnglish(index: number) {
    this.newIncludeEnList.splice(index, 1)
  }

  getEmailLanguage(index: number): Language {
    if (this.newLangList[index].code === 'en') {
      this.newIncludeEnList[index] = false
    }
    return this.newLangList[index] || this.defaultEmailLang
  }

  getDisabledEnglish(index: number) {
    return this.newLangList[index]?.code === 'en'
  }

  getIncludeEnglish(index: number): boolean {
    return this.newIncludeEnList[index] || false
  }

  removeUserRecipient(index: number) {
    let formArray = this.recipientsForm.get('users') as FormArray
    formArray.removeAt(index)
    this.removeEmailLanguage(index)
    this.removeIncludeEnglish(index)
  }

  toggleNewCheckboxEn(index: number) {
    const formArray = this.recipientsForm.get('users') as FormArray
    const currentValue = formArray.at(index).value
    formArray.at(index).setValue(!currentValue)
  }

  addNewUserRecipient() {
    let recipient = new FormGroup({
      firstName: new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp)]),
      lastName: new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp)]),
      email: new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]),
      companyName: new FormControl(null, [Validators.required]),
      type: new FormControl(SharedResourceAccessTypeFe.VIEW_ONLY),
      isContact: new FormControl(false)
    })
    let formArray = this.recipientsForm.get('users') as FormArray
    formArray.insert(formArray.controls.length, recipient)
    this.newLangList.push(this.defaultEmailLang)
  }

  openSharedDashboardModal(template: TemplateRef<any>) {
    this.confirmShare = false
    if (this.selectedDashboard instanceof DashboardFe) {
      let existingArray = this.recipientsForm.get('existing') as FormArray
      existingArray.clear()
      this.selectedDashboard.recipients.forEach((r, index) => {
        let recipient = new FormGroup({
          firstName: new FormControl({ value: r.firstName, disabled: true }),
          lastName: new FormControl({ value: r.lastName, disabled: true }),
          email: new FormControl({ value: r.email, disabled: true }),
          companyName: new FormControl({ value: r.companyName, disabled: true }),
          type: new FormControl(r.accessType)
        })
        let formArray = this.recipientsForm.get('existing') as FormArray
        this.existingEmailLangs.push(this.languageService.getLanguageObject(r.emailLanguage))
        formArray.insert(index, recipient)
      })

      let newUserArray = this.recipientsForm.get('users') as FormArray
      newUserArray.clear()

      if (this.selectedDashboard.recipients.length == 0) {
        this.addNewUserRecipient()
      }
      this.openModal(template, 'modal-xl modal-extra-extra-wide')
    }
  }

  async shareDashboard() {
    let existing = this.recipientsForm.get('existing').value.length
    let newUsers = this.recipientsForm.get('users').value.length

    if (existing == 0 && newUsers == 0) {
      this.recipientsForm.setErrors({ noRecipients: true })
    }

    let updatedRecipients: RecipientInfo_ModifyFe[] = []
    let newRecipients: RecipientInfo_AddFe[] = []

    this.recipientsForm.get('existing')['controls'].forEach((FormGroup, index) => {
      let accessType = FormGroup.controls.type.value
      let recipient = (this.selectedDashboard as DashboardFe).recipients.find(
        (c) => c.email == FormGroup.controls.email.value
      )
      if (accessType != recipient.accessType) {
        let newR = new RecipientInfo_ModifyFe(
          recipient.email,
          recipient.companyName,
          accessType,
          this.getEmailLanguage(index).code,
          this.getIncludeEnglish(index)
        )
        updatedRecipients.push(newR)
      }
    })

    this.recipientsForm.get('users')['controls'].forEach((FormGroup, index) => {
      let accessType = FormGroup.controls.type.value

      let contact = this.contacts.find((c) => c.affiliationEmail == FormGroup.controls.email.value)
      let newR
      if (contact && FormGroup.controls.email.disabled) {
        newR = new RecipientInfo_AddFe(
          FormGroup.controls.email.value,
          FormGroup.controls.firstName.value,
          FormGroup.controls.lastName.value,
          FormGroup.controls.companyName.value,
          contact.affiliationId,
          contact.affiliationCompanyId,
          accessType,
          this.selectedDashboard.name,
          this.getEmailLanguage(index).code,
          this.getIncludeEnglish(index)
        )
      } else {
        newR = new RecipientInfo_AddFe(
          FormGroup.controls.email.value,
          FormGroup.controls.firstName.value,
          FormGroup.controls.lastName.value,
          FormGroup.controls.companyName.value,
          null,
          null,
          accessType,
          this.selectedDashboard.name,
          this.getEmailLanguage(index).code,
          this.getIncludeEnglish(index)
        )
      }
      newRecipients.push(newR)
    })

    newRecipients.forEach((r) => {
      let alreadyExists = !(this.selectedDashboard as DashboardFe).recipients.every((ur) => ur.email !== r.email)
      let emails = newRecipients.filter((nr) => nr.email == r.email)
      if (emails.length > 1 || alreadyExists) {
        this.recipientsForm.setErrors({ sameEmail: true })
      }
    })

    if (this.recipientsForm.invalid) {
      this.recipientsForm.markAllAsTouched()
      return
    }

    if (this.selectedDashboard instanceof DashboardFe) {
      try {
        this.inProgress = true
        let newSharedDashboard = new SharedDashboard_AddFe(this.selectedDashboard.id, newRecipients, IdUtil.next())
        if (this.selectedDashboard.sharedId) {
          const recipients = [...updatedRecipients, ...newRecipients]
          await this.stateService.updateSharedDashboardRecipients(recipients, this.selectedDashboard.sharedId)
        } else {
          await this.stateService.createNewSharedDashboard(newSharedDashboard)
        }
        this.inProgress = false
        this.closeModal(true)
      } catch (err) {
        this.inProgress = false
        this.closeModal(true)
      }
    }
  }

  async removeRecipient(recipient: RecipientInfoFe, index: number) {
    try {
      this.inProgress = true
      let r = new RecipientInfo_DeleteFe(recipient.email)
      await this.stateService.deleteSharedDashboardRecipient(r, recipient.objectSharedId)
      let formArray = this.recipientsForm.get('existing') as FormArray
      formArray.removeAt(index)
      this.existingEmailLangs.splice(index, 1)
      this.inProgress = false
    } catch (err) {
      this.inProgress = false
    }
  }

  async removeSharedDashboard() {
    if (this.selectedDashboard instanceof DashboardFe) {
      try {
        this.inProgress = true
        await this.stateService.deleteSharedDashboard(this.selectedDashboard.sharedId)
        this.selectedDashboard.confirmRemoval = false
        this.inProgress = false
        this.closeModal(true)
      } catch (err) {
        this.inProgress = false
      }
    }
  }

  filterRows() {
    let newRegEx = new RegExp(this.filterText, 'i')
    this.filteredContacts = this.contacts.filter(
      (dataOwner) =>
        dataOwner.userFirstName.match(newRegEx) ||
        dataOwner.userLastName.match(newRegEx) ||
        dataOwner.affiliationEmail.match(newRegEx) ||
        (dataOwner.supplierCompanyname
          ? dataOwner.supplierCompanyname.match(newRegEx)
          : this.stateService.activeWorkspace.companyName.match(newRegEx))
    )
  }

  selectAllDataOwners(event: Event) {
    if ((event.target as HTMLInputElement).checked) this.filteredContacts.forEach((d) => (d.isSelected = true))
    else this.filteredContacts.forEach((d) => (d.isSelected = false))
  }

  selectContacts() {
    let selectedContacts = this.contacts.filter((c) => c.isSelected)

    this.recipientsForm.get('existing')['controls'].forEach((FormGroup, index) => {
      selectedContacts = selectedContacts.filter((c) => c.affiliationEmail !== FormGroup.controls.email.value)
    })

    this.recipientsForm.get('users')['controls'].forEach((FormGroup, index) => {
      selectedContacts = selectedContacts.filter((c) => c.affiliationEmail !== FormGroup.controls.email.value)
    })

    selectedContacts.forEach((c) => {
      let recipient = new FormGroup({
        firstName: new FormControl({ value: c.userFirstName, disabled: true }),
        lastName: new FormControl({ value: c.userLastName, disabled: true }),
        email: new FormControl({ value: c.affiliationEmail, disabled: true }),
        companyName: new FormControl({
          value: c.supplierCompanyname ? c.supplierCompanyname : this.stateService.activeWorkspace.companyName,
          disabled: true
        }),
        type: new FormControl(SharedResourceAccessTypeFe.VIEW_ONLY)
      })

      let formArray = this.recipientsForm.get('users') as FormArray
      formArray.insert(formArray.controls.length, recipient)
      this.newLangList.push(this.defaultEmailLang)
    })

    this.disselectContacts()
  }

  disselectContacts() {
    this.contacts.forEach((c) => (c.isSelected = false))
    this.showUserList()
  }

  showUserList() {
    let selectBtn = document.getElementById('select')
    let userList = document.getElementById('user-list')
    selectBtn.classList.toggle('hide')
    userList.classList.toggle('hide')
  }

  selectDashboard(dashboard: DashboardFe) {
    this.stateService.selectDashboard(dashboard)
    this.displayService.closeDetails()
    this.originalChartSettings.clear()
  }

  selectSharedDashboard(shareddashboard: SharedDashboardFe) {
    this.stateService.selectDashboard(shareddashboard)
    this.displayService.closeDetails()
  }

  isSelectedDashboardSharedDashboard() {
    if (this.selectedDashboard instanceof SharedDashboardFe) return true
    return false
  }

  isExportAllowed() {
    if (this.selectedDashboard['accessType'] == SharedResourceAccessTypeFe.VIEW_AND_EXPORT) return true
    return false
  }

  onIncludeEnChange(index: number, isExist: boolean, event: any) {
    const checkedValue = (event.target as HTMLInputElement).checked
    this.newIncludeEnList[index] = checkedValue
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'create_dashboard':
        this.openModal(this.createDashboardModal, 'modal-md')
        break
      case 'edit_dashboard':
        this.openModal(this.editDashboardModal, 'modal-md')
        break
      case 'delete_dashboard':
        this.openModal(this.deleteDashboardModal, 'modal-md')
        break
      case 'share_dashboard':
        this.openSharedDashboardModal(this.shareDashboardModal)
        break
      case 'export_dashboard':
        this.openModal(this.exportDashboard, 'modal-md')
        break
      case 'add_kpi':
        this.openModal(this.addKpiDashboardModal, 'modal-md')
        break
      case 'save_dashboard':
        this.saveDashboard()
        break
      case 'duplicate_dashboard':
        this.openModal(this.duplicateDashboardModal, 'modal-md')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }
}
