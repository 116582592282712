import { first } from 'rxjs/operators'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChange,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormArray, FormGroup, FormControl, AbstractControl } from '@angular/forms'
import * as UUID from 'uuid'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { ArrowDivDirective } from './directive/arrow.directive'
import { KeyBoardService } from './service/keyboard.service'
import { ModifyColumnModalComponent } from './modal/modifyColumnModal/modifyColumnModal.component'
import { DataService } from './service/data.service'
import { ImportedDataService } from './service/ImportDataService'
import { Subject } from 'rxjs'
import { DataGridTableData } from './model/DataGridTableData'
import { DataGridRow } from './model/DataGridRow'
import { DataGridStatus } from './model/DataGridStatus'
import { DataGridColumnType } from './model/DataGridColumnType'
import { DataGridColumnSchema } from './model/DataGridColumnSchema'
import { DataGridColumnMode } from './model/DataGridColumnMode'
import { DataGridObserverInterface } from './service/DataGridObserverInterface'
import { DataGridServiceInterface } from './service/DataGridServiceInterface'
import { MultiEntityDataGridIntegrationService } from './service/MultiEntityDataGridIntegrationService'
import { DatePipe } from '@angular/common'
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'
import { RowInRefTableHandlerModalComponent } from './modal/rowInRefTableHandlerModal/modal.component'
import { NestedParentHandlerModalComponent } from './modal/nestedParentHandlerModal/modal.component'
import { DataGridTableMode } from './model/DataGridTableMode'
import { ColumnType } from './model/ColumnType'
import { CollapseDirective } from 'ngx-bootstrap/collapse'
import { DragSelectClasses, SelectedData } from './directive/dragSelect.directive'
import { ActionFe, ColumnModifiedSubjectFe } from './model/ColumnModifiedSubjectFe'
import { groupUnitsByMeasurementTypeAndSystem } from '../../unit-systems/model/utils'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { SourceFe } from 'src/app/model/schema/SourceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { UnitFe } from '../../unit-systems/model/UnitFe'
import { UnitsByMeasurementType } from '../../unit-systems/model/UnitsByMeasurementType'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { EmissionFactorConversionFe } from 'src/app/model/emissions/EmissionFactorConversionFe'
import { StageTableDataGridIntergationService } from '../../data/StageTableDataGridIntergationService'
import _ from 'lodash'
import { DateUtil } from 'src/app/utils/DateUtil'
import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe'
import { PredefinedEmissionFactorOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedEmissionFactorOptionFe'
import { PredefinedNumberOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedNumberOptionFe'
import { PredefinedTextOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedTextOptionFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { NoUnit } from '../../unit-systems/unit-selector/unit-selector.component'
import { QuestionNumberFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe'
import { QuestionDateFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDateFe'
import { QuestionEmissionFactorFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe'
import { UnitUtilFe } from 'src/app/utils/UnitUtilFe'
import { PopoverConfig, PopoverDirective } from 'ngx-bootstrap/popover'
import { DATE_FORMAT } from 'src/app/utils/ConstantUtil'

@Component({
  selector: 'datagrid-table-editor',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html',
  providers: [PopoverConfig]
})
export class DataGridTableComponent
  extends AbstractLanguageComponent
  implements OnInit, OnChanges, DataGridObserverInterface, AfterViewInit
{
  isLoading = false
  rowArray = new FormArray([])
  colAray = new FormArray([])
  dataSource = this.rowArray.controls
  colSource = this.colAray.controls
  currentRow = -1
  currentCol = -1
  noUnitEnum = NoUnit

  // for editing cell when in focus
  previousCell = -1
  currentCell = -1
  oldWidthCol: string[] = []
  // for dataType
  colDataTypes: ColumnType[] = []
  isSaved = true
  isValueChanged = false
  showCalculation = false
  pageNumbers: number[] = []
  table: DataGridTableData //diff from mainTableData is it doesnot store rowIds in row values for refCols, stores exact values

  // @Input() multi
  @Input() dataGrid!: DataGridServiceInterface
  @ViewChildren(ArrowDivDirective) inputs!: QueryList<ArrowDivDirective>
  @ViewChild('tableComponent') tableComponent!: ElementRef<HTMLDivElement>

  deletedRows: { rowDetails: DataGridRow; index: number }[] = []
  addedRows: DataGridRow[] = []
  rowNo = -1
  wasInside = false
  pageCount = 1
  currentItems = 0
  selectedIds: string[] = []
  extractedData: SelectedData[] = []
  extractedColumnNos: number[] = []

  isColumnHasValidValues: boolean[] = []
  isNewTable = false
  exportIt = new Subject<boolean>()
  unitArray: { [key: string]: { [key: string]: string } } = {} // {rowId: {colNo: unitSymbol}}
  emissionFactorArray: { [key: string]: { [key: string]: AbstractEmissionFactorFe } } = {} // {rowId: {colNo: AbstractEmissionFactorFe}}

  refData: {
    [key: string]: { refColNo: number; refKey: string; newColNo: number }[]
  } = {} //stores refTables details of all tables(nested table included)
  lengthOfDataSchema: { [key: string]: number } = {} //stores no of non-ref col for each table
  parentDetails: { [key: string]: number } = {} //stores no of all non-ref cols of reftable(nested is included) that each table has
  immediateChildDetails: { [key: string]: string } = {} //stores key of immediate child table of each ref table
  childDetails: { [key: string]: { colNo: number[]; children: string[] } } = {}
  refTables: DataGridTableData[] = [] //stores all(nested is included) reftables in order
  refService = new MultiEntityDataGridIntegrationService()
  rowDetails: { [key: string]: { [key: string]: DataGridRow | null } } = {}
  //rowDetails ={rowId : {refTableKey:refTableRow }}
  updatedRefRows: { [key: string]: DataGridRow[] } = {}
  rowRefIds: { [key: string]: string[] }[] = []
  totalRefCols: number = 0 //stores total no of refrence columns

  mainSchema: DataGridColumnSchema[] = [] //stores dataSchema of mainTable and all refTables(nested included)

  selectedCurrentIndex: number
  selectedColumnForPredefinedOption: DataGridColumnSchema // store column information while adding pre defined options
  selectedColumnTypeForPredefinedOption: string // type is different from DataGridColumnSchema type

  mainTableData!: DataGridTableData //store main table data
  @Input() isModal: boolean = false

  //for table dropdown
  isTableDropdownOpen = false
  @Input() isInsideTableDropdown = false
  @Input() isInsideRefRowHandlerModal = false
  tableDropdownRef: BsDropdownDirective | null = null
  filterText = new FormControl(null)
  filterWidth = '140px'
  isRefRowInMainTableEmpty = false
  firstRowBtnIsHovered = false

  unlistenClick!: () => void
  inProgress = false
  noOfMinRows = 5
  rightClickDropdownRef: BsDropdownDirective | null = null
  isDataTypeHeaderCollapsed = false
  selectedColumn: number | null = null
  selectedRow: number | null = null
  isADblClick = false
  isCopyingCuttingFromCell = false
  isCopyingCuttingRowColumn = false
  isCutting = false
  startSelectionForCopying = false
  headlineRowsIndex: number[] = [0]
  diselectedColumnIndex: number[] = []
  selectedColumnIndex: number[] = []
  diselectedRowIndex: number[] = []
  selectedRowIndex: number[] = []
  maxRowsToLoad = 50
  cellToBeCopied: HTMLInputElement | null = null

  mappedToCols: { col: DataGridColumnSchema; mappedColIndex: number }[] = []
  unitsForMappedToCols = new Map() //<number, string> : mappedToColIndex, unitSymbol
  hiddenColumns: number[] = []
  ariaExpanded: boolean = false

  units: UnitFe[] = []
  customUnits: UnitFe[] = []
  unitsByMeasurementType: UnitsByMeasurementType[] = []
  showTable: any

  isCustomOptionAdded: boolean = false
  tempPredefinedOptions = []
  tempUserQuestion: string
  tempUserQuestionDesccription: string
  dropdownStyles: { [key: string]: string } = {}
  dateFormat = DATE_FORMAT

  @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>

  constructor(
    private keyboardService: KeyBoardService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private importedDataService: ImportedDataService,
    private renderer: Renderer2,
    private ngZone: NgZone,
    public languageService: LanguageService,
    private stateService: StateServiceFe,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private displayService: DisplayServiceFe,
    private popoverConfig: PopoverConfig
  ) {
    super(languageService)
    ;[]
    this.table = new DataGridTableData()
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
    this.popoverConfig.container = 'body'
    this.popoverConfig.placement = 'auto'
  }

  async ngOnInit(): Promise<void> {
    this.rowCount = this.table.rowCount
    await this.loadUnits()

    this.keyboardService.keyBoard.subscribe((res) => {
      this.move(res)
    })

    if (!this.isModeExportExcelTableData()) {
      this.dataService.columnModified.subscribe((data: ColumnModifiedSubjectFe) => {
        if (data.action == ActionFe.ADD) {
          let details = {
            name: data.obj.label!,
            type: data.obj.type,
            unit: ''
          }
          this.addColumn({ ...details }, this.currentCol + 1)
          this.dataGrid.markDataModified(true, this.checkDataValidity())
          this.isSaved = false
          this.setPaginationWidth(10)
        } else if (data.action == ActionFe.EDIT) {
          this.updateColumn(data.obj.label!)
        }
      })
    }

    if (this.isModeSelectRefTableData()) {
      this.isRefRowInMainTableEmpty = this.dataService.detailsOfRowToBeHandled.allRowIds.length == 0
    }

    this.unlistenClick = this.renderer.listen('document', 'click', (event) => {
      if (!this.wasInside) {
        if (this.isModeEditTableData() || this.isModeEditRefTableData()) {
          this.removeCellFocus(this.currentCol, this.currentRow)

          if (this.colDataTypes[this.currentCol]) {
            if (
              this.colDataTypes[this.currentCol].generic === 'number' &&
              this.colDataTypes[this.currentCol].original === DataGridColumnType.MEASUREMENT
            ) {
              document.getElementById(`col${this.currentCol}row${this.currentRow}number`)?.classList.remove('invisible')
              if (this.previousCell !== this.currentCell) {
                document.getElementById(`col${this.currentCol}row${this.currentRow}`)?.classList.remove('show')
              }
            } else if (this.colDataTypes[this.currentCol].generic === 'date') {
              const el = <HTMLInputElement>document.getElementById(`col${this.currentCol}row${this.currentRow}value`)
              el.readOnly = true
            }
          }

          this.disselectRowColumn()
          this.unselectCellsForCopying()
        }
      } else if (!this.isADblClick && !this.isCopyingCuttingRowColumn) {
        this.disselectRowColumn()
      }
      if (!this.isCopyingCuttingFromCell) {
        this.disselectCellToBeCopied()
      }
      this.isCopyingCuttingFromCell = false
      this.startSelectionForCopying = false
      this.isCopyingCuttingRowColumn = false
      this.wasInside = false
    })

    this.dataService.updatedRowInfo$.subscribe(() => {
      this.updatedRefRows = this.dataService.updatedRowInfo
      this.dataGrid.markDataModified(true, this.checkDataValidity())
      this.isSaved = false
    })

    this.emitRowCount()
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  async ngOnChanges(changes: { [name: string]: SimpleChange }): Promise<void> {
    if (changes.dataGrid && changes.dataGrid.previousValue !== changes.dataGrid.currentValue) {
      await this.renderNewDataTable()
      // if(!this.dataGrid.getMode().includes('multi'))
      this.dataGrid.registerObserver(this)
    }
    if (this.isModeExportExcelTableData()) {
      this.importedDataService.mapToTaxonomy.subscribe(async (map) => await this.setMappingDetails())
    }
  }

  ngAfterViewInit() {
    if (!this.isNewTable) {
      this.setPaginationWidth(10)
    }

    if (this.isModeSelectRefTableData()) {
      const rowWidth = this.tableComponent.nativeElement.getElementsByClassName('table-row')[0]?.clientWidth
      const containerWidth = this.tableComponent.nativeElement.getElementsByClassName('tableContainer')[0]?.clientWidth
      const width = Math.min(rowWidth, containerWidth)
      const filter = this.tableComponent.nativeElement.querySelector(`#filter`)
      if (filter) {
        ;(filter as HTMLElement).style.width = `${width - 30}px`
      }
      // this.filterWidth = `${width - 30}px`;
    }
  }

  getModifiedTableData(): DataGridTableData {
    let updatedData: DataGridTableData = new DataGridTableData()
    updatedData.name = this.table.name
    updatedData.ordered = this.table.ordered
    updatedData.rowCount = this.table.rowCount
    updatedData.pageNumber = this.table.pageNumber
    updatedData.pageSize = this.table.pageSize
    updatedData.key = this.table.key

    //refIds for all rows
    const allRowOriginalValues: DataGridRow[] = []

    // updating rows data with new data if changed
    this.table.rows.map((x, index) => {
      const values: any[] =
        this.refTables.length > 0 && x.status != DataGridStatus.ADDED
          ? this.mainTableData.rows.find((row) => row.rowId == x.rowId)?.values!
          : Array(this.mainTableData.dataSchema.length + this.mainTableData.referenceSchema.length)
      const row = {
        rowId: x.rowId,
        status: x.status,
        values,
        ordinal: index + 1
      }
      updatedData.rows.push(row)
      allRowOriginalValues.push({ ...row })

      if (x.status === DataGridStatus.MODIFIED || x.status === DataGridStatus.ADDED) {
        const newData: any[] = []
        let indexInAddedRows = this.addedRows.findIndex((y) => y.rowId == x.rowId)
        this.mainTableData.dataSchema.forEach((col, colNo) => {
          if (this.isMeasurementType(colNo)) {
            let val = this.rowArray.value[indexInAddedRows]['col' + colNo]
            if (typeof val == 'string') val = val ? val.trim() : val
            newData.push({
              quantity: val,
              unit: this.getUnit(colNo, x.rowId)
            })
          } else if (this.isEFType(colNo)) {
            newData.push(this.getEmissionFactor(colNo, x.rowId))
          } else if (this.colDataTypes[colNo].original != DataGridColumnType.MEASUREMENT) {
            newData.push(this.rowArray.value[indexInAddedRows]['col' + colNo])
          }
        })
        updatedData.rows[index].values = [...newData, ...values.slice(this.mainTableData.dataSchema.length)]
      }
      if (x.status === DataGridStatus.ADDED) {
        allRowOriginalValues[index].values = []
      }
    })

    updatedData.rows.forEach((row, rowNo) => {
      if (row.status == DataGridStatus.ADDED || row.status == DataGridStatus.MODIFIED) {
        this.mainTableData.referenceSchema.forEach((refCol, colNo) => {
          const refTableKey = refCol.referenceKey
          //if that reftable value is changed then push updated rowId
          if (this.rowDetails[row.rowId!] && this.rowDetails[row.rowId!][refTableKey]) {
            row.values[this.mainTableData.dataSchema.length + colNo] = this.rowDetails[row.rowId!][refTableKey]?.rowId
          }
        })
        // updatedData.rows[rowNo].values = rowValue;
      }
    })

    //updating position of values
    updatedData.rows.forEach((row) => {
      const valuesOfDataSchemaCols = row.values.splice(0, this.table.dataSchema.length)
      row.values.push(...valuesOfDataSchemaCols)
    })

    //updating labels of columns
    this.table.dataSchema.forEach((col, colNo) => {
      col.label = this.colAray.value[colNo]['col' + colNo]
    })

    //updating width of columns
    let headers = document.getElementsByClassName('colData')
    Array.from(headers).forEach((col, i) => {
      if (col.classList.contains('resized')) {
        this.table.dataSchema![i].width = col.clientWidth + 'px'
      }
    })

    updatedData.dataSchema = this.table.dataSchema
    updatedData.referenceSchema = this.table.referenceSchema

    this.setPaginationWidth(10)

    return updatedData
  }

  getModifiedReferenceTables(): DataGridTableData[] {
    const updatedRefTables: DataGridTableData[] = []

    this.refTables.forEach((table, index) => {
      updatedRefTables.push(this.createADeepCopyOfTable(table))
      updatedRefTables[index].rows.forEach((row) => {
        const valuesOfDataSchemaCols = row.values.splice(0, table.dataSchema.length)
        row.values.push(...valuesOfDataSchemaCols)
      })
    })
    return updatedRefTables
  }

  renderNewDataTable(): void {
    this.inProgress = true
    this.dataGrid.markDataModified(false, true)
    this.isSaved = true
    this.isValueChanged = false
    this.deletedRows = []
    this.refTables = []
    this.rowRefIds = []
    this.totalRefCols = 0
    this.rowDetails = {}
    this.noOfMinRows = this.dataGrid.getNoOfMinRows()
    this.populateTable()
    this.addColInArray()
    this.addRowsInArray()
    this.populateUnitArray()
    this.populateEmissionFactorArray()

    this.currentItems =
      this.table.pageNumber && this.table.pageSize ? (this.table.pageNumber - 1) * this.table.pageSize : 0
    this.changePages()
    this.dataGrid.markDataModified(false, this.checkDataValidity())
    this.inProgress = false
  }

  changePages(): void {
    let last: number = this.pageNumbers.slice(-1)[0] || 0
    let first: number = this.pageNumbers.slice(0)[0] || 0
    const currentPage = this.table.pageNumber

    if (this.pageNumbers.length === 0) {
      last = 0
    } else if (this.pageNumbers.length == 10) {
      if (last == currentPage - 1 && last != this.pageCount) {
        const upperLimit = this.pageCount - last >= 10 ? 10 : this.pageCount - last
        this.pageNumbers.splice(0, upperLimit)
      } else if (first == currentPage + 1 && first != 1) {
        this.pageNumbers = []
        last = first - 11 >= 0 ? first - 11 : 0
      } else if (this.pageCount == currentPage || currentPage == 1) {
        this.pageNumbers = []
        last = currentPage == 1 ? 0 : currentPage - 10
      }
    }

    for (let i = last; i < this.pageCount && this.pageNumbers.length < 10; i++) {
      this.pageNumbers.push(i + 1)
    }

    if (last > this.pageCount) {
      this.pageNumbers.splice(this.pageCount)
    }
  }

  populateUnitArray(): void {
    this.unitArray = {}

    this.table.rows.forEach((x, i) => {
      this.unitArray[x.rowId] = {}
      x.values.forEach((val, j) => {
        if (this.isMeasurementType(j)) {
          this.unitArray[x.rowId]['col' + j] = val?.unit
        }
      })
    })
  }

  populateEmissionFactorArray(): void {
    this.emissionFactorArray = {}

    this.table.rows.forEach((x, i) => {
      this.emissionFactorArray[x.rowId] = {}
      x.values.forEach((val, j) => {
        if (this.isEFType(j)) {
          this.emissionFactorArray[x.rowId]['col' + j] = val ? EmissionFactorConversionFe.fromTransfer(val) : val
        }

        if (this.isPreDeterminedEFType(j) && !this.isMultipleEFType(j)) {
          try {
            const ef = this.getPreDeterminedEF({ col: j })
            this.emissionFactorArray[x.rowId]['col' + j] = ef
          } catch (err) {}
        }
      })
    })
  }

  populateTable(): void {
    //creating a deeper copy
    this.mainTableData = this.createADeepCopyOfTable(this.dataGrid.getMainTableData())

    this.table = new DataGridTableData()
    this.table.name = this.mainTableData.name
    this.table.key = this.mainTableData.key

    //adding Ref Columns Related Info
    if (this.dataGrid.getReferenceTables()) {
      let refCols: { refColNo: number; refKey: string; newColNo: number }[] = []
      this.parentDetails[this.table.key!] = 0

      this.mainTableData.referenceSchema?.forEach((refCol, index) => {
        this.addInfoRelatedRefTables(refCol.referenceKey, index)
        const x = this.dataGrid.getReferenceTables().find((x) => x.key == refCol.referenceKey)!
        refCols.push({
          refColNo: index,
          refKey: refCol.referenceKey,
          newColNo: index
        })
        this.parentDetails[this.table.key!] += this.parentDetails[refCol.referenceKey!] + x.dataSchema.length
      })

      this.lengthOfDataSchema[this.table.key!] = this.mainTableData.dataSchema.length
      this.refData[this.mainTableData.key!] = refCols
    }

    //adding Source Columns
    let sourceCols: DataGridColumnSchema[] = []
    if (this.dataGrid.getSourceColumns?.()) {
      this.mainTableData.dataSchema = [...this.dataGrid.getSourceColumns(), ...this.mainTableData.dataSchema]
    }
    this.mainSchema = [...this.mainTableData.dataSchema]
    if (this.refTables.length > 0) {
      //adding dataSchema of refTables to mainSchema
      this.refTables.forEach((table) => {
        table.dataSchema?.forEach((element) => {
          this.mainSchema?.push(element)
        })
      })
    }

    //updating width of cols
    if (this.oldWidthCol.length > 0 && this.table.dataSchema.length > 0) {
      this.oldWidthCol.forEach((width, i) => {
        if (this.table.dataSchema[i + this.totalRefCols]) {
          this.table.dataSchema[i + this.totalRefCols].width = width
        }
      })
    }

    this.table.dataSchema = this.mainTableData.dataSchema
    this.table.referenceSchema = this.mainTableData.referenceSchema

    if (this.mainTableData.referenceSchema.length > 0) {
      //updating indexes of values of dataSchema and refSchema , before : ['rowId','rowId2','data1','data2'] , after:['data1','data2','rowId1','rowId2'];
      this.mainTableData.rows.forEach((row, i) => {
        const valuesOfRefSchemaCols = row.values.splice(0, this.mainTableData.referenceSchema.length)
        row.values.push(...valuesOfRefSchemaCols)
      })

      this.refTables.forEach((table) => {
        table.rows.forEach((row) => {
          const valuesOfRefSchemaCols = row.values.splice(0, table.referenceSchema.length)
          row.values.push(...valuesOfRefSchemaCols)
        })
      })
    }

    this.mainTableData.rows.map((x) => {
      let sourceValues = this.dataGrid.getSourceRowValue?.(x) ? this.dataGrid.getSourceRowValue?.(x) : []
      this.table.rows.push({
        rowId: x.rowId,
        status: x.status,
        values: [...sourceValues, ...x.values.slice(0, this.mainTableData.dataSchema.length)],
        ordinal: x.ordinal
      })
      this.rowRefIds.push({ [x.rowId!]: [] })
    })

    //adding refRows to table
    if (this.refTables.length > 0) {
      this.addRefRows()
    }

    this.refData[this.table.key!].reverse()

    this.table.ordered = this.mainTableData.ordered
    this.table.rowCount = this.mainTableData.rowCount
    this.table.pageNumber = this.mainTableData.pageNumber
    this.table.pageSize = this.mainTableData.pageSize
    this.pageCount = Math.ceil((this.table.rowCount as number) / this.table.pageSize)
    this.isNewTable = this.table.rowCount === 0
    this.colDataTypes = []
  }

  sourceColsLength() {
    if (this.dataGrid.getSourceColumns?.()) {
      return this.dataGrid.getSourceColumns?.().length
    }
    return 0
  }

  addInfoRelatedRefTables(key: string, tableNo: number = 0) {
    let referenceTables = this.dataGrid.getReferenceTables()
    const reftable = referenceTables.find((table) => table.key == key)!

    let refCols: { refColNo: number; refKey: string; newColNo: number }[] = []
    reftable.referenceSchema?.forEach((element, index) => {
      const key = element.referenceKey
      refCols.push({ refColNo: index, refKey: key, newColNo: index })
    })
    this.lengthOfDataSchema[reftable.key!] = reftable.dataSchema.length
    this.totalRefCols += reftable.dataSchema.length
    this.refData[reftable.key!] = refCols
    this.refTables.push(this.createADeepCopyOfTable(reftable))
    this.childDetails[key] ? null : (this.childDetails[key] = { colNo: [], children: [] })

    if (reftable.referenceSchema.length > 0) {
      let insideRefCols = 0
      reftable.referenceSchema.forEach((col) => {
        this.immediateChildDetails[col.referenceKey] = key

        this.childDetails[col.referenceKey] = {
          colNo: [...this.childDetails[key].colNo],
          children: [...this.childDetails[key].children]
        }
        let offset = this.childDetails[key].colNo[this.childDetails[key].colNo.length - 1]
          ? this.childDetails[key].colNo[this.childDetails[key].colNo.length - 1] + 1
          : this.mainTableData.dataSchema.length + tableNo //last element

        this.childDetails[col.referenceKey].colNo.push(offset)
        this.childDetails[col.referenceKey].children.push(reftable.key!)

        this.addInfoRelatedRefTables(col.referenceKey)
        const x = this.dataGrid.getReferenceTables().find((table) => table.key == col.referenceKey)!
        insideRefCols += this.parentDetails[col.referenceKey] + x.dataSchema.length
      })
      this.parentDetails[key] = insideRefCols
    }

    this.parentDetails[key] ? null : (this.parentDetails[key] = 0)
  }

  createADeepCopyOfTable(table: DataGridTableData) {
    const copiedTable = new DataGridTableData()
    copiedTable.name = table.name
    copiedTable.dataSchema = [...table.dataSchema]
    copiedTable.referenceSchema = [...table.referenceSchema]
    copiedTable.rows = []
    table.rows.forEach((row) => {
      copiedTable.rows.push({
        rowId: row.rowId,
        status: row.status,
        values: [...row.values],
        ordinal: row.ordinal
      })
    })

    copiedTable.ordered = table.ordered
    copiedTable.pageNumber = table.pageNumber
    copiedTable.pageSize = table.pageSize
    copiedTable.key = table.key
    copiedTable.rowCount = table.rowCount

    return { ...copiedTable }
  }

  addColInArray(): void {
    if (this.table) {
      this.colAray = new FormArray([])
      this.isColumnHasValidValues = []
      //adding FormGroup for each column
      this.mainSchema?.forEach((element: DataGridColumnSchema, index: number) => {
        this.colDataTypes.push(ColumnType.fromDataGridColumnType(element))
        const newGroup = new FormGroup({})
        newGroup.addControl('col' + index, new FormControl(element.label))
        this.colAray.push(newGroup)
        this.isColumnHasValidValues.push(true)
      })
      this.colSource = [...this.colAray.controls]
    }
  }

  // sorted predefined date options
  getSortedPredefinedDateOptions(predefinedOptions: PredefinedDateOptionFe[]): PredefinedDateOptionFe[] {
    const options = predefinedOptions
      .filter((item) => item)
      .sort((a: PredefinedDateOptionFe, b: PredefinedDateOptionFe) => {
        if (a.from && b.from) {
          return new Date(a.from).getTime() - new Date(b.from).getTime()
        }
        return 0
      })
      .map((item) => new PredefinedDateOptionFe(item.from, item.to))
    return options
  }

  containsPredefinedOptions(element: ColumnType | DataGridColumnSchema): boolean {
    const predefinedOptions = element.predefinedOptions
    return predefinedOptions && predefinedOptions.length > 0
  }

  setPredefinedDateColValue(rowNo: number, colNo: number, option: PredefinedDateOptionFe) {
    let answer = this.datePipe.transform(option.from, 'yyyy-MM-dd')

    if (option.to) {
      const formattedTo = this.datePipe.transform(option.to, 'yyyy-MM-dd')
      answer = this.slocale(`${answer} ${this.slocale('to')} ${formattedTo}`)
    }

    this.rowArray.controls[rowNo].patchValue({ ['col' + colNo]: answer })
  }

  setPredefinedTextColValue(rowNo: number, colNo: number, option: PredefinedTextOptionFe) {
    this.rowArray.controls[rowNo].patchValue({ ['col' + colNo]: option.text })
  }

  setPredefinedNumberColValue(rowNo: number, colNo: number, filter: string, option: PredefinedNumberOptionFe) {
    let answer = ''
    if (filter === 'unit') {
      answer = `${option.unit}`
    } else if (filter === 'unit-value') {
      answer = `${option.value} ${option.unit || this.noUnitEnum.SYMBOL}`
    }
    this.rowArray.controls[rowNo].patchValue({ ['col' + colNo]: answer })
  }

  setPredefinedEmissionFactorColValue(
    rowNo: number,
    rowId: string,
    colNo: number,
    option: PredefinedEmissionFactorOptionFe
  ) {
    if (!this.emissionFactorArray[rowId]) {
      this.emissionFactorArray[rowId] = {}
    }
    this.emissionFactorArray[rowId]['col' + colNo] = option?.selectedEmissionFactor
    this.rowArray.controls[rowNo].patchValue({ ['col' + colNo]: option?.selectedEmissionFactor?.conversionFactor })
  }

  addNewPredefinedOptions(colNo: number, ef?: AbstractEmissionFactorFe, rowNo?: number) {
    if (!this.tempPredefinedOptions[colNo] && !ef) {
      return
    }

    switch (this.colDataTypes[colNo].generic) {
      case 'date':
        this.colDataTypes[colNo].predefinedOptions.push(new PredefinedDateOptionFe(this.tempPredefinedOptions[colNo]))
        this.colDataTypes[colNo].predefinedOptions = QuestionDateFe.setQuestion(
          this.colDataTypes[colNo]
        ).getSortedPredfinedDateOptions()
        break
      case 'text':
        this.colDataTypes[colNo].predefinedOptions.push(new PredefinedTextOptionFe(this.tempPredefinedOptions[colNo]))
        this.colDataTypes[colNo].predefinedOptions = this.colDataTypes[colNo].predefinedOptions.sort(
          (a: PredefinedTextOptionFe, b: PredefinedTextOptionFe) => {
            return a.text.toLowerCase().localeCompare(b.text)
          }
        )

        break
      case 'EMISSION_FACTOR':
        this.colDataTypes[colNo].predefinedOptions.push(new PredefinedEmissionFactorOptionFe(ef))
        this.setPredefinedEmissionFactorColValue(
          rowNo,
          this.addedRows[rowNo].rowId,
          colNo,
          new PredefinedEmissionFactorOptionFe(ef)
        )
        break
      case 'number':
        this.colDataTypes[colNo].predefinedOptions.push(this.tempPredefinedOptions[colNo] as PredefinedNumberOptionFe)
        this.colDataTypes[colNo].predefinedOptions = QuestionNumberFe.setQuestion(
          this.colDataTypes[colNo]
        ).getSortedPredfinedNumberOptions()

        break
    }
    this.tempPredefinedOptions[colNo] = [null]
    this.isCustomOptionAdded = false
  }

  addNewPredefinedOptionsWithUnit(colNo: number, unit: string) {
    if (!this.tempPredefinedOptions[colNo]) {
      this.tempPredefinedOptions[colNo] = new PredefinedNumberOptionFe()
    }
    if (!unit) {
      return
    }
    this.tempPredefinedOptions[colNo].unit = unit
  }

  addNewPredefinedOptionsWithUnitNumber(colNo: number, event: any) {
    if (!this.tempPredefinedOptions[colNo]) {
      this.tempPredefinedOptions[colNo] = new PredefinedNumberOptionFe()
    }
    this.tempPredefinedOptions[colNo].value = event.target.value
    if (!this.tempPredefinedOptions[colNo].unit) {
      this.tempPredefinedOptions[colNo].unit = this.colDataTypes[colNo]?.predefinedOptions[0]?.unit
    }
  }

  addRefRows() {
    this.refData[this.table.key!].forEach((data) => {
      const refTable = this.refTables.find((table) => table.key == data.refKey)!
      this.addRefRowsInArray(
        this.mainTableData.rows,
        this.mainTableData.name!,
        refTable,
        data.refColNo + this.mainTableData.dataSchema.length
      )
    })
  }

  addRefRowsInArray(
    ogTableRows: (DataGridRow | null)[],
    ogTableName: string,
    refTable: DataGridTableData,
    colNo: number
  ) {
    let rowValues: (DataGridRow | null)[] = []
    ogTableRows.forEach((row, rowNo) => {
      const refId = row?.values[colNo]
      const refRow = refTable.rows.find((x) => x.rowId == refId)
      if (refRow) {
        refRow?.values.forEach((val, index) => {
          //only pushing values of non-ref cols
          if (index < refTable.dataSchema.length) {
            this.table.rows[rowNo].values.push(val)
            this.rowRefIds[rowNo][this.table.rows[rowNo].rowId!].push(refRow.rowId ? refRow.rowId : '') //pushing rowIds for each refCol
          }
        })
      } else {
        //pushing blank space for rows whose id do not match with any rowId in refTable
        for (let i = 0; i < refTable.dataSchema.length; i++) {
          this.table.rows[rowNo].values.push(null)
        }
      }
      rowValues.push(refRow ? refRow : null)
    })

    if (this.refData[refTable.key!].length > 0) {
      this.refData[refTable.key!].forEach((data) => {
        const x = this.refTables.find((table) => table.key == data.refKey)!
        this.addRefRowsInArray(rowValues, refTable.name!, x, data.refColNo + refTable.dataSchema.length)
      })
    }
  }

  addRowsInArray(): void {
    if (this.table) {
      this.rowArray = new FormArray([])
      this.addedRows = []
      if (this.isModeExportExcelTableData()) {
        this.table.rows.slice(0, this.maxRowsToLoad).forEach((x, index) => {
          this.addRow(x, index)
        })
      } else {
        this.table.rows.forEach((x, index) => {
          this.addRow(x, index)
        })
      }
      this.dataSource = [...this.rowArray.controls]
    }
  }

  addRow(data: DataGridRow | null = null, position: any = null, isNewRow = false): void {
    if (this.mainTableData) {
      const newGroup = new FormGroup({})
      const newData: any[] = []
      const newUnitArray: { [key: string]: string | null } = {}
      const newEfArray: { [key: string]: AbstractEmissionFactorFe | null } = {}
      this.colSource.forEach((x: any, i: number) => {
        let value: any = null
        let dataValue: any = null
        if (data === null) {
          if (this.colDataTypes[i].generic === 'boolean') {
            value = true
            dataValue = true
          } else if (this.isMeasurementType(i)) {
            newUnitArray['col' + i] = this.colDataTypes[i].unitSymbol
            value = 0
            dataValue = { quantity: 0, unit: this.colDataTypes[i].unitSymbol }
          } else if (this.isEFType(i)) {
            if (this.isPreDeterminedEFType(i) && !this.isMultipleEFType(i)) {
              try {
                const ef = this.getPreDeterminedEF({ col: i })
                newEfArray['col' + i] = ef
              } catch (err) {}
            } else {
              newEfArray['col' + i] = null
            }
          }
          newData.push(dataValue)
        } else if (this.isMeasurementType(i)) {
          if (data.values[i] && typeof data.values[i] == 'object') {
            value = data.values[i].quantity
          } else {
            value = data.values[i]
          }
        } else if (
          (data.values[i] instanceof Date && this.isModeExportExcelTableData()) ||
          this.isValidDate(data.values[i])
        ) {
          value = this.datePipe.transform(data.values[i], 'yyyy-MM-dd')
        } else {
          value = data.values[i]
        }
        newGroup.addControl('col' + i, new FormControl(value))
      })

      let indexInOG = position
      if (data === null) {
        let ordinal = this.table.ordered ? 1 : -1

        if (position !== this.addedRows.length) {
          indexInOG = this.table.rows.findIndex((x) => x.rowId === this.addedRows[position].rowId)
        } else {
          indexInOG = this.table.rows.length
        }

        if (this.table.ordered && this.table.rows.length != 0) {
          if (indexInOG > 0 && indexInOG !== this.table.rows.length) {
            ordinal = (this.table.rows[indexInOG - 1].ordinal + this.table.rows[indexInOG].ordinal) / 2
          } else if (indexInOG === this.table.rows.length) {
            if (this.table.pageNumber !== this.pageCount) {
              const limit = Number(this.table.pageSize)
              ordinal = (this.table.rows[indexInOG - 1].ordinal + (this.currentItems + limit)) / 2
            } else {
              ordinal = this.currentItems + indexInOG + 1
            }
          } else if (indexInOG === 0) {
            if (this.currentItems !== 0) {
              ordinal = (this.currentItems + this.table.rows[indexInOG].ordinal) / 2
            } else {
              ordinal = this.table.rows[indexInOG].ordinal / 2
            }
          }
        }
        data = {
          rowId: UUID.v4(),
          status: DataGridStatus.ADDED,
          values: newData,
          ordinal
        }
      }

      if (isNewRow) {
        this.table.rows.splice(indexInOG, 0, data)
        this.unitArray[data.rowId] = newUnitArray
        this.emissionFactorArray[data.rowId] = newEfArray
        this.table.rowCount!++
        this.rowRefIds.splice(position, 0, {
          [data.rowId!]: Array(this.totalRefCols)
        })
        this.unitArray[data.rowId] = {}
        data.values.forEach((val, j) => {
          if (this.isMeasurementType(j)) {
            this.unitArray[data.rowId]['col' + j] = val?.unit
          }
        })
      }

      this.rowArray.insert(position, newGroup)
      this.addedRows.splice(position, 0, data)
      this.dataSource = [...this.rowArray.controls]
    }
  }

  async addColumn(
    data: { name: string; type: string; unit: string } | null = null,
    position: any = null
  ): Promise<void> {
    if (this.mainTableData) {
      let newColumnName = ''
      let newDataGridColumnType = ''
      let newColumnUnit = ''
      if (data) {
        newColumnName = data.name
        newDataGridColumnType = data.type
        newColumnUnit = data.unit
      }
      const length = this.colAray.length
      const newField = new FormGroup({})

      if (position < length) {
        const columns = this.colAray.value.map((x: any, i: number) => x['col' + i])
        this.colAray.controls[position].get('col' + position)?.patchValue(newColumnName)
        for (let i = position + 1; i < length; i++) {
          const x = 'col' + i
          this.colAray.controls[i].setValue({ [x]: columns[i - 1] })
        }
        newField.addControl('col' + length, new FormControl(columns[length - 1]))
        this.colAray.push(newField)
      } else {
        newField.addControl('col' + length, new FormControl(newColumnName))
        this.colAray.push(newField)
      }

      const value: any = '' + newColumnUnit

      this.rowArray.controls.forEach((group: any) => {
        const values = Object.values(group.value)
        if (position < length) {
          group.get('col' + position).setValue(value)
          for (let i = position + 1; i < length; i++) {
            group.get('col' + i).setValue(values[i - 1])
          }
          group.addControl('col' + length, new FormControl(values[length - 1]))
        } else {
          group.addControl('col' + length, new FormControl(value))
        }
      })

      let newCol = {
        name: newColumnName,
        label: newColumnName,
        type: newDataGridColumnType,
        mode: DataGridColumnMode.NULLABLE,
        status: DataGridStatus.ADDED,
        width: '10rem',
        deployed: false,
        modified: true,
        isCalculated: false,
        attributeUnit: null
      }

      this.mainSchema?.splice(position, 0, newCol)

      this.table.dataSchema?.splice(position - this.totalRefCols, 0, newCol)

      this.colDataTypes.splice(position, 0, ColumnType.fromDataGridColumnType(newCol))

      this.table.rows.forEach((x, i: number) => {
        x.values.splice(position, 0, value)
      })
      this.populateUnitArray()
      this.colSource = [...this.colAray.controls]
      this.dataSource = [...this.rowArray.controls]
      this.setPaginationWidth(10)
    }
  }

  move(object: any): void {
    this.isADblClick = false
    if (object.action === 'quantityClicked' || object.action === 'outsideClicked') {
      object.element = this.tableComponent.nativeElement.querySelector(`#${object.element}`)
    }

    const inputToArray = this.inputs.toArray()
    let columns = this.mainSchema?.length || 0
    let index = inputToArray.findIndex((x) => x.element.nativeElement === object.element)
    if ((object.action === 'CLICKED' || object.action === 'quantityClicked') && this.previousCell === -1) {
      this.previousCell = index
    } else {
      this.previousCell = this.currentCell
    }
    if (object.action === 'CLICKED' || object.action === 'quantityClicked') {
      this.wasInside = true
    }

    const previousColNo = this.previousCell % columns

    switch (object.action) {
      case 'UP':
        index -= columns
        break
      case 'DOWN':
        index += columns
        break
      case 'LEFT':
        index--
        const col = index % columns
        if (this.colDataTypes[col].generic === 'checkbox' && !this.isModeEditTableData() && index > 3) {
          index--
        }
        break
      case 'RIGHT':
        index++
        const colNo = index % columns
        if (this.colDataTypes[colNo].generic === 'checkbox' && !this.isModeEditTableData() && index > 3) {
          index++
        }
        break
    }

    if (index >= 0 && index < inputToArray.length) {
      let headerRows = 2
      this.currentCell = index
      this.currentCol = this.currentCell % columns
      this.currentRow = Math.floor(this.currentCell / columns) - headerRows

      inputToArray[this.currentCell].element.nativeElement.focus()
      if ((this.isModeEditTableData() || this.isModeEditRefTableData()) && this.previousCell >= headerRows * columns) {
        inputToArray[this.previousCell].isClicked = false
        inputToArray[this.previousCell].element.nativeElement.readOnly = true
        this.tableComponent.nativeElement
          .querySelector(`#${inputToArray[this.previousCell].element.nativeElement.id.slice(0, -5)}`)
          ?.classList.remove('focusedBorder')
        if (
          this.colDataTypes[previousColNo].generic === 'number' &&
          this.colDataTypes[previousColNo].original === DataGridColumnType.MEASUREMENT
        ) {
          const previousRow = Math.floor(this.previousCell / this.colDataTypes.length) - 1

          this.tableComponent.nativeElement
            .querySelector(`#${inputToArray[this.previousCell].element.nativeElement.id.slice(0, -5)}` + 'number')
            ?.classList.remove('invisible')
          if (this.previousCell !== this.currentCell) {
            this.tableComponent.nativeElement
              .querySelector(`#dropdownMenuCol${previousColNo}Row${previousRow}`)
              ?.classList.remove('show')
          }
        }
      }
    }
  }

  getControl(element: AbstractControl, column: string): FormControl {
    const control = element.get(column) as FormControl
    return control
  }

  changeColor(index: number, colNo: number): void {
    if (this.table) {
      if (this.getControl(this.dataSource[index], 'col' + colNo).invalid) {
        this.isColumnHasValidValues[colNo] = false
      }

      let isColumnValid = this.dataSource.every((rowControl) => this.getControl(rowControl, 'col' + colNo).valid)
      if (isColumnValid) {
        this.isColumnHasValidValues[colNo] = true
      }

      let oldVal = this.addedRows[index].values[colNo]
      if (
        this.colDataTypes[colNo].generic === 'number' &&
        this.colDataTypes[colNo].original === DataGridColumnType.MEASUREMENT
      ) {
        oldVal = oldVal?.quantity
      }
      const newVal = this.rowArray.value[index]['col' + colNo]
      if (newVal !== oldVal) {
        if (this.addedRows[index].status !== DataGridStatus.ADDED) {
          this.addedRows[index].status = DataGridStatus.MODIFIED
        }
      } else {
        if (this.addedRows[index].status == DataGridStatus.MODIFIED) {
          this.addedRows[index].status = DataGridStatus.STORED
        }
      }

      if (newVal && typeof newVal == 'string') {
        let control = this.getControl(this.dataSource[index], 'col' + colNo)
        control.setValue(newVal.trim())
      }

      this.checkForModifiedRowCells()
    }
  }

  isDateValue(rowNo: number, colNo: number) {
    let control = this.getControl(this.dataSource[rowNo], 'col' + colNo)
    let val = control.value
    if (val && val != '') {
      return true
    }
    return false
  }

  async deleteAllRows() {
    let rows = this.addedRows.length
    for (let i = 0; i < rows; i++) {
      this.del('row', 0, this.addedRows[0].rowId)
    }
  }

  async del(type: string = '', index: number = 0, rowId: string = ''): Promise<void> {
    const indexInData = this.isNewTable ? index : this.table.rows.findIndex((x) => x.rowId === rowId)
    this.removeCellFocus(this.currentCol, this.currentRow)
    if (this.mainTableData) {
      if (type === 'row') {
        this.currentRow = index

        if (this.table.rows[indexInData].status != DataGridStatus.ADDED) {
          this.deletedRows.push({
            rowDetails: this.table.rows.slice(indexInData, indexInData + 1)[0],
            index
          })
          this.table.rows[indexInData].status = DataGridStatus.DELETED
        } else {
          this.table.rows.splice(indexInData, 1)
          delete this.unitArray[rowId]
          delete this.emissionFactorArray[rowId]
        }
        this.rowArray.removeAt(index)
        this.rowRefIds.splice(index, 1)
        this.addedRows.splice(index, 1)
        this.dataSource = [...this.rowArray.controls]

        this.checkForModifiedRowCells()
        ;(this.table.rowCount as number)--
      } else {
        const length = this.colAray.length
        this.currentCol = index

        this.rowArray.controls.forEach((group: any) => {
          const values = Object.values(group.value)
          if (this.currentCol < length) {
            for (let i = this.currentCol; i < length - 1; i++) {
              group.get('col' + i).setValue(values[i + 1])
            }
          }
          group.removeControl('col' + (length - 1))
        })

        if (this.currentCol < length) {
          const columns = this.colAray.value.map((x: any, i: number) => x['col' + i])

          this.colAray.controls[this.currentCol].get('col' + this.currentCol)?.patchValue('Column')
          for (let i = this.currentCol; i < length - 1; i++) {
            const x = 'col' + i
            this.colAray.controls[i].setValue({ [x]: columns[i + 1] })
          }
        }
        this.colAray.removeAt(length - 1)
        //delete column from dataGridTable
        this.table.dataSchema?.splice(this.currentCol - this.totalRefCols, 1)
        this.mainSchema?.splice(this.currentCol, 1)
        this.isColumnHasValidValues.splice(this.currentCol, 1)

        this.table.rows.forEach((data, i) => {
          data.values.splice(this.currentCol, 1)
        })

        this.colDataTypes.splice(this.currentCol, 1)
        this.populateUnitArray()
        this.dataSource = [...this.rowArray.controls]
        this.colSource = [...this.colAray.controls]

        this.dataGrid.markDataModified(true, this.checkDataValidity())
        this.isSaved = false
        this.setPaginationWidth(-168)
      }
    }
    if (this.isFormView && this.selectedEntryIndex) {
      this.selectedEntryIndex = this.selectedEntryIndex - 1
    }
  }

  addRowAfter(index: number): void {
    this.removeCellFocus(this.currentCol, this.currentRow)
    this.currentRow = this.currentItems + index

    this.addRow(null, index + 1, true)
    this.dataGrid.markDataModified(true, this.checkDataValidity())
    this.isSaved = false

    this.rowCount = this.table.rowCount
  }

  addColBelow(index: number = 0): void {
    this.currentCol = index
    this.modalRef = this.modalService.show(ModifyColumnModalComponent, {
      id: 2
    })
  }

  // Open change header setting modal
  onChangeHeaderInputSetting(index: number, modalTemplateRef: TemplateRef<any>): void {
    this.selectedCurrentIndex = index
    this.selectedColumnForPredefinedOption = this.mainSchema[index]

    this.selectedColumnForPredefinedOption['titleNumber'] = this.selectedColumnForPredefinedOption?.label
    let colType = this.colDataTypes[index].generic

    if (colType === DataGridColumnType.EMISSION_FACTOR) {
      colType = 'emissionFactor'
    }

    this.tempUserQuestion = this.selectedColumnForPredefinedOption?.userQuestion
    this.tempUserQuestionDesccription = this.selectedColumnForPredefinedOption.userQuestionDescription

    this.selectedColumnTypeForPredefinedOption = colType
    this.openModal(modalTemplateRef, 'modal-lg')
  }

  updateColumn(label: string) {
    let colNo = this.currentCol
    let oldVal = this.mainSchema[colNo].label
    const newVal = label
    this.colAray.controls[colNo].get('col' + colNo)?.setValue(label)
    if (newVal !== oldVal) {
      if (this.mainSchema[colNo].status !== DataGridStatus.ADDED) {
        this.mainSchema[colNo].status = DataGridStatus.MODIFIED
        this.mainSchema[colNo].modified = true
      }
    } else if (this.mainSchema[colNo].status == DataGridStatus.MODIFIED) {
      this.mainSchema[colNo].status = DataGridStatus.STORED
    }

    this.isValueChanged = this.mainSchema.some((x) => x.status === DataGridStatus.MODIFIED || x.modified === true)
    this.isSaved = !this.mainSchema.some((x) => x.status !== DataGridStatus.STORED)

    this.dataGrid.markDataModified(!this.isSaved, this.checkDataValidity())
  }

  editColumn(colNo: number) {
    this.currentCol = colNo
    let column = { ...this.mainSchema[colNo] }
    const initialState = {
      mode: 'edit',
      column
    }
    this.modalRef = this.modalService.show(ModifyColumnModalComponent, {
      initialState,
      id: 2
    })
  }

  removeReadOnly(el: any, type: string): void {
    if (type === 'col' && this.isModeEditTableSchema()) {
      el.readOnly = false
    } else if (type === 'row' && (this.isModeEditTableData() || this.isModeEditRefTableData())) {
      el.readOnly = false
      this.tableComponent.nativeElement.querySelector(`#${el.id.slice(0, -5)}`)?.classList.add('focusedBorder')
    }
  }

  saveData(): void {
    this.dataService.saveData(this.rowArray, this.addedRows)
  }

  isCellEditable(id: string): boolean {
    if (this.tableComponent)
      return this.tableComponent.nativeElement.querySelector(`#${id}`)?.classList.contains('focusedBorder')!
    else return false
  }

  getValue(el: AbstractControl | null = null, id: string): string {
    if (el !== null) {
      return this.getControl(el, id).value
    }
    return (this.tableComponent.nativeElement.querySelector(`#${id}`) as HTMLInputElement)?.value
  }

  updateValue(col: number, row: number): void {
    const control = this.dataSource[row].get('col' + col)

    const quan = (
      this.tableComponent.nativeElement.querySelector(`#col${col}row${row}` + 'quantity') as HTMLInputElement
    )?.value
    // const unit = (this.tableComponent.nativeElement.querySelector(`#col${col}row${row}` + 'unit') as HTMLInputElement)?.value;
    control?.setValue(quan)

    const indexInOG = this.isNewTable ? row : this.table.rows.findIndex((x) => x.rowId === this.addedRows[row].rowId)
    // this.unitArray[indexInOG]['col' + col] = unit;

    const oldQuan = this.addedRows[row].values[col].quantity
    const oldUnit = this.addedRows[row].values[col].unit

    if (oldQuan !== quan) {
      this.tableComponent.nativeElement.querySelector(`#col${col}row${row}`)?.classList.add('editedBg')
      this.tableComponent.nativeElement.querySelector(`#col${col}row${row}`)?.classList.remove('bg-light')
      if (this.addedRows[row].status !== DataGridStatus.ADDED) {
        this.addedRows[row].status = DataGridStatus.MODIFIED
      }
    } else {
      this.tableComponent.nativeElement.querySelector(`#col${col}row${row}`)?.classList.remove('editedBg')

      this.tableComponent.nativeElement
        .querySelector(`#col${col}row${row}`)
        ?.classList.add(row % 2 === 0 ? 'editModeBg' : 'bg-light')

      if (this.addedRows[row].status == DataGridStatus.MODIFIED) this.addedRows[row].status = DataGridStatus.STORED
    }
    this.checkForModifiedRowCells()
  }

  numberClicked(id: string): void {
    if (!this.isModeEditTableData()) {
      this.move({ element: id + 'value', action: 'quantityClicked' })
    } else {
      this.tableComponent.nativeElement.querySelector(`#${id}number`)?.classList.add('invisible')
      const el = this.tableComponent.nativeElement.querySelector(`#${id}value`) as HTMLInputElement
      el.click()
    }
  }

  setPaginationWidth(extra: number = 0): void {
    if (
      !this.isModeExportExcelTableData() &&
      this.dataGrid.hasMainTablePagination() &&
      this.dataGrid.getMainTableData().rowCount != 0 &&
      this.deletedRows.length == 0 &&
      !this.isNewTable
    ) {
      const containerWidth = this.tableComponent.nativeElement.getElementsByClassName('tableContainer')[0].clientWidth
      const tableWidth = this.tableComponent.nativeElement.getElementsByTagName('tbody')[0].clientWidth
      const width = Math.min(tableWidth, containerWidth)
      if (tableWidth < window.screen.width && tableWidth != 0) {
        const el = document.getElementsByClassName('rowNos')[0] as HTMLElement
        el ? (el.style.width = '' + (width + extra - 10) + 'px') : null
      }
    }
  }

  async pagination(action: string, value: string = ''): Promise<void> {
    let pageNumber = this.table.pageNumber
    let pageSize = this.table.pageSize
    if (action === 'pageLimitChanged') {
      pageSize = this.table.pageSize
      pageNumber = 1
      this.pageNumbers = []
    } else if (action === 'navigationChanged') {
      if (value === 'previous') {
        pageNumber = --this.table.pageNumber
      } else if (value === 'next') {
        pageNumber = ++this.table.pageNumber
      } else if (value === 'first') {
        pageNumber = 1
      } else if (value === 'last') {
        pageNumber = this.pageCount
      }
    } else if (action === 'pageNoChanged') {
      pageNumber = this.pageNumbers[Number(value)]
    }

    this.setOldWidthCol()
    this.dataGrid.loadMainTablePage(pageSize, pageNumber).then(async (data) => {
      await this.renderNewDataTable()
    })
    this.currentItems = (this.table.pageNumber - 1) * this.table.pageSize
  }

  revokeAllRows() {
    let rows = this.deletedRows.length
    this.deletedRows
      .slice()
      .reverse()
      .forEach((row, index) => this.revokeRows(rows - index - 1, row.rowDetails))
  }

  revokeRows(index: number, row: DataGridRow): void {
    this.deletedRows.splice(index, 1)
    let indexInAddedRows = this.addedRows.findIndex((x) => x.ordinal > row.ordinal)
    if (indexInAddedRows === -1) {
      indexInAddedRows = this.addedRows.length
    }

    this.addRow(row, indexInAddedRows)

    this.table.rows.map((x) => {
      if (x.rowId === row.rowId) {
        x.status = DataGridStatus.STORED
      }
    })

    this.checkForModifiedRowCells()
  }

  getClasses(col: number, rowNo: number, isEven: boolean): string {
    let classes = ''

    if (this.colDataTypes[col].isCalculated || this.colDataTypes[col].isPreDetermined) {
      classes += 'calculated '
    }

    if (this.isModeEditTableData() || this.isModeEditRefTableData() || this.isModeSelectTableRow()) {
      if (this.selectedColumn == col) {
        classes += 'selectedCol '
      } else if (this.selectedRow == rowNo) {
        classes += 'selectedRow '
      }

      if (this.hiddenColumns.includes(col)) {
        classes += 'd-none '
      }

      if (this.isModeEditTableData() || this.isModeEditRefTableData()) {
        classes += ' l-shadow '
        if (this.isMeasurementType(col)) {
          if (
            this.addedRows[rowNo].values[col]?.quantity != this.rowArray.value[rowNo]['col' + col] &&
            this.addedRows[rowNo].status != 'ADDED'
          ) {
            classes += 'editedBg'
            this.addedRows[rowNo].status = DataGridStatus.MODIFIED
          }
        } else if (
          this.addedRows[rowNo].values[col] != this.rowArray.value[rowNo]['col' + col] &&
          this.addedRows[rowNo].status != 'ADDED'
        ) {
          classes += 'editedBg'
          this.addedRows[rowNo].status = DataGridStatus.MODIFIED
        }
      }
    } else if (this.isModeEditTableSchema()) {
      if (this.mainSchema![col].status === 'ADDED') {
        classes += 'editedBg'
      }
    } else if (this.isModeExportExcelTableData() || this.isModeMapExtractedData()) {
      if (this.diselectedColumnIndex.includes(col) || this.diselectedRowIndex.includes(rowNo)) {
        classes += 'diselected '
      }
      if (this.headlineRowsIndex.includes(rowNo)) {
        classes += 'headline '
      } else if (this.selectedColumnIndex.includes(col) || this.selectedRowIndex.includes(rowNo)) {
        classes += `${DragSelectClasses.selectionClass} `
      }
      classes += 'l-shadow'
    }
    return classes
  }

  getUnit(col: number, rowId: string) {
    return this.unitArray[rowId]['col' + col]
  }

  setUnit(col: number, rowIndex: number, rowId: string, unitSymbol: string) {
    this.unitArray[rowId]['col' + col] = unitSymbol
    if (this.addedRows[rowIndex].status !== DataGridStatus.ADDED) {
      this.addedRows[rowIndex].status = DataGridStatus.MODIFIED
    }
  }

  setAllUnit(col: number, unitSymbol: string) {
    Object.values(this.unitArray).forEach((row) => (row['col' + col] = unitSymbol))
    this.addedRows.forEach((row) => {
      if (row.status !== DataGridStatus.ADDED) {
        row.status = DataGridStatus.MODIFIED
      }
    })
  }

  getEmissionFactor(col: number, rowId: string) {
    return this.emissionFactorArray[rowId]['col' + col]
  }

  setEmissionFactor(col: number, rowIndex: number, rowId: string, ef: AbstractEmissionFactorFe) {
    this.emissionFactorArray[rowId]['col' + col] = ef
    if (this.addedRows[rowIndex].status !== DataGridStatus.ADDED) {
      this.addedRows[rowIndex].status = DataGridStatus.MODIFIED
    }
  }

  setAllEmissionFactor(col: number, ef: AbstractEmissionFactorFe) {
    Object.values(this.emissionFactorArray).forEach((row) => (row['col' + col] = ef))
    this.addedRows.forEach((row) => {
      if (row.status !== DataGridStatus.ADDED) {
        row.status = DataGridStatus.MODIFIED
      }
    })
  }

  stop(e: MouseEvent): void {
    e.stopPropagation()
  }

  toggleDropdown(i: number, rowNo: number): void {
    this.tableComponent.nativeElement.querySelector(`#dropdownMenuCol${i}Row${rowNo}`)?.classList.toggle('show')
  }

  setOldWidthCol() {
    this.oldWidthCol = []
    let headers = this.tableComponent.nativeElement.querySelectorAll('.colData')
    Array.from(headers).forEach((col: any, i) => {
      this.oldWidthCol.push(col.clientWidth + 'px')
      if (col.classList.contains('resized')) {
        this.mainSchema![i].width = col.clientWidth + 'px'
      }
    })
  }

  ngOnDestroy() {
    if (this.unlistenClick) this.unlistenClick()
  }

  getElement(x: any): FormGroup {
    return x as FormGroup
  }

  onScroll() {
    const len = this.rowArray.value.length
    const rowCount = this.table.rowCount!
    if (
      rowCount >= this.maxRowsToLoad &&
      len < rowCount &&
      !(this.isModeEditTableData() || this.isModeEditRefTableData())
    ) {
      this.isLoading = true
      this.fetchRows(len)
    }
  }

  async fetchRows(len: number, rowsToLoad = this.maxRowsToLoad) {
    let newWidths: string[] = []
    const x = Array.from(<HTMLCollectionOf<HTMLTableCellElement>>document.getElementsByClassName('resized'))
    x.forEach((col) => newWidths.push(col.clientWidth + 'px'))
    this.table.rows.slice(len, len + rowsToLoad).forEach((x: DataGridRow, index: number) => {
      this.addRow(x, len + index)
    })
    setTimeout(() => {
      this.isLoading = false
      x.forEach((col: HTMLTableCellElement, index) => {
        const rows = Array.from(
          <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName(`col${col.cellIndex - 1}`)
        ).slice(len, len + this.maxRowsToLoad)
        rows.forEach((cell) => {
          this.renderer.setStyle(cell, 'width', newWidths[index])
        })
      })
    })
  }

  onClosingRightClickDropdown(event?: any) {
    this.rightClickDropdownRef = null
  }

  hideRightClickDropdown() {
    if (this.rightClickDropdownRef) {
      this.rightClickDropdownRef.hide()
    }
  }

  onOpeningRightClickDropdown(drodpown: BsDropdownDirective) {
    if (this.rightClickDropdownRef) {
      this.rightClickDropdownRef.hide()
    }
    this.rightClickDropdownRef = drodpown
  }

  getRestArray(): number[] {
    const length = this.noOfMinRows - this.addedRows.length
    const arr = Array.apply(null, Array(length))
    return arr.map((currentValue, index) => index + 1 + this.addedRows.length)
  }

  async setMappingDetails() {
    if (this.extractedData) {
      let extractedCols = this.mainSchema.filter((col, index) => this.extractedColumnNos.includes(index))
      let taxonomyEntities = await this.importedDataService.excelViewerService.getTaxonomyEntities()
      if (taxonomyEntities.length <= 1) {
        let tableDef: any
        let target = { value: 0 }
        tableDef = await this.importedDataService.excelViewerService.getEntityTableDef(taxonomyEntities[target.value])
        this.importedDataService.name = tableDef.name!
        this.mappedToCols = []
        tableDef.dataSchema?.map((col: DataGridColumnSchema, index: number) => {
          this.mappedToCols.push({
            col: col,
            mappedColIndex: index < extractedCols.length ? index : -1
          })
        })
        this.mainSchema.filter((col, index) => {
          if (this.mappedToColumn(index)) {
            this.setMappingLabel(index)
          }
        })
        this.setExtractedData()
      }
    }
  }

  mappedToColumn(colNo: number): DataGridColumnSchema | null {
    let index = this.getExtractedColNo(colNo)
    let mappedTo = this.mappedToCols.find((m) => m.mappedColIndex == index && index != -1)
    if (mappedTo) {
      return mappedTo.col
    }
    return null
  }

  setUnitForMappedToCol(colNo: number, unit: string) {
    let index = this.getExtractedColNo(colNo)
    let mappedToIndex = this.mappedToCols.findIndex((m) => m.mappedColIndex == index && index != -1)
    this.unitsForMappedToCols.set(mappedToIndex, unit)
  }

  getUnitForMappedToCol(colNo: number): string {
    let index = this.getExtractedColNo(colNo)
    let mappedToIndex = this.mappedToCols.findIndex((m) => m.mappedColIndex == index && index != -1)
    return this.unitsForMappedToCols.get(mappedToIndex)
  }

  getMeasurementTypeForMappedToCol(colNo: number) {
    let index = this.getExtractedColNo(colNo)
    let orgCol = this.mappedToCols.find((m) => m.mappedColIndex == index && index != -1)
    const includeMeasurementTypes = new Set()
    if (orgCol?.col?.measurementKey) includeMeasurementTypes.add(orgCol?.col?.measurementKey)
    return includeMeasurementTypes
  }

  mappedToColumnIndex(colNo: number): number {
    let index = this.getExtractedColNo(colNo)
    let mappedTo = this.mappedToCols.findIndex((m) => m.mappedColIndex == index && index != -1)
    return mappedTo
  }

  mappedToColSelected(x: any, colNo: number): void {
    let index = this.getExtractedColNo(colNo)
    let isAlreadyMappedTo = this.mappedToCols.filter((m) => m.mappedColIndex == index && index != -1)
    isAlreadyMappedTo.forEach((m) => {
      let orgColIndex = this.extractedColumnNos[m.mappedColIndex]
      this.removeMappingLabel(orgColIndex)
      this.unitsForMappedToCols.delete(orgColIndex)
      m.mappedColIndex = -1
    })
    if (x.value != -1) {
      let mappedToCol = this.mappedToCols[x.value]
      if (mappedToCol.mappedColIndex != -1) {
        let orgColIndex = this.extractedColumnNos[mappedToCol.mappedColIndex]
        this.removeMappingLabel(orgColIndex)
        mappedToCol.mappedColIndex = -1
      }
      this.mappedToCols[x.value].mappedColIndex = index
      this.setMappingLabel(colNo)
    } else {
      let elems = this.tableComponent.nativeElement.querySelectorAll(`.col${colNo}.${DragSelectClasses.selectionClass}`)
      elems.forEach((e) => e.classList.remove('mapped'))
    }
    this.setExtractedData()
  }

  areValuesValid(colNo: number) {
    let mappedToCol = this.mappedToColumn(colNo)
    if (mappedToCol) {
      let index = this.mappedToCols.findIndex((m) => m.col == mappedToCol)
      if (mappedToCol.type == DataGridColumnType.DATE) {
        const rows = this.importedDataService.getSelectedDataTable().rows
        for (let i = 0; i < rows.length; i++) {
          let row = rows[i]
          let val = row.values[index]
          if (val && !this.isValidDate(val)) {
            return false
          }
        }
      } else if (mappedToCol.type == DataGridColumnType.NUMERIC || mappedToCol.type == 'FLOAT') {
        const rows = this.importedDataService.getSelectedDataTable().rows
        for (let i = 0; i < rows.length; i++) {
          let row = rows[i]
          let val = row.values[index]
          if (val && !this.isValidNumber(val)) {
            return false
          }
        }
      }
    }
    return true
  }

  isValidDate(date: any) {
    return (typeof date == 'string' && date.match(ValidationRegex.DateRegex) != null) || date instanceof Date
  }

  isValidNumber(val: any) {
    if (typeof val == 'string') {
      val = val.trim()
      if (val != '') {
        return ValidationRegex.NumberRegExp.test(val)
      }
    }
    return true
  }

  setMappingLabel(colNo: number) {
    let mappingLabel = this.tableComponent.nativeElement.querySelector(`#mappedLabels${colNo}`) as HTMLElement
    let firstCell = this.tableComponent.nativeElement.querySelector(
      `.col${colNo}.${DragSelectClasses.selectionClass}:not(.diselected):not(.headline)`
    ) as HTMLElement
    firstCell.classList.add('firstCell')
    mappingLabel.style.top = `${firstCell.offsetTop - 20}px`
    let elems = this.tableComponent.nativeElement.querySelectorAll(
      `.col${colNo}.${DragSelectClasses.selectionClass}:not(.diselected):not(.headline)`
    )
    elems.forEach((e) => e.classList.add('mapped'))
    elems[elems.length - 1].classList.add('lastCell')
  }

  removeMappingLabel(colNo: number) {
    let elems = this.tableComponent.nativeElement.querySelectorAll(`.col${colNo}.${DragSelectClasses.selectionClass}`)
    elems.forEach((e) => e.classList.remove('mapped'))
  }

  setExtractedData() {
    this.importedDataService.cols = this.mappedToCols
    this.importedDataService.unitsForMappedToCols = this.unitsForMappedToCols
    this.importedDataService.setSelectedTableSchema()
    this.importedDataService.setSelectedTableRows()
  }

  markSelectedData(event: { value: SelectedData[]; element: HTMLDivElement }) {
    this.selectedColumnIndex = []
    this.selectedRowIndex = []
    const el = this.tableComponent ? this.tableComponent.nativeElement : null
    if (el == event.element && el) {
      this.extractedData = event.value
      this.setSelectedData()
    }
  }

  getExtractedColNo(colNo: number): number {
    let index = this.extractedColumnNos.findIndex((v) => v == colNo)
    return index
  }

  isDataSelected() {
    if (this.importedDataService.selectedData && this.importedDataService.selectedData.length > 0) {
      return true
    }
    return false
  }

  isDeselectedColumn(column: number) {
    return this.diselectedColumnIndex.includes(column)
  }

  deselectColumn(column: number) {
    this.diselectedColumnIndex.push(column)
    this.setSelectedData()
  }

  reselectColumn(column: number) {
    this.diselectedColumnIndex = this.diselectedColumnIndex.filter((c) => c != column)
    this.setSelectedData()
  }

  isDeselectedRow(row: number) {
    return this.diselectedRowIndex.includes(row)
  }

  deselectRow(row: number) {
    this.diselectedRowIndex.push(row)
    this.setSelectedData()
  }

  reselectRow(row: number) {
    this.diselectedRowIndex = this.diselectedRowIndex.filter((c) => c != row)
    this.setSelectedData()
  }

  setSelectedData() {
    let rowValues: string[][] = []
    if (this.selectedColumnIndex.length > 0) {
      this.mainTableData.rows.forEach((r, rowNo) => {
        if (!this.isDeselectedRow(rowNo) && !this.isHeadlineRow(rowNo)) {
          let values = r.values.filter(
            (v, colNo) => this.selectedColumnIndex.includes(colNo) && !this.isDeselectedColumn(colNo)
          )
          rowValues.push(values)
        }
      })
    } else if (this.selectedRowIndex.length > 0) {
      this.mainTableData.rows.forEach((r, rowNo) => {
        if (this.selectedRowIndex.includes(rowNo) && !this.isDeselectedRow(rowNo) && !this.isHeadlineRow(rowNo)) {
          let values = r.values.filter((v, colNo) => !this.isDeselectedColumn(colNo))
          rowValues.push(values)
        }
      })
    } else {
      this.extractedData.forEach((data) => {
        if (!this.isDeselectedRow(data.rowNo)) {
          let includedCol = data.values.filter((v) => !this.isDeselectedColumn(v.colNo))
          let values = includedCol.map((v) => v.value)
          rowValues.push(values)
        }
      })
    }
    this.importedDataService.setSelectedData(rowValues)
    if (this.isDataSelected()) {
      let columns: number[] = []
      if (this.selectedColumnIndex.length > 0) {
        columns = this.selectedColumnIndex.sort()
      } else if (this.selectedRowIndex.length > 0) {
        this.mainSchema.forEach((c, colno) => {
          if (!this.isDeselectedColumn(colno)) {
            columns.push(colno)
          }
        })
      } else {
        let extractedCols = this.extractedData[0].values.filter((v) => !this.isDeselectedColumn(v.colNo))
        columns = extractedCols.map((col) => col.colNo)
      }
      this.extractedColumnNos = columns
      this.importedDataService.excelViewerService.markDataSelected(true)
    } else {
      this.importedDataService.excelViewerService.markDataSelected(false)
      this.diselectedColumnIndex = []
      this.diselectedRowIndex = []
    }
  }

  setSelectedRowInRowArray(
    updatedRefRow: DataGridRow,
    rowNo: number,
    prevCols: number,
    refTableKey: string,
    updatedChildRow: DataGridRow | null = null
  ) {
    let rowValue: { [key: string]: any } = {}
    updatedRefRow.values.forEach((val, index) => {
      const offset = index + prevCols
      rowValue['col' + offset] = val
    })

    this.rowArray.controls[rowNo].patchValue(rowValue)

    let isSameValue = false
    const colInMainTable = this.mainTableData.referenceSchema.findIndex(
      (col) => col.referenceKey == this.refService.database.key
    )
    if (colInMainTable != null) {
      const x = this.mainTableData.rows.find((row) => row.rowId == this.addedRows[rowNo].rowId)
      let orgValues = x?.values.slice(-this.mainTableData.dataSchema.length)
        ? x?.values.slice(-this.mainTableData.dataSchema.length)
        : []
      let newValues = [...Object.values(this.rowArray.value[rowNo])].slice(-this.mainTableData.dataSchema.length)
      let isSameRefValue = x?.values[colInMainTable] == updatedRefRow.rowId
      isSameValue = isSameRefValue && newValues.every((val, index) => val == orgValues[index])
      //isSameValue stores if the row data of maintable is unchanged and the selected row is the same as original
    }

    if (isSameValue) {
      //delete rowDetails of the reftable and its referenced reftables
      this.deleteRowDetails(refTableKey, this.addedRows[rowNo].rowId!)
    } else {
      if (this.dataService.rowInRefTableHandlerType == 'add Parent' && updatedChildRow) {
        this.rowDetails[this.addedRows[rowNo].rowId!]
          ? (this.rowDetails[this.addedRows[rowNo].rowId!][refTableKey] = updatedChildRow)
          : (this.rowDetails[this.addedRows[rowNo].rowId!] = {
              [refTableKey]: updatedChildRow
            })
      } else {
        this.rowDetails[this.addedRows[rowNo].rowId!]
          ? (this.rowDetails[this.addedRows[rowNo].rowId!][refTableKey] = updatedRefRow)
          : (this.rowDetails[this.addedRows[rowNo].rowId!] = {
              [refTableKey]: updatedRefRow
            })
      }
    }

    if (this.addedRows[rowNo].status != DataGridStatus.ADDED) {
      //if its same value and none of its other reftables are modified then mark it as stored
      if (
        isSameValue &&
        (!this.rowDetails[this.addedRows[rowNo].rowId!] ||
          Object.keys(this.rowDetails[this.addedRows[rowNo].rowId!]).length === 0)
      ) {
        this.addedRows[rowNo].status = DataGridStatus.STORED
      } else {
        this.addedRows[rowNo].status = DataGridStatus.MODIFIED
      }
    }
  }

  deleteRowDetails(key: string, rowId: string) {
    console.log('code here......')
    const table = this.refTables.find((table) => table.key == key)!
    this.rowDetails[rowId] ? delete this.rowDetails[rowId][key] : null
    if (table.referenceSchema.length > 0) {
      table.referenceSchema.forEach((col) => this.deleteRowDetails(col.referenceKey, rowId))
    }
  }

  selectRefRow(rowNo: number) {
    let allRowIds: any[] = []

    this.table.dataSchema.forEach(() => {
      allRowIds.push(this.addedRows[rowNo].rowId)
    })

    allRowIds.push(...this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!])

    this.dataService.selectedRowData = {
      row: this.addedRows[rowNo],
      allRowIds
    }
    this.dataService.selectedRow$.next(true)
  }

  filterData() {
    this.rowArray = new FormArray([])
    this.addedRows = []

    let filteredRows = this.table.rows.filter(
      (row) => row.values.some((val) => val.match(this.filterText.value, 'i')) && row.status != DataGridStatus.DELETED
    )
    filteredRows?.forEach((row, index) => this.addRow(row, index, false))
    this.dataSource = [...this.rowArray.controls]
  }

  handleRefRow(type: string, givenInitialState: { [key: string]: any } = {}) {
    this.dataService.isModifyRefRowModalOpen = true
    this.dataService.selectedRow$.next(false) //making sure that the comp knows no row is selected
    const initialState = {
      table: givenInitialState.table ? givenInitialState.table : this.dataGrid.getMainTableData(),
      allRefTables: givenInitialState.allRefTables ? givenInitialState.allRefTables : this.refTables,
      parentTableToBeUpdated: givenInitialState.parentTableToBeUpdated,
      type
    }
    this.dataService.rowInRefTableHandlerType = type

    const modal = this.modalService.show(RowInRefTableHandlerModalComponent, {
      initialState,
      class: 'modal-lg'
    })

    modal.onHidden.subscribe(() => {
      const rowData = modal.content?.rowHandlerModalResultRow
      if (rowData) {
        this.dataService.rowHandlerModalResultRow = { ...rowData }
        this.dataService.rowHandlerModalResult$.next(true)
        this.dataService.selectedRow$.next(false)
      } else {
        this.dataService.rowHandlerModalResult$.next(false)
        this.dataService.selectedRow$.next(false)
      }
      this.dataService.isModifyRefRowModalOpen = false
    })
  }

  openNestedParentHandlerModal(rowNo: number, colNo: number) {
    let prevCols = this.mainTableData.dataSchema.length
    let selectedTableKey = this.table.key!
    let selectedTable: DataGridTableData
    for (let table of this.refTables) {
      if (colNo >= prevCols && colNo < prevCols + table.dataSchema.length) {
        selectedTableKey = table.key!
        selectedTable = table
        break
      }
      prevCols += table.dataSchema.length
    }
    if (selectedTableKey == this.table.key) return

    let selectedChildTable = this.refTables.find((x) => x.key == this.immediateChildDetails[selectedTableKey])!
    let colNoOfChildTable = this.table.dataSchema.length
    for (let x of this.refTables) {
      if (x.key == this.immediateChildDetails[selectedTableKey]) {
        break
      } else {
        colNoOfChildTable += x.dataSchema.length
      }
    }

    const modalRef = this.modalService.show(NestedParentHandlerModalComponent, {
      initialState: {
        table: selectedTable!,
        childTable: selectedChildTable,
        selectedChildRowValues: Object.values(this.rowArray.value[rowNo] as string).slice(
          colNoOfChildTable,
          colNoOfChildTable + selectedChildTable.dataSchema.length
        )
      }
    })

    modalRef.onHide.subscribe(() => {
      if (modalRef.content?.type) {
        let initialState: { [key: string]: any } = {}
        const refTables: DataGridTableData[] = []
        const addRefTableFun = (key: string) => {
          const table = this.refTables.find((table) => table.key == key)!
          refTables.push(table)
          table.referenceSchema.forEach((x) => {
            addRefTableFun(x.referenceKey)
          })
        }

        selectedTable.referenceSchema.forEach((key) => addRefTableFun(key.referenceKey))

        if (modalRef.content.type == 'edit') {
          this.setDetailsOfRowToBeHandled(prevCols, rowNo, selectedTable)

          this.dataService.rowHandlerModalResult$.pipe(first()).subscribe((isSaved) => {
            if (isSaved) {
              const modifiedRow = this.dataService.rowHandlerModalResultRow.rowWithAllExactValues
              this.setSelectedRowInRowArray(modifiedRow, rowNo, prevCols, selectedTableKey)

              this.rowRefIds.forEach((x, index) => {
                const rowIds = Object.values(x)[0]
                if (rowIds.includes(modifiedRow.rowId!)) {
                  this.updateSimilarRefRowsInRowArray(modifiedRow, index, prevCols)
                }
              })

              this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].splice(
                prevCols - this.table.dataSchema.length,
                this.dataService.rowHandlerModalResultRow.allRowIds.length,
                ...this.dataService.rowHandlerModalResultRow.allRowIds
              )
            }
            this.checkForModifiedRowCells()
          })

          initialState = {
            table: selectedTable,
            allRefTables: refTables
          }
        } else if (modalRef.content.type == 'add Parent') {
          this.setDetailsOfRowToBeHandled(colNoOfChildTable, rowNo, selectedChildTable)

          refTables.splice(0, 0, selectedTable)

          this.dataService.rowHandlerModalResult$.pipe(first()).subscribe((isSaved) => {
            if (isSaved) {
              const modifiedRowWithChildDetails = this.dataService.rowHandlerModalResultRow.rowWithAllExactValues
              const modifiedRow = {
                ...this.dataService.rowHandlerModalResultRow.updatedParentRowWithAllExactValues
              }

              this.setSelectedRowInRowArray(
                modifiedRow,
                rowNo,
                prevCols,
                selectedChildTable.key!,
                modifiedRowWithChildDetails
              )

              this.rowRefIds.forEach((x, index) => {
                const rowIds = Object.values(x)[0]
                if (rowIds.includes(modifiedRowWithChildDetails.rowId!)) {
                  this.updateSimilarRefRowsInRowArray(modifiedRow, index, prevCols)
                }
              })

              this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].splice(
                prevCols - this.table.dataSchema.length,
                this.dataService.rowHandlerModalResultRow.allRowIds.length,
                ...this.dataService.rowHandlerModalResultRow.allRowIds
              )
            }

            this.checkForModifiedRowCells()
          })

          initialState = {
            table: selectedChildTable,
            allRefTables: refTables,
            parentTableToBeUpdated: selectedTable
          }
        }

        this.handleRefRow(modalRef.content.type, initialState)
      }
    })
  }

  setDetailsOfRowToBeHandled(colNo: number, rowNo: number, table: DataGridTableData) {
    const totalLength = table.dataSchema.length + this.parentDetails[table.key!]
    let allRowIds = this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].slice(
      colNo - this.table.dataSchema.length,
      colNo - this.table.dataSchema.length + totalLength
    )

    let allExactValues = Object.values(this.rowArray.value[rowNo]).slice(colNo, colNo + totalLength)

    let refRow = new DataGridRow()
    refRow = table.rows.find(
      (x) => x.rowId == this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!][colNo - this.table.dataSchema.length]
    )!

    this.dataService.detailsOfRowToBeHandled = {
      row: refRow,
      allExactValues,
      allRowIds,
      rowInMainTable: this.addedRows[rowNo]
    }
  }

  //will be executed only for mainTable
  onOpeningTableDropdown(rowNo: number, colNo: number, dropdown: BsDropdownDirective): void {
    //close previously opened dropdown
    if (this.tableDropdownRef) {
      this.tableDropdownRef.hide()
    }

    let prevCols = this.mainTableData.dataSchema.length
    let selectedTableKey = this.table.key!
    let selectedTable = new DataGridTableData()
    for (let table of this.refTables) {
      if (colNo >= prevCols && colNo < prevCols + table.dataSchema.length) {
        selectedTableKey = table.key!
        selectedTable = table
        break
      }
      prevCols += table.dataSchema.length
    }
    if (selectedTableKey == this.table.key) return

    const width = this.tableComponent.nativeElement.querySelector(`#col${colNo}row${rowNo}`)?.clientWidth!
    let leftDistance = `-${70 + (colNo - prevCols) * width}px`

    const dropdownElement = document.getElementsByClassName('tableDropdown-right')[0]
    if (dropdownElement) (dropdownElement as HTMLElement).style.left = leftDistance

    this.currentRow = rowNo

    const addRefTableFun = (key: string) => {
      const table = this.getModifiedReferenceTables().find((table) => table.key == key)!
      this.refService.referencedTables.push(table)
      table.referenceSchema.forEach((x) => {
        addRefTableFun(x.referenceKey)
      })
    }
    this.refService = new MultiEntityDataGridIntegrationService()
    this.refService.database = this.createADeepCopyOfTable(
      this.getModifiedReferenceTables().find((table) => table.key == selectedTableKey)!
    )
    this.refService.database.referenceSchema.forEach((x) => {
      addRefTableFun(x.referenceKey)
    })
    this.refService.database.pageNumber = 1

    this.refService.currentPage = new DataGridTableData()
    this.refService.currentPage.name = this.refService.database.name
    this.refService.currentPage.key = this.refService.database.key
    this.refService.currentPage.dataSchema = this.refService.database.dataSchema
    this.refService.currentPage.referenceSchema = this.refService.database.referenceSchema
    this.refService.currentPage.ordered = this.refService.database.ordered
    this.refService.currentPage.rowCount = this.refService.database.rowCount
    this.refService.currentPage.pageNumber = this.refService.database.pageNumber
    this.refService.currentPage.pageSize = this.refService.database.pageSize
    this.refService.currentPage.rows = this.refService.database.rows.slice(0, this.refService.database.pageSize)
    this.refService.mainDataGrid = this.dataGrid

    const isRefRowEmpty =
      !this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!][prevCols - this.mainTableData.dataSchema.length]
    if (this.addedRows[rowNo].status == DataGridStatus.ADDED && isRefRowEmpty) {
      this.dataService.detailsOfRowToBeHandled = {
        row: new DataGridRow(),
        allExactValues: [],
        allRowIds: [],
        rowInMainTable: this.addedRows[rowNo]
      }
    } else {
      this.setDetailsOfRowToBeHandled(prevCols, rowNo, selectedTable)
    }

    const startCell = document.getElementById(`col${prevCols}row${rowNo}`) as HTMLElement
    startCell.classList.remove('l-shadow')
    const startCellDiv = document.getElementById(`col${prevCols}row${rowNo}div`) as HTMLElement
    startCellDiv.classList.add('leftBorder')
    const endCell = document.getElementById(
      `col${prevCols + (this.refService.database.dataSchema.length - 1)}row${rowNo}`
    ) as HTMLElement
    endCell.classList.remove('l-shadow')
    const endCellDiv = document.getElementById(
      `col${prevCols + (this.refService.database.dataSchema.length - 1)}row${rowNo}div`
    ) as HTMLElement
    endCellDiv.classList.add('rightBorder')

    this.isTableDropdownOpen = true
    this.tableDropdownRef = dropdown

    //show refCells as one
    this.refService.database.dataSchema.forEach((col, index) => {
      const element = document.getElementById(`col${prevCols + index}row${rowNo}div`) as HTMLElement
      element.style.display = 'block'
    })
    const caret = document.getElementById(
      `col${prevCols + (this.refService.database.dataSchema.length - 1)}row${rowNo}caret`
    ) as HTMLElement
    caret.style.display = 'block'

    this.dataService.selectedRow$.pipe(first()).subscribe((isSelected) => {
      if (isSelected) {
        let selectedRowData = this.dataService.selectedRowData
        this.setSelectedRowInRowArray(selectedRowData.row, rowNo, prevCols, selectedTableKey)

        this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].splice(
          prevCols - this.table.dataSchema.length,
          this.dataService.selectedRowData.allRowIds.length,
          ...this.dataService.selectedRowData.allRowIds
        )
      }
      this.removeCellFocus(colNo, rowNo)

      this.checkForModifiedRowCells()

      if (this.tableDropdownRef == dropdown) {
        this.tableDropdownRef.hide()
      }
    })

    this.dataService.rowHandlerModalResult$.pipe(first()).subscribe((isSaved) => {
      if (isSaved) {
        if (this.dataService.rowInRefTableHandlerType != 'add Parent') {
          if (this.dataService.rowInRefTableHandlerType == 'add') {
            const newRow = this.dataService.rowHandlerModalResultRow.rowWithAllExactValues
            const allAddedRowsInfo = this.dataService.rowHandlerModalResultRow.allAddedRowsInfo
            this.setSelectedRowInRowArray(newRow, rowNo, prevCols, selectedTableKey)

            const tableIndex = this.refTables.findIndex((table) => table.key == selectedTableKey)
            this.refTables[tableIndex] = this.createADeepCopyOfTable(this.refService.observer?.getModifiedTableData()!)

            //updating indexes of values of dataSchema and refSchema , before : ['rowId','rowId2','data1','data2'] , after:['data1','data2','rowId1','rowId2'];
            this.refTables[tableIndex].rows.forEach((row, i) => {
              const valuesOfRefSchemaCols = row.values.splice(0, selectedTable.referenceSchema.length)
              row.values.push(...valuesOfRefSchemaCols)
            })
            this.refTables[tableIndex].rows.push(allAddedRowsInfo[selectedTableKey])

            this.refService.observer?.getModifiedReferenceTables().forEach((table) => {
              const refTableIndex = this.refTables.findIndex((x) => x.key == table.key)
              this.refTables[refTableIndex] = this.createADeepCopyOfTable(table)
              this.refTables[refTableIndex].rows.forEach((row, i) => {
                const valuesOfRefSchemaCols = row.values.splice(0, table.referenceSchema.length)
                row.values.push(...valuesOfRefSchemaCols)
              })
            })
          } else if (this.dataService.rowInRefTableHandlerType == 'edit') {
            const modifiedRow = this.dataService.rowHandlerModalResultRow.rowWithAllExactValues
            this.setSelectedRowInRowArray(modifiedRow, rowNo, prevCols, selectedTableKey)

            this.rowRefIds.forEach((x, index) => {
              const rowIds = Object.values(x)[0]
              if (rowIds.includes(modifiedRow.rowId!)) {
                this.updateSimilarRefRowsInRowArray(modifiedRow, index, prevCols)
              }
            })
          }

          this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].splice(
            prevCols - this.table.dataSchema.length,
            this.dataService.rowHandlerModalResultRow.allRowIds.length,
            ...this.dataService.rowHandlerModalResultRow.allRowIds
          )
        }
      }
      this.removeCellFocus(colNo, rowNo)

      this.checkForModifiedRowCells()
    })
  }

  updateSimilarRefRowsInRowArray(row: DataGridRow, rowNo: number, prevCols: number) {
    //updating value in rowArray
    let rowValue: { [key: string]: any } = {}
    row.values.forEach((val, index) => {
      const offset = index + prevCols
      rowValue['col' + offset] = val
    })
    this.rowArray.controls[rowNo].patchValue(rowValue)

    //updating rowIds
    this.rowRefIds[rowNo][this.addedRows[rowNo].rowId!].splice(
      prevCols - this.table.dataSchema.length,
      this.dataService.rowHandlerModalResultRow.allRowIds.length,
      ...this.dataService.rowHandlerModalResultRow.allRowIds
    )
  }

  onClosingTableDropdown(rowNo: number, colNo: number, dropdown: BsDropdownDirective) {
    let prevCols = this.mainTableData.dataSchema.length
    let selectedTableKey = this.table.key!
    for (let table of this.refTables) {
      if (colNo >= prevCols && colNo < prevCols + table.dataSchema.length) {
        selectedTableKey = table.key!
        break
      }
      prevCols += table.dataSchema.length
    }
    if (selectedTableKey == this.table.key) return

    //undo refCells as one and close dropdown
    this.refService.database.dataSchema.forEach((col, index) => {
      const element = document.getElementById(`col${prevCols + index}row${rowNo}div`) as HTMLElement
      element.style.display = 'none'
    })
    const caret = document.getElementById(
      `col${prevCols + (this.refService.database.dataSchema.length - 1)}row${rowNo}caret`
    ) as HTMLElement
    caret.style.display = 'none'

    const startCell = document.getElementById(`col${prevCols}row${rowNo}`) as HTMLElement
    startCell.classList.remove('l-shadow')
    const endCell = document.getElementById(
      `col${prevCols + (this.refService.database.dataSchema.length - 1)}row${rowNo}`
    ) as HTMLElement
    endCell.classList.remove('l-shadow')

    startCell.classList.add('l-shadow')
    endCell.classList.add('l-shadow')

    this.checkForModifiedRowCells()

    if (!this.dataService.isModifyRefRowModalOpen) {
      this.dataService.selectedRow$.next(false)
      this.dataService.rowHandlerModalResult$.next(false)
    }

    this.tableDropdownRef = null
  }

  isANestedParent(colNo: number) {
    for (let table of this.mainTableData.referenceSchema) {
      const tableCols = this.mainTableData.dataSchema.length - 1 + this.lengthOfDataSchema[table.referenceKey]
      if (colNo > tableCols && colNo <= tableCols + this.parentDetails[table.referenceKey]) {
        return true
      }
    }
    return false
  }

  isModeEditTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.EDIT_TABLE_DATA
  }

  isModeEditTableSchema() {
    return this.dataGrid.getMode() == DataGridTableMode.EDIT_TABLE_SCHEMA
  }

  isModeEditRefTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.EDIT_REFERENCE_TABLE_DATA
  }

  isModeSelectRefTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.SELECT_REFERENCE_TABLE_DATA
  }

  isModeExportExcelTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.EXPORT_EXCEL_TABLE_DATA
  }

  isModeGetSelectedTableRows() {
    return this.dataGrid.getMode() == DataGridTableMode.GET_SELECTED_TABLE_ROWS
  }

  isModeSelectTableRow() {
    return this.dataGrid.getMode() == DataGridTableMode.SELECT_TABLE_ROW
  }

  isModeMapExtractedData() {
    return this.dataGrid.getMode() == DataGridTableMode.MAP_EXTRACTED_DATA
  }

  isViewWithPredefinedOptions() {
    return this.dataGrid.getMode() == DataGridTableMode.VIEW_WITH_PREDEFINED_OPTIONS
  }

  isMeasurementType(colNo: number) {
    let type = this.colDataTypes[colNo]
    return type && type.generic == 'number' && type.original == DataGridColumnType.NUMERIC
  }

  isEFType(colNo: number) {
    let type = this.colDataTypes[colNo]
    return type.original == DataGridColumnType.EMISSION_FACTOR
  }

  isPreDeterminedEFType(colNo: number) {
    let type = this.colDataTypes[colNo]
    return type.original == DataGridColumnType.EMISSION_FACTOR && type.isPreDetermined
  }

  isMultipleEFType(colNo: number) {
    const colName = this.mainSchema[colNo].name.toLowerCase()
    const rawTaxonomyData = _.find(
      (this.dataGrid as StageTableDataGridIntergationService).entity.columns,
      (taxonomAttribute) => taxonomAttribute.key.toLowerCase() == colName
    )
    const isMultiple =
      rawTaxonomyData.emissionFactors.length > 1 || !_.isEmpty(rawTaxonomyData.emissionFactors[0].condition)
    let type = this.colDataTypes[colNo]
    return type.original == DataGridColumnType.EMISSION_FACTOR && type.isPreDetermined && isMultiple
  }

  getPreDeterminedEF({ col }) {
    const colName = this.mainSchema[col].name.toLowerCase()
    const rawTaxonomyData = _.find(
      (this.dataGrid as StageTableDataGridIntergationService).entity.columns,
      (taxonomAttribute) => taxonomAttribute.key.toLowerCase() == colName
    )
    const emissionFactor = EmissionFactorConversionFe.fromTransfer(rawTaxonomyData.emissionFactors[0].value)
    return emissionFactor
  }

  onOpeningErrorMsg(className: string, dropdown: BsDropdownDirective | null = null): void {
    const dropdownElement = document.getElementsByClassName(className)[0]
    if (dropdownElement) {
      ;(dropdownElement as HTMLElement).style.bottom = '10px'
      ;(dropdownElement as HTMLElement).style.left = '145px'
    }
  }

  checkDataValidity() {
    for (let colNo = 0; colNo < this.isColumnHasValidValues.length; colNo++) {
      let isColumnValid = this.dataSource.every((rowControl) => this.getControl(rowControl, 'col' + colNo)?.valid)
      if (isColumnValid) {
        this.isColumnHasValidValues[colNo] = true
      } else {
        this.isColumnHasValidValues[colNo] = false
      }
    }
    return this.isColumnHasValidValues.every((isvalid) => isvalid == true)
  }

  toggleDataTypeCollapse(collapse: CollapseDirective) {
    if (this.isDataTypeHeaderCollapsed) {
      collapse.display = 'table-row-group'
    } else {
      collapse.display = 'none'
    }
    this.isDataTypeHeaderCollapsed = !this.isDataTypeHeaderCollapsed
  }

  selectColumn(index: number) {
    this.selectedColumn = index
    this.isADblClick = true
  }

  selectRow(index: number) {
    this.removeCellFocus(this.currentCol, this.currentRow)
    this.selectedColumn = null
    this.selectedRow = index
    this.isADblClick = true
  }

  openTraceSource(index: number, traceToSourceMenu: BsDropdownDirective, event: MouseEvent) {
    this.selectRow(index)
    this.openRightClickMenu(traceToSourceMenu, event)
  }

  exportRow() {
    if (this.selectedRow) {
      this.dataGrid.exportSelectedRows([this.addedRows[this.selectedRow]])
    }
  }

  disselectRowColumn() {
    this.isADblClick = false
    this.selectedRow = null
    this.selectedColumn = null
  }

  removeCellFocus(colNo: number, rowNo: number) {
    this.tableComponent.nativeElement.querySelector(`#col${colNo}row${rowNo}`)?.classList.remove('focusedBorder')
  }

  startSelection() {
    this.startSelectionForCopying = true
  }

  unselectCellsForCopying() {
    let classes = DragSelectClasses.getAllClassesRelatedToCopySelection()
    let allCells = Object.entries(this.tableComponent.nativeElement.querySelectorAll('td'))
    allCells.forEach((tag) => tag[1].classList.remove(...classes))
  }

  copyData(rowNo: number, colNo: number, isCuttingOperation = false) {
    this.disselectCellToBeCopied()
    if (this.selectedColumn == null && this.selectedRow == null) {
      this.cellToBeCopied = this.tableComponent.nativeElement.querySelector(`#col${colNo}row${rowNo}value`)
      this.cellToBeCopied?.offsetParent?.classList.add('copyCell')
      this.isCopyingCuttingFromCell = true
    } else {
      this.wasInside = true
      this.isCopyingCuttingRowColumn = true
    }
    this.isCutting = isCuttingOperation
  }

  disselectCellToBeCopied(): void {
    if (this.cellToBeCopied) {
      this.cellToBeCopied.offsetParent?.classList.remove('copyCell')
      this.cellToBeCopied = null
    }
  }

  pasteData(rowNo: number, colNo: number) {
    if (this.selectedColumn != null) {
      let totalRows = this.dataSource.length
      for (let index = 0; index < totalRows; index++) {
        if (rowNo + index < totalRows) {
          let copyFromControl = this.getControl(this.dataSource[index], 'col' + this.selectedColumn)
          let control = this.getControl(this.dataSource[rowNo + index], 'col' + colNo)
          control.setValue(copyFromControl.value)
          if (this.isCutting) {
            copyFromControl.setValue('')
          }
        }
      }
      this.disselectRowColumn()
    } else if (this.selectedRow != null) {
      let totalCols = this.colSource.length
      for (let index = 0; index < totalCols; index++) {
        if (colNo + index < totalCols) {
          let copyFromControl = this.getControl(this.dataSource[this.selectedRow], 'col' + index)
          let control = this.getControl(this.dataSource[rowNo], 'col' + (colNo + index))
          control.setValue(copyFromControl.value)
          if (this.isCutting) {
            copyFromControl.setValue('')
          }
        }
      }
      this.disselectRowColumn()
    } else if (this.cellToBeCopied) {
      let control = this.getControl(this.dataSource[rowNo], 'col' + colNo)
      control.setValue(this.cellToBeCopied.value)
      if (this.isCutting) {
        let rowIndex: any = this.cellToBeCopied.getAttribute('rowNo')
        let colNo = this.cellToBeCopied.getAttribute('colNo')
        if (rowIndex && colNo) {
          rowIndex = parseInt(rowIndex)
          this.dataSource[rowIndex].get(`col${colNo}`)?.setValue('')
        }
      }
      this.disselectCellToBeCopied()
    }
    this.checkForModifiedRowCells()
  }

  openRightClickMenu(menu: BsDropdownDirective, event: MouseEvent): void {
    menu.toggle(true)
    event.stopPropagation()
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  pasteCompleted(pasteComplete: boolean): void {
    if (pasteComplete) {
      this.checkForModifiedRowCells()
    }
  }

  checkForModifiedRowCells() {
    this.isValueChanged = this.addedRows.some((x) => x.status === DataGridStatus.MODIFIED)
    this.isSaved = !this.table.rows.some((x) => x.status !== DataGridStatus.STORED)
    this.dataGrid.markDataModified(!this.isSaved, this.checkDataValidity())
  }

  getColumnHeader(colNo: number): string {
    var headers = []
    var quotient, remainder, colName

    quotient = Math.floor(colNo / 26)
    remainder = colNo % 26
    colName = ''

    while (quotient > 0) {
      colName = String.fromCharCode(65 + ((quotient - 1) % 26)) + colName
      quotient = Math.floor((quotient - 1) / 26)
    }

    colName += String.fromCharCode(65 + remainder)
    headers.push(colName)
    let header = headers.join('')
    return header
  }

  isHeadlineRow(index: number): boolean {
    let isheadline = this.headlineRowsIndex.includes(index)
    return isheadline
  }

  markAsHeadline(index: number): void {
    this.headlineRowsIndex.push(index)
  }

  removeHeadline(index: number): void {
    this.headlineRowsIndex = this.headlineRowsIndex.filter((i) => i != index)
  }

  async selectFullSheet() {
    this.isLoading = true
    const len = this.rowArray.value.length
    const rowCount = this.table.rowCount!
    if (
      rowCount >= this.maxRowsToLoad &&
      len < rowCount &&
      !(this.isModeEditTableData() || this.isModeEditRefTableData())
    ) {
      this.fetchRows(len, rowCount)
    }
    setTimeout(() => {
      let arr = Object.entries(this.tableComponent.nativeElement.querySelectorAll('td:not(.headline) input.rowData'))
      arr.forEach((inputEl) => {
        let tdTag = inputEl[1].parentElement?.parentElement
        tdTag?.classList.add(DragSelectClasses.selectionClass)
      })
      this.extractedData = []

      for (const [rowNo, rowValues] of Object.entries(this.rowArray.value)) {
        if (!this.isHeadlineRow(Number(rowNo))) {
          const values = Object.values(rowValues).map((value: any, colNo: number) => ({ colNo, value }))
          this.extractedData.push(new SelectedData(Number(rowNo), values))
        }
      }

      this.diselectedColumnIndex = []
      this.diselectedRowIndex = []
      setTimeout(() => {
        this.setSelectedData()
        this.isLoading = false
      })
    }, 500)
  }

  selectDataFromColumn(colNo: number) {
    this.selectedRowIndex = []
    if (this.selectedColumnIndex.length == 0) {
      this.removeSelectedCellClass([DragSelectClasses.selectionClass])
    }
    if (this.selectedColumnIndex.includes(colNo)) {
      this.selectedColumnIndex = this.selectedColumnIndex.filter((c) => c != colNo)
    } else {
      this.selectedColumnIndex.push(colNo)
    }
    this.setSelectedData()
  }

  selectDataFromRow(rowNo: number) {
    this.selectedColumnIndex = []
    if (this.selectedRowIndex.length == 0) {
      this.removeSelectedCellClass([DragSelectClasses.selectionClass])
    }
    if (this.selectedRowIndex.includes(rowNo)) {
      this.selectedRowIndex = this.selectedRowIndex.filter((c) => c != rowNo)
    } else {
      this.selectedRowIndex.push(rowNo)
    }
    this.setSelectedData()
  }

  removeSelectedCellClass(className: string[]) {
    let allCells = Object.entries(this.tableComponent.nativeElement.querySelectorAll('td'))
    allCells.forEach((tag) => tag[1].classList.remove(...className))
  }

  isColumnExtracted(colNo: number) {
    let isExtracted = this.extractedColumnNos.includes(colNo)
    return isExtracted
  }

  hasAnyNewRows() {
    let anyNewRows = this.mainTableData.rows.filter((r) => r.status == DataGridStatus.ADDED)
    return anyNewRows.length > 0
  }

  isSourceDataRequest(source: string) {
    return source == SourceFe.DATA_REQUEST
  }

  isSourceDirectEntry(source: string) {
    return source == SourceFe.DIRECT_ENTRY
  }

  isSourceAutomatedFeed(source: string) {
    return source == SourceFe.AUTOMATED_FEED
  }

  hideColumn(colNo: number) {
    this.hiddenColumns.push(colNo)
  }

  unhideCol(colNo: number) {
    this.hiddenColumns = this.hiddenColumns.filter((col) => col !== colNo)
  }

  getColumnName(colNo: number) {
    return this.mainSchema[colNo].label
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  getMeasurementType(i: number) {
    const includeMeasurementTypes = new Set()
    if (this.colDataTypes[i].measurementKey) includeMeasurementTypes.add(this.colDataTypes[i].measurementKey)
    return includeMeasurementTypes
  }

  totalLength() {
    let length = 0
    this.colDataTypes.forEach((col, i) => {
      ;((this.isMeasurementType(i) && col.showUnitCol) || this.isEFType(i)) && !col.isCalculated
        ? (length += 2)
        : length++
    })
    return length
  }

  setUnitSelectorPos(event: any) {
    var elDistanceToTop = window.scrollY + event.target.getBoundingClientRect().top
    var elDistanceToLeft = window.scrollX + event.target.getBoundingClientRect().left
    let unitDropdown = document.getElementsByClassName('dropdown-menu inside-table show')
    if (unitDropdown[0]) {
      unitDropdown[0]['top'] = elDistanceToTop
      unitDropdown[0]['left'] = elDistanceToLeft
    }
  }

  getEntityCol(colNo: number) {
    if (this.dataGrid.getEntityCols) {
      const cols = this.dataGrid.getEntityCols()
      return cols.find((c) => c.dbColName() == this.mainSchema[colNo].name)
    }
  }

  isModeViewTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.VIEW
  }

  setSelectedRow(rowId: string) {
    let index = this.addedRows.findIndex((r) => r.rowId === rowId)
    if (index >= 0) {
      this.selectRow(index)
    }
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = 'modal-xl') {
    let config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: className
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  //Form View
  @Input() isFormView: boolean = false
  @Input() isSMView: boolean = true
  @Input() isPreviewMode: boolean
  // @Output() rowCountChange = new EventEmitter<number>();

  editingColumnIndex: number | null = null

  selectedEntryIndex: number = 0
  rowCount: number

  updateSelectedEntry(event: Event) {
    const selectElement = event.target as HTMLSelectElement
    this.selectedEntryIndex = parseInt(selectElement.value, 10)
    if (isNaN(parseInt(selectElement.value))) {
      this.addNewFormRow()
      this.selectedEntryIndex = this.dataSource.length - 1
    }
  }

  toggleView() {
    this.isFormView = !this.isFormView // Toggle between views
  }

  startEdit(index: number): void {
    this.editingColumnIndex = index
  }

  cancelEdit() {
    this.editingColumnIndex = null
  }

  saveEdit(index: number): void {
    this.cancelEdit() // Implement save logic here
  }

  addNewFormRow() {
    // const newColumn = new FormGroup({
    //   columnName: new FormControl('Entry ' + (this.colAray.length + 1)),
    //   dataType: new FormControl('STRING'),
    //   questionText: new FormControl({ value: '', disabled: true }),
    //   description: new FormControl({ value: '', disabled: true })
    // });
    // this.colAray.push(newColumn);
    this.emitRowCount()

    this.addRowAfter(this.dataSource.length - 1)
  }

  emitRowCount() {
    // this.rowCount = this.colAray.length;
    // this.rowCountChange.emit(this.colAray.length);
  }

  updateRowCount() {
    this.emitRowCount()
  }

  getDataType(index: number): string {
    return this.colAray.at(index).get('dataType').value
  }

  setDataType(index: number, dataType: string) {
    this.colAray.at(index).get('dataType').setValue(dataType)
  }

  onCogIconClick(index: number) {
    if (this.isSMView) {
      this.editingColumnIndex = index
    }
  }

  cancelEditRow() {
    this.editingColumnIndex = -1
  }

  saveEditRow(index: number) {
    // Implement save logic here
    this.editingColumnIndex = -1
  }

  // Modal for long text input
  currentText: string = ''
  @ViewChild('textModal', { static: true }) textModal: TemplateRef<any>
  @ViewChild('textModalNonEditable', { static: true }) textModalNonEditable: TemplateRef<any>

  openTextModal(text: string, rowNo: number, colNo: number) {
    this.currentRow = rowNo
    this.currentCol = colNo
    const control = this.getControl(this.dataSource[rowNo], `col${colNo}`)
    this.currentText = control.value || ''

    // Preserve line breaks
    this.currentText = this.currentText.replace(/(\r\n|\n|\r)/gm, '\n')

    this.modalRef = this.modalService.show(this.textModal, {
      class: 'modal-lg'
    })

    this.modalService.onShown.subscribe(() => {
      const textarea = document.getElementById('editableModalText') as HTMLTextAreaElement
      if (textarea) {
        textarea.value = this.currentText
        textarea.focus()
        textarea.setSelectionRange(textarea.value.length, textarea.value.length)
      }
    })

    this.cdr.markForCheck()
  }

  updateCellValue(text: string, rowNo: number, colNo: number) {
    let control = this.getControl(this.dataSource[rowNo], `col${colNo}`)
    control.setValue(text)
    this.changeColor(rowNo, colNo)
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide()
      this.modalRef = null
    }
    this.cdr.detectChanges()
    this.cdr.markForCheck()
  }

  expandTextInput(event: any, rowNo: number, colNo: number) {
    const control = this.getControl(this.dataSource[rowNo], `col${colNo}`)
    const text = control.value || ''

    if (text.length > 25) {
      this.openTextModal(text, rowNo, colNo)
    } else {
      this.hideModal()
    }

    // Update the input field
    const inputElement = event.target as HTMLInputElement
    const lines = text.split('\n')
    inputElement.value = lines[0] + (lines.length > 1 ? '...' : '')

    this.changeColor(rowNo, colNo)
  }

  public saveModalText(): void {
    if (this.modalRef) {
      let updatedText = (document.getElementById('editableModalText') as HTMLTextAreaElement).value
      let control = this.getControl(this.dataSource[this.currentRow], `col${this.currentCol}`)
      control.setValue(updatedText)

      // Update the input field
      const inputElement = document.getElementById(
        `col${this.currentCol}row${this.currentRow}value`
      ) as HTMLInputElement
      if (inputElement) {
        const lines = updatedText.split('\n')
        inputElement.value = lines[0] + (lines.length > 1 ? '...' : '')
      }

      this.changeColor(this.currentRow, this.currentCol)
      this.hideModal()
    }
  }

  // Double click event
  openTextModalNonEditable(event: MouseEvent, rowNo: number, colNo: number) {
    event.preventDefault()
    event.stopPropagation()

    const control = this.getControl(this.dataSource[rowNo], `col${colNo}`)
    this.currentText = control.value || ''

    this.modalRef = this.modalService.show(this.textModalNonEditable, {
      class: 'modal-lg'
    })
    let dropDown = document.getElementById('dropdownMenu') as HTMLElement
    dropDown.classList.remove('show')
  }

  handleLongTextClick(event: any, rowNo: number, i: number) {
    const editMode = (this.isModeEditTableData() || this.isModeEditRefTableData()) && !this.colDataTypes[i].isCalculated
    if (editMode) {
      this.removeReadOnly(event.target, 'row')
      if (this.colDataTypes[i].generic === 'text') {
        const control = this.getControl(this.dataSource[rowNo], `col${i}`)
        const text = control.value || ''
        this.currentText = text

        // Focus on the input element
        const inputElement = event.target as HTMLInputElement
        inputElement.focus()

        // Move cursor to the end of the input
        const len = inputElement.value.length
        inputElement.setSelectionRange(len, len)

        // Prevent default to avoid any unwanted behavior
        event.preventDefault()
      }
    }
    this.changeColor(rowNo, i)
  }

  handleKeyPress(event: KeyboardEvent, rowNo: number, i: number) {
    const control = this.getControl(this.dataSource[rowNo], `col${i}`)
    const currentValue = control.value || ''
    const newValue = currentValue + event.key
    control.setValue(newValue)
    event.preventDefault()
  }

  handleInputChange(event: any, rowNo: number, i: number) {
    let input = event.target
    let text = input.value || ''
    const control = this.getControl(this.dataSource[rowNo], `col${i}`)

    // Handle paste event
    if (event.type === 'paste') {
      event.preventDefault()
      const pastedText = (event.clipboardData || window.Clipboard).getData('text')
      text = pastedText.replace(/(\r\n|\n|\r)/gm, '\n')
    }

    control.setValue(text)

    const lines = text.split('\n')
    input.value = lines[0] + (lines.length > 1 ? '...' : '')

    if (this.colDataTypes[i].generic === 'text' && text.length > 25) {
      this.expandTextInput(event, rowNo, i)
    } else {
      this.hideModal()
    }

    this.changeColor(rowNo, i)
  }

  openEfDetailTab(ef: AbstractEmissionFactorFe) {
    this.displayService.openEfDetailsTab(ef)
  }

  clearText() {
    setTimeout(() => {
      this.currentText = ''
      this.cdr.detectChanges()
      this.cdr.markForCheck()
    }, 0)
  }

  handleDragStart(event: DragEvent, rowNo: number, i: number) {
    const control = this.getControl(this.dataSource[rowNo], `col${i}`)
    const text = control.value || ''
    event.dataTransfer?.setData('text/plain', text)
    event.dataTransfer?.setData('application/json', JSON.stringify({ formattedText: text }))

    // Preserve the original cell's content
    this.dataSource[rowNo][`col${i}`] = text
  }

  handleDrop(event: DragEvent, rowNo: number, i: number) {
    event.preventDefault()
    let text: string

    try {
      const jsonData = event.dataTransfer?.getData('application/json')
      if (jsonData) {
        const data = JSON.parse(jsonData)
        text = data.formattedText
      } else {
        text = event.dataTransfer?.getData('text/plain') || ''
      }
    } catch (error) {
      text = event.dataTransfer?.getData('text/plain') || ''
    }

    const control = this.getControl(this.dataSource[rowNo], `col${i}`)
    control.setValue(text)

    // Update input value to show first line with ellipsis if multiline
    const lines = text.split('\n')
    const inputElement = event.target as HTMLInputElement
    inputElement.value = lines[0] + (lines.length > 1 ? '...' : '')

    this.changeColor(rowNo, i)
  }

  setUserQuestion(colNo: number, question: string): void {
    this.mainSchema[colNo]['userQuestion'] = question
  }

  getUserQuestion(colNo: number): string {
    return this.mainSchema[colNo] && this.mainSchema[colNo].hasOwnProperty('userQuestion')
      ? this.mainSchema[colNo]['userQuestion']
      : ''
  }

  getTitleOrUserQuestionOf(colNo: number): string {
    return this.mainSchema[colNo].userQuestion || this.mainSchema[colNo].label
  }

  setUserDescription(colNo: number, question: string): void {
    this.mainSchema[colNo]['userQuestionDescription'] = question
  }

  getUserDescription(colNo: number): string {
    return this.mainSchema[colNo] && this.mainSchema[colNo].hasOwnProperty('userQuestionDescription')
      ? this.mainSchema[colNo]['userQuestionDescription']
      : ''
  }

  //Fixing Enter key issue
  handleKeyDown(event: KeyboardEvent, rowNo: number, colNo: number) {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  isUnitOnly(predefinedOptions: PredefinedNumberOptionFe[] = []): boolean {
    return (
      predefinedOptions &&
      predefinedOptions.length === 1 &&
      predefinedOptions[0].value == null &&
      predefinedOptions[0].value !== 0
    )
  }

  getUnitString(unit) {
    return UnitUtilFe.getUnitString(unit)
  }

  getTitleDescriptionModelTitle(): string {
    return this.slocale(`Title and description: ${this.selectedColumnForPredefinedOption.label}`)
  }

  getInfoTitleDescriptionModelTitle(): string {
    return this.slocale(`${this.selectedColumnForPredefinedOption.label}`)
  }

  applyUserTitleQuestionChanges() {
    if (this.tempUserQuestion) {
      this.setUserQuestion(this.selectedCurrentIndex, this.tempUserQuestion)
    }

    if (this.tempUserQuestionDesccription) {
      this.setUserDescription(this.selectedCurrentIndex, this.tempUserQuestionDesccription)
    }

    this.closeModal()
  }

  closeAllPopovers() {
    console.log('closeAllPopovers')
    this.popovers.forEach((popover) => popover.hide())
  }

  trackById(index: number): number {
    return index
  }
  dropdownMenuWidth(event: Event): void {
    const element = event.target as HTMLElement
    const parentWithClass = element.closest('.dropdown-toggle') as HTMLElement
    const parentWidth = parentWithClass?.offsetWidth || 150

    this.dropdownStyles = {
      width: `${parentWidth}px`
    }
  }
}
