export abstract class Answer_AddFe {
  id: string
  sectionId: string
  questionId: string
  questionType: string
  comments: string | null

  isSubmitted = false

  constructor(id: string, sectionId: string, questionId: string, questionType: string, comments: string | null) {
    this.id = id
    this.sectionId = sectionId
    this.questionId = questionId
    ;(this.questionType = questionType), (this.comments = comments)
  }

  public abstract isInvalid(isRequired: boolean): boolean
  public abstract hasAnyValue(): boolean
  public abstract reset()
  public abstract toTransfer(): Answer_AddFe
}
