import { Component, OnInit } from '@angular/core'
import { LoginUserFe } from 'src/app/model/org/LoginUserFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends AbstractLanguageComponent implements OnInit {
  public notifications: any
  public searchText: any
  public isAscendingSort: boolean = false
  public sortType: string
  public sortReverse: boolean = false
  public activeClass: string = ''
  public showContainer: boolean = false
  public date: any
  public tab: any = ''

  constructor(
    private backendAS: RouterFe,
    public stateService: StateServiceFe,
    languageService: LanguageService
  ) {
    super(languageService)
  }

  ngOnInit() {
    this.loadAll()
  }

  selectFilter(check) {
    if (check == 'Inbox') {
      this.tab = 'Inbox'
    } else if (check == 'Deleted') {
      this.tab = 'Deleted'
    } else if (check == 'Requests') {
      this.tab = 'Requests'
    } else {
      this.tab = 'Files'
    }
  }

  myFunction() {
    document.getElementById('clickdetails').style.display = 'block'
  }

  parse(summary) {
    return JSON.parse(summary)
  }

  public async loadAll() {
    this.selectFilter('Inbox')
    let notifications = await this.backendAS.listAllNotifications()
    notifications.forEach((element) => {
      var utcDate = element.time_stamp.value
      var localDate = new Date(utcDate)
      element.time_stamp.value = localDate.toLocaleString()
    })
    this.notifications = notifications
  }

  public async loadDeleted() {
    this.selectFilter('Deleted')
    let deletedNotifications = await this.backendAS.getDeletedNotifications()
    deletedNotifications.forEach((element) => {
      var utcDate = element.time_stamp.value
      var localDate = new Date(utcDate)
      element.time_stamp.value = localDate.toLocaleString()
    })
    this.notifications = deletedNotifications
  }

  public async loadRequests() {
    this.selectFilter('Requests')
    let extNotifications = await this.backendAS.getExternalRequestsNotifications()
    extNotifications.forEach((element) => {
      var utcDate = element.time_stamp.value
      var localDate = new Date(utcDate)
      element.time_stamp.value = localDate.toLocaleString()
    })
  }

  public async loadFiles() {
    this.selectFilter('Files')
    let fileNotifications = await this.backendAS.getFileNotifications()
    fileNotifications.forEach((element) => {
      var utcDate = element.time_stamp.value
      var localDate = new Date(utcDate)
      element.time_stamp.value = localDate.toLocaleString()
    })
  }

  public async markDeleted(notification) {
    if (notification.status !== 'DELETED') {
      let done = await this.backendAS.markNotificationDeleted(notification)
      this.notifications = this.notifications.filter((n) => n.id != notification.id)
    }
  }

  public async markRead(notification) {
    if (notification.status !== 'READ' && notification.status !== 'DELETED') {
      let done = await this.backendAS.markNotificationRead(notification)
      let n = this.notifications.find((n) => n.id == notification.id)
      n.status = 'READ'
      this.stateService.markNotificationRead(n)
    } else {
    }
  }

  public async delete(notification) {
    let done = await this.backendAS.deleteNotification(notification)
    this.notifications = this.notifications.filter((n) => n.id != notification.id)
  }
}
