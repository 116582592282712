<div class="m-0 scroll" [ngClass]="{ card: !isDataExtractor }">
  <div *ngIf="stageTable.loadInProgress">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="overflow: hidden" *ngIf="stageTable.dataGridService && !stageTable.loadInProgress">
    <div class="d-flex flex-row mx-0 px-0" style="overflow: hidden" [ngClass]="{ card: !isDataExtractor }">
      <div class="col-12 m-auto" style="overflow: hidden">
        <div class="card-head mx-0 px-0">
          <div class="card-header p-0 pt-1" *ngIf="!this.isDataExtractor">
            <div class="btn-toolbar d-flex justify-content-between">
              <div class="d-flex flex-row col-9 p-0 flex-grow-1 m-0">
                <ul class="list-inline m-0 ps-3 d-flex flex-row flex-grow">
                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.duration.title') }}:</a>
                  <li class="list-inline-item m-0">
                    <a class="badge btn-info text-white">
                      <span class="m-0">{{ stageTable.timeFromString }}</span>
                    </a>
                  </li>
                  <a class="m-0">-</a>
                  <li class="list-inline-item m-0">
                    <a class="badge btn-info text-white">
                      <span class="m-0">{{ stageTable.timeToString }}</span>
                    </a>
                  </li>
                </ul>
                <ul class="list-inline m-0 ps-3">
                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.source.title') }}:</a>
                  <li class="list-inline-item m-0">
                    <a
                      class="badge text-white"
                      [ngStyle]="{ 'background-color': stageTable.fileType().color }"
                      *ngIf="stageTable.sourceFile"
                    >
                      <i class="la {{ stageTable.fileType().icon }} margin-ft"></i>
                      <span class="m-0">{{ stageTable.sourceFile }}</span>
                    </a>
                  </li>
                </ul>
                <ul class="list-inline m-0 ps-3">
                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.status.title') }}:</a>
                  <li class="list-inline-item m-0">
                    <a
                      class="badge text-white"
                      [ngStyle]="{ 'background-color': stageTable.statusColor() }"
                      style="color: white"
                    >
                      <i class="la {{ stageTable.statusIcon() }} margin-ft"></i>
                      <span class="m-0">{{ locale(stageTable.statusLocaleKey()) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-2">
                <button
                  class="btn mx-1 py-1 mb-1 tertiary-button float-end"
                  [disabled]="!stageTable.taxonomyEntity"
                  (click)="openMasterTable()"
                >
                  {{ locale('locale_key.pages.datahub.data_entry.button.go_to_master_table') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="" style="max-height: 81vh; overflow-y: auto">
          <file-upload-picker
            *ngIf="isDataExtractor"
            [destTableService]="toCopyStageTableService"
            (dataExtractedEvent)="dataExtracted($event)"
            (closeEvent)="closeFileExtractor(stageTable)"
          ></file-upload-picker>
          <datagrid-table-editor
            [ngClass]="{ hidden: isDataExtractor }"
            [dataGrid]="stageTable.dataGridService"
          ></datagrid-table-editor>
        </div>
      </div>
    </div>
  </div>
</div>
