import { Component, OnInit } from '@angular/core'
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
@Component({
  selector: 'app-rotatepassword',
  templateUrl: './rotatepassword.component.html',
  styleUrls: ['./rotatepassword.component.scss']
})
export class RotatePasswordComponent extends AbstractLanguageComponent implements OnInit {
  passwordForm = new FormGroup(
    {
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.NewPasswordRegex)]),
      confirmPassword: new FormControl('', [Validators.required])
    },
    { validators: this.passwordMatchValidator }
  )

  forbiddenEmails: any
  successMessage: string
  loading = false
  IsvalidForm = true
  submitted = false
  Message: string
  validLogin: boolean
  uuid: any
  passwordVisibleMap = new Map()

  constructor(
    private router: Router,
    private backendService: RouterFe,
    private alertService: AlertServiceFe,
    private ErrorsFe: ErrorsFe,
    languageService: LanguageService,
    private loginService: LoginServiceFe
  ) {
    super(languageService)
  }

  async ngOnInit() {
    this.passwordVisibleMap.set('currentPassword', false)
    this.passwordVisibleMap.set('newPassword', false)
    this.passwordVisibleMap.set('confirmPassword', false)
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('newPassword').value
    const confirmPassword = formGroup.get('confirmPassword').value

    if (newPassword !== confirmPassword) {
      formGroup.get('confirmPassword').setErrors({ notMatch: true })
    } else {
      return null
    }
  }

  async onSubmit() {
    this.submitted = true
    this.loading = false

    if (this.passwordForm.invalid) {
      return
    }

    var passwordRequest = {
      currentPassword: this.passwordForm.value.currentPassword,
      newPassword: this.passwordForm.value.newPassword,
      confirmPassword: this.passwordForm.value.confirmPassword
    }

    this.loading = true
    try {
      let changed = await this.backendService.changePassword(passwordRequest)
      if (changed) {
        this.loading = false
        this.validLogin = false
        this.alertService.showSuccess(this.locale('locale_key.password-rotation.your_password_successfully_changed'))
        this.loginService.logout()
      }
    } catch (error) {
      this.loading = false
      this.validLogin = false
      const knownError = error.error
      if (knownError) {
        let knownError = this.ErrorsFe.matchError(error.error)
        if (knownError === OpenErrorsFe.PASSWORD_IS_ALREADY_USED) {
          this.passwordForm.get('newPassword').setErrors({ previouslyUsed: true })
          this.passwordForm.get('confirmPassword').setErrors({ previouslyUsed: true })
        } else if (
          knownError === OpenErrorsFe.INCORRECT_PASS_ERROR_WHILE_CHANGING_PASS ||
          knownError === OpenErrorsFe.INCORRECT_PASS_ERROR ||
          knownError === OpenErrorsFe.UNMATCHED_PASSES_ERROR ||
          knownError === OpenErrorsFe.USER_NOT_FOUND_ERROR
        ) {
          this.alertService.showError(knownError.message)
        } else {
          this.alertService.showError(this.locale('locale_key.password-rotation.it_wasnot_possible_to_change_password'))
        }
      }
    }
  }

  isPasswordVisible(key): boolean {
    return this.passwordVisibleMap.get(key)
  }

  togglePassword(key: string) {
    let isVisible = !this.passwordVisibleMap.get(key)
    this.passwordVisibleMap.set(key, isVisible)
    if (isVisible) {
      ;(document.getElementById(key) as HTMLInputElement).type = 'text'
    } else {
      ;(document.getElementById(key) as HTMLInputElement).type = 'password'
    }
  }
}
