<body
  class="horizontal-layout horizontal-menu-padding 1-column blank-page pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar"
  data-open="click"
  data-menu="horizontal-menu"
  data-col="1-column"
  data-gr-c-s-loaded="true"
>
  <div class="pace pace-inactive">
    <div
      class="pace-progress"
      data-progress-text="100%"
      data-progress="99"
      style="transform: translate3d(100%, 0px, 0px)"
    >
      <div class="pace-progress-inner"></div>
    </div>
    <div class="pace-activity"></div>
  </div>
  <div class="app-content container center-layout">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row"></div>
      <div class="content-body">
        <section class="row flexbox-container">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-header align text-center">
                    <strong>{{ locale('locale_key.password-rotation.welcome_back') }}</strong>
                    <p>
                      {{ locale('locale_key.password-rotation.its_been_while_since_last_change') }}
                    </p>
                  </div>
                  <div class="card-body">
                    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0" for="currentPassword">
                          {{ locale('locale_key.password-rotation.enter_your_current_password') }}
                        </label>

                        <div class="input-group">
                          <input
                            type="password"
                            id="currentPassword"
                            formControlName="currentPassword"
                            name="currentPassword"
                            class="form-control pr-2"
                            [ngClass]="{ 'is-invalid': submitted && passwordForm.get('currentPassword').errors }"
                            placeholder="{{ locale('locale_key.password-rotation.enter_current_password') }}"
                          />
                          <i
                            *ngIf="!isPasswordVisible('currentPassword')"
                            (click)="togglePassword('currentPassword')"
                            class="passwordToggle la la-eye-slash"
                            aria-hidden="true"
                          ></i>
                          <i
                            *ngIf="isPasswordVisible('currentPassword')"
                            (click)="togglePassword('currentPassword')"
                            class="passwordToggle la la-eye"
                            aria-hidden="true"
                          ></i>
                          <div *ngIf="submitted && passwordForm.get('currentPassword').errors" class="invalid-feedback">
                            <div *ngIf="passwordForm.get('currentPassword').errors.required">
                              {{ locale('locale_key.wizard.password_is_required.message') }}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0" for="password">
                          {{ locale('locale_key.password-rotation.enter_your_new_password') }}
                        </label>
                        <div>
                          <span class="mb-0 password-info" for="currentPassword">
                            {{ locale('locale_key.pages.profile.security.password.minimum_requirements') }}
                          </span>
                        </div>
                        <div class="input-group">
                          <input
                            type="password"
                            id="newPassword"
                            formControlName="newPassword"
                            name="newPassword"
                            class="form-control pr-2"
                            [ngClass]="{ 'is-invalid': submitted && passwordForm.get('newPassword').errors }"
                            placeholder="{{ locale('locale_key.password-rotation.enter_new_password') }}"
                          />
                          <i
                            *ngIf="!isPasswordVisible('newPassword')"
                            (click)="togglePassword('newPassword')"
                            class="passwordToggle la la-eye-slash"
                            aria-hidden="true"
                          ></i>
                          <i
                            *ngIf="isPasswordVisible('newPassword')"
                            (click)="togglePassword('newPassword')"
                            class="passwordToggle la la-eye"
                            aria-hidden="true"
                          ></i>
                          <div *ngIf="submitted && passwordForm.get('newPassword').errors" class="invalid-feedback">
                            <div *ngIf="passwordForm.get('newPassword').errors.required">
                              {{ locale('locale_key.wizard.password_is_required.message') }}
                            </div>
                            <div *ngIf="passwordForm.get('newPassword').errors.pattern">
                              {{
                                locale('locale_key.general.validation_message.password_rotation.not_meet_requirements')
                              }}
                            </div>
                            <div *ngIf="passwordForm.get('newPassword').errors.previouslyUsed"></div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0" for="confirmPassword">
                          {{ locale('locale_key.password-rotation.re_enter_your_new_password') }}
                        </label>
                        <div class="input-group">
                          <input
                            type="password"
                            id="confirmPassword"
                            formControlName="confirmPassword"
                            name="confirmPassword"
                            class="form-control pr-2"
                            [ngClass]="{ 'is-invalid': submitted && passwordForm.get('confirmPassword').errors }"
                            placeholder="{{ locale('locale_key.password-rotation.re_enter_new_password') }}"
                          />
                          <i
                            *ngIf="!isPasswordVisible('confirmPassword')"
                            (click)="togglePassword('confirmPassword')"
                            class="passwordToggle la la-eye-slash"
                            aria-hidden="true"
                          ></i>
                          <i
                            *ngIf="isPasswordVisible('confirmPassword')"
                            (click)="togglePassword('confirmPassword')"
                            class="passwordToggle la la-eye"
                            aria-hidden="true"
                          ></i>
                          <div *ngIf="submitted && passwordForm.get('confirmPassword').errors" class="invalid-feedback">
                            <div *ngIf="passwordForm.get('confirmPassword').errors.required">
                              {{ locale('locale_key.wizard.password_is_required.message') }}
                            </div>
                            <div *ngIf="passwordForm.get('confirmPassword').errors.notMatch">
                              {{ locale('locale_key.general.validation_message.new_password_confirm_not_match') }}
                            </div>
                            <div *ngIf="passwordForm.get('confirmPassword').errors.previouslyUsed">
                              {{ locale('locale_key.pages.profile.security.password.used_previously') }}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <div style="border-top: 1px solid #e4e5ec; margin-bottom: 15px"></div>
                      <div class="form-group">
                        <button [disabled]="loading" type="submit" class="btn btn-outline-info btn-block w-100">
                          <i class="fas fa-unlock"></i>
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          {{ locale('locale_key.password-rotation.save_new_password') }}
                        </button>
                      </div>
                      <div
                        class="alert-message text-center alert alert-warning alert-dismissible"
                        *ngIf="validLogin"
                        role="alert"
                      >
                        <strong>{{ Message }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div
                        class="alert-message text-center alert alert-warning alert-dismissible"
                        *ngIf="validLogin"
                        role="alert"
                      >
                        <strong>{{ successMessage }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</body>
