import { MathJsInstance } from 'mathjs'

export class CheckFormulaUnitCompatibility {
  public static checkAddition(expression: string, unitEvaluator: MathJsInstance) {
    const splitTokens = expression.split('+')
    let commonUnit = ''
    // check every token is evaluated to the same unit

    for (let i = 0; i < splitTokens.length; i++) {
      // first replace ")(" with * to evaluate the expression
      let token = splitTokens[i].replace(/\)\(/g, '*')
      // replace ( and ) with empty string
      token = token.replace(/\(|\)/g, '')
      try {
        const evaluatedToken = unitEvaluator.evaluate(token).toString()
        const evaluatedUnit = evaluatedToken.split(' ')[1]
        if (i === 0) {
          commonUnit = evaluatedUnit
        } else if (commonUnit !== evaluatedUnit) {
          return false
        }
      } catch (_) {
        return false
      }
    }
    return true
  }
}
