<div class="formula_operand_dropdown">
  <div class="title">{{ locale('locale_key.pages.emission.select_what_you_like_insert_here') }}</div>

  <div class="operand_groups">
    <div class="operand_group">
      <ng-container *ngIf="showDatapoints">
        <div class="dropdown datapoints_dropdown">
          <div class="choose_datapoint_container" data-bs-toggle="dropdown" data-bs-auto-close="outside">
            <div class="choose_datapoint_label">
              <ng-container *ngIf="!token?.datapoint">
                {{ locale('locale_key.pages.emission.choose_data_point') }}
              </ng-container>
              <ng-container *ngIf="token?.datapoint">
                {{ getLabel({ datapoint: token.datapoint }) }}
              </ng-container>
            </div>
            <span class="caret">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                <path
                  d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                  stroke="#6B6E80"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div class="dropdown-menu">
            <div class="datapoints_dropdown_content">
              <div class="search">
                <i class="search_icon las la-search"></i>
                <input
                  class="search_input"
                  [(ngModel)]="otherDatapointsFilter"
                  type="text"
                  placeholder="{{ locale('locale_key.pages.emission.search_for_data_point') }}"
                />
                <i class="clear_search_icon las la-times" (click)="clearOtherDatapointsFilter()"></i>
              </div>
              <div class="datapoint_list">
                <div
                  class="datapoint"
                  [class.selected_datapoint]="token?.datapoint?.key && datapoint?.key == token?.datapoint?.key"
                  *ngFor="let datapoint of getOtherDatapoints(selectedDatapoint)"
                  (click)="setDatapointOperand({ tokenIdx, datapoint })"
                >
                  <ng-container *ngIf="datapoint.datatype == 'EMISSION_FACTOR'">
                    <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
                    <span>{{ resolveLabel({ token: { datapoint }, deployed: true }) }}</span>
                    <b class="ms-2">({{ resolveUnit({ token: { datapoint }, deployed: true }) }})</b>
                  </ng-container>
                  <ng-container *ngIf="datapoint.datatype != 'EMISSION_FACTOR'">
                    {{ resolveLabel({ token: { datapoint }, deployed: true }) }}
                    <b *ngIf="datapoint?.unit?.symbol" class="ms-2">
                      ({{ resolveUnit({ token: { datapoint }, deployed: true }) }})
                    </b>
                  </ng-container>
                </div>
              </div>
              <div
                class="add_new_datapoint_btn"
                (click)="startAddNewDatapoint({ tokenIdx })"
                *ngIf="showDatapoints || showAddNewDataPointBtn"
              >
                {{ locale('locale_key.pages.data_categories_formula.add_new_data_point') }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        id="addFixedNumber_createFormula"
        class="add_fixed_number_or_container"
        [class.adding]="showAddFixedNumberInput"
      >
        <div class="add_fixed_number_input_container">
          <input
            type="number"
            step="any"
            class="add_fixed_number_input"
            [(ngModel)]="addFixedNumberValue"
            placeholder="{{ locale('locale_key.pages.data_supplier_questionnaire.type_number') }}"
          />
          <unit-selector
            [includeMeasurementTypes]="includeMeasurementTypes"
            [mode]="'select'"
            [selectBy]="'symbol'"
            [selectedUnitContainer]="unitInput"
            [source]="'formula_builder_dropdown'"
            [customUnits]="customUnits"
            [unitsByMeasurementType]="unitsByMeasurementType"
          ></unit-selector>
          <div class="add_fixed_number_input_control" (click)="addFixedNumber({ tokenIdx })">
            <i class="icon las la-plus"></i>
            <span class="label">{{ locale('locale_key.general.buttons.add') }}</span>
          </div>
        </div>
        <div class="add_fixed_number_btn" (click)="startAddFixedNumber()">
          <i class="icon las la-plus"></i>
          <span class="label">{{ locale('locale_key.pages.data_categories_formula.add_fixed_number') }}</span>
        </div>
      </div>

      <div class="add_emission_factor_container">
        <div class="add_emission_factor_btn" (click)="openModal(emissionModal)">
          <ng-container *ngTemplateOutlet="emissionFactorFireIcon"></ng-container>
          <span class="label">{{ locale('locale_key.pages.data_categories_formula.add_emission_factor') }}</span>
        </div>
        <ng-template #emissionModal>
          <emission-factor-detail
            (closeEvent)="closeModal()"
            (emissionFactorSelected)="addEmissionFactor({ emissionFactor: $event, tokenIdx })"
          ></emission-factor-detail>
        </ng-template>
      </div>
    </div>

    <div class="operand_group">
      <div class="add_before_after_container">
        <div class="add_before_btn" (click)="addControlBefore({ tokenIdx })">
          <i class="icon las la-plus-circle"></i>
          <span class="label">{{ locale('locale_key.pages.data_categories_formula.add_before') }}</span>
        </div>
        <div class="add_after_btn" (click)="addControlAfter({ tokenIdx })">
          <i class="icon las la-plus-circle"></i>
          <span class="label">{{ locale('locale_key.pages.data_categories_formula.add_after') }}</span>
        </div>
      </div>
      <div class="separator"></div>
      <div class="remove_btn" (click)="removeToken({ tokenIdx })">
        <i class="icon las la-trash-alt"></i>
        <span class="label">{{ locale('locale_key.general.buttons.remove') }}</span>
      </div>
    </div>
  </div>

  <add-datapoint-dialog #addDatapointDialog [selectedDataCategory]="selectedDataCategory"></add-datapoint-dialog>
</div>
<ng-template #emissionFactorFireIcon>
  <svg
    style="margin-right: 0.2rem"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59912 0.292969L6.35205 4.04004L4.96484 2.6543L4.4375 3.25342C1.82525 6.21442 0.5 8.90475 0.5 11.25C0.5 14.9715 3.8645 18 8 18C12.1355 18 15.5 14.9715 15.5 11.25C15.5 7.6785 11.6023 3.27455 9.27881 0.966797L8.59912 0.292969ZM8.89355 2.71729C10.8256 4.73179 14 8.466 14 11.25C14 13.0434 12.9652 14.6282 11.3926 15.5757C11.6165 15.0406 11.75 14.4506 11.75 13.8223C11.75 12.0365 10.4865 9.91829 9.42676 8.45654L8.79395 7.58203L7.13281 10.0737L6.07812 9.01904L5.58301 9.83643C4.69876 11.2959 4.25 12.6365 4.25 13.8223C4.25 14.4506 4.38349 15.0406 4.60742 15.5757C3.0348 14.6282 2 13.0434 2 11.25C2 9.45825 3.04823 7.25753 5.04248 4.85303L6.64795 6.45996L8.89355 2.71729ZM8.82764 10.2363C9.75014 11.6741 10.25 12.9253 10.25 13.8223C10.25 15.299 9.2405 16.5 8 16.5C6.7595 16.5 5.75 15.299 5.75 13.8223C5.75 13.1308 5.96768 12.3392 6.39893 11.4609L7.36719 12.4277L8.82764 10.2363Z"
      fill="#374659"
    />
  </svg>
</ng-template>
