<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-start">
        <nav>
          <ul class="nav">
            <ng-container *ngFor="let title of activeView?.header; let first = first; let last = last">
              <li class="navbar-item" *ngIf="!first">
                <h3 class="mt-2 mx-3"><i class="la la-angle-right font-medium-1"></i></h3>
              </li>
              <li class="navbar-item cursor">
                <a (click)="first ? setView('overview') : !last ? setView(activeView?.backViewName) : null">
                  <h3 class="mt-2">{{ title }}</h3>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ml-4 mt-"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div
            class="card me-2"
            style="height: 84vh; max-width: 100vw !important"
            [ngStyle]="!cardHeader ? { border: 'none' } : {}"
          >
            <!-- Pull existing data from platform -->
            <div class="container-fluid mx-0 px-0" *ngIf="pullDataFromPlatform">
              <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                <div class="d-flex fled-row justify-content-start align-items-center pt-3 ps-2">
                  <h4 class="ps-2">{{ slocale('Pull existing data from platform') }}</h4>
                </div>
                <hr class="m-0 p-0" />
                <div class="row text-center pb-3 pt-4">
                  <div class="col-3 mt-0 pt-0 mx-0 ps-1 pe-0">
                    <div
                      class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                    >
                      <div class="circle1 active-circle1 mx-0 px-0">1</div>
                      <h6 class="my-auto ms-1">{{ slocale('Select calculation method') }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div
                      class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                    >
                      <div class="circle1 active mx-0 px-0">2</div>
                      <h6 class="my-auto ms-1">{{ slocale('Select data category') }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div
                      class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                    >
                      <div class="circle1 active mx-0 px-0">3</div>
                      <h6 class="my-auto ms-1">{{ slocale('Filter and select data') }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div
                      class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                    >
                      <div class="circle1 active mx-0 px-0">4</div>
                      <h6 class="my-auto ms-1 mt-1 mb-0 pb-0">{{ slocale('Add missing data') }}</h6>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()"
              >
                <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                  <div class="d-block text-center">
                    <div
                      class="col stepper text-center"
                      *ngFor="let step of stepsPullData; let i = index"
                      [class.active-stepper]="currentActive === i + 1 || step.completed"
                    ></div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-center mb-1">
                  <h6 class="text-center m-0">
                    {{ stepsPullData[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})
                  </h6>
                </div>
              </ng-container>
            </div>
            <hr class="mt-0 pt-0 mx-0 px-0" [ngClass]="!pullDataFromPlatform ? 'hide' : 'show'" />
            <div
              class="card-body mt-0 pt-0 px-0"
              style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important"
              *ngIf="pullDataFromPlatform && !bizTravel"
            >
              <!--NEW PROJECT PAGE-->
              <div *ngIf="activeFormNo == 1" class="px-3 h-100">
                <div class="" style="height: 90% !important">
                  <div class="container-fluid vh-auto">
                    <div class="row p-3 text-left">
                      <h5 class="text-justify pt-3">
                        {{
                          slocale('Select which type of emissions you want to calculate (select at least one option).')
                        }}
                      </h5>
                      <div class="d-flex flex-row justify-content-start align-items-center">
                        <input type="radio" id="yes" name="emissionFactors" value="yes" checked class="d-inline me-2" />
                        <label for="yes" class="d-inline">{{ slocale('Location-based emissions') }}</label>
                        <input type="radio" id="no" name="emissionFactors" value="no" class="d-inline ms-4 me-2" />
                        <label for="no" class="d-inline">{{ slocale('Market-based emissions') }}</label>
                      </div>
                    </div>
                    <div class="row p-3">
                      <h5>
                        {{
                          slocale(
                            'Before you can get started you need to select the calculation method for the data you want to pull.'
                          )
                        }}
                      </h5>
                    </div>
                    <div class="d-flex flex-row justify-content-evenly align-items-center w-100 flex-wrap">
                      <div
                        class="card platform-card mb-3 p-0 h-100"
                        style="border-top: 10px solid var(--purple); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale('Weight data') }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                              <ul class="">
                                <li>{{ slocale('Waste material') }}</li>
                                <li>{{ slocale('Waste volume in weight') }}</li>
                                <li>{{ slocale('Waste treatment method') }}</li>
                                <li>{{ slocale('Emission Factor') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
                        </div>
                      </div>
                      <div
                        class="card platform-card mb-3 p-0 h-100"
                        style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">
                                  {{ slocale('Unit data') }}
                                  <span class="badge bg-comp-grey w-auto text-wrap p-2 success-text">
                                    Best practice
                                  </span>
                                </h5>
                              </label>
                              <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                              <ul class="">
                                <li>{{ slocale('Product group') }}</li>
                                <li>{{ slocale('Waste volume (in number of units)') }}</li>
                                <li>{{ slocale('Average weight per unit') }}</li>
                                <li>{{ slocale('Waste treatment method') }}</li>
                                <li>{{ slocale('Emission Factor') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
                        </div>
                      </div>
                      <div
                        class="card platform-card mb-3 p-0 h-100"
                        style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale('Unit data') }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                              <ul class="">
                                <li>{{ slocale('Product group') }}</li>
                                <li>{{ slocale('Waste volume (in number of units)') }}</li>
                                <li>{{ slocale('Average weight per unit') }}</li>
                                <li>{{ slocale('Waste treatment method') }}</li>
                                <li>{{ slocale('Emission Factor') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-evenly align-items-center w-100 flex-wrap">
                      <div
                        class="card platform-card mb-3 p-0 h-100"
                        style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale('Unit data') }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                              <ul class="">
                                <li>{{ slocale('Product group') }}</li>
                                <li>{{ slocale('Waste volume (in number of units)') }}</li>
                                <li>{{ slocale('Average weight per unit') }}</li>
                                <li>{{ slocale('Waste treatment method') }}</li>
                                <li>{{ slocale('Emission Factor') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
                        </div>
                      </div>
                      <div
                        class="card platform-card mb-3 p-0 h-100"
                        style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale('Unit data') }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                              <ul class="">
                                <li>{{ slocale('Product group') }}</li>
                                <li>{{ slocale('Waste volume (in number of units)') }}</li>
                                <li>{{ slocale('Average weight per unit') }}</li>
                                <li>{{ slocale('Waste treatment method') }}</li>
                                <li>{{ slocale('Emission Factor') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
                        </div>
                      </div>
                      <div
                        class="card platform-card mb-3 p-0 h-100 disabled"
                        style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
                      >
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input
                                type="radio"
                                id="newProject"
                                name="projectType"
                                value="newProject"
                                checked
                                class="d-inline me-2"
                              />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale('Spend data') }}</h5>
                              </label>
                              <h6 class="pt-3">
                                {{
                                  slocale(
                                    'This method calculates emissions by assessing the monetary value of goods or services purchased and applying emission factors to those expenditures.'
                                  )
                                }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted" style="color: var(--orange) !important">
                            {{ slocale('COMING SOON') }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="row px-3 text-center">
                      <p class="text-justify pt-3">
                        {{
                          slocale(
                            'Questions about which method to choose? Contact your customer support representative for assistance.'
                          )
                        }}
                      </p>
                    </div>
                    <hr />
                    <div class="row px-3 text-start">
                      <div class="d-flex flex-row flex-wrap pt-3 pb-2">
                        <h5 class="text-justify">{{ slocale('Should data reporters submit emission factors?') }}</h5>
                        <i
                          class="la la-info-circle info-popover-arrow d-inline size-15 ms-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale('Project name should be unique.') }}</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center pb-3">
                        <button class="btn tertiary-button me-2 custom-button">{{ slocale('Yes') }}</button>
                        <button class="btn btn-pink custom-button">{{ slocale('No') }}</button>
                      </div>
                    </div>
                    <hr />
                    <div class="row px-3 text-start">
                      <div class="d-flex flex-row flex-wrap pt-3 pb-2">
                        <h5 class="text-justify">{{ slocale('Should data reporters submit emission factors?') }}</h5>
                        <i
                          class="la la-info-circle info-popover-arrow d-inline size-15 ms-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale('Project name should be unique.') }}</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center">
                        <button class="btn btn-pink custom-button me-2">{{ slocale('Yes') }}</button>
                        <button class="btn tertiary-button custom-button">{{ slocale('No') }}</button>
                      </div>
                      <div class="d-flex flex-column px-3 py-2">
                        <small>
                          This is the information the data reporters will receive for the required proof documentation.
                          Edit the description so that it matches your requirements.
                        </small>
                        <textarea class="form-control mt-2 w-75" id="exampleFormControlTextarea1" rows="3">
                          Proof documentation may include supporting evidence such as invoices, utility bills, or operational records. This documentation validates and verifies the reported data, ensuring accuracy and reliability in the carbon calculation process.
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--NAME THE PROJECT PAGE-->
              <div *ngIf="activeFormNo == 2 && !bizTravel" class="px-3">
                <div class="d-flex flex-row flex-wrap">
                  <h5>{{ slocale('Which data category contains data for Waste generated in operations?') }}</h5>
                  <i
                    class="la la-info-circle info-popover-arrow"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale('Project name should be unique.') }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>{{ slocale('Select data category') }}</option>
                    <option value="1">{{ slocale('Option 1') }}</option>
                    <option value="2">{{ slocale('Option 2') }}</option>
                    <option value="3">{{ slocale('Option 3') }}</option>
                  </select>
                </div>
              </div>
              <!--SELECT EMISSIONS PAGE-->
              <div *ngIf="activeFormNo == 3 && !bizTravel" style="overflow-x: hidden; height: 100% !important">
                <div class="container-fluid">
                  <div class="row">
                    <p class="ps-4">
                      {{
                        slocale(
                          'Filter the table to only show the data you want to use to calculate the emissions for Waste generated in operations. Next, select which column contains the relevant data for each of the required data points listed below. If any data is missing you can add it in the next step.'
                        )
                      }}
                    </p>
                  </div>
                  <div
                    class="bg-light-gray pb-2 pt-3 px-3 mx-2 d-flex flex-row justify-content-between align-items-center flex-wrap border"
                  >
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste material') }}
                      <i
                        class="la la-info-circle info-popover-arrow bold"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste material .....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste treatment method') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste treament.....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste volume in weight') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste vol in weight.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5 d-none" style="position: relative; top: 2px"></i>
                      {{ slocale('Emission factor') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Emission factor.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Time') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Time.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Organizational unit') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Org unit....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Country') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Country....') }}</p>
                      </div>
                    </h6>
                  </div>
                  <div class="w-100 h-100 p-2">
                    <img src="assets/img/table.jpg" alt="CarbonLab-Icons-01" class="img-fluid w-100 h-100" />
                  </div>
                </div>
              </div>
              <!--EMISSIONS DASHBOARD PAGE-->
              <div *ngIf="activeFormNo == 4 && !bizTravel" class="px-2 bg-light h-100" style="overflow-x: hidden">
                <div class="container-fluid">
                  <div class="row">
                    <p class="ps-4">
                      {{
                        slocale(
                          'The necessary data points for calculating the GHG emissions have been added. Fill in any missing data to continue with the calculation.'
                        )
                      }}
                    </p>
                  </div>
                  <div
                    class="bg-light-gray pb-2 pt-3 px-3 mx-2 d-flex flex-row justify-content-between align-items-center flex-wrap border"
                  >
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste material') }}
                      <i
                        class="la la-info-circle info-popover-arrow bold"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste material .....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste treatment method') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste treament.....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Waste volume in weight') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Waste vol in weight.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <span class="bg-pink w-auto rounded px-2 text-white">{{ slocale('New') }}</span>
                      {{ slocale('Emission factor') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Emission factor.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Time') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Time.') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Organizational unit') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Org unit....') }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale('Country') }}
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Country....') }}</p>
                      </div>
                    </h6>
                  </div>
                  <div class="w-100 h-100 p-2">
                    <img src="assets/img/table.jpg" alt="CarbonLab-Icons-01" class="img-fluid w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="card-footer"
              class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0"
              style="max-width: 100vw; background-color: transparent"
              *ngIf="cardFooter"
            >
              <ng-container *ngIf="!loadingInProgress">
                <button type="button" class="btn m-1 tertiary-button custom-button" (click)="saveDraftModal(true)">
                  {{ locale('locale_key.general.buttons.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 secondary-button custom-button"
                  [hidden]="activeFormNo == 1"
                  (click)="saveDraftModal(false)"
                >
                  {{ locale('locale_key.button.save_as_draft') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 tertiary-button custom-button"
                  *ngIf="activeFormNo != 1"
                  (click)="prev()"
                >
                  {{ locale('locale_key.general.buttons.previous') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 primary-button custom-button mt-sm-1"
                  *ngIf="activeFormNo != 4"
                  (click)="next()"
                >
                  {{ locale('locale_key.general.buttons.next') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 primary-button custom-button mt-sm-1"
                  (click)="pullDataFromPlatform ? openModal(emissionsPortalModal, 'modal-lg') : closeModal()"
                  *ngIf="activeFormNo == 4"
                >
                  {{ activeFormNo == 4 && pullDataFromPlatform ? slocale('Next') : slocale('Finish') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 primary-button custom-button mt-sm-1"
                  *ngIf="activeFormNo == 1"
                  (click)="openModal(updateCategoriesModal, 'modal-lg')"
                >
                  {{ slocale('Test') }}
                </button>
                <!-- <button type="button" class="btn mx-1 primary-button custom-button" [hidden]="activeFormNo > 1" >{{ locale("locale_key.pages.data_request.create_wizard.button.send_request") }}</button> -->
              </ng-container>
            </div>

            <!--BUSINESS TRAVEL EMISSIONS PAGE-->
            <div
              class="card-body px-0 mx-2 m-auto pt-3"
              *ngIf="activeView?.name == 'addData'"
              style="overflow-x: hidden"
            >
              <div
                class="d-flex flex-row justify-content-between align-content-start px-3 w-auto flex-wrap"
                style="max-width: 95% !important; max-height: 200px"
              >
                <div class="d-flex fled-row justify-content-start align-items-center">
                  <i class="la la-plane size-15 me-2 bold" style="transform: rotate(90deg)"></i>
                  <h4 class="mb-0 pb-0">{{ slocale('Business travel') }}</h4>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <span style="color: lightgray">{{ slocale('ACTIVITY-BASED') }}</span>
                </div>
              </div>
              <hr />
              <div
                class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap"
                style="max-width: 100vw !important"
              >
                <div class="card px-5 py-4 mt-2 bizTravel-card">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <img
                        _ngcontent-qgx-c224=""
                        src="/assets/img/logos/Logo-Sustainlab.png"
                        class="brand-logo img-responsive img-fluid pb-1"
                        style="width: 2.5rem"
                      />
                      <small class="card-title col pt-1">{{ slocale('Pull existing data from platform') }}</small>
                      <button
                        class="btn primary-button mx-auto custom-button"
                        (click)="pullDataFromPlatform = true; bizTravel = false"
                      >
                        {{ slocale('Start') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card px-5 py-4 mt-2 bizTravel-card flex-wrap">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <i class="la la-envelope-o my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
                      <small class="card-title col">{{ slocale('Request from data reporters') }}</small>
                      <button class="btn primary-button mx-auto custom-button" (click)="setView('requestData')">
                        {{ slocale('Start') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card px-5 py-4 mt-2 bizTravel-card">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <i
                        _ngcontent-qgx-c224=""
                        class="la la-keyboard-o my-auto mx-2 dark-text"
                        style="font-size: 3.5rem"
                      ></i>
                      <small class="card-title col">{{ slocale('Request from data reporters') }}</small>
                      <button class="btn primary-button mx-auto custom-button">{{ slocale('Start') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row px-3 flex-wrap" *ngIf="!emissionsEmpty" style="max-width: 100vw !important">
                <div class="col-lg-6 col-sm-12" style="height: 100% !important">
                  <div
                    class="d-flex flex-row justify-content-center align-items-end blue-border-b p-2 mt-auto"
                    style="height: 100% !important; min-height: 99px; background-color: var(--light-blue)"
                  >
                    <h6 class="mt-auto" style="position: relative; top: 10px">{{ slocale('TOTAL: ') }}</h6>
                    <p class="mt-auto mb-0 pb-0 mx-2" style="position: relative; top: 7px">-</p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 pb-2">
                  <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                    <small class="m-auto">{{ slocale('COMPLETED REQUESTS') }}</small>
                    <span class="m-auto">-</span>
                    <small class="m-auto">{{ slocale('PENDING REQUESTS') }}</small>
                    <span class="m-auto">-</span>
                    <small class="m-auto">{{ slocale('SUBMISSIONS TO REVIEW') }}</small>
                    <a><i class="la la-arrow-right fs-3 m-auto"></i></a>
                  </div>
                </div>
                <div class="">
                  <div
                    class="col-12 border bg-comp-grey text-center h-100 d-flex flex-column justify-content-center align-items-center"
                    style="height: 100%; min-height: 300px"
                  >
                    <p class="my-auto">{{ slocale('No data reportted yet') }}</p>
                  </div>
                </div>
              </div>
              <div class="row px-3 flex-wrap" *ngIf="emissionsEmpty" style="max-width: 100vw !important">
                <div class="col-lg-6 col-md-12" style="height: 100% !important">
                  <div
                    class="d-flex flex-row justify-content-center align-items-center blue-border-b p-2 mt-auto"
                    style="height: 100% !important; min-height: 99px; background-color: var(--light-blue)"
                  >
                    <h3 class="mt-auto" style="position: relative; top: 10px">{{ slocale('TOTAL: ') }}</h3>
                    <h3 class="mt-auto mb-0 pb-0" style="position: relative; top: 3px">
                      <span class="bold mx-2">44</span>
                      tCO2e
                    </h3>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 pb-2">
                  <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                    <small class="m-auto">{{ slocale('COMPLETED REQUESTS') }}</small>
                    <h4 class="m-auto bold d-inline">90%</h4>
                    <small class="m-auto">{{ slocale('PENDING REQUESTS') }}</small>
                    <h4 class="m-auto bold d-inline">2</h4>
                    <small class="m-auto">{{ slocale('SUBMISSIONS TO REVIEW') }}</small>
                    <div class="icon-circle-pink-lg me-2 my-auto"><span class="text-white bold d-inline">2</span></div>
                    <a><i class="la la-arrow-right fs-3 m-auto"></i></a>
                  </div>
                </div>
                <div class="px-2">
                  <div class="border bg-comp-grey h-100 mx-1">
                    <div class="pt-3" style="height: 100%; min-height: 300px; max-width: 100% !important">
                      <div class="container-fluid">
                        <ul
                          class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent"
                          *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()"
                        >
                          <li class="nav-item bg-transparent">
                            <a
                              class="nav-link mb-0 pb-0"
                              id="base-tab31"
                              [class.active]="activeTab === 'tab31'"
                              (click)="selectTab('tab31')"
                            >
                              {{ slocale('All data entries') }}
                            </a>
                          </li>
                          <li class="nav-item bg-transparent">
                            <a
                              class="nav-link mb-0 pb-0"
                              id="base-tab32"
                              [class.active]="activeTab === 'tab32'"
                              (click)="selectTab('tab32')"
                            >
                              {{ slocale('Per organizational unit') }}
                            </a>
                          </li>
                          <li class="nav-item bg-transparent">
                            <a
                              class="nav-link mb-0 pb-0"
                              id="base-tab33"
                              [class.active]="activeTab === 'tab33'"
                              (click)="selectTab('tab33')"
                            >
                              {{ slocale('Input Log') }}
                            </a>
                          </li>
                          <div class="ms-auto">
                            <input
                              type="text"
                              class="form-control w-100"
                              placeholder="{{ locale('locale_key.general.search') }}"
                              (input)="filter(search.value)"
                              #search
                            />
                          </div>
                        </ul>
                        <div
                          class="d-flex flex-row justify-content-between align-items-center"
                          *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()"
                        >
                          <h5 class="">{{ getActiveTabName() }}</h5>
                          <div class="dropdown ms-auto no-border">
                            <button
                              class="btn no-border"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ul class="no-hover-bg nav-underline w-100 bg-transparent">
                                <li class="nav-item bg-transparent">
                                  <a
                                    class="nav-link active"
                                    id="base-tab31"
                                    [class.active]="activeTab === 'tab31'"
                                    (click)="selectTab('tab31')"
                                  >
                                    {{ slocale('All data entries') }}
                                  </a>
                                </li>
                                <li class="nav-item bg-transparent">
                                  <a
                                    class="nav-link"
                                    id="base-tab32"
                                    [class.active]="activeTab === 'tab32'"
                                    (click)="selectTab('tab32')"
                                  >
                                    {{ slocale('Per organizational unit') }}
                                  </a>
                                </li>
                                <li class="nav-item bg-transparent">
                                  <a
                                    class="nav-link"
                                    id="base-tab33"
                                    [class.active]="activeTab === 'tab33'"
                                    (click)="selectTab('tab33')"
                                  >
                                    {{ slocale('Input Log') }}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          class="me-auto"
                          *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()"
                        >
                          <input
                            type="text"
                            class="form-control w-auto"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filter(search.value)"
                            #search
                          />
                        </div>
                        <div class="tab-content px-1 pt-1">
                          <div
                            role="tabpanel"
                            class="tab-pane"
                            id="tab31"
                            [class.active]="activeTab === 'tab31'"
                            aria-labelledby="base-tab31"
                          >
                            <div class="">
                              <table class="table table-hover table-borderless table-responsive mt-2">
                                <thead>
                                  <tr>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('Emission source') }}
                                        <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th class="hide-on-small">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('Organizational unit') }}
                                        <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th class="hide-on-small">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('Time') }}
                                        <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">{{ slocale('Calculation method') }}</h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('Emission factor') }}
                                        <i
                                          class="la la-info-circle info-popover-arrow warning"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        ></i>
                                        <div class="dropdown-menu arrow p-2 info-popover">
                                          <p>{{ slocale('Explanation about emissions.') }}</p>
                                        </div>
                                      </h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('GHG emissions') }}
                                      </h6>
                                    </th>
                                    <th class="hide-on-medium">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale('Added to project') }}
                                        <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th>{{ slocale('') }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="row-1">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale('Sthlm office') }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale('Weight method') }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-2">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale('Sthlm office') }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale('Weight method') }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-3">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small" colspan="5">
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale('waiting for data submissions from ') }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline">
                                              <span class="text-white px-2">3</span>
                                            </div>
                                            {{ slocale('data reporters') }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-turquoise">{{ slocale('Sent') }}</span>
                                      </div>
                                    </td>
                                    <td
                                      class="pt-3"
                                      colspan="3"
                                      *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize()"
                                    >
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale('waiting for data submissions from ') }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline">
                                              <span class="text-white px-2">3</span>
                                            </div>
                                            {{ slocale('data reporters') }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-turquoise">{{ slocale('Sent') }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-4">
                                    <td class="pt-3">
                                      {{ slocale('To be defined by data reporters') }}
                                    </td>
                                    <td
                                      class="pt-3 hide-on-small"
                                      colspan="5"
                                      *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()"
                                    >
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale('waiting for data submissions from ') }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline">
                                              <span class="text-white px-2">3</span>
                                            </div>
                                            {{ slocale('data reporters') }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-orange">{{ slocale('In progress') }}</span>
                                      </div>
                                    </td>
                                    <td
                                      class="pt-3"
                                      colspan="3"
                                      *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize()"
                                    >
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale('waiting for data submissions from ') }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline">
                                              <span class="text-white px-2">3</span>
                                            </div>
                                            {{ slocale('data reporters') }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-orange">{{ slocale('In progress') }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-5">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale('Sthlm office') }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale('Weight method') }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a class="cursor d-block">
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-6">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale('Sthlm office') }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale('Weight method') }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="">
                                    <td class="pt-3">
                                      {{ slocale('Air travel') }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale('Sthlm office') }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale('Weight method') }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button
                                          class="btn no-border"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')">
                                            <i class="la la-calculator me-2"></i>
                                            {{ slocale('Show calculation') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(selectEmissionsModal, 'modal-xl')"
                                          >
                                            <i class="la la-edit me-2"></i>
                                            {{ slocale('Edit emission factor') }}
                                          </a>
                                          <a
                                            class="cursor d-block"
                                            (click)="openModal(emissionsPortalModal, 'modal-xl')"
                                          >
                                            <i class="la la-code-branch me-2"></i>
                                            {{ slocale('Trace source') }}
                                          </a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')">
                                            <i class="la la-trash me-2 warning"></i>
                                            {{ slocale('Remove entry from project') }}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class="tab-pane"
                            id="tab32"
                            [class.active]="activeTab === 'tab32'"
                            aria-labelledby="base-tab32"
                          >
                            <p>
                              Sugar plum tootsie roll biscuit caramels. Liquorice brownie pastry cotton candy oat cake
                              fruitcake jelly chupa chups. Pudding caramels pastry powder cake soufflé wafer caramels.
                              Jelly-o pie cupcake.
                            </p>
                          </div>
                          <div
                            class="tab-pane"
                            id="tab33"
                            [class.active]="activeTab === 'tab33'"
                            aria-labelledby="base-tab33"
                          >
                            <p>
                              Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee
                              cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert
                              jujubes powder sweet sesame snaps.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--ALL PROJECTS DASHBOARD and SUMMARY Table-->
            <div
              class="card-body px-0 mt-0 pt-0"
              *ngIf="allProjects && !cardHeader && !cardFooter"
              style="overflow-x: hidden"
            >
              <div class="h-100 mx-1 p-2">
                <div class="" style="height: 100%; min-height: 300px; max-width: 100% !important">
                  <div class="container-fluid">
                    <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
                      <li class="nav-item bg-transparent">
                        <a
                          class="nav-link mb-0 pb-0 active"
                          id="base-tab35"
                          [class.active]="activeTab === 'tab35'"
                          (click)="selectTab('tab35')"
                        >
                          {{ slocale('Dashboard') }}
                        </a>
                      </li>
                      <li class="nav-item bg-transparent">
                        <a
                          class="nav-link mb-0 pb-0"
                          id="base-tab36"
                          [class.active]="activeTab === 'tab36'"
                          (click)="selectTab('tab36')"
                        >
                          {{ slocale('Summary table') }}
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content px-1 pt-1">
                      <div
                        role="tabpanel"
                        class="tab-pane"
                        id="tab35"
                        [class.active]="activeTab === 'tab35'"
                        aria-labelledby="base-tab35"
                      >
                        <div class="container-fluid">
                          <img src="assets/img/dashboard.jpg" class="img-fluid w-100 h-100 mt-2" alt="dashboard" />
                        </div>
                      </div>
                      <div
                        class="tab-pane"
                        id="tab36"
                        [class.active]="activeTab === 'tab36'"
                        aria-labelledby="base-tab36"
                      >
                        <div class="container-fluid">
                          <div class="row py-3">
                            <select class="form-select w-auto" aria-label="Default select example">
                              <option selected>{{ slocale('All entries') }}</option>
                              <option value="1">Project 1</option>
                              <option value="2">Project 2</option>
                              <option value="3">Project 3</option>
                            </select>
                          </div>
                          <img src="assets/img/table.jpg" class="img-fluid w-100 h-100 mt-2" alt="dashboard" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PROJECT OVERVIEW STARTS-->
            <div class="card-body px-0 pt-0 mt-0 bg-comp-grey scroll-y" *ngIf="activeView?.name == 'overview'">
              <div class="container-fluid px-0">
                <div class="d-flex flex-row flex-wrap justify-content-between bg-white p-3">
                  <div class="col-lg-6 col-sm-12">
                    <h5 class="mb-lg-0 mb-sm-2 pt-2">{{ project.name }}</h5>
                  </div>
                  <div
                    class="col-lg-6 col-sm-12 d-flex flex-row justify-content-lg-end justify-content-sm-start justify-content-md-start"
                  >
                    <div
                      class="d-flex flex-row justify-content-lg-end justify-content-sm-start justify-content-md-start"
                    >
                      <div class="green-border w-auto d-inline px-2">
                        <span>
                          {{ slocale(project.emissionCategoryKeys.length + ' emission categories selected') }}
                        </span>
                      </div>
                      <i
                        class="la la-edit size-15 d-inline ms-2 mt-1"
                        (click)="openModal(emissionCategoriesModal, 'modal-lg')"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid px-0">
                <div class="row mt-2 bg-white pt-2 mx-0 px-0">
                  <div class="col-lg-7 col-md-12 d-flex flex-row flex-wrap justify-content-around">
                    <div class="d-flex flex-row justify-content-between align-items-center m-0 p-0 scope-container-sm">
                      <div class="d-flex flex-column justify-content-between green-border-b p-2 mt-auto mx-1">
                        <h6 class="me-auto">{{ slocale('SCOPE 1') }}</h6>
                        <p class="ms-auto">-</p>
                      </div>
                      <div class="d-flex flex-column justify-content-between mx-1">
                        <select class="form-select" aria-label="Default select example">
                          <option selected>{{ slocale('Location based') }}</option>
                          <option value="1">{{ slocale('Option 1') }}</option>
                          <option value="2">{{ slocale('Option 2') }}</option>
                          <option value="3">{{ slocale('Option 3') }}</option>
                        </select>
                        <div class="w-100 bg-white" style="height: 10px"></div>
                        <div
                          class="d-flex flex-column justify-content-between align-items-end yellow-border-b mt-auto p-2"
                        >
                          <h6 class="me-auto">{{ slocale('SCOPE 2') }}</h6>
                          <p class="ms-auto">-</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-between align-items-end blue-border-b p-2 mt-auto mx-1"
                    >
                      <h6 class="me-auto">{{ slocale('SCOPE 3') }}</h6>
                      <p class="ms-auto">-</p>
                    </div>
                    <div
                      class="d-flex flex-row justify-content-between align-items-end pink-border-b flex-grow-1 p-2 mt-auto mx-1"
                    >
                      <h6 class="me-auto mt-auto" style="position: relative; top: 10px">{{ slocale('TOTAL') }}</h6>
                      <p class="ms-auto mt-auto mb-0 pb-0" style="position: relative; top: 8px">-</p>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 pb-2">
                    <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                      <small class="m-auto">{{ slocale('COMPLETED REQUESTS') }}</small>
                      <span class="m-auto">-</span>
                      <small class="m-auto">{{ slocale('PENDING REQUESTS') }}</small>
                      <span class="m-auto">-</span>
                      <small class="m-auto">{{ slocale('SUBMISSIONS TO REVIEW') }}</small>
                      <i class="la la-arrow-right fs-3 m-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid px-0">
                <!-- <div class="p-3 bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <h6 class="mb-0 pb-0 d-inline">{{ slocale("Data collection") }}</h6>
                  <button class="btn primary-button custom-button text-white">{{ slocale("Start") }}</button>
                </div> -->
                <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <div class="accordion w-100" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <a
                          class="accordion-button d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div class="m-0 p-0">
                            <h6 class="mb-0 pb-0 text-wrap">{{ slocale('Data collection') }}</h6>
                          </div>
                          <div class="m-0 p-0 d-flex flex-row justify-content-end align-items-center flex-grow-1">
                            <input
                              type="text"
                              class="form-control me-1 w-auto input-small"
                              placeholder="{{ locale('locale_key.general.search') }}"
                              (input)="filter(search.value)"
                              #search
                            />
                            <a class="underline my-auto mx-2 cursor" (click)="expandAll()">
                              {{ locale('locale_key.pages.kpi_library.button.expand_all') }}
                            </a>
                            <a class="underline my-auto me-2 cursor" (click)="collapseAll()">
                              {{ locale('locale_key.pages.kpi_library.button.collapse_all') }}
                            </a>
                          </div>
                        </a>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body p-0 pb-3">
                          <div class="bg-light-gray p-3 mx-0">
                            <h6 class="mb-0 pb-0">{{ slocale('Reporting company') }}</h6>
                          </div>
                          <p class="px-2 mb-0 mt-2">{{ slocale('Scope 1 (Direct)') }}</p>
                          <div class="accordion w-100 px-2" id="accordionExample1">
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-building-o size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Company facilities') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">80</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-bus size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Company vehicles') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button" (click)="addData()">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale('Scope 2 (Indirect)') }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white teal-border-l thin-border-t"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center">
                                    <i class="la la-lightbulb size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">
                                      {{ slocale('Purchased electricity, steam, heating & cooling') }}
                                    </h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center flex-grow-1">
                                    <select class="form-select w-auto me-2" aria-label="Default select example">
                                      <option selected>{{ slocale('Location based') }}</option>
                                      <option value="1">{{ slocale('Option 1') }}</option>
                                      <option value="2">{{ slocale('Option 2') }}</option>
                                      <option value="3">{{ slocale('Option 3') }}</option>
                                    </select>
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseFour"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bg-light-gray p-3 mx-0 mt-2">
                              <h6 class="mb-0 pb-0">{{ slocale('Upstream Activities') }}</h6>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale('Scope 3 (Indirect)') }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l thin-border-t"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseFive"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-handshake size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Purchased goods and services') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseFive"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSix"
                                  aria-expanded="false"
                                  aria-controls="collapseSix"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-car-side size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Upstream transportation and distribution') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseSix"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSeven"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-trash-alt size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Waste generated in operations') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseSeven"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseEight"
                                  aria-expanded="false"
                                  aria-controls="collapseEight"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-plane size-15 me-2" style="transform: rotate(90deg)"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Business travel') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapseEight"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button" (click)="bizTravel = true">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapse9"
                                  aria-expanded="false"
                                  aria-controls="collapse9"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-bus size-15 me-2" style="transform: rotate(90deg)"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Employee commuting') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapse9"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bg-light-gray p-3 mx-0 mt-2">
                              <h6 class="mb-0 pb-0">{{ slocale('Downstream Activities') }}</h6>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale('Scope 3 (Indirect)') }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white pink-border-l thin-border-t"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapse10"
                                  aria-expanded="false"
                                  aria-controls="collapse10"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-handshake size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale('Use of sold products') }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapse10"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a
                                  class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white pink-border-l"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapse11"
                                  aria-expanded="false"
                                  aria-controls="collapse11"
                                >
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-car-side size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">
                                      {{ slocale('Downstream transportation and distribution') }}
                                    </h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">
                                      {{ slocale('Total: ') }}
                                      <span class="bold me-1">25</span>
                                      tCO2e
                                    </h6>
                                  </div>
                                </a>
                              </h2>
                              <div
                                id="collapse11"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button">
                                      <i class="la la-plus-circle me-2 size-15 bold"></i>
                                      Add data
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid px-0">
                <div class="p-3 bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <h6 class="mb-0 pb-0 d-inline">{{ slocale('Dashboard & Summary') }}</h6>
                  <a class=""><i class="la la-arrow-right size-15"></i></a>
                </div>
              </div>
              <div class="container-fluid px-0">
                ---FILTERS---
                <div class="accordion" id="accordionExample15">
                  <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3 bg-white border">
                    <span>
                      <span class="mb-0 pb-0 ms-1">{{ slocale('Sort by name') }}</span>
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                        A-Z
                      </label>
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                        Z-A
                      </label>
                    </span>
                    <a class="underline">{{ slocale('Clear') }}</a>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a
                        class="accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse15"
                        aria-expanded="true"
                        aria-controls="collapse15"
                      >
                        {{ slocale('Filter by condition') }}
                      </a>
                    </h2>
                    <div id="collapse15" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body pb-2">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <div class="">
                            <div class="btn-group" dropdown container="body">
                              <button
                                type="button"
                                class="form-control w-auto dark-text mx-1"
                                id="group-button"
                                dropdownToggle
                                aria-controls="group-dropdown-menu"
                                style="text-align: left"
                              >
                                {{ selectedOption || slocale('None') }}
                              </button>
                              <div
                                class="dropdown-menu"
                                id="group-dropdown-menu"
                                *dropdownMenu
                                role="menu"
                                aria-labelledby="group-button"
                              >
                                <a class="dropdown-item" (click)="setDateOption(slocale('Is empty'))">
                                  {{ slocale('Is empty') }}
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Is not empty'))">
                                  {{ slocale('Is not empty') }}
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Date is before'))">
                                  {{ slocale('Date is before') }}
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Date is between'))">
                                  {{ slocale('Date is between') }}
                                </a>
                              </div>
                            </div>
                          </div>
                          <a class="underline my-auto mx-2 cursor">{{ slocale('Clear all') }}</a>
                        </div>
                        <div *ngIf="selectedOption === slocale('Date is before')" class="mt-1 px-1">
                          <input type="date" class="form-control" />
                        </div>
                        <div
                          *ngIf="selectedOption === slocale('Date is between')"
                          class="mt-1 d-flex align-items-center px-1"
                        >
                          <input type="date" class="form-control me-2" />
                          <span>{{ slocale('and') }}</span>
                          <input type="date" class="form-control ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a
                        class="accordion-button collapsed bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse16"
                        aria-expanded="false"
                        aria-controls="collapse16"
                      >
                        {{ slocale('Filter by value') }}
                      </a>
                    </h2>
                    <div id="collapse16" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <input
                            type="text"
                            class="form-control me-1 w-auto input-small"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filter(search.value)"
                            #search
                          />
                          <div class="d-flex flex-row justify-content-end align-items-center">
                            <a class="underline my-auto mx-2 cursor">{{ slocale('Select all') }}</a>
                            <a class="underline my-auto me-2 cursor">{{ slocale('Deselect all') }}</a>
                          </div>
                        </div>
                        <div class="d-flex flex-column mt-2">
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              01.013.2023
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a
                        class="accordion-button collapsed bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse17"
                        aria-expanded="false"
                        aria-controls="collapse17"
                      >
                        {{ slocale('Filter by emission source') }}
                      </a>
                    </h2>
                    <div id="collapse17" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <input
                            type="text"
                            class="form-control me-1 w-auto input-small"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filter(search.value)"
                            #search
                          />
                          <div class="d-flex flex-row justify-content-end align-items-center">
                            <a class="underline my-auto mx-2 cursor">{{ slocale('Select all') }}</a>
                            <a class="underline my-auto me-2 cursor">{{ slocale('Deselect all') }}</a>
                          </div>
                        </div>
                        <div class="d-flex flex-column mt-2">
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              Air travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              Train travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              Road travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input mx-2"
                                style="position: relative; top: 2px"
                              />
                              Hotel accomodation
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PROJECT OVERVIEW ENDS-->
            <project-request *ngIf="activeView?.name == 'requestData'"></project-request>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #saveDraft>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="!saveBeforeLeave">
      {{ slocale('Save as draft') }}
    </h4>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="saveBeforeLeave">
      {{ slocale('Save before leaving') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2" *ngIf="!saveBeforeLeave">
      {{ slocale('Your project will be saved in the Project Overview page.') }}
    </p>
    <p class="ps-2" *ngIf="saveBeforeLeave">
      {{
        slocale(
          "If you leave this page your changes won't be saved. To save changes return to the page and select the save option you want."
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white" (click)="saveProject()">
        {{ this.saveBeforeLeave ? slocale('Return to page') : slocale('Save') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Show Calculations Modal -->
<ng-template #showCalcModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 bold" id="myModalLabel14">
      {{ slocale('GHG emissions ') }}
      <span class="text-muted" style="font-weight: 300">{{ slocale('in the unit') }}</span>
      {{ slocale('Kg CO2e') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-row justify-content-between align-items-center blue-border w-100 p-2 bg-selected">
      <span class="mb-0 pb-0">{{ slocale('Activity data * Emission factor waste') }}</span>
      <i class="la la-edit size-15 me-2 bold" (click)="openModal(editCalcModal, 'modal-lg')"></i>
    </div>
  </div>
</ng-template>

<!-- Edit calculation modal-->
<ng-template #editCalcModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Edit calculation formula') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 bold">{{ slocale('Edit calculation formula for this entry') }}</p>
    </div>
    <div class="row px-2">
      <p class="">
        <span class="bold">{{ slocale('GHG emissions ') }}</span>
        {{ slocale('will be calculated as ') }}
      </p>
    </div>
    <div class="row px-1">
      <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row justify-content-start align-items-center w-100 flex-grow-1">
          <span class="px-2 py-1 dotted-square mx-2">
            <i
              class="la la-plus-circle size-15 m-auto bold"
              (click)="editCalculation('id')"
              style="position: relative; top: 2px"
            ></i>
          </span>
          <span class="teal-border-round p-2 w-auto">
            <span class="me-auto">
              {{ slocale('Electricity energy ') }}
              <span class="ms-auto text-mutted">{{ slocale('Kwh') }}</span>
            </span>
          </span>
          <span class="teal-sqaure bold size-15 mx-2">+</span>
          <span class="px-2 py-1 dotted-square">
            <i
              class="la la-plus-circle size-15 m-auto bold"
              (click)="editCalculation('id')"
              style="position: relative; top: 2px"
            ></i>
          </span>
        </div>
        <div
          class="d-flex flex-row justify-content-end align-items-center p-2"
          [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'text-nowrap' : 'text-wrap'"
        >
          <i class="la la-close size-15 ms-auto my-auto bold mx-1" (click)="editCalculation('id')"></i>
          <span *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">{{ slocale('Clear calculation') }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button" data-test="request-create-button">
        {{ locale('locale_key.general.buttons.save_changes') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #selectEmissionsModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 *ngIf="!emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Select from my company’s emission factors') }}
    </h5>
    <h5 *ngIf="emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Search in emission databases provided by SustainLab') }}
    </h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="!emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Edit') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 80vh !important">
          <table class="table table-responsive mt-1 table-sm">
            <thead style="border-bottom: 4px solid var(--main-contrast-color)">
              <tr class="">
                <th colspan="2">
                  <h6 class="text-muted mb-0 pb-0 mt-2">{{ slocale('Custom emission detail factor') }}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="">
                  <h6 class="text-muted mt-2">{{ slocale('Emission source') }}</h6>
                </td>
                <td class="">
                  <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                </td>
              </tr>
              <tr class="">
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Emission source unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">{{ slocale('litres') }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">kg CH4</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted mt-2">2.01888</h6>
                </td>
              </tr>
            </tbody>
            <h6 class="text-muted ps-2 pt-3">{{ slocale('Emission factor origin') }}</h6>
            <p class="ps-2 text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo
            </p>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th class="">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Database name') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Additional details') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3 hide-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">kg CH4</h6>
                      </div>
                      <div class="pt-3">
                        <h6 class="blurred-text">0.00107</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3 show-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 100vh !important">
          <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab31"
                [class.active]="activeTab === 'emissionFactor'"
                (click)="selectTab('emissionFactor')"
              >
                {{ slocale('Emission factor detail') }}
              </a>
            </li>
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab32"
                [class.active]="activeTab === 'databaseInfo'"
                (click)="selectTab('databaseInfo')"
              >
                {{ slocale('Database info') }}
              </a>
            </li>
          </ul>
          <div class="tab-content px-1 pt-1">
            <div
              role="tabpanel"
              class="tab-pane"
              id="emissionFactor"
              [class.active]="activeTab === 'emissionFactor'"
              aria-labelledby="base-emissionFactor"
            >
              <div class="">
                <table class="table table-responsive mt-1 table-sm">
                  <thead>
                    <tr class="">
                      <th colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="">
                      <td class="">
                        <h6 class="text-muted mt-2">{{ slocale('Category') }}</h6>
                      </td>
                      <td class="">
                        <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                      </td>
                    </tr>
                    <tr class="">
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Natural gas') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Sub-type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Biodiesel (from used cooking oil)') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Clasification') }}</h6>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Commodity Unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Continent') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Europe') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Data source') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">
                          {{ slocale('UK Government GHG Conversion Factors for Company Reporting') }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Location') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('-') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Scope') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Scope 1') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Year') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('2020') }}</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class="text-muted ps-2 pt-3 w-100">{{ slocale('Emission factor origin') }}</h6>
                <p class="ps-2 text-justify">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                </p>
              </div>
            </div>
            <div
              class="tab-pane"
              id="databaseInfo"
              [class.active]="activeTab === 'databaseInfo'"
              aria-labelledby="base-databaseInfo"
            >
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <h6 class="bold">DEFRA</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Original release date') }}</h6>
                <h6 class="bold">Jan 01 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Upload date in platform') }}</h6>
                <h6 class="bold">Jun 01, 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Publisher organization') }}</h6>
                <h6 class="bold">ACME Inc</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied geography') }}</h6>
                <h6 class="bold">UK, US, Europe</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied time period') }}</h6>
                <h6 class="bold">Dec 2021- Dec 2023</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <p class="bold">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt, eius voluptatibus? Deleniti autem
                  animi ipsam explicabo iste similique ipsa, illum voluptates omnis tenetur ut fuga nostrum provident
                  dignissimos dicta eos officiis eius nam saepe corrupti optio? Et consequatur impedit autem iure
                  pariatur esse aspernatur minima quae eos sit fugit fuga at molestias, suscipit voluptatibus. Nulla
                  temporibus magnam voluptates repellendus, porro minus quod quasi tempora a labore assumenda placeat,
                  qui ea quae voluptatem tempore ducimus unde delectus facilis at? Ab corporis deserunt a magni quis
                  sint quasi et, iusto tempore nulla saepe assumenda ipsum consequatur facere, natus, in laboriosam
                  iste? Ipsa?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn tertiary-button" (click)="closeModal()" data-test="request-cancel-button">
        {{ slocale('Apply no factor') }}
      </button>
      <button class="btn primary-button custom-button" data-test="request-create-button">
        {{ slocale('Apply factor') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #emissionsPortalModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Emissions portal') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row mx-auto text-center">
      <h3 class="bold mx-auto">{{ slocale('Choose your action.') }}</h3>
    </div>
    <div class="container-fluid">
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-search my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Search in emission databases') }}</small>
              <small class="card-title col mx-auto">{{ slocale('provided by SustainLab') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="
                  activeTab = 'emissionFactor';
                  emissionFormSustainLab = true;
                  openModal(selectEmissionsModal, 'modal-xl')
                "
              >
                {{ slocale('Search') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-database my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Select from my company's") }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factors') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="emissionFormSustainLab = false; openModal(selectEmissionsModal, 'modal-xl')"
              >
                {{ slocale('Select') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i _ngcontent-qgx-c224="" class="la la-plus my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Add custom') }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factor') }}</small>
              <button class="btn primary-button mx-auto custom-button">{{ slocale('Add custom') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Remove from Project Modal -->
<ng-template #removeProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Remove from project') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'Are you sure you want to remove this entry? The data will be removed from this carbon lab project and this action cannot be undone. Removing this entry will not affect the data categories in the platform.'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Return to page') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Remove') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data sucessfully added to project Modal -->
<ng-template #dataSuccessfullyAddedModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">
      {{ slocale('Data successfully added to project') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'You have successfully added data into the CarbonLab project. The emissions will now be calculated based on your selected calculation method.'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('OK') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Missing emissions factors Modal -->
<ng-template #missingEmissionsModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Missing emission factors') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'You have not filled in all necessary emission factors to calculated the emissions. Do you want to fill them in now or at a later time?'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Fill in now') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Fill in later') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Update Data Categories modal-->
<ng-template #updateCategoriesModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Update data categories') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="container-fluid">
      <div class="row">
        <p class="py-3 bold">
          {{
            slocale(
              'To be able to calculate the GHG emissions new necessary data points need to be added to this data category. The new data points will be added to the data categories once you save the changes.'
            )
          }}
        </p>
      </div>
      <div class="accordion w-100 px-2 mt-3" id="accordionExample1">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a
              class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table size-15 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale('Data points to be added') }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center p-3 flex-wrap">
                <span class="badge badge-green bg-green text-wrap w-auto m-1">
                  Environmental > Waste generate > Waste generated in operations
                </span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span>
                <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1">GHG emissions</span>
                <span><i class="la la-edit size-15 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button">{{ slocale('Save changes') }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Export Table modal-->
<ng-template #exportTableModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Export table') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="container-fluid">
      <div class="row">
        <p class="pt-3 pb-1">{{ slocale('Select in what format to export the summary table') }}</p>
      </div>
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-pdf my-auto mx-2" style="font-size: 3.5rem; color: red !important"></i>
          <label class="card-title mx-auto">
            <input
              type="checkbox"
              class="form-check-input me-1"
              id="exampleCheck1"
              style="position: relative; top: 2px"
            />
            {{ slocale('Export to PDF') }}
          </label>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i
            class="la la-file-excel my-auto mx-2 bg-gradient"
            style="font-size: 3.5rem; color: var(--main-gradient-start-color) !important"
          ></i>
          <label class="card-title mx-auto">
            <input
              type="checkbox"
              class="form-check-input me-1"
              id="exampleCheck1"
              style="position: relative; top: 2px"
            />
            {{ slocale('Export to in CSV') }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button">{{ locale('locale_key.general.toolbar.button.export') }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Emission Categories Modal -->
<ng-template #emissionCategoriesModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale('Edit project setup') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale('Project Name*') }}</h5>
        <i
          class="la la-info-circle info-popover-arrow ms-1"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="position: relative; top: 3px"
        ></i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>
            {{
              slocale(
                'Choose a name for your carbon project to specify eg. the time period or organization sections included in the calculations.'
              )
            }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center mt-2">
        <span class="w-75">
          {{
            slocale(
              'Choose the emission categories to include in your project scope. If you require additional categories beyond those provided, you can add custom ones by editing the custom categories.'
            )
          }}
        </span>
        <span>
          <i
            class="la la-edit size-15 ms-1"
            style="position: relative; top: 2px"
            (click)="openModal(customEmissionModal, 'modal-md')"
          ></i>
          Edit custom categories
        </span>
      </div>
      <p>You can add custom section or custom emission category if the requirement is out of scope.</p>
      !!!Dropdown content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Confirm') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Custom Emission Categories Modal -->
<ng-template #customEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">
      {{ slocale('Add custom emission category to Scope 1 (Direct)') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <label class="form-label">{{ slocale('Category name*') }}</label>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <label class="form-label">{{ slocale('Description') }}</label>
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-add text-white custom-button"
        (click)="openModal(removeEmissionModal, 'modal-lg')"
      >
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove Emission Categories Modal -->
<ng-template #removeEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale('Remove Emission Category') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <p>
        {{
          slocale(
            'Are you sure you want to remove this emission category? The data will be removed from this CarbonLab project and this action cannot be undone. This action will not affect the Data Categories in the platform and your data will still be available in the platform.'
          )
        }}
      </p>
      !!!Content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-add text-white custom-button"
        (click)="openModal(removeEmissionsConfModal, 'modal-sm')"
      >
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove emissions Confirmation Modal -->
<ng-template #removeEmissionsConfModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Remove emission category') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale('The category is currently empty and it will not affect your project.') }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Cancel') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Confirm') }}</button>
    </ng-container>
  </div>
</ng-template>
