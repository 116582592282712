import { DateUtil } from 'src/app/utils/DateUtil'
import { FileDataFe } from '../../../file/FileDataFe'
import { QUESTION_TYPE } from '../../request/questionnaire/QuestionTypeFe'
import { AnswerFe } from './AnswerFe'

export class AnswerAttachmentFe extends AnswerFe {
  attachmentStorageId: string
  attachmentLabel: string

  file: FileDataFe
  isLoading = false

  constructor(
    id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    attachmentStorageId: string,
    attachmentLabel: string
  ) {
    super(id, sectionId, questionId, QUESTION_TYPE.ATTACHMENT, comments)
    this.attachmentStorageId = attachmentStorageId
    this.attachmentLabel = attachmentLabel
  }

  public static fromTransfer(transfer: any): AnswerAttachmentFe {
    let answer = new AnswerAttachmentFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.attachmentStorageId,
      transfer.attachmentLabel
    )
    return answer
  }

  public hasAnyValue(): boolean {
    return this.attachmentStorageId != null
  }

  public static getTitle(
    requestLabel: string,
    question: string,
    assignee: string,
    submissionDate: Date,
    label: string
  ): string {
    return `[${requestLabel}][${question}][${assignee}][${DateUtil.toString2(submissionDate)}][${label}]`
  }
}
