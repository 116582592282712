<div *ngIf="loadingInProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card-content" *ngIf="!loadingInProgress">
  <div class="card-body p-1">
    <div class="row">
      <div class="col-md-6 col-lg-3 mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{ locale('locale_key.general.search') }}"
          (input)="applySearchFilter(search.value)"
          #search
        />
      </div>
      <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
        <button
          type="button"
          class="form-control text-start"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
        >
          {{ locale('locale_key.pages.datahub.filter_group_by') }}
          {{ groupedBy ? groupedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <a class="dropdown-item" (click)="groupedBy = null">{{ locale('locale_key.pages.datahub.filter_none') }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" *ngFor="let option of groupedByOptions" (click)="groupFiles(option)">
            {{ option.name }}
          </a>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body" [insideClick]="true">
        <button
          type="button"
          class="form-control filter-button text-start"
          id="filter-button"
          dropdownToggle
          aria-controls="filter-dropdown-menu"
        >
          <p class="d-inline m-0">
            <i class="la la-filter"></i>
            {{ locale('locale_key.pages.datahub.filter_dropdown') }}
          </p>
          <p
            class="text-muted filter-text text-truncate col-7 m-0"
            *ngIf="customMultipleFilters.length > 0 || selectedSource"
          >
            :
            {{
              selectedSource == 'DATA_REQUEST'
                ? locale('locale_key.pages.datahub.files_portal.files_from_request')
                : selectedSource == 'EXPLORER'
                  ? locale('locale_key.pages.datahub.files_portal.uplaoded_files')
                  : ''
            }}
            <ng-container *ngFor="let filter of customMultipleFilters; let first = first">
              <ng-container *ngIf="!first || selectedSource">,</ng-container>
              {{ filter.name }}
            </ng-container>
          </p>
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="filter-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="filter-button"
          [ngClass]="{ 'width-dropdown': screenSize.isXSmallSize() }"
        >
          <div class="accordion-body px-0 pt-0">
            <div class="bg-light-gray col pt-3">
              <div class="d-flex flex-row justify-content-between px-2">
                <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                <a class="underline" (click)="clearAllFilters()">
                  {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                </a>
              </div>
              <div class="row px-2">
                <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedSource">
                  {{ locale('locale_key.pages.datahub.filter_none') }}
                </i>
                <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedSource">
                  {{
                    selectedSource == 'DATA_REQUEST'
                      ? locale('locale_key.pages.datahub.files_portal.files_from_request')
                      : selectedSource == 'EXPLORER'
                        ? locale('locale_key.pages.datahub.files_portal.uplaoded_files')
                        : ''
                  }}
                  <ng-container *ngFor="let filter of customMultipleFilters; let first = first">
                    <ng-container *ngIf="!first || selectedSource">,</ng-container>
                    {{ filter.name }}
                  </ng-container>
                </p>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
              <div class="d-flex" [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="selectedSource"
                    (change)="applyFilters()"
                    name="source"
                    value="DATA_REQUEST"
                    id="requests"
                  />
                  <label class="form-check-label" for="requests">
                    {{ locale('locale_key.pages.datahub.files_portal.show_files_from_requests') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="selectedSource"
                    (change)="applyFilters()"
                    name="source"
                    value="EXPLORER"
                    id="explorer"
                  />
                  <label class="form-check-label" for="explorer">
                    {{ locale('locale_key.pages.datahub.files_portal.uploaded_files') }}
                  </label>
                </div>
              </div>
              <div class="d-flex flex-row">
                <a class="underline" (click)="selectedSource = null; applyFilters()">
                  {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                </a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#companyCollapse"
                      aria-expanded="true"
                      aria-controls="companyCollapse"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.datahub.button.company') }}
                      </span>
                    </button>
                  </h2>
                  <div
                    id="companyCollapse"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterCompanies(companySearch.value)"
                            #companySearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3">
                          <a class="underline" (click)="selectAll('companyCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('companyCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let company of filteredCompanies; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="companyCheckbox"
                          id="company{{ i }}"
                          [checked]="isCustomFilterSelected(company)"
                          (change)="changeCustomFilters($event, company)"
                        />
                        <label>{{ company.supplierCompanyname }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.datahub.button.data_reporter') }}
                      </span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterDataReporters(contactSearch.value)"
                            #contactSearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3">
                          <a class="underline" (click)="selectAll('contactCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('contactCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let contact of filteredDataReporters; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="contactCheckbox"
                          id="company{{ i }}"
                          [checked]="isCustomFilterSelected(contact)"
                          (change)="changeCustomFilters($event, contact)"
                        />
                        <label class="col-4">{{ contact.getName() }}</label>
                        <label class="col-4">{{ contact.getCompanyName(stateService.activeWorkspace) }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.datahub.button.request') }}
                      </span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterRequest(requestSearch.value)"
                            #requestSearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3">
                          <a class="underline" (click)="selectAll('requestCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('requestCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let rg of filteredRgs; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="requestCheckbox"
                          id="company{{ i }}"
                          [checked]="isCustomFilterSelected(rg)"
                          (change)="changeCustomFilters($event, rg)"
                        />
                        <label>{{ rg.title }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseDate"
                      aria-expanded="false"
                      aria-controls="collapseDate"
                    >
                      {{ locale('locale_key.general.data_type.date') }}
                    </button>
                  </h2>
                  <div id="collapseDate" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="dateFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="dateIs"
                            value="dateIs"
                          />
                          <label class="form-check-label" for="dateIs">
                            {{ locale('locale_key.pages.datahub.files_portal.date_is') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="dateFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="dateIsBetween"
                            value="dateIsBetween"
                          />
                          <label class="form-check-label" for="dateIsBetween">
                            {{ locale('locale_key.pages.datahub.files_portal.date_is_between') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="dateFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="dateIsBefore"
                            value="dateIsBefore"
                          />
                          <label class="form-check-label" for="dateIsBefore">
                            {{ locale('locale_key.pages.datahub.files_portal.date_is_before') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="dateFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="dateIsAfter"
                            value="dateIsAfter"
                          />
                          <label class="form-check-label" for="dateIsAfter">
                            {{ locale('locale_key.pages.datahub.files_portal.date_is_after') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="ms-5">
                      <input
                        class="form-control d-inline ms-1"
                        type="date"
                        [(ngModel)]="date1"
                        (change)="handleDateCustomFilters()"
                      />
                      <ng-container *ngIf="dateFilter == 'dateIsBetween'">
                        {{ locale('locale_key.pages.datahub.data_entries.and') }}
                        <input
                          class="form-control d-inline ms-1"
                          [min]="date1"
                          type="date"
                          [(ngModel)]="date2"
                          (change)="handleDateCustomFilters()"
                        />
                      </ng-container>
                      <span class="underline text-muted" (click)="clearDateFilter()">
                        {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
        <button
          type="button"
          class="form-control"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ locale('locale_key.pages.datahub.filter_sort_by') }}
          {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <ng-container *ngFor="let option of sortedByOptions">
            <div class="dropdown-divider" *ngIf="option.divider"></div>
            <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="scroll" style="max-height: 65vh" *ngIf="!groupedBy">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a
              class="accordion accordion-icon-rotate accordion-button m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
              type="button"
            >
              <div
                class="col-xl-9 col-lg-9 col-md-9 col-sm-6 col-12"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <h5 class="text-truncate dark-text d-inline" *ngIf="!searchKeyword || searchKeyword == ''">
                  {{ locale('locale_key.pages.datahub.files_portal.table_header.all_files') }}
                </h5>
                <h5 class="text-truncate dark-text d-inline" *ngIf="searchKeyword && searchKeyword != ''">
                  {{ locale('locale_key.pages.datahub.search_result', { keyword: searchKeyword }) }}
                </h5>
              </div>
              <div class="col d-flex justify-content-end float-end pe-0">
                <a
                  class="p-0 my-auto float-end"
                  style="max-height: 75px; font-size: 14px"
                  [ngClass]="screenSize.isXSmallSize() ? 'me-auto mt-2' : 'ms-auto'"
                >
                  <ng-container *ngIf="!downloadingFiles">
                    <button
                      class="btn tertiary-button my-auto me-2"
                      (click)="showDownloadButton = false"
                      *ngIf="showDownloadButton"
                    >
                      <i class="la la-download"></i>
                      {{ locale('locale_key.pages.datahub.files_portal.button.multiple_files') }}
                    </button>
                    <button
                      class="btn secondary-button custom-button"
                      id="btn"
                      *ngIf="!showDownloadButton"
                      (click)="cancelDownload($event)"
                    >
                      {{ locale('locale_key.general.buttons.cancel') }}
                    </button>
                    <button
                      class="btn btn-add custom-button me-2"
                      *ngIf="!showDownloadButton"
                      (click)="downloadSelectedFiles($event)"
                    >
                      {{ locale('locale_key.general.buttons.download') }}
                    </button>
                  </ng-container>
                  <div class="ball-pulse d-flex justify-content-center loader-primary" *ngIf="downloadingFiles">
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                  </div>
                  <i
                    class="icon me-1"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </a>
              </div>
              <!-- <div class="px-3"></div> -->
            </a>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
              <div class="border" style="overflow: hidden !important; background-color: var(--complementary-color1)">
                <div class="card-content" style="overflow-y: hidden !important">
                  <div class="card-body px-2" style="overflow-y: hidden !important">
                    <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                      <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex flex-row my-auto ps-0">
                          <input
                            type="checkbox"
                            *ngIf="!showDownloadButton"
                            class="form-check-input me-2 my-auto"
                            (change)="handleAllFiles($event, 'filesCheckbox', filteredFiles)"
                          />
                          <span
                            class="my-auto text-truncate bold dark-text"
                            [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                          >
                            <b>{{ locale('locale_key.pages.datahub.files_portal.table_header.file_name') }}</b>
                          </span>
                        </div>
                        <div class="col-xl-3 col-md-4 col-6 justify-content-start my-auto d-none d-sm-block">
                          <span class="my-auto text-truncate bold dark-text">
                            {{ locale('locale_key.pages.datahub.files_portal.table_header.uploaded_by') }}
                          </span>
                        </div>
                        <div class="col-xl-2 col-2 justify-content-start my-auto d-none d-md-block">
                          <span class="my-auto text-truncate bold dark-text">
                            {{ locale('locale_key.pages.datahub.button.company') }}
                          </span>
                        </div>
                        <div class="col-xl-2 col-2 d-none d-xl-block justify-content-start my-auto">
                          <span class="my-auto text-truncate bold dark-text">
                            {{ locale('locale_key.pages.datahub.files_portal.table_header.added_on') }}
                          </span>
                        </div>
                        <div class="col-xl-1 col-1 justify-content-end my-auto">
                          <span class="my-auto text-truncate bold dark-text"></span>
                        </div>
                      </div>
                      <ng-container *ngFor="let file of filteredFiles; let i = index">
                        <div
                          class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                          (click)="selectedFile = file"
                        >
                          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-11 d-flex flex-row my-auto ps-0">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              *ngIf="!showDownloadButton"
                              class="filesCheckbox me-2"
                              id="file{{ i }}"
                              (change)="fileSelectedForDownload($event, file)"
                            />
                            <h5
                              class="text-bold-400 my-auto text-wrap"
                              [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                            >
                              {{ file.label }}
                            </h5>
                          </div>
                          <div class="col-xl-3 col-md-4 col-5 flex-row justify-content-start my-auto d-none d-sm-block">
                            <h5 class="dark-text d-inline text-truncate">{{ file.getUploaderName() }}</h5>
                          </div>
                          <div
                            class="col-xl-2 col-md-3 col-2 flex-row justify-content-start my-auto text-truncate d-none d-md-block"
                          >
                            <h5 class="dark-text d-inline text-truncate">{{ file.uploaderCompany }}</h5>
                          </div>
                          <div class="col-xl-2 col-2 d-none d-xl-block justify-content-start my-auto text-truncate">
                            <span class="my-auto text-truncate dark-text">{{ file.localTimeCreated() }}</span>
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 d-flex justify-content-end float-end pe-0">
                            <div class="btn-group my-auto">
                              <ng-container *ngIf="!screenSize.isXSmallSize()">
                                <i
                                  class="la la-trash me-1 fs-4"
                                  *ngIf="!file.loadingFileInProgress && file.source == 'EXPLORER'"
                                  (click)="openModal(deleteWindow)"
                                ></i>
                                <i
                                  class="la la-download me-1 fs-4"
                                  *ngIf="!file.loadingFileInProgress"
                                  (click)="downloadFile(file, $event)"
                                ></i>
                                <i
                                  class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 mx-2"
                                  (click)="openFile(file)"
                                ></i>
                                <div
                                  class="ball-pulse d-flex justify-content-center loader-primary"
                                  *ngIf="file.loadingFileInProgress"
                                >
                                  <div style="background-color: var(--main-dark-color1)"></div>
                                  <div style="background-color: var(--main-dark-color1)"></div>
                                  <div style="background-color: var(--main-dark-color1)"></div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="screenSize.isXSmallSize()">
                                <div
                                  class="description-btn d-flex justify-content-center"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="la la-ellipsis-h fs-2 my-auto" style="rotate: 90deg"></i>
                                </div>
                                <div
                                  class="dropdown-menu ms-0 ps-0"
                                  style="min-width: fit-content !important; z-index: 1000000 !important"
                                >
                                  <ng-container *ngIf="screenSize.isXSmallSize()">
                                    <button
                                      class="dropdown-item ms-0 ps-1"
                                      *ngIf="!file.loadingFileInProgress && file.source == 'EXPLORER'"
                                      type="button"
                                      (click)="openModal(deleteWindow)"
                                    >
                                      <i class="la la-trash me-2 fs-4"></i>
                                      {{ locale('locale_key.general.buttons.delete') }}
                                    </button>
                                    <button
                                      class="dropdown-item ms-0 ps-1"
                                      type="button"
                                      (click)="downloadFile(file, $event)"
                                    >
                                      <i class="la la-download me-2 fs-4"></i>
                                      {{ locale('locale_key.general.buttons.download') }}
                                    </button>
                                    <button class="dropdown-item ms-0 ps-1" type="button" (click)="openFile(file)">
                                      <i class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 me-2"></i>
                                      {{ locale('locale_key.general.buttons.open') }}
                                    </button>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll" style="max-height: 65vh" *ngIf="groupedBy">
      <div class="mb-3" *ngFor="let group of filteredGroupedFiles; let i = index">
        <a
          class="accordion accordion-icon-rotate accordion-button m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
          id="filesHeader{{ i }}"
        >
          <div
            class="col-xl-4 col-lg-4 col-md-4 col-12"
            data-bs-toggle="collapse"
            attr.href="#files{{ i }}"
            aria-expanded="true"
            attr.aria-controls="files{{ i }}"
          >
            <h5 class="text-truncate dark-text d-inline">{{ group.name }}</h5>
          </div>
          <div class="col d-flex justify-content-end float-end pe-0">
            <a
              class="p-0 my-auto float-end"
              style="max-height: 75px"
              [ngClass]="screenSize.isXSmallSize() ? 'me-auto mt-2' : 'ms-auto'"
            >
              <ng-container *ngIf="!downloadingFiles">
                <button
                  class="btn tertiary-button my-auto"
                  (click)="openDownloadButtonGroupIndex = i"
                  *ngIf="openDownloadButtonGroupIndex != i"
                >
                  <i class="la la-download fs-4"></i>
                  {{ locale('locale_key.pages.datahub.files_portal.button.multiple_files') }}
                </button>
                <button
                  class="btn secondary-button custom-button"
                  id="btn"
                  *ngIf="openDownloadButtonGroupIndex == i"
                  (click)="cancelDownload($event)"
                >
                  {{ locale('locale_key.general.buttons.cancel') }}
                </button>
                <button
                  class="btn btn-add custom-button"
                  *ngIf="openDownloadButtonGroupIndex == i"
                  (click)="downloadSelectedFiles($event)"
                >
                  {{ locale('locale_key.general.buttons.download') }}
                </button>
              </ng-container>
              <div class="ball-pulse d-flex justify-content-center loader-primary" *ngIf="downloadingFiles">
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
              </div>
              <!-- <i class="icon me-1" data-bs-toggle="collapse" attr.href="#files{{ i }}" aria-expanded="true" attr.aria-controls="files{{ i }}"></i> -->
            </a>
          </div>
        </a>
        <div
          id="files{{ i }}"
          role="tabpanel"
          attr.aria-labelledby="filesHeader{{ i }}"
          class="border show"
          style="overflow: hidden !important; background-color: var(--complementary-color1)"
        >
          <div class="card-content" style="overflow-y: hidden !important">
            <div class="card-body px-2" style="overflow-y: hidden !important">
              <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex flex-row my-auto ps-0">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      *ngIf="openDownloadButtonGroupIndex == i"
                      (change)="handleAllFiles($event, 'filesCheckbox' + i, group.files)"
                    />
                    <h5
                      class="my-auto text-truncate bold"
                      [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                    >
                      <b>{{ locale('locale_key.pages.datahub.files_portal.table_header.file_name') }}</b>
                    </h5>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 col-3 justify-content-start my-auto d-none d-sm-flex">
                    <span class="my-auto text-truncate bold dark-text">
                      {{ locale('locale_key.pages.datahub.files_portal.table_header.uploaded_by') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-md-4 col-2 d-none d-md-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold dark-text">
                      {{ locale('locale_key.pages.datahub.button.company') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-xl-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold dark-text">
                      {{ locale('locale_key.pages.datahub.files_portal.table_header.added_on') }}
                    </span>
                  </div>
                  <div class="col-xl-1 col-1 d-none d-lg-flex justify-content-end my-auto">
                    <span class="my-auto text-truncate bold"></span>
                  </div>
                </div>
                <ng-container *ngFor="let file of group.files">
                  <div
                    class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                    (click)="selectedFile = file"
                  >
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-11 d-flex flex-row my-auto ps-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        *ngIf="openDownloadButtonGroupIndex == i"
                        class="filesCheckbox{{ i }}"
                        (change)="fileSelectedForDownload($event, file)"
                      />
                      <h5
                        class="text-bold-400 my-auto text-wrap"
                        [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                      >
                        {{ file.label }}
                      </h5>
                    </div>
                    <div
                      class="col-xl-3 col-md-4 col-sm-5 col-3 flex-row justify-content-start my-auto text-truncate d-none d-sm-flex"
                    >
                      <h5 class="dark-text d-inline text-truncate">{{ file.getUploaderName() }}</h5>
                    </div>
                    <div
                      class="col-xl-2 col-md-3 col-2 d-none d-md-flex flex-row justify-content-start my-auto text-truncate"
                    >
                      <h5 class="dark-text d-inline text-truncate">{{ file.uploaderCompany }}</h5>
                    </div>
                    <div class="col-xl-2 col-2 d-none d-xl-flex justify-content-start my-auto text-truncate">
                      <span class="my-auto text-truncate">{{ file.localTimeCreated() }}</span>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 d-flex justify-content-end float-end pe-0">
                      <div class="btn-group my-auto" *ngIf="!screenSize.isXSmallSize()">
                        <i
                          class="la la-trash me-1 fs-4"
                          *ngIf="!file.loadingFileInProgress && file.source == 'EXPLORER'"
                          (click)="openModal(deleteWindow)"
                        ></i>
                        <i
                          class="la la-download me-1 fs-4"
                          *ngIf="!file.loadingFileInProgress"
                          (click)="downloadFile(file, $event)"
                        ></i>
                        <i class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 mx-2" (click)="openFile(file)"></i>
                        <div
                          class="ball-pulse d-flex justify-content-center loader-primary"
                          *ngIf="file.loadingFileInProgress"
                        >
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                        </div>
                      </div>
                      <ng-container *ngIf="screenSize.isXSmallSize()">
                        <div
                          class="description-btn d-flex justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="la la-ellipsis-h fs-2 my-auto" style="rotate: 90deg"></i>
                        </div>
                        <div
                          class="dropdown-menu ms-0 ps-0"
                          style="min-width: fit-content !important; z-index: 1000000 !important"
                        >
                          <!-- <button class="dropdown-item ms-0 ps-1" type="button">
                            <i class="la la-copy me-1"></i> {{ locale("locale_key.pages.datahub.data_entries.button.duplicate") }}
                          </button> -->
                          <ng-container *ngIf="screenSize.isXSmallSize()">
                            <button
                              class="dropdown-item ms-0 ps-1"
                              *ngIf="!file.loadingFileInProgress && file.source == 'EXPLORER'"
                              type="button"
                              (click)="openModal(deleteWindow)"
                            >
                              <i class="la la-trash me-2 fs-4"></i>
                              {{ locale('locale_key.general.buttons.delete') }}
                            </button>
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="downloadFile(file, $event)">
                              <i class="la la-download me-2 fs-4"></i>
                              {{ locale('locale_key.general.buttons.download') }}
                            </button>
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openFile(file)">
                              <i class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 me-2"></i>
                              {{ locale('locale_key.general.buttons.open') }}
                            </button>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteWindow>
  <div class="modal-header" style="border: none">
    <div
      class="icon-background d-inline mt-2"
      style="height: 18px; width: 18px; background-color: #c65c5c; border-radius: 50%"
    >
      <i
        class="la la-times fs-6 text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: 2px;
          top: -2px;
        "
      ></i>
    </div>
    <h4 class="modal-title ms-2">
      {{ locale('locale_key.pages.file_explorer.delete_file_confirmation_modal.title') }}
    </h4>
    <button type="button" class="ms-auto btn close">
      <span aria-hidden="false" class="list-inline">
        <a (click)="closeModal()"><i class="las la-times"></i></a>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale('locale_key.pages.file_explorer.delete_file_confirmation_modal.description') }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-grid-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!inProgress">
      <button type="button" class="btn primary-button custom-button" (click)="deleteFileFromStorage()">
        {{ locale('locale_key.pages.file_explorer.delete_file_confirmation_modal.button.delete') }}
      </button>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.close') }}
      </button>
    </ng-container>
  </div>
</ng-template>
