import { DataGridColumnSchema } from 'src/app/components/projects/data-grid-ui/model/DataGridColumnSchema'
import { ColumnModeFe } from './ColumnModeFe'
import { ColumnTypeFe } from './ColumnTypeFe'
import { UnitFe } from 'src/app/components/unit-systems/model/UnitFe'
import { AbstractEmissionFactorFe } from '../emissions/AbstractEmissionFactorFe'

export class ColumnSchemaFe {
  public name: string
  public label: string | undefined
  public type: string = ColumnTypeFe.STRING
  public mode: string = ColumnModeFe.NULLABLE
  public status: string | undefined
  public width: string = '10rem'
  public isCalculated: boolean = false
  public emissionFactor: AbstractEmissionFactorFe | null
  public unitSymbol?: string | undefined
  public measurementKey?: string | undefined
  public showUnitCol?: boolean = true
  public isPreDetermined?: boolean = false
  public predefinedOptions?: any[] = []
  public customValuesAllowedWhenPredefinedOptionsSet?: boolean = false
  public userQuestion?: string | undefined
  public userQuestionDescription?: string | undefined

  public static fromTransfer(
    transfer: any,
    entityLabel: string,
    isCalculated = false,
    attributeUnit: UnitFe = null,
    showUnitCol = true,
    datatype: string = null,
    isPreDetermined = false,
    emissionFactor: AbstractEmissionFactorFe = null
  ) {
    let columnSchema = new ColumnSchemaFe()
    columnSchema.name = transfer['name']
    columnSchema.type = datatype ? datatype : transfer['type']
    columnSchema.mode = transfer['mode']
    columnSchema.label = entityLabel
    columnSchema.isCalculated = isCalculated
    columnSchema.unitSymbol = attributeUnit?.symbol
    columnSchema.measurementKey = attributeUnit?.measurementType
    columnSchema.showUnitCol = showUnitCol
    columnSchema.isPreDetermined = isPreDetermined
    columnSchema.emissionFactor = emissionFactor
    columnSchema.predefinedOptions = transfer['predefinedOptions'] || []
    columnSchema.customValuesAllowedWhenPredefinedOptionsSet = transfer['customValuesAllowedWhenPredefinedOptionsSet']
      ? true
      : false
    columnSchema.userQuestion = transfer['userQuestion']
    columnSchema.userQuestionDescription = transfer['userQuestionDescription']
    return columnSchema
  }

  public toDataGridColumnSchema(): DataGridColumnSchema {
    let dgcs = new DataGridColumnSchema()
    dgcs.label = this.label
    dgcs.mode = this.mode
    dgcs.name = this.name
    dgcs.status = this.status
    dgcs.type = this.type
    dgcs.width = this.width
    dgcs.isCalculated = this.isCalculated
    dgcs.unitSymbol = this.unitSymbol
    dgcs.measurementKey = this.measurementKey
    dgcs.showUnitCol = this.showUnitCol
    dgcs.isPreDetermined = this.isPreDetermined
    dgcs.emissionFactor = this.emissionFactor
    dgcs.predefinedOptions = this.predefinedOptions
    dgcs.customValuesAllowedWhenPredefinedOptionsSet = this.customValuesAllowedWhenPredefinedOptionsSet
    dgcs.userQuestion = this.userQuestion
    dgcs.userQuestionDescription = this.userQuestionDescription
    return dgcs
  }

  public static fromDataGridColumnSchema(dgcs: DataGridColumnSchema): ColumnSchemaFe {
    let column = new ColumnSchemaFe()
    column.label = dgcs.label
    column.mode = dgcs.mode
    column.name = dgcs.name
    column.status = dgcs.status
    column.type = dgcs.type
    column.width = dgcs.width
    column.isCalculated = dgcs.isCalculated
    column.unitSymbol = dgcs.unitSymbol
    column.measurementKey = dgcs.measurementKey
    column.showUnitCol = dgcs.showUnitCol
    column.isPreDetermined = dgcs.isPreDetermined
    column.emissionFactor = dgcs.emissionFactor
    column.predefinedOptions = dgcs.predefinedOptions
    column.customValuesAllowedWhenPredefinedOptionsSet = dgcs.customValuesAllowedWhenPredefinedOptionsSet
    column.userQuestion = dgcs.userQuestion
    column.userQuestionDescription = dgcs.userQuestionDescription
    return column
  }
}
