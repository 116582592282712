import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { RouterFe } from 'src/app/route/RouterFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { GlobalDataTaxonomyFe } from 'src/app/model/taxonomy/GlobalDataTaxonomyFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { MathStatementFe } from 'src/app/model/calculation/MathStatementFe'
import { TwoOperandStatementFe } from 'src/app/model/calculation/TwoOperandStatementFe'
import { ValueStatementFe } from 'src/app/model/calculation/ValueStatementFe'
import { EmissionFactorFe } from 'src/app/model/emission/EmissionFactorFe'
import { EmissionFactorStatementFe } from 'src/app/model/calculation/EmissionFactorStatementFe'
import { OperatorFe } from 'src/app/model/calculation/OperatorFe'
import { VariableStatementFe } from 'src/app/model/calculation/VariableStatementFe'
import { StatementBuilderFe } from 'src/app/model/calculation/StatementBuilderFe'
import { LoginUserFe } from 'src/app/model/org/LoginUserFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { ValidationMessages } from './../../model/form-validation/FormValidationMessages'
import { TaxonomyInfoSubjectFe } from 'src/app/model/subject/TaxonomyInfoSubjectFe'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'

@Component({
  selector: 'taxonomy-component',
  templateUrl: './taxonomy.component.html',
  styleUrls: ['./taxonomy.component.scss']
})
export class TaxonomyComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('emissionPortal', { static: true }) emissionPortal: TemplateRef<any>
  userInfo: LoginUserFe
  globalTaxonomy: GlobalDataTaxonomyFe
  selectedEntityKey: string = null
  customCategoryLabel = ''
  equationCustomValue: number = null
  isInProgress: boolean = false
  isLoading = false
  isDirty = false
  openEmissionPortal = false
  toAssignEmissionAttr
  toAssignEmissionItem
  taxonomy_tips: true
  dataTypeMessage = this.locale('locale_key.general.validation_message.datatype_required')
  dataDescriptionMessage = this.locale('locale_key.general.validation_message.description_required')
  dataShortNameMessage = this.locale('locale_key.general.validation_message.shortname_required')

  isEditMode = false
  depTaxonomy: TaxonomyInfoFe
  newTaxonomy: TaxonomyInfoFe
  public modalRef: BsModalRef
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left',
        actionName: 'back_from_edit',
        visible: () => this.isEditMode,
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.save'),
        longLabel: this.locale('locale_key.general.toolbar.button.save'),
        tooltip: this.locale('locale_key.general.toolbar.button.save'),
        icon: 'la la-save',
        actionName: 'save_new_taxonomy_changes',
        visible: () => this.isEditMode && this.isDirty && !this.isInProgress,
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.upgrade'),
        longLabel: this.locale('locale_key.general.toolbar.button.upgrade'),
        tooltip: this.locale('locale_key.general.toolbar.button.upgrade'),
        icon: 'la la-cloud-upload',
        actionName: 'deploy_taxonomy',
        visible: () => this.isEditMode && (this.isDirty || this.newTaxonomy.modified) && !this.isInProgress,
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.edit'),
        longLabel: this.locale('locale_key.general.toolbar.button.edit'),
        tooltip: this.locale('locale_key.general.toolbar.button.edit'),
        icon: 'la la-edit',
        actionName: 'edit_taxonomy',
        visible: () => !this.isEditMode,
        disabled: false
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false
      }
    ]
  ]

  url: string

  constructor(
    private readonly FormBuilder: FormBuilder,
    private _cd: ChangeDetectorRef,
    protected modalService: BsModalService,
    private ErrorsFe: ErrorsFe,
    private backendService: RouterFe,
    private stateService: StateServiceFe,
    private loginService: LoginServiceFe,
    private displayService: DisplayServiceFe,
    private alertService: AlertServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    if (stateService.activeWorkspace) {
      this.loadCompanyTaxonomy()
    }

    stateService.depTaxonomyInfoSubject.subscribe((subject: TaxonomyInfoSubjectFe) => {
      this.depTaxonomy = subject.taxonomyInfo
    })

    stateService.newTaxonomyInfoSubject.subscribe((subject: TaxonomyInfoSubjectFe) => {
      this.newTaxonomy = subject.taxonomyInfo
    })
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    this.selectedEntityKey = null
    this.isDirty = false
    this.isEditMode = false
    this.userInfo = this.loginService.getUserInfo()
    this.isLoading = true
    await this.loadGloblTaxonomy()
    await this.loadCompanyTaxonomy()

    if (this.depTaxonomy.entities.length == 0) {
      this.displayService.openTips()
    }
    this.isLoading = false
  }

  remainingChildren(parentKey = null): any {
    let taxonomyInfo = this.getTaxonomyInfo()
    var existingChildren: any[] = taxonomyInfo.childrenSortedByOrdinal(parentKey)
    let children = this.globalTaxonomy.children(parentKey)
    if (children) {
      children = children.filter((gitem) => {
        let item = existingChildren.find((litem) => litem.key == gitem.key)
        return item ? false : true
      })
      return children
    }
    return children
  }

  addCategory(item) {
    let taxonomyInfo = this.getTaxonomyInfo()
    taxonomyInfo.addEntity(item)
    this.isDirty = true
  }

  addCustomCategory(divId, parentCatItem: EntityFe) {
    let taxonomyInfo = this.getTaxonomyInfo()
    const label = { en: this.customCategoryLabel }
    taxonomyInfo.addCustomEntity(label, parentCatItem)
    this.customCategoryLabel = ''
    let popupElement = document.getElementById(divId)
    popupElement.click()
    this.isDirty = true
  }

  removeCategory(entity) {
    let taxonomyInfo = this.getTaxonomyInfo()
    let removedKeys = taxonomyInfo.removeEntity(entity)
    if (this.getSelectedEntity() && removedKeys.includes(this.getSelectedEntity().key)) {
      this.selectedEntityKey = null
    }
    this.isDirty = true
  }

  moveup(entity: EntityFe) {
    let taxonomyInfo = this.getTaxonomyInfo()
    taxonomyInfo.raiseOrder(entity)
    this.isDirty = true
  }

  movedown(entity: EntityFe) {
    let taxonomyInfo = this.getTaxonomyInfo()
    taxonomyInfo.decreaseOrder(entity)
    this.isDirty = true
  }

  public async loadGloblTaxonomy() {
    let globalTaxonomy = await this.backendService.getGlobalDataTaxonomy().toPromise()
    this.globalTaxonomy = new GlobalDataTaxonomyFe(globalTaxonomy, this.languageService)
  }

  private postLoadingTaxonomy(taxonomyInfo: TaxonomyInfoFe) {
    let maxOrdinal = taxonomyInfo.maxOrdinal()

    let noOfDataScopes = 0
    taxonomyInfo?.childrenSortedByOrdinal().forEach((item0) => {
      taxonomyInfo?.childrenSortedByOrdinal(item0.key).forEach((item1) => {
        taxonomyInfo?.childrenSortedByOrdinal(item1.key).forEach((item2) => {
          noOfDataScopes++
        })
      })
    })

    taxonomyInfo.entities.forEach((t, i) => {
      if (!t.ordinal) {
        t.ordinal = ++maxOrdinal
      }

      if (t.columns) {
        /*         t.columns.forEach(c => {
          if (c.calculation) {
            if (c.calculation == "undefined" || c.calculation == "null") {
              c.calculation = null
            } else {
              let jsonObj = JSON.parse(c.calculation) 
              c.calculation = MathStatement.castToClass(jsonObj)  
            }
          }
        });
 */
      }
    })
  }

  public async loadCompanyTaxonomy() {
    try {
      this.isLoading = true
      let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
      this.depTaxonomy = depTaxonomy
      this.newTaxonomy = newTaxonomy
      this.postLoadingTaxonomy(depTaxonomy)
      this.isLoading = false
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (knownError == this.ErrorsFe.TAXONOMY_NOT_SEEDED_ERROR) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(this.ErrorsFe.TAXONOMY_NOT_LOADED_ERROR.message)
      }
      this.isLoading = false
    }
  }

  selectItem(itemKey: string) {
    this.selectedEntityKey = itemKey
  }

  unselectedAttrs() {
    let selectedEntity = this.getSelectedEntity()
    let skeys = selectedEntity.columns.map((s) => s.key)
    let attrs = this.globalTaxonomy.selfAndInheritedAttributes(selectedEntity)
    let uattrs = attrs.filter((a) => !skeys.includes(a.key))
    return uattrs
  }

  selectAttr(attr) {
    let clone = JSON.parse(JSON.stringify(attr))
    let newAttr = new TaxonomyAttributeFe(clone, this.languageService)
    let selectedEntity = this.getSelectedEntity()
    selectedEntity.columns.push(newAttr)
    selectedEntity.adjustKey(newAttr)
    this.isDirty = true
    selectedEntity.modified = true
    this.newTaxonomy.modified = true
  }

  deselectAttr(attr) {
    let selectedEntity = this.getSelectedEntity()
    selectedEntity.columns = selectedEntity.columns.filter((s) => s.key != attr.key)
    this.isDirty = true
    selectedEntity.modified = true
    this.newTaxonomy.modified = true
  }

  numericalAttrs(exclude = null) {
    let selectedEntity = this.getSelectedEntity()
    if (exclude) {
      return selectedEntity.columns.filter((a) => a.datatype == 'NUMERIC' && a.key != exclude.key)
    } else {
      return selectedEntity.columns.filter((a) => a.datatype == 'NUMERIC')
    }
  }

  addCalculation(attr) {
    attr.calculation = new MathStatementFe()
    this.isDirty = true
  }

  resetCalculation(attr) {
    attr.calculation = null
    this.isDirty = true
  }

  parseCalculation(attr) {
    if (attr.calculation) {
      let statement = StatementBuilderFe.build(attr.calculation)
      return statement
    }
  }

  addTwoOprandStatement(attr, item, opKey) {
    let statement = new TwoOperandStatementFe()
    statement.operator = OperatorFe.opByKey(opKey)
    if (item.parent) {
      if (item.position == 'L') {
        item.parent.leftStatement = statement
      } else {
        item.parent.rightStatement = statement
      }
    } else {
      attr.calculation = statement
    }
    this.isDirty = true
  }

  addValue(attr, item) {
    let statement = new ValueStatementFe('', this.equationCustomValue)
    if (item.parent) {
      if (item.position == 'L') {
        item.parent.leftStatement = statement
      } else {
        item.parent.rightStatement = statement
      }
    } else {
      attr.calculation = statement
    }

    this.equationCustomValue = null

    let popupElement = document.getElementById('popup3')
    popupElement.click()
    this.isDirty = true
  }

  selectEmissionFactor(attr, item) {
    this.toAssignEmissionAttr = attr
    this.toAssignEmissionItem = item
  }

  setEmissionFactor(em: EmissionFactorFe) {
    if (em) {
      let statement = new EmissionFactorStatementFe(em)
      if (this.toAssignEmissionItem && this.toAssignEmissionItem.parent) {
        let item = this.toAssignEmissionItem
        if (item.position == 'L') {
          item.parent.leftStatement = statement
        } else {
          item.parent.rightStatement = statement
        }
      } else {
        this.toAssignEmissionAttr.calculation = statement
      }
    }
    this.toAssignEmissionItem = null
    this.toAssignEmissionAttr = null
    this.openEmissionPortal = false
    this.isDirty = true
    this.closeModal(true)
  }

  addVariable(attr, item, entityAttr) {
    let selectedEntity = this.getSelectedEntity()
    let statement = new VariableStatementFe(
      '',
      selectedEntity.key,
      selectedEntity.getLabel(),
      entityAttr.key,
      entityAttr.label
    )
    if (item.parent) {
      if (item.position == 'L') {
        item.parent.leftStatement = statement
      } else {
        item.parent.rightStatement = statement
      }
    } else {
      attr.calculation = statement
    }
    this.isDirty = true
  }

  isPlaceholder(element) {
    if (element instanceof MathStatementFe) return element.isPlaceholder()
    else return false
  }

  tooltip(item) {
    if (item instanceof MathStatementFe) {
      return item.tooltip()
    } else {
      return null
    }
  }

  isSelected(attr) {
    let selectedEntity = this.getSelectedEntity()
    return selectedEntity.columns && selectedEntity.columns.find((a) => a.key == attr.key)
  }

  addEmptyAttr() {
    let selectedEntity = this.getSelectedEntity()
    let attr = {
      taxonomyKey: selectedEntity.key,
      key: null,
      label: null,
      datatype: null,
      description: null
    }
    let taxonomyAttr = new TaxonomyAttributeFe(attr, this.languageService)
    selectedEntity.columns.push(taxonomyAttr)
    selectedEntity.adjustKey(taxonomyAttr)

    this.isDirty = true
    // for( let taxonomyAttr of selectedEntity.columns) {
    //   if(taxonomyAttr.key == "nokey" && (taxonomyAttr.label == null || taxonomyAttr.label == '' || taxonomyAttr.label == undefined) && (taxonomyAttr.description == null || taxonomyAttr.description == '' || taxonomyAttr.description == undefined) && (taxonomyAttr.datatype == null || taxonomyAttr.datatype == '' || taxonomyAttr.datatype == undefined)) {
    //       this.isDirty = false

    //     }else {
    //     }
    // }
  }

  checkIsInvalid() {
    let entity = this.getSelectedEntity()
    if (entity) {
      let isInValid = entity.columns.some(
        (e) =>
          e.descriptionControl.invalid ||
          e.labelControl.invalid ||
          e.datatype == null ||
          e.datatype == undefined ||
          e.datatype == ''
      )
      entity.isInValid = isInValid
    }
    let anyEntityInvalid = this.getTaxonomyInfo().entities.some((e) => e.isInValid)
    return anyEntityInvalid
  }

  async saveNewTaxonomyChanges() {
    try {
      let newTaxonomyInfo = this.getTaxonomyInfo()
      this.isInProgress = true
      newTaxonomyInfo.diffDeployment(this.depTaxonomy)
      let updated = await this.stateService.updateNewTaxonomyInfo(newTaxonomyInfo)
      this.isInProgress = false
      this.isDirty = false
      newTaxonomyInfo.modified = true
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (knownError == this.ErrorsFe.TAXONOMY_NOT_SAVED_ERROR) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(this.ErrorsFe.TAXONOMY_NOT_SAVED_ERROR.message)
      }
      this.isDirty = true
      this.isInProgress = false
    }
  }

  async deployTaxonomy() {
    try {
      this.isInProgress = true
      if (this.isDirty) {
        let updated = await this.stateService.updateNewTaxonomyInfo(this.newTaxonomy)
      }
      this.postLoadingTaxonomy(this.newTaxonomy)
      let deployed = await this.stateService.upgradeTaxonomyInfo()
      this.isEditMode = false
      this.isDirty = false
      this.isInProgress = false
      this.selectedEntityKey = null
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (
        knownError == this.ErrorsFe.TAXONOMY_NOT_DEPLOYED_ERROR ||
        knownError == this.ErrorsFe.MASTER_TABLE_NAME_NOT_GENERATED_ERROR ||
        knownError == this.ErrorsFe.MASTER_TABLE_ALREADY_EXISTS
      ) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(this.ErrorsFe.TAXONOMY_NOT_DEPLOYED_ERROR.message)
      }
      this.isDirty = true
      this.isInProgress = false
    }
  }

  editTaxonomy() {
    this.isEditMode = true
    this.selectedEntityKey = null
  }

  viewTaxonomy() {
    this.isEditMode = false
    this.selectedEntityKey = null
  }

  public openModal(toolbar: TemplateRef<any> | string, size: string = '') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: 'modal-xl'
    }
    this.modalRef = this.modalService.show(toolbar, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open')
    }
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  getTaxonomyInfo(): TaxonomyInfoFe {
    let taxonomyInfo = this.isEditMode ? this.newTaxonomy : this.depTaxonomy
    return taxonomyInfo
  }

  getSelectedEntity(taxonomy = null): EntityFe {
    if (!taxonomy) taxonomy = this.getTaxonomyInfo()
    let entity = taxonomy.entities.find((entity) => entity.key == this.selectedEntityKey)
    // if (!entity?.columns) {
    //   entity.columns = []
    //   entity.table = true
    // }
    return entity
  }

  changedLabel(attr: TaxonomyAttributeFe) {
    this.getSelectedEntity().adjustKey(attr)
    let orgAttr = this.getSelectedEntity(this.depTaxonomy).columns.find((a) => a.key == attr.key)
    if (orgAttr && orgAttr.label == attr.label) {
      attr.modified = false
    } else {
      attr.modified = true
      this.isDirty = true
    }
  }

  changedDesc(attr: TaxonomyAttributeFe) {
    let orgAttr = this.getSelectedEntity(this.depTaxonomy).columns.find((a) => a.key == attr.key)
    if (orgAttr && orgAttr.description == attr.description) {
      attr.modified = false
    } else {
      attr.modified = true
      this.isDirty = true
    }
  }

  changedDataType(attr: TaxonomyAttributeFe) {
    let orgAttr = this.getSelectedEntity(this.depTaxonomy).columns.find((a) => a.key == attr.key)
    if (orgAttr && orgAttr.datatype == attr.datatype) {
      attr.modified = false
    } else {
      attr.modified = true
      this.isDirty = true
    }
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'back_from_edit':
        this.viewTaxonomy()
        break
      case 'save_new_taxonomy_changes':
        this.saveNewTaxonomyChanges()
        break
      case 'deploy_taxonomy':
        this.deployTaxonomy()
        break
      case 'edit_taxonomy':
        this.editTaxonomy()
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }
}
