import { DataGridReferenceColumnSchema } from 'src/app/components/projects/data-grid-ui/model/DataGridReferenceColumnSchema'

export class ReferenceColumnSchemaFe {
  referenceKey: string

  public static fromTransfer(transfer: any) {
    let columnSchema = new ReferenceColumnSchemaFe()
    columnSchema.referenceKey = transfer['referenceKey']
    return columnSchema
  }

  public toDataGridColumnSchema(): DataGridReferenceColumnSchema {
    let dgrs = new DataGridReferenceColumnSchema()
    dgrs.referenceKey = this.referenceKey
    return dgrs
  }

  public static fromDataGridColumnSchema(dgcs: DataGridReferenceColumnSchema): ReferenceColumnSchemaFe {
    let column = new ReferenceColumnSchemaFe()
    column.referenceKey = dgcs.referenceKey
    return column
  }
}
