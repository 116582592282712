export class MathStatementFe {
  public static TYPE_KEY = 'EMPTY'
  public type = MathStatementFe.TYPE_KEY

  constructor(
    public parent = null,
    public position = null
  ) {}
  public toString(): string {
    return ''
  }

  public flatten(): any[] {
    return [this]
  }

  isPlaceholder() {
    return true
  }

  public tooltip(): string {
    return ''
  }

  public convert(JsonString: string) {
    return this
  }
}
