import { Directive, HostListener, ElementRef, OnInit } from '@angular/core'

@Directive({
  selector: '[resizer]'
})
export class NgxResizerDirective implements OnInit {
  height: number
  oldY = 0
  grabber = false

  constructor(private el: ElementRef) {}

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return
    }

    this.resizer(this.oldY - event.clientY)
    this.oldY = event.clientY
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabber = false
  }

  resizer(offsetY: number) {
    this.height += offsetY
    this.el.nativeElement.style.height = this.height + 'px'
  }

  @HostListener('mousedown', ['$event']) onResize(event: MouseEvent, resizer?: Function) {
    if (event.target['tagName'] != 'SELECT') {
      this.grabber = true
      this.oldY = event.clientY
      event.preventDefault()
    }
  }

  ngOnInit() {
    this.height = parseInt(this.el.nativeElement.offsetHeight)
  }
}
