import { SafeHtml } from '@angular/platform-browser'
import { DateUtil } from 'src/app/utils/DateUtil'

export class EmailFe {
  id: string
  requesterAffId: string
  requesterFirstName: string
  requesterLastName: string
  requesterCompanyName: string
  emailPurpose: string
  receiverAddress: string
  title: string
  body: string
  date: string
  sanitizedBody: SafeHtml

  constructor(
    id: string,
    requesterAffId: string,
    requesterFirstName: string,
    requesterLastName: string,
    requesterCompanyName: string,
    emailPurpose: string,
    receiverAddress: string,
    title: string,
    body: string,
    date: string,
    sanitizedBody?: SafeHtml
  ) {
    this.id = id
    this.requesterAffId = requesterAffId
    this.requesterFirstName = requesterFirstName
    this.requesterLastName = requesterLastName
    this.requesterCompanyName = requesterCompanyName
    this.emailPurpose = emailPurpose
    this.receiverAddress = receiverAddress
    this.title = title
    this.body = body
    this.date = date
    this.sanitizedBody = sanitizedBody
  }

  public static fromTransfer(transfer: any): EmailFe {
    let email = new EmailFe(
      transfer.id,
      transfer.requesterAffId,
      transfer.requesterFirstName,
      transfer.requesterLastName,
      transfer.requesterCompanyName,
      transfer.emailPurpose,
      transfer.receiverAddress,
      transfer.title,
      transfer.body,
      DateUtil.toString3(new Date(transfer.date))
    )
    return email
  }

  getName() {
    if (this.requesterLastName == 'SYSTEM') return this.requesterFirstName
    else return `${this.requesterFirstName} ${this.requesterLastName}`
  }
}
