import {
  ChangeDetectorRef,
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { RouterFe } from 'src/app/route/RouterFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DashboardChartFe } from 'src/app/model/dashboard/DashboardChartFe'
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe'
import { KpiDataFe } from 'src/app/model/kpi/KpiDataFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { Dashboard_AddFe } from 'src/app/model/dashboard/add/Dashboard_AddFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { InsightFe } from 'src/app/model/insight/InsightFe'
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew'

@Component({
  selector: 'dashboard-detail',
  templateUrl: './dashboard.detail.component.html',
  styleUrls: ['./dashboard.detail.component.scss']
})
export class DashboardDetailComponent extends AbstractLanguageComponent implements OnInit {
  public isLoadingData = false
  public inProgress = false
  public kpis: InsightFe[]
  titleMessage = this.locale('locale_key.general.validation_message.dashboard_title_required')

  form: FormGroup = new FormGroup({
    title: new FormControl(null, Validators.required),
    description: new FormControl(null)
  })

  @Input() edit: boolean = false
  @Input() kpi: boolean = false
  @Input() originalDashboard: DashboardFe | null = null
  @Input() dashboard: DashboardFe | null
  @Output() refreshView = new EventEmitter<DashboardFe>()
  @Output() closeModalEvent = new EventEmitter<boolean>()
  duplicate: boolean = false
  charts: DashboardChartFe[] = []

  constructor(
    private readonly FormBuilder: FormBuilder,
    private backendService: RouterFe,
    private stateService: StateServiceFe,
    languageService: LanguageService
  ) {
    super(languageService)
  }

  async ngOnInit() {
    await this.loadCompanyData()
    if (this.originalDashboard) {
      this.duplicate = true
      this.populateIt()
    }
    if (this.dashboard) {
      this.viewDashboard()
    }
  }

  viewDashboard() {
    this.form.patchValue({
      title: this.dashboard.name,
      description: this.dashboard.description
    })
    this.charts = [...this.dashboard.charts]
    this.form.markAsPristine()
  }

  async saveChages() {
    this.inProgress = true
    let formVal = this.form.value

    let newDashboard = new Dashboard_AddFe(IdUtil.next(), formVal.title, formVal.description, [...this.charts])
    if (this.originalDashboard) {
      //duplicate dashboard
      await this.stateService.modifyDashboard(this.originalDashboard)
      let dashboard = await this.stateService.createNewDashboard(newDashboard)
      this.refreshView.next(dashboard)
    } else if (this.edit) {
      //modify dashboard
      this.dashboard.name = formVal.title
      this.dashboard.description = formVal.description
      this.dashboard.charts = [...this.charts]
      await this.stateService.modifyDashboard(this.dashboard)
      this.refreshView.next(this.dashboard)
    } else if (this.kpi) {
      //add kpi
      this.dashboard.charts = [...this.charts]
      this.refreshView.next(this.dashboard)
    } else {
      //create new dashboard
      let dashboard = await this.stateService.createNewDashboard(newDashboard)
      this.refreshView.next(dashboard)
    }
    this.closeModalEvent.emit(true)
    this.inProgress = true
  }

  delete() {
    this.stateService.deleteDashboard(this.dashboard.id)
    this.closeModalEvent.emit(true)
  }

  cancel() {
    this.closeModalEvent.emit(true)
  }

  async loadCompanyData() {
    this.isLoadingData = true
    this.kpis = await this.stateService.getInsights()
    this.isLoadingData = false
  }

  addChartToDashboard(kpi: KpiDataFe) {
    let chart = new DashboardChartFe()
    chart.kpiId = kpi.id
    if (this.charts.length > 0) {
      let ordinals: number[] = this.charts.map((c) => c.ordinal)
      let maxOrdinal = Math.max(...ordinals)
      chart.ordinal = maxOrdinal + 1
    } else {
      chart.ordinal = 1
    }
    let kd = this.kpis.find((kd) => kd['id'] == chart.kpiId)
    let wrapper = ChartWrapperFeNew.build(kd, chart.setting)
    chart.wrapper = wrapper
    chart.setting = wrapper.setting
    this.charts.push(chart)
  }

  deleteChart(index: number) {
    this.charts.splice(index, 1)
  }

  populateIt() {
    let charts = this.originalDashboard.charts.map((c) => {
      let chart = new DashboardChartFe()
      chart.kpiId = c.kpiId
      chart.ordinal = c.ordinal
      chart.setting = c.setting
      chart.wrapper = c.wrapper
      return chart
    })
    let newDashboard = new DashboardFe(
      IdUtil.next(),
      'Copy of ' + this.originalDashboard.name,
      this.originalDashboard.description,
      charts,
      null
    )
    this.dashboard = newDashboard
  }
}
