<ng-container *ngIf="readingFile">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-container>
<div class="exportedTable" *ngIf="!readingFile">
  <datagrid-table-editor [dataGrid]="dataGrid"></datagrid-table-editor>

  <div
    [ngClass]="isModeExportExcelTableData() ? 'sheetNames' : ''"
    class="nav bg-white"
    *ngIf="isModeExportExcelTableData() || isModeViewTableData()"
  >
    <ng-container *ngFor="let sheetName of sheetNames; let i = index">
      <button class="sheetName nav-link" (click)="changeSheet(i)" [ngClass]="{ active: selectedSheetNo == i }">
        {{ sheetName }}
      </button>
    </ng-container>
  </div>
</div>
