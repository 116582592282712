import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DataCategoryServiceFe } from 'src/app/services/DataCategoryServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'add-data-category',
  templateUrl: './add-data-category.component.html',
  styleUrls: ['./add-data-category.component.scss']
})
export class AddDataCategoryComponent extends AbstractLanguageComponent implements OnInit {
  item0Control = new FormControl(null, [Validators.required])
  item1Control = new FormControl(null, [Validators.required])
  dataCategoryNameLang = new FormControl(null, [Validators.required])
  dataCategoryNameEn = new FormControl(null, [Validators.required])
  @Output() closeEvent = new EventEmitter<boolean>()
  taxonomy: TaxonomyInfoFe

  constructor(
    public languageService: LanguageService,
    public categoryService: DataCategoryServiceFe
  ) {
    super(languageService)
  }

  async ngOnInit(): Promise<void> {
    this.taxonomy = await this.categoryService.getTaxonomy()
  }

  onRootTaxonomyChange() {
    this.item1Control.setValue(null)
  }

  addEntity() {
    const label = {}
    if (this.activeLanguage.code !== 'en') {
      label[this.activeLanguage.code] = this.dataCategoryNameLang.value
    }

    label['en'] = this.dataCategoryNameEn.value

    this.categoryService.addEntity(label, this.item1Control.value)
    this.closeModal()
  }

  closeModal() {
    this.closeEvent.emit(true)
  }

  isLanguageEnglish() {
    return this.languageService.activeLanguage.code === 'en'
  }

  getCategoryLanguageFlag() {
    return `fs-4 fi fi-${this.activeLanguage.icon}`
  }
}
