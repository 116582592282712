import { Component, OnInit, Output } from '@angular/core'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject, Subscription } from 'rxjs'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DatahubService } from './DatahubService'
import { SupplierCompanyFe } from 'src/app/model/data-suppliers/company/SupplierCompanyFe'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { AutomatedFeedInfoFe } from 'src/app/model/automatedFeed/AutomatedFeedInfoFe'
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { DirectEntryInfoFe } from 'src/app/model/directEntry/DirectEntryInfoFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'

@Component({
  selector: 'datahub-home',
  templateUrl: './datahubHome.component.html',
  styleUrls: ['./data-hub.component.scss']
})
export class DatahubHomeComponent extends AbstractLanguageComponent implements OnInit {
  @Output() setViewAs: Subject<string> = new Subject()
  menuCollapsed: boolean
  selected = null

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInProgress: boolean = false
  filteringData = false
  subscriptions = new Subscription()

  taxonomy: TaxonomyInfoFe

  filteredCompanies: SupplierCompanyFe[] = []
  filteredGroupedCompanies: { name: string; companies: SupplierCompanyFe[] }[] = []
  companies: SupplierCompanyFe[] = []
  selectedCompany: SupplierCompanyFe
  searchKeywordForCompany: string = ''

  filteredContacts: ContactFe[] = []
  filteredGroupedContacts: { name: string; contacts: ContactFe[] }[] = []
  contacts: ContactFe[] = []
  selectedContact: ContactFe
  searchKeywordForContact: string = ''

  noOfDOSubmissions: number = 0
  noOfFiles: number = 0
  noOFDirectEntries: number = 0
  noOfAutomatedFeeds: number = 0
  noOfMasterTable: number = 0

  groupedBy = null
  sortedBy = null
  groupedByOptionsForCompany = [
    { name: this.locale('locale_key.pages.datahub.group.option.industry'), value: 'industries' }
  ]
  sortedByOptionsForCompany = [
    { name: this.locale('locale_key.pages.datahub.sort.option.company_name'), value: 'companyName' },
    { name: this.locale('locale_key.pages.datahub.sort.option.company_name_desc'), value: 'companyNameDes' },
    { name: this.locale('locale_key.pages.datahub.sort.option.industry_name'), value: 'industryName', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.industry_name_desc'), value: 'industryNameDes' }
  ]

  groupedByOptionsForContact = [
    { name: this.locale('locale_key.pages.datahub.group.option.company'), value: 'company' }
  ]
  sortedByOptionsForContact = [
    { name: this.locale('locale_key.pages.datahub.sort.option.contact_name'), value: 'contactName' },
    { name: this.locale('locale_key.pages.datahub.sort.option.contact_name_desc'), value: 'contactNameDes' },
    { name: this.locale('locale_key.pages.datahub.sort.option.company_name'), value: 'companyName', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.company_name_desc'), value: 'companyNameDes' }
  ]

  filteredIndustries: string[] = []
  customMultipleFiltersForCompany: { name: string; value: string }[] = []
  filteredIndustry: string[] = []
  selectedContactType: string = null
  customMultipleFiltersForDO: { name: string; value: string }[] = []

  showFiltered = false
  filteredItems: {
    rg: RequestGroupFe[]
    entries: DirectEntryInfoFe[]
    files: FileDataFe[]
    feeds: AutomatedFeedInfoFe[]
    entities: EntityFe[]
  }

  constructor(
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public stateService: StateServiceFe,
    public datahubService: DatahubService,
    private displayService: DisplayServiceFe,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    super(languageService)

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    this.loadingInProgress = true
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomy = depTaxonomy
    this.subscriptions.add(
      this.datahubService.itemSelectedToFilterSubject.subscribe((item) => {
        if (item) {
          this.setFilteredData()
        }
      })
    )
    this.subscriptions.add(this.datahubService.filterChanged.subscribe((item) => this.renderNewData()))
    if (this.datahubService.selectedItemToFilter) {
      this.setFilteredData()
    }
    await this.renderNewData()
    this.loadingInProgress = false
  }

  async renderNewData() {
    this.resetCustomFiltersSearch()
    this.groupedBy = null
    this.sortedBy = null
    this.companies = await this.stateService.getPartners()
    this.contacts = await this.stateService.getContacts()
    this.clearAllFilters()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  setView(view: string) {
    this.datahubService.setView(view)
  }

  async setFilteredData() {
    this.filteringData = true
    this.noOfDOSubmissions = (await this.datahubService.getFilteredRequestGroups()).length
    this.noOfFiles = (await this.datahubService.getFilteredFiles()).length
    this.noOfMasterTable = (await this.datahubService.getFilteredEntites()).length
    this.noOFDirectEntries = (await this.datahubService.getFilteredDirectEntries()).length
    this.noOfAutomatedFeeds = (await this.datahubService.getFilteredAutomatedFeeds()).length
    this.filteringData = false
  }

  selectCompany(company: SupplierCompanyFe) {
    this.selectedCompany = company
    this.datahubService.selectItemToFilter(company)
  }

  applySearchFilterCompany(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeywordForCompany = keyword
    }
    this.filteredCompanies = this.companies.filter((info) =>
      info.supplierCompanyname.match(new RegExp(this.searchKeywordForCompany, 'i'))
    )
    this.group()
  }

  selectContact(dataOwner: ContactFe) {
    this.selectedContact = dataOwner
    this.datahubService.selectItemToFilter(dataOwner)
  }

  applySearchFilterContact(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeywordForContact = keyword
    }
    this.filteredContacts = this.contacts.filter((info) => {
      return info.getName().match(new RegExp(this.searchKeywordForContact, 'i'))
    })
    this.group()
  }

  taxonomyName(): string {
    return this.datahubService.getSelectdTaxonomyName(this.taxonomy)
  }

  getContactName(): string {
    let contact = this.datahubService.selectedItemToFilter
    if (contact instanceof ContactFe) {
      return contact.getName()
    }
  }

  group(option?) {
    if (option) {
      this.groupedBy = option
    }
    this.filteredGroupedCompanies = []
    this.filteredGroupedContacts = []
    if (this.groupedBy?.value == 'industries') {
      let industries = new Map<string, SupplierCompanyFe[]>()
      this.filteredCompanies.forEach((company) => {
        let industry = industries.get(company.supplierIndustry)
        if (industry) {
          industry.push(company)
        } else {
          industries.set(company.supplierIndustry, [company])
        }
      })
      industries.forEach((companies, name) => this.filteredGroupedCompanies.push({ name, companies }))
    } else if (this.groupedBy?.value == 'company') {
      let companyNames = new Map<string, string>()
      let companies = new Map<string, ContactFe[]>()
      this.filteredContacts.forEach((contact) => {
        let company = companies.get(contact.affiliationCompanyId)
        companyNames.set(contact.affiliationCompanyId, contact.getCompanyName(this.stateService.activeWorkspace))
        if (company) {
          company.push(contact)
        } else {
          companies.set(contact.affiliationCompanyId, [contact])
        }
      })
      companies.forEach((contacts, companyId) =>
        this.filteredGroupedContacts.push({ name: companyNames.get(companyId), contacts })
      )
    }
    if (this.sortedBy) {
      this.sort()
    }
  }

  sort(option?) {
    if (option) {
      this.sortedBy = option
    }
    let sortFun
    if (this.datahubService.isFilter('company')) {
      if (this.sortedBy.value == 'companyName') {
        sortFun = (a: SupplierCompanyFe, b: SupplierCompanyFe) => {
          if (a.supplierCompanyname.toLowerCase() < b.supplierCompanyname.toLowerCase()) {
            return -1
          } else if (a.supplierCompanyname.toLowerCase() > b.supplierCompanyname.toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'companyNameDes') {
        sortFun = (a: SupplierCompanyFe, b: SupplierCompanyFe) => {
          if (a.supplierCompanyname.toLowerCase() < b.supplierCompanyname.toLowerCase()) {
            return 1
          } else if (a.supplierCompanyname.toLowerCase() > b.supplierCompanyname.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'industryName') {
        sortFun = (a: SupplierCompanyFe, b: SupplierCompanyFe) => {
          if (a.supplierIndustry.toLowerCase() < b.supplierIndustry.toLowerCase()) {
            return -1
          } else if (a.supplierIndustry.toLowerCase() > b.supplierIndustry.toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'industryNameDes') {
        sortFun = (a: SupplierCompanyFe, b: SupplierCompanyFe) => {
          if (a.supplierIndustry.toLowerCase() < b.supplierIndustry.toLowerCase()) {
            return 1
          } else if (a.supplierIndustry.toLowerCase() > b.supplierIndustry.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      }
      if (sortFun) {
        if (this.groupedBy) {
          this.filteredGroupedCompanies.forEach((group) => (group.companies = group.companies.sort(sortFun)))
        } else {
          this.filteredCompanies = this.filteredCompanies.sort(sortFun)
        }
      }
    } else {
      if (this.sortedBy.value == 'companyName') {
        sortFun = (a: ContactFe, b: ContactFe) => {
          if (
            a.getCompanyName(this.stateService.activeWorkspace).toLowerCase() <
            b.getCompanyName(this.stateService.activeWorkspace).toLowerCase()
          ) {
            return -1
          } else if (
            a.getCompanyName(this.stateService.activeWorkspace).toLowerCase() >
            b.getCompanyName(this.stateService.activeWorkspace).toLowerCase()
          ) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'companyNameDes') {
        sortFun = (a: ContactFe, b: ContactFe) => {
          if (
            a.getCompanyName(this.stateService.activeWorkspace).toLowerCase() <
            b.getCompanyName(this.stateService.activeWorkspace).toLowerCase()
          ) {
            return 1
          } else if (
            a.getCompanyName(this.stateService.activeWorkspace).toLowerCase() >
            b.getCompanyName(this.stateService.activeWorkspace).toLowerCase()
          ) {
            return -1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'contactName') {
        sortFun = (a: ContactFe, b: ContactFe) => {
          if (a.getName().toLowerCase() < b.getName().toLowerCase()) {
            return -1
          } else if (a.getName().toLowerCase() > b.getName().toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'contactNameDes') {
        sortFun = (a: ContactFe, b: ContactFe) => {
          if (a.getName().toLowerCase() < b.getName().toLowerCase()) {
            return 1
          } else if (a.getName().toLowerCase() > b.getName().toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      }
      if (sortFun) {
        if (this.groupedBy) {
          this.filteredGroupedContacts.forEach((group) => (group.contacts = group.contacts.sort(sortFun)))
        } else {
          this.filteredContacts = this.filteredContacts.sort(sortFun)
        }
      }
    }
  }

  async applyFilters() {
    await this.applyShowOnlyFilter()
  }

  async applyShowOnlyFilter() {
    this.companies = await this.stateService.getPartners()
    this.contacts = await this.stateService.getContacts()
    if (this.selectedContactType == 'INTERNAL') {
      this.contacts = this.contacts.filter(
        (contact) => contact.affiliationCompanyId == this.stateService.activeWorkspace.companyId
      )
    } else if (this.selectedContactType == 'EXTERNAL') {
      this.contacts = this.contacts.filter(
        (contact) => contact.affiliationCompanyId != this.stateService.activeWorkspace.companyId
      )
    }
    this.applyCustomFilters()
  }

  clearAllFilters() {
    this.customMultipleFiltersForCompany = []
    this.customMultipleFiltersForDO = []
    this.applyFilters()
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | string, level?: number) {
    if (this.datahubService.isFilter('company')) {
      if ((event.target as HTMLInputElement).checked && !(value instanceof SupplierCompanyFe)) {
        this.customMultipleFiltersForCompany.push({ name: value, value })
      } else {
        this.customMultipleFiltersForCompany = this.customMultipleFiltersForCompany.filter((f) => f.value != value)
      }
    } else {
      if ((event.target as HTMLInputElement).checked && value instanceof SupplierCompanyFe) {
        this.customMultipleFiltersForCompany.push({ name: value.supplierCompanyname, value: value.supplierCompanyId })
      } else {
        this.customMultipleFiltersForCompany = this.customMultipleFiltersForCompany.filter((f) => f.value != value)
      }
    }
    this.applyFilters()
  }

  applyCustomFilters() {
    if (this.customMultipleFiltersForCompany.length > 0 && this.datahubService.isFilter('company')) {
      this.companies = this.companies.filter((company) => {
        for (let f of this.customMultipleFiltersForCompany) {
          if (company.supplierIndustry == f.value) {
            return true
          }
        }
        return false
      })
    } else if (this.customMultipleFiltersForCompany.length > 0 && this.datahubService.isFilter('dataReporter')) {
      this.contacts = this.contacts.filter((company) => {
        for (let f of this.customMultipleFiltersForCompany) {
          if (company.affiliationCompanyId == f.value) {
            return true
          }
        }
        return false
      })
    }
    this.applySearchFilterCompany()
    this.applySearchFilterContact()
  }

  isCustomFilterSelected(value: any) {
    if (this.datahubService.isFilter('company')) {
      return this.customMultipleFiltersForCompany.find((f) => f.value == value)
    } else {
      return this.customMultipleFiltersForCompany.find((f) => f.value == value)
    }
  }

  async filterCompany(keyword: string) {
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter((company) =>
      this.contacts.find((contact) => company.supplierCompanyId == contact.affiliationCompanyId)
    )
    let regex = new RegExp(keyword, 'i')
    this.filteredCompanies = relatedCompanies.filter((info) => info.supplierCompanyname.match(regex))
  }

  async filterIndustries(keyword: string) {
    let companies = await this.stateService.getPartners()
    let industries = companies.map((c) => c.supplierIndustry)
    let regex = new RegExp(keyword, 'i')
    this.filteredIndustries = industries.filter((industry) => industry.match(regex))
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = false))
    if (className == 'industryCheckbox') {
      this.filteredIndustries.forEach((industry) => {
        this.customMultipleFiltersForCompany = this.customMultipleFiltersForCompany.filter((f) => f.value != industry)
      })
    }
    this.applyFilters()
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = true))
    if (className == 'industryCheckbox') {
      this.filteredIndustries.forEach((industry) => {
        if (!this.customMultipleFiltersForCompany.find((f) => f.value == industry)) {
          this.customMultipleFiltersForCompany.push({ name: industry, value: industry })
        }
      })
    }
    this.applyFilters()
  }

  resetCustomFiltersSearch() {
    this.filterCompany('')
    this.filterIndustries('')
  }

  getRecentlyOpenedItems() {
    return this.stateService.activeWorkspace.recentlyOpenedItems
  }

  async searchDatahub(keyword: string) {
    let regex = new RegExp(keyword, 'i')
    let rgs = await this.stateService.getRequestGroups()
    let filteredRgs = rgs.filter((rg) => {
      if (rg.title.match(regex)) {
        return true
      }
      let requests = rg.requests.filter((req) => req.getAssigneeName().match(regex))
      return requests.length > 0
    })
    let files = await this.stateService.getAllFiles()
    let filteredFiles = files.filter((info) => {
      let word =
        info.label +
        (info.getUploaderName() ? info.getUploaderName() : '') +
        (info.uploaderCompany ? info.uploaderCompany : '')
      return word.match(regex)
    })
    let directEntries = await this.stateService.getStageTableInfos()
    let filteredEntries = directEntries.filter((info) => {
      if (info.taxonomyEntity) {
        return (
          info.taxonomyEntity.getLabel().match(regex) ||
          info.parentEntity?.getLabel().match(regex) ||
          info.rootParentEntity?.getLabel().match(regex)
        )
      }
      return false
    })
    let systems = await this.stateService.getAutomatedFeedSystems()
    let feeds = []
    systems.forEach((system) => feeds.push(...system.automatedFeeds))
    let filteredFeeds = feeds.filter((feed) => {
      if (feed.taxonomyEntity) {
        return (
          feed.taxonomyEntity.label.match(regex) ||
          feed.parentEntity.label.match(regex) ||
          feed.rootParentEntity.label.match(regex)
        )
      }
    })
    let allChildEntities = this.taxonomy.entities.filter((e) => e.key.split('.').length == 3)
    let filteredEntities = allChildEntities.filter((e) => e.getLabel().match(regex))
    this.showFiltered = true
    this.filteredItems = {
      rg: filteredRgs,
      entries: filteredEntries,
      files: filteredFiles,
      feeds: filteredFeeds,
      entities: filteredEntities
    }
  }

  mark() {
    this.showFiltered = false
  }

  getCustomFilterTextContact() {
    let text = ''
    if (this.selectedContactType == 'INTERNAL') {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.internal_contacts')}`
    } else if (this.selectedContactType == 'EXTERNAL') {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.external_contacts')}`
    }
    if (this.selectedContactType && this.customMultipleFiltersForDO.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `
    }
    text += this.customMultipleFiltersForDO.map((f) => f.name).join(', ')
    return text
  }

  getCustomFilterTextCompany() {
    let text = this.customMultipleFiltersForCompany.map((f) => f.name).join(', ')
    return text
  }
}
