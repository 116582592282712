import { Component, OnInit } from '@angular/core'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe'
import { DatahubService } from './DatahubService'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { InsightFe } from 'src/app/model/insight/InsightFe'

@Component({
  selector: 'datahub-details',
  templateUrl: './details.component.html'
})
export class DatahubDetailsComponent extends AbstractLanguageComponent implements OnInit {
  requestGroup: RequestGroupFe
  entity: EntityFe
  dataReporterDetails = true
  filteredInsights: InsightFe[] = []
  filteredDashboards: DashboardFe[] = []
  taxonomy: TaxonomyInfoFe

  constructor(
    public displayService: DisplayServiceFe,
    public stateService: StateServiceFe,
    languageService: LanguageService,
    public datahubService: DatahubService
  ) {
    super(languageService)
  }

  async ngOnInit() {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomy = depTaxonomy
    if (this.displayService.detailsData) {
      this.setData()
    }
    this.displayService.detailsDataUpdatedSubject.subscribe((isUpdated) => {
      if (isUpdated) {
        this.setData()
      }
    })
  }

  setData() {
    let rg = this.displayService.detailsData.requestGroup
    let entity = this.displayService.detailsData.entity
    if (rg) {
      this.requestGroup = rg
      this.setRgRelatedData()
    }
    if (entity) {
      this.entity = entity
      this.setEntityRelatedData()
    }
  }

  openDashboard(dashboard: DashboardFe) {
    this.stateService.selectDashboard(dashboard)
  }

  async setRgRelatedData() {
    let kpis = await this.stateService.getInsights()
    let taxonomyKeys: Set<string> = new Set()
    this.requestGroup.requests[0]?.questionnaire?.sections.forEach((section) => {
      if (section.taxonomyKey) {
        taxonomyKeys.add(section.taxonomyKey)
      }
    })
    this.filteredInsights = kpis.filter((insight) =>
      insight.definition.dataSeries.find((ds) => ds.dataPoints.find((dp) => dp.taxonomyKey == this.entity.key))
    )
    this.filteredDashboards = []
    let dashboards = await this.stateService.getDashboards()
    dashboards.filter((d) => {
      let isPresent = false
      for (let chart of d.charts) {
        if (this.filteredInsights.find((kpi) => kpi.id == chart.kpiId)) {
          isPresent = true
          break
        }
      }
      if (isPresent) {
        this.filteredDashboards.push(d)
      }
    })
  }

  async setEntityRelatedData() {
    let kpis = await this.stateService.getInsights()
    let requestGroups = await this.stateService.getRequestGroups()
    this.filteredInsights = kpis.filter((insight) =>
      insight.definition.dataSeries.find((ds) => ds.dataPoints.find((dp) => dp.taxonomyKey == this.entity.key))
    )
    this.filteredDashboards = []
    let dashboards = await this.stateService.getDashboards()
    dashboards.filter((d) => {
      let isPresent = false
      for (let chart of d.charts) {
        if (this.filteredInsights.find((kpi) => kpi.id == chart.kpiId)) {
          isPresent = true
          break
        }
      }
      if (isPresent) {
        this.filteredDashboards.push(d)
      }
    })
  }

  selectDashboard(dashboard: DashboardFe) {
    this.stateService.selectDashboard(dashboard)
  }

  selectKpi(kpi: InsightFe) {
    this.stateService.selectInsight(kpi)
  }
}
