import { QuestionFe } from './QuestionFe'
import { QUESTION_TYPE } from './QuestionTypeFe'
import { BooleanStatementFe } from './condition/BooleanStatementFe'
import { AnswerAttachment_AddFe } from '../../timeline/add/answer/AnswerAttachment_AddFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { AnswerAttachmentFe } from '../../timeline/answer/AnswerAttachmentFe'
import { BooleanStatementBuilderFe } from './condition/BooleanStatementBuilderFe'
import { DomSanitizer } from '@angular/platform-browser'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { FileTypeFe } from 'src/app/model/file/FileTypeFe'

export class QuestionAttachmentFe extends QuestionFe {
  newAnswer: AnswerAttachment_AddFe = new AnswerAttachment_AddFe(
    IdUtil.next(),
    this.sectionId,
    this.id,
    null,
    null,
    null,
    null
  )
  answer: AnswerAttachmentFe

  constructor(
    id: string,
    sectionId: string,
    ordinal: number | null,
    followUpCondition: BooleanStatementFe | null,
    question: string,
    desc: string | null,
    comments: boolean,
    required: boolean,
    csrdDatapointId: string | undefined,
    referencedParagraph: string | undefined,
    referenceApplicationRequirement: string | undefined,
    referencedParagraphInformation: string | undefined,
    applicationRequirements: string[] | undefined,
    supplementaryParagraph: string | undefined,
    supplementary: string | undefined,
    applicationRequirementFootnotes: string | undefined,
    drFootNote: string | undefined
  ) {
    super(
      id,
      sectionId,
      QUESTION_TYPE.ATTACHMENT,
      ordinal,
      followUpCondition,
      question,
      desc,
      comments,
      required,
      null,
      csrdDatapointId,
      referencedParagraph,
      referenceApplicationRequirement,
      referencedParagraphInformation,
      applicationRequirements,
      supplementaryParagraph,
      supplementary,
      applicationRequirementFootnotes,
      drFootNote
    )
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionAttachmentFe {
    transfer.followUpCondition = transfer.followUpCondition
      ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition)
      : null
    let item = this.setQuestion(transfer, sectionId)
    return item
  }

  public static setQuestion(transfer: any, sectionId?): QuestionAttachmentFe {
    let item = new QuestionAttachmentFe(
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.csrdDatapointId,
      transfer.referencedParagraph,
      transfer.referenceApplicationRequirement,
      transfer.referencedParagraphInformation,
      transfer.applicationRequirements,
      transfer.supplementaryParagraph,
      transfer.supplementary,
      transfer.applicationRequirementFootnotes,
      transfer.drFootNote
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }
}
