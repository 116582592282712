<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between my-2" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class="ps-0 my-auto mt-0">{{ locale('locale_key.pages.network.title') }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h cursor fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button cursor"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="loadingData">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex h-100 flex-row me-2 w-100" *ngIf="!loadingData">
          <div class="card w-100 pt-1 me-2" style="overflow: auto !important; max-height: 85vh">
            <div id="accordionWrap1" role="tablist" aria-multiselectable="true" class="px-2 pt-3 fixed-width">
              <div class="d-flex flex-row justify-content-between my-3 ps-0">
                <div class="form-group me-2">
                  <div class="input-group border border-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0" id="searchIcon">
                        <i class="fs-4 la la-search la-flip-horizontal"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control border-0"
                      placeholder="{{ locale('locale_key.general.search') }}"
                      [(ngModel)]="searchKeyword"
                      (input)="filterCompanies()"
                      style="height: 2rem"
                      aria-label="Search"
                      aria-describedby="searchIcon clearIcon"
                    />
                  </div>
                </div>
                <button
                  class="btn border border-secondary border-2 p-0"
                  [hidden]="isAllExpanded()"
                  (click)="expandAll()"
                  style="width: 150px; height: 32px"
                >
                  <i class="fs-4 la la-edit"></i>
                  <a class="my-auto me-2 cursor dark-text" style="font-size: 1rem !important">
                    {{ locale('locale_key.pages.kpi_library.button.expand_all') }}
                  </a>
                </button>
                <button
                  class="btn border border-secondary border-2 p-0"
                  [hidden]="isAllCollapsed()"
                  (click)="collapseAll()"
                  style="width: 150px; height: 32px"
                >
                  <a class="my-auto cursor" style="font-size: 1rem !important">
                    {{ locale('locale_key.pages.kpi_library.button.collapse_all') }}
                  </a>
                </button>
              </div>
              <ng-container *ngFor="let object of filteredData; let i = index">
                <!-- EXISTING PARTNER-->
                <div
                  class="accordion accordion-icon-rotate mb-2 cursor-arrow"
                  [ngClass]="{ collapsed: isCollapsed[i] }"
                  (click)="setSelectedCompany(object)"
                  *ngIf="!isAInvitation(object)"
                >
                  <div class="p-0 m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center">
                    <a
                      class="col-xl-6 col-lg-6 col-md-6 col-sm-5 col-6 d-flex flex-row my-auto ps-3"
                      (click)="toggleCollapse(i)"
                    >
                      <div class="d-flex flex-row justify-content-start align-content-center my-auto">
                        <h6 class="my-auto" *ngIf="object == currentCompany">
                          <i
                            class="la la-building me-2"
                            style="color: var(--main-contrast-color)"
                            tooltip="{{ currentCompany.name | titlecase }}"
                          ></i>
                          {{ locale('locale_key.pages.network.own_company.title') }}
                        </h6>
                        <h6 class="my-auto" *ngIf="object != currentCompany">
                          <i class="la la-building me-2" tooltip="{{ object.supplierCompanyname | titlecase }}"></i>
                          {{ object.supplierCompanyname }}
                        </h6>
                      </div>
                    </a>
                    <!-- Section 2 -->
                    <div
                      class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 d-none d-sm-block justify-content-end my-auto"
                    >
                      <div class="d-flex flex-row justify-content-end h-100 align-content-center">
                        <ng-container *ngFor="let option of userOptions; let j = index">
                          <div>
                            <div class="d-flex flex-row justify-content-center align-content-center my-auto">
                              <div
                                class="icon-circle me-2 my-auto"
                                [ngClass]="{
                                  'circle-pink': object.selectedOption == option.value && isCollapsed[i] == false
                                }"
                              >
                                <span class="text-white">
                                  {{
                                    option.value === 'DEACTIVATED_USERS'
                                      ? object.deactivatedDOs?.length
                                      : option.value === 'ACTIVE_USERS'
                                        ? object.dataOwners?.length
                                        : option.value === 'INVITATIONS'
                                          ? object.doInvitations.length
                                          : ''
                                  }}
                                </span>
                              </div>
                              <a
                                class="dark-text me-3"
                                (click)="
                                  (option.value === 'DEACTIVATED_USERS' && object.deactivatedDOs?.length == 0) ||
                                  (option.value === 'ACTIVE_USERS' && object.dataOwners?.length == 0) ||
                                  (option.value === 'INVITATIONS' && object.doInvitations?.length == 0)
                                    ? (isCollapsed[i] = true)
                                    : (isCollapsed[i] = false);
                                  object.selectedOption = option.value
                                "
                                [ngClass]="{
                                  deactivated: !(
                                    (option.value === 'DEACTIVATED_USERS' && object.deactivatedDOs?.length > 0) ||
                                    (option.value === 'ACTIVE_USERS' && object.dataOwners?.length > 0) ||
                                    (option.value === 'INVITATIONS' && object.doInvitations?.length > 0)
                                  ),
                                  'active-link': object.selectedOption == option.value && isCollapsed[i] == false
                                }"
                              >
                                {{ option.label }}
                              </a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <!-- Accordion Toggle -->
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-6 d-flex justify-content-end float-end pe-3">
                      <a
                        class="p-0 my-auto ms-auto collapsed float-end"
                        (click)="toggleCollapse(i)"
                        style="max-height: 75px"
                      >
                        <i
                          class="la la-angle-down float-end"
                          [ngClass]="{ activeAccordion: !isCollapsed[i] }"
                          style="color: black"
                        ></i>
                      </a>
                      <div class="btn-group">
                        <div
                          class="description-btn d-flex justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="la la-ellipsis-h cursor fs-2 my-auto" style="rotate: 90deg"></i>
                        </div>
                        <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                          <button class="dropdown-item ms-0 ps-1" type="button" (click)="updateDetailsData(object)">
                            <i class="la la-info me-1"></i>
                            {{ locale('locale_key.general.buttons.show_details') }}
                          </button>
                          <div class="dropdown-divider"></div>
                          <ng-container *ngFor="let option of userOptions; let j = index">
                            <div>
                              <div class="d-flex flex-row justify-content-start align-content-center my-auto">
                                <a
                                  class="dark-text me-3 ms-1"
                                  [ngClass]="{
                                    deactivated: !(
                                      (option.value === 'DEACTIVATED_USERS' && object.deactivatedDOs?.length > 0) ||
                                      (option.value === 'ACTIVE_USERS' && object.dataOwners?.length > 0) ||
                                      (option.value === 'INVITATIONS' && object.doInvitations?.length > 0)
                                    ),
                                    'active-link': object.selectedOption == option.value && isCollapsed[i] == false
                                  }"
                                  (click)="
                                    (option.value === 'DEACTIVATED_USERS' && object.deactivatedDOs?.length == 0) ||
                                    (option.value === 'ACTIVE_USERS' && object.dataOwners?.length == 0) ||
                                    (option.value === 'INVITATIONS' && object.doInvitations?.length == 0)
                                      ? (isCollapsed[i] = true)
                                      : (isCollapsed[i] = false);
                                    object.selectedOption = option.value
                                  "
                                >
                                  {{ option.label }}
                                </a>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div [collapse]="isCollapsed[i]" id="acc{{ i }}" class="bg-lightgray border">
                    <div
                      class="card-content"
                      *ngIf="object.selectedOption == 'ACTIVE_USERS' && object.dataOwners?.length > 0"
                    >
                      <div class="card-body px-0">
                        <div class="row py-3 px-2 suppliers-filter btn-add text-white my-1 mx-0 mt-2">
                          <div class="col-xl-3 col-lg-3 col-md-4 col-6 ms-0 ps-0">
                            <h5 class="text-white cursor-arrow fs-6">{{ locale('locale_key.general.name') }}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{ locale('locale_key.pages.network.emai_column') }}
                            </h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-2 d-none d-md-block">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{
                                locale('locale_key.pages.clients.clients_table.managers_sub_table.status_column.title')
                              }}
                            </h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2 d-none d-lg-block">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{ locale('locale_key.pages.network.business_partners_table.on_going_requests_column') }}
                            </h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2 d-none d-lg-block">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{ locale('locale_key.pages.network.business_partners_table.shared_dashboards_column') }}
                            </h5>
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 d-none d-lg-block">
                            <h5 class="text-white text-end text-truncate fs-6">
                              {{ locale('locale_key.general.table.actions_column') }}
                            </h5>
                          </div>
                        </div>
                        <div
                          class="row py-3 px-1 m-1 bg-text-white"
                          [ngClass]="{ 'border-b': !last }"
                          *ngFor="let owner of object.dataOwners; let userIdx = index; let last = last"
                          (click)="setSelectedUser({ user: owner, idx: userIdx })"
                        >
                          <div class="col-xl-3 col-lg-3 col-md-4 col-6 ps-0 my-auto">
                            <div class="row m-0 p-0 ms-0 flex-nowrap my-auto cursor-arrow">
                              <div class="circle-icon me-1 my-auto" *ngIf="!screenSize.isSmallerSize()">
                                <i class="las la-user fs-4 text-white"></i>
                              </div>
                              <p class="text-truncate my-auto cursor-arrow">
                                {{ owner.getName() | titlecase }}
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-5 my-auto">
                            <p class="my-auto text-truncate cursor-arrow">{{ owner.affiliationEmail }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-2 my-auto d-none d-md-block">
                            <p class="badge badge-success my-auto cursor-arrow" style="width: 100px; padding: 5px">
                              {{ locale('locale_key.general.state.connected') }}
                            </p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2 d-none d-lg-block my-auto">
                            <p class="my-auto text-truncate cursor-arrow">
                              {{
                                owner.noOfOngoingRequests() == 1
                                  ? owner.noOfOngoingRequests() +
                                    ' ' +
                                    locale('locale_key.pages.network.active_requests_table.request_column')
                                  : owner.noOfOngoingRequests() +
                                    ' ' +
                                    locale('locale_key.pages.network.active_requests_table.request_column_plural')
                              }}
                            </p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2 d-none d-lg-block my-auto">
                            <p class="my-auto text-truncate cursor-arrow">
                              {{
                                owner.sharedDashboards.length == 1
                                  ? owner.sharedDashboards.length +
                                    ' ' +
                                    locale(
                                      'locale_key.pages.network.business_partners_table.shared_dashboards_column_singular'
                                    )
                                  : owner.sharedDashboards.length +
                                    ' ' +
                                    locale(
                                      'locale_key.pages.network.business_partners_table.shared_dashboards_column_plural'
                                    )
                              }}
                            </p>
                          </div>
                          <div class="col-xl-1 col-lg-1 col-1 ps-0 text-end">
                            <div class="btn-group ms-auto">
                              <div
                                class="description-btn d-flex justify-content-end"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="la la-ellipsis-h cursor fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button
                                  class="dropdown-item ms-0 ps-1"
                                  type="button"
                                  (click)="updateDetailsData(owner)"
                                >
                                  <i class="la la-info me-1"></i>
                                  {{ locale('locale_key.general.buttons.show_details') }}
                                </button>
                                <div class="dropdown-divider"></div>
                                <button
                                  class="dropdown-item ms-0 ps-1"
                                  (click)="openModal(deactivateAffModal, 'modal-md')"
                                >
                                  <i class="la la-user me-1" style="color: var(--danger-color)"></i>
                                  {{
                                    locale(
                                      'locale_key.pages.clients.clients_table.users_sub_table.button.deactivate_user'
                                    )
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-content"
                      *ngIf="object.selectedOption == 'INVITATIONS' && object.doInvitations?.length > 0"
                    >
                      <div class="card-body px-0">
                        <div class="row py-3 px-2 suppliers-filter btn-add text-white my-1 mx-0 mt-2">
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ms-0 ps-0">
                            <h5 class="text-white cursor-arrow fs-6">{{ locale('locale_key.general.name') }}</h5>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                            <h5 class="text-white cursor-arrow fs-6">{{ locale('locale_key.general.email') }}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-2 col-md-4 d-none d-md-block">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{
                                locale(
                                  'locale_key.pages.clients.clients_table.data_reporters_sub_table.status_column.title'
                                )
                              }}
                            </h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-2 d-none d-lg-block">
                            <h5 class="text-white cursor-arrow fs-6">
                              {{ locale('locale_key.general.user_information.invited_on') }}
                            </h5>
                          </div>
                          <div
                            class="col-xl-2 col-lg-2 col-1 d-none d-lg-block ps-0"
                            *ngIf="!screenSize.isSmallerSize()"
                          >
                            <h5 class="text-white text-end text-truncate cursor-arrow fs-6">
                              {{ locale('locale_key.general.table.actions_column') }}
                            </h5>
                          </div>
                        </div>
                        <div
                          class="row py-3 px-1 m-1 bg-text-white"
                          [ngClass]="{ 'border-b': !last }"
                          *ngFor="let invitation of object.doInvitations; let i = index; let last = last"
                          (click)="setSelectedInvitation(invitation, i)"
                        >
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 my-auto ps-0">
                            <div class="row m-0 p-0 ms-0 flex-nowrap my-auto cursor-arrow">
                              <div class="circle-icon me-1 my-auto" *ngIf="!screenSize.isSmallerSize()">
                                <i class="las la-user fs-4 text-white"></i>
                              </div>
                              <p
                                class="text-truncate my-auto cursor-arrow"
                                tooltip="{{ invitation.getName() | titlecase }}"
                              >
                                {{ invitation.getName() | titlecase }}
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5">
                            <p class="my-auto text-truncate cursor-arrow">{{ invitation.contactEmail }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 d-none d-md-block my-auto">
                            <span
                              class="badge text-white cursor-arrow"
                              [ngClass]="invitation.invitationStatus == 'ACTIVE' ? 'badge-turquoise' : 'badge-red'"
                            >
                              {{
                                invitation.getStatus() == 'Invited'
                                  ? locale('locale_key.general.state.invited')
                                  : locale('locale_key.general.state.cancelled')
                              }}
                            </span>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-2 d-none d-lg-block">
                            <p class="my-auto cursor-arrow">
                              {{ invitation.invitationDate | date: 'dd MMM, yyyy (HH:mm)' }}
                            </p>
                          </div>
                          <div class="col-lg-2 col-lg-2 col-1 ps-0 text-end">
                            <div class="btn-group ms-auto">
                              <div
                                class="description-btn d-flex justify-content-end"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="la la-ellipsis-h cursor fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button
                                  class="dropdown-item ms-0 ps-1"
                                  [hidden]="invitation.invitationStatus != 'ACTIVE'"
                                  (click)="openModal(confirmContactCancellation, 'modal-md')"
                                >
                                  {{
                                    locale(
                                      'locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation'
                                    )
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-content"
                      *ngIf="object.selectedOption == 'DEACTIVATED_USERS' && object.deactivatedDOs.length > 0"
                    >
                      <div class="card-body px-0">
                        <div class="row p-3 suppliers-filter btn-add text-white my-1 mx-0 mt-2">
                          <div class="col-xl-3 col-lg-3 col-md-col-6 col-6 ms-0 ps-0">
                            <h5 class="text-white cursor-arrow">{{ locale('locale_key.general.name') }}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-6 col-6">
                            <h5 class="text-white text-truncate cursor-arrow">
                              {{ locale('locale_key.pages.network.business_partners_table.status_column') }}
                            </h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-2 d-none d-lg-block">
                            <h5 class="text-white cursor-arrow">
                              {{ locale('locale_key.pages.network.business_partners_table.joined_on_column') }}
                            </h5>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-6 d-none d-lg-block">
                            <h5 class="text-white cursor-arrow">
                              {{
                                locale(
                                  'locale_key.pages.clients.clients_table.data_reporters_sub_table.deactivated_on_column.title'
                                )
                              }}
                            </h5>
                          </div>
                          <div
                            class="ol-lg-2 col-lg-2 col-1 ps-0 text-end d-none d-lg-block"
                            *ngIf="!screenSize.isSmallerSize()"
                          >
                            <h5 class="text-white float-end text-truncate cursor-arrow">
                              {{ locale('locale_key.general.table.actions_column') }}
                            </h5>
                          </div>
                        </div>
                        <div
                          class="row py-2 px-1 m-1 bg-text-white"
                          [ngClass]="{ 'border-b': !last }"
                          *ngFor="let user of object.deactivatedDOs; let userIdx = index; let last = last"
                          (click)="setSelectedUser({ user: user, idx: userIdx })"
                        >
                          <div class="col-xl-3 col-lg-3 col-md-6 col-6 my-auto">
                            <div class="row m-0 p-0 ms-0 flex-nowrap my-auto cursor-arrow">
                              <div class="circle-icon me-1 my-auto" *ngIf="!screenSize.isSmallerSize()">
                                <i class="las la-user fs-4 text-white"></i>
                              </div>
                              <p class="text-truncate my-auto cursor-arrow" tooltip="{{ user.getName() | titlecase }}">
                                {{ user.getName() | titlecase }}
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-5 col-5 my-auto">
                            <p class="badge badge-gray my-auto cursor-arrow" style="width: 100px; padding: 5px">
                              {{ locale('locale_key.general.state.deactivated') }}
                            </p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-2 d-none d-lg-block my-auto">
                            <p class="my-auto cursor-arrow">{{ user.getActivationDate() }}</p>
                          </div>

                          <div class="col-xl-3 col-lg-3 col-6 d-none d-lg-block my-auto">
                            <p class="my-auto cursor-arrow">{{ user.getDeactivationDate() }}</p>
                          </div>
                          <div class="col-lg-2 col-lg-2 col-1 text-end">
                            <div class="btn-group ms-auto text-end">
                              <div
                                class="description-btn d-flex justify-content-end px-2 ms-auto text-end"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  class="la la-ellipsis-h cursor fs-2 ellipsis-mgr my-auto text-end"
                                  style="rotate: 90deg"
                                ></i>
                              </div>
                              <div class="dropdown-menu">
                                <button
                                  class="dropdown-item"
                                  *ngIf="user.invitationStatus != 'ACTIVE'"
                                  (click)="inviteAgain(user)"
                                >
                                  {{
                                    locale(
                                      'locale_key.pages.clients.clients_table.data_deactivated_users_table.button.invite_again'
                                    )
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- PARTNER INVITATION-->
                <div
                  class="accordion accordion-icon-rotate mb-2 cursor-arrow"
                  [ngClass]="{ collapsed: isCollapsed[i] }"
                  (click)="setSelectedInvitation(object, i)"
                  *ngIf="isAInvitation(object)"
                >
                  <div
                    class="p-0 collapsed m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center"
                  >
                    <a
                      class="col-xl-6 col-lg-6 col-md-6 col-sm-5 col-6 d-flex flex-row my-auto ps-3"
                      (click)="toggleCollapse(i)"
                    >
                      <div class="d-flex flex-row justify-content-start align-content-center my-auto">
                        <h6 class="my-auto cursor-arrow">
                          <i class="la la-building me-2" tooltip="{{ object.partnerName | titlecase }}"></i>
                          {{ object.partnerName }}
                        </h6>
                      </div>
                    </a>
                    <!-- Section 2 -->
                    <a class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 d-none d-sm-block justify-content-end my-auto">
                      <div class="d-flex flex-row justify-content-end h-100 align-content-center">
                        <span
                          class="badge text-white cursor-arrow"
                          [ngClass]="object.invitationStatus == 'ACTIVE' ? 'badge-turquoise' : 'badge-red'"
                        >
                          {{
                            object.getStatus() == 'Invited'
                              ? locale('locale_key.general.state.invited')
                              : locale('locale_key.general.state.cancelled')
                          }}
                        </span>
                      </div>
                    </a>
                    <!-- Accordion Toggle -->
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-5 d-flex justify-content-end float-end pe-3">
                      <a
                        class="p-0 my-auto ms-auto collapsed float-end"
                        (click)="toggleCollapse(i)"
                        style="max-height: 75px"
                      >
                        <i
                          class="la la-angle-down float-end"
                          [ngClass]="{ activeAccordion: !isCollapsed[i] }"
                          style="color: black"
                        ></i>
                      </a>
                      <div class="btn-group">
                        <div
                          class="description-btn d-flex justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="la la-ellipsis-h cursor fs-2 my-auto" style="rotate: 90deg"></i>
                        </div>
                        <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                          <button
                            class="dropdown-item ms-0 ps-1"
                            *ngIf="object.invitationStatus == 'ACTIVE'"
                            (click)="openModal(confirmPartnerCancellation, 'modal-md')"
                          >
                            {{
                              locale('locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation')
                            }}
                          </button>
                          <button
                            class="dropdown-item ms-0 ps-1"
                            *ngIf="object.invitationStatus != 'ACTIVE'"
                            (click)="inviteAgain(object)"
                          >
                            {{
                              locale(
                                'locale_key.pages.clients.clients_table.data_deactivated_users_table.button.invite_again'
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div [collapse]="isCollapsed[i]" id="acc{{ i }}" class="bg-lightgray border">
                    <div class="card-content">
                      <div class="card-body px-0">
                        <div class="row py-3 px-4 suppliers-filter btn-add text-white my-1 mx-0 mt-2">
                          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 ms-0 ps-0">
                            <h5 class="text-white cursor-arrow">{{ locale('locale_key.general.name') }}</h5>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                            <h5 class="text-white cursor-arrow">
                              {{
                                locale(
                                  'locale_key.pages.clients.clients_table.data_reporters_sub_table.status_column.title'
                                )
                              }}
                            </h5>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-3 col-3 d-none d-md-block">
                            <h5 class="text-white cursor-arrow">
                              {{ locale('locale_key.general.user_information.invited_on') }}
                            </h5>
                          </div>
                          <div
                            class="col-xl-2 col-lg-2 col-md-2 col-1 d-none d-lg-block ps-0"
                            *ngIf="!screenSize.isSmallerSize()"
                          >
                            <h5 class="text-white float-end text-truncate cursor-arrow">
                              {{ locale('locale_key.general.table.actions_column') }}
                            </h5>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-text-white">
                          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 my-auto ps-0">
                            <div class="row m-0 p-0 ms-0 flex-nowrap my-auto cursor-arrow">
                              <div class="circle-icon me-1 my-auto" *ngIf="!screenSize.isSmallerSize()">
                                <i class="las la-user fs-4 text-white"></i>
                              </div>
                              <p
                                class="text-truncate my-auto cursor-arrow"
                                tooltip="{{
                                  object.primaryContactFirstName + ' ' + (object.primaryContactLastName | titlecase)
                                }}"
                              >
                                {{ object.primaryContactFirstName + ' ' + object.primaryContactLastName | titlecase }}
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-5 my-auto">
                            <span
                              class="badge text-white cursor-arrow"
                              [ngClass]="object.invitationStatus == 'ACTIVE' ? 'badge-turquoise' : 'badge-red'"
                            >
                              {{
                                object.getStatus() == 'Invited'
                                  ? locale('locale_key.general.state.invited')
                                  : locale('locale_key.general.state.cancelled')
                              }}
                            </span>
                          </div>
                          <div class="col-xl-3 col-lg-3 col-3 d-none d-md-block">
                            <p class="my-auto cursor-arrow">
                              {{ object.invitationDate | date: 'dd MMM, yyyy (HH:mm)' }}
                            </p>
                          </div>
                          <div class="col-lg-2 col-lg-2 col-1 ps-0 text-end">
                            <div class="btn-group ms-auto">
                              <div
                                class="description-btn d-flex justify-content-end"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="la la-ellipsis-h cursor fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button
                                  class="dropdown-item ms-0 ps-1"
                                  [hidden]="object.invitationStatus != 'ACTIVE'"
                                  (click)="openModal(confirmPartnerCancellation, 'modal-md')"
                                >
                                  {{
                                    locale(
                                      'locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation'
                                    )
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #invitePartnerModal>
  <data-supplier-company-entry [selectedInvitation]="selectedInvitation"></data-supplier-company-entry>
</ng-template>

<ng-template #confirmContactCancellation>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      <i
        class="la la-exclamation-circle"
        style="color: var(--warning-color); font-size: 1.5rem; position: relative; top: 2px"
      ></i>
      {{ locale('locale_key.pages.data_reporters.cancel_invitation_confirmation_modal.title') }}
    </h4>
    <button type="button" class="btn close" *ngIf="!isLoading" (click)="closeModal()">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{
        locale('locale_key.pages.data_reporters.cancel_invitation_confirmation_modal.description', {
          object: selectedCompany.doInvitations[this.selectedIndex].getName()
        })
      }}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!isLoading">
      <button
        type="button"
        class="btn secondary-button custom-button"
        data-dismiss="modal"
        *ngIf="!isLoading"
        (click)="closeModal()"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="cancelInvitation()" data-dismiss="modal">
        {{ locale('locale_key.general.buttons.yes') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmPartnerCancellation>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      <i
        class="la la-exclamation-circle"
        style="color: var(--warning-color); font-size: 1.5rem; position: relative; top: 2px"
      ></i>
      {{ locale('locale_key.pages.network.cancel_invitation_confirmation_modal.title') }}
    </h4>
    <button type="button" class="btn close" *ngIf="!isLoading" (click)="closeModal()">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{
        locale('locale_key.pages.network.cancel_invitation_confirmation_modal.description', {
          partner_name: selectedInvitation['partnerName']
        })
      }}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!isLoading">
      <button
        type="button"
        class="btn secondary-button custom-button"
        data-dismiss="modal"
        *ngIf="!isLoading"
        (click)="closeModal()"
      >
        {{ locale('locale_key.pages.network.cancel_invitation_confirmation_modal.button.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="cancelInvitation()" data-dismiss="modal">
        {{ locale('locale_key.pages.network.cancel_invitation_confirmation_modal.button.yes') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<ng-template #inviteReporterModal>
  <data-supplier-personnel-entry [selectedContact]="selectedUser"></data-supplier-personnel-entry>
</ng-template>

<!-- Deactivating user Modal -->
<ng-template #deactivateAffModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 mt-2 my-auto text-truncate" id="myModalLabel12">
      <i class="la la-exclamation-circle mt-2 color-red" style="font-size: 2rem; color: red"></i>
      {{ locale('locale_key.pages.clients.deactivating_modal.title', { user_name: selectedUser.getName() }) }}
    </h4>
    <button type="button" class="btn close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body mb-1 text-justify">
    <h5 *ngIf="selectedUser && selectedCompany">
      {{
        locale('locale_key.pages.clients.deactivating_modal.description', {
          user_name: selectedUser.getName(),
          company_name: selectedCompany.supplierCompanyname ? selectedCompany.supplierCompanyname : selectedCompany.name
        })
      }}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.pages.clients.deactivating_modal.button.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="deactivateContact()">
        {{ locale('locale_key.pages.clients.deactivating_modal.button.deactivate') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>
