export class PartnerInvitation_CheckExistingUserCompaniesFe {
  invitationUuid: string
  existedPrimaryContactUserEmail: string | undefined
  googleUserCreds: any

  constructor(invitationUuid: string, existedPrimaryContactUserEmail: string | undefined, googleUserCreds: any) {
    this.invitationUuid = invitationUuid
    this.existedPrimaryContactUserEmail = existedPrimaryContactUserEmail
    this.googleUserCreds = googleUserCreds
  }
}
