import { Component, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { MasterTableDataGridIntergationService } from '../../model/taxonomy/MasterTableDataGridIntergationService'
import { Subscription } from 'rxjs'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { RowFe } from 'src/app/model/schema/RowFe'
import { DatahubService } from './DatahubService'
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'

@Component({
  selector: 'master-table',
  templateUrl: './masterTable.component.html',
  styleUrls: ['./data-hub.component.scss']
})
export class MasterTableComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('emissionModal') efModal: TemplateRef<any>
  @ViewChild('traceData', { static: true }) traceData: TemplateRef<any>
  loadingInProgress = false
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  entity: EntityFe
  sourceDataGridService: MasterTableDataGridIntergationService

  subscriptions = new Subscription()

  constructor(
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private stateService: StateServiceFe,
    public datahubService: DatahubService,
    private displayService: DisplayServiceFe,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    super(languageService)

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    this.entity = this.datahubService.selectedEntity
    if (!(this.entity.dataGridService && this.entity.dataGridService.currentPageData)) {
      this.loadingInProgress = true
      this.entity.dataGridService = new MasterTableDataGridIntergationService(
        this.entity,
        this.stateService,
        this.languageService
      )
      this.entity.dataGridService.mode = DataGridTableMode.SELECT_TABLE_ROW
      this.entity.dataGridService.hasTablePagination = true
      this.entity.dataGridService.setSelectedRowId(this.entity.selectedRowId)
      await this.entity.dataGridService.loadMainTablePage()
      this.loadingInProgress = false
    }
    if (this.entity.selectedRowId) {
      this.entity.selectedRowId = null
    }
    this.subscriptions.add(this.entity.dataGridService.traceSource.subscribe((row) => this.traceSource(row)))
    this.subscriptions.add(this.entity.dataGridService.openEfModal.subscribe((open) => this.openModal(this.efModal)))
    this.subscriptions.add(
      this.entity.dataGridService.showEfDetailsPanel.subscribe((ef) => this.displayService.openEfDetailsTab(ef))
    )
    this.subscriptions.add(
      this.datahubService.exportMasterTable.subscribe(
        async (download) => await this.entity.dataGridService.downloadWholeTable(this.datahubService.exportFileType)
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  traceSource(row: RowFe) {
    this.openModal(this.traceData)
    this.sourceDataGridService = new MasterTableDataGridIntergationService(
      this.entity,
      this.stateService,
      this.languageService
    )
    this.sourceDataGridService.mode = DataGridTableMode.SELECT_TABLE_ROW
    this.sourceDataGridService.currentPageData = TableDataFe.fromTransfer(
      this.entity.dataGridService.backendTableData,
      this.entity,
      this.languageService.getDisplayActiveLanguage()
    )
    this.sourceDataGridService.currentPageData.rows = [row]
    this.sourceDataGridService.currentPageData.rowCount = 1
    this.sourceDataGridService.currentPageData.key = this.entity.key
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = 'modal-xl') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: className
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(): void {
    this.modalService.hide()
    document.body.classList.remove('modal-open')
  }

  updateDetailsData() {
    let detailsData = { entity: this.entity }
    this.displayService.updateDetailsData(detailsData)
  }

  async setEmissionFactor(ef: AbstractEmissionFactorFe) {
    this.loadingInProgress = true
    await this.entity.dataGridService.setEmissionFactor(ef)
    this.closeModal()
    this.loadingInProgress = false
  }

  getMgmtCompanyId(): string {
    return this.stateService.activeWorkspace.companyId
  }
}
