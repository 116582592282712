import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { GlobalEntityFe } from './GlobalEntityFe'

export class GlobalDataTaxonomyFe {
  public entities: GlobalEntityFe[] = []

  constructor(jsonObj, languageService: LanguageService) {
    this.entities = jsonObj.map((e) => new GlobalEntityFe(e, languageService))
  }

  parentEntity(childKey) {
    let parentItem = null
    let components = childKey.split('.')
    if (components.length > 0) {
      let parentKey = childKey.substring(0, childKey.lastIndexOf('.'))
      parentItem = this.entities.find((t) => t.key == parentKey)
    }
    return parentItem
  }

  children(parentKey: string = null): GlobalEntityFe[] {
    let children
    if (!parentKey) {
      children = this.entities.filter((item) => item.key.split('.').length == 1)
    } else if (parentKey) {
      let level = parentKey.split('.').length
      children = this.entities.filter(
        (item) => item.key.startsWith(parentKey) && item.key.split('.').length == level + 1
      )
    }

    return children
  }

  selfAndInheritedAttributes(entity) {
    var attrs = []
    let parent = this.parentEntity(entity.key)
    if (parent) {
      let pattrs = this.selfAndInheritedAttributes(parent)
      attrs.push(...pattrs)
    }

    if (entity.attributes) {
      attrs.push(...entity.attributes)
    }
    return attrs
  }
}
