import { QUESTION_TYPE } from '../../request/questionnaire/QuestionTypeFe'
import { AnswerAttachmentFe } from './AnswerAttachmentFe'
import { AnswerDataTableFe } from './AnswerDataTableFe'
import { AnswerDateFe } from './AnswerDateFe'
import { AnswerEmissionFactorFe } from './AnswerEmissionFactorFe'
import { AnswerFe } from './AnswerFe'
import { AnswerMultipleChoiceFe } from './AnswerMultipleChoiceFe'
import { AnswerNumberFe } from './AnswerNumberFe'
import { AnswerSingleChoiceFe } from './AnswerSingleChoiceFe'
import { AnswerTextFe } from './AnswerTextFe'

export class AnswerTypeConversionFe {
  public static fromTransfer(transfer: any): AnswerFe {
    let activityFe: AnswerFe
    switch (transfer.questionType) {
      case QUESTION_TYPE.DATA_TABLE:
        return AnswerDataTableFe.fromTransfer(transfer)
      case QUESTION_TYPE.DATE:
        return AnswerDateFe.fromTransfer(transfer)
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        return AnswerMultipleChoiceFe.fromTransfer(transfer)
      case QUESTION_TYPE.SINGLE_CHOICE:
        return AnswerSingleChoiceFe.fromTransfer(transfer)
      case QUESTION_TYPE.NUMBER:
        return AnswerNumberFe.fromTransfer(transfer)
      case QUESTION_TYPE.TEXT:
        return AnswerTextFe.fromTransfer(transfer)
      case QUESTION_TYPE.ATTACHMENT:
        return AnswerAttachmentFe.fromTransfer(transfer)
      case QUESTION_TYPE.EMISSION_FACTOR:
        return AnswerEmissionFactorFe.fromTransfer(transfer)
    }
    return activityFe
  }
}
