<div class="m-0 p-0">
  <div class="details">
    <div class="modal-body p-2 mb-2" style="height: 70vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="content clearfix">
        <div style="overflow-x: hidden">
          <div class="row mb-2" *ngIf="categoryService.newEntities.length > 0">
            <div class="accordion" id="entitesAccordion">
              <h2 class="accordion-header" id="entityHeading">
                <div
                  class="accordion-button bg-light-gray"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#entityCollapse"
                  aria-expanded="true"
                  aria-controls="entityCollapse"
                >
                  <i class="las la-table fs-3 pe-2"></i>
                  {{ locale('locale_key.connected_actions.data_categories_to_be_added') }}
                </div>
              </h2>
              <div
                id="entityCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="entityHeading"
                data-bs-parent="#entitesAccordion"
              >
                <span class="badge badge-pink w-auto py-auto m-2" *ngFor="let entity of categoryService.newEntities">
                  {{ entity.getTaxonomyTitle(taxonomy) }}
                </span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="categoryService.dataPointsLength() > 0">
            <div class="accordion" id="dataPointAcc">
              <h2 class="accordion-header" id="dpHeading">
                <div
                  class="accordion-button bg-light-gray"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#dpCollapse"
                  aria-expanded="true"
                  aria-controls="dpCollapse"
                >
                  <i class="las la-table fs-3 pe-2"></i>
                  {{ locale('locale_key.connected_actions.data_points_to_be_added') }}
                </div>
              </h2>
              <div
                id="dpCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="dpHeading"
                data-bs-parent="#dataPointAcc"
              >
                <ng-container *ngFor="let entity of categoryService.getNewDataPointEntites()">
                  <span class="badge badge-info w-auto py-auto m-2 mb-0">
                    {{ entity.getTaxonomyTitle(taxonomy) }}
                  </span>
                  <ng-container *ngFor="let dp of categoryService.getNewDataPoints(entity); let last = last">
                    <span class="badge badge-pink w-auto py-auto mx-2">
                      {{ dp.getLabel() }}
                    </span>
                    <ng-container *ngIf="!last">/</ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
