import { ChangeDetectorRef, Component, OnInit, EventEmitter, Input, Output, TemplateRef, Inject } from '@angular/core'
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms'
import { RouterFe } from 'src/app/route/RouterFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { DateUtil } from 'src/app/utils/DateUtil'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DirectEntryInfoFe } from 'src/app/model/directEntry/DirectEntryInfoFe'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { DOCUMENT } from '@angular/common'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'

@Component({
  selector: 'insert-data-entry',
  templateUrl: './data.entry.component.html',
  styleUrls: ['./data.entry.component.scss']
})
export class DataEntryInsertComponent extends AbstractLanguageComponent implements OnInit {
  @Input() mode: string = 'create' //create, edit, copy
  @Input() stageTableInfo: DirectEntryInfoFe
  @Output() closeModalEvent = new EventEmitter<boolean>()

  isLoadingData = true
  inProgress = false
  taxonomyInfo: TaxonomyInfoFe = undefined
  form: FormGroup
  tableEntities = []
  screenSize = ScreenWidthSizeFe.WIDTH_LARGE
  dataCategoryMessage = this.locale('locale_key.general.validation_message.data_category_required')
  dataStartDateMessage = this.locale('locale_key.general.validation_message.start_date_required')
  dataEndDateMessage = this.locale('locale_key.general.validation_message.end_date_required')

  constructor(
    private readonly FormBuilder: FormBuilder,
    private _cd: ChangeDetectorRef,
    private loginService: LoginServiceFe,
    private backendService: RouterFe,
    private stateService: StateServiceFe,
    languageService: LanguageService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.isLoadingData = true
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {
    this.form = this.FormBuilder.group({
      taxonomyKey: [null, Validators.required],
      timeFrom: [null, Validators.required],
      timeTo: [null, Validators.required],
      sourceFile: [null]
    })

    this.loadCompanyData()
  }

  viewInfo() {
    if (this.mode == 'copy' && this.stageTableInfo) {
      this.form.patchValue({
        taxonomyKey: this.stageTableInfo.taxonomyKey,
        sourceFile: this.stageTableInfo.sourceFile
      })
    }
    if (this.mode == 'edit' && this.stageTableInfo) {
      this.form.patchValue({
        taxonomyKey: this.stageTableInfo.taxonomyKey,
        timeFrom: this.stageTableInfo.timeFrom ? DateUtil.toString(this.stageTableInfo.timeFrom) : undefined,
        timeTo: this.stageTableInfo.timeTo ? DateUtil.toString(this.stageTableInfo.timeTo) : undefined,
        sourceFile: this.stageTableInfo.sourceFile
      })
      this.form.get('timeFrom')?.disable()
      this.form.get('timeTo')?.disable()
    }
    this.form.markAsPristine()
  }

  async saveChages() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    this.inProgress = true
    let formVal = this.form.value
    let destStageTableInfo: DirectEntryInfoFe = new DirectEntryInfoFe()

    if (this.mode == 'create') {
      destStageTableInfo.id = IdUtil.next()
    } else if (this.mode == 'edit') {
      destStageTableInfo.id = this.stageTableInfo.id
    } else if (this.mode == 'copy') {
      destStageTableInfo.id = IdUtil.next()
    }

    destStageTableInfo.taxonomyKey = formVal.taxonomyKey
    destStageTableInfo.timeFrom = formVal.timeFrom
    destStageTableInfo.timeTo = formVal.timeTo
    destStageTableInfo.sourceFile = formVal.sourceFile
    destStageTableInfo.creatorAffId = this.stateService.activeWorkspace.affiliationId
    destStageTableInfo.creatorFirstName = this.loginService.loginUser.firstName
    destStageTableInfo.creatorLastName = this.loginService.loginUser.lastName

    if (this.mode == 'create') {
      await this.stateService.initStageTableInfo(destStageTableInfo)
    } else if (this.mode == 'edit') {
      await this.stateService.updateStageTableInfo(destStageTableInfo)
    } else if (this.mode == 'copy') {
      await this.stateService.copyStageTableInfo(this.stageTableInfo, destStageTableInfo)
    }
    this.closeModalEvent.emit(true)
    this.inProgress = false
  }

  delete() {
    this.stateService.deleteStageTableInfo(this.stageTableInfo)
    this.closeModalEvent.emit(true)
  }

  cancel() {
    this.closeModalEvent.emit(true)
    this.modalRef.hide()
    document.body.classList.remove('modal-open')
  }

  async loadCompanyData() {
    this.isLoadingData = true
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
    this.tableEntities = this.taxonomyInfo.entities.filter((t) => t.table == true)
    this.viewInfo()
    this.isLoadingData = false
  }

  timeFromChanged() {
    this.form.patchValue({
      timeTo: undefined
    })
  }

  openModal(templateRef: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(templateRef, { class: size })
  }
}
