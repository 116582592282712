<div class="loader-wrapper mt-5" *ngIf="loadingInProgress">
  <div class="loader-container">
    <div class="ball-grid-pulse loader-primary">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<div class="card px-2 pt-3 fixed-width" *ngIf="!loadingInProgress">
  <div class="row">
    <div class="col-8 ps-3">
      <p>{{ locale('locale_key.pages.data_hub.master_table.data_categories.table_info') }}</p>
    </div>
    <div class="col-4 pe-3 ps-1 text-xl-end text-start">
      <a class="btn tertiary-button me-2" (click)="updateDetailsData()">
        {{ locale('locale_key.pages.data_hub.master_table.show_table_info') }}
      </a>
    </div>
  </div>
  <div class="mt-3 scroll" style="max-height: 65vh">
    <datagrid-table-editor [dataGrid]="entity.dataGridService"></datagrid-table-editor>
  </div>
</div>

<ng-template #traceData>
  <app-trace-data [masterTableDataGridServices]="[sourceDataGridService]"></app-trace-data>
</ng-template>

<ng-template #emissionModal>
  <emission-factor-detail
    [mgmtCompanyId]="getMgmtCompanyId()"
    (closeEvent)="closeModal()"
    (emissionFactorSelected)="setEmissionFactor($event)"
  ></emission-factor-detail>
</ng-template>
