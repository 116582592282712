import { AbstractSignInMethodFe } from './AbstractSignInMethodFe'
import { SignInMethodProviderFe } from './SignInMethodProviderFe'

export class AffGoogleSignInMethodFe extends AbstractSignInMethodFe {
  constructor(isEnabled: boolean) {
    super(SignInMethodProviderFe.GOOGLE, isEnabled)
  }

  public static fromTransfer(transfer: any): AffGoogleSignInMethodFe {
    let singInMethod = new AffGoogleSignInMethodFe(transfer.isEnabled)
    return singInMethod
  }
}
