export class ValidationRegex {
  public static DatapointLabelEnglish = /^[A-Za-z_][A-Za-z0-9_\[\]()/, ]*$/
  public static DateRegex = /^\d{4}-\d{2}-\d{2}$/
  public static EmailRegex = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[_a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/
  public static WebisteRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  public static PasswordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}/
  public static NewPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_\W])(?!.*\s).{8,}$/
  public static NonWhitespaceRegExp = /\S*/
  public static NumberRegExp = /^\s*-?\d+(\.\d+)?\s*$/
}
