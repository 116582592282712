import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import _ from 'lodash'
import { UnitFe } from '../../unit-systems/model/UnitFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { groupUnitsByMeasurementTypeAndSystem } from '../../unit-systems/model/utils'
import { UnitsByMeasurementType } from '../../unit-systems/model/UnitsByMeasurementType'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { Language } from 'src/app/utils/language/Language'
import { Subscription } from 'rxjs'
import {
  UnitMeasurementTypes,
  getUnitMeasurementTypeDefaultUnitSymbol,
  getUnitMeasurementTypeName
} from '../../unit-systems/model/UnitMeasurementType'
import { DatapointDatatype } from 'src/app/model/taxonomy/DatapointDatatypeFe'
import { DatapointProvidedBy } from 'src/app/model/taxonomy/DatapointProvidedByFe'
import { CustomEmissionFactorFe } from 'src/app/model/emissions/CustomEmissionFactorFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { TaxonomyEmissionFactorFe } from 'src/app/model/taxonomy/TaxonomyEmissionFactorFe'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'

enum ModalNames {
  addDatapointModal = 'addDatapointModal'
}

@Component({
  selector: 'add-datapoint-dialog',
  templateUrl: './add-datapoint-dialog.component.html',
  styleUrls: ['./add-datapoint-dialog.component.scss']
})
export class AddDatapointDialogComponent extends AbstractLanguageComponent implements OnInit {
  @Input() selectedDataCategory

  @ViewChild(`${ModalNames.addDatapointModal}`, { static: true })
  addDatapointModal: TemplateRef<any>

  modals
  descriptionEnglish
  descriptionActiveLanguage
  shortnameEnglish
  shortnameActiveLanguage
  datatypeInput = ''
  providedByInput = 'user'
  conversionFactorMeasurementTypeInput = ''
  sourceMeasurementTypeInput = ''
  unitInput = { selectedUnit: {} }
  hasError = {
    description: false,
    descriptionActiveLang: false,
    shortname: false,
    shortnameActiveLang: false,
    datatype: false,
    unit: false,
    emissionFactor: false,
    conversionFactorMeasurementType: false,
    sourceMeasurementType: false
  }

  errorMsg = {
    description: '',
    descriptionActiveLang: '',
    shortname: '',
    shortnameActiveLang: '',
    datatype: '',
    unit: '',
    emissionFactor: '',
    conversionFactorMeasurementType: '',
    sourceMeasurementType: ''
  }

  units: UnitFe[] = []
  unitsByMeasurementType: UnitsByMeasurementType[] = []
  customUnits: UnitFe[] = []
  pageLanguage: Language
  private displayPageLanguageSubject: Subscription
  hasCalculationErrors: boolean = false
  showCalculationErrors: boolean = false
  fakeSelectedDatapoint: TaxonomyAttributeFe
  measurementTypesWithData = []
  measurementTypes = []
  filteredMeasurementTypes = []
  emissionFactorInput: AbstractEmissionFactorFe

  constructor(
    private modalService: BsModalService,
    public languageService: LanguageService,
    private stateService: StateServiceFe
  ) {
    super(languageService)
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
  }

  async ngOnInit() {
    // when changing display language
    this.displayPageLanguageSubject = this.languageService.pageLanguageSubject.subscribe((language) => {
      this.pageLanguage = language
    })

    this.pageLanguage = this.languageService.activeLanguage

    this.setupModals()
    await this.loadUnits()
    this.fakeSelectedDatapoint = new TaxonomyAttributeFe({}, this.languageService)

    //Get measurment type keys from units that are allowed to display
    const units = await this.stateService.getUnits()
    const measurementTypesWithDataKeys = new Set()
    units
      .filter((unit) => unit.shouldDisplay)
      .forEach((unit) => {
        measurementTypesWithDataKeys.add(unit.measurementType)
      })

    this.measurementTypesWithData = Array.from(measurementTypesWithDataKeys)

    Object.keys(UnitMeasurementTypes).forEach((key) => {
      const measurementType = {
        key,
        name: getUnitMeasurementTypeName(UnitMeasurementTypes[key]),
        defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[key])
      }
      this.measurementTypes.push(measurementType)
    })

    let measurementTypes = _.cloneDeep(this.measurementTypes)
    //filter out measurement type
    const exludeMeausurementTypes = [UnitMeasurementTypes.NO_UNIT, UnitMeasurementTypes.BINARY]
    measurementTypes = measurementTypes.filter(
      (measurementType) => !exludeMeausurementTypes.includes(measurementType.key)
    )
    this.filteredMeasurementTypes = measurementTypes
  }

  ngOnDestroy() {
    if (this.displayPageLanguageSubject) {
      this.displayPageLanguageSubject.unsubscribe()
    }
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  private setupModals() {
    this.modals = {
      [`${ModalNames.addDatapointModal}`]: {
        template: this.addDatapointModal,
        class: `modal-md ${ModalNames.addDatapointModal}`
      }
    }
  }

  resolveLabel(label: any): string {
    return label[this.activeLanguage.code] || label['en'] || label
  }

  private openModal(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal
    const customClass = options.class ?? this.modals[modal].class
    const template = this.modals[modal].template
    const ignoreBackdropClick = options.ignoreBackdropClick ?? true
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    })
  }

  private closeModal({ modal }) {
    const modalRef = this.modals[modal].ref
    this.modalService.hide(modalRef.id)
  }

  cancelAddDatapoint() {
    this.closeModal({
      modal: ModalNames.addDatapointModal
    })
  }

  checkInputs() {
    let hasErrors = false

    this.resetErrorObject()

    const langCode = this.pageLanguage.code

    // check inputs empty
    // check for English datapoint
    if (_.isEmpty(this.descriptionEnglish)) {
      hasErrors = true
      this.hasError['description'] = true
      this.errorMsg['description'] = this.locale('locale_key.add_data_point.description.is_required')
    }

    if (langCode !== 'en' && _.isEmpty(this.descriptionActiveLanguage)) {
      hasErrors = true
      this.hasError['descriptionActiveLang'] = true
      this.errorMsg['descriptionActiveLang'] = this.locale('locale_key.add_data_point.description.is_required')
    }

    // check for English datapoint
    if (_.isEmpty(this.shortnameEnglish)) {
      hasErrors = true
      this.hasError['shortname'] = true
      this.errorMsg['shortname'] = this.locale('locale_key.add_data_point.shortname.is_required')
    } else if (this.isDuplicateDatapoint(this.shortnameEnglish, 'en')) {
      hasErrors = true
      this.hasError['shortname'] = true
      this.errorMsg['shortname'] = this.locale('locale_key.pages.data_category.label.duplicate_name.message')
    } else if (!this.isValidNameRegex(this.shortnameEnglish)) {
      hasErrors = true
      this.hasError['shortname'] = true
      this.errorMsg['shortname'] = this.locale('locale_key.pages.data_category.data_point.validation.message')
    }

    // check other language datapoint
    if (langCode !== 'en') {
      if (_.isEmpty(this.shortnameActiveLanguage)) {
        hasErrors = true
        this.hasError['shortnameActiveLang'] = true
        this.errorMsg['shortnameActiveLang'] = this.locale('locale_key.add_data_point.shortname.is_required')
      } else if (this.isDuplicateDatapoint(this.shortnameActiveLanguage, langCode)) {
        hasErrors = true
        this.hasError['shortnameActiveLang'] = true
        this.errorMsg['shortnameActiveLang'] = this.locale(
          'locale_key.pages.data_category.label.duplicate_name.message'
        )
      }
    }

    if (_.isEmpty(this.datatypeInput)) {
      hasErrors = true
      this.hasError['datatype'] = true
      this.errorMsg['datatype'] = this.locale('locale_key.add_data_point.datatype.is_required')
    }

    if (this.datatypeInput == 'NUMERIC' && _.isEmpty(this.unitInput.selectedUnit)) {
      hasErrors = true
      this.hasError['unit'] = true
      this.errorMsg['unit'] = this.locale('locale_key.add_data_point.unit.is_required')
    }

    if (this.datatypeInput == DatapointDatatype.EMISSION_FACTOR) {
      if (this.providedByInput == DatapointProvidedBy.user) {
        ;[
          { key: 'sourceMeasurementType', msg: 'locale_key.pages.emission.source_measurement_type_is_required' },
          {
            key: 'conversionFactorMeasurementType',
            msg: 'locale_key.pages.emission.conversion_factor_measurement_type_is_required'
          }
        ].forEach((check) => {
          const input = `${check.key}Input`
          if (_.isEmpty(this[input])) {
            hasErrors = true
            this.hasError[check.key] = true
            this.errorMsg[check.key] = this.locale(check.msg)
          }
        })
      }
      if (this.providedByInput == DatapointProvidedBy.pre_determined) {
        ;[{ key: 'emissionFactor', msg: 'locale_key.pages.emission.emission_factor_is_required' }].forEach((check) => {
          const input = `${check.key}Input`
          if (_.isEmpty(this[input])) {
            hasErrors = true
            this.hasError[check.key] = true
            this.errorMsg[check.key] = this.locale(check.msg)
          }
        })
      }
    }

    return hasErrors
  }

  confirmAddDatapoint() {
    const hasErrors = this.checkInputs()
    if (hasErrors) {
      return
    }

    let description = {}
    let label = {}

    const langCode = this.pageLanguage.code

    if (langCode !== 'en') {
      description[langCode] = this.descriptionActiveLanguage?.trim()
      label[langCode] = this.shortnameActiveLanguage?.trim()
    }

    description['en'] = this.descriptionEnglish?.trim()
    label['en'] = this.shortnameEnglish?.trim()

    const datatype = this.datatypeInput
    const unit = this.unitInput.selectedUnit
    const providedBy = this.providedByInput

    const attr = {
      taxonomyKey: this.selectedDataCategory.key,
      key: null,
      label,
      datatype,
      description,
      unit,
      providedBy
    }

    const taxonomyAttr = new TaxonomyAttributeFe(attr, this.languageService)
    taxonomyAttr.ordinal = this.selectedDataCategory.level_3.columns.length
    taxonomyAttr.isNew = true

    if (datatype == DatapointDatatype.EMISSION_FACTOR) {
      if (this.providedByInput == DatapointProvidedBy.user) {
        // create example emission factor
        const sourceMeasurementType = _.find(this.measurementTypes, { key: this.sourceMeasurementTypeInput })
        const sourceUnit = _.find(this.units, { symbol: sourceMeasurementType.defaultUnitSymbol })

        const conversionMeasurementType = _.find(this.measurementTypes, {
          key: this.conversionFactorMeasurementTypeInput
        })
        const conversionUnit = _.find(this.units, { symbol: conversionMeasurementType.defaultUnitSymbol })

        const emissionFactor = new CustomEmissionFactorFe(
          IdUtil.next(),
          '',
          sourceUnit?.name ?? '',
          sourceUnit?.symbol ?? '',
          1,
          conversionUnit?.symbol ?? '',
          'SYSTEM_EXAMPLE',
          {}
        )
        const ef: TaxonomyEmissionFactorFe = {
          isFallback: false,
          value: emissionFactor
        }
        taxonomyAttr.emissionFactors[0] = ef
      }

      if (this.providedByInput == DatapointProvidedBy.pre_determined) {
        taxonomyAttr.emissionFactors[0] = { ...this.fakeSelectedDatapoint.emissionFactors[0] }
        this.fakeSelectedDatapoint.emissionFactors = []
      }
    }

    this.selectedDataCategory.level_3.columns.push(taxonomyAttr)
    this.selectedDataCategory.level_3.adjustKey(taxonomyAttr)

    this.closeModal({
      modal: ModalNames.addDatapointModal
    })
  }

  clearPreviousInput() {
    this.descriptionEnglish = ''
    this.descriptionActiveLanguage = ''
    this.shortnameEnglish = ''
    this.shortnameActiveLanguage = ''
    this.datatypeInput = ''
    this.emissionFactorInput = null
    this.conversionFactorMeasurementTypeInput = ''
    this.sourceMeasurementTypeInput = ''
    this.providedByInput = 'user'

    this.resetErrorObject()
  }

  resetErrorObject() {
    this.hasError = {
      description: false,
      descriptionActiveLang: false,
      shortname: false,
      shortnameActiveLang: false,
      datatype: false,
      unit: false,
      emissionFactor: false,
      conversionFactorMeasurementType: false,
      sourceMeasurementType: false
    }

    this.errorMsg = {
      description: '',
      descriptionActiveLang: '',
      shortname: '',
      shortnameActiveLang: '',
      datatype: '',
      unit: '',
      emissionFactor: '',
      conversionFactorMeasurementType: '',
      sourceMeasurementType: ''
    }
  }

  open() {
    this.clearPreviousInput()
    this.openModal({
      modal: ModalNames.addDatapointModal,
      ignoreBackdropClick: false
    })
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  isLanguageEnglish() {
    return this.pageLanguage.code === 'en'
  }

  getCategoryLanguageFlag() {
    const icon = this.pageLanguage.icon
    return `fs-4 fi ${icon}`
  }

  isDuplicateDatapoint(name: string, langCode: string): boolean {
    return (
      this.selectedDataCategory &&
      this.selectedDataCategory.level_3.columns.filter((col) => col.label[langCode] === name).length >= 1
    )
  }

  isValidNameRegex(name: string) {
    return name.match(ValidationRegex.DatapointLabelEnglish)
  }

  changeDatapointProvidedBy(providedBy) {
    this.providedByInput = providedBy
  }

  updateHasCalculationErrors(value: boolean) {
    this.hasCalculationErrors = value
    if (!this.hasCalculationErrors && this.showCalculationErrors) {
      this.showCalculationErrors = false
    }
  }

  emissionFactorChanged({ emissionFactor }) {
    const ef: TaxonomyEmissionFactorFe = {
      isFallback: false,
      value: emissionFactor
    }
    this.fakeSelectedDatapoint.emissionFactors[0] = ef
    this.emissionFactorInput = emissionFactor
  }

  getMeasurementTypes() {
    let finalMeasurementTypes = this.filteredMeasurementTypes
    // filter out measurement types without default unit symbol
    finalMeasurementTypes = finalMeasurementTypes.filter(
      (measurementType) => !_.isEmpty(measurementType.defaultUnitSymbol)
    )
    // map unit name
    finalMeasurementTypes = finalMeasurementTypes.map((measurementType) => {
      const unit = _.find(this.units, { symbol: measurementType.defaultUnitSymbol })
      if (unit) {
        measurementType.defaultUnitName = unit.name
      }
      return measurementType
    })
    return finalMeasurementTypes
  }

  getMeasureTypeName(measurementType) {
    return getUnitMeasurementTypeName(measurementType)
  }
}
