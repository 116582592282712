import { ElementRef, OnInit, QueryList, TemplateRef } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ChartBuilderServiceFeNew } from 'src/app/services/ChartBuilderServiceFeNew'
import { RouterFe } from 'src/app/route/RouterFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { Chart } from 'chart.js'
import { Subscription } from 'rxjs'
import { jsPDF } from 'jspdf'
import * as XLSX from 'xlsx'
import { tap } from 'rxjs'
import { saveAs } from 'file-saver'
import { base64ToFile } from 'ngx-image-cropper'
import { NgxCaptureService } from 'ngx-capture'
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew'
import { ChartSettingFeNew } from 'src/app/model/chart/ChartSettingFeNew'
import { ChartTypeFe } from 'src/app/model/chart/ChartTypeFe'

export abstract class AbstractDashboardComponent {
  public chartWrappers: ChartWrapperFeNew[] = []
  public isCollapsed: boolean[] = []
  public canvases: any
  public firstTimeDraw = true
  public modalRef: BsModalRef
  private canvasSubscription: Subscription

  constructor(
    protected backendService: RouterFe,
    protected chartBuilderService: ChartBuilderServiceFeNew,
    protected stateService: StateServiceFe,
    protected modalService: BsModalService,
    protected captureService: NgxCaptureService
  ) {}

  async init(): Promise<void> {
    Chart.defaults.color = '#405369'
    Chart.defaults.font.family = "'Open Sans', sans-serif"
    Chart.defaults.font.weight = '400'
    await this.loadCompanyData()
  }

  onChangedSetting(index): void {
    if (this.chartWrappers[index].setting.chartType == ChartTypeFe.TABLE) {
      this.chartWrappers[index].insight.kpiDataGridService.setTable(this.chartWrappers[index].setting)
    } else {
      let hiddenDatasetLabels = this.getHiddenDatasetLabels(index)
      this.chartWrappers[index].chart.destroy()
      let kpiData = this.chartWrappers[index].insight
      let setting: ChartSettingFeNew = this.chartWrappers[index].setting
      var chartData = this.chartBuilderService.buildChart(kpiData, setting)
      chartData.options.plugins.title.display = false
      var chart = new Chart(this.canvases._results[index].nativeElement.getContext('2d'), chartData)
      this.chartWrappers[index].chart = chart
      this.hideDatasets(index, hiddenDatasetLabels)
    }
  }

  getHiddenDatasetLabels(index) {
    let hiddenDatasetLabels = []
    let chart = this.chartWrappers[index].chart
    chart.data.datasets.forEach((d, i) => {
      if (chart.getDatasetMeta(i).hidden) {
        hiddenDatasetLabels.push(d.label)
      }
    })
    return hiddenDatasetLabels
  }

  hideDatasets(index, datasetLabels: string[]) {
    let chart = this.chartWrappers[index].chart
    chart.data.datasets.forEach((d, i) => {
      if (datasetLabels.includes(d.label)) {
        chart.getDatasetMeta(i).hidden = true
        chart.update()
      }
    })
  }

  refreshView(htmlCanvases: QueryList<ElementRef>) {
    if (this.canvasSubscription) {
      this.canvasSubscription.unsubscribe()
      this.firstTimeDraw = true
    }
    this.canvasSubscription = htmlCanvases.changes.subscribe((canvases) => {
      if (this.firstTimeDraw) {
        this.firstTimeDraw = false
        this.canvases = canvases
        this.drawAllCharts()
      }
    })
  }

  drawAllCharts() {
    this.canvases.forEach((canvas, indx) => {
      this.drawChart(canvas, indx)
    })
  }

  drawChart(canvas, index) {
    let wr = this.chartWrappers[index]
    if (!wr.hasError && wr.setting.chartType != ChartTypeFe.TABLE) {
      let chartData = this.chartBuilderService.buildChart(wr.insight, wr.setting)
      if (wr.chart) {
        wr.chart.destroy()
      }
      chartData.options.plugins.title.display = false
      let chart = new Chart(canvas.nativeElement.getContext('2d'), chartData)
      Chart.register({
        id: 'custom-plugin-before-draw',
        beforeDraw: function (chart, args, options) {
          var ctx = chart.ctx
          var chartArea = chart.chartArea
          ctx.save()
          ctx.fillStyle = 'white'
          ctx.fillRect(
            chartArea.left - 200,
            chartArea.top - 300,
            chartArea.right - chartArea.left + 200,
            chartArea.bottom - chartArea.top + 500
          )
          ctx.restore()
        }
      })
      wr.chart = chart
    }
  }

  downloadImage(index) {
    let el = document.getElementById(`item-${index}`)
    this.captureService
      .getImage(el, true)
      .pipe(
        tap((img) => {
          let file = base64ToFile(img as string)
          const formData = new FormData()
          formData.append('file', file, 'image.png')
          var title =
            this.chartWrappers[index].setting.titleOption == 'custom'
              ? this.chartWrappers[index].setting.title
              : this.chartWrappers[index].insight.title
          saveAs(file, title)
        })
      )
      .subscribe()
  }

  downloadSheet(index, exportType?: string) {
    var title =
      this.chartWrappers[index].setting.titleOption == 'custom'
        ? this.chartWrappers[index].setting.title
        : this.chartWrappers[index].insight.title
    this.chartWrappers[index].insight.kpiDataGridService.setTable(this.chartWrappers[index].setting)
    this.chartWrappers[index].insight.kpiDataGridService.downloadCurrentPage(exportType, title)
  }

  downloadPDF(index) {
    let el = document.getElementById(`item-${index}`)
    this.captureService
      .getImage(el, true)
      .pipe(
        tap((img) => {
          var pdf = new jsPDF()
          const a4Size = { height: 300, width: 210 }
          let leftMargin = 10,
            topMargin = 20,
            rightMargin = 10
          let height = this.pixelTOmm(el.clientHeight)
          let width = this.pixelTOmm(el.clientWidth)
          let ratio = height / width

          let maxHeight = a4Size.height - topMargin
          let maxWidth = a4Size.width - leftMargin - rightMargin
          if (height > maxHeight) {
            height = maxHeight
            width = height * (1 / ratio)
          }
          if (width > maxWidth) {
            width = maxWidth
            height = width * ratio
          }
          pdf.addImage(img, leftMargin, topMargin, width, height)
          var title =
            this.chartWrappers[index].setting.titleOption == 'custom'
              ? this.chartWrappers[index].setting.title
              : this.chartWrappers[index].insight.title
          pdf.save(`${title}.pdf`)
        })
      )
      .subscribe()
  }

  pixelTOmm(quant: number): number {
    return quant * 0.2645833333
  }

  public openModal(toolbar: TemplateRef<any>) {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: 'slideInRight1'
    }
    this.modalRef = this.modalService.show(toolbar, config)
  }

  protected abstract loadCompanyData(): void
}
