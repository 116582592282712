import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractActivityFe } from './AbstractActivityFe'
import { AcceptSubmissionActivityFe } from './AcceptSubmissionActivityFe'
import { ActivityTypeFe } from './ActivityTypeFe'
import { CloseRequestActivityFe } from './CloseRequestActivityFe'
import { CreateRequestActivityFe } from './CreateRequestActivityFe'
import { RejectSubmissionActivityFe } from './RejectSubmissionActivityFe'
import { SubmitAnswersActivityFe } from './SubmitAnswersActivityFe'
import { SubmitMessageActivityFe } from './SubmitMessageActivityFe'
import { ConnectSectionsActivityFe } from './ConnectSectionsActivityFe'
import { DisconnectSectionsActivityFe } from './DisconnectSectionsActivityFe'

export class ActivityTypeConversionFe {
  public static fromTransfer(transfer: any, languageService: LanguageService): AbstractActivityFe {
    let activityFe: AbstractActivityFe
    if (transfer.type == ActivityTypeFe.ACCEPT_SUBMISSION) {
      activityFe = AcceptSubmissionActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.CLOSE_REQUEST) {
      activityFe = CloseRequestActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.CREATE_REQUEST) {
      activityFe = CreateRequestActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.REJECT_SUBMISSION) {
      activityFe = RejectSubmissionActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.SUBMIT_ANSWERS) {
      activityFe = SubmitAnswersActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.SUBMIT_MESSAGE) {
      activityFe = SubmitMessageActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.CONNECT_SECTIONS) {
      activityFe = ConnectSectionsActivityFe.fromTransfer(transfer, languageService)
    } else if (transfer.type == ActivityTypeFe.DISCONNECT_SECTIONS) {
      activityFe = DisconnectSectionsActivityFe.fromTransfer(transfer, languageService)
    }
    return activityFe
  }
}
