import { DateUtil } from 'src/app/utils/DateUtil'
import { EntityFe } from '../taxonomy/EntityFe'
import { TaxonomyInfoFe } from '../taxonomy/TaxonomyInfoFe'
import { TableDataFe } from '../schema/TableDataFe'
import { AutomatedFeedDataGridIntergationService } from 'src/app/components/data-hub/AutomatedFeedDataGridIntergationService'

export class AutomatedFeedInfoFe {
  timeFromString: string
  timeToString: string
  lastModifiedString: string
  lastFetchedOnString: string
  rootParentEntity: EntityFe
  parentEntity: EntityFe
  taxonomyEntity: EntityFe
  tableData: TableDataFe = null
  updateInfoInProgress = false
  connectionInProgress = false
  saveInProgress = false
  loadInProgress = false
  dataGridService: AutomatedFeedDataGridIntergationService

  public constructor(
    public id: string,
    public systemName: string,
    public taxonomyKey: string,
    public timeFrom: Date,
    public timeTo: Date,
    public modified: boolean,
    public transformed: boolean,
    public lastModified: Date,
    public lastFetchedOn: Date,
    public logoStorageId: string | null
  ) {
    this.timeFrom = new Date(timeFrom)
    this.timeTo = new Date(timeTo)
    this.lastModified = new Date(lastModified)
    this.lastFetchedOn = new Date(lastFetchedOn)
  }

  public static fromTransfer(transfer: any, taxonomyInfo: TaxonomyInfoFe): AutomatedFeedInfoFe {
    let info = new AutomatedFeedInfoFe(
      transfer.id,
      transfer.systemName,
      transfer.taxonomyKey,
      transfer.timeFrom,
      transfer.timeTo,
      transfer.modified,
      transfer.transformed,
      transfer.lastModified,
      transfer.lastFetchedOn,
      transfer.logoStorageId
    )
    info.timeFromString = info.timeFrom ? DateUtil.toString3(info.timeFrom) : '-'
    info.timeToString = info.timeTo ? DateUtil.toString3(info.timeTo) : '-'
    info.lastModifiedString = info.lastModified ? DateUtil.toString3(info.lastModified) : '-'
    info.lastFetchedOnString = info.lastFetchedOn ? DateUtil.toString3(info.lastFetchedOn) : '-'
    info.rootParentEntity = taxonomyInfo.rootParentEntity(info.taxonomyKey)
    info.parentEntity = taxonomyInfo.parentEntity(info.taxonomyKey)
    info.taxonomyEntity = taxonomyInfo.entityByKey(info.taxonomyKey)
    return info
  }

  public hasContent(): boolean {
    return this.tableData ? true : false
  }

  public taxonomyName(): string {
    if (this.taxonomyEntity) {
      return `${this.rootParentEntity?.getLabel()} > ${this.parentEntity?.getLabel()} > ${this.taxonomyEntity.getLabel()}`
    } else {
      return this.taxonomyKey
    }
  }

  public statusIcon(): string {
    return this.transformed ? 'la-link' : 'la-unlink'
  }

  public statusLocaleKey(): string {
    if (this.transformed && !this.modified) {
      return 'locale_key.general.state.added'
    } else if (this.transformed && this.modified) {
      return 'locale_key.general.state.modified'
    } else {
      return 'locale_key.general.state.not_added'
    }
  }

  public statusColor(): string {
    if (this.transformed && !this.modified) {
      return 'green'
    } else if (this.transformed && this.modified) {
      return 'orange'
    } else {
      return 'gray'
    }
  }
}
