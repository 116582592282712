import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { QUESTION_TYPE } from '../../request/questionnaire/QuestionTypeFe'
import { AnswerFe } from './AnswerFe'
import { EmissionFactorConversionFe } from 'src/app/model/emissions/EmissionFactorConversionFe'

export class AnswerEmissionFactorFe extends AnswerFe {
  selectedEmissionFactor: AbstractEmissionFactorFe
  constructor(
    id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    selectedEmissionFactor: AbstractEmissionFactorFe
  ) {
    super(id, sectionId, questionId, QUESTION_TYPE.EMISSION_FACTOR, comments)
    this.selectedEmissionFactor = selectedEmissionFactor
  }

  public static fromTransfer(transfer: any): AnswerEmissionFactorFe {
    let answer = new AnswerEmissionFactorFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.selectedEmissionFactor ? EmissionFactorConversionFe.fromTransfer(transfer.selectedEmissionFactor) : null
    )
    return answer
  }

  public hasAnyValue(): boolean {
    return !!this.selectedEmissionFactor
  }
}
