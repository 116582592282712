import { ChangeDetectorRef, Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ServerErrorFe } from 'src/app/utils/ServerErrorFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'alert-modal',
  templateUrl: './alert.modal.html',
  styleUrls: ['./alert.modal.scss']
})
export class AlertModal extends AbstractLanguageComponent implements OnInit {
  @Input() type: string //success, info, warning, error, question
  @Input() message: string
  @Input() error: ServerErrorFe | null
  title: string

  constructor(
    private modalRef: BsModalRef,
    private router: Router,
    languageService: LanguageService
  ) {
    super(languageService)
  }

  ngOnInit(): void {
    this.convertTypeToCapital()
  }

  closeModal() {
    this.modalRef.hide()
    document.body.classList.remove('modal-open')
  }

  convertTypeToCapital() {
    switch (this.type) {
      case 'error':
        this.title = this.locale('locale_key.general.error_message.title')
        break
      case 'warning':
        this.title = this.locale('locale_key.general.warning_message.title')
        break
      case 'info':
        this.title = this.locale('locale_key.general.info_message.title')
        break
      case 'success':
        this.title = this.locale('locale_key.general.success_message.title')
        break
    }
  }

  routeToLogin() {
    this.router.navigate([RoutesFe.LOGIN.getPath()])
    this.closeModal()
  }
}
