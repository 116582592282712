<ng-container
  *ngIf="
    isDetailsTab || isQuickTipsTab || isConnectedActionsTab || isEFDetailsTab || isCsrdQuestionTab || isCsrdDatapointTab
  "
>
  <div class="animated slideInRight text-start" id="slideInRight" style="width: 26rem; border-radius: 5px">
    <div class="modal-content custom-content thin-border">
      <div class="modal-header" style="padding: 0rem; margin-top: 1px">
        <ul class="nav nav-tabs nav-underline no-hover-bg nav-justified w-100">
          <li class="nav-item w-50" *ngIf="isQuickTipsTab">
            <a
              class="nav-link w-100 border-0"
              [ngClass]="{ active: displayService.isQuickTipsOpen() }"
              (click)="showTips()"
            >
              <i class="las la-info me-2 d-inline"></i>
              <p class="d-inline">{{ locale('locale_key.general.quick_tips.title') }}</p>
              <button type="button" class="btn close float-end p-0 me-2" (click)="closeTips()">
                <span aria-hidden="true"><i class="las la-times mt-1" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isDetailsTab">
            <a
              class="nav-link w-100 border-0"
              [ngClass]="{ active: displayService.isDetailsTabOpen() }"
              (click)="showDetails()"
            >
              <i
                class="me-2 mb-2 d-inline las fs-4 la"
                [ngClass]="{
                  'la-envelope-open': tabTitle == 'Request Info',
                  'la la-industry': tabTitle == 'Partner Info',
                  'las la-user': tabTitle == 'Contact Info',
                  'la la-list': tabTitle == 'Task Info',
                  'la la-cog': tabTitle == 'Chart Settings',
                  'las la-info': tabTitle == 'Info'
                }"
              ></i>
              <p class="text-underline d-inline cursor-arrow">{{ tabTitle | titlecase }}</p>
              <button type="button" class="btn close float-end p-0 me-2" (click)="closeDetails()">
                <span aria-hidden="true"><i class="las la-times" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isEFDetailsTab">
            <a class="nav-link w-100 border-0" [ngClass]="{ active: displayService.isEFTabOpen() }" (click)="showEF()">
              <p class="text-underline d-inline cursor-arrow">
                {{ locale('locale_key.pages.emission.emission_factor') }}
              </p>
              <button
                type="button"
                class="btn close float-end p-0 me-2"
                (click)="isEFDetailsTab = false; openOtherTab()"
              >
                <span aria-hidden="true"><i class="las la-times" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isEFDetailsTab && selectedEf['relatedDatabase']">
            <a
              class="nav-link w-100 border-0"
              [ngClass]="{ active: displayService.isDatabaseTabOpen() }"
              (click)="displayService.openTab = 'DATABASE_INFO'"
            >
              <p class="text-underline d-inline cursor-arrow">
                {{ locale('locale_key.pages.emission.database_info') }}
              </p>
              <button
                type="button"
                class="btn close float-end p-0 me-2"
                (click)="isEFDetailsTab = false; openOtherTab()"
              >
                <span aria-hidden="true"><i class="las la-times" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isConnectedActionsTab">
            <a class="nav-link w-100 border-0" [ngClass]="{ active: displayService.isConnectedActionsTabOpen() }">
              <i class="las la-info me-2 d-inline"></i>
              <p class="d-inline">{{ locale('locale_key.pages.insight_detail.connected_actions') }}</p>
              <button type="button" class="btn close float-end p-0 me-2" (click)="closeConnectedActions()">
                <span aria-hidden="true"><i class="las la-times mt-1" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isCsrdQuestionTab">
            <a
              class="nav-link w-100 border-0"
              [ngClass]="{ active: displayService.isCsrdQuestionTabOpen() }"
              (click)="showCsrdQuestion()"
            >
              <p class="text-underline d-inline cursor-arrow">{{ slocale('CSRD Question') }}</p>
              <button
                type="button"
                class="btn close float-end p-0 me-2"
                (click)="isCsrdQuestionTab = false; openOtherTab()"
              >
                <span aria-hidden="true"><i class="las la-times" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
          <li class="nav-item w-50" *ngIf="isCsrdDatapointTab">
            <a
              class="nav-link w-100 border-0"
              [ngClass]="{ active: displayService.isCsrdDatapointTabOpen() }"
              (click)="showCsrdDatapoint()"
            >
              <p class="text-underline d-inline cursor-arrow">{{ slocale('CSRD Datapoint') }}</p>
              <button
                type="button"
                class="btn close float-end p-0 me-2"
                (click)="isCsrdDatapointTab = false; openOtherTab()"
              >
                <span aria-hidden="true"><i class="las la-times" style="font-weight: bold"></i></span>
              </button>
            </a>
          </li>
        </ul>
      </div>
      <div style="height: var(--page-height-minus-app-header-footer); min-height: 90vh" class="">
        <ng-container *ngIf="displayService.isQuickTipsOpen()">
          <div class="modal-header p-3" style="border-radius: 0px; border: none; background-color: #fff">
            <h4 class="modal-title mt-2 text-uppercase">{{ locale(quickTipsTitleKey) }}</h4>
          </div>
          <div class="m-0 p-0">
            <div
              class="modal-body pt-0 p-3 quicktips-lg"
              style="
                overflow-y: scroll !important;
                overflow-x: hidden;
                background-color: #fff;
                max-height: 80vh !important;
              "
            >
              <div class="content clearfix text-justify tips" [innerHTML]="locale(quickTipsDescKey)"></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="displayService.isDetailsTabOpen()">
          <request-details *ngIf="activeRoute?.rootPath == 'requests'"></request-details>
          <task-details *ngIf="isTaskPage()"></task-details>
          <partner-details *ngIf="activeRoute?.rootPath == 'partners'"></partner-details>
          <network-details *ngIf="activeRoute?.rootPath == 'network'"></network-details>
          <chart-settings *ngIf="activeRoute?.rootPath == 'dashboard'"></chart-settings>
          <datahub-details *ngIf="activeRoute?.rootPath == 'hub'"></datahub-details>
        </ng-container>
        <ng-container *ngIf="displayService.isConnectedActionsTabOpen()">
          <connected-actions></connected-actions>
        </ng-container>
        <ng-container *ngIf="displayService.isEFTabOpen()">
          <ef-details
            *ngIf="selectedEf"
            [showOnlyInfo]="true"
            [isEFDetailOpen]="true"
            [emissionFactor]="selectedEf"
          ></ef-details>
        </ng-container>
        <ng-container *ngIf="displayService.isCsrdQuestionTabOpen()">
          <csrd-info-tab
            [question]="selectedCsrdQuestion"
            [datapoint]="selectedCsrdDatapoint"
            [datapointSettings]="selectedCsrdDatapointSettings"
          ></csrd-info-tab>
        </ng-container>
        <ng-container *ngIf="displayService.isCsrdDatapointTabOpen()">
          <csrd-info-tab
            [datapoint]="selectedCsrdDatapoint"
            [datapointSettings]="selectedCsrdDatapointSettings"
          ></csrd-info-tab>
        </ng-container>
        <ng-container *ngIf="displayService.isDatabaseTabOpen()">
          <ef-details
            *ngIf="selectedEf"
            [showOnlyInfo]="true"
            [isEFDetailOpen]="false"
            [emissionFactor]="selectedEf"
          ></ef-details>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
