import { Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { RouteFe } from './RouteFe'
import { RoutesFe } from './RoutesFe'

@Injectable({
  providedIn: 'root'
})
export class RouteServiceFe {
  activeRoute: RouteFe | undefined
  public activeRouteSubject: Subject<RouteFe | undefined> = new Subject<RouteFe | undefined>()
  public parameterSubject: Subject<string> = new Subject<string>()

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let path = val.url
        let activeRoute = RoutesFe.matchRootPath(path)
        if (this.activeRoute != activeRoute) {
          this.activeRoute = activeRoute
          this.activeRouteSubject.next(this.activeRoute)
        }
      }
    })
  }
}
