import { QuestionnaireFe } from '../questionnaire/QuestionnaireFe'
import { RecurrenceFe } from '../recurrence/RecurrenceFe'
import { Request_AddFe } from './Request_AddFe'

export class RequestGroup_AddFe {
  id: string
  title: string
  desc: string | null
  questionnaire: QuestionnaireFe | null
  requests: Request_AddFe[]
  dueDate: Date | null
  draft: boolean
  recurrence: RecurrenceFe | null
  isChildRecurring: boolean
  recurringParent: string | null
  requesterAffId: string
  requesterFirstName: string
  requesterLastName: string
  requesterCompanyId: string
  requesterCompanyName: string
  requesterUserId: string

  constructor(
    id: string,
    title: string,
    desc: string,
    questionnaire: QuestionnaireFe | null,
    requests: Request_AddFe[],
    dueDate: Date | null,
    draft: boolean,
    recurrence: RecurrenceFe | null,
    isChildRecurring: boolean,
    recurringParent: string | null,
    requesterAffId: string,
    requesterFirstName: string,
    requesterLastName: string,
    requesterCompanyId: string,
    requesterCompanyName: string,
    requesterUserId: string
  ) {
    this.id = id
    this.requests = requests
    this.title = title
    this.desc = desc
    this.questionnaire = questionnaire
    this.dueDate = dueDate
    this.draft = draft
    this.recurrence = recurrence
    this.isChildRecurring = isChildRecurring
    this.recurringParent = recurringParent
    this.requesterAffId = requesterAffId
    this.requesterFirstName = requesterFirstName
    this.requesterLastName = requesterLastName
    this.requesterCompanyId = requesterCompanyId
    this.requesterCompanyName = requesterCompanyName
    this.requesterUserId = requesterUserId
  }
}
