<div style="height: 100%" *ngIf="answer; else noAnswer">
  <div class="ps-3 scroll-y" style="height: 90%">
    <div class="accordion mt-2" id="sectionParentPreview">
      <div class="accordion-item" *ngFor="let section of request.questionnaire.sections; let sectionIndex = index">
        <h2 class="accordion-header">
          <button
            class="accordion-button px-0 pe-3 bg-white"
            type="button"
            data-bs-toggle="collapse"
            attr.data-bs-target="#sectionAccordion{{ sectionIndex }}Preview"
            aria-expanded="true"
            attr.aria-controls="sectionAccordion{{ sectionIndex }}Preview"
            id="section{{ sectionIndex }}Preview"
          >
            <div class="d-flex flex-row my-auto p-2">
              <span class="me-2">
                <h5 class="my-auto ms-2">
                  {{
                    locale('locale_key.pages.direct_data_entries.section_number', { sectionNumber: sectionIndex + 1 })
                  }}
                </h5>
              </span>
              <div class="px-2 my-auto d-flex justify-content-end" *ngIf="request.questionnaire.status == 'APPROVED'">
                <span class="statusBox mt-0 me-2" *ngIf="requestService.isSectionConnected(section)">
                  <i class="la la-link" style="color: var(--main-gradient-start-color)"></i>
                  <span class="ms-1">{{ locale('locale_key.general.state.added') }}</span>
                </span>
                <div class="btn-group">
                  <div
                    class="description-btn text-end"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="request.questionnaire.status == 'APPROVED' && section.taxonomyKey"
                  >
                    <i
                      class="la la-ellipsis-v ms-auto my-auto cursor size-15"
                      [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"
                    ></i>
                  </div>
                  <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                    <button
                      class="dropdown-item underline ms-0 px-1 text-dark"
                      type="button"
                      *ngIf="section.taxonomyKey && !requestService.isSectionConnected(section)"
                      (click)="selectedSection = section; openModal(confirmConnect, 'modal-lg')"
                    >
                      {{ locale('locale_key.pages.direct_data_entries.button.add_data') }}
                    </button>
                    <button
                      class="dropdown-item underline ms-0 px-1 text-dark"
                      type="button"
                      *ngIf="section.taxonomyKey && requestService.isSectionConnected(section)"
                      (click)="selectedSection = section; openModal(confirmDisconnect, 'modal-md')"
                    >
                      {{ locale('locale_key.pages.data_request.approved_section.button.remove') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="sectionAccordion{{ sectionIndex }}Preview"
          class="accordion-collapse collapse show"
          data-bs-parent="#sectionParent"
        >
          <div class="accordion-body ps-2 pe-0">
            <div
              class="d-flex flex-column w-100 py-3 px-2 mb-2 mx-2"
              [ngClass]="{ 'thin-border-b': !last }"
              *ngFor="let question of section.questions; let i = index; let last = last"
            >
              <div class="row">
                <span class="col-11 my-auto dark-text fs-5 ps-1 bold">{{ i + 1 }}. {{ question.question }}</span>
                <div class="btn-group col-1">
                  <div
                    class="description-btn text-end"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="section.taxonomyKey && question.mappedToColKeys"
                  >
                    <i
                      class="la la-ellipsis-v ms-auto my-auto cursor size-15"
                      [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"
                    ></i>
                  </div>
                  <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                    <button
                      class="dropdown-item underline ms-0 px-1 text-dark"
                      type="button"
                      (click)="showMappingDetails(section, question, mappingDetails)"
                    >
                      {{ locale('locale_key.pages.data_request.show_mapping_details') }}
                    </button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="question.answer">
                <span class="text-muted ms-5 py-3">
                  <div class="d-flex align-items-center">
                    <i class="gradient-end bold me-2 fs-5">{{ locale('locale_key.pages.task_portal.answer') }}</i>
                    <div class="d-flex flex-column align-items-start">
                      <ng-container *ngIf="requestService.isMultipleChoiceQues(question)">
                        <div class="d-flex pb-1" *ngFor="let optionId of question.answer.selectedOptionIds">
                          <input type="checkbox" class="mx-2" checked disabled />
                          <label class="ps-2">{{ question.getOption(optionId).value }}</label>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="requestService.isSingleChoiceQues(question)">
                        <div class="d-flex" *ngIf="question.answer.selectedOptionId">
                          <input type="radio" class="mx-2" checked disabled />
                          <label>{{ question.getOption(question.answer.selectedOptionId).value }}</label>
                        </div>
                      </ng-container>
                      <label *ngIf="requestService.isNumberQues(question)">
                        {{ question.answer.value + ' ' + getUnitString(question.answer.unit) }}
                      </label>
                      <div class="d-flex" *ngIf="requestService.isDateQues(question)">
                        <h5 class="text-muted d-inline">{{ question.answer.getDateFromString() }}</h5>
                        <h5 class="mx-1" *ngIf="question.range">-</h5>
                        <h5 class="text-muted d-inline" *ngIf="question.range">
                          {{ question.answer.getDateToString() }}
                        </h5>
                      </div>
                      <h5 class="text-muted m-0 d-inline multiline" *ngIf="requestService.isTextQues(question)">
                        {{ question.answer.text }}
                      </h5>
                      <label
                        class="text-muted m-0 d-flex align-items-center"
                        *ngIf="requestService.isEmissionFactorQues(question)"
                      >
                        <emission-factor [selectedEF]="question.answer.selectedEmissionFactor"></emission-factor>
                        <div
                          class="btn-group col-1"
                          *ngIf="
                            section.taxonomyKey &&
                            question.mappedToColKeys &&
                            requestService.isSectionConnected(section)
                          "
                        >
                          <div
                            class="description-btn text-end"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="la la-ellipsis-v ms-auto my-auto cursor size-15"
                              [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"
                            ></i>
                          </div>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button
                              class="dropdown-item underline ms-0 px-1 text-dark"
                              type="button"
                              (click)="openMasterTable(section)"
                            >
                              {{ locale('locale_key.pages.data_request.edit.emission_factor_in_master_table') }}
                            </button>
                          </div>
                        </div>
                      </label>
                      <div
                        class="py-3 w-50 open-bx"
                        [ngClass]="{ xSmallSize: screenSize.isXSmallSize() }"
                        *ngIf="
                          (requestService.isAttachmentQues(question) && question.answer.attachmentStorageId) ||
                          requestService.isDataTableQues(question)
                        "
                      >
                        <div class="ball-pulse loader-primary" *ngIf="question.answer.isLoading">
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                        </div>
                        <ng-container *ngIf="!question.answer.isLoading">
                          <div class="col-2 text-left mx-0 px-0">
                            <i
                              class="la la-file-text-o fs-1 me-auto ms-0 ps-0"
                              *ngIf="requestService.isAttachmentQues(question)"
                            ></i>
                            <i
                              class="la la-table fs-1 me-auto ms-0 ps-0"
                              *ngIf="requestService.isDataTableQues(question)"
                            ></i>
                          </div>
                          <div class="col-6 d-flex justify-content-center text-left mx-0 ps-0">
                            <p
                              class="text-muted my-auto me-auto truncate"
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="{{
                                requestService.isDataTableQues(question) ? 'Data Table' : getAttachmentTitle(question)
                              }}"
                            >
                              {{
                                requestService.isDataTableQues(question)
                                  ? locale('locale_key.pages.data_request.question_type.data_table')
                                  : getAttachmentTitle(question)
                              }}
                            </p>
                          </div>
                          <div class="col-4 d-flex flex-row justify-content-center">
                            <span
                              class="text-muted m-auto underline cursor"
                              (click)="
                                requestService.isDataTableQues(question) ? openTableFile(question) : openFile(question)
                              "
                            >
                              {{ locale('locale_key.general.buttons.open') }}
                            </span>
                            <div class="dropdown">
                              <span
                                class="text-muted m-auto underline cursor"
                                data-bs-toggle="dropdown"
                                data-placement="top"
                              >
                                {{ locale('locale_key.general.buttons.export') }}
                              </span>
                              <ul class="dropdown-menu">
                                <li><a class="dropdown-item" (click)="export(question, 'xlsx')">xlsx</a></li>
                                <li><a class="dropdown-item" (click)="export(question, 'csv')">csv</a></li>
                              </ul>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <small class="text-muted" *ngIf="question.answer.comments">
                    <i class="gradient-end bold me-2 fs-5">
                      {{ locale('locale_key.pages.data_request.comments.placeholder') }}:
                    </i>
                    {{ question.answer.comments }}
                  </small>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-3 selected" *ngIf="request.questionnaire.status == 'APPROVED'">
    <span class="dark-text">
      <i class="la la-check-circle-o fs-4 me-2" style="color: #68c781; position: relative; top: 2px"></i>
      {{ locale('locale_key.pages.sm_requests.approved_data_desc') }}
    </span>
  </div>
  <div class="p-3 selected" *ngIf="request.questionnaire.status == 'REJECTED'">
    <span class="dark-text">
      <i class="la la-times-circle fs-4 me-2" style="color: #b9625f; position: relative; top: 2px"></i>
      {{
        locale('locale_key.pages.sm_requests.rejected_data_desc', {
          name: getLastActionableActivity().getSubmitterName()
        })
      }}
    </span>
  </div>
  <div class="modal-footer thin-border-t p-3" *ngIf="request.questionnaire.status == 'SUBMITTED'">
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button mx-1" (click)="openModal(confirmRejection)">
        {{ locale('locale_key.general.buttons.reject') }}
      </button>
      <button type="button" class="btn primary-button custom-button mx-1" (click)="openModal(confirmApproval)">
        {{ locale('locale_key.general.buttons.approve') }}
      </button>
    </ng-container>
  </div>
</div>
<ng-template #noAnswer>
  <div class="d-flex align-items-center justify-content-center" style="height: 100%">
    {{ locale('locale_key.pages.sm_requests.no_submitted_data') }}
  </div>
</ng-template>

<ng-template #confirmApproval>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">
      {{ locale('locale_key.pages.sm_requests.title.approve_submitted_data') }}
    </h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body border-shade">
    <h5>{{ locale('locale_key.pages.data_request.modal.approve_submitted_data.desc') }}</h5>
    <textarea class="form-control" rows="10" [(ngModel)]="message"></textarea>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="handleData('ACCEPT')">
        {{ locale('locale_key.general.buttons.approve') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmRejection>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.title.reject_submitted_data') }}
    </h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="border-shade">
      <h5>{{ locale('locale_key.pages.data_request.modal.reject_submitted_data.desc') }}</h5>
      <textarea class="form-control" rows="10" [(ngModel)]="message"></textarea>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn primary-button custom-button"
        [disabled]="!message || message?.length == 0"
        (click)="handleData('REJECT')"
      >
        {{ locale('locale_key.general.buttons.reject') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmDisconnect>
  <div class="modal-header btn-add">
    <div
      class="icon-background d-inline mt-0"
      style="
        height: 18px;
        width: 18px;
        background-color: var(--orange);
        border-radius: 50%;
        border: 2px solidtext-white;
      "
    >
      <i
        class="las la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -2px;
          top: -1px;
        "
      ></i>
    </div>
    <h5 class="modal-title text-white" id="myModalLabel12">
      {{ locale('locale_key.general.buttons.remove_from_maser_table') }}
    </h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{ locale('locale_key.pages.data_request.review_answer_wizard.disconnect_confirmation_modal.title') }}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button
        type="button"
        class="btn secondary-button custom-button"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="disconnectSection()">
        {{ locale('locale_key.general.buttons.remove_from_maser_table') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmConnect>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.title') }}
    </h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{ locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.desc') }}
    </p>
    <p>{{ locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.desc2') }}</p>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="connectSection()">
        {{
          locale(
            'locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.add_to_master_table'
          )
        }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #mappingDetails>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">{{ locale('locale_key.data_request.question_details') }}</h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale('locale_key.wizard.change.data_mapped_to') }}</p>
    <div class="row-design p-3">
      <span class="badge badge-info w-auto py-auto my-auto">
        {{ selectedSection.entity.getTaxonomyTitle(taxonomyInfo) }}
      </span>
      <div>
        <span class="me-2">{{ locale('locale_key.wizard.change.datapoint') }}</span>
        <span
          class="badge truncate badge-info w-auto py-auto my-auto"
          *ngFor="let mappedToCol of selectedQuestion.mappedToCols"
        >
          {{ mappedToCol?.getLabel() }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
