import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { FormGroup, FormControl, FormArray } from '@angular/forms'
import { BsModalService } from 'ngx-bootstrap/modal'
import { CompanyFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { AbstractSignInMethodFe } from 'src/app/model/signInMethod/AbstractSignInMethodFe'
import { AffMicrosoftSignInMethodFe } from 'src/app/model/signInMethod/AffMicrosoftSignInMethodFe'
import { CompanyMicrosoftSignInMethodFe } from 'src/app/model/signInMethod/CompanyMicrosoftSignInMethodFe'
import { AffGoogleSignInMethodFe } from 'src/app/model/signInMethod/GoogleSignInMethodFe'
import { AffPasswordSignInMethodFe } from 'src/app/model/signInMethod/PasswordSignInMethodFe'
import { SignInMethodUpdateFe, SigninMethodUpdateTypeFe } from 'src/app/model/signInMethod/SignInMethodUpdateFe'
import { TenantFe } from 'src/app/model/signInMethod/TenantFe'
import { AbstractAffiliationFe } from 'src/app/model/user/AbstractAffiliationFe'
import { AdminFe } from 'src/app/model/user/AdminFe'
import { AffiliationFe } from 'src/app/model/user/AffiliationFe'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { CustomerSuccessFe } from 'src/app/model/user/CustomerSuccessFe'
import { ManagerFe } from 'src/app/model/user/ManagerFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'sign-in-setting-modal',
  templateUrl: './signInSettings.modal.html'
})
export class SignInSettingsModalComponent extends AbstractLanguageComponent {
  @ViewChild('mfaWarning', { static: true }) mfaWarning: TemplateRef<any>
  @Input() type: string
  @Input() selectedCompany: CompanyFe
  @Input() selectedUser: AbstractAffiliationFe | CustomerSuccessFe
  @Output() closeEvent = new EventEmitter<boolean>()
  title = ''
  desc = ''
  form = new FormGroup({
    passwordEnabled: new FormControl(true),
    googleEnabled: new FormControl(true),
    microsoftEnabled: new FormControl(true),
    mfaRequired: new FormControl(false),
    tenants: new FormArray([]),
    selectedTenant: new FormControl('')
  })
  tenantToRemoveIdx = -1
  hasAffiliationsWithMfaEnabled = false
  isGoogleOpen: boolean = false
  isMicrosoftOpen: boolean = false
  selectedTenant: number | null = null
  inProgress = false
  screenSize = ScreenWidthSizeFe.WIDTH_XSMALL

  constructor(
    private stateService: StateServiceFe,
    private modalService: BsModalService,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit() {
    switch (this.type) {
      case 'company':
        this.title = `Sign-in methods: ${this.selectedCompany.name}`
        this.desc = `Select which sign-in method(s) should be available for the users of ${this.selectedCompany.name}.`
        this.selectedCompany.signInMethods.forEach((signinMethod) => {
          const providerStr = `${signinMethod.provider}Enabled`
          const enabled = signinMethod.isEnabled
          this.form.controls[providerStr].setValue(enabled)
          if (signinMethod instanceof CompanyMicrosoftSignInMethodFe) {
            this.tenants.clear()
            signinMethod.tenants.forEach((tenant) => {
              this.addTenant({
                id: tenant.id,
                description: tenant.description,
                isPrimary: tenant.isPrimary
              })
            })
          }
        })
        break
      case 'user':
        const user_name = this.selectedUser.getName()
        this.title = `Sign-in methods: ${user_name}`
        this.desc = `Select which sign-in method(s) should be available for the user ${user_name}.`
        const mfaRequired = this.selectedUser.mfaEnabled
        this.form.controls['mfaRequired'].setValue(mfaRequired)
        this.selectedUser.signInMethods.forEach((signinMethod) => {
          const providerStr = `${signinMethod.provider}Enabled`
          const enabled = signinMethod.isEnabled
          this.form.controls[providerStr].setValue(enabled)
          if (signinMethod instanceof AffMicrosoftSignInMethodFe) {
            const tenantID = signinMethod.tenantId
            this.form.controls['selectedTenant'].setValue(tenantID)
          }
        })
        break
    }
  }

  get tenants() {
    return this.form.controls['tenants'] as FormArray
  }

  addTenant({ id, description, isPrimary } = { id: '', description: '', isPrimary: false }) {
    const tenantForm = new FormGroup({
      id: new FormControl(id),
      description: new FormControl(description),
      isPrimary: new FormControl(isPrimary)
    })
    this.tenants.push(tenantForm)
  }

  setPrimaryTenant(idx: number) {
    //
  }
  confirmRemoveTenant(index: number) {
    this.tenantToRemoveIdx = index
  }

  cancelRemoveTenant(index: number) {
    this.tenantToRemoveIdx = -1
  }

  removeTenant(index: number) {
    this.tenants.removeAt(index)
    this.tenantToRemoveIdx = -1
  }

  selectTenant(evt) {
    const tenantID = evt.target.value
    console.log(`Selecting tenant : ${tenantID}`)
    this.form.controls['selectedTenant'].setValue(tenantID)
  }

  async saveAuthSettings() {
    this.inProgress = true
    const password = new AffPasswordSignInMethodFe(this.form.controls.passwordEnabled.value)
    const google = new AffGoogleSignInMethodFe(this.form.controls.googleEnabled.value)
    let microsoft: AbstractSignInMethodFe
    const mfaRequired = this.form.controls.mfaRequired.value
    let prevMfaRequired = false

    if ((this.type = SigninMethodUpdateTypeFe.USER)) {
      microsoft = new AffMicrosoftSignInMethodFe(
        this.form.controls.microsoftEnabled.value,
        this.form.controls.selectedTenant.value
      )
      prevMfaRequired = this.selectedUser.mfaEnabled
      const signinMethods = [password, google, microsoft]
      const authSettings = new SignInMethodUpdateFe(signinMethods, mfaRequired, prevMfaRequired, this.type)

      if (this.selectedUser instanceof CustomerSuccessFe) {
        authSettings.setUserDetails(this.selectedUser.userId, null, null)
        await this.stateService.updateCSSignInSettings(authSettings)
      } else if (this.selectedUser instanceof AdminFe) {
        authSettings.setUserDetails(
          this.selectedUser.userId,
          this.selectedUser.affiliationId,
          this.selectedUser.affiliationEmail
        )
        await this.stateService.updateAdminSignInSettings(authSettings)
      } else {
        authSettings.setUserDetails(
          this.selectedUser.userId,
          this.selectedUser.affiliationId,
          this.selectedUser.affiliationEmail
        )
        let userType = this.selectedUser instanceof ManagerFe ? 'SM' : 'DO'
        const saveAuthSettingsResult = await this.stateService.updateAffSignInSettings(authSettings, userType)
        this.hasAffiliationsWithMfaEnabled = saveAuthSettingsResult.hasAffiliationsWithMfaEnabled
      }
    } else {
      const microsoftTenants = this.tenants.controls.map((tenantControl) => {
        const tenantFormGroup = tenantControl as FormGroup
        const tenant = new TenantFe(
          tenantFormGroup.controls['id'].value,
          tenantFormGroup.controls['description'].value,
          tenantFormGroup.controls['isPrimary'].value
        )
        return tenant
      })
      microsoft = new CompanyMicrosoftSignInMethodFe(this.form.controls.microsoftEnabled.value, microsoftTenants)
      const signinMethods = [password, google, microsoft]
      const authSettings = new SignInMethodUpdateFe(signinMethods, mfaRequired, prevMfaRequired, this.type)
      authSettings.setCompanyDetails(this.selectedCompany.id)
      await this.stateService.updateCompanySignInSettings(authSettings)
    }

    this.close()

    if (
      this.type == SigninMethodUpdateTypeFe.USER && // is user auth settings
      prevMfaRequired != mfaRequired && // are switching mfa state
      !mfaRequired // are disabling mfa
    ) {
      this.openModal(this.mfaWarning, 'modal-md')
    }
    this.inProgress = true
  }

  openModal(templateRef: TemplateRef<any>, size: string = '') {
    this.modalService.show(templateRef, { class: size })
  }

  closeModal() {
    this.modalService.hide()
  }

  close() {
    this.closeEvent.emit(true)
  }
}
