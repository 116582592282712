import { KpiFe2 } from '../insight/KpiFe2'

export class ValueRowFeNew {
  public percentageVector: string[]
  constructor(
    public kpi: KpiFe2,
    public valueVector: number[]
  ) {
    let total = this.valueVector.reduce((a, b) => a + b, 0)
    this.percentageVector = this.valueVector.map((v) => ((v / total) * 100).toFixed(2) + '%')
  }
}
