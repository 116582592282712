import { AbstractEmissionFactorFe, EmissionFactorTypeFe } from './AbstractEmissionFactorFe'

export class CustomEmissionFactorFe extends AbstractEmissionFactorFe {
  constructor(
    public id: string,
    public sourceId: string,
    public sourceName: string,
    public sourceUnit: string,
    public conversionFactor: number,
    public conversionUnit: string,
    public origin: string,
    public additionalDetails: { [key: string]: string }
  ) {
    super(
      id,
      EmissionFactorTypeFe.CUSTOM_EMISSION_FACTOR,
      sourceId,
      sourceName,
      sourceUnit,
      conversionFactor,
      conversionUnit,
      origin,
      additionalDetails
    )
  }

  public static fromTransfer(transfer: any): CustomEmissionFactorFe {
    let ef = new CustomEmissionFactorFe(
      transfer.id,
      transfer.sourceId,
      transfer.sourceName,
      transfer.sourceUnit,
      transfer.conversionFactor,
      transfer.conversionUnit,
      transfer.origin,
      transfer.additionalDetails
    )

    return ef
  }
}
