import { Component, AfterViewChecked } from '@angular/core'

@Component({
  selector: 'preworkspace-layout',
  templateUrl: './preworkspace-layout.component.html',
  styleUrls: ['./preworkspace-layout.component.scss']
})
export class PreWorkspaceLayoutComponent implements AfterViewChecked {
  constructor() {}

  async ngOnInit() {}

  ngAfterViewChecked(): void {}
}
