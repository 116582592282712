export enum UnitSystems {
  'SI' = 'SI',
  'METRIC' = 'METRIC',
  'US_CUSTOMARY' = 'US_CUSTOMARY',
  'IMPERIAL' = 'IMPERIAL',
  'OTHER' = 'OTHER',
  'CUSTOM' = 'CUSTOM'
}

export const getUnitSystemName = (name: UnitSystems) => {
  switch (name) {
    case UnitSystems.SI:
      return 'International Standard'
    case UnitSystems.METRIC:
      return 'Metric'
    case UnitSystems.US_CUSTOMARY:
      return 'US Customary'
    case UnitSystems.IMPERIAL:
      return 'Imperial'
    case UnitSystems.OTHER:
      return 'Others'
    case UnitSystems.CUSTOM:
      return 'Custom'
    default:
      return `${name}`
  }
}
