import { Injectable } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { AlertModal } from '../components/layout/header/alert.modal'
import { ServerErrorFe } from '../utils/ServerErrorFe'

@Injectable({
  providedIn: 'root'
})
export class AlertServiceFe {
  constructor(private modalService: BsModalService) {}

  public showError(message: string, err: ServerErrorFe = null) {
    this.showAlert('error', message, err)
  }

  public showWarning(message: string) {
    this.showAlert('warning', message)
  }

  public showInfo(message: string) {
    this.showAlert('info', message)
  }

  public showSuccess(message: string) {
    this.showAlert('success', message)
  }

  public showAlert(type: string, message: string, err: ServerErrorFe = null) {
    let input = { type: type, message: message, error: err }
    let config = {
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: input
    }
    this.modalService.show(AlertModal, config)
  }
}
