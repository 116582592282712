import { Component, Input, OnInit, Output } from '@angular/core'
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'
import { Subject } from 'rxjs'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DataCategoryServiceFe } from 'src/app/services/DataCategoryServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss']
})
export class CategorySelectionComponent extends AbstractLanguageComponent implements OnInit {
  taxonomy: TaxonomyInfoFe
  @Input() editedTaxonomy: boolean
  selectedRootEntity: EntityFe
  selectedParentEntity: EntityFe
  selectedEntity: EntityFe
  @Input() entityKey: string
  @Output() entitySelected: Subject<EntityFe> = new Subject()

  constructor(
    public stateService: StateServiceFe,
    public languageService: LanguageService,
    private categoryService: DataCategoryServiceFe
  ) {
    super(languageService)
  }

  async ngOnInit(): Promise<void> {
    if (this.editedTaxonomy) {
      this.taxonomy = await this.categoryService.getTaxonomy()
    } else {
      let { depTaxonomy } = await this.stateService.getTaxonomyInfos()
      this.taxonomy = depTaxonomy
    }
    if (this.entityKey) {
      this.selectedEntity = this.taxonomy.entityByKey(this.entityKey)
      this.selectedParentEntity = this.taxonomy.parentEntity(this.entityKey)
      this.selectedRootEntity = this.taxonomy.rootParentEntity(this.entityKey)
    }
  }

  select(item0: EntityFe, item1: EntityFe, item2: EntityFe, dropdown: BsDropdownDirective) {
    this.selectedRootEntity = item0
    this.selectedParentEntity = item1
    this.selectedEntity = item2
    this.entitySelected.next(item2)
    dropdown.hide()
  }
}
