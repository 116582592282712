<div class="supplier-modal">
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">
      {{ locale('locale_key.pages.admins.add_customer_success_modal.title') }}
      {{ mode == 'admin' ? locale('locale_key.general.toolbar.button.admin') : '' }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()">
      <span aria-hidden="false" style="font-size: 26px" class="text-white fw-normal">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll-example" style="border-top: none">
    <div class="card-header pb-0">
      <div class="col-lg-12">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 mt-0 pt-0 m-auto d-flex"
            [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row justify-content-between'"
          >
            <ng-container *ngIf="!screenSize.isXSmallSize()">
              <div class="col-lg-5 col-md-5 mt-0 pt-0 mx-0 px-0 mx-auto">
                <div
                  class="d-flex flex-row mb-4 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                >
                  <div class="circle1 active-circle1 mx-0 px-0">1</div>
                  <h6 class="mt-3 ms-0 ps-3 pb-1">
                    {{ locale('locale_key.pages.admins.add_customer_success_modal.header.customer_success') }}
                    {{ mode == 'admin ' ? locale('locale_key.general.toolbar.button.admin') : '' }}
                    {{ locale('locale_key.general.info_message.title') }}
                  </h6>
                  <!-- <span class="ms-2 mt-1" style="font-size: 1.5rem" *ngIf="mode!='admin'"><i class="las la-angle-right"></i></span> -->
                </div>
              </div>
              <div class="col-lg-2 col-md-2 d-flex justify-content-center">
                <span class="mt-2" style="font-size: 1.5rem" *ngIf="mode != 'admin'">
                  <i class="las la-angle-right"></i>
                </span>
              </div>
              <div class="col-lg-5 col-md-5 text-start mx-0 px-0" *ngIf="mode != 'admin'">
                <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                  <div class="circle1 active mx-0 px-0">2</div>
                  <h6 class="mt-3 ms-0 ps-3 pb-1">
                    {{ locale('locale_key.pages.admins.add_customer_success_modal.header.assign_companies') }}
                  </h6>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="screenSize.isXSmallSize()">
              <div class="d-flex flex-row justify-content-center mb-1">
                <div class="d-block text-center">
                  <ng-container *ngIf="mode != 'admin'">
                    <div
                      class="col stepper text-center"
                      *ngFor="let step of steps; let i = index"
                      [class.active-stepper]="currentActive === i + 1 || step.completed"
                    ></div>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center mb-1" *ngIf="mode != 'admin'">
                <h6 class="text-center my-auto">
                  {{ steps[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})
                </h6>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <hr class="mt-0 pt-0 mx-3" />
    </div>
    <form class="task-form" [formGroup]="form1" *ngIf="activeFormNo == 1">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="d-flex">
              <div class="circle-icon me-1 ms-3" style="position: relative; bottom: 5px; left: 0">
                <i class="las la-user fs-4 text-white"></i>
              </div>
              <span>
                <h5 class="me-2">
                  {{ locale('locale_key.pages.admins.add_customer_success_modal.customer_success_info') }}
                </h5>
              </span>
              <i
                class="la la-info-circle dropdown-toggle info-popover-arrow fs-5"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></i>
              <div class="dropdown-menu arrow p-2 info-popover">
                <p>
                  {{ locale('locale_key.pages.admins.add_customer_success_modal.customer_success_info_description') }}
                </p>
              </div>
            </div>
            <div class="row px-3 mt-3">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ locale('locale_key.pages.admins.add_customer_success_modal.first_name') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.admins.add_customer_success_modal.first_name') }}"
                    required
                    formControlName="firstName"
                    [ngClass]="{
                      'is-invalid':
                        form1.get('firstName').errors &&
                        (form1.get('firstName').touched || form1.get('firstName').dirty)
                    }"
                  />
                  <div
                    *ngIf="
                      form1.get('firstName').errors && (form1.get('firstName').touched || form1.get('firstName').dirty)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form1.get('firstName').errors.required">
                      {{ firstNameMessage }}
                    </div>
                    <div *ngIf="form1.get('firstName').hasError('pattern')">
                      {{ lastNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>{{ locale('locale_key.pages.admins.add_customer_success_modal.last_name') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.admins.add_customer_success_modal.last_name') }}"
                    required
                    formControlName="lastName"
                    [ngClass]="{
                      'is-invalid':
                        form1.get('lastName').errors && (form1.get('lastName').touched || form1.get('lastName').dirty)
                    }"
                  />
                  <div
                    *ngIf="
                      form1.get('lastName').errors && (form1.get('lastName').touched || form1.get('lastName').dirty)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form1.get('lastName').errors.required">
                      {{ lastNameMessage }}
                    </div>
                    <div *ngIf="form1.get('lastName').hasError('pattern')">
                      {{ lastNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>{{ locale('locale_key.pages.admins.add_customer_success_modal.email') }}*</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.admins.add_customer_success_modal.email') }}"
                    required
                    formControlName="email"
                    [ngClass]="{
                      'is-invalid':
                        form1.get('email').errors && (form1.get('email').touched || form1.get('email').dirty)
                    }"
                  />
                  <div
                    *ngIf="form1.get('email').errors && (form1.get('email').touched || form1.get('email').dirty)"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form1.get('email').errors.required">
                      {{ emailMessage }}
                    </div>
                    <div *ngIf="form1.get('email').hasError('pattern')">
                      {{ lowerCaseEmail }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form class="task-form" *ngIf="activeFormNo == 2 && mode != 'admin'">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <h5 class="ms-0 ps-0">
              {{ locale('locale_key.pages.admins.add_customer_success_modal.assign_companies_section') }}
            </h5>
            <small>
              {{ locale('locale_key.pages.admins.add_customer_success_modal.assign_companies_section.description') }}
            </small>
            <div class="row border scroll-example mh-300">
              <table class="table table-responsive table-borderless">
                <tbody>
                  <tr *ngFor="let company of mgmtCompanies">
                    <td>
                      <input type="checkbox" class="form-check-inline" (change)="toggleCompanies($event, company)" />
                      {{ company.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!isLoading">
      <button class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.pages.clients.add_customer_success_modal.button.cancel') }}
      </button>
      <button class="btn secondary-button custom-button" (click)="prev()" *ngIf="activeFormNo == 2">
        {{ locale('locale_key.pages.clients.add_customer_success_modal.button.previous') }}
      </button>
      <button
        class="btn btn-add text-white custom-button"
        (click)="next()"
        *ngIf="activeFormNo == 1 && mode != 'admin'"
        [disabled]="form1.invalid"
      >
        {{ locale('locale_key.pages.clients.add_customer_success_modal.button.next') }}
      </button>
      <button
        class="btn btn-add text-white custom-button"
        (click)="addUser()"
        *ngIf="activeFormNo == 2 || (activeFormNo == 1 && mode == 'admin')"
        [disabled]="activeFormNo == 1 ? form1.invalid : assignedCompanies.length == 0"
      >
        {{ locale('locale_key.pages.admins.add_customer_success_modal.button.add_user') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</div>
