import { BooleanStatementFe } from './BooleanStatementFe'
import { ValueStatementFe } from './ValueStatementFe'

export class DateValueStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'DATE'
  public type = DateValueStatementFe.TYPE_KEY
  public static START_KEYWORD = '|'

  constructor(value: any) {
    super(value)
  }

  public toFormulaString(): string {
    let str = [`${DateValueStatementFe.START_KEYWORD}`, `${this.value}`].join('')
    return str
  }

  public static fromFormulaString(formula: string): DateValueStatementFe {
    let nextSpace = formula.indexOf(BooleanStatementFe.SPACE)
    let str = formula.substring(DateValueStatementFe.START_KEYWORD.length, nextSpace)
    let value = parseFloat(str)
    return new DateValueStatementFe(value)
  }

  public evaluate(values: Map<string, any>): number {
    return this.value
  }

  public static fromTransfer(transfer: any): DateValueStatementFe {
    let value = transfer['value']
    let statement = new DateValueStatementFe(value)
    return statement
  }
}
