<div class="m-0">
  <div class="main-modal-header modal-header py-1 px-3" ngxModalDraggable>
    <h4 class="modal-title text-white text-truncate">
      {{mode=="add" ? locale("locale_key.pages.organization.edit_mode.add_organization_data_group") :
      locale("locale_key.pages.organization.edit_mode.edit_organization_data_group")}}
    </h4>
    <button type="button" class="btn close">
      <span aria-hidden="false" class="list-inline">
        <a (click)="closeModal()"><i class="las la-times text-white"></i></a>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="content clearfix">
      <div class="form-group">
        <div>
          <div class="d-flex flex-column mb-3 ps-3" *ngIf='mode=="add"'>
            <div class="custom-control custom-radio col-12">
              <input
                class="custom-control-input"
                type="radio"
                name="type"
                id="selectFrom"
                value="selectFrom"
                [(ngModel)]="type"
              />
              <label class="custom-control-label form-label" for="selectFrom">
                {{locale("locale_key.pages.organization.edit_mode.select_organization_data_group")}}
              </label>
            </div>
            <div class="col-12 w-100">
              <select
                class="form-select form-control float-start"
                [disabled]="type=='defineNew'"
                [(ngModel)]='type=="defineNew" ? "": selectedOptionalNode'
                #defineNew="ngModel"
                [ngClass]="{ 'is-invalid':  defineNew.errors && (defineNew.dirty || defineNew.touched) }"
                required
              >
                <option value="nullValue">---</option>
                <ng-container *ngFor="let optionalNode of optionalNodes;let i=index;">
                  <option [value]="i">{{optionalNode.label}}</option>
                </ng-container>
              </select>
              <div *ngIf="defineNew.errors && (defineNew.dirty || defineNew.touched)" class="invalid-feedback">
                <div *ngIf="defineNew.errors.required">{{ selectExistingEntityMessage}}</div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mb-3" [ngClass]='{"ps-3": mode=="add", " mb-2": mode!="add"}'>
            <div class="custom-radio col-12" [ngClass]='{"custom-control": mode=="add"}'>
              <input
                class="custom-control-input"
                type="radio"
                name="type"
                value="defineNew"
                id="defineNew"
                [(ngModel)]="type"
                *ngIf='mode=="add"'
              />
              <label class="form-label" [ngClass]='{"custom-control-label": mode=="add"}' for="defineNew">
                {{mode=="add"? locale("locale_key.pages.organization.edit_mode.define_organization_data_group") :
                locale("locale_key.pages.organization.edit_mode.new_data_group_name")}}
              </label>
            </div>
            <div class="col-12">
              <ng-container *ngIf='mode=="add"'>
                <input
                  type="text"
                  class="form-control"
                  style="width: inherit"
                  [ngModel]='type=="defineNew"? node.label : ""'
                  (ngModelChange)="updatedLabel=$event; node.label=$event;"
                  [disabled]="mode=='add' && (type=='selectFrom' || isNodeLabelAdded)"
                  name="defineNew"
                  placeholder="Enter entity name"
                  #defineNew="ngModel"
                  [ngClass]="{ 'is-invalid':  defineNew.errors && (defineNew.dirty || defineNew.touched) }"
                  required
                />
                <div *ngIf="defineNew.errors && (defineNew.dirty || defineNew.touched)" class="invalid-feedback">
                  <div *ngIf="defineNew.errors.required">{{ defineNewEntityMessage}}</div>
                </div>
              </ng-container>
              <ng-container *ngIf='mode == "edit"'>
                <!-- adds col-large to make it reponsiveness and make width 100% for less than 1200px -->
                <input
                  type="text"
                  class="form-control d-inline w-100"
                  [ngModel]="node.label"
                  (ngModelChange)="updatedLabel=$event"
                />
              </ng-container>
            </div>
          </div>
          <div
            class="row mb-3 pe-3 text-center"
            *ngIf='mode =="add" && allExistingLabels.includes(node.label) && type=="defineNew"'
          >
            {{locale("locale_key.pages.organization.edit_mode.new_data_group_exists")}}
          </div>
          <ng-container *ngIf='mode=="edit" || (mode=="add" && type=="defineNew" && isNodeLabelAdded)'>
            <div class="d-flex flex-row">
              <div class="col-10">
                <label class="mb-0 col">
                  {{mode=="add" ? locale("locale_key.pages.organization.edit_mode.new_data_group_column_name") :
                  locale("locale_key.pages.organization.edit_mode.new_data_group_column_name.desc")}}
                </label>
              </div>
              <div class="col-2">
                <span class="position-absolute" style="right: 0">
                  <div
                    class="custom-control custom-checkbox d-inline"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Show all related enitities"
                    container="body"
                    data-bs-placement="bottom"
                    containerClass="tooltipClass"
                    *ngIf='parentNodeLabels.length>0 && parentNode?.key != "PARENT_NODE";'
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      [ngModel]="showRef"
                      (click)="toggleShowRef()"
                      id="showRef"
                    />
                    <label for="showRef" class="custom-control-label"></label>
                  </div>

                  <a class="float-end me-3" (click)="changeFormat('table')">
                    <i
                      class="la la-table"
                      tooltip='{{mode == "add" ? locale("locale_key.pages.organization.edit_mode.tooltip.define") : locale("locale_key.pages.organization.edit_mode.tooltip.see_as_table_format")}} {{locale("locale_key.pages.organization.edit_mode.tooltip.table_format")}}'
                      container="body"
                      placement="bottom"
                      containerClass="tooltipClass"
                      style="font-size: 22px"
                    ></i>
                  </a>
                  <a class="float-end" (click)="changeFormat('form')">
                    <i
                      class="la la-file-text"
                      tooltip='{{mode == "add" ? locale("locale_key.pages.organization.edit_mode.tooltip.define") : locale("locale_key.pages.organization.edit_mode.tooltip.see_as_table_format")}} {{locale("locale_key.pages.organization.edit_mode.tooltip.form_format")}}'
                      container="body"
                      placement="bottom"
                      containerClass="tooltipClass"
                      style="font-size: 18px; top: 1px; position: relative"
                    ></i>
                  </a>
                </span>
              </div>
            </div>
            <hr class="mt-0" />
            <!-- remove container temporary -->
            <div class="box" *ngIf="format == 'form'">
              <div class="row">
                <label class="form-label">{{node.label}}</label>
              </div>
              <form [formGroup]="columns">
                <!-- adds col-5 temporary -->
                <div
                  class="d-flex flex-row col-5 mb-3"
                  [formGroup]="col"
                  *ngFor="let col of getControl();let i =index; let isLast=last; let isFirst=first"
                  style="padding-left: 2px"
                >
                  <input
                    class="form-control col-5 me-3"
                    formControlName="name"
                    placeholder="Column Name"
                    (input)="validate()"
                    [ngClass]="{ 'is-invalid':  col.get('name').errors && (col.get('name').dirty || col.get('name').touched) }"
                    required
                  />
                  <div
                    *ngIf="col.get('name').errors && (col.get('name').dirty || col.get('name').touched)"
                    class="invalid-feedback"
                  >
                    <div class="ps-2" *ngIf="col.get('name').errors.required">{{ columnNameMessage}}</div>
                  </div>
                  <select
                    id="type"
                    class="form-select form-control col-5 me-2"
                    formControlName="type"
                    [ngClass]="{ 'is-invalid':  col.get('type').errors && (col.get('type').dirty || col.get('type').touched) }"
                    required
                  >
                    <option value="STRING">{{locale('locale_key.general.data_type.text')}}</option>
                    <option value="NUMERIC">{{locale('locale_key.general.data_type.number')}}</option>
                    <option value="DATE">{{locale('locale_key.general.data_type.date')}}</option>
                    <option value="BOOLEAN">{{locale('locale_key.general.data_type.boolean')}}</option>
                  </select>
                  <div
                    *ngIf="col.get('type').errors && (col.get('type').dirty || col.get('type').touched)"
                    class="invalid-feedback"
                  >
                    <div class="p3-4" *ngIf="col.get('type').errors.required">{{ columnTypeMessage}}</div>
                  </div>
                  <div class="d-flex flex-row col-1 mt-1">
                    <a (click)="addNewCol(i)" *ngIf="isLast">
                      <i
                        class="las la-plus-square mt-1 mx-0"
                        style="font-size: 1.5rem; color: #374659"
                        tooltip='{{locale("locale_key.pages.organization.edit_mode.tooltip.add_new_column")}}'
                        container="body"
                        placement="bottom"
                        containerClass="tooltipClass"
                      ></i>
                    </a>
                    <a (click)="delCol(i)" *ngIf="totalCols>1 || !isFirst">
                      <i
                        class="las la-minus-square mt-1 mx-0"
                        style="font-size: 1.5rem; color: #374659"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        title='{{locale("locale_key.pages.organization.edit_mode.tooltip.delete_column")}}'
                        container="body"
                        containerClass="tooltipClass"
                      ></i>
                    </a>
                  </div>
                </div>
              </form>
              <ng-container *ngIf="showRef">
                <ng-container *ngFor="let parent of dataGrid.referencedTables">
                  <ng-container *ngIf='parent.key!="PARENT_NODE"'>
                    <div class="row">
                      <div class="col-12">{{parent.name}}</div>
                    </div>
                    <div class="row mb-3" *ngFor="let col of parent.dataSchema;let i =index" style="padding-left: 2px">
                      <input class="form-control col-5 ms-3" [value]="col.label" disabled />
                      <select
                        id="type"
                        class="form-select form-control col-5 ms-3"
                        [value]="col.type"
                        [disabled]="true"
                      >
                        <option value="STRING">
                          {{locale('locale_key.pages.organization.edit_node.data_type.text')}}
                        </option>
                        <option value="NUMERIC">
                          {{locale('locale_key.pages.organization.edit_node.data_type.number')}}
                        </option>
                        <option value="DATE">
                          {{locale('locale_key.pages.organization.edit_node.data_type.time')}}
                        </option>
                        <option value="BOOLEAN">
                          {{locale('locale_key.pages.organization.edit_node.data_type.boolean')}}
                        </option>
                      </select>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div class="row mb-3" *ngIf="format =='table'">
              <p class="col">{{locale('locale_key.pages.organization.edit_mode.add_remove_columns')}}</p>
              <datagrid-table-editor [dataGrid]="dataGrid" class="col-12"></datagrid-table-editor>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
      {{locale('locale_key.general.buttons.cancel')}}
    </button>
    <button
      type="submit"
      class="btn btn-add custom-button"
      *ngIf="type=='defineNew' && mode=='add'"
      [disabled]='(!isNodeLabelAdded && node.label == "" && allExistingLabels.includes(node.label)) || 
      (isNodeLabelAdded && (format == "form" ? !allColumnsHaveName : dataGrid.database.dataSchema.length == 0)) || node.label == ""'
      (click)="isNodeLabelAdded ? editNode() : addNodeLabel()"
    >
      {{locale("locale_key.general.buttons.add")}}
    </button>
    <button type="submit" class="btn btn-add custom-button" *ngIf='type!="defineNew"' (click)="editNode()">
      {{mode == "add" ? locale("locale_key.general.buttons.add") : locale("locale_key.general.buttons.save")}}
    </button>
  </div>
</div>
