import { EmailTemplateFe } from './EmailTemplateFe'

export class EmailCategoryFe {
  key: string
  category: string
  emails: EmailTemplateFe[]

  constructor(category: string, emails: EmailTemplateFe[]) {
    this.key = category.toLowerCase().replaceAll(' ', '_')
    this.category = category
    this.emails = emails
  }

  public static async fromTransfer(transfer: any): Promise<EmailCategoryFe> {
    const emails = transfer.emails || []
    const emailArrayPromise: Promise<EmailTemplateFe>[] = emails.map((e) => EmailTemplateFe.fromTransfer(e))
    const emailArray = await Promise.all(emailArrayPromise)
    return new EmailCategoryFe(transfer.category, emailArray)
  }
}
