import {
  SchedulingOccurenceGeneratorFe,
  SchedulingOccurenceGeneratorFromTypes
} from '../../scheduling/SchedulingOccurenceGeneratorFe'
import { QuestionnaireFe } from '../request/questionnaire/QuestionnaireFe'
import { RequestGroupFe } from '../request/RequestGroupFe'
import { AbstractActivityFe } from '../timeline/AbstractActivityFe'

export class TASK_STATUS {
  public static CREATED = 'CREATED'
  public static IN_PROGRESS = 'IN_PROGRESS'
  public static APPROVED = 'APPROVED'
  public static CLOSED = 'CLOSED'
}

export class TaskFe {
  id: string
  title: string
  requestGroupId: string
  description: string | null
  date: Date
  dueDate: Date | null
  requesterAffId: string
  requesterFirstName: string
  requesterLastName: string
  requesterCompanyId: string
  requesterCompanyName: string
  managementCompanyId: string
  managementCompanyName: string
  assigneeAffId: string
  assigneeFirstName: string
  assigneeLastName: string
  status: string
  readStatus: string
  questionnaire: QuestionnaireFe
  timelineItems: AbstractActivityFe[]

  requestGroup: RequestGroupFe

  constructor(
    id: string,
    requestGroupId: string,
    title: string,
    description: string | null,
    date: Date,
    dueDate: Date | null,
    requesterAffId: string,
    requesterFirstName: string,
    requesterLastName: string,
    requesterCompanyId: string,
    requesterCompanyName: string,
    managementCompanyId: string,
    managementCompanyName: string,
    assigneeAffId: string,
    assigneeFirstName: string,
    assigneeLastName: string,
    status: string,
    readStatus: string,
    questionnaire: QuestionnaireFe
  ) {
    this.id = id
    this.requestGroupId = requestGroupId
    this.title = title
    this.description = description
    this.date = date ? new Date(date) : null
    this.dueDate = dueDate ? new Date(dueDate) : null
    this.requesterAffId = requesterAffId
    this.requesterFirstName = requesterFirstName
    this.requesterLastName = requesterLastName
    this.requesterCompanyId = requesterCompanyId
    this.requesterCompanyName = requesterCompanyName
    this.managementCompanyId = managementCompanyId
    this.managementCompanyName = managementCompanyName
    this.assigneeAffId = assigneeAffId
    this.assigneeFirstName = assigneeFirstName
    this.assigneeLastName = assigneeLastName
    this.status = status
    this.readStatus = readStatus
    this.questionnaire = questionnaire
    this.timelineItems = null
  }

  public static fromTransfer(transfer: any): TaskFe {
    const taskFe = new TaskFe(
      transfer.id,
      transfer.requestGroupId,
      transfer.title,
      transfer.description,
      transfer.date,
      transfer.dueDate,
      transfer.requesterAffId,
      transfer.requesterFirstName,
      transfer.requesterLastName,
      transfer.requesterCompanyId,
      transfer.requesterCompanyName,
      transfer.managementCompanyId,
      transfer.managementCompanyName,
      transfer.assigneeAffId,
      transfer.assigneeFirstName,
      transfer.assigneeLastName,
      transfer.status,
      transfer.readStatus,
      QuestionnaireFe.fromTransfer(transfer.questionnaire)
    )
    return taskFe
  }

  getTitle(): string {
    return this.requestGroup.getTitle()
  }

  getStatusTextKeyword() {
    switch (this.status) {
      case TASK_STATUS.CREATED:
        return 'locale_key.general.state.sent'
      case TASK_STATUS.APPROVED:
        return 'locale_key.general.state.completed'
      case TASK_STATUS.IN_PROGRESS:
        return 'locale_key.general.state.in_progress'
      case TASK_STATUS.CLOSED:
        return 'locale_key.general.state.closed'
    }
  }

  getStatusClass() {
    switch (this.status) {
      case TASK_STATUS.CREATED:
        return 'badge-turquoise'
      case TASK_STATUS.APPROVED:
        return 'badge-green'
      case TASK_STATUS.IN_PROGRESS:
        return 'badge-orange'
      case TASK_STATUS.CLOSED:
        return 'badge-gray'
    }
  }

  getRequesterName() {
    return `${this.requesterFirstName} ${this.requesterLastName}`
  }
}
