export const environment = {
  production: false,
  googleIdentityPlatform: {
    masterTenantId: 'sustainlab-aq0h4',
    firebaseApiKey: 'AIzaSyBT9YCH9-PtyqyPyUXaQ5NWn6N-6eHP8RA',
    firebaseAuthDomain: 'sustainlab-staging.firebaseapp.com'
  },
  passwordRotationNofDays: 90,
  pulseFreqSec: 10 * 1000
}
