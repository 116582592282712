import { Injectable } from '@angular/core'
import { UnitFe } from '../components/unit-systems/model/UnitFe'
import { UnitMeasurementTypes } from '../components/unit-systems/model/UnitMeasurementType'
import { DepGraph } from 'dependency-graph'
import { all, create } from 'mathjs'
import _ from 'lodash'
import { StateServiceFe } from './StateServiceFe'

@Injectable({
  providedIn: 'root'
})
export class UnitServiceFe {
  constructor() {}

  getNoUnit() {
    const noUnit = new UnitFe({
      name: 'No unit',
      symbol: '',
      definition: '',
      offset: '',
      aliases: '',
      baseName: '',
      description: '',
      isCustom: true,
      isConvertible: false,
      isSI: false,
      isMetric: false,
      isUSCustomary: false,
      isImperial: false,
      isOther: true,
      measurementType: UnitMeasurementTypes.NO_UNIT,
      shouldDisplay: true,
      isStandard: true
    })

    return noUnit
  }

  isValidUnitName(name: string) {
    // "unit" is a keyword, should not be allowed as symbol
    if (name.toLowerCase().trim() == 'unit') {
      return false
    }

    //Should start with an alpha character
    const startChar = name.charAt(0)
    if (!/^[a-zA-Z]$/.test(startChar)) {
      return false
    }

    //Should only be alphanumeric characters
    if (!/^[a-zA-Z0-9]+$/.test(name)) {
      return false
    }

    return true
  }

  generateUnitEvaluator({ units }) {
    const evaluater = create(all, {
      number: 'BigNumber'
    })

    const customUnits = {}
    const customUnitsDepGraph = new DepGraph()
    units.forEach((unit) => {
      // filter out none custom units
      // TO DO : filter out compound units
      if (!unit.isCustom || _.isEmpty(unit.symbol)) return

      // add node to dep graph
      customUnitsDepGraph.addNode(`${unit.symbol}`)

      // TO DO : update for unit prefixes
      customUnits[`${unit.symbol}`] = {
        definition: unit.definition
      }
    })
    // build dependency tree
    const customUnitSymbols = Object.keys(customUnits)
    customUnitSymbols.forEach((defSymbol) => {
      const definition = customUnits[defSymbol].definition
      customUnitSymbols.forEach((checkSymbol) => {
        if (definition.includes(checkSymbol)) {
          customUnitsDepGraph.addDependency(defSymbol, checkSymbol)
        }
      })
    })

    const orderToCreateUnits = customUnitsDepGraph.overallOrder()
    orderToCreateUnits.forEach((symbol) => {
      const definition = customUnits[symbol]
      try {
        evaluater.createUnit(symbol, definition)
      } catch (err) {}
    })

    return evaluater
  }

  getUnit({ symbol: rawSymbol, units }) {
    let symbol = rawSymbol
    //Incase symbol is a prefixed unit, we need to get the base unit symbol
    const evaluator = this.generateUnitEvaluator({ units })
    try {
      const exp = `1 ${symbol}`
      const baseSymbol = evaluator.evaluate(exp).units[0].unit.name
      symbol = baseSymbol
    } catch (err) {}

    const checkFound = ({ unit, symbol }) => {
      let found = false
      let foundUnit

      if (unit.symbol == symbol) {
        found = true
        foundUnit = unit
      }
      const aliases = unit.aliases.split(',')
      aliases.forEach((alias) => {
        if (alias.trim() == symbol) {
          found = true
          foundUnit = unit
        }
      })
      return { found, foundUnit }
    }

    let foundUnit: UnitFe
    units.forEach((unit) => {
      const mainResult = checkFound({ unit, symbol })
      if (mainResult.found) {
        foundUnit = mainResult.foundUnit
      }
      if (unit.hasPrefixes) {
        unit.prefixList.forEach((prefixedUnit) => {
          const prefixedResult = checkFound({ unit: prefixedUnit, symbol })
          if (prefixedResult.found) {
            foundUnit = prefixedResult.foundUnit
          }
        })
      }
    })

    return foundUnit
  }

  isCompound(unit: UnitFe) {
    let isCompound = false
    if (unit.isCompound) {
      isCompound = true
    }
    if (!_.isEmpty(unit.leftSymbol) && !_.isEmpty(unit.rightSymbol)) {
      isCompound = true
    }
    return isCompound
  }

  generateMTCode({ unit, units }: { unit: UnitFe; units: UnitFe[] }) {
    let MTCode = 'MTCODE'
    if (!this.isCompound(unit)) {
      MTCode = `${unit.measurementType}`
    } else {
      const leftUnit = this.getUnit({ symbol: unit.leftSymbol, units })
      const rightUnit = this.getUnit({ symbol: unit.rightSymbol, units })

      const leftMTCode = this.generateMTCode({ unit: leftUnit, units })
      const rightMTCode = this.generateMTCode({ unit: rightUnit, units })

      MTCode = `${leftMTCode}/${rightMTCode}`
    }

    return MTCode
  }
}
