import { RouterFe } from 'src/app/route/RouterFe'
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { StageTableDataGridIntergationService } from './StageTableDataGridIntergationService'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { DirectEntryInfoFe } from 'src/app/model/directEntry/DirectEntryInfoFe'
import { StageTableInfoSubjectFe } from 'src/app/model/subject/StageTableInfoSubjectFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { RowStatusFe } from 'src/app/model/schema/RowStatusFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode'

@Component({
  selector: 'app-data-portal',
  templateUrl: './data-portal.component.html',
  styleUrls: ['./data-portal.component.scss']
})
export class DataPortalComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('initStageTableModal', { static: true }) initStageTableModal: TemplateRef<any>
  public modalRef: BsModalRef
  public taxonomyInfo: TaxonomyInfoFe
  public dataEntities: EntityFe[] = []
  public stageTableInfos: DirectEntryInfoFe[] = []
  public isCollapsed: boolean[] = []
  public inProgress = false
  public isPagination = true
  public toEditStageTableInfo: DirectEntryInfoFe
  public toCopyStageTableInfo: DirectEntryInfoFe
  public toCopyStageTableService: StageTableDataGridIntergationService
  isDataExtractor = false
  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode
  tableConnections: { [key: string]: boolean } = {}

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.data'),
        longLabel: this.locale('locale_key.pages.direct_data_entries.tooltip.add_data_entry'),
        tooltip: this.locale('locale_key.pages.direct_data_entries.tooltip.add_data_entry'),
        icon: 'la la-plus',
        actionName: 'add_data',
        visible: () => !this.activeTable() && this.homeActive(),
        disabled: false
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.add'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.connect'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.connect'),
        icon: 'ft-cloud',
        actionName: 'connect_to_pipeline',
        visible: () => this.getButtonVisibility('connect'),
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.remove'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.disconnect'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.disconnect'),
        icon: 'ft-cloud-off',
        actionName: 'disconnect_from_pipeline',
        visible: () => this.getButtonVisibility('disconnect'),
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.extract'),
        longLabel: this.locale('locale_key.general.buttons.extract_from_file'),
        tooltip: this.locale('locale_key.general.buttons.extract_from_file'),
        icon: 'ft-file-plus',
        actionName: 'open_file_extractor',
        visible: () => this.getButtonVisibility('extract'),
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.save'),
        longLabel: this.locale('locale_key.general.buttons.save_changes'),
        tooltip: this.locale('locale_key.general.buttons.save_changes'),
        icon: 'la la-save',
        actionName: 'save_changes',
        visible: () => this.getButtonVisibility('save'),
        disabled: () => this.getButtonDisabled('save')
      },
      {
        shortLabel: this.locale('locale_key.general.buttons.export'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.export_data_source'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.export_data_source'),
        icon: 'ft-download',
        actionName: 'export_data_source',
        visible: () => this.getButtonVisibility('export'),
        disabled: false
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info',
        actionName: 'toggle_quick_tips',
        visible: () => true
      }
    ]
  ]

  myOptions = {
    placement: 'right',
    showDelay: 100,
    zIndex: 99999
  }

  constructor(
    private modalService: BsModalService,
    private alertService: AlertServiceFe,
    private ErrorsFe: ErrorsFe,
    private backendService: RouterFe,
    private stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    stateService.stageTableInfoSubject.subscribe(async (subject: StageTableInfoSubjectFe) => {
      this.loadCompanyData()
    })
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    await this.loadCompanyData()
    if (this.stageTableInfos.length == 0) {
      this.displayService.openTips()
      this.displayService.updateQuickTipsData({ viewMode: null, page: 'data' })
    }
  }

  public async loadCompanyData() {
    this.inProgress = true
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
    this.stageTableInfos = await this.stateService.getStageTableInfos()
    let entityKeys = this.stageTableInfos.map((sdl) => sdl.taxonomyKey)
    entityKeys = entityKeys.filter((e) => e != undefined)
    this.dataEntities = this.taxonomyInfo.rootParentEntities(entityKeys)
    this.inProgress = false
  }

  public closeTab(stageTable: DirectEntryInfoFe) {
    stageTable.isActive = false
    stageTable.isOpen = false
  }

  public homeActive(): boolean {
    let tableActive = this.stageTableInfos.find((st) => st.isActive)
    return tableActive ? false : true
  }

  viewHome() {
    this.stageTableInfos.forEach((st) => {
      st.isActive = false
    })
    this.displayService.updateQuickTipsData({ viewMode: null, page: 'data' })
  }

  async viewTable(index) {
    let stageTable = this.stageTableInfos[index]
    if (!stageTable.hasContent()) {
      stageTable.loadInProgress = true
      let entity = this.taxonomyInfo.entityByKey(stageTable.taxonomyKey)
      let dataGridService = new StageTableDataGridIntergationService(
        stageTable,
        entity,
        this.backendService,
        this.languageService
      )
      await dataGridService.loadMainTablePage()
      stageTable.dataGridService = dataGridService
      this.stageTableInfos.forEach((st) => (st.isActive = false))
      stageTable.loadInProgress = false
      stageTable.isActive = true
      stageTable.isOpen = true
    } else {
      stageTable.isOpen = true
      this.stageTableInfos.forEach((f) => (f.isActive = false))
      stageTable.isActive = true
    }

    this.displayService.updateQuickTipsData({ viewMode: 'table_viewer', page: 'data' })
  }

  async connecToPipeline(stageTable: DirectEntryInfoFe) {
    try {
      await this.stateService.connecStageTableToPipeline(stageTable)
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error)
      if (
        knownError &&
        (knownError == this.ErrorsFe.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING ||
          knownError == this.ErrorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING)
      ) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(
          'Unfortunately we were unable to connect the table to the pipeline due to some internal reasons! Please try again!'
        )
      }
    }
  }

  async disconnectFromPipeline(stageTable: DirectEntryInfoFe) {
    try {
      await this.stateService.disconnectStageTableFromPipeline(stageTable)
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error)
      if (
        knownError &&
        (knownError == this.ErrorsFe.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING ||
          knownError == this.ErrorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING)
      ) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(
          'Unfortunately we were unable to disconnect the table from the pipeline due to some internal reasons! Please try again!'
        )
      }
    }
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: clazz
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open')
    }
  }

  closeFileExtractor(stageTable: DirectEntryInfoFe) {
    this.isDataExtractor = false
    stageTable.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
  }

  openFileExtractor(stageTable: DirectEntryInfoFe) {
    this.toCopyStageTableInfo = stageTable
    let entity = this.taxonomyInfo.entityByKey(stageTable.taxonomyKey)
    this.toCopyStageTableService = new StageTableDataGridIntergationService(
      stageTable,
      entity,
      this.backendService,
      this.languageService
    )
    this.toCopyStageTableService.hasTablePagination = false
    this.toCopyStageTableService.mode = DataGridTableMode.VIEW
    this.toCopyStageTableService.currentPageData = stageTable.dataGridService.currentPageData
    this.isDataExtractor = true
  }

  selectedFile: FileDataFe

  fileSelected(file: FileDataFe) {
    this.selectedFile = file
  }

  async dataExtracted(newTableData: TableDataFe) {
    newTableData.rows.forEach((row) => (row.status = RowStatusFe.ADDED))
    let stageTable = this.activeTable()
    stageTable.tableData.rows = stageTable.tableData.rows.concat(newTableData.rows)
    await stageTable.dataGridService.observer.renderNewDataTable()
    let isValid = stageTable.dataGridService.observer.checkDataValidity()
    stageTable.dataGridService.markDataModified(newTableData.rows.length > 0, isValid)
    this.closeFileExtractor(stageTable)
  }

  activeTable(): DirectEntryInfoFe {
    let stageTable = this.stageTableInfos.find((st) => st.isActive)
    return stageTable
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  async exportDataSource(stageTable: DirectEntryInfoFe) {
    stageTable.saveInProgress = true
  }

  handleToolbarAction(actionName: string, stageTable: any) {
    switch (actionName) {
      case 'add_data':
        this.openModal(this.initStageTableModal, 'modal-md')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
      case 'connect_to_pipeline':
        this.connecToPipeline(stageTable)
        break
      case 'disconnect_from_pipeline':
        this.disconnectFromPipeline(stageTable)
        break
      case 'open_file_extractor':
        this.openFileExtractor(stageTable)
        break
      case 'save_changes':
        stageTable.dataGridService.saveChages(stageTable)
        break
      case 'export_data_source':
        this.exportDataSource(stageTable)
        break
    }
  }

  getButtonVisibility(buttonType: string) {
    let stageTable = this.getActiveStageTable()

    // Return true for the Info button.
    if (buttonType === 'Info') {
      return true
    }

    // If the tableStage is closed, return true only for the Data button.
    if (!stageTable) {
      return buttonType === 'Data'
    }

    if (!stageTable) {
      return false
    }

    switch (buttonType) {
      case 'connect':
        return this.activeTable() && this.isConnected()
      case 'disconnect':
        return this.activeTable() && !this.isConnected()
      case 'extract':
        return this.activeTable()
      case 'save':
        return this.activeTable()
      case 'export':
        return this.activeTable()
      default:
        return false
    }
  }

  getButtonDisabled(buttonType: string) {
    let stageTable = this.getActiveStageTable()
    if (!stageTable) return false

    switch (buttonType) {
      case 'save':
        return !(
          stageTable.dataGridService.modified &&
          stageTable.dataGridService.isDataValid &&
          !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress)
        )
      default:
        return false
    }
  }

  getActiveStageTable() {
    for (let stageTable of this.stageTableInfos) {
      if (stageTable.dataGridService && !stageTable.loadInProgress && stageTable.isActive) {
        return stageTable
      }
    }
    return this.stageTableInfos[0]
  }

  isTableStageClosed(): boolean {
    // Check if all stageTableInfos are closed or if there are no stageTableInfos.
    return this.stageTableInfos.length === 0 || this.stageTableInfos.every((stageTable) => !stageTable.isOpen)
  }

  updateButtonVisibility() {
    const isHomeActive = this.homeActive()
    this.pageToolbar.forEach((toolbarGroup) => {
      toolbarGroup.forEach((toolbarButton) => {
        if (toolbarButton.shortLabel === 'Data' || toolbarButton.shortLabel === 'Info') {
          toolbarButton.visible = () => isHomeActive
        } else {
          toolbarButton.visible = () => !isHomeActive
        }
      })
    })
  }

  isConnected(): boolean {
    let stageTable = this.getActiveStageTable()
    return stageTable.statusLocaleKey() === 'locale_key.general.state.added' ? true : false
  }
}
