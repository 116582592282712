<div
  class="d-flex flex-column h-100"
  *ngIf="!answerMode"
  style="max-width: 100% !important; box-sizing: border-box !important"
>
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row" style="height: var(--pages-header-footer-height)">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <div class="">
          <nav>
            <ul class="nav">
              <li class="navbar-item">
                <a>
                  <h3 class="">
                    {{ locale('locale_key.pages.data_request.create_wizard.title') }}
                    <span *ngIf="viewNo == 2">> {{ selectedTask.getTitle() }}</span>
                  </h3>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill" style="max-width: 100% !important; box-sizing: border-box !important">
    <div
      class="d-flex flex-row"
      style="
        max-width: 100% !important;
        box-sizing: border-box !important;
        height: var(--page-content-minus-1-page-header-footer);
      "
    >
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill w-100 mx-auto" style="max-width: 100% !important; box-sizing: border-box !important">
        <div class="d-flex h-100 flex-row w-100 mx-auto">
          <div class="d-flex flex-column w-100 card ms-0 ps-0 me-2 mx-auto">
            <ng-container *ngIf="viewNo == 1; else secondView">
              <div class="col-12 pe-0 ms-0 ps-0" style="max-width: 100% !important; box-sizing: border-box !important">
                <div class="mx-auto w-100 my-3 px-3 pt-1">
                  <div class="row">
                    <div class="col-md-6 col-lg-3 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{ locale('locale_key.general.search') }}"
                        #search
                        (input)="applySearchFilter(search.value)"
                      />
                    </div>
                    <div class="col-lg-6 mb-3 btn-group" dropdown container="body" [insideClick]="true">
                      <button
                        type="button"
                        class="form-control filter-button"
                        id="filter-button"
                        dropdownToggle
                        aria-controls="filter-dropdown-menu"
                        style="text-align: left"
                      >
                        <p class="d-inline m-0">
                          <i class="la la-filter"></i>
                          {{ locale('locale_key.pages.datahub.filter_dropdown') }}
                        </p>
                        <p
                          class="text-muted filter-text text-truncate col-7 m-0"
                          [title]="getCustomFilterText()"
                          *ngIf="customMultipleFilters.length > 0 || selectedStatus"
                        >
                          :{{ getCustomFilterText() }}
                        </p>
                      </button>
                      <div
                        class="ms-3 dropdown-menu"
                        id="filter-dropdown-menu"
                        *dropdownMenu
                        role="menu"
                        aria-labelledby="filter-button"
                        [ngClass]="{ 'width-dropdown': screenSize.isXSmallSize() }"
                      >
                        <div class="accordion-body px-0 pt-0">
                          <div class="bg-light-gray col pt-3">
                            <div class="d-flex flex-row justify-content-between px-2">
                              <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                              <a class="underline" (click)="clearAllFilters()">
                                {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                              </a>
                            </div>
                            <div class="row px-2">
                              <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">
                                {{ locale('locale_key.pages.datahub.filter_none') }}
                              </i>
                              <p
                                class="text-muted text-truncate"
                                *ngIf="customMultipleFilters.length > 0 || selectedStatus"
                              >
                                {{ getCustomFilterText() }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                            <div class="d-flex" [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  [(ngModel)]="selectedStatus"
                                  (change)="applyFilters()"
                                  name="status"
                                  value="CREATED"
                                  id="sent"
                                />
                                <label class="form-check-label" for="sent">
                                  {{ locale('locale_key.pages.datahub.do_submissions.filter.show_requests_sent') }}
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  [(ngModel)]="selectedStatus"
                                  (change)="applyFilters()"
                                  name="status"
                                  value="APPROVED"
                                  id="completed"
                                />
                                <label class="form-check-label" for="completed">
                                  {{ locale('locale_key.pages.datahub.do_submissions.filter.show_completed_requests') }}
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  [(ngModel)]="selectedStatus"
                                  (change)="applyFilters()"
                                  name="status"
                                  value="IN_PROGRESS"
                                  id="inProgress"
                                />
                                <label class="form-check-label" for="inProgress">
                                  {{
                                    locale('locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests')
                                  }}
                                </label>
                              </div>
                            </div>
                            <div class="d-flex flex-row">
                              <a class="underline" (click)="selectedStatus = null; applyFilters()">
                                {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                              </a>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="accordion" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                                      {{ locale('locale_key.general.user_role.sustainability_managers') }}
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="collapseTwo"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div class="accordion-body scroll" style="max-height: 20rem">
                                    <div class="row">
                                      <div class="col-md-6 col-lg-6 mb-3">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="{{ locale('locale_key.general.search') }}"
                                          (input)="filterSMs(contactSearch.value)"
                                          #contactSearch
                                        />
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-3 text-end">
                                        <a class="underline me-2" (click)="selectAll('contactCheckbox')">
                                          {{ locale('locale_key.general.action.select_all') }}
                                        </a>
                                        <a class="underline" (click)="deselectAll('contactCheckbox')">
                                          {{ locale('locale_key.general.action.deselect_all') }}
                                        </a>
                                      </div>
                                    </div>
                                    <div *ngFor="let contact of filteredSMs; let i = index">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        class="contactCheckbox"
                                        id="company{{ i }}"
                                        [checked]="isCustomFilterSelected(contact)"
                                        (change)="changeCustomFilters($event, contact, true)"
                                      />
                                      <label class="col-4 ps-2">{{ contact.name }}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                                      {{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="collapseThree"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordionExample1"
                                >
                                  <div class="accordion-body scroll" style="max-height: 20rem">
                                    <div class="row">
                                      <div class="col-md-6 col-lg-6 mb-3">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="{{ locale('locale_key.general.search') }}"
                                          (input)="filterCompanies(companySearch.value)"
                                          #companySearch
                                        />
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-3 text-end">
                                        <a class="underline me-2" (click)="selectAll('companyCheckbox')">
                                          {{ locale('locale_key.general.action.select_all') }}
                                        </a>
                                        <a class="underline" (click)="deselectAll('companyCheckbox')">
                                          {{ locale('locale_key.general.action.deselect_all') }}
                                        </a>
                                      </div>
                                    </div>
                                    <div *ngFor="let company of filteredCompanies; let i = index">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        class="companyCheckbox"
                                        id="company{{ i }}"
                                        [checked]="isCustomFilterSelected(company)"
                                        (change)="changeCustomFilters($event, company)"
                                      />
                                      <label class="ms-2">{{ company.name }}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
                      <button
                        type="button"
                        class="form-control"
                        id="group-button"
                        dropdownToggle
                        aria-controls="group-dropdown-menu"
                        style="text-align: left"
                      >
                        {{ locale('locale_key.pages.datahub.filter_sort_by') }}
                        {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
                      </button>
                      <div
                        class="ms-3 dropdown-menu"
                        id="group-dropdown-menu"
                        *dropdownMenu
                        role="menu"
                        aria-labelledby="group-button"
                      >
                        <ng-container *ngFor="let option of sortedByOptions">
                          <div class="dropdown-divider" *ngIf="option.divider"></div>
                          <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="max-width: 100% !important; height: 73vh" class="card px-3 pt-1 border-0 scroll-y">
                  <ng-container *ngFor="let task of filteredTasks; let i = index">
                    <div
                      class="mb-2"
                      [ngClass]="{ 'bg-lightgray': selectedTask == task }"
                      style="max-width: 100% !important; box-sizing: border-box !important"
                    >
                      <div
                        class="p-0 row-design pe-1"
                        style="max-width: 100% !important; box-sizing: border-box !important"
                      >
                        <div
                          class="row mx-auto my-1 py-1"
                          style="max-width: 100% !important; box-sizing: border-box !important"
                        >
                          <div
                            class="col-10 col-lg-4"
                            style="max-width: 100% !important; box-sizing: border-box !important"
                          >
                            <div class="d-flex flex-column justify-content-center h-100">
                              <span class="bold">
                                <i
                                  class="la la-calendar-o"
                                  style="font-size: 1.5rem"
                                  *ngIf="task.requestGroup.isChildRecurring"
                                ></i>
                                <i
                                  class="la la-refresh"
                                  style="position: relative; bottom: 7px; left: -5px"
                                  *ngIf="task.requestGroup.isChildRecurring"
                                ></i>
                                {{ task.getTitle() }}
                              </span>
                              <p class="m-0 p-0 dark-text">
                                {{ task.requesterFirstName + ' ' + task.requesterLastName }}
                              </p>
                              <p class="m-0 p-0 text-muted">
                                {{ task.requesterCompanyName }}
                              </p>
                            </div>
                          </div>
                          <div class="col-2 d-none d-lg-block my-auto">
                            <div class="d-flex flex-column justify-content-center align-center">
                              <span class="text-muted m-auto">
                                {{ locale('locale_key.pages.data_request.title.created') }}
                              </span>
                              <span class="badge m-auto badge-gray">{{ task.date | date: 'dd MMM, yyyy' }}</span>
                            </div>
                          </div>
                          <div class="col-2 my-auto d-none d-lg-block">
                            <div class="d-flex flex-column justify-content-center align-center">
                              <span class="text-muted m-auto">
                                {{ locale('locale_key.pages.data_request.title.deadline') }}
                              </span>
                              <h6 class="badge m-auto" [ngClass]="getDeadline(task)">
                                {{ task.dueDate | date: 'dd MMM, yyyy' }}
                              </h6>
                            </div>
                          </div>
                          <div class="col-2 d-none d-lg-block my-auto">
                            <div class="d-flex flex-column justify-content-center align-center">
                              <h6 class="badge m-auto" [ngClass]="task.getStatusClass()">
                                {{ locale(task.getStatusTextKeyword()) }}
                              </h6>
                            </div>
                          </div>
                          <div class="col-2 text-end my-auto" (click)="selectTask(task)">
                            <span class="my-auto ms-auto cursor">
                              <i
                                class="la la-arrow-right fs-3 thin-border bg-white w-auto p-2"
                                style="border-radius: 5px"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #secondView>
              <answer *ngIf="selectedTask" [task]="selectedTask" (endAnswerMode)="endAnswerMode()"></answer>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
