import { AbstractRecipientInfoFe } from '../add/AbstractRecipientInfoFe'
import { RecipientInfo_ActionFe } from '../add/RecipientInfo_ActionFe'

export class RecipientInfo_ModifyFe extends AbstractRecipientInfoFe {
  companyName: string
  accessType: string
  emailLanguage: string
  includeEnglish: boolean

  constructor(email: string, companyName: string, accessType: string, emailLanguage: string, includeEnglish: boolean) {
    super(email, RecipientInfo_ActionFe.MODIFIED)
    this.companyName = companyName
    this.accessType = accessType
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
  }

  public static fromTransfer(transfer: any): RecipientInfo_ModifyFe {
    let info = new RecipientInfo_ModifyFe(
      transfer.email,
      transfer.companyName,
      transfer.accessType,
      transfer.emailLanguage,
      transfer.includeEnglish
    )
    return info
  }
}
