import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { LoginUserFe } from '../model/org/LoginUserFe'
import { RouterFe } from '../route/RouterFe'
import { RoutesFe } from '../route/RoutesFe'
import { OpenErrorsFe } from '../utils/KNOWN_ERRORS'
import { GoogleIdentityPlatformService, UserCredentialWithCustomClaims } from './GoogleIdentityPlatformService'
import { UserServiceFe } from './UserServiceFe'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LoginServiceFe {
  private static SESSION_TOEKN_KEY = 'sustainlab.studio.token'

  public loginUser: LoginUserFe
  public loggedIn: boolean = false
  public loggedInSubject: Subject<boolean> = new Subject<boolean>()
  private httpClient: HttpClient
  public mfaRequired: boolean = false
  private checkMfa: boolean = true
  private googleUserCreds: UserCredentialWithCustomClaims
  private isPasswordProvider: string = ''

  constructor(
    httpBackend: HttpBackend,
    private router: Router,
    private backendService: RouterFe,
    private userService: UserServiceFe,
    private gcpIp: GoogleIdentityPlatformService
  ) {
    this.httpClient = new HttpClient(httpBackend)
  }

  private setLoggedIn() {
    this.userService.setUserAsAffiliated()
    this.loggedIn = true
    this.loggedInSubject.next(true)
  }

  private setLoggedOut() {
    sessionStorage.removeItem(LoginServiceFe.SESSION_TOEKN_KEY)
    this.loginUser = null
    this.loggedIn = false
    this.loggedInSubject.next(false)
    this.router.navigate([RoutesFe.LOGIN.fullPath()])
  }

  public handleSessionError() {
    this.setLoggedOut()
  }

  public async checkLogin() {
    if (!this.loggedIn) {
      let token = this.loadToken()
      if (token) {
        try {
          let tokenHeader: HttpHeaders = this.getTokenHeader(token)
          let loggedIn = await this.backendService.isLoggedIn(tokenHeader)
          let user = await this.backendService.getUserInfo(tokenHeader)
          this.loginUser = LoginUserFe.fromTransfer(user)
          if (loggedIn) {
            this.setLoggedIn()
          } else {
            this.setLoggedOut()
          }
        } catch (err) {
          this.setLoggedOut()
        }
      } else {
        this.setLoggedOut()
      }
    }
  }

  private getTokenHeader(token: string): HttpHeaders {
    let headers = new HttpHeaders({ Authorization: 'Bearer ' + token })
    return headers
  }

  async redirectToGoogle() {
    await this.gcpIp.signInWithGoogle()
  }

  async redirectToMicrosoft() {
    await this.gcpIp.signInWithMicrosoft()
  }

  async sendVerifcationCode({ phonenumber, mfaBtnID, mfaType, mfaPhoneIdx }) {
    const verificationId = await this.gcpIp.sendVerifcationCode({
      phonenumber,
      mfaBtnID,
      mfaType,
      mfaPhoneIdx
    })
    return verificationId
  }

  async verifyMfaCode({ verificationID, verificationCode, mfaType }) {
    const result = await this.gcpIp.verifyMfaCode({
      verificationID,
      verificationCode,
      mfaType
    })
    return result
  }

  async login({ email, password, provider, loginAction }): Promise<LoginUserFe> {
    this.setLoggedOut()
    this.setProvider(provider)
    try {
      let googleUserCreds: UserCredentialWithCustomClaims
      switch (provider) {
        case 'google':
          googleUserCreds = await this.gcpIp.getRedirectResults()
          break
        case 'microsoft':
          googleUserCreds = await this.gcpIp.getRedirectResults()
          break
        case 'password':
          googleUserCreds = await this.gcpIp.signInWithEmailAndPassword({
            email,
            password
          })
          break
        case 'mfaEnroll':
          this.checkMfa = false
          googleUserCreds = this.googleUserCreds
          break
        case 'mfaAuth':
          this.checkMfa = false
          googleUserCreds = this.gcpIp.getGoogleCreds()
          break
      }

      if (this.checkMfa && googleUserCreds.customClaims.mfaRequired) {
        this.googleUserCreds = googleUserCreds
        throw OpenErrorsFe.MFA_ENROLL_REQUIRED
      }

      this.googleUserCreds = googleUserCreds

      switch (loginAction) {
        case 'login':
        case 'switchWorkspace':
          let userInfo = await this.backendService.login(email, password, googleUserCreds)
          this.loginUser = LoginUserFe.fromTransfer(userInfo)
          sessionStorage.setItem(LoginServiceFe.SESSION_TOEKN_KEY, userInfo.token)
          this.setLoggedIn()
          break

        case 'register':
          //dodge backend login in
          break
      }
      return this.loginUser
    } catch (err) {
      this.setLoggedOut()
      throw err
    }
  }

  async logout() {
    try {
      let userInfo = this.getUserInfo()
      if (userInfo) {
        let tokenHeader = this.getTokenHeader(this.loginUser.token)
        await this.httpClient.get<boolean>('/api/auth/logout', { headers: tokenHeader }).toPromise()
      }
    } catch (err) {}
    this.setLoggedOut()
  }

  loadToken(): string | undefined {
    let token = sessionStorage.getItem(LoginServiceFe.SESSION_TOEKN_KEY)
    return token
  }

  getUserInfo(): LoginUserFe | undefined {
    return this.loginUser
  }

  changeLangauage(langCode: string): void {
    this.loginUser.language = langCode
  }

  changeEmailLangauage(langCode: string): void {
    this.loginUser.emailLanguage = langCode
  }

  getGoogleUserCreds() {
    return this.googleUserCreds
  }

  public async checkPasswordRotation() {
    if (this.isPasswordExpired()) {
      this.router.navigate([RoutesFe.ROTATE_PASS.fullPath()])
    }
  }

  isPasswordExpired(): boolean {
    if (this.loginUser) {
      if (!this.loginUser.passwordUpdateTimeStamp) {
        return true
      }
      const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24
      const currentDate = new Date()
      const pastDate = new Date(this.loginUser.passwordUpdateTimeStamp)
      const differenceInMs = currentDate.getTime() - pastDate.getTime()
      const differenceInDays = differenceInMs / MILLISECONDS_IN_A_DAY
      return differenceInDays > environment.passwordRotationNofDays
    }
    return false
  }

  setProvider(provider) {
    this.isPasswordProvider = provider
  }

  isAuthProviderPassword() {
    return this.isPasswordProvider === 'password'
  }

  updateUserPasswordUpdateTimestamp() {
    this.loginUser.passwordUpdateTimeStamp = new Date()
  }
}
