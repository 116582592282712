<div *ngIf="loadingInProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card px-2 pt-3 fixed-width" *ngIf="!loadingInProgress">
  <p *ngIf="datahubService.isHomeView()" class="h6">
    {{ locale('locale_key.pages.datahub.master_table_portal.info') }}
  </p>
  <div class="row justify-content-between ps-0">
    <div class="col-12 d-flex flex-row justify-content-end pe-3">
      <a class="underline my-auto me-2 cursor" (click)="expandAll()">
        {{ locale('locale_key.pages.kpi_library.button.expand_all') }}
      </a>
      <a class="underline my-auto me-2 cursor" (click)="collapseAll()">
        {{ locale('locale_key.pages.kpi_library.button.collapse_all') }}
      </a>
    </div>
    <div class="col-md-12 col-lg-6 mb-3">
      <input
        type="text"
        class="form-control w-auto me-1"
        placeholder="{{ locale('locale_key.general.search') }}"
        (input)="filter(search.value)"
        #search
      />
    </div>
    <div class="col-md-12 col-lg-6 mb-3 d-flex justify-content-lg-end justify-content-md-start pe-3">
      <div class="btn-group w-auto" dropdown container="body">
        <button
          type="button"
          class="form-control w-auto dark-text"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ locale('locale_key.pages.datahub.filter_sort_by') }}
          {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="dropdown-menu w-auto"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <ng-container *ngFor="let option of sortedByOptions">
            <div class="dropdown-divider" *ngIf="option.divider"></div>
            <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3 scroll" style="max-height: 65vh">
    <div class="mb-3" *ngFor="let item0 of filteredRootParentEntites; let i = index">
      <a
        [ngStyle]="{ 'border-top': '10px solid' + depTaxonomy.color(item0) }"
        class="p-0 m-auto w-100 accordion-button row-design py-3 flex-wrap d-flex flex-row align-content-center accordion accordion-icon-rotate pe-1"
        (click)="isCollapsed[i] = !isCollapsed[i]"
        [id]="'item' + i"
        [ngClass]="{ collapsed: isCollapsed[i] }"
      >
        <div class="col-xl-4 col-lg-4 col-md-4 col-10 d-flex flex-row my-auto text-truncate">
          <i class="{{ depTaxonomy.icon(item0) }} mx-2 pt-1 fs-5" [ngStyle]="{ color: depTaxonomy.color(item0) }"></i>
          <h5 class="dark-text d-inline">{{ item0.getLabel(this.activeLanguage) }}</h5>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7 col-5 d-none d-md-block justify-content-end my-auto">
          <div class="d-flex flex-row justify-content-end h-100 align-content-center">
            <div class="d-flex flex-row justify-content-center align-content-center my-auto">
              <div class="icon-circle-dark me-2 my-auto">
                <span class="text-white">{{ noOfDataCategories(item0.key) }}</span>
              </div>
              <a class="dark-text me-3">
                {{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}
              </a>
            </div>
            <div class="d-flex flex-row justify-content-center align-content-center my-auto">
              <div class="icon-circle-dark me-2 my-auto">
                <span class="text-white">{{ noOfSubDataCategories(item0.key) }}</span>
              </div>
              <a class="dark-text me-3">
                {{ locale('locale_key.pages.data_hub.master_table.sub_categories.title') }}
              </a>
            </div>
          </div>
        </div>
      </a>
      <div [collapse]="isCollapsed[i]" class="collapse border" style="background-color: var(--complementary-color1)">
        <div class="card-content">
          <div class="card-body px-2">
            <div class="m-0 p-0">
              <ng-container *ngFor="let item1 of getParentEntities(item0.key); let i2 = index">
                <div class="mb-3">
                  <div
                    class="row py-3 px-2 mx-auto accordion-button w-100 accordion cursor accordion-icon-rotate bg-white"
                    [ngClass]="isCollapsed2[i][i2] ? 'border collapsed' : 'border-l border-t border-r'"
                    (click)="isCollapsed2[i][i2] = !isCollapsed2[i][i2]"
                  >
                    <div class="col-xl-11 col-lg-11 col-md-11 col-11 d-flex flex-row my-auto ps-0">
                      <span
                        class="bold my-auto text-truncate dark-text h5"
                        [ngStyle]="{ color: depTaxonomy.color(item1) }"
                        *ngIf="!item1.editText"
                        [ngClass]="{ 'ps-1': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                      >
                        {{ item1.getLabel(this.activeLanguage) }}
                      </span>
                    </div>
                  </div>
                  <div [collapse]="isCollapsed2[i][i2]" class="collapse border">
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 bg-white my-auto"
                      [ngClass]="{ 'border-b': !last, selected: selectedEntity == item2 }"
                      (click)="selectEntity(item2)"
                      *ngFor="let item2 of getChildEntities(item1.key); let last = last"
                    >
                      <div class="col-xl-11 col-lg-11 col-md-11 col-10 d-flex flex-row my-auto cursor ps-0">
                        <span
                          class="dark-text my-auto text-truncate"
                          [ngStyle]="{ color: depTaxonomy.color(item2) }"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{ item2.getLabel(this.activeLanguage) }}
                        </span>
                      </div>
                      <div
                        class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-0"
                        *ngIf="!datahubService.isHomeView()"
                      >
                        <div class="btn-group my-auto">
                          <i
                            class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0"
                            (click)="showEntityMasterTable(item2)"
                          ></i>
                          <div
                            class="description-btn d-flex justify-content-center"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="la la-ellipsis-h cursor fs-5 my-auto" style="rotate: 90deg"></i>
                          </div>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-3" type="button" (click)="updateDetailsData(item2)">
                              <i class="la la-info me-1"></i>
                              {{ locale('locale_key.pages.data_hub.master_table.show_table_info') }}
                            </button>
                            <div class="dropdown-divider"></div>
                            <button
                              class="dropdown-item ms-0 ps-3"
                              type="button"
                              *ngIf="!item2?.dataGridService?.downloading"
                              (click)="downloadMasterTable(item2, $event, 'csv')"
                            >
                              <i class="ft-download me-2"></i>
                              {{ locale('locale_key.pages.datahub.master_table_portal.button.download_in_csv') }}
                            </button>
                            <button
                              class="dropdown-item ms-0 ps-3"
                              type="button"
                              *ngIf="!item2?.dataGridService?.downloading"
                              (click)="downloadMasterTable(item2, $event, 'xlsx')"
                            >
                              <i class="ft-download me-2"></i>
                              {{ locale('locale_key.pages.datahub.master_table_portal.button.download_in_xlsx') }}
                            </button>
                            <div
                              class="ball-pulse d-flex justify-content-center loader-primary"
                              *ngIf="item2?.dataGridService?.downloading"
                            >
                              <div style="background-color: var(--main-dark-color1)"></div>
                              <div style="background-color: var(--main-dark-color1)"></div>
                              <div style="background-color: var(--main-dark-color1)"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
