import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import _ from 'lodash'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'emission-factor',
  templateUrl: './emission-factor.component.html',
  styleUrls: ['./emission-factor.component.scss']
})
export class EmissionFactorComponent extends AbstractLanguageComponent {
  @Input() mgmtCompanyId = null
  @Input() isEditable = false
  @Input() selectedEF: AbstractEmissionFactorFe = null
  @Input() insideTable = false
  @Input() showAsEditBtn = false
  @Input() datapointEFUnit: { sourceUnit: string; conversionUnit: string } = null
  @Input() isButtonFullWidth: boolean = false
  @Input() wipeSelectedEF: boolean = false
  @Input() customButtonHtml: boolean = false
  @Input() displayStyle: 'DEFAULT' | 'SOURCE_UNIT' = 'DEFAULT'
  @Input() isDisabled: boolean = false

  @Output() emissionFactorSelected = new EventEmitter<AbstractEmissionFactorFe>()

  constructor(
    public languageService: LanguageService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private displayService: DisplayServiceFe
  ) {
    super(languageService)
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = 'modal-xl') {
    if (this.isDisabled) {
      return
    }
    let config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: className
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  setEmissionFactor(ef: AbstractEmissionFactorFe) {
    if (!this.wipeSelectedEF) {
      this.selectedEF = ef
    }
    this.emissionFactorSelected.emit(ef)
  }

  isEmpty(obj) {
    const isObjEmpty = _.isEmpty(obj)
    return isObjEmpty
  }

  openEfDetailTab() {
    this.displayService.openEfDetailsTab(this.selectedEF)
  }
}
