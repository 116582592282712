import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractSignInMethodFe } from './AbstractSignInMethodFe'
import { AffMicrosoftSignInMethodFe } from './AffMicrosoftSignInMethodFe'
import { CompanyMicrosoftSignInMethodFe } from './CompanyMicrosoftSignInMethodFe'
import { AffGoogleSignInMethodFe } from './GoogleSignInMethodFe'
import { AffPasswordSignInMethodFe } from './PasswordSignInMethodFe'
import { SignInMethodProviderFe } from './SignInMethodProviderFe'

export class SignInMethodConversionFe {
  public static fromTransfer(obj: any, forCompany: boolean = false): AbstractSignInMethodFe {
    if (!obj.hasOwnProperty('isEnabled')) {
      obj.isEnabled = true
    }
    switch (obj.provider) {
      case SignInMethodProviderFe.PASSWORD:
        return AffPasswordSignInMethodFe.fromTransfer(obj)
      case SignInMethodProviderFe.GOOGLE:
        return AffGoogleSignInMethodFe.fromTransfer(obj)
      case SignInMethodProviderFe.MICROSOFT:
        if (forCompany) {
          return CompanyMicrosoftSignInMethodFe.fromTransfer(obj)
        } else {
          return AffMicrosoftSignInMethodFe.fromTransfer(obj)
        }
    }
  }

  public static fromGoogleCreds(googleUserCreds: any): AbstractSignInMethodFe {
    let signedInMethod
    let provider = googleUserCreds.provider || googleUserCreds.providerId
    if (!provider && googleUserCreds._tokenResponse.kind == 'identitytoolkit#VerifyPasswordResponse') {
      provider = SignInMethodProviderFe.PASSWORD
    }
    switch (provider) {
      case SignInMethodProviderFe.GOOGLE:
      case SignInMethodProviderFe.GOOGLE_COM:
        signedInMethod = new AffGoogleSignInMethodFe(googleUserCreds.customClaims.mfaRequired)
        return signedInMethod
      case SignInMethodProviderFe.MICROSOFT:
      case SignInMethodProviderFe.MICROSOFT_COM:
        signedInMethod = new AffMicrosoftSignInMethodFe(
          googleUserCreds.customClaims.mfaRequired,
          googleUserCreds.customClaims.tenantId
        )
        return signedInMethod
      case SignInMethodProviderFe.PASSWORD:
        signedInMethod = new AffPasswordSignInMethodFe(googleUserCreds.customClaims.mfaRequired)
        return signedInMethod
    }
  }
}
