export class CsrdDatapointFe {
  id: string
  description: string
  name: string
  isVoluntary: boolean
  applicationRequirements: string[] | null
  applicationFootnotes: string[] | null
  phaseIn: string | null
  supplementalInfo: string | null
  supplementalReference: string | null
  LegislationLocation: string
  displayConditionIcon: boolean | null
  conditional: string | null
  ordinal: number
  relationToOtherStandards: string | null
  isMandatory: boolean

  constructor(
    id: string,
    description: string,
    name: string,
    isVoluntary: boolean,
    applicationRequirements: string[] | null,
    applicationFootnotes: string[] | null,
    phaseIn: string | null,
    supplementalInfo: string | null,
    supplementalReference: string | null,
    LegislationLocation: string,
    displayConditionIcon: boolean | null,
    conditional: string | null,
    ordinal: number,
    relationToOtherStandards: string | null,
    isMandatory: boolean
  ) {
    this.id = id
    this.description = description
    this.name = name
    this.isVoluntary = isVoluntary
    this.applicationRequirements = applicationRequirements
    this.applicationFootnotes = applicationFootnotes
    this.phaseIn = phaseIn
    this.supplementalInfo = supplementalInfo
    this.supplementalReference = supplementalReference
    this.LegislationLocation = LegislationLocation
    this.displayConditionIcon = displayConditionIcon
    this.conditional = conditional
    this.ordinal = ordinal
    this.relationToOtherStandards = relationToOtherStandards
    this.isMandatory = isMandatory
  }

  public static fromJson(json: any): CsrdDatapointFe {
    const dp = new CsrdDatapointFe(
      json['id'],
      json['description'],
      json['name'],
      json['isVoluntary'],
      json['applicationRequirements'],
      json['applicationFootnotes'],
      json['phaseIn'],
      json['supplementalInfo'],
      json['supplementalReference'],
      json['LegislationLocation'],
      json['displayConditionIcon'],
      json['conditional'],
      json['ordinal'],
      json['relationToOtherStandards'],
      json['isMandatory']
    )
    return dp
  }
}
