import { DataGridColumnType } from './DataGridColumnType'
import { DataGridColumnMode } from './DataGridColumnMode'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { AbstractPredefinedOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/AbstractPredefinedOptionFe'

export class DataGridColumnSchema {
  public name: string | undefined
  public label: string | undefined
  public type: string = DataGridColumnType.STRING
  public mode: string = DataGridColumnMode.NULLABLE
  public status: string | undefined
  public width: string = '10rem'
  public deployed: boolean = false
  public modified: boolean = false
  public isCalculated?: boolean = false
  public emissionFactor?: AbstractEmissionFactorFe
  public unitSymbol?: string | undefined
  public measurementKey?: string | undefined
  public showUnitCol?: boolean = true
  public isPreDetermined?: boolean = true
  public predefinedOptions?: AbstractPredefinedOptionFe[] = []
  public customValuesAllowedWhenPredefinedOptionsSet?: boolean
  public userQuestion?: string | undefined
  public userQuestionDescription?: string | undefined

  //if deployed and not modified then status is 'STORED'
  //if deployed and modified then status is 'EDITED'
  //if not deployed then status is 'ADDED'
}
