import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import _ from 'lodash'
import {
  Calculation,
  CalculationCondition,
  CalculationCriterion,
  Comparator,
  ConditionComparators
} from '../model/CalculationBuilderModels'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'conditions-builder',
  templateUrl: './conditions-builder.component.html',
  styleUrls: ['./conditions-builder.component.scss']
})
export class ConditionsBuilderComponent
  extends AbstractLanguageComponent
  implements OnInit, AfterViewChecked, AfterViewInit
{
  @Input() showEditMode: boolean = true
  @Input() selectedDatapoint: TaxonomyAttributeFe | null
  @Input() selectedDataCategory
  @Input() level3DataCategory: EntityFe
  @Input() calculation: Calculation
  @Input() showConditionErrors: boolean = false
  @Output() updateHasConditionErrors = new EventEmitter<boolean>()
  conditionErrorMsgs = new Set()
  hasConditionErrors = false

  constructor(public languageService: LanguageService) {
    super(languageService)
  }

  ngOnInit(): void {}

  private positionLines() {
    document.querySelectorAll('.condition_container').forEach((conditionContainer, idx) => {
      const conditionalContainerFallbackId = `condCont_${idx}`
      let conditionalContainerId = conditionContainer.id
      if (!conditionalContainerId) {
        conditionContainer.id = conditionalContainerFallbackId
        conditionalContainerId = conditionalContainerFallbackId
      }

      const conditionPillContainer = conditionContainer.querySelector<HTMLElement>('.condition_pill_container')

      const firstCriteria = document.querySelector<HTMLElement>(
        `#${conditionalContainerId} > .rules_container > *:first-child`
      )
      const firstCriteriaHeight = firstCriteria?.offsetHeight

      const secondCriteria = document.querySelector<HTMLElement>(
        `#${conditionalContainerId} > .rules_container > *:nth-child(2)`
      )
      const secondCriteriaHeight = secondCriteria?.offsetHeight

      // position and size horizontal top right line
      const horizontalTopRightLine = conditionContainer.querySelector<HTMLElement>('.horizontal.top.right.line')
      if (horizontalTopRightLine) {
        const horizontalTopRightLineTop = firstCriteriaHeight / 2 - 1
        const horizontalTopRightLineWidth = conditionPillContainer.offsetWidth / 2 - 1
        horizontalTopRightLine.style.top = `${horizontalTopRightLineTop}px`
        horizontalTopRightLine.style.width = `${horizontalTopRightLineWidth}px`
      }

      // position and size vertical top line
      const verticalTopLine = conditionContainer.querySelector<HTMLElement>('.vertical.top.line')
      if (verticalTopLine) {
        const verticalTopLineTop = firstCriteriaHeight / 2
        const verticalTopLineHeight = conditionPillContainer.offsetHeight / 2 - firstCriteriaHeight / 2
        verticalTopLine.style.top = `${verticalTopLineTop}px`
        verticalTopLine.style.height = `${verticalTopLineHeight}px`
      }

      // position and size horizontal bottom right line
      const horizontalBottomRightLine = conditionContainer.querySelector<HTMLElement>('.horizontal.bottom.right.line')
      if (horizontalBottomRightLine) {
        const horizontalBottomRightLineBottom = secondCriteriaHeight / 2 - 2
        const horizontalBottomRightLineWidth = conditionPillContainer.offsetWidth / 2 - 1
        horizontalBottomRightLine.style.bottom = `${horizontalBottomRightLineBottom}px`
        horizontalBottomRightLine.style.width = `${horizontalBottomRightLineWidth}px`
      }

      // position and size vertical bottom line
      const verticalBottomLine = conditionContainer.querySelector<HTMLElement>('.vertical.bottom.line')
      if (verticalBottomLine) {
        const verticalBottomLineBottom = secondCriteriaHeight / 2 - 2
        const verticalBottomLineHeight = conditionPillContainer.offsetHeight / 2 - secondCriteriaHeight / 2
        verticalBottomLine.style.bottom = `${verticalBottomLineBottom}px`
        verticalBottomLine.style.height = `${verticalBottomLineHeight}px`
      }
    })
  }

  ngAfterViewInit() {
    //position lines atleast once
    this.positionLines()
  }

  ngAfterViewChecked() {
    //TO DO : Only position lines if conditions tree changes
    this.positionLines()
    this.checkConditionForErrors()
  }

  private checkConditionForErrors() {
    this.conditionErrorMsgs.clear()

    if (_.isEmpty(this.calculation.condition)) {
      this.calculation.condition = new CalculationCondition({
        comparator: null,
        criteria: null
      })
    }

    this.calculation.condition.hasError = false

    if (this.isEmptyCondition()) {
      this.calculation.condition.hasError = true
      this.conditionErrorMsgs.add(this.locale('locale_key.pages.emission.please_add_a_condition'))
    }
  }

  hasConditionError() {
    if (_.isEmpty(this.calculation.condition)) {
      this.calculation.condition = new CalculationCondition({
        comparator: null,
        criteria: null
      })
    }
    return this.calculation.condition.hasError
  }

  createCondition() {
    if (this.selectedDatapoint) {
      this.selectedDatapoint.isEdited = true
    }
    const criterion = new CalculationCriterion()
    const condition = new CalculationCondition({
      comparator: ConditionComparators.NONE_COMPARATOR,
      criteria: [criterion]
    })
    this.calculation.condition = condition
  }

  wrapCondition({ comparator }: { comparator: Comparator }) {
    if (this.selectedDatapoint) {
      this.selectedDatapoint.isEdited = true
    }
    const criterion = new CalculationCriterion()

    if (this.calculation.condition.criteria.length == 1) {
      this.calculation.condition.comparator = comparator
      this.calculation.condition.criteria.push(criterion)
      return
    }

    if (this.calculation.condition.criteria.length == 2) {
      const replacementCondition = _.cloneDeep(this.calculation.condition)
      const condition = new CalculationCondition({
        comparator,
        criteria: [replacementCondition, criterion]
      })
      this.calculation.condition = condition
      return
    }
  }

  isEmptyCondition() {
    return _.isEmpty(this.calculation.condition) || _.isEmpty(this.calculation.condition.criteria)
  }
}
