import { FormControl, Validators } from '@angular/forms'
import { Answer_AddFe } from '../../timeline/add/answer/Answer_AddFe'
import { AnswerFe } from '../../timeline/answer/AnswerFe'
import { BooleanStatementFe } from './condition/BooleanStatementFe'
import { QuestionChoiceOptionFe } from './QuestionChoiceOptionFe'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import { QuestionDataTableFe } from './QuestionDataTableFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import * as _ from 'lodash'
import { QUESTION_TYPE } from './QuestionTypeFe'

export abstract class QuestionFe {
  isMapped = true
  isParent = false
  hasFollowUpQuestion = false
  childrenNumber = 0
  isFollowUpQues = false
  parentQuestionId: string
  followUpOptions: QuestionChoiceOptionFe[] | QuestionChoiceOptionFe
  isEditMode = false
  answer: AnswerFe
  newAnswer: Answer_AddFe
  titleControl = new FormControl(null, Validators.required)

  //mapping related
  editMapping = true
  mappingConfirmedOnce = false
  mappedToCols: TaxonomyAttributeFe[] = []

  selectedPredefineAnswer: any
  isCustomOptionAdded: boolean
  titleNumber: string

  constructor(
    public id: string,
    public sectionId: string,
    public type: string,
    public ordinal: number | null,
    public followUpCondition: BooleanStatementFe | null,
    public question: string,
    public desc: string | null,
    public comments: boolean,
    public required: boolean,
    public mappedToColKeys: string[] | null,
    public csrdDatapointId: string | undefined,
    public referencedParagraph: string | undefined,
    public referenceApplicationRequirement: string | undefined,
    public referencedParagraphInformation: string | undefined,
    public applicationRequirements: string[] | undefined,
    public supplementaryParagraph: string | undefined,
    public supplementary: string | undefined,
    public applicationRequirementFootnotes: string | undefined,
    public drFootNote: string | undefined
  ) {
    this.isMapped = mappedToColKeys ? true : false
  }

  isFieldSelected(col: TaxonomyAttributeFe): boolean {
    if (this.mappedToColKeys) {
      let isSelected = this.mappedToColKeys.includes(col.key)
      return isSelected
    }
    return false
  }

  toggleField(col: TaxonomyAttributeFe, question?: QuestionDataTableFe) {
    if (this.isFieldSelected(col)) {
      this.mappedToCols = this.mappedToCols.filter((column) => column != col)
      if (question) {
        question.removeEntityColumn(col)
      }
    } else {
      // allow single data point for question DATA_TABLE
      if (
        this.type === QUESTION_TYPE.DATA_TABLE ||
        (this.type !== QUESTION_TYPE.DATA_TABLE && this.mappedToCols.length == 0)
      ) {
        this.mappedToCols.push(col)
        if (question) {
          question.addEntityColumn(col)
        }
        if (this.type == QUESTION_TYPE.NUMBER) {
          this['measurementKey'] = col.unit?.measurementType
        } else if (this.type == QUESTION_TYPE.EMISSION_FACTOR) {
          this['datapointEf'] = col.emissionFactors[0]?.value
        }
      }
    }
    this.mappedToColKeys = this.mappedToCols.map((col) => col.key)
  }

  confirmMapping() {
    this.editMapping = false
    this.mappingConfirmedOnce = true
    this.titleControl.enable()

    //enable specific range
    if (this.type == QUESTION_TYPE.DATE) {
      this['rangeControl'].enable()
    }
  }

  setEditMapping() {
    this.editMapping = true
    this.titleControl.disable()

    //disable specific range
    if (this.type == QUESTION_TYPE.DATE) {
      this['rangeControl'].disable()
    }
  }

  setMappedToCols(entity: EntityFe) {
    if (this.mappedToColKeys && _.isArray(this.mappedToColKeys)) {
      this.mappedToColKeys = this.mappedToColKeys.filter((key) => entity.getColByKey(key))
      this.mappedToCols = this.mappedToColKeys.map((key) => entity.getColByKey(key))
    }
  }

  csrdLegislationLocation(): string {
    const LegislationLocation: string =
      (this.referencedParagraph ? this.referencedParagraph : '') +
      (this.referencedParagraph && this.referenceApplicationRequirement ? ', ' : '') +
      (this.referenceApplicationRequirement ? this.referenceApplicationRequirement : '')
    return LegislationLocation && LegislationLocation.length > 0 ? LegislationLocation : 'TBD'
  }

  csrdFootnotes(): string[] {
    const result: string[] = []

    if (this.drFootNote) {
      result.push(this.drFootNote)
    }

    if (this.applicationRequirementFootnotes) {
      result.push(...this.applicationRequirementFootnotes)
    }

    return result
  }
}
