<div class="csrdInfoTab">
  <ng-container *ngIf="question">
    <div class="accordion">
      <div class="accordion-item paragraphInformation">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#legislationLocationBody"
            aria-expanded="false"
            aria-controls="legislationLocationBody"
          >
            {{ slocale('Legislation location') }}
          </button>
        </h2>
        <div id="legislationLocationBody" class="accordion-collapse collapse" aria-labelledby="legislationLocationBody">
          <div class="accordion-body">
            <p
              *ngIf="question.csrdLegislationLocation(); else noData"
              [innerHTML]="question.csrdLegislationLocation()"
            ></p>
          </div>
        </div>
      </div>
      <div class="accordion-item paragraphInformation">
        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseThree"
          >
            {{ slocale('Paragraph information') }}
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingThree"
        >
          <div class="accordion-body">
            <p
              *ngIf="question.referencedParagraphInformation; else noData"
              [innerHTML]="question.referencedParagraphInformation | newlineToBr"
            ></p>
          </div>
        </div>
      </div>
      <div class="accordion-item applicationRequirements">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#applicationRequirementsBody"
            aria-expanded="false"
            aria-controls="applicationRequirementsBody"
          >
            {{ slocale('Application Requirements') }}
          </button>
        </h2>
        <div
          id="applicationRequirementsBody"
          class="accordion-collapse collapse"
          aria-labelledby="applicationRequirementsBody"
        >
          <div class="accordion-body">
            <ng-container *ngIf="question.applicationRequirements; else noData">
              <ng-container *ngFor="let applicationRequirement of question.applicationRequirements">
                <p
                  [innerHTML]="applicationRequirement | newlineToBr"
                  *ngIf="!applicationRequirement.includes('N/A')"
                ></p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item additionalReferences">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#additionalReferencesBody"
            aria-expanded="false"
            aria-controls="additionalReferencesBody"
          >
            {{ slocale('Additional References') }}
          </button>
        </h2>
        <div
          id="additionalReferencesBody"
          class="accordion-collapse collapse"
          aria-labelledby="additionalReferencesBody"
        >
          <div class="accordion-body">
            <ng-container *ngIf="!(!question.supplementaryParagraph && !question.supplementary); else noData">
              <p
                *ngIf="question.supplementaryParagraph && !question.supplementaryParagraph.includes('N/A')"
                [innerHTML]="question.supplementaryParagraph | newlineToBr"
              ></p>
              <p
                *ngIf="question.supplementary && !question.supplementary.includes('N/A')"
                [innerHTML]="question.supplementary | newlineToBr"
              ></p>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item footnotes">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#footnotesBody"
            aria-expanded="false"
            aria-controls="footnotesBody"
          >
            {{ slocale('Footnotes') }}
          </button>
        </h2>
        <div id="footnotesBody" class="accordion-collapse collapse" aria-labelledby="footnotesBody">
          <div class="accordion-body">
            <ng-container *ngIf="question.csrdFootnotes() as footnotes; else noData">
              <ng-container *ngIf="footnotes.length > 0; else noData">
                <ng-container *ngFor="let footnote of footnotes">
                  <p [innerHTML]="footnote | newlineToBr" *ngIf="!footnote.includes('N/A')"></p>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item internalComment">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#internalComment"
            aria-expanded="false"
            aria-controls="internalComment"
          >
            {{ slocale('Internal comment') }}
          </button>
        </h2>
        <div id="internalComment" class="accordion-collapse collapse" aria-labelledby="internalComment">
          <div class="accordion-body">
            <p
              *ngIf="datapointSettings?.comment; else noData"
              [innerHTML]="datapointSettings?.comment | newlineToBr"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!question">
    <div class="datapointName">
      <div class="status phaseIn" *ngIf="datapoint.phaseIn">
        <i class="la la-clock icon"></i>
      </div>
      <div class="status mayDisclose" *ngIf="datapoint.isVoluntary">
        <i class="la la-question icon"></i>
      </div>
      <div class="status conditional" *ngIf="datapoint.conditional">
        <i class="las la-code-branch icon"></i>
      </div>
      <div class="label">{{ datapoint.id }} {{ datapoint.name }}</div>
    </div>
    <div class="accordion">
      <div class="accordion-item phaseIn">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <i class="la la-clock icon"></i>
            {{ slocale('Phase-in or effective date') }}
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div class="accordion-body">
            <p *ngIf="datapoint.phaseIn; else noData" [innerHTML]="datapoint.phaseIn | newlineToBr"></p>
          </div>
        </div>
      </div>
      <div class="accordion-item conditional">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            <i class="las la-code-branch icon"></i>
            {{ slocale('Conditional') }}
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingTwo"
        >
          <div class="accordion-body">
            <p *ngIf="datapoint.conditional; else noData" [innerHTML]="datapoint.conditional | newlineToBr"></p>
          </div>
        </div>
      </div>
      <div class="accordion-item paragraphInformation">
        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseThree"
          >
            {{ slocale('Paragraph information') }}
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingThree"
        >
          <div class="accordion-body">
            <p *ngIf="datapoint.description; else noData" [innerHTML]="datapoint.description | newlineToBr"></p>
          </div>
        </div>
      </div>
      <div class="accordion-item applicationRequirements">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#applicationRequirementsBody"
            aria-expanded="false"
            aria-controls="applicationRequirementsBody"
          >
            {{ slocale('Application Requirements') }}
          </button>
        </h2>
        <div
          id="applicationRequirementsBody"
          class="accordion-collapse collapse"
          aria-labelledby="applicationRequirementsBody"
        >
          <div class="accordion-body">
            <ng-container *ngIf="datapoint.applicationRequirements; else noData">
              <ng-container *ngFor="let applicationRequirement of datapoint.applicationRequirements">
                <p
                  [innerHTML]="applicationRequirement | newlineToBr"
                  *ngIf="!applicationRequirement.includes('N/A')"
                ></p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item additionalReferences">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#additionalReferencesBody"
            aria-expanded="false"
            aria-controls="additionalReferencesBody"
          >
            {{ slocale('Additional References') }}
          </button>
        </h2>
        <div
          id="additionalReferencesBody"
          class="accordion-collapse collapse"
          aria-labelledby="additionalReferencesBody"
        >
          <div class="accordion-body">
            <ng-container *ngIf="!(!datapoint.supplementalInfo && !datapoint.supplementalReference); else noData">
              <p
                *ngIf="datapoint.supplementalInfo && !datapoint.supplementalInfo.includes('N/A')"
                [innerHTML]="datapoint.supplementalInfo | newlineToBr"
              ></p>
              <p
                *ngIf="datapoint.supplementalReference && !datapoint.supplementalReference.includes('N/A')"
                [innerHTML]="datapoint.supplementalReference | newlineToBr"
              ></p>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item footnotes">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#footnotesBody"
            aria-expanded="false"
            aria-controls="footnotesBody"
          >
            {{ slocale('Footnotes') }}
          </button>
        </h2>
        <div id="footnotesBody" class="accordion-collapse collapse" aria-labelledby="footnotesBody">
          <div class="accordion-body">
            <ng-container
              *ngIf="datapoint.applicationFootnotes && datapoint.applicationFootnotes.length > 0; else noData"
            >
              <ng-container *ngFor="let applicationFootnote of datapoint.applicationFootnotes">
                <p [innerHTML]="applicationFootnote | newlineToBr" *ngIf="!applicationFootnote.includes('N/A')"></p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="accordion-item relationToOtherStandards">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#relationToOtherStandardsBody"
            aria-expanded="false"
            aria-controls="relationToOtherStandardsBody"
          >
            {{ slocale('Relation to other standards') }}
          </button>
        </h2>
        <div
          id="relationToOtherStandardsBody"
          class="accordion-collapse collapse"
          aria-labelledby="relationToOtherStandardsBody"
        >
          <div class="accordion-body">
            <p
              *ngIf="datapoint.relationToOtherStandards; else noData"
              [innerHTML]="datapoint.relationToOtherStandards | newlineToBr"
            ></p>
          </div>
        </div>
      </div>
      <div class="accordion-item internalComment">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#internalComment"
            aria-expanded="false"
            aria-controls="internalComment"
          >
            {{ slocale('Internal comment') }}
          </button>
        </h2>
        <div id="internalComment" class="accordion-collapse collapse" aria-labelledby="internalComment">
          <div class="accordion-body">
            <p
              *ngIf="datapointSettings?.comment; else noData"
              [innerHTML]="datapointSettings?.comment | newlineToBr"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noData>
  <p>{{ slocale('N/A') }}</p>
</ng-template>
