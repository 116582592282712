import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { EntityFe } from './EntityFe'

export class TaxonomyInfoFe {
  public version: number
  public entities: EntityFe[]
  public deletedEntities: EntityFe[] = []

  public modified = false
  private languageService: LanguageService

  constructor(version: number, entities: EntityFe[], languageService: LanguageService) {
    this.languageService = languageService
    this.version = version
    this.entities = entities
  }

  categoryFQN(catKey): string {
    let components = catKey.split('.')
    var parent = ''
    components.forEach((c, i) => {
      if (i == 0) {
        parent = c
      } else {
        components[i] = parent + '.' + c
        parent = components[i]
      }
    })
    let taxonomies = components.map((c) => {
      return this.entities.find((t) => {
        if (t.key == c) return t
      })
    })
    let labels = taxonomies.map((t) => t?.getLabel())
    return labels.join(' -> ')
  }

  entityByKey(key: string): EntityFe {
    let entity = this.entities.find((item) => item.key == key)
    return entity
  }

  allWithKeyPrefix(parentKey: string): any {
    return this.entities.filter((item) => item.key.startsWith(parentKey))
  }

  rootParentEntities(childKeys: string[]): EntityFe[] {
    let parentKeys = childKeys.map((childKey) => {
      return this.rootParentKey(childKey)
    })
    let uniqueParentKeys = [...new Set(parentKeys)]
    let parentEntities = uniqueParentKeys.map((parentKey) => this.entities.find((t) => t.key == parentKey))
    return parentEntities
  }

  rootParentEntity(childKey: string): EntityFe {
    let parentItem
    let rootParentKey = this.rootParentKey(childKey)
    if (rootParentKey) {
      parentItem = this.entities.find((t) => t.key == rootParentKey)
    }
    return parentItem
  }

  rootParentKey(childKey: string): string {
    let rootParentKey
    let components = childKey.split('.')
    if (components.length > 0) {
      rootParentKey = childKey.substring(0, childKey.indexOf('.'))
    }
    return rootParentKey
  }

  parentEntities(childKeys: string[]): EntityFe[] {
    let parentEntities = childKeys.map((childKey) => this.parentEntity(childKey))
    let uniqueParentEntities = [...new Set(parentEntities)]
    return uniqueParentEntities
  }

  childrenSortedByOrdinal(parentKey: string = null): EntityFe[] {
    if (parentKey == null) {
      let entities: EntityFe[] = this.entities.filter((item) => item.key.split('.').length == 1)
      entities.sort((a, b) => {
        return a.ordinal < b.ordinal ? -1 : 1
      })
      return entities
    } else {
      let level = parentKey.split('.').length
      let entities: EntityFe[] = this.entities.filter(
        (item) => item.key.startsWith(parentKey) && item.key.split('.').length == level + 1
      )
      entities.sort((a, b) => {
        return a.ordinal < b.ordinal ? -1 : 1
      })
      return entities
    }
  }

  totalDataCategories(): number {
    let roots = this.childrenSortedByOrdinal()
    let parent = []
    roots.forEach((item) => parent.push(...this.childrenSortedByOrdinal(item.key)))
    return parent.length
  }

  parentEntity(childKey): EntityFe {
    let parentItem = null
    let components = childKey.split('.')
    if (components.length > 0) {
      let parentKey = childKey.substring(0, childKey.lastIndexOf('.'))
      parentItem = this.entities.find((t) => t.key == parentKey)
    }
    return parentItem
  }

  parentKey(childKey): string {
    let components = childKey.split('.')
    if (components.length > 0) {
      return childKey.substring(0, childKey.lastIndexOf('.'))
    }
  }

  siblings(item: EntityFe) {
    let siblings = []
    let level = item.key.split('.').length
    if (level == 1) {
      siblings = this.entities.filter((t) => t.key.split('.').length == 1)
    } else {
      let parent = this.parentEntity(item.key)
      let items = this.allWithKeyPrefix(parent.key)
      siblings = items.filter((i) => i.key.split('.').length == level)
    }
    return siblings
  }

  addEntity(jsonObj) {
    let entity = EntityFe.fromTransfer(jsonObj, this.languageService)
    let c = entity.key.split('.')
    if (c.length == 3) {
      entity.table = true
    } else {
      entity.table = false
    }
    entity.deployed = false
    entity.modified = true
    entity.isNew = true

    entity.columns = entity.columns.map((column) => {
      column.isNew = true
      return column
    })

    this.modified = true
    let maxOrdinal = this.maxOrdinal()
    entity.ordinal = maxOrdinal + 1
    this.entities.push(entity)
  }

  addCustomEntity(label: { [key: string]: string }, parentEntity: EntityFe): EntityFe {
    return this.addCustomEntityByParentKey(label, parentEntity?.key)
  }

  addCustomEntityByParentKey(label: { [key: string]: string }, parentEntityKey: string) {
    // always use english label in key
    var basekey = label['en'].replace(/[^a-zA-Z0-9_]/gi, '_').toLowerCase()
    if (basekey.length > 0) {
      basekey = parentEntityKey ? parentEntityKey + '.' + basekey : basekey
      let table = false
      let c = basekey.split('.')
      if (c.length == 3) {
        table = true
      }
      let key = this.adjustKey(basekey)
      let maxOrdinal = this.maxOrdinal()
      let entity: EntityFe = new EntityFe(this.languageService)
      entity.ordinal = maxOrdinal + 1
      entity.key = key
      entity.label = label
      entity.table = table
      entity.deployed = false
      entity.modified = true
      entity.isNew = true
      this.modified = true
      this.entities.push(entity)
      return entity
    }
  }

  adjustKey(basekey: string) {
    let i = 0
    let key = basekey
    let keyNotMatch = false
    while (!keyNotMatch) {
      let item = this.entities.find((a) => a.key == key)
      if (!item) {
        keyNotMatch = true
      } else {
        i++
        key = `${basekey}_${i}`
      }
    }
    return key
  }

  removeEntity(entity: EntityFe): string[] {
    let children = this.allWithKeyPrefix(entity.key)
    let keys = children ? children.map((c) => c.key) : []
    this.entities = this.entities.filter((i) => !keys.includes(i.key))
    return keys
  }

  hasPrevSibling(item: EntityFe) {
    let siblings = this.siblings(item)
    let prevs = siblings.filter((s) => s.ordinal < item.ordinal)
    return prevs && prevs.length > 0
  }

  hasNextSibling(item: EntityFe) {
    let siblings = this.siblings(item)
    let nexts = siblings.filter((s) => s.ordinal > item.ordinal)
    return nexts && nexts.length > 0
  }

  maxOrdinal() {
    let maxOrdinal = 0
    this.entities.forEach((t, i) => {
      if (t.ordinal && t.ordinal > maxOrdinal) {
        maxOrdinal = t.ordinal
      }
    })
    return maxOrdinal
  }

  raiseOrder(item: EntityFe) {
    let ordinal = item.ordinal
    let siblings = this.siblings(item)
    let prev
    siblings.forEach((s) => {
      if (s.ordinal < ordinal) {
        if (!prev || prev.ordinal < s.ordinal) {
          prev = s
        }
      }
    })
    if (prev) {
      item.ordinal = prev.ordinal
      prev.ordinal = ordinal
    }
  }

  decreaseOrder(item: EntityFe) {
    let ordinal = item.ordinal
    let siblings = this.siblings(item)
    let next
    siblings.forEach((s) => {
      if (s.ordinal > ordinal) {
        if (!next || next.ordinal > s.ordinal) {
          next = s
        }
      }
    })
    if (next) {
      item.ordinal = next.ordinal
      next.ordinal = ordinal
    }
  }

  color(item: EntityFe): string {
    if (item) {
      if (item.color) {
        return item.color
      } else {
        let parentItem = this.parentEntity(item.key)
        if (parentItem) {
          return this.color(parentItem)
        } else {
          ;('black')
        }
      }
    } else {
      return 'red'
    }
  }

  icon(item: EntityFe): string {
    if (item) {
      if (item.icon) {
        return item.icon.replace('ft', 'las la')
      } else {
        let parentItem = this.parentEntity(item.key)
        if (parentItem) {
          return this.icon(parentItem)
        } else {
          ;('')
        }
      }
    } else {
      return 'las la-exclamation-triangle'
    }
  }

  public static fromTransfer(
    transfer: any,
    toCompareWith: TaxonomyInfoFe | undefined,
    languageService: LanguageService
  ): TaxonomyInfoFe {
    let entities = transfer.entities.map((be) => EntityFe.fromTransfer(be, languageService))
    let taxonomyInfo = new TaxonomyInfoFe(transfer.version, entities, languageService)
    if (toCompareWith) {
      taxonomyInfo.diffDeployment(toCompareWith)
    }
    return taxonomyInfo
  }

  diffDeployment(depTaxonomy: TaxonomyInfoFe) {
    this.deletedEntities = []
    depTaxonomy.entities.forEach((de) => {
      let entity = this.entities.find((e) => e.key == de.key)
      if (!entity) {
        this.deletedEntities.push(de)
      }
    })
    this.entities.forEach((e1) => {
      let deployedEnity = depTaxonomy.entities.find((e2) => e2.key == e1.key)
      e1.diffDeployment(deployedEnity)
    })

    let modifiedEntity = this.entities.find((e) => !e.deployed || e.modified)

    this.modified = this.deletedEntities.length > 0 || modifiedEntity ? true : false
  }

  getTaxonomyEntityText(entityKey: string) {
    let rootParent = this.rootParentEntity(entityKey)
    let parent = this.parentEntity(entityKey)
    let entity = this.entityByKey(entityKey)
    if (entity) {
      let label = `${rootParent.label} > ${parent.label} > ${entity.label}`
      return label
    }
  }

  toJSON(): any {
    const { languageService, ...data } = this
    return data
  }

  filterEntities(keyword: string = ''): EntityFe[] {
    const lowercaseKeyword = keyword.toLowerCase().trim()
    let allRootEntities = this.childrenSortedByOrdinal()
    if (lowercaseKeyword === '') {
      return allRootEntities
    } else {
      let filteredEntities: EntityFe[] = []

      for (let root of allRootEntities) {
        if (this.entityMatchesKeyword(root, lowercaseKeyword)) {
          filteredEntities.push(root)
        }

        let parents = this.childrenSortedByOrdinal(root.key)
        for (let parent of parents) {
          if (this.entityMatchesKeyword(parent, lowercaseKeyword)) {
            filteredEntities.push(parent)
          }

          let children = this.childrenSortedByOrdinal(parent.key)
          for (let child of children) {
            if (this.entityMatchesKeyword(child, lowercaseKeyword)) {
              filteredEntities.push(child)
            }
          }
        }
      }

      return filteredEntities
    }
  }

  private entityMatchesKeyword(entity: EntityFe, keyword: string): boolean {
    return entity.getLabel().toLowerCase().includes(keyword)
  }
}
