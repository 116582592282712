export abstract class Operator {
  public key: string
  public symbol: any
  public label: string

  constructor(key: string, symbol: any, label: string) {
    this.key = key
    this.symbol = symbol
    this.label = label
  }
}
