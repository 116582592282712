export abstract class AbstractActivity_AddFe {
  id: string
  type: string
  message: string | null
  draft: boolean
  requestTaskId: string
  requestGroupId: string
  requestGroupLabel: string

  constructor(
    id: string,
    type: string,
    message: string | null,
    draft: boolean,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string
  ) {
    this.id = id
    this.type = type
    this.message = message
    this.draft = draft
    this.requestTaskId = requestTaskId
    this.requestGroupId = requestGroupId
    this.requestGroupLabel = requestGroupLabel
  }
}
