import { Component, OnInit } from '@angular/core'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { DataCategoryServiceFe } from 'src/app/services/DataCategoryServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'connected-actions',
  templateUrl: './connected-actions.component.html',
  styleUrls: ['./details.component.scss']
})
export class ConnectedActionsComponent extends AbstractLanguageComponent implements OnInit {
  taxonomy: TaxonomyInfoFe

  constructor(
    languageService: LanguageService,
    public categoryService: DataCategoryServiceFe
  ) {
    super(languageService)
  }

  async ngOnInit() {
    this.taxonomy = await this.categoryService.getTaxonomy()
  }
}
