import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { QUESTION_TYPE } from '../../../request/questionnaire/QuestionTypeFe'
import { Answer_AddFe } from './Answer_AddFe'

export class AnswerEmissionFactor_AddFe extends Answer_AddFe {
  selectedEmissionFactor: AbstractEmissionFactorFe

  constructor(
    id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    selectedEmissionFactor: AbstractEmissionFactorFe
  ) {
    super(id, sectionId, questionId, QUESTION_TYPE.EMISSION_FACTOR, comments)
    this.selectedEmissionFactor = selectedEmissionFactor
  }

  public isInvalid(isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return !!this.selectedEmissionFactor
  }

  public reset(): void {
    this.selectedEmissionFactor = null
  }

  public toTransfer() {
    let ans = new AnswerEmissionFactor_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.selectedEmissionFactor
    )
    return ans
  }
}
