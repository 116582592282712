export class ScreenWidthSizeFe {
  static readonly WIDTH_XSMALL = new ScreenWidthSizeFe('XS', 'WIDTH_XSMALL', 0, 575)
  static readonly WIDTH_SMALL = new ScreenWidthSizeFe('S', 'WIDTH_SMALL', 576, 767)
  static readonly WIDTH_MEDIUM = new ScreenWidthSizeFe('M', 'WIDTH_MEDIUM', 768, 991)
  static readonly WIDTH_LARGE = new ScreenWidthSizeFe('L', 'WIDTH_LARGE', 992, 1199)
  static readonly WIDTH_XLARGE = new ScreenWidthSizeFe('XL', 'WIDTH_XLARGE', 1200, 100000)

  constructor(
    readonly key: string,
    readonly name: string,
    readonly widthRangeStart: number,
    readonly widthRangeEnd: number
  ) {}

  public static toScreenSize(width: number): ScreenWidthSizeFe {
    switch (true) {
      case width < this.WIDTH_XSMALL.widthRangeEnd:
        return this.WIDTH_XSMALL
      case width < this.WIDTH_SMALL.widthRangeEnd:
        return this.WIDTH_SMALL
      case width < this.WIDTH_MEDIUM.widthRangeEnd:
        return this.WIDTH_MEDIUM
      case width < this.WIDTH_LARGE.widthRangeEnd:
        return this.WIDTH_LARGE
      default:
        return this.WIDTH_XLARGE
    }
  }

  public smallerThan(other: ScreenWidthSizeFe): boolean {
    return this.widthRangeStart < other.widthRangeStart
  }

  public greaterOrEqualTo(other: ScreenWidthSizeFe): boolean {
    return this.widthRangeStart >= other.widthRangeStart
  }

  public isXSmallSize() {
    return this == ScreenWidthSizeFe.WIDTH_XSMALL
  }

  public isSmallerSize() {
    return this == ScreenWidthSizeFe.WIDTH_XSMALL || this == ScreenWidthSizeFe.WIDTH_SMALL
  }

  public isMediumSize() {
    return this == ScreenWidthSizeFe.WIDTH_MEDIUM
  }

  public isLargeSize() {
    return this == ScreenWidthSizeFe.WIDTH_LARGE
  }

  public isXLargeSize() {
    return this == ScreenWidthSizeFe.WIDTH_XLARGE
  }

  public isSize(sizes: string[]) {
    return sizes.includes(this.key)
  }
}
