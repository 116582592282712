import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { UnitFe } from 'src/app/components/unit-systems/model/UnitFe'
import { groupUnitsByMeasurementTypeAndSystem } from 'src/app/components/unit-systems/model/utils'
import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe'
import { PredefinedEmissionFactorOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedEmissionFactorOptionFe'
import { PredefinedNumberOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedNumberOptionFe'
import { QuestionDateFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDateFe'
import { QuestionEmissionFactorFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe'
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe'
import { QuestionNumberFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { InputFilterUtil } from 'src/app/utils/InputFilterUtil'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { PredefinedTextOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedTextOptionFe'
import { cloneDeep } from 'lodash'
import { NoUnit } from 'src/app/components/unit-systems/unit-selector/unit-selector.component'
import { AbstractPredefinedOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/AbstractPredefinedOptionFe'
import { QuestionTextFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTextFe'

enum QuestionType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  DateRange = 'dateRange',
  EmissionFactor = 'emissionFactor'
}

enum NumberOption {
  UNIT = 'unit'
}

enum CommonOption {
  DEFAULT = 'default',
  SELECT = 'select'
}

enum ModalNames {
  removeAllModal = 'removeAllModal'
}
@Component({
  selector: 'questionnaire-predefined-option-editor',
  templateUrl: './questionnaire-predefined-option-editor.component.html',
  styleUrls: [
    './questionnaire-predefined-option-editor.component.scss',
    '../questionaire-creator-full-page.component.scss',
    '../../data-suppliers.component.scss'
  ]
})
export class QuestionnairePredefinedOptionEditorComponent extends AbstractLanguageComponent implements OnInit {
  @Input() question: QuestionFe
  @Input() questionType: QuestionType
  @Input() alreadyPreDefinedOptionList?: AbstractPredefinedOptionFe[] = []
  @Output() closeEvent = new EventEmitter<boolean>()

  selectedOption: string = CommonOption.DEFAULT
  predefinedOptions: any[] = []
  customUnits: any[] = []
  unitsByMeasurementType: any[] = []
  units: UnitFe[] = []
  selectedUnitContainer: { selectedUnit?: UnitFe } = {}
  modals: any
  savePredefinedSateMap = new Map()
  customValuesAllowedWhenPredefinedOptionsSet: boolean = true

  @ViewChild(`${ModalNames.removeAllModal}`, { static: true })
  categorySelectorModal: TemplateRef<any>

  constructor(
    public languageService: LanguageService,
    private modalService: BsModalService,
    private stateService: StateServiceFe
  ) {
    super(languageService)
  }

  async ngOnInit() {
    this.setupModals()
    this.customValuesAllowedWhenPredefinedOptionsSet = (
      this.question as any
    ).customValuesAllowedWhenPredefinedOptionsSet

    if (this.questionType === QuestionType.Number) {
      await this.loadUnits()
    }

    if (this.question && this.setupPredefinedOptionsFromQuestion()) {
      this.initializeSelectedOption()
      return
    }

    this.initializeRows()
  }

  setText(index: number, value: string) {
    if (value) {
      this.predefinedOptions[index].text = value.replace(/\r\n/g, '\n')
    }
  }

  // When getting the text
  getText(index: number): string {
    return this.predefinedOptions[index]?.text?.replace(/\n/g, '\r\n') || ''
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  getSelectedUnitContainer(symbol: string) {
    const unit = this.units.find((u) => u.symbol === symbol)
    this.selectedUnitContainer.selectedUnit = unit
    return this.selectedUnitContainer
  }
  initializeRows(): void {
    if (!this.alreadyPreDefinedOptionList.length) {
      this.addRow()
      if (
        this.questionType !== QuestionType.Number ||
        (this.questionType === QuestionType.Number && this.selectedOption === CommonOption.SELECT)
      ) {
        this.addRow()
      }
    } else {
      // if predefined list already exists
      this.addRowWithPredefinedOptions()
    }
  }

  resetRows(): void {
    this.predefinedOptions = []
  }

  initializeSelectedOption(): void {
    if (this.questionType === QuestionType.Date || this.questionType === QuestionType.DateRange) {
      this.selectOption(CommonOption.SELECT, false)
    } else if (this.questionType === QuestionType.Number) {
      const options = (this.question as QuestionNumberFe).predefinedOptions
      if (options[0]?.value === null || options[0]?.value == undefined) {
        this.selectOption(NumberOption.UNIT, false)
      } else {
        this.selectOption(CommonOption.SELECT, false)
      }
    } else if (this.questionType === QuestionType.EmissionFactor) {
      this.selectOption(CommonOption.SELECT, false)
    } else if (this.questionType === QuestionType.Text) {
      this.selectOption(CommonOption.SELECT, false)
    }
  }

  setupModals() {
    this.modals = {
      [`${ModalNames.removeAllModal}`]: {
        template: this.categorySelectorModal,
        class: `modal-ms ${ModalNames.removeAllModal}`
      }
    }
  }

  selectOption(option: string, reset: boolean = true): void {
    this.selectedOption = option
    if (this.savePredefinedSateMap.has(option)) {
      this.predefinedOptions = this.savePredefinedSateMap.get(option)
    } else {
      if (reset) {
        this.resetRows()
        this.initializeRows()
      }
      this.savePredefinedSateMap.set(option, this.predefinedOptions)
    }
  }

  isOptionActive(option: string): boolean {
    return this.selectedOption === option
  }

  addRow(): void {
    switch (this.questionType) {
      case QuestionType.Date:
      case QuestionType.DateRange:
        this.predefinedOptions.push(new PredefinedDateOptionFe())
        break
      case QuestionType.Number:
        this.predefinedOptions.push(new PredefinedNumberOptionFe())
        break
      case QuestionType.EmissionFactor:
        this.predefinedOptions.push(new PredefinedEmissionFactorOptionFe())
        break
      case QuestionType.Text:
        this.predefinedOptions.push(new PredefinedTextOptionFe())
        break
      default:
        this.predefinedOptions.push({})
    }
  }

  addRowWithPredefinedOptions(): void {
    switch (this.questionType) {
      case QuestionType.Date:
      case QuestionType.DateRange:
        for (const options of this.alreadyPreDefinedOptionList) {
          this.predefinedOptions.push(new PredefinedDateOptionFe(new Date(options.from), options.to))
        }
        break
      case QuestionType.Number:
        for (const options of this.alreadyPreDefinedOptionList) {
          this.predefinedOptions.push(new PredefinedNumberOptionFe(options.unit, options.measurementKey, options.value))
        }
        break
      case QuestionType.EmissionFactor:
        for (const options of this.alreadyPreDefinedOptionList) {
          this.predefinedOptions.push(new PredefinedEmissionFactorOptionFe(options.selectedEmissionFactor))
        }
        break
      case QuestionType.Text:
        for (const options of this.alreadyPreDefinedOptionList) {
          this.predefinedOptions.push(new PredefinedTextOptionFe(options.text))
        }
        break
      default:
        this.predefinedOptions.push({})
    }
  }
  setDateFrom(dateString: string, index: number): void {
    if (dateString) {
      this.predefinedOptions[index].from = new Date(dateString)
    }
  }

  setDateTo(dateString: string, index: number): void {
    if (dateString) {
      this.predefinedOptions[index].to = new Date(dateString)
    }
  }

  removeRow(index: number): void {
    if (this.predefinedOptions.length > 1) {
      this.predefinedOptions.splice(index, 1)
    }
  }

  removeAllRows(): void {
    if (this.alreadyPreDefinedOptionList?.length) {
      this.alreadyPreDefinedOptionList = []
    }
    if (this.predefinedOptions?.length >= 1) {
      this.predefinedOptions = []
      this.initializeRows()
    }

    this.cancelRemoveAllModal()
  }

  isActionButtonDisabled(): boolean {
    if (this.selectedOption === CommonOption.DEFAULT) {
      return false
    }
    return this.predefinedOptions.some((option, index) => !this.isValidOption(option) || this.isInvalidDate(index))
  }

  isValidOption(option: any): boolean {
    if (option instanceof Date) {
      return !this.isInvalidDateFor(option)
    }

    if (!option || (typeof option === 'object' && Object.keys(option).length === 0)) {
      return false
    }

    switch (this.questionType) {
      case QuestionType.Date:
        return !!option.from
      case QuestionType.DateRange:
        return !!option.from && !!option.to
      case QuestionType.Number:
        return this.isValidNumberOption(option)
      case QuestionType.Text:
        return this.isValidTextOptions(option)
      case QuestionType.EmissionFactor:
        return this.isValidEmissionFactorOption(option)
      default:
        return true
    }
  }

  isValidNumberOption(option: any): boolean {
    if (this.selectedOption === NumberOption.UNIT) {
      return option.measurementKey === 'NO_UNIT' || (option.unit != null && option.measurementKey != null)
    }
    if (this.selectedOption === CommonOption.SELECT) {
      return (
        option.measurementKey === 'NO_UNIT' ||
        (option.value != null && option.unit != null && option.measurementKey != null)
      )
    }
    return false
  }

  isValidTextOptions(option: any): boolean {
    return Boolean(option.text)
  }

  isValidEmissionFactorOption(option: any): boolean {
    return !!option.selectedEmissionFactor
  }

  setupPredefinedOptionsFromQuestion(): boolean {
    switch (this.questionType) {
      case QuestionType.Number:
        return this.setupNumberOptions()
      case QuestionType.Date:
      case QuestionType.DateRange:
        return this.setupDateOptions()
      case QuestionType.EmissionFactor:
        return this.setupEmissionFactoryOptions()
      case QuestionType.Text:
        return this.setupTextOptions()
      default:
        return false
    }
  }

  setupNumberOptions(): boolean {
    return this.setupPredefinedOptions(this.getQuestionNumber().predefinedOptions)
  }

  setupDateOptions(): boolean {
    return this.setupPredefinedOptions(this.getQuestionDate().predefinedOptions)
  }

  setupEmissionFactoryOptions(): boolean {
    return this.setupPredefinedOptions(this.getQuestionEmissionFactor().predefinedOptions)
  }

  setupTextOptions(): boolean {
    return this.setupPredefinedOptions(this.getQuestionText().predefinedOptions)
  }

  setupPredefinedOptions(options: any): boolean {
    if (options?.length > 0) {
      this.predefinedOptions = cloneDeep(options)
      return true
    }
    return false
  }

  apply(close: boolean = true): void {
    switch (this.questionType) {
      case QuestionType.Number:
        this.applyNumberSelection()
        break
      case QuestionType.Date:
      case QuestionType.DateRange:
        this.applyDateSelection()
        break
      case QuestionType.EmissionFactor:
        this.applyEmissionFactorSelection()
        break
      case QuestionType.Text:
        this.applyTextSelection()
        break
    }

    if (close) {
      this.closeSelfModal()
    }
  }

  applyNumberSelection(): void {
    const question = this.getQuestionNumber()
    if (this.selectedOption === CommonOption.DEFAULT) {
      question.predefinedOptions = []
      question.customValuesAllowedWhenPredefinedOptionsSet = false
      return
    }

    if (this.selectedOption === NumberOption.UNIT) {
      const firstOption = this.predefinedOptions[0] as PredefinedNumberOptionFe
      question.predefinedOptions = [new PredefinedNumberOptionFe(firstOption.unit, firstOption.measurementKey)]
      question.customValuesAllowedWhenPredefinedOptionsSet = false
    } else if (this.selectedOption === CommonOption.SELECT) {
      question.predefinedOptions = this.predefinedOptions
      question.predefinedOptions = QuestionNumberFe.setQuestion(question).getSortedPredfinedNumberOptions()
    }
  }

  applyTextSelection(): void {
    const question = this.question as QuestionDateFe

    if (this.selectedOption === 'default') {
      question.predefinedOptions = []
    } else if (this.selectedOption === 'select') {
      question.predefinedOptions = this.predefinedOptions
        .filter((item) => item)
        .sort((a: PredefinedTextOptionFe, b: PredefinedTextOptionFe) => {
          return a.text.toLowerCase().localeCompare(b.text)
        })
    }
  }

  applyDateSelection(): void {
    const question = this.question as QuestionDateFe
    if (this.selectedOption === CommonOption.DEFAULT) {
      question.predefinedOptions = []
    } else if (this.selectedOption === CommonOption.SELECT) {
      question.predefinedOptions = this.predefinedOptions
      question.predefinedOptions = QuestionDateFe.setQuestion(question).getSortedPredfinedDateOptions()
    }
  }

  applyEmissionFactorSelection(): void {
    const question = this.getQuestionEmissionFactor()
    if (this.selectedOption === CommonOption.DEFAULT) {
      question.predefinedOptions = []
    } else if (this.selectedOption === CommonOption.SELECT) {
      question.predefinedOptions = this.predefinedOptions
    }
  }

  closeSelfModal(): void {
    this.closeEvent.emit(true)
  }

  onFromDateChange(value: string, index: number): void {
    this.predefinedOptions[index].from = new Date(value)
  }

  onToDateChange(value: string, index: number): void {
    this.predefinedOptions[index].to = new Date(value)
  }

  getMinDate(index: number): string {
    const fromDate = this.predefinedOptions[index]?.from
    if (fromDate && fromDate instanceof Date) {
      return fromDate ? fromDate.toISOString().split('T')[0] : ''
    }
    return ''
  }

  isInvalidDate(index: number): boolean {
    if (this.questionType === QuestionType.DateRange) {
      const { from, to } = this.predefinedOptions[index] || {}
      return from && to && from > to
    }
    return false
  }

  isInvalidDateFor(option: any): boolean {
    return option.from && option.to && option.from > option.to
  }

  getUnitsByMeasurementType(): any[] {
    return this.unitsByMeasurementType
  }

  getCustomUnits(): any[] {
    return this.customUnits
  }

  getMeasurementType(): Set<string> {
    const includeMeasurementTypes = new Set<string>()
    if (this.question.mappedToCols) {
      this.question.mappedToCols.forEach((col) => {
        if (col.unit) includeMeasurementTypes.add(col.unit.measurementType)
      })
    }
    // add for no unit filter
    if (this.question['measurementKey'] === 'NO_UNIT') {
      includeMeasurementTypes.add('NO_UNIT')
    }
    return includeMeasurementTypes
  }

  setUnit(event: UnitFe, option?: PredefinedNumberOptionFe): void {
    if (option) {
      option.unit = event.symbol
      option.measurementKey = event.measurementType
    } else {
      const firstOption = this.predefinedOptions[0] as PredefinedNumberOptionFe
      firstOption.unit = event.symbol
      firstOption.measurementKey = event.measurementType
    }
  }

  setEmissionFactor(index: number, event: AbstractEmissionFactorFe): void {
    this.predefinedOptions[index].selectedEmissionFactor = event
  }

  getQuestionNumber(): QuestionNumberFe {
    return this.question as QuestionNumberFe
  }

  getQuestionText(): QuestionTextFe {
    return this.question as QuestionTextFe
  }
  getQuestionDate(): QuestionDateFe {
    return this.question as QuestionDateFe
  }

  getQuestionEmissionFactor(): QuestionEmissionFactorFe {
    return this.question as QuestionEmissionFactorFe
  }

  filterNumberInput(index: number, event: KeyboardEvent) {
    if (InputFilterUtil.isValidNumberInput(event)) {
      this.updateNumberValue(index, this.predefinedOptions[index].value)
    } else {
      event.preventDefault()
    }
  }

  updateNumberValue(index: number, newValue: string): void {
    const isValidNumber = /^(-?\d*\.?\d*)?$/.test(newValue)
    if (isValidNumber) {
      this.predefinedOptions[index].value = parseFloat(newValue)
    }
  }

  openModal(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal
    const customClass = options.class ?? this.modals[modal].class
    const template = this.modals[modal].template
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    })
  }

  closeModal({ modal }) {
    const modalRef = this.modals[modal].ref
    if (modalRef) {
      this.modalService.hide(modalRef.id)
    }
  }

  showRemoveAllModal() {
    this.openModal({
      modal: ModalNames.removeAllModal,
      ignoreBackdropClick: false
    })
  }

  cancelRemoveAllModal() {
    this.closeModal({ modal: ModalNames.removeAllModal })
  }

  showSelectPlaceHolder(unit) {
    if (!unit) return true
    return false
  }

  skipInitNoUnit() {
    return (
      this.getQuestionNumber().measurementKey !== NoUnit.MEASUREMENT_KEY ||
      (this.getQuestionNumber().measurementKey === NoUnit.MEASUREMENT_KEY &&
        !this.getQuestionNumber()?.newAnswer?.isSubmitted)
    )
  }

  onChangeAdditionAllowed(val: boolean) {
    this.customValuesAllowedWhenPredefinedOptionsSet = val
    ;(this.question as any).customValuesAllowedWhenPredefinedOptionsSet = val
  }
}
