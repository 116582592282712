export class OperatorFe {
  static readonly MUL = new OperatorFe('mul', '*') //create
  static readonly DIV = new OperatorFe('div', '/') //create
  static readonly ADD = new OperatorFe('add', '+') //create
  static readonly SUB = new OperatorFe('sub', '-') //create

  private constructor(
    public readonly key: string,
    public readonly symbol: any
  ) {}

  public static opByKey(key) {
    switch (key) {
      case this.MUL.key:
        return OperatorFe.MUL
      case this.DIV.key:
        return OperatorFe.DIV
      case this.ADD.key:
        return OperatorFe.ADD
      case this.SUB.key:
        return OperatorFe.SUB
    }
  }

  public toString(): string {
    return this.symbol
  }

  public tooltip(): string {
    return this.symbol
  }
}
