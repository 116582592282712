import { DataGridTableData } from 'src/app/components/projects/data-grid-ui/model/DataGridTableData'
import { KpiDefFe } from '../kpi/KpiDefFe'
import { OrganizationEntityFe } from '../organization/OrganizationEntityFe'
import { EntityFe } from '../taxonomy/EntityFe'
import { ColumnSchemaFe } from './ColumnSchemaFe'
import { ReferenceColumnSchemaFe } from './ReferenceColumnSchemaFe'
import { RowFe } from './RowFe'
import { TableDefFe } from './TableDefFe'
import { ColumnTypeFe } from './ColumnTypeFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { Language } from 'src/app/utils/language/Language'

export class TableDataFe extends TableDefFe {
  public rows: RowFe[] = []
  public rowCount: number
  public pageSize: number
  public pageNumber: number
  public ordered: boolean = false

  public entity: EntityFe | OrganizationEntityFe
  public entitySelectedFilter: boolean = false
  public dataSelectedFilter: boolean = false

  public numberOfFileredRows: number = 0

  public addEntityToFilter() {
    this.entitySelectedFilter = true
  }

  public removeEntityFromFilter() {
    this.entitySelectedFilter = false
  }

  public addDataToFilter(rowId: string) {
    let row = this.rows.find((row) => (row.rowId = rowId))
    row.selectedInFilter = true
  }

  public removeDataFromFilter(rowId: string) {
    let row = this.rows.find((row) => (row.rowId = rowId))
    row.selectedInFilter = false
  }

  public toggleRowInFilter(row: RowFe) {
    if (row.selectedInFilter) {
      row.selectedInFilter = false
      this.numberOfFileredRows -= 1
    } else {
      row.selectedInFilter = true
      this.numberOfFileredRows += 1
    }
  }

  public getSingleRowInFilter(): RowFe {
    let row = this.rows.find((row) => row.selectedInFilter)
    return row
  }

  public static fromTransfer(
    transfer: any,
    entity: EntityFe | OrganizationEntityFe,
    langCode: string,
    showUnitCols = true
  ): TableDataFe {
    let tableData = new TableDataFe()
    tableData.entity = entity
    tableData.key = transfer['key']
    tableData.label = entity.label[langCode] || entity.label['en']
    tableData.name = transfer['name']
    tableData.ordered = transfer['ordered']
    let referenceSchema: any[] = transfer['referenceSchema']
    let managementSchema: any[] = transfer['managementSchema']
    let dataSchema: any[] = transfer['dataSchema']
    if (referenceSchema) {
      let refColSchema = referenceSchema.map((dbCol) => ReferenceColumnSchemaFe.fromTransfer(dbCol))
      tableData.referenceSchema = refColSchema
    }

    if (dataSchema) {
      let columnSchema = dataSchema.map((dbCol) => {
        let colName = dbCol['name']
        let entityLabel = ''
        let isCalculated = false
        let isPreDetermined = false
        let emissionFactor = null
        let attributeUnit = null
        let datatype = dbCol.type
        if (entity instanceof EntityFe) {
          let dbColumn = entity.columns.find((c) => c.dbColName() == colName)
          if (dbColumn) {
            entityLabel = dbColumn.label[langCode] || dbColumn.label['en']
            isCalculated = dbColumn.providedBy == 'calculation'
            isPreDetermined = dbColumn.providedBy == 'pre_determined'
            emissionFactor = !isPreDetermined ? dbColumn.emissionFactors[0]?.value : null
            attributeUnit = dbColumn.unit
            datatype = dbColumn.datatype
            if (dbColumn.unit && dbColumn.unit.symbol) {
              entityLabel += ` (${dbColumn.unit.symbol})`
            }
          }
        } else if (entity instanceof OrganizationEntityFe) {
          let dbColumn = entity.attributes.find((a) => a.dbColName() == colName)
          if (dbColumn) {
            entityLabel = dbColumn.label
            datatype = dbColumn.datatype
          }
        }

        let colSchema = ColumnSchemaFe.fromTransfer(
          dbCol,
          entityLabel,
          isCalculated,
          attributeUnit,
          showUnitCols,
          datatype,
          isPreDetermined,
          emissionFactor
        )
        return colSchema
      })

      tableData.dataSchema = columnSchema
    }

    if (managementSchema) {
      let mgmtColSchema = managementSchema.map((dbCol) => {
        let colName = dbCol['name']
        let colSchema = ColumnSchemaFe.fromTransfer(dbCol, colName)
        return colSchema
      })
      tableData.managementSchema = mgmtColSchema
    }

    let rows: any[] = transfer['rows']
    tableData.rows = rows.map((row, i) => RowFe.fromTransfer(row, i))
    tableData.rowCount = transfer['rowCount']
    tableData.pageSize = transfer['pageSize']
    tableData.pageNumber = transfer['pageNumber']
    return tableData
  }

  public toDataGridTableData(): DataGridTableData {
    let dgTableData = new DataGridTableData()
    dgTableData.key = this.key
    dgTableData.name = this.label
    dgTableData.ordered = this.ordered
    dgTableData.pageNumber = this.pageNumber
    dgTableData.pageSize = this.pageSize
    dgTableData.rowCount = this.rowCount
    dgTableData.rows = this.rows.map((row) => row.toDataGridRow())
    dgTableData.referenceSchema = this.referenceSchema.map((sc) => sc.toDataGridColumnSchema())
    dgTableData.dataSchema = this.dataSchema.map((sc) => sc.toDataGridColumnSchema())
    dgTableData.managementSchema = this.managementSchema.map((sc) => sc.toDataGridColumnSchema())
    return dgTableData
  }

  public static fromDataGridTableData(dgtd: DataGridTableData): TableDataFe {
    let tableData = new TableDataFe()
    tableData.key = dgtd.key
    tableData.label = dgtd.name
    tableData.ordered = dgtd.ordered
    tableData.pageNumber = dgtd.pageNumber
    tableData.pageSize = dgtd.pageSize
    tableData.rowCount = dgtd.rowCount
    tableData.rows = dgtd.rows.map((row) => RowFe.fromDataGridRow(row))
    tableData.referenceSchema = dgtd.referenceSchema.map((sc) => ReferenceColumnSchemaFe.fromDataGridColumnSchema(sc))
    tableData.dataSchema = dgtd.dataSchema.map((sc) => ColumnSchemaFe.fromDataGridColumnSchema(sc))
    tableData.managementSchema = dgtd.managementSchema?.map((sc) => ColumnSchemaFe.fromDataGridColumnSchema(sc)) || []
    return tableData
  }

  public toTransfer(): TableDataFe {
    let tableData = new TableDataFe()
    tableData.key = this.key
    tableData.name = this.name
    tableData.ordered = this.ordered
    tableData.pageNumber = this.pageNumber
    tableData.pageSize = this.pageSize
    tableData.rowCount = this.rowCount
    tableData.rows = this.rows
    tableData.referenceSchema = this.referenceSchema
    tableData.managementSchema = this.managementSchema
    tableData.dataSchema = this.dataSchema
    return tableData
  }
}
