import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'newlineToBr',
  pure: true
})
export class NewlineToBrPipe implements PipeTransform {
  transform(value: string): string {
    // Check if the value is defined and a string, replace newlines with <br>
    return value ? value.replace(/\n/g, '<br>') : value
  }
}
