import { DataPointFe } from './DataPointFe'

export class GroupingFe {
  public id: string
  public label: string
  public dataPoints: DataPointFe[] = []

  constructor() {}

  public static fromTransfer(transfer: any): GroupingFe {
    let grouping = new GroupingFe()
    grouping.id = transfer.id
    grouping.label = transfer.label
    grouping.dataPoints = transfer.dataPoints.map((dataPoint) => DataPointFe.fromTransfer(dataPoint))
    return grouping
  }

  setId(index) {
    this.id = '_grouping_' + index
  }
}
