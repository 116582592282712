import { Chart } from 'chart.js'
import { IdUtil } from 'src/app/utils/IdUtil'
import { InsightFe } from '../insight/InsightFe'
import { ChartSettingFeNew } from './ChartSettingFeNew'
import { ChartOptionsFeNew } from './ChartOptionsFeNew'
import { KpiDataGridIntergationService } from 'src/app/components/kpi/KpiDataGridIntergationService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DataGridTableMode } from 'src/app/components/projects/data-grid-ui/model/DataGridTableMode'

export class ChartWrapperFeNew {
  public insight: InsightFe
  public options: ChartOptionsFeNew
  public setting: ChartSettingFeNew
  public chart: Chart
  public hasError: boolean = false
  public errors: string[] = []
  public tempId: string = IdUtil.next()

  public static build(
    insight: InsightFe,
    setting: ChartSettingFeNew | undefined = undefined,
    stateService: StateServiceFe | undefined = undefined
  ): ChartWrapperFeNew {
    let errors: string[] = []
    let wrapper = new ChartWrapperFeNew()
    wrapper.insight = insight
    if (!insight) {
      errors.push('The connected KPI is not longer available!')
    } else {
      if (!insight.kpis) {
        errors.push('The connected KPI has no result!')
      } else {
        if (insight.errorCode || insight.kpis.find((k) => k.errorCode)) {
          errors.push('Error in calculating some of the results!')
        }
        try {
          if (!setting) {
            setting = ChartSettingFeNew.buildInstance(insight)
          }
          if (!setting.options) {
            setting.options = ChartOptionsFeNew.buildInstance(insight)
          }
        } catch (error) {
          errors.push(error.message)
        }
      }
    }

    if (setting) {
      wrapper.setting = setting
      wrapper.options = setting.options
      if (stateService && wrapper.insight) {
        wrapper.insight.kpiDataGridService = new KpiDataGridIntergationService(wrapper.insight, stateService)
        wrapper.insight.kpiDataGridService.mode = DataGridTableMode.VIEW
        wrapper.insight.kpiDataGridService.setTable(setting, false)
      }
    }

    wrapper.hasError = errors.length > 0 ? true : false
    wrapper.errors = errors
    return wrapper
  }

  public renderTable() {
    this.insight.kpiDataGridService.renderTable()
  }
}
