import { MathStatementFe } from './MathStatementFe'
import { OperatorFe } from './OperatorFe'
import { StatementBuilderFe } from './StatementBuilderFe'

export class TwoOperandStatementFe extends MathStatementFe {
  public static TYPE_KEY = 'TWO_OPS'
  public type = TwoOperandStatementFe.TYPE_KEY

  public leftStatement: MathStatementFe = new MathStatementFe(this, 'L')
  public rightStatement: MathStatementFe = new MathStatementFe(this, 'R')
  public operator: OperatorFe

  constructor() {
    super()
  }

  public toString(): string {
    return '(' + this.leftStatement + ' ' + this.operator + ' ' + this.leftStatement + ')'
  }

  public flatten(): any[] {
    return ['('].concat(this.leftStatement.flatten(), this.operator.symbol, this.rightStatement.flatten(), [')'])
  }

  isPlaceholder() {
    return false
  }
}
