import { ManagerFe } from '../../user/ManagerFe'
import COUNTRIES_DATA from '../../COUNTRIES.json'
import { ContactFe } from '../../user/ContactFe'
import { ManagerInvitationFe } from '../../invitation/ManagerInvitationFe'
import { ContactInvitationFe } from './ContactInvitationFe'
import { AbstractSignInMethodFe } from '../../signInMethod/AbstractSignInMethodFe'
import { SignInMethodConversionFe } from '../../signInMethod/SignInMethodConversionFe'
export class CompanyTypeFe {
  public static ROOT = 'ROOT'
  public static MANAGEMENT = 'MANAGEMENT'
  public static SUPPLIER = 'SUPPLIER'
}

export class CompanyFe {
  id: string
  name: string
  companyAccountType: string
  companyAccountPurpose: string
  countryCode: string
  industryGroup: string
  industry: string
  website: string
  signInMethods: AbstractSignInMethodFe[]

  country: string
  managers: ManagerFe[] = []
  managerInvitations: ManagerInvitationFe[] = []
  dataOwners: ContactFe[] = []
  doInvitations: ContactInvitationFe[] = []
  deactivatedManagers: ManagerFe[] = []
  deactivatedDOs: ContactFe[] = []
  selectedOption = 'SM'

  isSelected = false

  constructor(
    id: string,
    name: string,
    companyAccountType: string,
    companyAccountPurpose: string,
    country: string,
    industryGroup: string | null,
    industry: string | null,
    website: string | null,
    signInMethods: AbstractSignInMethodFe[]
  ) {
    this.id = id
    this.name = name
    this.companyAccountType = companyAccountType
    this.companyAccountPurpose = companyAccountPurpose
    this.countryCode = country
    this.industryGroup = industryGroup
    this.industry = industry
    this.website = website
    this.signInMethods = signInMethods
    this.setCountry()
  }

  public static fromTransfer(transfer: any): CompanyFe {
    let signInMethods = transfer.signInMethods?.map((o: any) => SignInMethodConversionFe.fromTransfer(o, true))
    let companyFe = new CompanyFe(
      transfer.id,
      transfer.name,
      transfer.type,
      transfer.purpose,
      transfer.countryCode,
      transfer.industryGroup,
      transfer.industry,
      transfer.website,
      signInMethods
    )
    return companyFe
  }

  setCountry() {
    let data = Object.entries(COUNTRIES_DATA).find((data) => data[0] == this.countryCode)
    this.country = data ? data[1]['en'] : ''
  }
}
