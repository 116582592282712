import { TaxonomyInfoFe } from '../taxonomy/TaxonomyInfoFe'
import { EntityFe } from '../taxonomy/EntityFe'

export class TaxonomyFilterFe {
  taxonomyInfo: TaxonomyInfoFe
  rootScopes: EntityFe[] = []
  selectedEntity: EntityFe

  constructor(taxonomyInfo: TaxonomyInfoFe) {
    this.taxonomyInfo = taxonomyInfo
    let s0 = this.taxonomyInfo.childrenSortedByOrdinal()
    s0.forEach((s) => {
      let s1s = this.taxonomyInfo.childrenSortedByOrdinal(s.key)
      s1s.forEach((s1) => this.rootScopes.push(s1))
    })
  }

  subScopes(entity: EntityFe): EntityFe[] {
    return this.taxonomyInfo.childrenSortedByOrdinal(entity.key)
  }

  selectedEntityColor(): string {
    if (this.selectedEntity) {
      return this.taxonomyInfo.color(this.selectedEntity)
    } else {
      return undefined
    }
  }

  selectedEntityIcon(): string {
    if (this.selectedEntity) {
      return this.taxonomyInfo.icon(this.selectedEntity)
    } else {
      return undefined
    }
  }

  entityColor(entity: EntityFe): string {
    if (entity) {
      return this.taxonomyInfo.color(entity)
    } else {
      return undefined
    }
  }

  entityIcon(entity: EntityFe): string {
    if (entity) {
      return this.taxonomyInfo.icon(entity)
    } else {
      return undefined
    }
  }

  /*   public setFirstEntity(entity: EntityFe) {
    if (!entity) {
      this.firstEntity = undefined
      this.secondEntity = undefined
      this.secondEntityOptions = []
      this.thirdEntity = undefined
      this.thirdEntityOptions = []
    } else if (!this.firstEntity || this.firstEntity.key != entity.key) {
      this.firstEntity = entity
      this.secondEntity = undefined
      this.secondEntityOptions = this.taxonomyInfo.childrenSortedByOrdinal(this.firstEntity.key)
      this.thirdEntity = undefined
      this.thirdEntityOptions = []
    }
  }

  public setSecondEntity(entity: EntityFe) {
    if (!entity) {
      this.secondEntity = undefined
      this.thirdEntity = undefined
      this.thirdEntityOptions = []
    } else if (!this.secondEntity || this.secondEntity.key != entity.key) {
      this.secondEntity = entity
      this.thirdEntity = undefined
      this.thirdEntityOptions = this.taxonomyInfo.childrenSortedByOrdinal(this.secondEntity.key)
    }
  }

  public setThirdEntity(entity: EntityFe) {
    if (!entity) {
      this.thirdEntity = undefined
    } else if (!this.thirdEntity || this.thirdEntity.key != entity.key) {
      this.thirdEntity = entity
    }
  } */
}
