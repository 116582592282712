import { CustomRepeatScheduleFe } from './CustomRepeatScheduleFe'

export class RepeatScheduleFe {
  seedDate: string
  type: string
  preset: string
  presetCron: string
  custom: CustomRepeatScheduleFe

  constructor(seedDate: string, type: string, preset: string, presetCron: string, custom: CustomRepeatScheduleFe) {
    this.seedDate = seedDate
    this.type = type
    this.preset = preset
    this.presetCron = presetCron
    this.custom = custom
  }

  public static fromTransfer(transfer: any): RepeatScheduleFe {
    let sche = new RepeatScheduleFe(
      transfer.seedDate,
      transfer.type,
      transfer.preset,
      transfer.presetCron,
      CustomRepeatScheduleFe.fromTransfer(transfer.custom)
    )
    return sche
  }

  toJSON(): any {
    const { ...data } = this
    return data
  }
}
