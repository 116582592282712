export const UnitPrefixes = [
  { name: 'deca', symbol: 'da' },
  { name: 'hecto', symbol: 'h' },
  { name: 'kilo', symbol: 'k' },
  { name: 'mega', symbol: 'M' },
  { name: 'giga', symbol: 'G' },
  { name: 'tera', symbol: 'T' },
  { name: 'peta', symbol: 'P' },
  { name: 'exa', symbol: 'E' },
  { name: 'zetta', symbol: 'Z' },
  { name: 'yotta', symbol: 'Y' },
  { name: 'ronna', symbol: 'R' },
  { name: 'quetta', symbol: 'Q' },
  { name: 'deci', symbol: 'd' },
  { name: 'centi', symbol: 'c' },
  { name: 'milli', symbol: 'm' },
  { name: 'micro', symbol: 'u' },
  { name: 'nano', symbol: 'n' },
  { name: 'pico', symbol: 'p' },
  { name: 'femto', symbol: 'f' },
  { name: 'atto', symbol: 'a' },
  { name: 'zepto', symbol: 'z' },
  { name: 'yocto', symbol: 'y' },
  { name: 'ronto', symbol: 'r' },
  { name: 'quecto', symbol: 'q' }
]
