<div class="modal-header main-modal-header" style="border-bottom: none">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
    {{ locale('locale_key.add_data_point.add_new_data_category') }}
  </h4>
  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.pages.data_category.label.category_level_1') }}</p>
    <select
      class="form-control col-11 mb-1"
      [formControl]="item0Control"
      [ngClass]="{ 'is-invalid': item0Control.errors && item0Control.touched }"
      (change)="onRootTaxonomyChange()"
    >
      <option value="">
        {{ locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_data_category_main_topic.placholder') }}
      </option>
      <option [value]="item0.key" *ngFor="let item0 of taxonomy?.childrenSortedByOrdinal()">
        {{ item0.getLabel() }}
      </option>
    </select>
  </div>
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.pages.data_category.label.category_level_2') }}</p>
    <select
      class="form-control col-11 mb-1"
      [formControl]="item1Control"
      [ngClass]="{ 'is-invalid': item1Control.errors && item1Control.touched }"
    >
      <option value="">
        {{ locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_data_category_main_topic.placholder') }}
      </option>
      <ng-container *ngIf="item0Control.value">
        <option [value]="item1.key" *ngFor="let item1 of taxonomy?.childrenSortedByOrdinal(item0Control.value)">
          {{ item1.getLabel() }}
        </option>
      </ng-container>
    </select>
  </div>
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.pages.data_category.label.new_category_name') }}</p>
    <div *ngIf="!isLanguageEnglish()" class="d-flex flex-row">
      <i [class]="getCategoryLanguageFlag()" style="margin-right: 12px"></i>
      <input
        class="form-control mb-0"
        placeholder="{{ locale('locale_key.pages.data_category.label.enter.new_category_name') }}"
        [formControl]="dataCategoryNameLang"
        [ngClass]="{ 'is-invalid': dataCategoryNameLang.errors && dataCategoryNameLang.touched }"
      />
    </div>
    <br *ngIf="!isLanguageEnglish()" />
    <div class="d-flex flex-row">
      <i *ngIf="!isLanguageEnglish()" class="fs-4 fi fi-us" style="margin-right: 12px"></i>
      <input
        class="form-control mb-0"
        placeholder="{{ locale('locale_key.pages.data_category.label.enter.new_category_name') }}"
        [formControl]="dataCategoryNameEn"
        [ngClass]="{ 'is-invalid': dataCategoryNameEn.errors && dataCategoryNameEn.touched }"
      />
    </div>
  </div>
  <div class="info" style="display: flex; align-items: center">
    <i class="info las la-info-circle" style="font-size: 20px; margin-right: 6px"></i>
    <span>{{ locale('locale_key.pages.data_category.label.calculations.can_be_defined') }}</span>
  </div>
  <span>{{ locale('locale_key.pages.data_category.label.any_new_data_categories') }}</span>
</div>
<div class="modal-footer" style="border-top: none">
  <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
    {{ locale('locale_key.general.buttons.cancel') }}
  </button>
  <button type="button" class="btn primary-button custom-button" (click)="addEntity()">
    {{ locale('locale_key.general.buttons.add') }}
  </button>
</div>
