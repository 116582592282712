import { ValueStatementFe } from './ValueStatementFe'

export class NullValueStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'NULL'
  public type = NullValueStatementFe.TYPE_KEY
  public static START_KEYWORD = '~'

  constructor(value = null) {
    super(value)
  }

  public toFormulaString(): string {
    let str = [`${NullValueStatementFe.START_KEYWORD}`, `${this.value}`].join('')
    return str
  }

  public static fromFormulaString(formula: string): NullValueStatementFe {
    return new NullValueStatementFe()
  }

  public evaluate(values: Map<string, any>): number {
    return this.value
  }

  public static fromTransfer(transfer: any): NullValueStatementFe {
    let statement = new NullValueStatementFe()
    return statement
  }
}
