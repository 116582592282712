import { DateUtil } from 'src/app/utils/DateUtil'

export class EmissionFactorTypeFe {
  public static GLOBAL_EMISSION_FACTOR = 'GLOBAL_EMISSION_FACTOR'
  public static CUSTOM_EMISSION_FACTOR = 'CUSTOM_EMISSION_FACTOR'
}

export abstract class AbstractEmissionFactorFe {
  constructor(
    public id: string,
    public type: string,
    public sourceId: string,
    public sourceName: string,
    public sourceUnit: string,
    public conversionFactor: number,
    public conversionUnit: string,
    public origin: string,
    public additionalDetails: { [key: string]: string }
  ) {}

  getAdditionDetails(): any[] {
    return Object.entries(this.additionalDetails)
  }

  getUnit(): string {
    return `${this.conversionUnit} / ${this.sourceUnit}`
  }

  getDateString(date) {
    if (date) return DateUtil.toString3(date)
  }
}
