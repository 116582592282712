import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from 'src/app/model/project/CSRDProjectInfoFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { PROJECTS_PAGES } from '../../../projects.component'
import { CSRD_PROJECT_PAGES } from '../csrd-project-overview.component'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { DR_DATAPOINTS_PAGES, DrDatapointsComponent } from './dr-datapoints/dr-datapoints.component'
import _ from 'lodash'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { Subscription } from 'rxjs'
import { CsrdDefintionService } from '../../CsrdDefintionService'

export enum DR_TABS {
  'OVERVIEW' = 'OVERVIEW',
  'DATAPOINTS' = 'DATAPOINTS',
  'REQUESTS' = 'REQUESTS',
  'COLLECTED_DATA' = 'COLLECTED_DATA'
}

@Component({
  selector: 'csrd-dr',
  templateUrl: './dr.component.html',
  styleUrls: ['./dr.component.scss']
})
export class DrComponent extends AbstractLanguageComponent implements OnInit {
  public DR_TABS = DR_TABS
  public DR_STATUSES = CSRDDisclosureSettingsStatus
  drStatus = CSRDDisclosureSettingsStatus.NOT_STARTED

  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>()
  @Output() switchCsrdProjectPage = new EventEmitter<CSRD_PROJECT_PAGES>()
  @Input() activeCsrdProject: CSRDProjectInfoFe
  @Input() activeTopic: any
  @Input() activeEsrs: any
  @Input() activeDr: any

  @ViewChild(DrDatapointsComponent) drDatapointsComponent!: DrDatapointsComponent

  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left',
        actionName: 'back',
        visible: () => true
      }
    ],
    [
      {
        shortLabel: this.slocale('All projects'),
        longLabel: this.slocale('All projects'),
        tooltip: this.slocale('All projects'),
        icon: 'la la-project-diagram',
        visible: () => true,
        actionName: 'all_projects'
      }
    ]
    // [
    //   {
    //     shortLabel: this.slocale("Home"),
    //     longLabel: this.slocale("Home"),
    //     tooltip: this.slocale("Home"),
    //     icon: "la la-home",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.export"),
    //     icon: "las la-download",
    //     actionName: "export_table",
    //     visible: () => true,
    //     disabled: false
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.info"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     icon: "la la-info",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ]
  ]

  activeDrTab: DR_TABS = DR_TABS.OVERVIEW
  url: string
  subscription: Subscription = new Subscription()

  constructor(
    languageService: LanguageService,
    public displayService: DisplayServiceFe,
    private StateService: StateServiceFe,
    private cd: ChangeDetectorRef,
    public drService: CsrdDefintionService
  ) {
    super(languageService)
  }

  ngOnInit(): void {
    const { newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings()
    this.drStatus = newDisclosureSettings.status
    this.url = window.location.href
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  canSwitchCsrdProjectPage() {
    let canSwitch = true
    if (this.activeDrTab == DR_TABS.DATAPOINTS) {
      canSwitch = this.drDatapointsComponent.canSwitchCsrdProjectPage()
    }
    return canSwitch
  }

  goSwitchCsrdProjectPage() {
    this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW)
  }

  canSwitchProjectsPage() {
    let canSwitch = true
    if (this.activeDrTab == DR_TABS.DATAPOINTS) {
      canSwitch = this.drDatapointsComponent.canSwitchProjectsPage()
    }
    return canSwitch
  }

  goSwitchProjectsPage() {
    this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
    this.activeCsrdProject = null
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'back':
        // Close side panel
        this.displayService.closeSubject.next(true)
        if (this.canSwitchCsrdProjectPage()) {
          this.goSwitchCsrdProjectPage()
        }
        break
      case 'all_projects':
        // Close side panel
        this.displayService.closeSubject.next(true)
        if (this.canSwitchProjectsPage()) {
          this.goSwitchProjectsPage()
        }
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  switchActiveDrTab({ tab }: { tab: DR_TABS }) {
    // Close side panel
    this.displayService.closeSubject.next(true)
    //TO DO : Disable switching to REQUESTS and COLLECTED DATA tab until requests has been implemented
    // if (tab == DR_TABS.REQUESTS || tab == DR_TABS.COLLECTED_DATA) {
    //   return;
    // }
    this.activeDrTab = tab
    //this.boot();
  }

  startAssessment() {
    this.activeDrTab = DR_TABS.DATAPOINTS
    setTimeout(() => {
      this.drDatapointsComponent.startAssessment()
    }, 1)
  }

  startDataRequest() {
    this.switchActiveDrTab({ tab: DR_TABS.REQUESTS })
  }

  getNewTopicEsrsAndDisclosureSettings(): {
    newTopicSettings: CSRDTopicSettings
    newEsrsSettings: CSRDEsrsSettings
    newDisclosureSettings: CSRDDisclosureSettings
  } {
    let newTopicSettings: CSRDTopicSettings
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings)
      }
    })

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      }
    }

    let newEsrsSettings: CSRDEsrsSettings
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings)
      }
    })

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: !!this.activeEsrs.isMandatory,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      }
    }

    let newDisclosureSettings: CSRDDisclosureSettings
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == this.activeDr.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings)
      }
    })

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: this.activeDr.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        isMandatory: !!this.activeDr.isMandatory,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      }
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings }
  }
}
