<div class="modal-header main-modal-header">
  <i class="la la-sign-out me-1" style="font-size: 25px"></i>
  <h4 class="modal-title mb-0 pb-0 ms-2 text-white me-auto text-truncate" id="myModalLabel12" *ngIf="type=='company'">
    {{locale('locale_key.pages.clients.signin_settings_modal.title', {object_name:selectedCompany.name}) }}
  </h4>
  <h4 class="modal-title mb-0 pb-0 ms-2 text-white me-auto text-truncate" id="myModalLabel12" *ngIf="type=='user'">
    {{locale('locale_key.pages.clients.signin_settings_modal.title', {object_name:selectedUser.getName()}) }}
  </h4>
  <button type="button" class="btn close" *ngIf="!inProgress" aria-label="Close" (click)="close()">
    <span aria-hidden="true"><i class="las la-times text-white"></i></span>
  </button>
</div>
<div id="accordionWrap" class="modal-body mb-1 text-justify" [formGroup]="form">
  <h5 class="my-1" *ngIf="type=='company'">
    {{locale('locale_key.pages.clients.signin_settings_modal.description_company', {company:selectedCompany.name}) }}
  </h5>
  <h5 class="my-1" *ngIf="type=='user'">
    {{locale('locale_key.pages.clients.signin_settings_modal.description_user', {user:selectedUser.getName()}) }}
  </h5>
  <div class="mb-1 row-design" style="height: 69px">
    <div class="d-flex flex-row mx-auto w-100 my-1 py-3">
      <div class="d-flex flex-row justify-content-between w-100">
        <div class="d-flex flex-row">
          <img
            src="../../../assets/img/logos/Logo-Sustainlab.png"
            class="ms-1 me-2 pt-1 my-auto"
            style="height: 30px"
          />
          <h5 class="my-auto">
            SustainLab {{locale('locale_key.pages.clients.signin_settings_modal.sustainlab_email_password')}}
          </h5>
        </div>
        <label class="switch my-auto ms-auto mt-2">
          <input type="checkbox" formControlName="passwordEnabled" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row mx-auto w-100 my-1 py-3 mb-3 my-auto pe-1 row-design" style="height: 69px">
    <div class="d-flex flex-row justify-content-between w-100 my-auto">
      <div class="d-flex flex-row my-auto">
        <img
          src="../../../assets/img/logos/google_logo-removebg-preview.png"
          style="height: 30px; width: 30px"
          class="ms-1 me-2 my-auto"
        />
        <h5 class="my-auto">Google</h5>
      </div>
      <label class="switch my-auto ms-auto mt-2 me-0">
        <input type="checkbox" formControlName="googleEnabled" />
        <span class="slider round my-auto"></span>
      </label>
    </div>
  </div>
  <div role="tablist" aria-multiselectable="true" class="px-0 pt-1 mt-3 mb-0">
    <div class="accordion accordion-icon-rotate mb-50 row-design">
      <a [id]="'item'" class="p-0 d-flex flex-row w-100" style="height: 69px">
        <div class="d-flex flex-row mx-auto w-100 my-1 py-3 my-auto pe-1">
          <a
            class="col-10"
            data-bs-toggle="collapse"
            [href]="'#accordion-msft'"
            aria-expanded="true"
            [attr.aria-controls]="'accordion'"
            (click)="isMicrosoftOpen = !isMicrosoftOpen"
          >
            <div class="d-flex flex-row justify-content-between w-100 my-auto">
              <div class="d-flex flex-row my-auto">
                <img
                  src="../../../assets/img/logos/microsoft_logo.jpg"
                  style="height: 30px; width: 30px"
                  class="ms-1 me-2 my-auto"
                />
                <h5 class="my-auto">Microsoft</h5>
              </div>
              <div class="ms-auto my-auto">
                <a
                  class="mt-3 ms-auto float-end mb-3 collapsed"
                  [ngClass]="isMicrosoftOpen ? 'la la-angle-up' : 'la la-angle-down'"
                  data-bs-toggle="collapse"
                  [href]="'#accordion-msft'"
                  aria-expanded="true"
                  [attr.aria-controls]="'accordion'"
                  (click)="isMicrosoftOpen = !isMicrosoftOpen"
                ></a>
              </div>
            </div>
          </a>
          <div class="col-2 float-end text-end my-auto me-0 pe-0">
            <label class="switch my-auto ms-auto mt-2 me-0">
              <input type="checkbox" formControlName="microsoftEnabled" />
              <span class="slider round my-auto"></span>
            </label>
          </div>
        </div>
      </a>
      <div
        [id]="'accordion-msft'"
        role="tabpanel"
        data-bs-parent="#accordionWrap"
        attr.aria-labelledby="item"
        class="collapse mt-0 pt-0 border-t w-100"
      >
        <div class="card-content px-0" *ngIf="type == 'company'">
          <div class="card-body px-50">
            <h5 class="mb-2">
              {{locale('locale_key.pages.clients.signin_settings_modal.microsoft_tenant_instructions')}}
            </h5>
            <div class="row mb-0">
              <div class="col-4 text-left">
                <label class="me-auto">
                  {{locale('locale_key.pages.clients.signin_settings_modal.microsoft_tenant_id')}}
                </label>
              </div>
              <div class="col-8">
                <label>
                  {{locale('locale_key.pages.clients.signin_settings_modal.microsoft_tenant_id.description')}}
                </label>
              </div>
            </div>
            <ng-container formArrayName="tenants">
              <ng-container *ngFor="let tenantForm of tenants.controls; let i = index">
                <div class="row px-0 mt-2" [formGroup]="tenantForm">
                  <div class="col-4" style="max-width: 100%">
                    <div class="my-auto d-flex flex-row justify-content-between w-100" style="max-width: 100%">
                      <label class="ml-auto d-inline mt-1" *ngIf="selectedTenant === i">
                        <i class="la la-graduation-cap my-auto" style="max-width: 20px"></i>
                      </label>
                      <input
                        type="text"
                        class="form-control d-inline"
                        required
                        placeholder="Tenant ID"
                        formControlName="id"
                        style="max-width: 100%"
                      />
                    </div>
                  </div>
                  <div class="col-7" *ngIf="tenantToRemoveIdx != i">
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="Tenant description"
                      formControlName="description"
                    />
                  </div>
                  <div class="col-7" *ngIf="tenantToRemoveIdx == i">
                    <p class="mb-0 pb-0">{{locale('locale_key.pages.clients.signin_settings_modal.remove_tenant')}}</p>
                    <div class="d-flex flex-row">
                      <button
                        class="btn secondary-button custom-button"
                        (click)="cancelRemoveTenant(i)"
                        [ngClass]="screenSize.isXSmallSize() ? 'mt-1 me-2' : 'ms-1'"
                      >
                        {{locale('locale_key.pages.clients.signin_settings_modal.button.cancel_removal_tenant')}}
                      </button>
                      <button
                        class="btn primary-button custom-button"
                        (click)="removeTenant(i)"
                        [ngClass]="screenSize.isXSmallSize() ? 'mt-1' : 'ms-1'"
                      >
                        {{locale('locale_key.pages.clients.signin_settings_modal.button.remove_tenant')}}
                      </button>
                    </div>
                  </div>
                  <div class="col-1" *ngIf="tenantToRemoveIdx != i">
                    <div class="btn-group">
                      <div
                        class="description-btn d-flex justify-content-end remove-arrow-dropdown"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="la la-ellipsis-h fs-4 mt-auto my-auto" style="rotate: 90deg"></i>
                      </div>
                      <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                        <button class="dropdown-item ms-0 ps-1" type="button" (click)="setPrimaryTenant(i)">
                          <i class="la la-graduation-cap mr-25" style="position: relative; top: 3px"></i>
                          {{locale('locale_key.pages.clients.signin_settings_modal.button.set_as_primary')}}
                        </button>
                        <button class="dropdown-item ms-0 ps-1" type="button" (click)="confirmRemoveTenant(i)">
                          <i class="la la-trash-o"></i>
                          {{locale('locale_key.pages.clients.signin_settings_modal.button.confirm_remove_tenant')}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row px-0">
              <div class="col-12">
                <a (click)="addTenant()" class="me-auto ml-">
                  <i class="la la-plus ms-2 fs-4 mt-2"></i>
                  {{locale('locale_key.pages.clients.signin_settings_modal.button.add_tenant_id')}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content px-0 mt-2" *ngIf="type == 'user'" style="margin-bottom: 21px">
          <div class="col-lg-12 col-sm-12">
            <label>{{locale('locale_key.pages.clients.signin_settings_modal.assign_tenant_to_company')}}</label>
            <select
              class="form-select form-control w-50"
              (change)="selectTenant($event)"
              formControlName="selectedTenant"
            >
              <option value="" selected></option>
              <option
                *ngFor="let tenantForm of tenants.controls; let i = index"
                [value]="tenantForm.controls['id'].value"
              >
                {{ tenantForm.controls["id"].value }} - {{ tenantForm.controls["description"].value }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-2" />
  <div class="accordion accordion-icon-rotate row-design" *ngIf="type == 'user'" style="height: 69px">
    <div class="d-flex flex-row mx-auto w-100 my-1 py-3">
      <div class="d-flex flex-row justify-content-between w-100">
        <div class="d-flex flex-row">
          <i class="las la-mobile-alt fs-4 my-auto ms-2 me-2" style="color: var(--main-gradient-start-color)"></i>
          <h5 class="my-auto">{{locale('locale_key.pages.clients.signin_settings_modal.mfa_required')}}</h5>
        </div>
        <label class="switch my-auto ms-auto mt-2">
          <input type="checkbox" formControlName="mfaRequired" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="border-top: none">
  <ng-container *ngIf="!inProgress">
    <button type="button" class="btn secondary-button custom-button" (click)="close()">
      {{locale('locale_key.pages.clients.signin_settings_modal.button.cancel')}}
    </button>
    <button type="button" class="btn primary-button custom-button" (click)="saveAuthSettings()">
      {{locale('locale_key.pages.clients.signin_settings_modal.button.save')}}
    </button>
  </ng-container>
  <div class="ball-pulse loader-primary" *ngIf="inProgress">
    <div style="background-color: #405369"></div>
    <div style="background-color: #405369"></div>
    <div style="background-color: #405369"></div>
  </div>
</div>

<ng-template #mfaWarning>
  <div class="modal-header alert-modal-header">
    <div
      class="icon-background d-inline mt-2"
      style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite"
    >
      <i
        class="la la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -2px;
          top: -1px;
        "
      ></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12" *ngIf="!hasAffiliationsWithMfaEnabled">
      {{locale('locale_key.pages.clients.mfa_warning_modal.confirmation')}}
    </h4>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12" *ngIf="hasAffiliationsWithMfaEnabled">
      {{locale('locale_key.pages.clients.mfa_warning_modal.affiliation_exists')}}
    </h4>
  </div>
  <div class="modal-body">
    <h5 *ngIf="!hasAffiliationsWithMfaEnabled">
      {{locale('locale_key.pages.clients.mfa_warning_modal.confirmation')}}
    </h5>
    <h5 *ngIf="hasAffiliationsWithMfaEnabled">
      {{locale('locale_key.pages.clients.mfa_warning_modal.mfa_disbled_company')}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn btn-add white custom-button" (click)="closeModal()">
      {{ locale("locale_key.general.buttons.continue") }}
    </button>
  </div>
</ng-template>
