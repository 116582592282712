<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-start">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ slocale('PROJECTS') }}</h3>
              </a>
            </li>
            <ng-container>
              <li class="navbar-item">
                <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
              </li>
              <li class="navbar-item">
                <a>
                  <h3 class="mt-2">{{ projectTitle.value ? projectTitle.value : slocale('New CarbonLab Project') }}</h3>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ml-4 mt-"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important">
            <div class="pb-0">
              <div class="container-fluid">
                <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                  <div class="row text-center pb-3 pt-4">
                    <div class="col-4 mt-0 pt-0 mx-0 ps-1 pe-0">
                      <div
                        class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                      >
                        <div class="circle1 active-circle1 mx-0 px-0">1</div>
                        <h6 class="my-auto ms-1">{{ slocale('Learn about CarbonLab') }}</h6>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="col-4 text-start mx-0 px-0">
                      <div
                        class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                      >
                        <div class="circle1 active mx-0 px-0">2</div>
                        <h6 class="my-auto ms-1">{{ slocale('Pick project name') }}</h6>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="col-4 text-start mx-0 px-0">
                      <div
                        class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
                      >
                        <div class="circle1 active mx-0 px-0">3</div>
                        <h6 class="my-auto ms-1">{{ slocale('Select emissions categories') }}</h6>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()"
                >
                  <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                    <div class="d-block text-center">
                      <div
                        class="col stepper text-center"
                        *ngFor="let step of steps; let i = index"
                        [class.active-stepper]="currentActive === i + 1 || step.completed"
                      ></div>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mb-1">
                    <h6 class="text-center m-0">
                      {{ steps[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})
                    </h6>
                  </div>
                </ng-container>
              </div>
            </div>
            <hr class="mt-0 pt-0 mx-0 px-0" />
            <div
              class="card-body mt-0 pt-0 px-0"
              style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important"
              *ngIf="!pullDataFromPlatform && !bizTravel && !allProjects"
            >
              <!--NEW PROJECT PAGE-->
              <div *ngIf="activeFormNo == 1" class="px-3">
                <div
                  class="d-flex flex-column justify-content-around aling-items-center"
                  style="height: 100% !important"
                >
                  <div class="row px-3">
                    <h3>{{ slocale('Welcome to the CarbonLab!') }}</h3>
                    <p>
                      {{
                        slocale(
                          'This is the space where you can customize projects that calculate carbon emissions based on your company’s needs. SustainLab is here to guide you through each step of the process. Happy calculating!'
                        )
                      }}
                    </p>
                  </div>
                  <div class="container-fluid vh-auto d-flex flex-column justify-content-center align-items-center">
                    <div class="row w-100 justify-content-around mt-1 step-progress flex-wrap px-0">
                      <span class="dotted-line"></span>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-around align-items-center">
                            <i class="la la-cog proj-icon"></i>
                            <small class="card-title col">{{ slocale('Step 1') }}</small>
                            <h5 class="card-title col">{{ slocale('Setup') }}</h5>
                          </div>
                          <div class="d-flex flex-column">
                            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                              <ul id="cLab-li" class="mt-4">
                                <li>{{ slocale('Create a name for your project') }}</li>
                                <li>{{ slocale('Select which type of emission categories you want to work with') }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <i class="la la-database proj-icon"></i>
                            <small class="card-title col">{{ slocale('Step 2') }}</small>
                            <h5 class="card-title col">{{ slocale('Data collection') }}</h5>
                          </div>
                          <ul id="cLab-li" class="mt-4">
                            <li>{{ slocale('Collect data by sending a request to data owners, or') }}</li>
                            <li>{{ slocale('Collect data by adding it directly yourself, or') }}</li>
                            <li>{{ slocale('Collect data by using your existing data in the platform') }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <i class="la la-fire-alt proj-icon"></i>
                            <small class="card-title">{{ slocale('Step 3') }}</small>
                            <h5 class="card-title">{{ slocale('Calculated carbon emissions') }}</h5>
                          </div>
                          <ul id="cLab-li" class="mt-4">
                            <li>
                              {{
                                slocale(
                                  'Add an emission factor by browsing the Emission factor database, using your own or creating something custom'
                                )
                              }}
                            </li>
                            <li>{{ slocale('Collect data by adding it directly yourself, or') }}</li>
                            <li>
                              {{
                                slocale(
                                  'The system will calculate your carbon emissions and you can view them as dashboards or tables'
                                )
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--NAME THE PROJECT PAGE-->
              <div *ngIf="activeFormNo == 2" class="px-3">
                <div class="d-flex flex-row flex-wrapw">
                  <h5>{{ slocale('Project Name*') }}</h5>
                  <i
                    class="la la-info-circle info-popover-arrow size-15 ms-1"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="position: relative; top: 3px"
                  ></i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale('Project name should be unique.') }}</p>
                  </div>
                </div>
                <p>
                  {{
                    slocale(
                      'Choose a name for your carbon project to specify eg. the time period or organization sections included in the calculations.'
                    )
                  }}
                </p>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    [formControl]="projectTitle"
                    id="projectTitle"
                    placeholder="{{ slocale('Enter project name') }}"
                  />
                </div>
              </div>
              <!--SELECT EMISSIONS PAGE-->
              <div *ngIf="activeFormNo == 3" style="overflow-x: hidden">
                <div class="row ps-1">
                  <p class="ps-4">
                    {{ slocale('Select the emission categories you want to include in your project.') }}
                  </p>
                </div>
                <div class="container-fluid" style="height: 100% !important">
                  <div
                    class="d-flex flex-column align-items-center h-100 data-categories-page"
                    style="height: 100% !important"
                  >
                    <div
                      class="flex-grow-1 w-100 border h-100 text-center main-content"
                      style="padding-right: 0 !important"
                    >
                      <!-- VIEW / EDIT DATACATEGORIES -->
                      <div class="accordion accordion-flush" style="overflow: hidden; max-height: 100%">
                        <div
                          *ngFor="let level_1 of depTaxonomy.childrenSortedByOrdinal(); let isFirst = first"
                          class="accordion-item level_1_container"
                        >
                          <div class="accordion-header">
                            <div class="level_1_header">
                              <span class="level_1_label">
                                {{ resolveString(level_1.label) }}
                              </span>
                            </div>
                            <div
                              class="accordion-button"
                              [class.collapsed]="!isFirst"
                              type="button"
                              data-bs-toggle="collapse"
                              [attr.data-bs-target]="'#' + level_1.key"
                            ></div>
                          </div>
                          <div [id]="level_1.key" class="accordion-collapse collapse" [class.show]="isFirst">
                            <div class="accordion-body">
                              <div
                                *ngFor="let level_2 of depTaxonomy.childrenSortedByOrdinal(level_1.key)"
                                class="level_2_container"
                              >
                                <div class="level_2_header">
                                  <span class="level_2_label">
                                    {{ resolveString(level_2.label) }}
                                  </span>
                                  <div class="level_2_add_control">
                                    <div class="dropdown">
                                      <div
                                        class="dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                      >
                                        <i class="icon las la-plus"></i>
                                        <span class="add_label">
                                          {{
                                            locale('locale_key.pages.data_category.data_category.add_to_label', {
                                              label: resolveString(level_2.label)
                                            })
                                          }}
                                        </span>
                                      </div>
                                      <div [id]="'dropdown_menu_' + level_2.key" class="dropdown-menu">
                                        <div
                                          [id]="'add_custom_category_container_' + level_2.key"
                                          class="level_2_add_custom_category_container viewing"
                                        >
                                          <div class="level_2_add_custom_category_cta">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="17"
                                              viewBox="0 0 16 17"
                                              fill="none"
                                            >
                                              <path
                                                d="M8 3.83325V13.1666"
                                                stroke="#374659"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M3.33334 8.5H12.6667"
                                                stroke="#374659"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                            <span>
                                              {{
                                                locale(
                                                  'locale_key.pages.data_category.data_category.add_custom_category'
                                                )
                                              }}
                                            </span>
                                          </div>
                                          <input
                                            class="level_2_add_custom_category_input"
                                            type="text"
                                            placeholder="Enter name"
                                          />
                                          <div class="level_2_add_custom_category_control">
                                            <i class="icon las la-plus"></i>
                                            <span class="add_label">
                                              {{ locale('locale_key.general.buttons.add') }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="error">
                                          {{
                                            locale('locale_key.pages.data_category.data_category.name_already_exists')
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="level_3_container">
                                  <div
                                    *ngFor="let level_3 of depTaxonomy.childrenSortedByOrdinal(level_2.key)"
                                    class="level_3_box"
                                    (click)="toggleKeySelection(level_3.key)"
                                  >
                                    <div class="level_3_indicator purple"></div>
                                    <div class="level_3_content">
                                      <i class="icon"></i>
                                      <span class="level_3_label text-start">{{ resolveString(level_3.label) }}</span>
                                      <i
                                        class="la la-check pink fs-4"
                                        *ngIf="isEmissionCategorySelected(level_3.key)"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <img
                        src="assets/img/Emissions scope.jpg"
                        alt="CarbonLab-Icons-01"
                        class="img-fluid"
                        style="max-width: 800px; width: 100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="card-footer"
              class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0"
              style="max-width: 100vw; background-color: transparent"
              *ngIf="cardFooter"
            >
              <ng-container *ngIf="!loadingInProgress">
                <button type="button" class="btn m-1 tertiary-button custom-button" (click)="saveDraftModal(true)">
                  {{ locale('locale_key.general.buttons.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 secondary-button custom-button"
                  *ngIf="activeFormNo != 1"
                  (click)="saveDraftModal(false)"
                >
                  {{ locale('locale_key.button.save_as_draft') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 tertiary-button custom-button"
                  *ngIf="activeFormNo != 1"
                  (click)="prev()"
                >
                  {{ locale('locale_key.general.buttons.previous') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 primary-button custom-button mt-sm-1"
                  *ngIf="activeFormNo != 3"
                  (click)="next()"
                >
                  {{ locale('locale_key.general.buttons.next') }}
                </button>
                <button
                  type="button"
                  class="btn m-1 primary-button custom-button mt-sm-1"
                  (click)="createProject()"
                  *ngIf="activeFormNo == 3"
                  [disabled]="selectedEmissionCategoryKeys.size == 0"
                >
                  {{ slocale('Finish setup') }}
                </button>
              </ng-container>
              <div *ngIf="loadingInProgress" class="ball-pulse loader-primary text-center m-auto">
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #saveDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="!saveBeforeLeave">
        {{ slocale('Save as draft') }}
      </h4>
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="saveBeforeLeave">
        {{ slocale('Save before leaving') }}
      </h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2" *ngIf="!saveBeforeLeave">
        {{ slocale('Your project will be saved in the Project Overview page.') }}
      </p>
      <p class="ps-2" *ngIf="saveBeforeLeave">
        {{
          slocale(
            "If you leave this page your changes won't be saved. To save changes return to the page and select the save option you want."
          )
        }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal(); closeCreatingProject()">
          {{ locale('locale_key.general.buttons.cancel') }}
        </button>
        <button type="button" class="btn btn-add text-white" (click)="saveProject()">
          {{ this.saveBeforeLeave ? slocale('Return to page') : slocale('Save') }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</div>

<!-- Show Calculations Modal -->
<ng-template #showCalcModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 bold" id="myModalLabel14">
      {{ slocale('GHG emissions ') }}
      <span class="text-muted" style="font-weight: 300">{{ slocale('in the unit') }}</span>
      {{ slocale('Kg CO2e') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-row justify-content-between align-items-center blue-border w-100 p-2 bg-selected">
      <span class="mb-0 pb-0">{{ slocale('Activity data * Emission factor waste') }}</span>
      <i class="la la-edit size-15 me-2 bold" (click)="openModal(editCalcModal, 'modal-lg')"></i>
    </div>
  </div>
</ng-template>

<!-- Edit calculation modal-->
<ng-template #editCalcModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Edit calculation formula') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 bold">{{ slocale('Edit calculation formula for this entry') }}</p>
    </div>
    <div class="row px-2">
      <p class="">
        <span class="bold">{{ slocale('GHG emissions ') }}</span>
        {{ slocale('will be calculated as ') }}
      </p>
    </div>
    <div class="row px-1">
      <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row justify-content-start align-items-center w-100 flex-grow-1">
          <span class="px-2 py-1 dotted-square mx-2">
            <i
              class="la la-plus-circle size-15 m-auto bold"
              (click)="editCalculation('id')"
              style="position: relative; top: 2px"
            ></i>
          </span>
          <span class="teal-border-round p-2 w-auto">
            <span class="me-auto">
              {{ slocale('Electricity energy ') }}
              <span class="ms-auto text-mutted">{{ slocale('Kwh') }}</span>
            </span>
          </span>
          <span class="teal-sqaure bold size-15 mx-2">+</span>
          <span class="px-2 py-1 dotted-square">
            <i
              class="la la-plus-circle size-15 m-auto bold"
              (click)="editCalculation('id')"
              style="position: relative; top: 2px"
            ></i>
          </span>
        </div>
        <div
          class="d-flex flex-row justify-content-end align-items-center p-2"
          [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'text-nowrap' : 'text-wrap'"
        >
          <i class="la la-close size-15 ms-auto my-auto bold mx-1" (click)="editCalculation('id')"></i>
          <span *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">{{ slocale('Clear calculation') }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button" data-test="request-create-button">
        {{ locale('locale_key.general.buttons.save_changes') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #selectEmissionsModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 *ngIf="!emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Select from my company’s emission factors') }}
    </h5>
    <h5 *ngIf="emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Search in emission databases provided by SustainLab') }}
    </h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="!emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Edit') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 80vh !important">
          <table class="table table-responsive mt-1 table-sm">
            <thead style="border-bottom: 4px solid var(--main-contrast-color)">
              <tr class="">
                <th colspan="2">
                  <h6 class="text-muted mb-0 pb-0 mt-2">{{ slocale('Custom emission detail factor') }}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="">
                  <h6 class="text-muted mt-2">{{ slocale('Emission source') }}</h6>
                </td>
                <td class="">
                  <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                </td>
              </tr>
              <tr class="">
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Emission source unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">{{ slocale('litres') }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">kg CH4</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted mt-2">2.01888</h6>
                </td>
              </tr>
            </tbody>
            <h6 class="text-muted ps-2 pt-3">{{ slocale('Emission factor origin') }}</h6>
            <p class="ps-2 text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo
            </p>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th class="">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Database name') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Additional details') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3 hide-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">kg CH4</h6>
                      </div>
                      <div class="pt-3">
                        <h6 class="blurred-text">0.00107</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3 show-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 100vh !important">
          <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab31"
                [class.active]="activeTab === 'emissionFactor'"
                (click)="selectTab('emissionFactor')"
              >
                {{ slocale('Emission factor detail') }}
              </a>
            </li>
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab32"
                [class.active]="activeTab === 'databaseInfo'"
                (click)="selectTab('databaseInfo')"
              >
                {{ slocale('Database info') }}
              </a>
            </li>
          </ul>
          <div class="tab-content px-1 pt-1">
            <div
              role="tabpanel"
              class="tab-pane"
              id="emissionFactor"
              [class.active]="activeTab === 'emissionFactor'"
              aria-labelledby="base-emissionFactor"
            >
              <div class="">
                <table class="table table-responsive mt-1 table-sm">
                  <thead>
                    <tr class="">
                      <th colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="">
                      <td class="">
                        <h6 class="text-muted mt-2">{{ slocale('Category') }}</h6>
                      </td>
                      <td class="">
                        <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                      </td>
                    </tr>
                    <tr class="">
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Natural gas') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Sub-type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Biodiesel (from used cooking oil)') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Clasification') }}</h6>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Commodity Unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Continent') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Europe') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Data source') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">
                          {{ slocale('UK Government GHG Conversion Factors for Company Reporting') }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Location') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('-') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Scope') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Scope 1') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Year') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('2020') }}</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class="text-muted ps-2 pt-3 w-100">{{ slocale('Emission factor origin') }}</h6>
                <p class="ps-2 text-justify">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                </p>
              </div>
            </div>
            <div
              class="tab-pane"
              id="databaseInfo"
              [class.active]="activeTab === 'databaseInfo'"
              aria-labelledby="base-databaseInfo"
            >
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <h6 class="bold">DEFRA</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Original release date') }}</h6>
                <h6 class="bold">Jan 01 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Upload date in platform') }}</h6>
                <h6 class="bold">Jun 01, 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Publisher organization') }}</h6>
                <h6 class="bold">ACME Inc</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied geography') }}</h6>
                <h6 class="bold">UK, US, Europe</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied time period') }}</h6>
                <h6 class="bold">Dec 2021- Dec 2023</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <p class="bold">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt, eius voluptatibus? Deleniti autem
                  animi ipsam explicabo iste similique ipsa, illum voluptates omnis tenetur ut fuga nostrum provident
                  dignissimos dicta eos officiis eius nam saepe corrupti optio? Et consequatur impedit autem iure
                  pariatur esse aspernatur minima quae eos sit fugit fuga at molestias, suscipit voluptatibus. Nulla
                  temporibus magnam voluptates repellendus, porro minus quod quasi tempora a labore assumenda placeat,
                  qui ea quae voluptatem tempore ducimus unde delectus facilis at? Ab corporis deserunt a magni quis
                  sint quasi et, iusto tempore nulla saepe assumenda ipsum consequatur facere, natus, in laboriosam
                  iste? Ipsa?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn tertiary-button" (click)="closeModal()" data-test="request-cancel-button">
        {{ slocale('Apply no factor') }}
      </button>
      <button class="btn primary-button custom-button" data-test="request-create-button">
        {{ slocale('Apply factor') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #emissionsPortalModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Emissions portal') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row mx-auto text-center">
      <h3 class="bold mx-auto">{{ slocale('Choose your action.') }}</h3>
    </div>
    <div class="container-fluid">
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-search my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Search in emission databases') }}</small>
              <small class="card-title col mx-auto">{{ slocale('provided by SustainLab') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="
                  activeTab = 'emissionFactor';
                  emissionFormSustainLab = true;
                  openModal(selectEmissionsModal, 'modal-xl')
                "
              >
                {{ slocale('Search') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-database my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Select from my company's") }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factors') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="emissionFormSustainLab = false; openModal(selectEmissionsModal, 'modal-xl')"
              >
                {{ slocale('Select') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i _ngcontent-qgx-c224="" class="la la-plus my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Add custom') }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factor') }}</small>
              <button class="btn primary-button mx-auto custom-button">{{ slocale('Add custom') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Remove from Project Modal -->
<ng-template #removeProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Remove from project') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'Are you sure you want to remove this entry? The data will be removed from this carbon lab project and this action cannot be undone. Removing this entry will not affect the data categories in the platform.'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Return to page') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Remove') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data sucessfully added to project Modal -->
<ng-template #dataSuccessfullyAddedModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">
      {{ slocale('Data successfully added to project') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'You have successfully added data into the CarbonLab project. The emissions will now be calculated based on your selected calculation method.'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('OK') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Missing emissions factors Modal -->
<ng-template #missingEmissionsModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Missing emission factors') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          'You have not filled in all necessary emission factors to calculated the emissions. Do you want to fill them in now or at a later time?'
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Fill in now') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Fill in later') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Update Data Categories modal-->
<ng-template #updateCategoriesModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Update data categories') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="container-fluid">
      <div class="row">
        <p class="py-3 bold">
          {{
            slocale(
              'To be able to calculate the GHG emissions new necessary data points need to be added to this data category. The new data points will be added to the data categories once you save the changes.'
            )
          }}
        </p>
      </div>
      <div class="accordion w-100 px-2 mt-3" id="accordionExample1">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a
              class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table size-15 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale('Data points to be added') }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center p-3 flex-wrap">
                <span class="badge badge-green bg-green text-wrap w-auto m-1">
                  Environmental > Waste generate > Waste generated in operations
                </span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span>
                <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1">GHG emissions</span>
                <span><i class="la la-edit size-15 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button">{{ slocale('Save changes') }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Export Table modal-->
<ng-template #exportTableModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Export table') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="container-fluid">
      <div class="row">
        <p class="pt-3 pb-1">{{ slocale('Select in what format to export the summary table') }}</p>
      </div>
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-pdf my-auto mx-2" style="font-size: 3.5rem; color: red !important"></i>
          <label class="card-title mx-auto">
            <input
              type="checkbox"
              class="form-check-input me-1"
              id="exampleCheck1"
              style="position: relative; top: 2px"
            />
            {{ slocale('Export to PDF') }}
          </label>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i
            class="la la-file-excel my-auto mx-2 bg-gradient"
            style="font-size: 3.5rem; color: var(--main-gradient-start-color) !important"
          ></i>
          <label class="card-title mx-auto">
            <input
              type="checkbox"
              class="form-check-input me-1"
              id="exampleCheck1"
              style="position: relative; top: 2px"
            />
            {{ slocale('Export to in CSV') }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button class="btn primary-button custom-button">{{ locale('locale_key.general.toolbar.button.export') }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Emission Categories Modal -->
<ng-template #emissionCategoriesModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale('Edit project setup') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale('Project Name*') }}</h5>
        <i
          class="la la-info-circle info-popover-arrow ms-1"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="position: relative; top: 3px"
        ></i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>
            {{
              slocale(
                'Choose a name for your carbon project to specify eg. the time period or organization sections included in the calculations.'
              )
            }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" id="projectTitle" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center mt-2">
        <span class="w-75">
          {{
            slocale(
              'Choose the emission categories to include in your project scope. If you require additional categories beyond those provided, you can add custom ones by editing the custom categories.'
            )
          }}
        </span>
        <span>
          <i
            class="la la-edit size-15 ms-1"
            style="position: relative; top: 2px"
            (click)="openModal(customEmissionModal, 'modal-md')"
          ></i>
          Edit custom categories
        </span>
      </div>
      <p>You can add custom section or custom emission category if the requirement is out of scope.</p>
      !!!Dropdown content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Confirm') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Custom Emission Categories Modal -->
<ng-template #customEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">
      {{ slocale('Add custom emission category to Scope 1 (Direct)') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <label class="form-label">{{ slocale('Category name*') }}</label>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <label class="form-label">{{ slocale('Description') }}</label>
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-add text-white custom-button"
        (click)="openModal(removeEmissionModal, 'modal-lg')"
      >
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove Emission Categories Modal -->
<ng-template #removeEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale('Remove Emission Category') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2 scroll-y">
      <p>
        {{
          slocale(
            'Are you sure you want to remove this emission category? The data will be removed from this CarbonLab project and this action cannot be undone. This action will not affect the Data Categories in the platform and your data will still be available in the platform.'
          )
        }}
      </p>
      !!!Content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-add text-white custom-button"
        (click)="openModal(removeEmissionsConfModal, 'modal-sm')"
      >
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove emissions Confirmation Modal -->
<ng-template #removeEmissionsConfModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale('Remove emission category') }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale('The category is currently empty and it will not affect your project.') }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale('Cancel') }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Confirm') }}</button>
    </ng-container>
  </div>
</ng-template>
