<div class="d-flex flex-column h-100" *ngIf="showProjectsList()">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between my-2" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class="ps-0 my-auto mt-0">{{ slocale('Projects') }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h cursor fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn"
                      (click)="handleToolbarAction(toolbarButton.actionName)"
                      *ngIf="toolbarButton.visible()"
                    >
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button cursor"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="loadingData">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex h-100 flex-row me-2 w-100" *ngIf="!loadingData">
          <div
            class="card w-100 pt-1 me-2"
            style="overflow: auto !important; height: var(--page-content-minus-1-page-header-footer)"
          >
            <div id="accordionWrap1" role="tablist" aria-multiselectable="true" class="px-2 pt-3 fixed-width">
              <!-- Project cards container -->
              <!-- <div class="row">
                <h3 class="px-4">{{ slocale("CarbonLab projects") }}</h3>
              </div> -->
              <div class="container-fluid">
                <div class="row">
                  <!-- <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="card project-card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <h5 class="card-title"><i class="la la-fire-alt proj-icon"></i> Carbon Emissions: Main production 2023</h5>
                          <div class="dropdown">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" (click)="openModal(duplicateProjectModal, 'modal-lg')"><i class="la la-copy size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Duplicate project") }}</a>
                              <a class="dropdown-item startDeleteProjectBtn"><i class="la la-trash-alt danger size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Delete project") }}</a>
                            </div>
                          </div>
                        </div>
                        <p class="card-text mx-1 mb-0">{{ slocale("Emission categories") }}</p>
                        <div class="row d-flex justify-content-center align-items-center mx-1 px-2" style="background-color: var(--complementary-color1)">
                          <div class="row pt-2 px-4">
                            <div class="col-4">
                              <div class="px-0 green-border-b d-flex flex-column justify-content-center align-items-center">
                                <small>{{ slocale("Scope 1") }}</small>
                                <h5 class="mb-0">2</h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="px-0 yellow-border-b d-flex flex-column justify-content-center align-items-center">
                                <small>{{ slocale("Scope 2") }}</small>
                                <h5 class="mb-0">1</h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="px-0 purple-border-b d-flex flex-column justify-content-center align-items-center">
                                <small>{{ slocale("Scope 3") }}</small>
                                <h5 class="mb-0">7</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p class="card-text mx-1 mb-0 mt-2">{{ slocale("Request activity") }}</p>
                        <div class="row d-flex justify-content-center align-items-center mx-1 border p-3" style="background-color: var(--complementary-color1)">
                          <div class="d-flex flex-row justify-content-between w-100">
                            <span>{{ slocale("Number of data entries") }}</span>
                            <span class="text-center" style="min-width: 50px">73</span>
                          </div>
                          <div class="d-flex flex-row justify-content-between w-100">
                            <span>{{ slocale("Completed requests") }}</span>
                            <span class="text-center" style="min-width: 50px">52%</span>
                          </div>
                          <div class="d-flex flex-row justify-content-between w-100">
                            <span>{{ slocale("Pending requests") }}</span>
                            <span class="text-center" style="min-width: 50px">7</span>
                          </div>
                          <div class="d-flex flex-row justify-content-between w-100">
                            <span>{{ slocale("Submissions to review") }}</span>
                            <div class="d-flex flex-row justify-content-center align-items-center" style="min-width: 50px"><span class="icon-circle circle-pink white text-center">4</span></div>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                          <a class="btn primary-button mx-auto custom-button">{{ slocale("Open") }}</a>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- CC PROJECTS -->
                  <!-- <ng-container *ngFor="let project of ccProjects">
                    <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                      <div class="card project-card">
                        <div class="card-body">
                          <div class="d-flex justify-content-between">
                            <h5 class="card-title"><i class="la la-fire proj-icon"></i> {{project.name}} </h5>
                            <div class="dropdown">
                              <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="openModal(duplicateProjectModal, 'modal-lg')"><i class="la la-copy size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Duplicate project") }}</a>
                                <a class="dropdown-item startDeleteProjectBtn" (click)="startDeleteProject(project)"><i class="la la-trash-alt danger size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Delete project") }}</a>
                              </div>
                            </div>
                          </div>
                          <p class="card-text mx-1 mb-0">{{ slocale("Emission categories") }}</p>
                          <div class="row d-flex justify-content-center align-items-center mx-1 px-2" style="background-color: var(--complementary-color1)">
                            <div class="row pt-2 px-4">
                              <div class="col-4">
                                <div class="px-0 green-border-b d-flex flex-column justify-content-center align-items-center">
                                  <small>{{ slocale("Scope 1") }}</small>
                                  <h5 class="mb-0">{{project.scope1Emission}}</h5>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="px-0 yellow-border-b d-flex flex-column justify-content-center align-items-center">
                                  <small>{{ slocale("Scope 2") }}</small>
                                  <h5 class="mb-0">{{project.scope2Emission}}</h5>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="px-0 purple-border-b d-flex flex-column justify-content-center align-items-center">
                                  <small>{{ slocale("Scope 3") }}</small>
                                  <h5 class="mb-0">{{project.scope3Emission}}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p class="card-text mx-1 mb-0 mt-2">{{ slocale("Request activity") }}</p>
                          <div class="row d-flex justify-content-center align-items-center mx-1 border p-3" style="background-color: var(--complementary-color1)">
                            <div class="d-flex flex-row justify-content-between w-100">
                              <span>{{ slocale("Number of data entries") }}</span>
                              <span class="text-center" style="min-width: 50px">{{project.noOfDataEntries}}</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between w-100">
                              <span>{{ slocale("Completed requests") }}</span>
                              <span class="text-center" style="min-width: 50px">-</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between w-100">
                              <span>{{ slocale("Pending requests") }}</span>
                              <span class="text-center" style="min-width: 50px">-</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between w-100">
                              <span>{{ slocale("Submissions to review") }}</span>
                              <div class="d-flex flex-row justify-content-center align-items-center" style="min-width: 50px"><span class="icon-circle circle-pink white text-center">4</span></div>
                            </div>
                          </div>
                          <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                            <a class="btn primary-button mx-auto custom-button" (click)="selectProject(project)">{{ slocale("Open") }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                    <div class="card project-card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <h5 class="card-title"><i class="la la-fire-alt proj-icon"></i> Carbon Emissions 2024</h5>
                          <div class="dropdown">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" (click)="openModal(duplicateProjectModal, 'modal-lg')"><i class="la la-copy size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Duplicate project") }}</a>
                              <a class="dropdown-item startDeleteProjectBtn"><i class="la la-trash-alt danger size-15 pe-1" style="position: relative; top: 2px"></i>{{ slocale("Delete project") }}</a>
                            </div>
                          </div>
                        </div>
                        <p></p>
                        <div class="row d-flex justify-content-center align-items-center mx-1 border p-3 mt-5" style="background-color: var(--complementary-color1); min-height: 215px !important">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <h6 class="bold">{{ slocale("Project setup incomplete.") }}</h6>
                            <p>{{ slocale("Finish it up to begin collecting data.") }}</p>
                          </div>
                          <div class="d-flex flex-row justify-content-center align-items-center">
                            <p class="m-auto">{{ slocale("Progress") }}</p>
                          </div>
                          <div class="progress px-0">
                            <div class="progress-bar bg-linear-gradient" role="progressbar" aria-valuenow="40" aria-valuemin="40" aria-valuemax="100" style="width: 40%">40%</div>
                          </div>
                          <div class="d-flex flex-row justify-content-center align-items-center">
                            <p class="m-auto">{{ slocale("40%") }}</p>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                          <a class="btn primary-button mx-auto">{{ slocale("Resume setup") }}</a>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- CSRD PROJECTS -->
                  <div class="row">
                    <h3 class="px-3">{{ slocale('CSRD projects') }}</h3>
                  </div>
                  <ng-container *ngIf="csrdProjects.length == 0">
                    <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                      <div class="card project-card mx-0 px-0">
                        <div class="card-body mx-0 px-0">
                          <div class="d-flex justify-content-center align-items-center px-2">
                            <h5 class="card-title bold py-3">{{ slocale('CSRD') }}</h5>
                          </div>
                          <div class="delimitor mb-3"></div>
                          <div class="d-flex justify-content-center align-items-center px-2">
                            <h6 class="text-justify">
                              {{ slocale('Collect data for the Corporate Reporting Directive') }}
                            </h6>
                          </div>
                          <div class="d-flex flex-column justify-content-center align-items-center mt-3 px-2">
                            <div class="eu-logo">
                              <h5 id="eu">EU</h5>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                              <div class="star"></div>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-row justify-content-center align-items-center mt-4"
                            id="startCreateNewCSRDProjectBtn"
                            (click)="startCreateNewCSRDProject()"
                          >
                            <a class="btn primary-button mx-auto custom-button">{{ slocale('Create new') }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="csrdProjects.length > 0">
                    <ng-container *ngFor="let project of csrdProjects">
                      <ng-container *ngIf="!project.draft">
                        <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                          <div class="card project-card">
                            <div class="card-body">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                  <div
                                    class="d-flex flex-column justify-content-center align-items-center my-4 px-2 d-inline ms-4"
                                  >
                                    <div class="eu-logo-sm">
                                      <h5 id="eu-sm" class="pt-2">EU</h5>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                    </div>
                                  </div>
                                  <h6 class="card-title d-inline ps-4 ms-1 pt-1">{{ project.name }}</h6>
                                </div>
                                <div class="dropdown">
                                  <button
                                    class="btn no-border"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="startDuplicateProject(project)">
                                      <i class="la la-copy size-15 pe-1" style="position: relative; top: 2px"></i>
                                      {{ slocale('Duplicate project') }}
                                    </a>
                                    <a
                                      class="dropdown-item startDeleteProjectBtn"
                                      (click)="startDeleteProject(project)"
                                    >
                                      <i
                                        class="la la-trash-alt danger size-15 pe-1"
                                        style="position: relative; top: 2px"
                                      ></i>
                                      {{ slocale('Delete project') }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <!-- TO DO : Fix in phase 2 -->
                              <!-- <select class="form-select mt-2 w-100" id="projectSelect" aria-label="Default select example">
                                <option selected>{{ slocale("Final answer progress") }}</option>
                                <option value="1">{{ slocale("Data collection progress") }}</option>
                              </select> -->

                              <div
                                class="row d-flex justify-content-center align-items-center mt-2"
                                style="background-color: #f8fbfb"
                              >
                                <div class="row w-25 m-0 p-0">
                                  <div
                                    class="p-0 pink-border-b d-flex flex-column justify-content-center align-items-center"
                                  >
                                    <small>{{ slocale('Overall') }}</small>
                                    <h6 class="mb-0 pb-0">0%</h6>
                                  </div>
                                </div>
                                <div class="row p-0 m-0">
                                  <div class="col-3 px-1">
                                    <div
                                      class="p-0 m-0 gray-border-b d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <small>{{ slocale('Cross-cutting') }}</small>
                                      <h6 class="mb-0 pb-0">0%</h6>
                                    </div>
                                  </div>
                                  <div class="col-3 px-1">
                                    <div
                                      class="p-0 green-border-b d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <small>{{ slocale('Environmental') }}</small>
                                      <h6 class="mb-0 pb-0">0%</h6>
                                    </div>
                                  </div>
                                  <div class="col-3 px-1">
                                    <div
                                      class="p-0 yellow-border-b d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <small>{{ slocale('Social') }}</small>
                                      <h6 class="mb-0 pb-0">0%</h6>
                                    </div>
                                  </div>
                                  <div class="col-3 px-1">
                                    <div
                                      class="p-0 blue-border-b d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <small>{{ slocale('Governance') }}</small>
                                      <h6 class="mb-0 pb-0">0%</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p class="card-text mx-1 mb-0 mt-1">{{ slocale('Request & tasks') }}</p>
                              <div
                                class="row d-flex justify-content-center align-items-center mx-1 border px-3 py-2"
                                style="background-color: var(--complementary-color1)"
                              >
                                <div class="d-flex flex-row justify-content-between w-100">
                                  <span>{{ slocale('Completed superuser tasks') }}</span>
                                  <span class="text-center" style="min-width: 50px">0%</span>
                                </div>
                                <div class="d-flex flex-row justify-content-between w-100">
                                  <span>{{ slocale('Completed own tasks') }}</span>
                                  <span class="text-center" style="min-width: 50px">0%</span>
                                </div>
                                <div class="d-flex flex-row justify-content-between w-100">
                                  <span>{{ slocale('Completed requests') }}</span>
                                  <div
                                    class="d-flex flex-row justify-content-center align-items-center"
                                    style="min-width: 50px"
                                  >
                                    0%
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                                <a
                                  class="btn primary-button mx-auto custom-button viewCsrdProjectBtn"
                                  (click)="viewCsrdProject(project)"
                                >
                                  {{ slocale('Open') }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="project.draft">
                        <div class="mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
                          <div class="card project-card">
                            <div class="card-body">
                              <div class="d-flex justify-content-between">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                  <div
                                    class="d-flex flex-column justify-content-center align-items-center my-4 px-2 d-inline ms-4"
                                  >
                                    <div class="eu-logo-sm">
                                      <h5 id="eu-sm" class="pt-2">EU</h5>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                      <div class="star-sm"></div>
                                    </div>
                                  </div>
                                  <h6 class="card-title d-inline ps-4 ms-1 pt-1">{{ project.name }}</h6>
                                </div>
                                <div class="dropdown">
                                  <button
                                    class="btn no-border"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="startDuplicateProject(project)">
                                      <i class="la la-copy size-15 pe-1" style="position: relative; top: 2px"></i>
                                      {{ slocale('Duplicate project') }}
                                    </a>
                                    <a
                                      class="dropdown-item startDeleteProjectBtn"
                                      (click)="startDeleteProject(project)"
                                    >
                                      <i
                                        class="la la-trash-alt danger size-15 pe-1"
                                        style="position: relative; top: 2px"
                                      ></i>
                                      {{ slocale('Delete project') }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <p></p>
                              <div
                                class="row d-flex justify-content-center align-items-center mx-1 border p-3 mt-5"
                                style="background-color: var(--complementary-color1); min-height: 215px !important"
                              >
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                  <h6 class="bold">{{ slocale('Project setup incomplete.') }}</h6>
                                  <p>{{ slocale('Finish it up to begin collecting data.') }}</p>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                  <p class="m-auto">{{ slocale('Progress') }}</p>
                                </div>
                                <div class="progress px-0">
                                  <div
                                    class="progress-bar bg-linear-gradient"
                                    role="progressbar"
                                    aria-valuenow="40"
                                    aria-valuemin="40"
                                    aria-valuemax="100"
                                    style="width: 40%"
                                  >
                                    40%
                                  </div>
                                </div>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                  <p class="m-auto">{{ slocale('40%') }}</p>
                                </div>
                              </div>
                              <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                                <a class="btn primary-button mx-auto" (click)="resumeCSRDSetup(project)">
                                  {{ slocale('Resume setup') }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Create project modal-->
<ng-template #addProjectModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Create new project') }}</h5>
    <button type="button" class="btn close" (click)="close()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row">
      <p class="py-3 bold">{{ slocale('Select the project you want to work on.') }}</p>
    </div>
    <div class="container-fluid">
      <div class="row">
        <!-- <div id="carbonLab" class="mb-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="card project-card mx-0 px-0" [ngClass]="projectType === 'CC' ? 'selected-proj' : ''" (click)="projectType = 'CC'">
            <div class="card-body mx-0 px-0">
              <div class="d-flex justify-content-center align-items-center px-2">
                <h5 class="card-title bold py-3">{{ slocale("CarbonLab") }}</h5>
              </div>
              <div class="delimitor mb-3"></div>
              <div class="d-flex justify-content-center align-items-center px-2">
                <h6 class="text-justify">{{ slocale("Calculate your company's carbon emissions") }}</h6>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center mt-3 px-2">
                <div class="eu-logo"><i class="la la-fire-alt proj-icon-lg" style="size: 10rem"></i></div>
              </div>
              <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                <p class="">{{ slocale("Start a ") }} <span class="bold">{{ slocale("CarbonLab") }}</span>{{ slocale(" project") }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <div id="csrd" class="mb-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div
            class="card project-card mx-0 px-0"
            [ngClass]="projectType === 'CSRD' ? 'selected-proj' : ''"
            (click)="selectNewCSRDProject()"
          >
            <div class="card-body mx-0 px-0">
              <div class="d-flex justify-content-center align-items-center px-2">
                <h5 class="card-title bold py-3">{{ slocale('CSRD') }}</h5>
              </div>
              <div class="delimitor mb-3"></div>
              <div class="d-flex justify-content-center align-items-center px-2">
                <h6 class="text-justify">{{ slocale('Collect data for the Corporate Reporting Directive') }}</h6>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center mt-3 px-2">
                <div class="eu-logo">
                  <h5 id="eu">EU</h5>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                  <div class="star"></div>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                <p class="">
                  {{ slocale('Start a ') }}
                  <span class="bold">{{ slocale('CSRD') }}</span>
                  {{ slocale(' project') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row p1">
      <p class="p-1 border create_project_cta">
        <span class="bold">{{ slocale('Ready to venture beyond CSRD?') }}</span>
        {{
          slocale(
            ' Contact your customer success representative to discover how you can utilize the platform for customized
        data collection.'
          )
        }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="close()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        class="btn primary-button custom-button"
        *ngIf="projectType == 'CC'"
        (click)="createProject()"
        data-test="request-create-button"
      >
        {{ locale('locale_key.general.buttons.create') }}
      </button>
      <button
        class="btn primary-button custom-button"
        *ngIf="projectType == 'CSRD'"
        (click)="startCreateNewCSRDProject()"
        data-test="request-create-button"
      >
        {{ locale('locale_key.general.buttons.create') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Duplicate CSRD Project Modal -->
<ng-template #duplicateCSRDProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">
      {{ slocale('Duplicate project') + " '" + activeCsrdProject.name + "'" }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          "When you duplicate this project, a new project with the same name and same settings, including material topics and data points, will be created. Both are editable upon entering the project. However, the new project won't
      include any previously collected data."
        )
      }}
    </p>
    <p class="ps-2">
      {{ slocale('You will start by reviewing and updating the duplicated metadata of the project.') }}
    </p>
    <p class="ps-2">
      {{ slocale('Would you like to duplicate the project?') }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="close()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn text-white custom-button primary-button" (click)="confirmDuplicateProject()">
        {{ slocale('Duplicate') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Duplicate Project Modal -->
<ng-template #duplicateProjectModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">
      {{ slocale("Duplicate project 'Carbon emissions: Main production 2023'?") }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2">
      <p class="">
        {{
          slocale(
            'To duplicate the project, you need to decide how to handle all project-specific data points for emission factors and GHG emissions:'
          )
        }}
      </p>
      <label class="d-inline-block ms-0 ps-0">
        <input class="form-check-inline" type="checkbox" id="flexSwitchCheckDefault" checked />
        <h5 class="d-inline ms-2 bold">{{ slocale('Use the same data') }}</h5>
      </label>
      <label class="ps-4 ms-3">
        {{
          slocale(
            'You will directly utilize the same data from the other project for all emission factors and GHG emissions. The same data points will be shared among the two projects and any changes to those shared data points will affect both
        projects.'
          )
        }}
      </label>
      <br />
      <label class="d-inline-block ms-0 ps-0 pt-3">
        <input class="form-check-inline" type="checkbox" id="flexSwitchCheckDefault" />
        <h5 class="d-inline bold">{{ slocale('Duplicate data for independent use') }}</h5>
      </label>
      <label class="ps-4 ms-2">
        {{
          slocale(
            'Duplicate all emission factor and GHG emission data points and the contained data for independent use in this project. The duplicated data points and data will be added in the next step.'
          )
        }}
      </label>
      <p class="pt-3">
        {{ slocale('You will have to go through the setup process once with duplicated settings first.') }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="close()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-add text-white custom-button"
        (click)="openModal(duplicatingProjectModal, 'modal-lg')"
      >
        {{ slocale('Confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Duplicating Project Modal -->
<ng-template #duplicatingProjectModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale('Duplicating...') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-2">
      <p class="">
        {{
          slocale(
            'The system is duplicating the projects “Carbon emissions: Main production 2023”.You can also see the total progress below.'
          )
        }}
      </p>
      <label class="ms-0 ps-0 d-block">
        <i class="la la-check-circle-o success-text size-15"></i>
        <h5 class="d-inline ms-2 bold">{{ slocale("Scanning project's progress") }}</h5>
      </label>
      <p class="pb-2">
        {{ slocale('The system is scanning and checking the project’s current progress and status.') }}
      </p>
      <label class="ms-0 ps-0 d-block">
        <i class="la la-check-circle-o success-text size-15"></i>
        <h5 class="d-inline ms-2 bold">{{ slocale('Copying project') }}</h5>
      </label>
      <p class="pb-2">
        {{ slocale('Duplicate copies of the project’s details are being created.') }}
      </p>
      <label class="ms-0 ps-0 d-block">
        <i class="la la-check-circle-o success-text size-15"></i>
        <h5 class="d-inline ms-2 bold">{{ slocale('Creating project') }}</h5>
      </label>
      <p class="pb-2">
        {{ slocale('The duplicated project is created into the system.') }}
      </p>
      <div class="d-flex flex-row justify-content-start align-items-center py-2 flex-wrap">
        <span class="d-inline me-2">{{ slocale('Total Duplication Progress') }}</span>
        <div class="progress px-0 d-inline w-50">
          <div
            class="progress-bar bg-linear-gradient m-auto"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="100"
            aria-valuemax="100"
            style="width: 100%"
          >
            100%
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center ms-2">
          <p class="m-auto">{{ slocale('100%') }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="openModal(cancelProjectDuplicationModal, 'modal-md')"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Delete Project Modal -->
<ng-template #deleteProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale(getDeleteProjectHeader()) }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
        slocale(
          "If you delete this project, it won't appear on the projects page anymore. However, all your data collected and insights within the project will remain securely stored on the platform, just not organized under this project."
        )
      }}
    </p>
    <p class="ps-2">
      {{ slocale("You can't restore this project after you have deleted it") }}
    </p>
    <p class="ps-2">
      {{ slocale('Are you sure you want to delete?') }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="deletingProject">
      <div class="ball-pulse loader-primary text-center m-auto">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!deletingProject">
      <button type="button" class="btn secondary-button custom-button" (click)="close()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn text-white bg-danger" (click)="confirmDeleteProject()">
        {{ slocale('Delete project') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Cancel Project duplication -->
<ng-template #cancelProjectDuplicationModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">
      {{ slocale('Cancel project duplication?') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-column w-100 p-2">
      <p>{{ slocale('Are you sure?') }}</p>
      <p>{{ slocale('All changes will be undone and the current duplication progress will be lost.') }}</p>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="close()">
        {{ slocale('Cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale('Confirm') }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data Successfully duplicated -->
<ng-template #projectDuplicateModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">
      {{ slocale('Data successfully duplicated to this project') }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-column w-100 p-2">
      <p>{{ slocale('You have successfully duplicated this CarbonLab project.') }}</p>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn btn-add text-white">{{ slocale('Go to setup') }}</button>
    </ng-container>
  </div>
</ng-template>

<carbonlab-overview
  [project]="selectedProject"
  *ngIf="selectedProject && selectedProject.type == 'CC'"
></carbonlab-overview>
<carbonlab-setup
  *ngIf="createNewProject"
  (createdProject)="selectProject($event)"
  (close)="createNewProject = false"
></carbonlab-setup>
<create-new-csrd-project
  *ngIf="activePage == 'CSRD_SETUP'"
  [csrdProjects]="csrdProjects"
  [activeCsrdProject]="activeCsrdProject"
  (switchProjectsPage)="switchProjectsPage($event)"
  (viewNewCsrdProject)="viewNewCsrdProject($event)"
></create-new-csrd-project>
<csrd-project-overview
  *ngIf="activePage == 'CSRD_OVERVIEW'"
  [csrdProjects]="csrdProjects"
  [activeCsrdProject]="activeCsrdProject"
  (switchProjectsPage)="switchProjectsPage($event)"
></csrd-project-overview>
