<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3>{{ locale('locale_key.pages.direct_data_entries.title') }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize() && !homeActive()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn page-toolbar-dropdown-toggle text-center ms-4"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h fs-2 mt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <li role="menuitem" *ngIf="!this.activeTable() && this.homeActive()">
              <button type="button" class="btn" (click)="openModal(initStageTableModal)">
                <i class="la la-plus"></i>
                <span>{{ locale('locale_key.general.toolbar.button.add_data_entry') }}</span>
              </button>
            </li>
            <ng-container *ngFor="let stageTable of stageTableInfos; let fileIndex = index">
              <ng-container *ngIf="stageTable.dataGridService && !stageTable.loadInProgress && stageTable.isActive">
                <li role="menuitem">
                  <button
                    class="btn"
                    (click)="connecToPipeline(stageTable)"
                    *ngIf="
                      !stageTable.dataGridService.modified &&
                      !stageTable.transformed &&
                      !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress) &&
                      stageTable.tableData.rows.length > 0
                    "
                  >
                    <i class="las la-link"></i>
                    <span>{{ locale('locale_key.general.toolbar.button.add') }}</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    class="btn"
                    (click)="disconnectFromPipeline(stageTable)"
                    *ngIf="
                      !stageTable.dataGridService.modified &&
                      stageTable.transformed &&
                      !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress)
                    "
                  >
                    <i class="las la-unlink"></i>
                    <span>{{ locale('locale_key.general.toolbar.button.remove') }}</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    class="btn"
                    (click)="toCopyStageTableInfo = stageTable; openFileExtractor(stageTable)"
                    *ngIf="
                      !stageTable.dataGridService.modified &&
                      !(
                        stageTable.loadInProgress ||
                        stageTable.saveInProgress ||
                        stageTable.connectionInProgress ||
                        stageTable.updateInfoInProgress
                      )
                    "
                  >
                    <i class="las la-file-medical"></i>
                    <span>{{ locale('locale_key.general.toolbar.button.extract') }}</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    class="btn"
                    (click)="exportDataSource(stageTable)"
                    *ngIf="
                      !stageTable.dataGridService.modified &&
                      !(
                        stageTable.loadInProgress ||
                        stageTable.saveInProgress ||
                        stageTable.connectionInProgress ||
                        stageTable.updateInfoInProgress
                      )
                    "
                  >
                    <i class="las la-download"></i>
                    <span>{{ locale('locale_key.general.buttons.export') }}</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="btn"
                    (click)="stageTable.dataGridService.saveChages(stageTable)"
                    *ngIf="
                      stageTable.dataGridService.modified &&
                      stageTable.dataGridService.isDataValid &&
                      !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress)
                    "
                  >
                    <i class="la la-save"></i>
                    <span>{{ locale('locale_key.general.buttons.save') }}</span>
                  </button>
                </li>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </ng-container>
            </ng-container>
            <li role="menuitem">
              <button type="button" class="btn" (click)="toggleTips()">
                <i class="la la-info fs-5"></i>
                <span>{{ locale('locale_key.general.toolbar.button.info') }}</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize() && homeActive()">
          <button
            id="button-alignment"
            dropdownToggle
            type="button"
            class="btn dropdown-toggle page-toolbar-dropdown-toggle"
            aria-controls="dropdown-alignment"
          >
            <i class="la la-ellipsis-h font-large-1 mt-0" style="rotate: 90deg"></i>
          </button>
          <ul
            id="dropdown-alignment"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
            role="menu"
            aria-labelledby="button-alignment"
          >
            <li role="menuitem" *ngIf="!this.activeTable() && this.homeActive()">
              <button type="button" class="btn" (click)="openModal(initStageTableModal)">
                <i class="la la-plus"></i>
                <span>{{ locale('locale_key.general.toolbar.button.add_data_entry') }}</span>
              </button>
            </li>
            <li role="menuitem">
              <button type="button" class="btn" (click)="toggleTips()">
                <i class="la la-info"></i>
                <span>{{ locale('locale_key.general.toolbar.button.info') }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize() && homeActive()">
        <ng-container *ngFor="let group of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let button of group">
              <button
                *ngIf="button.visible()"
                [disabled]="button.disabled ? button.disabled() : false"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(button.actionName, 'add_data')"
                data-delay="300"
              >
                <i class="fs-5" [class]="button.icon"></i>
                <span
                  class="mx-auto"
                  [style.fontSize]="button.shortLabel === 'Save' ? '1rem' : '0.7rem'"
                  [ngClass]="{ 'toolbar-disconnect': button.shortLabel === 'Disconnect' }"
                >
                  {{ button.shortLabel }}
                </span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize() && !isTableStageClosed() && !homeActive()">
        <ng-container *ngFor="let stageTable of stageTableInfos; let fileIndex = index">
          <ng-container *ngIf="stageTable.dataGridService && !stageTable.loadInProgress && stageTable.isActive">
            <button
              class="btn page-toolbar-button"
              (click)="connecToPipeline(stageTable)"
              *ngIf="
                !stageTable.dataGridService.modified &&
                !stageTable.transformed &&
                !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress) &&
                stageTable.tableData.rows.length > 0
              "
              data-bs-placement="left"
              data-bs-toggle="tooltip"
              title="{{ locale('locale_key.general.toolbar.button.tooltip.connect') }}"
              data-delay="300"
            >
              <i class="las la-link fs-5"></i>
              <span class="text-center">{{ locale('locale_key.general.toolbar.button.add') }}</span>
            </button>
            <button
              class="btn page-toolbar-button"
              (click)="disconnectFromPipeline(stageTable)"
              *ngIf="
                !stageTable.dataGridService.modified &&
                stageTable.transformed &&
                !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress)
              "
              data-bs-placement="left"
              data-bs-toggle="tooltip"
              title="{{ locale('locale_key.general.toolbar.button.tooltip.disconnect') }}"
              data-delay="300"
            >
              <i class="las la-unlink fs-5"></i>
              <span style="font-size: 0.69rem; position: relative; left: 0px !important">
                {{ locale('locale_key.general.toolbar.button.remove') }}
              </span>
            </button>

            <button
              class="btn page-toolbar-button"
              (click)="toCopyStageTableInfo = stageTable; openFileExtractor(stageTable)"
              *ngIf="
                !stageTable.dataGridService.modified &&
                !(
                  stageTable.loadInProgress ||
                  stageTable.saveInProgress ||
                  stageTable.connectionInProgress ||
                  stageTable.updateInfoInProgress
                )
              "
              data-bs-placement="left"
              data-bs-toggle="tooltip"
              title="{{ locale('locale_key.general.buttons.extract_from_file') }}"
              data-delay="300"
            >
              <i class="las la-file-medical fs-5"></i>
              <span style="font-size: 0.7rem">{{ locale('locale_key.general.toolbar.button.extract') }}</span>
            </button>

            <button
              class="btn page-toolbar-button"
              (click)="exportDataSource(stageTable)"
              *ngIf="
                !stageTable.dataGridService.modified &&
                !(
                  stageTable.loadInProgress ||
                  stageTable.saveInProgress ||
                  stageTable.connectionInProgress ||
                  stageTable.updateInfoInProgress
                )
              "
              data-bs-placement="left"
              data-bs-toggle="tooltip"
              title="{{ locale('locale_key.general.toolbar.button.tooltip.export_data_source') }}"
              data-delay="300"
            >
              <i class="las la-download fs-5"></i>
              <span style="font-size: 0.7rem">{{ locale('locale_key.general.buttons.export') }}</span>
            </button>
            <button
              type="button"
              class="btn page-toolbar-button"
              (click)="stageTable.dataGridService.saveChages(stageTable)"
              *ngIf="
                stageTable.dataGridService.modified &&
                stageTable.dataGridService.isDataValid &&
                !(stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress)
              "
            >
              <i class="la la-save fs-5"></i>
              <span>{{ locale('locale_key.general.buttons.save') }}</span>
            </button>
            <button
              type="button"
              class="btn page-toolbar-button"
              (click)="toggleTips()"
              title="{{ locale('locale_key.general.toolbar.button.tooltip') }}"
            >
              <i class="la la-info fs-5"></i>
              <span>{{ locale('locale_key.general.toolbar.button.info') }}</span>
            </button>
            <div class="mx-auto mt-0 mb-1"></div>
            <div class="mx-auto">
              <div
                class="ball-pulse loader-primary"
                *ngIf="stageTable.loadInProgress || stageTable.saveInProgress || stageTable.connectionInProgress"
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <ul class="nav nav-tabs" style="border: none">
            <li class="nav-item">
              <a class="nav-link py-0" [ngClass]="{ active: homeActive() }" data-bs-toggle="tab" (click)="viewHome()">
                <i class="la la-home me-1"></i>
                {{ locale('locale_key.main_navbar.home') }}
              </a>
            </li>
            <ng-container *ngFor="let stageTable of stageTableInfos; let tableIndex = index">
              <li class="nav-item" *ngIf="stageTable.isOpen">
                <a
                  class="nav-link py-0"
                  [ngClass]="{ active: stageTable.isActive }"
                  data-bs-toggle="tab"
                  (click)="viewTable(tableIndex)"
                >
                  <i
                    class="la la-table me-1"
                    [ngStyle]="{ color: taxonomyInfo.color(taxonomyInfo.entityByKey(stageTable.taxonomyKey)) }"
                    *ngIf="!stageTable.loadInProgress"
                  ></i>
                  <i class="la la-spinner spinner" *ngIf="stageTable.loadInProgress"></i>
                  {{ stageTable.taxonomyEntity.label }}
                  <i class="las la-times m-3" (click)="closeTab(stageTable)"></i>
                </a>
              </li>
            </ng-container>
          </ul>
          <div class="tab-content me-2">
            <div id="home" class="tab-pane" [ngClass]="{ active: homeActive() }" *ngIf="homeActive()">
              <div class="card w-100 me-2" style="overflow-y: auto; overflow-x: hidden; height: 83vh">
                <div class="card-body py-0 mx-3 px-3" *ngIf="stageTableInfos.length > 0">
                  <ng-container *ngFor="let stageTable of stageTableInfos; let i = index">
                    <div class="card m-3 custom-card">
                      <div class="card-head d-flex justify-content-between" style="padding: 0.5rem">
                        <a
                          role="button"
                          (click)="viewTable(i)"
                          style="min-width: 20rem"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="{{ locale('locale_key.general.buttons.open') }}"
                          show-delay="300"
                        >
                          <i
                            class="la la-table me-1"
                            [ngStyle]="{ color: taxonomyInfo.color(stageTable.taxonomyEntity) }"
                          ></i>
                          <span class="ms-3" *ngIf="stageTable.taxonomyEntity">
                            {{ stageTable.taxonomyEntity.label }}
                          </span>
                          <span class="ms-3" *ngIf="!stageTable.taxonomyEntity">{{ stageTable.taxonomyKey }}</span>
                        </a>
                        <div class="d-flex flex-row p-0 flex-grow-1 m-0">
                          <ul class="list-inline m-0 ps-4 d-flex flex-row flex-grow">
                            <li class="list-inline-item m-0" style="width: 50%">
                              <div class="badge badge-info fw-normal">{{ stageTable.timeFromString }}</div>
                            </li>
                            <a class="m-0 text-decoration-none">-</a>
                            <li class="list-inline-item m-0" style="width: 50%">
                              <div class="badge badge-info fw-normal">{{ stageTable.timeToString }}</div>
                            </li>
                          </ul>
                          <ul class="list-inline m-0 ps-5">
                            <li class="list-inline-item m-0" style="min-width: 15rem">
                              <div
                                class="badge text-white"
                                [ngStyle]="{ 'background-color': stageTable.fileType().color }"
                                style="color: white"
                                *ngIf="stageTable.sourceFile"
                              >
                                <i class="la {{ stageTable.fileType().icon }} font-small-3"></i>
                                <span class="font-small-3 fw-normal">{{ stageTable.sourceFile }}</span>
                              </div>
                            </li>
                          </ul>
                          <ul class="list-inline m-0 ps-5">
                            <li class="list-inline-item m-0">
                              <div
                                class="badge text-white"
                                [ngStyle]="{ 'background-color': stageTable.statusColor() }"
                                style="color: white"
                              >
                                <i class="la {{ stageTable.statusIcon() }} font-small-3"></i>
                                <span class="font-small-3 fw-normal">
                                  {{
                                    stageTable.statusString() == 'Connected'
                                      ? locale('locale_key.general.state.added')
                                      : stageTable.statusString() == 'Disconnected'
                                        ? locale('locale_key.general.state.not_added')
                                        : locale('locale_key.general.state.modified')
                                  }}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="heading-elements">
                          <div class="d-felx flex-row btn-group">
                            <button
                              class="btn btn-icon action-icon"
                              (click)="toCopyStageTableInfo = stageTable; openModal(copyStageTableModal)"
                              *ngIf="
                                !(
                                  stageTable.loadInProgress ||
                                  stageTable.saveInProgress ||
                                  stageTable.connectionInProgress ||
                                  stageTable.updateInfoInProgress
                                )
                              "
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="{{ locale('locale_key.pages.direct_data_entries.copy_data_entry_modal.title') }}"
                              show-delay="300"
                            >
                              <i class="las la-copy"></i>
                            </button>
                            <button
                              class="btn btn-icon action-icon"
                              (click)="toEditStageTableInfo = stageTable; openModal(editStageTableModal)"
                              *ngIf="
                                !(
                                  stageTable.loadInProgress ||
                                  stageTable.saveInProgress ||
                                  stageTable.connectionInProgress ||
                                  stageTable.updateInfoInProgress
                                )
                              "
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="{{ locale('locale_key.pages.direct_data_entries.edit_data_entry_modal.title') }}"
                              show-delay="300"
                            >
                              <i class="las la-edit"></i>
                            </button>
                            <button
                              class="btn btn-icon action-icon"
                              (click)="connecToPipeline(stageTable)"
                              *ngIf="
                                !stageTable.transformed &&
                                !(
                                  stageTable.loadInProgress ||
                                  stageTable.saveInProgress ||
                                  stageTable.connectionInProgress ||
                                  stageTable.updateInfoInProgress
                                )
                              "
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="{{
                                locale('locale_key.pages.direct_data_entries.tooltip.add_data_to_master_table')
                              }}"
                              show-delay="300"
                            >
                              <i class="las la-link"></i>
                            </button>
                            <button
                              class="btn btn-icon action-icon"
                              (click)="disconnectFromPipeline(stageTable)"
                              *ngIf="
                                stageTable.transformed &&
                                !(
                                  stageTable.loadInProgress ||
                                  stageTable.saveInProgress ||
                                  stageTable.connectionInProgress ||
                                  stageTable.updateInfoInProgress
                                )
                              "
                              data-bs-toggle="tooltip"
                              data-placement="top"
                              title="{{
                                locale('locale_key.pages.direct_data_entries.tooltip.remove_from_master_table')
                              }}"
                              show-delay="300"
                            >
                              <i class="las la-unlink"></i>
                            </button>
                            <div
                              class="ball-pulse loader-primary"
                              *ngIf="
                                stageTable.loadInProgress ||
                                stageTable.saveInProgress ||
                                stageTable.connectionInProgress ||
                                stageTable.updateInfoInProgress
                              "
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let stageTable of stageTableInfos; let fileIndex = index">
              <div class="tab-pane" [ngClass]="{ active: stageTable.isActive }" *ngIf="stageTable.isActive">
                <div class="card main-card m-0" style="overflow: auto; height: 100vh">
                  <div *ngIf="stageTable.loadInProgress">
                    <div class="loader-wrapper mt-5">
                      <div class="loader-container">
                        <div class="ball-grid-pulse loader-primary">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class=""
                    style="overflow: hidden"
                    *ngIf="stageTable.dataGridService && !stageTable.loadInProgress"
                  >
                    <div class="row mx-0 px-0" style="overflow: hidden">
                      <div class="card col-12 m-auto" style="overflow: hidden">
                        <div class="card-head mx-0 px-0">
                          <div class="card-header p-0 pt-1">
                            <div class="btn-toolbar d-flex justify-content-between">
                              <div class="d-flex flex-row p-0 flex-grow-1 m-0 make-col">
                                <ul class="list-inline m-0 ps-4 d-flex flex-row flex-grow no-ps">
                                  <a class="me-3">
                                    {{ locale('locale_key.pages.direct_data_entries.duration.title') }}:
                                  </a>
                                  <li class="list-inline-item m-0">
                                    <a class="badge btn-info text-white">
                                      <span class="m-0">{{ stageTable.timeFromString }}</span>
                                    </a>
                                  </li>
                                  <a class="m-0">-</a>
                                  <li class="list-inline-item m-0">
                                    <a class="badge btn-info text-white">
                                      <span class="m-0">{{ stageTable.timeToString }}</span>
                                    </a>
                                  </li>
                                </ul>
                                <ul class="list-inline m-0 ps-3 no-ps">
                                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.source.title') }}:</a>
                                  <li class="list-inline-item m-0">
                                    <a
                                      class="badge text-white"
                                      [ngStyle]="{ 'background-color': stageTable.fileType().color }"
                                      style="color: white"
                                      *ngIf="stageTable.sourceFile"
                                    >
                                      <i class="la {{ stageTable.fileType().icon }} me-1"></i>
                                      <span class="m-0">{{ stageTable.sourceFile }}</span>
                                    </a>
                                  </li>
                                </ul>
                                <ul class="list-inline m-0 ps-5 no-ps">
                                  <a class="me-3">{{ locale('locale_key.pages.direct_data_entries.status.title') }}:</a>
                                  <li class="list-inline-item m-0">
                                    <a
                                      class="badge text-white"
                                      [ngStyle]="{ 'background-color': stageTable.statusColor() }"
                                      style="color: white"
                                    >
                                      <i class="la {{ stageTable.statusIcon() }} me-1"></i>
                                      <span class="m-0">
                                        {{
                                          stageTable.statusString() == 'Connected'
                                            ? locale('locale_key.general.state.added')
                                            : stageTable.statusString() == 'Disconnected'
                                              ? locale('locale_key.general.state.not_added')
                                              : locale('locale_key.general.state.modified')
                                        }}
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-header p-2" style="max-height: 81vh; overflow-y: auto">
                          <file-upload-picker
                            *ngIf="isDataExtractor"
                            [destTableService]="toCopyStageTableService"
                            (dataExtractedEvent)="dataExtracted($event)"
                            (closeEvent)="closeFileExtractor(stageTable)"
                          ></file-upload-picker>
                          <datagrid-table-editor
                            [ngClass]="{ hidden: isDataExtractor }"
                            [dataGrid]="stageTable.dataGridService"
                          ></datagrid-table-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editStageTableModal>
  <data-entry
    [mode]="'edit'"
    [stageTableInfo]="toEditStageTableInfo"
    (closeModalEvent)="closeModal($event)"
  ></data-entry>
</ng-template>
<ng-template #copyStageTableModal>
  <data-entry
    [mode]="'copy'"
    [stageTableInfo]="toCopyStageTableInfo"
    (closeModalEvent)="closeModal($event)"
  ></data-entry>
</ng-template>
<ng-template #initStageTableModal>
  <data-entry (closeModalEvent)="closeModal($event)"></data-entry>
</ng-template>
