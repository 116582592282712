<div class="d-flex flex-row h-100">
  <div class="d-flex flex-column justify-content-between h-100 sidebar-conf" *ngIf="!initCacheInProgress">
    <!-- three dots -->
    <div class="d-flex justify-content-center" *ngIf="!screenHeightSize.largerThanXsmall()">
      <div class="btn-group" dropdown placement="bottom right">
        <button
          id="button-alignment"
          dropdownToggle
          type="button"
          class="btn page-toolbar-dropdown-toggle"
          aria-controls="dropdown-alignment"
        >
          <i class="las la-ellipsis-v fs-2 text-white"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu bg--dark" role="menu">
          <div class="bg--dark">
            <li role="menuitem">
              <button
                type="button"
                class="btn d-flex p-2 w-100"
                (click)="selectMenuItem(null, null, $event)"
                routerLink="/ws/profile"
                routerLinkActive="router-link-active"
                [ngClass]="{ 'active-menu': profileMenuItem.isActivePage() }"
              >
                <i [class]="profileMenuItem.icon"></i>
                <span class="ms-2 text-white">{{ profileMenuItem.name }}</span>
              </button>
            </li>
          </div>
          <ng-container *ngFor="let itemGroup of filterdItemGroups() as igs; let itemGroupIndex = index">
            <ng-container>
              <ng-container *ngFor="let parent of itemGroup.items; let parentIdex = index">
                <div class="bg--dark">
                  <li role="menuitem">
                    <button
                      type="button"
                      class="btn d-flex p-2 w-100"
                      (click)="selectMenuItem(parent, null, $event)"
                      [ngClass]="{ 'active-menu': parent.isActivePage() }"
                    >
                      <i [class]="parent.icon"></i>
                      <span class="ms-2 text-white">{{ parent.name }}</span>
                      <i
                        *ngIf="parent.children && parent.children.length > 0"
                        class="la icon-color ms-auto"
                        [ngClass]="parent.open ? 'la-angle-up' : 'la-angle-down'"
                        style="font-size: 1rem !important"
                      ></i>
                    </button>
                  </li>
                </div>
                <div
                  class="divider dropdown-divider"
                  *ngIf="itemGroupIndex < igs.length - 1 && parentIdex == itemGroup.items.length - 1"
                ></div>
                <div [@slideInOut]="parent.open ? 'in' : 'out'">
                  <ng-container *ngIf="parent.open">
                    <ng-container *ngFor="let child of parent.children">
                      <div style="background-color: var(--main-dark-color1)" *ngIf="parent.visible">
                        <li role="menuitem">
                          <button
                            type="button"
                            class="btn d-flex p-2 justify-content-start w-100"
                            (click)="selectMenuItem(parent, child, $event)"
                            [ngClass]="{ 'active-menu': child.isActivePage() }"
                          >
                            <i [class]="child.icon"></i>
                            <span class="text-white ms-2" class="text-uppercase">{{ child.name }}</span>
                          </button>
                        </li>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>

    <ng-container *ngIf="!isUserAVisitor()">
      <!-- PROFILE AND WORKSPACE GROUP -->
      <div *ngIf="screenHeightSize.largerThanSmall()">
        <div class="d-flex flex-column h-100">
          <!-- User's name initials circle -->
          <div class="d-flex flex-column align-items-center float-center">
            <div class="d-flex flex-column align-items-center pt-2 cursor-pointer" (click)="toggleAdmin()">
              <div class="circle-header text-center">
                <span style="font-size: 1.5rem; font-weight: 350">{{ userInfo?.nameInitials() }}</span>
              </div>
              <span class="pt-2 ms-0 ps-0" style="font-size: 0.9rem; color: var(--main-light-color)">
                {{ userInfo.firstName }}
              </span>
            </div>
          </div>
          <!-- user profile item -->
          <div class="bg--dark" [hidden]="isUserProfileHide">
            <div
              class="d-flex flex-column align-items-center pt-3 cursor-pointer"
              routerLink="/ws/profile"
              [ngClass]="{
                'active-menu': profileMenuItem.isActivePage(),
                'none-active-menu': !profileMenuItem.isActivePage()
              }"
            >
              <i [class]="profileMenuItem.icon" style="font-size: 1.5rem"></i>
              <span style="font-size: 0.7rem">{{ profileMenuItem.name }}</span>
            </div>
          </div>
          <!-- workspace -->
          <div
            class="d-flex flex-column align-items-center pt-3"
            routerLink="/pr/workspaces"
            routerLinkActive="router-link-active"
          >
            <span
              class="badge badge-workspace fw-normal text-truncate text-center"
              style="font-size: 0.8rem"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ stateService.activeWorkspace?.companyName }}"
            >
              {{ stateService.activeWorkspace?.companyName.slice(0, 10) }}
            </span>
          </div>
          <div style="height: calc((100vh - 360px) * 0.05)"></div>
        </div>
      </div>
      <!-- MAIN MENU GROUP -->
      <div class="flex-fill" *ngIf="screenHeightSize.largerThanXsmall()">
        <div class="d-flex flex-column h-100">
          <ng-container *ngFor="let itemGroup of filterdItemGroups() as igs; let itemGroupIndex = index">
            <ng-container>
              <ng-container *ngFor="let parent of itemGroup.items; let parentIdex = index">
                <div
                  class="d-flex flex-column align-items-center pt-3 cursor-pointer"
                  (click)="selectMenuItem(parent, null, $event)"
                  [ngClass]="{ 'active-menu': parent.isActivePage(), 'none-active-menu': !parent.isActivePage() }"
                  *ngIf="parent.visible"
                >
                  <div class="d-flex flex-row align-items-center">
                    <div class="arrow-left me-1" *ngIf="parent.open"></div>
                    <i
                      [class]="parent.icon"
                      [ngClass]="{ selected: parent.isMenuHighlighted() }"
                      class="sidebar-item-icon"
                    ></i>
                  </div>
                  <span style="font-size: 1rem">{{ parent.name }}</span>
                </div>
                <div
                  class="d-flex flex-column align-items-center pt-3"
                  *ngIf="itemGroupIndex < igs.length - 1 && parentIdex == itemGroup.items.length - 1"
                >
                  <i class="las la-ellipsis-h fs-5 text-white"></i>
                </div>
                <div style="height: calc((100vh - 360px) * 0.03)" *ngIf="parent.visible && !parent.open"></div>
                <ng-container *ngIf="parent.open">
                  <ng-container *ngFor="let child of parent.children">
                    <div
                      class="bg--dark"
                      [ngClass]="{ 'active-menu': child.isActivePage(), 'none-active-menu': !child.isActivePage() }"
                      [@transitionState]="parent.getAnimationName()"
                    >
                      <div
                        class="d-flex flex-column align-items-center pt-4 cursor-pointer"
                        (click)="selectMenuItem(parent, child, $event)"
                      >
                        <i class="fs-4 {{ child.icon }}"></i>
                        <span style="font-size: 0.7rem">{{ child.name }}</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- LOGO GROUP -->
    <div class="d-flex justify-content-center mb-2" *ngIf="screenHeightSize.largerThanMedium()">
      <img
        class="brand-logo img-responsive img-fluid"
        src="/assets/img/logos/Logo-Sustainlab.png"
        style="width: 38px"
        routerLink="/ws/home"
      />
    </div>
  </div>
  <div *ngIf="screenWidthSize.isSmallerSize()">
    <div class="d-flex justify-content-start h-100">
      <a><i class="la la-bars header-button-icon toggleBtn" (click)="toggleMenu()"></i></a>
    </div>
  </div>
</div>
