import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { CustomEmissionFactorFe } from 'src/app/model/emissions/CustomEmissionFactorFe'
import { GlobalEmissionFactorFe } from 'src/app/model/emissions/GlobalEmissionFactorFe'

export class PredefinedEmissionFactorOptionFe {
  selectedEmissionFactor: AbstractEmissionFactorFe

  constructor(selectedEmissionFactor?: AbstractEmissionFactorFe) {
    if (selectedEmissionFactor) {
      if (selectedEmissionFactor['databaseId']) {
        this.selectedEmissionFactor = GlobalEmissionFactorFe.fromTransfer(selectedEmissionFactor)
      } else {
        this.selectedEmissionFactor = CustomEmissionFactorFe.fromTransfer(selectedEmissionFactor)
      }
    }
  }

  public static fromJsonObj(obj: any): PredefinedEmissionFactorOptionFe {
    return new PredefinedEmissionFactorOptionFe(obj.selectedEmissionFactor)
  }
}
