import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { LoginUserFe } from 'src/app/model/org/LoginUserFe'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { GoogleIdentityPlatformService } from 'src/app/services/GoogleIdentityPlatformService'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

enum MFA_SCREEN {
  'ADD_PHONE' = 'ADD_PHONE',
  'SELECT_PHONE' = 'SELECT_PHONE',
  'VERIFY_CODE' = 'VERIFY_CODE'
}
@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AbstractLanguageComponent implements OnInit {
  loginForm: FormGroup
  isSubmitted = false
  response: any
  invalidLogin: boolean
  ErrorMessage: String
  userInfo: LoginUserFe
  existingUser = false
  firstTimeUser = false
  existingUserNewEmail = false
  passwordMessage = ValidationMessages.password[0].message
  validationMinlengthMessage = ValidationMessages.password[1].message
  isPasswordVisible = false
  isLoggingIn = false
  phone: boolean = false
  receiveCode: boolean = false
  sendLink: boolean = false
  addPhone: boolean = true
  editPhone: boolean = false
  mfaRequired: boolean = false
  mfaScreen: MFA_SCREEN = MFA_SCREEN.SELECT_PHONE
  newPhonenumber: string = ''
  mfaVerificationID: string = ''
  mfaVerificationCode: string = ''
  mfaProvider: string = '' // Can be either mfaEnroll || mfaAuth
  userPhones = []
  mfaPhoneIdx: string = ''
  mfaType: string = ''
  currentPhoneNumbers: boolean = false
  showErrorMessage = false
  isLoading = false

  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  })

  constructor(
    private fb: FormBuilder,
    private loginService: LoginServiceFe,
    private router: Router,
    private gcpip: GoogleIdentityPlatformService,
    private activatedRoute: ActivatedRoute,
    private ErrorsFe: ErrorsFe,
    languageService: LanguageService
  ) {
    super(languageService)
    const loginAction = this.activatedRoute.snapshot.queryParams['loginAction']
    if (this.loginService.loggedIn && loginAction != 'switchWorkspace') {
      this.router.navigate([RoutesFe.HOME.fullPath()])
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]],
      password: ['', [Validators.required]]
    })
    this.checkLoggedInWithProvider()
  }

  get formFields() {
    return this.loginForm.controls
  }

  saveQueryParamsToLocalStorage() {
    const loginAction = this.activatedRoute.snapshot.queryParams['loginAction'] || 'login'
    const loginNextUrl = this.activatedRoute.snapshot.queryParams['loginNextUrl']
    switch (loginAction) {
      case 'register':
        sessionStorage.setItem('loginAction', loginAction)
        sessionStorage.setItem('loginNextUrl', loginNextUrl)
        break
      case 'switchWorkspace':
        sessionStorage.setItem('loginAction', loginAction)
        sessionStorage.setItem('loginNextUrl', loginNextUrl)

        const workspace = this.activatedRoute.snapshot.queryParams['workspace']
        const loginType = this.activatedRoute.snapshot.queryParams['loginType']
        sessionStorage.setItem('workspace', workspace)
        sessionStorage.setItem('loginType', loginType)
        break
    }
  }

  clearQueryParamsFromLocalStorage() {
    const queryParams = ['loginAction', 'loginNextUrl', 'loginType', 'workspace']
    queryParams.forEach((queryParam) => {
      sessionStorage.removeItem(queryParam)
    })
  }

  async redirectToGoogle() {
    this.saveQueryParamsToLocalStorage()
    await this.loginService.redirectToGoogle()
  }

  async redirectToMicrosoft() {
    this.saveQueryParamsToLocalStorage()
    await this.loginService.redirectToMicrosoft()
  }

  async checkLoggedInWithProvider() {
    const signInWithGoogle = sessionStorage.getItem('signInWithGoogle')
    if (signInWithGoogle == 'true') {
      sessionStorage.removeItem('signInWithGoogle')
      const provider = 'google'
      this.onLogin({ provider })
    }
    const signInWithMicrosoft = sessionStorage.getItem('signInWithMicrosoft')
    if (signInWithMicrosoft == 'true') {
      sessionStorage.removeItem('signInWithMicrosoft')
      const provider = 'microsoft'
      this.onLogin({ provider })
    }
  }

  async onLogin({ provider } = { provider: 'password' }) {
    this.isSubmitted = true
    this.invalidLogin = false
    if (provider == 'password' && this.loginForm.invalid) {
      return
    } else {
      try {
        if (provider != 'password') {
          this.loginForm.controls.email.clearValidators()
          this.loginForm.controls.password.clearValidators()
        }
        this.isLoggingIn = true
        const loginAction =
          this.activatedRoute.snapshot.queryParams['loginAction'] || sessionStorage.getItem('loginAction') || 'login'
        const loginNextUrl =
          this.activatedRoute.snapshot.queryParams['loginNextUrl'] || sessionStorage.getItem('loginNextUrl')
        const workspace = this.activatedRoute.snapshot.queryParams['workspace'] || sessionStorage.getItem('workspace')

        let user = await this.loginService.login({
          email: this.loginForm.controls.email.value,
          password: this.loginForm.controls.password.value,
          provider,
          loginAction
        })
        this.isLoggingIn = false
        switch (loginAction) {
          case 'login':
            this.router.navigate([RoutesFe.HOME.fullPath()])
            break
          case 'register':
            const registerUrl = loginNextUrl
            const registerAction = 'register'
            this.clearQueryParamsFromLocalStorage()
            this.router.navigate([registerUrl], {
              queryParams: {
                registerAction
              }
            })
            break
          case 'switchWorkspace':
            const workspaceUrl = loginNextUrl
            const workspaceAction = 'changeWorkspace'
            this.clearQueryParamsFromLocalStorage()
            console.log('In switch workspace...')
            console.log({
              workspaceUrl,
              workspaceAction,
              workspace
            })
            this.router.navigate([workspaceUrl], {
              queryParams: {
                workspaceAction,
                workspace
              }
            })
            break
        }
      } catch (error) {
        console.log(error)
        this.isLoggingIn = false
        this.invalidLogin = true
        let knownError = this.ErrorsFe.matchError(error.error)
        if (knownError) {
          if (
            knownError == OpenErrorsFe.INCORRECT_PASS_ERROR ||
            knownError == OpenErrorsFe.USER_NOT_FOUND_ERROR ||
            knownError == OpenErrorsFe.TOO_MANY_FAILED_ATTEMPTS ||
            knownError == OpenErrorsFe.PROVIDER_ACCOUNT_NOT_LINKED ||
            knownError == OpenErrorsFe.WEB_STORAGE_NOT_SUPPORTED
          ) {
            this.ErrorMessage = knownError.message
          } else if (knownError == OpenErrorsFe.MFA_ENROLL_REQUIRED) {
            this.mfaProvider = 'mfaEnroll'
            this.mfaRequired = true
            this.mfaScreen = MFA_SCREEN.ADD_PHONE
          } else if (knownError == OpenErrorsFe.MFA_AUTH_REQUIRED) {
            this.mfaProvider = 'mfaAuth'
            this.mfaRequired = true
            this.mfaScreen = MFA_SCREEN.SELECT_PHONE
            this.userPhones = this.gcpip.getUserPhones()
          } else {
            this.ErrorMessage = "Couldn't login, please try again!"
          }
        } else {
          this.ErrorMessage = "Couldn't login, please try again!"
        }
      }
    }
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible
    if (this.isPasswordVisible) {
      ;(document.getElementById('password') as HTMLInputElement).type = 'text'
    } else {
      ;(document.getElementById('password') as HTMLInputElement).type = 'password'
    }
  }

  async sendVerifcationCode({ mfaBtnID, mfaType }) {
    this.isLoading = true
    this.mfaType = mfaType
    let phonenumber = this.newPhonenumber
    switch (mfaType) {
      case 'enroll':
        phonenumber = this.phoneForm.get('phone').value.e164Number
        break
    }
    const verificationID = await this.loginService.sendVerifcationCode({
      phonenumber,
      mfaBtnID: mfaBtnID,
      mfaType,
      mfaPhoneIdx: this.mfaPhoneIdx
    })
    this.mfaVerificationID = verificationID
    this.mfaScreen = MFA_SCREEN.VERIFY_CODE
    this.isLoading = false
  }

  async verifyMfaCode() {
    this.isLoading = true
    try {
      await this.loginService.verifyMfaCode({
        verificationID: this.mfaVerificationID,
        verificationCode: this.mfaVerificationCode,
        mfaType: this.mfaType
      })
      this.showErrorMessage = false
      await this.onLogin({ provider: this.mfaProvider })
    } catch (error) {
      this.showErrorMessage = true
      console.error('Error during MFA code verification:', error)
    }
    this.isLoading = false
  }

  deletePhoneNumber(index: number) {
    this.userPhones.splice(index, 1)
  }

  goToLogin() {
    window.location.reload()
  }
}
