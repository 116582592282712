import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import * as UUID from 'uuid'
import { DataGridTableData } from '../model/DataGridTableData'
import { DataGridColumnType } from '../model/DataGridColumnType'
import { DataGridColumnMode } from '../model/DataGridColumnMode'
import { DataGridRow } from '../model/DataGridRow'
import { DataGridColumnSchema } from '../model/DataGridColumnSchema'
import { DataGridTaxonomyEntity } from '../model/DataGridTaxonomyEntity'
import { ExcelViewerServiceInterface } from './ExcelViewerServiceInterface'
import { DataGridStatus } from '../model/DataGridStatus'
import { SelectedData } from '../directive/dragSelect.directive'

@Injectable({
  providedIn: 'root'
})
export class ImportedDataService {
  table = new DataGridTableData()
  dataTaxonomylabels: string[] = []
  headers: string[] = []
  selectedData: string[][] = []
  method: string = ''
  public selectedDataTableAdded = new Subject<boolean>()
  public mapToTaxonomy = new Subject<boolean>()
  public excelViewerService!: ExcelViewerServiceInterface
  public cols: { col: DataGridColumnSchema; mappedColIndex: number }[] = []
  public unitsForMappedToCols = new Map()
  public taxonomyEntities: DataGridTaxonomyEntity[] = []
  public name: string = ''
  public mainTableKey: string = ''

  addData(value: [], headers: string[]): void {
    this.table = new DataGridTableData()
    this.table.rowCount = value.length
    this.table.name = this.name
    this.table.key = ''
    this.table.dataSchema = []
    this.table.referenceSchema = []
    headers.forEach((el) => {
      this.table.dataSchema?.push({
        name: el.includes('EMPTY') ? '' : el,
        label: el.includes('EMPTY') ? '' : el,
        type: DataGridColumnType.STRING,
        mode: DataGridColumnMode.NULLABLE,
        status: '',
        width: '10rem',
        deployed: false,
        modified: true
      })
    })

    this.table.rows.push({
      rowId: UUID.v4(),
      status: DataGridStatus.STORED,
      values: headers.map((h) => (h.includes('EMPTY') ? '' : h)),
      ordinal: -1
    })

    value.forEach((el) => {
      const x: any[] = []
      headers.forEach((field) => {
        if (el[field] == null || el[field] == undefined) {
          x.push(null)
        } else {
          x.push(el[field])
        }
      })

      this.table.rows.push({
        rowId: UUID.v4(),
        status: DataGridStatus.STORED,
        values: x,
        ordinal: -1
      })
    })
  }

  getData(): DataGridTableData {
    return this.table
  }

  getHeaders(): string[] {
    return [...this.headers]
  }

  setHeaders(headers: string[]): void {
    this.headers = [...headers]
  }

  setName(name: string) {
    this.table.name = name
  }

  setSelectedData(data: string[][]): void {
    this.selectedData = data
  }

  setSelectedTableRows() {
    if (this.selectedData) {
      let data: DataGridRow[] = []

      this.selectedData.forEach((el, rowNo: number) => {
        const rowValues: string[] = []
        this.cols.forEach((colData) => {
          rowValues.push(el[colData.mappedColIndex])
        })

        data.push({
          rowId: UUID.v4(),
          values: rowValues,
          status: 'STORED',
          ordinal: rowNo
        })
      })

      let exportedTable: DataGridTableData = {
        key: undefined,
        name: this.name,
        dataSchema: [],
        referenceSchema: [],
        rows: data,
        rowCount: data.length,
        ordered: false,
        pageSize: 25,
        pageNumber: 1
      }
      this.table = exportedTable
    }
  }

  setSelectedTableSchema() {
    let schema: DataGridColumnSchema[] = this.cols.map((x) => x.col)
    this.table.dataSchema = schema
    this.selectedDataTableAdded.next(true)
  }

  getSelectedDataTable(): DataGridTableData {
    return this.table
  }

  getSelectedData() {
    return this.selectedData
  }

  setDataTaxonomyLabels(data: string[]) {
    this.dataTaxonomylabels = [...data]
  }

  getDataTaxonomyLabels(): string[] {
    return [...this.dataTaxonomylabels]
  }

  setMethod(x: string): void {
    this.method = x
  }

  getMethod(): string {
    return this.method
  }
}
