import { UnitFe } from './UnitFe'
import {
  UnitMeasurementTypes,
  getUnitMeasurementTypeDefaultUnitSymbol,
  getUnitMeasurementTypeName
} from './UnitMeasurementType'
import { UnitSystems, getUnitSystemName } from './UnitSystems'
import _ from 'lodash'

export const groupUnitsByMeasurementTypeAndSystem = (units: UnitFe[]) => {
  const unitsByMeasurementType = {}
  units.forEach((unit) => {
    unitsByMeasurementType[unit.measurementType] = unitsByMeasurementType[unit.measurementType] || {}

    if (unit.isSI) {
      unitsByMeasurementType[unit.measurementType][UnitSystems.SI] = unitsByMeasurementType[unit.measurementType][
        UnitSystems.SI
      ] || { units: [] }
      unitsByMeasurementType[unit.measurementType][UnitSystems.SI].key = UnitSystems.SI
      unitsByMeasurementType[unit.measurementType][UnitSystems.SI].name = getUnitSystemName(UnitSystems.SI)
      unitsByMeasurementType[unit.measurementType][UnitSystems.SI].units.push(unit)
    }

    if (unit.isMetric) {
      unitsByMeasurementType[unit.measurementType][UnitSystems.METRIC] = unitsByMeasurementType[unit.measurementType][
        UnitSystems.METRIC
      ] || { units: [] }
      unitsByMeasurementType[unit.measurementType][UnitSystems.METRIC].key = UnitSystems.METRIC
      unitsByMeasurementType[unit.measurementType][UnitSystems.METRIC].name = getUnitSystemName(UnitSystems.METRIC)
      unitsByMeasurementType[unit.measurementType][UnitSystems.METRIC].units.push(unit)
    }

    if (unit.isImperial) {
      unitsByMeasurementType[unit.measurementType][UnitSystems.IMPERIAL] = unitsByMeasurementType[unit.measurementType][
        UnitSystems.IMPERIAL
      ] || { units: [] }
      unitsByMeasurementType[unit.measurementType][UnitSystems.IMPERIAL].key = UnitSystems.IMPERIAL
      unitsByMeasurementType[unit.measurementType][UnitSystems.IMPERIAL].name = getUnitSystemName(UnitSystems.IMPERIAL)
      unitsByMeasurementType[unit.measurementType][UnitSystems.IMPERIAL].units.push(unit)
    }

    if (unit.isUSCustomary) {
      unitsByMeasurementType[unit.measurementType][UnitSystems.US_CUSTOMARY] = unitsByMeasurementType[
        unit.measurementType
      ][UnitSystems.US_CUSTOMARY] || {
        units: []
      }
      unitsByMeasurementType[unit.measurementType][UnitSystems.US_CUSTOMARY].key = UnitSystems.US_CUSTOMARY
      unitsByMeasurementType[unit.measurementType][UnitSystems.US_CUSTOMARY].name = getUnitSystemName(
        UnitSystems.US_CUSTOMARY
      )
      unitsByMeasurementType[unit.measurementType][UnitSystems.US_CUSTOMARY].units.push(unit)
    }

    if (unit.isOther) {
      unitsByMeasurementType[unit.measurementType][UnitSystems.OTHER] = unitsByMeasurementType[unit.measurementType][
        UnitSystems.OTHER
      ] || { units: [] }
      unitsByMeasurementType[unit.measurementType][UnitSystems.OTHER].key = UnitSystems.OTHER
      unitsByMeasurementType[unit.measurementType][UnitSystems.OTHER].name = getUnitSystemName(UnitSystems.OTHER)
      unitsByMeasurementType[unit.measurementType][UnitSystems.OTHER].units.push(unit)
    }
  })

  let result = []
  Object.keys(unitsByMeasurementType).forEach((measurementTypeKey) => {
    const name = getUnitMeasurementTypeName(UnitMeasurementTypes[measurementTypeKey])
    const defaultUnitSymbol = getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[measurementTypeKey])
    let systems = Object.keys(unitsByMeasurementType[measurementTypeKey]).map((systemKey) => {
      const name = unitsByMeasurementType[measurementTypeKey][systemKey].name
      const units = unitsByMeasurementType[measurementTypeKey][systemKey].units
      const system = { name, key: systemKey, units }
      return system
    })
    systems = _.orderBy(systems, ['name'])
    const measurementType = { name, key: measurementTypeKey, systems, defaultUnitSymbol }

    if (systems.length > 0) {
      result.push(measurementType)
    }
  })

  result = _.orderBy(result, ['name'])
  return result
}
