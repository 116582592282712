import { QUESTION_TYPE } from '../../../request/questionnaire/QuestionTypeFe'
import { Answer_AddFe } from './Answer_AddFe'

export class AnswerSingleChoice_AddFe extends Answer_AddFe {
  selectedOptionId: string

  constructor(id: string, sectionId: string, questionId: string, comments: string | null, selectedOptionId: string) {
    super(id, sectionId, questionId, QUESTION_TYPE.SINGLE_CHOICE, comments)
    this.selectedOptionId = selectedOptionId
  }

  public isInvalid(isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return this.selectedOptionId ? true : false
  }

  public reset(): void {
    this.selectedOptionId = null
  }

  public toTransfer() {
    let ans = new AnswerSingleChoice_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.selectedOptionId
    )
    return ans
  }
}
