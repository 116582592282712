import { InsightFe } from '../insight/InsightFe'
import { CategoryOptionFeNew } from './CategoryOptionFeNew'
import { ChartTypeFe } from './ChartTypeFe'
import { TimeOptionFe } from './TimeOptionFe'

export class ChartOptionsFeNew {
  constructor(
    public readonly categoryOptions: CategoryOptionFeNew[],
    public readonly timeOptions: TimeOptionFe[],
    public readonly chartTypes: ChartTypeFe[]
  ) {}

  public timeScaleByIndex(index) {
    let currentTimeScale = this.timeOptions[index].timeScale
    return currentTimeScale
  }

  public static buildInstance(insightSubject: InsightFe): ChartOptionsFeNew {
    let timeOptions: TimeOptionFe[] = []
    let numVals = insightSubject.definition.dataSeries.length
    let isTimed = insightSubject.isTimed()
    let isPie = insightSubject.makesPie()
    let categoryOptions: CategoryOptionFeNew[] = []
    insightSubject.definition.groupings.forEach((grouping, i) => {
      let vals = insightSubject.uniqueCategoryValuesOrdered(i)
      let categoryOption = new CategoryOptionFeNew(grouping.id, grouping.label, vals)
      categoryOptions.push(categoryOption)
    })

    if (isTimed) {
      timeOptions = insightSubject.kpis.map((kpi) => {
        return new TimeOptionFe(kpi.timeScale, kpi.uniqueTimeValuesOrdered())
      })
    }

    if (!isTimed && numVals == 1) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [ChartTypeFe.BAR, ChartTypeFe.TABLE])
    } else if (!isTimed && numVals > 1 && !isPie) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [
        ChartTypeFe.BAR,
        ChartTypeFe.STACK,
        ChartTypeFe.DOUGHNUT,
        ChartTypeFe.PIE,
        ChartTypeFe.TABLE
      ])
    } else if (!isTimed && numVals > 1 && isPie) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [
        ChartTypeFe.PIE,
        ChartTypeFe.DOUGHNUT,
        ChartTypeFe.STACK,
        ChartTypeFe.BAR,
        ChartTypeFe.TABLE
      ])
    } else if (isTimed && numVals == 1) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [ChartTypeFe.BAR, ChartTypeFe.LINE, ChartTypeFe.TABLE])
    } else if (isTimed && numVals > 1 && !isPie) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [
        ChartTypeFe.BAR,
        ChartTypeFe.LINE,
        ChartTypeFe.STACK,
        ChartTypeFe.DOUGHNUT,
        ChartTypeFe.PIE,
        ChartTypeFe.TABLE
      ])
    } else if (isTimed && numVals > 1 && isPie) {
      return new ChartOptionsFeNew(categoryOptions, timeOptions, [
        ChartTypeFe.PIE,
        ChartTypeFe.DOUGHNUT,
        ChartTypeFe.STACK,
        ChartTypeFe.BAR,
        ChartTypeFe.LINE,
        ChartTypeFe.TABLE
      ])
    } else {
      throw Error('No chart-type is supported for KPI object: ' + insightSubject.title)
    }
  }
}
