import { ActivityTypeFe } from '../ActivityTypeFe'
import { AbstractActivity_AddFe } from './AbstractActivity_AddFe'

export class AcceptSubmissionActivity_AddFe extends AbstractActivity_AddFe {
  acceptedActivityId: string

  constructor(
    id: string,
    acceptedActivityId: string,
    message: string | null,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string
  ) {
    super(id, ActivityTypeFe.ACCEPT_SUBMISSION, message, false, requestTaskId, requestGroupId, requestGroupLabel)
    this.acceptedActivityId = acceptedActivityId
  }
}
