import { KpiFilterFe } from '../kpi/filter/KpiFilterFe'
import { KpiFilterFe2 } from '../kpi/filter/KpiFilterFe2'
import { DataSeriesFe } from './DataSeriesFe'
import { GroupingFe } from './GroupingFe'
import { TimeGroupingFe } from './TimeGroupingFe'

export class InsightProcessingPipelineDefFe {
  // purely front end stuff to support on the fly conversion
  unitSymbolChanged: boolean = false
  originalUnitSymbol: string
  constructor(
    public dataSeries: DataSeriesFe[],
    public unitLabel: string,
    public unitSymbol: string,
    public timeGrouping: TimeGroupingFe | undefined,
    public groupings: GroupingFe[],
    public filters: KpiFilterFe2[]
  ) {}

  public static fromTransfer(transfer: any): InsightProcessingPipelineDefFe {
    let timeGrouping = transfer.timeGrouping ? TimeGroupingFe.fromTransfer(transfer.timeGrouping) : undefined
    let groupings = transfer.groupings ? transfer.groupings.map((ds: any) => GroupingFe.fromTransfer(ds)) : []
    let filters = transfer.filters ? transfer.filters.map((filter) => KpiFilterFe2.fromTransfer(filter)) : []
    let definition = new InsightProcessingPipelineDefFe(
      transfer.dataSeries.map((ds: any) => DataSeriesFe.fromTransfer(ds)),
      transfer.unitLabel,
      transfer.unitSymbol,
      timeGrouping,
      groupings,
      filters
    )
    return definition
  }

  public clone(): InsightProcessingPipelineDefFe {
    return InsightProcessingPipelineDefFe.fromTransfer(this)
  }

  public static toTransfer(
    dataSeries: DataSeriesFe[],
    unitLabel: string,
    unitSymbol: string,
    timeGrouping: TimeGroupingFe | undefined,
    groupings: GroupingFe[],
    filters: KpiFilterFe2[]
  ): InsightProcessingPipelineDefFe {
    groupings.forEach((grouping: GroupingFe, index) => {
      grouping.setId(index)
      grouping.dataPoints.forEach((dp) => (dp.groupingId = grouping.id))
    })
    dataSeries.forEach((ds: DataSeriesFe, index) => {
      ds.setId(index)
      ds.dataPoints.forEach((dp) => (dp.dataSeriesId = ds.id))
    })
    let definition = new InsightProcessingPipelineDefFe(
      dataSeries,
      unitLabel,
      unitSymbol,
      timeGrouping,
      groupings,
      filters
    )
    return definition
  }

  getUnit() {
    return this.unitSymbol || this.unitLabel
  }
}
