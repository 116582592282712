<div class="container-fluid mx-0 px-0">
  <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
    <div class="d-flex fled-row justify-content-start align-items-center pt-3 ps-2">
      <h4 class="ps-2">{{ slocale('Create a request') }}</h4>
    </div>
    <hr class="m-0 p-0" />
    <div class="row text-center pb-3 pt-4">
      <div
        class="col-3 mt-0 pt-0 mx-0 ps-1 pe-0"
        *ngFor="let step of stepsRequestData; let index = index; let last = last"
      >
        <div
          class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center"
        >
          <div class="circle1 active-circle1 mx-0 px-0">{{ index + 1 }}</div>
          <h6 class="my-auto ms-1">{{ step.title }}</h6>
          <span class="ms-1 mt-1" style="font-size: 1.5rem" *ngIf="!last"><i class="las la-angle-right"></i></span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
    <div class="d-flex flex-row justify-content-center mb-1 pt-3">
      <div class="d-block text-center">
        <div
          class="col stepper text-center"
          *ngFor="let step of stepsRequestData; let i = index"
          [class.active-stepper]="currentActive === i + 1 || step.completed"
        ></div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center mb-1">
      <h6 class="text-center m-0">
        {{ stepsRequestData[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})
      </h6>
    </div>
  </ng-container>
</div>
<hr class="mt-0 pt-0 mx-0 px-0" />
<div
  class="card-body mt-0 pt-0 px-0"
  style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important; height: 60vh"
>
  <div *ngIf="activeFormNo == 1" class="px-3 h-100">
    <div class="" style="height: 90% !important">
      <div class="container-fluid vh-auto">
        <div class="row p-3">
          <h5 class="text-justify pt-3">
            {{ slocale('Select the calculation method for the data you want to report.') }}
          </h5>
        </div>
        <div class="d-flex flex-row justify-content-evenly align-items-center w-100 flex-wrap">
          <div
            class="card calMethod platform-card mb-3 p-0 h-100"
            [ngClass]="{ selected: calculationMethod == 'dataReporter' }"
            (click)="calculationMethod = 'dataReporter'"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input
                    type="radio"
                    id="calculationMethod"
                    [(ngModel)]="calculationMethod"
                    name="calculationMethod"
                    value="dataReporter"
                    checked
                    class="d-inline me-2"
                  />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">{{ slocale('Data reporter select the calculation method') }}</h5>
                  </label>
                  <h6 class="pt-3">
                    {{
                      slocale(
                        'With this option data reporters can select the calculation method that best suits their available data.'
                      )
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
            </div>
          </div>
          <div
            class="card calMethod platform-card mb-3 p-0 h-100"
            [ngClass]="{ selected: calculationMethod == 'weightData' }"
            (click)="calculationMethod = 'weightData'"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input
                    type="radio"
                    id="calculationMethod"
                    [(ngModel)]="calculationMethod"
                    name="calculationMethod"
                    value="weightData"
                    checked
                    class="d-inline me-2"
                  />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">{{ slocale('Weight data') }}</h5>
                  </label>
                  <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                  <ul class="">
                    <li>{{ slocale('Waste material') }}</li>
                    <li>{{ slocale('Waste volume in weight') }}</li>
                    <li>{{ slocale('Waste treatment method') }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
            </div>
          </div>
          <div
            class="card calMethod platform-card mb-3 p-0 h-100"
            [ngClass]="{ selected: calculationMethod == 'unitData' }"
            (click)="calculationMethod = 'unitData'"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input
                    type="radio"
                    id="calculationMethod"
                    [(ngModel)]="calculationMethod"
                    name="calculationMethod"
                    value="unitData"
                    checked
                    class="d-inline me-2"
                  />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">
                      {{ slocale('Unit data') }}
                      <span class="badge bg-comp-grey w-auto text-wrap p-2 success-text">Best practice</span>
                    </h5>
                  </label>
                  <h6 class="pt-3">{{ slocale('Data required for emission calculation') }}</h6>
                  <ul class="">
                    <li>{{ slocale('Product group') }}</li>
                    <li>{{ slocale('Waste volume (in number of units)') }}</li>
                    <li>{{ slocale('Average weight per unit') }}</li>
                    <li>{{ slocale('Waste treatment method') }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
            </div>
          </div>
          <div
            class="card calMethod platform-card mb-3 p-0 h-100"
            [ngClass]="{ selected: calculationMethod == 'customCal' }"
            (click)="calculationMethod = 'customCal'"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input
                    type="radio"
                    id="calculationMethod"
                    [(ngModel)]="calculationMethod"
                    name="calculationMethod"
                    value="customCal"
                    checked
                    class="d-inline me-2"
                  />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">{{ slocale('Define custom calculation method') }}</h5>
                  </label>
                  <h6 class="pt-3">{{ slocale('Steps to define the method:') }}</h6>
                  <ul class="">
                    <li>{{ slocale('define necessary data points') }}</li>
                    <li>{{ slocale('determine where data will be stored') }}</li>
                    <li>{{ slocale('define calculation for GHG emissions') }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
            </div>
          </div>
          <div
            class="card calMethod platform-card mb-3 p-0 h-100"
            [ngClass]="{ selected: calculationMethod == 'noCal' }"
            (click)="calculationMethod = 'noCal'"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input
                    type="radio"
                    id="calculationMethod"
                    [(ngModel)]="calculationMethod"
                    name="calculationMethod"
                    value="noCal"
                    checked
                    class="d-inline me-2"
                  />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">{{ slocale('GHG emissions without calculations') }}</h5>
                  </label>
                  <h6 class="pt-3">
                    {{
                      slocale(
                        'Opt for this option to input final emission data directly without additional calculations.'
                      )
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted">{{ slocale('ACTIVITY-BASED') }}</h5>
            </div>
          </div>
          <div
            class="card calMethod platform-card mb-3 p-0 h-100 disabled"
            style="border-top: 10px solid var(--complementary-color1); border-radius: 0"
          >
            <div class="card-body px-0">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="p-4 pt-5">
                  <input type="radio" disabled class="d-inline me-2" />
                  <label for="newProject" class="d-inline">
                    <h5 class="d-inline">{{ slocale('Spend data') }}</h5>
                  </label>
                  <h6 class="pt-3">
                    {{
                      slocale(
                        'This method calculates emissions by assessing the monetary value of goods or services purchased and applying emission factors to those expenditures.'
                      )
                    }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-footer bg-comp-grey text-center pt-2">
              <h5 class="mb-0 pb-0 text-muted" style="color: var(--orange) !important">{{ slocale('COMING SOON') }}</h5>
            </div>
          </div>
        </div>
        <div class="row px-3 text-center">
          <i class="text-justify pt-3">
            {{
              slocale(
                'Questions about which method to choose? Contact your customer support representative for assistance.'
              )
            }}
          </i>
        </div>
        <hr />
        <div class="row px-3 text-start">
          <div class="d-flex flex-row flex-wrap pt-3 pb-2">
            <h5 class="text-justify">{{ slocale('Should data reporters submit emission factors?') }}</h5>
            <i
              class="la la-info-circle info-popover-arrow d-inline size-15 ms-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>
                {{
                  slocale(
                    'You have the flexibility to choose whether data reporters provide emission factors along with their data. If they report these factors, you also have the option to make edits later if needed. Alternatively, if you prefer not to have data reporters include emission factors, you can add the factors once you receive the reported data.'
                  )
                }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-center pb-3">
            <button
              class="btn tertiary-button me-2 custom-button"
              (click)="askEF = true"
              [ngClass]="{ 'btn-pink': askEF }"
            >
              {{ slocale('Yes') }}
            </button>
            <button
              class="btn tertiary-button me-2 custom-button"
              (click)="askEF = false"
              [ngClass]="{ 'btn-pink': !askEF }"
            >
              {{ slocale('No') }}
            </button>
          </div>
          <div class="d-flex flex-column px-3 py-2" *ngIf="askEF">
            <small>Emission factor submitted by data reporters can be edited.</small>
          </div>
        </div>
        <hr />
        <div class="row px-3 text-start">
          <div class="d-flex flex-row flex-wrap pt-3 pb-2">
            <h5 class="text-justify">
              {{
                slocale('Should data reporters include proof documents for the submitted data to ensure traceability?')
              }}
            </h5>
            <i
              class="la la-info-circle info-popover-arrow d-inline size-15 ms-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>
                {{
                  slocale(
                    'You can decide whether the data reporters should provide Proof documents (eg. invoices, utility bills, operational records) for the data they are reporting, to ensure the numbers are accurate and reliable.'
                  )
                }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-center">
            <button
              class="btn tertiary-button custom-button me-2"
              (click)="askProofDocuments = true"
              [ngClass]="{ 'btn-pink': askProofDocuments }"
            >
              {{ slocale('Yes') }}
            </button>
            <button
              class="btn tertiary-button custom-button me-2"
              (click)="askProofDocuments = false"
              [ngClass]="{ 'btn-pink': !askProofDocuments }"
            >
              {{ slocale('No') }}
            </button>
          </div>
          <div class="d-flex flex-column px-3 py-2" *ngIf="askProofDocuments">
            <small>
              This is the information the data reporters will receive for the required proof documentation. Edit the
              description so that it matches your requirements.
            </small>
            <textarea class="form-control mt-2 w-75" [formControl]="proofDocQues" id="proofDocQues" rows="3"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--NAME THE PROJECT PAGE-->
  <div *ngIf="activeFormNo == 2" class="px-3">
    <div class="row ms-4 mt-1" style="max-width: 40%" [formGroup]="reqDetailsForm">
      <div class="col-12">
        <div class="form-group">
          <label for="title">{{ locale('locale_key.pages.data_request.request.title') }}*</label>
          <input
            type="text"
            class="form-control"
            id="title"
            placeholder="{{ locale('locale_key.pages.data_request.create.title.placeholder') }}"
            formControlName="title"
            [ngClass]="{
              'is-invalid': reqDetailsForm.get('title').errors && reqDetailsForm.get('title').touched
            }"
            data-test="request-title-field"
          />
          <div
            *ngIf="reqDetailsForm.get('title').errors && reqDetailsForm.get('title').touched"
            class="invalid-feedback"
          >
            <div *ngIf="reqDetailsForm.get('title').errors.required">
              {{ requestTitleMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="form-group">
          <label for="title">{{ locale('locale_key.pages.data_request.create.desc.title') }}</label>
          <textarea
            type="text"
            class="form-control"
            id="description"
            placeholder="{{ locale('locale_key.pages.data_request.create.desc.placeholder') }}"
            formControlName="desc"
            style="min-height: 250px"
            (keyup)="autoGrowTextZone($event)"
            data-test="request-description"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <!--SELECT EMISSIONS PAGE-->
  <div *ngIf="activeFormNo == 3" style="overflow-x: hidden; height: 100% !important">
    <div class="row ms-4 mt-1">
      <div class="col-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <h4 class="bold">{{ slocale('Request preview') }}*</h4>
            <span class="ms-auto p-1 border" (click)="editQuestionnaire = !editQuestionnaire">
              <i class="las la-edit py-1 size-15 my-auto" *ngIf="!editQuestionnaire"></i>
              <span class="text-muted my-auto cursor">
                {{ !editQuestionnaire ? slocale('Edit questionnaire') : slocale('See preview') }}
              </span>
            </span>
          </div>
          <p>
            This request is about to be sent to the data reporters and it is automatically generated based on your
            selected emission sources and other settings. You can review and edit it if needed.
          </p>
        </div>
      </div>
    </div>
    <div class="mx-2">
      <edit-questionnaire
        *ngIf="editQuestionnaire"
        [showHeader]="false"
        [sections]="questionnaire.sections"
      ></edit-questionnaire>
      <questionnaire-preview
        *ngIf="!editQuestionnaire"
        [questionnaire]="questionnaire"
        [requestTitle]="reqDetailsForm.controls.title.value"
        [showMapping]="false"
      ></questionnaire-preview>
    </div>
  </div>
  <!--EMISSIONS DASHBOARD PAGE-->
  <div *ngIf="activeFormNo == 4" class="px-2 bg-light h-100" style="overflow-x: hidden">
    <div class="container-fluid">
      <div class="row">
        <p class="ps-4">
          {{
            slocale(
              'The necessary data points for calculating the GHG emissions have been added. Fill in any missing data to continue with the calculation.'
            )
          }}
        </p>
      </div>
      <div
        class="bg-light-gray pb-2 pt-3 px-3 mx-2 d-flex flex-row justify-content-between align-items-center flex-wrap border"
      >
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Waste material') }}
          <i
            class="la la-info-circle info-popover-arrow bold"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Waste material .....') }}</p>
          </div>
        </h6>
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Waste treatment method') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Waste treament.....') }}</p>
          </div>
        </h6>
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Waste volume in weight') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Waste vol in weight.') }}</p>
          </div>
        </h6>
        <h6>
          <span class="bg-pink w-auto rounded px-2 text-white">{{ slocale('New') }}</span>
          {{ slocale('Emission factor') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Emission factor.') }}</p>
          </div>
        </h6>
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Time') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Time.') }}</p>
          </div>
        </h6>
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Organizational unit') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Org unit....') }}</p>
          </div>
        </h6>
        <h6>
          <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
          {{ slocale('Country') }}
          <i
            class="la la-info-circle info-popover-arrow"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div class="dropdown-menu arrow p-2 info-popover">
            <p>{{ slocale('Country....') }}</p>
          </div>
        </h6>
      </div>
      <div class="w-100 h-100 p-2">
        <img src="assets/img/table.jpg" alt="CarbonLab-Icons-01" class="img-fluid w-100 h-100" />
      </div>
    </div>
  </div>
</div>
<div
  id="card-footer"
  class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0 w-100"
  style="background-color: transparent"
>
  <ng-container *ngIf="!loadingInProgress">
    <button type="button" class="btn m-1 tertiary-button custom-button" (click)="saveDraftModal(true)">
      {{ locale('locale_key.general.buttons.cancel') }}
    </button>
    <button
      type="button"
      class="btn m-1 secondary-button custom-button"
      [hidden]="activeFormNo == 1"
      (click)="saveDraftModal(false)"
    >
      {{ locale('locale_key.button.save_as_draft') }}
    </button>
    <button type="button" class="btn m-1 tertiary-button custom-button" *ngIf="activeFormNo != 1" (click)="prev()">
      {{ locale('locale_key.general.buttons.previous') }}
    </button>
    <button
      type="button"
      class="btn m-1 primary-button custom-button mt-sm-1"
      *ngIf="activeFormNo != 4"
      (click)="next()"
    >
      {{ locale('locale_key.general.buttons.next') }}
    </button>
    <button
      type="button"
      class="btn m-1 primary-button custom-button mt-sm-1"
      (click)="pullDataFromPlatform ? openModal(emissionsPortalModal, 'modal-lg') : closeModal()"
      *ngIf="activeFormNo == 4"
    >
      {{ activeFormNo == 4 && pullDataFromPlatform ? slocale('Next') : slocale('Finish') }}
    </button>
  </ng-container>
</div>

<!-- Create project modal-->
<ng-template #emissionsPortalModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Emissions portal') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row mx-auto text-center">
      <h3 class="bold mx-auto">{{ slocale('Choose your action.') }}</h3>
    </div>
    <div class="container-fluid">
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-search my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Search in emission databases') }}</small>
              <small class="card-title col mx-auto">{{ slocale('provided by SustainLab') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="
                  activeTab = 'emissionFactor';
                  emissionFormSustainLab = true;
                  openModal(selectEmissionsModal, 'modal-xl')
                "
              >
                {{ slocale('Search') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-database my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Select from my company's") }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factors') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="emissionFormSustainLab = false; openModal(selectEmissionsModal, 'modal-xl')"
              >
                {{ slocale('Select') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i _ngcontent-qgx-c224="" class="la la-plus my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Add custom') }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factor') }}</small>
              <button class="btn primary-button mx-auto custom-button">{{ slocale('Add custom') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #selectEmissionsModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 *ngIf="!emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Select from my company’s emission factors') }}
    </h5>
    <h5 *ngIf="emissionFormSustainLab" class="modal-title text-white">
      {{ slocale('Search in emission databases provided by SustainLab') }}
    </h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="!emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Edit') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')">
                        <i class="la la-edit me-2"></i>
                        {{ slocale('Edit') }}
                      </a>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 80vh !important">
          <table class="table table-responsive mt-1 table-sm">
            <thead style="border-bottom: 4px solid var(--main-contrast-color)">
              <tr class="">
                <th colspan="2">
                  <h6 class="text-muted mb-0 pb-0 mt-2">{{ slocale('Custom emission detail factor') }}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="">
                  <h6 class="text-muted mt-2">{{ slocale('Emission source') }}</h6>
                </td>
                <td class="">
                  <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                </td>
              </tr>
              <tr class="">
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Emission source unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">{{ slocale('litres') }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">kg CH4</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                </td>
                <td>
                  <h6 class="text-muted mt-2">2.01888</h6>
                </td>
              </tr>
            </tbody>
            <h6 class="text-muted ps-2 pt-3">{{ slocale('Emission factor origin') }}</h6>
            <p class="ps-2 text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo
            </p>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="emissionFormSustainLab">
    <div class="container-fluid p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1">
                <i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>
                {{ slocale('Back to menu') }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input
                type="text"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.general.search') }}"
                (input)="filter(search.value)"
                #search
              />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale('Click on the emission factor to select') }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Emission source unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion unit') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Conversion factor') }}</span>
                    </h6>
                  </th>
                  <th class="">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Database name') }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale('Additional details') }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3 hide-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">kg CH4</h6>
                      </div>
                      <div class="pt-3">
                        <h6 class="blurred-text">0.00107</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3 show-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale('Licenced material') }}</h4>
                      <i
                        class="la la-info-circle info-popover-arrow ms-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale('liters') }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale('Biofuel') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale('liters') }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Sub-Type: Natural gas') }}
                      </span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">
                        {{ slocale('Location: Europe') }}
                      </span>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 100vh !important">
          <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab31"
                [class.active]="activeTab === 'emissionFactor'"
                (click)="selectTab('emissionFactor')"
              >
                {{ slocale('Emission factor detail') }}
              </a>
            </li>
            <li class="nav-item bg-transparent">
              <a
                class="nav-link mb-0 pb-0"
                id="base-tab32"
                [class.active]="activeTab === 'databaseInfo'"
                (click)="selectTab('databaseInfo')"
              >
                {{ slocale('Database info') }}
              </a>
            </li>
          </ul>
          <div class="tab-content px-1 pt-1">
            <div
              role="tabpanel"
              class="tab-pane"
              id="emissionFactor"
              [class.active]="activeTab === 'emissionFactor'"
              aria-labelledby="base-emissionFactor"
            >
              <div class="">
                <table class="table table-responsive mt-1 table-sm">
                  <thead>
                    <tr class="">
                      <th colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="">
                      <td class="">
                        <h6 class="text-muted mt-2">{{ slocale('Category') }}</h6>
                      </td>
                      <td class="">
                        <h6 class="text-muted bold mt-2">{{ slocale('Biofuel') }}</h6>
                      </td>
                    </tr>
                    <tr class="">
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Natural gas') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Sub-type') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Biodiesel (from used cooking oil)') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion factor') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Conversion unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Clasification') }}</h6>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Commodity Unit') }}</h6>
                      </td>
                      <td>
                        <div
                          class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2"
                        >
                          <h5 class="text-muted my-auto italic">{{ slocale('Licenced material') }}</h5>
                          <i
                            class="la la-info-circle info-popover-arrow ms-1 size-15"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale('Click on the link below to subscribe to licenced material.') }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Continent') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Europe') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Data source') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">
                          {{ slocale('UK Government GHG Conversion Factors for Company Reporting') }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Location') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('-') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Scope') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('Scope 1') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale('Year') }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale('2020') }}</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class="text-muted ps-2 pt-3 w-100">{{ slocale('Emission factor origin') }}</h6>
                <p class="ps-2 text-justify">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                </p>
              </div>
            </div>
            <div
              class="tab-pane"
              id="databaseInfo"
              [class.active]="activeTab === 'databaseInfo'"
              aria-labelledby="base-databaseInfo"
            >
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <h6 class="bold">DEFRA</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Original release date') }}</h6>
                <h6 class="bold">Jan 01 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Upload date in platform') }}</h6>
                <h6 class="bold">Jun 01, 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Publisher organization') }}</h6>
                <h6 class="bold">ACME Inc</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied geography') }}</h6>
                <h6 class="bold">UK, US, Europe</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Applied time period') }}</h6>
                <h6 class="bold">Dec 2021- Dec 2023</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale('Database name') }}</h6>
                <p class="bold">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt, eius voluptatibus? Deleniti autem
                  animi ipsam explicabo iste similique ipsa, illum voluptates omnis tenetur ut fuga nostrum provident
                  dignissimos dicta eos officiis eius nam saepe corrupti optio? Et consequatur impedit autem iure
                  pariatur esse aspernatur minima quae eos sit fugit fuga at molestias, suscipit voluptatibus. Nulla
                  temporibus magnam voluptates repellendus, porro minus quod quasi tempora a labore assumenda placeat,
                  qui ea quae voluptatem tempore ducimus unde delectus facilis at? Ab corporis deserunt a magni quis
                  sint quasi et, iusto tempore nulla saepe assumenda ipsum consequatur facere, natus, in laboriosam
                  iste? Ipsa?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn tertiary-button" (click)="closeModal()" data-test="request-cancel-button">
        {{ slocale('Apply no factor') }}
      </button>
      <button class="btn primary-button custom-button" data-test="request-create-button">
        {{ slocale('Apply factor') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #emissionsPortalModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale('Emissions portal') }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row mx-auto text-center">
      <h3 class="bold mx-auto">{{ slocale('Choose your action.') }}</h3>
    </div>
    <div class="container-fluid">
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 flex-wrap">
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-search my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Search in emission databases') }}</small>
              <small class="card-title col mx-auto">{{ slocale('provided by SustainLab') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="
                  activeTab = 'emissionFactor';
                  emissionFormSustainLab = true;
                  openModal(selectEmissionsModal, 'modal-xl')
                "
              >
                {{ slocale('Search') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-database my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Select from my company's") }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factors') }}</small>
              <button
                class="btn primary-button mx-auto custom-button"
                (click)="emissionFormSustainLab = false; openModal(selectEmissionsModal, 'modal-xl')"
              >
                {{ slocale('Select') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i _ngcontent-qgx-c224="" class="la la-plus my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale('Add custom') }}</small>
              <small class="card-title col mx-auto">{{ slocale('emission factor') }}</small>
              <button class="btn primary-button mx-auto custom-button">{{ slocale('Add custom') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        class="btn secondary-button custom-button"
        (click)="closeModal()"
        data-test="request-cancel-button"
      >
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<ng-template #saveDraft>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="!saveBeforeLeave">
      {{ slocale('Save as draft') }}
    </h4>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="saveBeforeLeave">
      {{ slocale('Save before leaving') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2" *ngIf="!saveBeforeLeave">
      {{ slocale('Your project will be saved in the Project Overview page.') }}
    </p>
    <p class="ps-2" *ngIf="saveBeforeLeave">
      {{
        slocale(
          "If you leave this page your changes won't be saved. To save changes return to the page and select the save option you want."
        )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal(); closeCreatingProject()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white" (click)="saveProject()">
        {{ this.saveBeforeLeave ? slocale('Return to page') : slocale('Save') }}
      </button>
    </ng-container>
  </div>
</ng-template>
