import { Component, HostListener, Inject, OnInit } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Router } from '@angular/router'
import { trigger, state, style, animate, transition, group } from '@angular/animations'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { KpiDefFe } from 'src/app/model/kpi/KpiDefFe'
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe'
import { LoginUserFe } from 'src/app/model/org/LoginUserFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { RouteServiceFe } from 'src/app/route/RouteServiceFe'
import { RouteFe } from 'src/app/route/RouteFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { UserServiceFe } from 'src/app/services/UserServiceFe'
import { VisitorServiceFe } from 'src/app/services/VisitorServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ScreenHeightSizeFe } from 'src/app/model/screens/ScreenHeightSize'
import { SidebarItemGroup } from 'src/app/model/sidebar/SidebarItemGroup'
import { SidebarItem } from 'src/app/model/sidebar/SidebarItem'
import { Language } from 'src/app/utils/language/Language'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('transitionState', [
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('open => closed', animate('4000ms ease-in')),
      transition('closed => open', animate('4000ms ease-out'))
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          'max-height': '500px',
          opacity: '1',
          visibility: 'visible'
        })
      ),
      state(
        'out',
        style({
          'max-height': '0px',
          opacity: '0',
          visibility: 'hidden'
        })
      ),
      transition('in => out', [
        group([
          animate(
            '400ms ease-in-out',
            style({
              opacity: '0'
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '0px'
            })
          ),
          animate(
            '700ms ease-in-out',
            style({
              visibility: 'hidden'
            })
          )
        ])
      ]),
      transition('out => in', [
        group([
          animate(
            '1ms ease-in-out',
            style({
              visibility: 'visible'
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '500px'
            })
          ),
          animate(
            '800ms ease-in-out',
            style({
              opacity: '1'
            })
          )
        ])
      ])
    ])
  ]
})
export class SidebarComponent extends AbstractLanguageComponent implements OnInit {
  url: string = ''
  public userInfo: LoginUserFe
  public fullScreen: boolean = false
  public submenuAccessOpen: boolean = false
  public accessManagement: string
  public mobileDevice: boolean = false
  public windowDevice: boolean = true
  public newNotificationCounter = 0
  public leftNaveExpand: boolean = false
  public modalRef: BsModalRef
  public dashboards: DashboardFe[]
  public activeRoute: RouteFe | undefined
  public toEditDashboard: DashboardFe
  public kpis: KpiDefFe[]
  public isSelected: string = 'PROFILE'
  initCacheInProgress = true
  isUserProfileHide = true

  itemGroups: SidebarItemGroup[] = []
  profileMenuItem

  selected: any[] = []
  translateX = null
  transition = null
  display = 'flex'
  screenWidthSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  screenHeightSize: ScreenHeightSizeFe = ScreenHeightSizeFe.HEIGHT_LARGE

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public stateService: StateServiceFe,
    private routeService: RouteServiceFe,
    private modalService: BsModalService,
    private loginService: LoginServiceFe,
    private visitorService: VisitorServiceFe,
    private userService: UserServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public displayService: DisplayServiceFe
  ) {
    super(languageService)

    this.responsive.screenWidthSizeSubject.subscribe((screenWidthSize: ScreenWidthSizeFe) => {
      this.screenWidthSize = screenWidthSize
    })

    this.responsive.screenHeightSizeSubject.subscribe((screenHeightSize: ScreenHeightSizeFe) => {
      this.screenHeightSize = screenHeightSize
    })

    this.screenWidthSize = responsive.currentScreenWidthSize
    this.screenHeightSize = responsive.currentScreenHeightSize

    this.setItemGroups()

    this.languageService.languageSubject.subscribe((language: Language) => {
      this.setItemGroups()
    })
  }

  async ngOnInit() {
    this.userInfo = this.loginService.getUserInfo()
    this.loginService.loggedInSubject.subscribe(async (loggedIn: boolean) => {
      this.userInfo = this.loginService.getUserInfo()
    })

    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.stateService.workspaceSubject.subscribe((activeCompany) => {})

    this.routeService.activeRouteSubject.subscribe((activeRoute: RouteFe | undefined) => {
      this.activeRoute = activeRoute
      let groups = this.itemGroups.filter((g) => g.relevantWorkspace())
      groups.forEach((group) => {
        group.items.forEach((parent) => {
          if (parent.route == activeRoute) {
            this.selectMenuItemThroughRoute(parent)
          } else {
            parent.children.forEach((child) => {
              if (child.route == activeRoute) {
                this.selectMenuItemThroughRoute(parent)
              }
            })
          }
        })
      })
    })

    this.activeRoute = this.routeService.activeRoute
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.responsive.resize($event.target.innerWidth, $event.target.innerHeight)
  }

  setItemGroups() {
    this.itemGroups = [
      new SidebarItemGroup(
        [new SidebarItem(this.locale('locale_key.main_navbar.home'), 'la la-home', RoutesFe.HOME, this.routeService)],
        () => {
          return !(this.stateService.activeWorkspace?.companyId == 'ADMIN')
        }
      ),

      new SidebarItemGroup(
        [new SidebarItem(this.slocale('PROJECTS'), 'la la-project-diagram', RoutesFe.PROJECTS, this.routeService)],
        () => {
          return !(
            this.stateService.activeWorkspace?.companyId == 'ADMIN' ||
            this.stateService.activeWorkspace?.affiliationRole == 'DATA_OWNER'
          )
        }
      ),

      new SidebarItemGroup(
        [
          new SidebarItem(
            this.locale('locale_key.sustainability_manager_portal.main_navbar.setup'),
            'las la-cog',
            null,
            this.routeService,
            [
              new SidebarItem(
                this.locale('locale_key.sustainability_manager_portal.main_navbar.setup.organization'),
                'la la-sitemap',
                RoutesFe.ORGANIZATION,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.sustainability_manager_portal.main_navbar.setup.data_categories'),
                'la la-cubes',
                RoutesFe.DATA_CATEGORIES,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.pages.units.unit_systems'),
                'la la-balance-scale',
                RoutesFe.UNIT_SYSTEMS,
                this.routeService
              )
            ]
          ),

          new SidebarItem(
            this.locale('locale_key.sustainability_manager_portal.main_navbar.connect'),
            'la la-share-alt',
            null,
            this.routeService,
            [
              new SidebarItem(
                this.locale('locale_key.sustainability_manager_portal.main_navbar.connect.network'),
                'la la-users',
                RoutesFe.NETWORK,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.data_owner_portal.main_navbar.inbox'),
                'la la-inbox',
                RoutesFe.SM_INBOX,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.sustainability_manager_portal.main_navbar.connect.data_requests'),
                'la la-envelope',
                RoutesFe.REQUESTS,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.pages.data_request.drafts_section.title'),
                'las la-pen-square',
                RoutesFe.DRAFTS,
                this.routeService
              )
            ]
          ),

          new SidebarItem(
            this.locale('locale_key.sustainability_manager_portal.main_navbar.track'),
            'la la-map-marker',
            null,
            this.routeService,
            [
              new SidebarItem(
                this.locale('locale_key.pages.insight_hub.insights_hub'),
                'la la-bolt',
                RoutesFe.INSIGHTS_HUB,
                this.routeService
              ),
              new SidebarItem(
                this.locale('locale_key.sustainability_manager_portal.main_navbar.track.dashboard'),
                'la la-dashboard',
                RoutesFe.DASHBOARD,
                this.routeService
              )
            ]
          )
        ],
        () => {
          return !(
            this.stateService.activeWorkspace?.companyId == 'ADMIN' ||
            this.stateService.activeWorkspace?.affiliationRole == 'DATA_OWNER'
          )
        }
      ),

      new SidebarItemGroup(
        [
          new SidebarItem(
            this.locale('locale_key.pages.data_hub.title'),
            'la la-table',
            RoutesFe.DATA_HUB,
            this.routeService
          )
        ],
        () => {
          return !(
            this.stateService.activeWorkspace?.companyId == 'ADMIN' ||
            this.stateService.activeWorkspace?.affiliationRole == 'DATA_OWNER'
          )
        }
      ),

      new SidebarItemGroup(
        [
          new SidebarItem(
            this.locale('locale_key.admin_portal.main_navbar.admins'),
            'la la-suitcase',
            RoutesFe.ADMINS,
            this.routeService
          ),
          new SidebarItem(
            this.locale('locale_key.admin_portal.main_navbar.clients'),
            'la la-building',
            RoutesFe.CLIENTS,
            this.routeService
          ),
          new SidebarItem(
            this.locale('locale_key.admin_portal.main_navbar.emails'),
            'la la-envelope-o',
            RoutesFe.EMAIL,
            this.routeService
          )
        ],
        () => {
          return this.stateService.activeWorkspace?.companyId == 'ADMIN'
        }
      ),

      new SidebarItemGroup(
        [
          new SidebarItem(
            this.locale('locale_key.data_owner_portal.main_navbar.inbox'),
            'la la-inbox',
            RoutesFe.DO_INBOX,
            this.routeService
          ),
          new SidebarItem(
            this.locale(
              'locale_key.sustainability_manager_portal.main_navbar.connect.data_requests'
            ).toLocaleUpperCase(),
            'la la-envelope',
            RoutesFe.DO_REQUESTS,
            this.routeService
          ),
          new SidebarItem(
            this.locale('locale_key.data_owner_portal.main_navbar.dashboard'),
            'la la-dashboard',
            RoutesFe.DASHBOARD,
            this.routeService
          )
        ],
        () => {
          return this.stateService.activeWorkspace?.affiliationRole == 'DATA_OWNER'
        }
      )
    ]
    this.profileMenuItem = new SidebarItem(
      this.locale('locale_key.main_navbar.profile'),
      'la la-user',
      RoutesFe.PROFILE,
      this.routeService
    )
  }

  public toggleMenu() {
    this.responsive.toggleNav()
  }

  filterdItemGroups(): SidebarItemGroup[] {
    return this.itemGroups.filter((ig) => ig.hasVisibleItem() && ig.relevantWorkspace())
  }

  selectMenuItem(parent: SidebarItem, child: SidebarItem, event?: MouseEvent) {
    // Close side panel
    this.displayService.closeSubject.next(true)
    if (event) {
      event.stopPropagation()
    }
    if (parent && !child && parent.children.length > 0) {
      if (!parent.open) {
        this.itemGroups.forEach((itemGroup) => {
          itemGroup.closeAll()
          itemGroup.setVisibleAll(false)
        })
        parent.setOpen()
        parent.visible = true
      } else {
        parent.setClose()

        this.itemGroups.forEach((itemGroup) => {
          itemGroup.setVisibleAll(true)
        })
      }

      if (parent.route) {
        this.router.navigate([parent.route.fullPath()])
      }
    } else if (parent && !child && parent.children.length == 0) {
      if (!parent.open) {
        this.itemGroups.forEach((itemGroup) => {
          itemGroup.closeAll()
          itemGroup.setVisibleAll(true)
        })
        parent.visible = true
      } else {
        parent.setClose()

        this.itemGroups.forEach((itemGroup) => {
          itemGroup.setVisibleAll(true)
        })
      }
      this.router.navigate([parent.route.fullPath()])
    } else if (parent && child && !child.isActivePage()) {
      this.router.navigate([child.route.fullPath()])
    }
  }

  selectMenuItemThroughRoute(parent: SidebarItem) {
    if (!parent.open) {
      this.itemGroups.forEach((itemGroup) => {
        itemGroup.closeAll()
        itemGroup.setVisibleAll(false)
      })
      parent.setOpen()
      parent.visible = true
    }
  }

  toggleAdmin() {
    this.isUserProfileHide = !this.isUserProfileHide
  }

  public isUserAVisitor() {
    return this.userService.isUserAVisitor()
  }
}
