import moment from 'moment'
import { DateUtil } from 'src/app/utils/DateUtil'
import { StringUtilFe } from 'src/app/utils/StringUtilFe'
import {
  SchedulingOccurenceGeneratorFe,
  SchedulingOccurenceGeneratorFromTypes
} from '../../scheduling/SchedulingOccurenceGeneratorFe'
import { Request_AddFe } from './add/Request_AddFe'
import { QuestionnaireFe } from './questionnaire/QuestionnaireFe'
import { RecurrenceFe } from './recurrence/RecurrenceFe'
import { RequestFe, REQUEST_TASK_STATUS } from './RequestFe'
import { RequestPeriodFe } from './recurrence/RequestPeriodFe'
import { CSRDRequestGroupInfoFe } from './csrd/CSRDRequestGroupInfoFe'

export class RECURRING_PERIOD {
  public static MONTHLY: string = 'MONTHLY'
  public static QUARTERLY: string = 'QUARTERLY'
  public static YEARLY: string = 'YEARLY'
}

export class REQUEST_GROUP__STATUS {
  public static CREATED: string = 'CREATED'
  public static IN_PROGRESS: string = 'IN_PROGRESS'
  public static APPROVED: string = 'APPROVED'
}

export class RequestGroupFe {
  id: string
  title: string
  description: string | null
  requestCount: number
  date: Date
  dueDate: Date | null
  requesterAffId: string
  requesterFirstName: string
  requesterLastName: string
  requesterCompanyId: string
  requesterCompanyName: string
  readStatus: string
  draft: boolean
  draft_json_string: string | null
  recurrence: RecurrenceFe | null
  isChildRecurring: boolean
  recurringParent: string | null

  status: string = 'CREATED'
  requests: RequestFe[]
  noOfCompletedRequests = 0

  //for draft
  questionnaire: QuestionnaireFe
  draftRequests: Request_AddFe[]

  //for requesting period
  selectedRequestPeriod: RequestPeriodFe | null
  previousRequestPeriods: RequestPeriodFe[] = []
  currentRequestPeriods: RequestPeriodFe[] = []
  upcomingRequestPeriods: RequestPeriodFe[] = []
  upcomingRequestPeriodsMsg: string | null

  //for csrd project info
  csrd?: CSRDRequestGroupInfoFe | null

  isSelected = false

  constructor(
    id: string,
    title: string,
    description: string | null,
    requestCount: number,
    date: Date,
    dueDate: Date | null,
    requesterAffId: string,
    requesterFirstName: string,
    requesterLastName: string,
    requesterCompanyId: string,
    requesterCompanyName: string,
    readStatus: string,
    draft: boolean,
    draft_json_string: string | null,
    recurrence: RecurrenceFe | null,
    isChildRecurring: boolean,
    recurringParent: string | null
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.requestCount = requestCount
    this.date = new Date(date)
    this.dueDate = new Date(dueDate)
    this.requesterAffId = requesterAffId
    this.requesterFirstName = requesterFirstName
    this.requesterLastName = requesterLastName
    this.requesterCompanyId = requesterCompanyId
    this.requesterCompanyName = requesterCompanyName
    this.readStatus = readStatus
    this.draft = draft
    this.draft_json_string = draft_json_string
    this.requests = null
    this.recurrence = recurrence
    this.isChildRecurring = isChildRecurring
    this.recurringParent = recurringParent

    if (draft && draft_json_string) {
      this.setDraftData()
    }
  }

  public static formTransfer(transfer: any): RequestGroupFe {
    let requestGroup = new RequestGroupFe(
      transfer.id,
      transfer.title,
      transfer.description,
      transfer.requestCount,
      transfer.date,
      transfer.dueDate,
      transfer.requesterAffId,
      transfer.requesterFirstName,
      transfer.requesterLastName,
      transfer.requesterCompanyId,
      transfer.requesterCompanyName,
      transfer.readStatus,
      transfer.draft,
      transfer.draft_json_string,
      transfer.recurrence ? RecurrenceFe.fromTransfer(transfer.recurrence) : null,
      transfer.isChildRecurring,
      transfer.recurringParent
    )
    return requestGroup
  }

  setDraftData() {
    let obj = StringUtilFe.convertStringToObj(this.draft_json_string)
    this.questionnaire = QuestionnaireFe.fromTransfer(obj['questionnaire'])
    this.draftRequests = obj['requests']?.map((r) => Request_AddFe.fromJsomObj(r))
  }

  setStatus() {
    let isAllNew = this.requests.every((r) => r.status == REQUEST_TASK_STATUS.CREATED)
    if (isAllNew) {
      this.status = REQUEST_GROUP__STATUS.CREATED
    } else {
      let isAllApproved = this.requests.every(
        (r) => r.status == REQUEST_TASK_STATUS.APPROVED || r.status == REQUEST_TASK_STATUS.CLOSED
      )
      if (isAllApproved) {
        this.status = REQUEST_GROUP__STATUS.APPROVED
      } else {
        this.status = REQUEST_GROUP__STATUS.IN_PROGRESS
      }
    }
    this.noOfCompletedRequests = this.requests.filter((r) => r.status == REQUEST_TASK_STATUS.APPROVED).length
  }

  getDate() {
    if (this.date) {
      return DateUtil.toString3(this.date)
    }
  }

  getStatusTextKeyword() {
    switch (this.status) {
      case REQUEST_GROUP__STATUS.CREATED:
        return 'locale_key.general.state.sent'
      case REQUEST_GROUP__STATUS.APPROVED:
        return 'locale_key.general.state.completed'
      case REQUEST_GROUP__STATUS.IN_PROGRESS:
        return 'locale_key.general.state.in_progress'
    }
  }

  getStatusClass() {
    switch (this.status) {
      case REQUEST_GROUP__STATUS.CREATED:
        return 'badge-turquoise'
      case REQUEST_GROUP__STATUS.APPROVED:
        return 'badge-green'
      case REQUEST_GROUP__STATUS.IN_PROGRESS:
        return 'badge-orange'
    }
  }

  getCreated() {
    if (this.recurrence) {
      return this.getStart()
    }
    const created_raw = this.date
    const date_format = 'DD MMM, yyyy'
    const created = moment(created_raw).format(date_format)
    return created
  }

  getDeadline() {
    if (this.recurrence) {
      return this.getEnd()
    }
    const deadline_raw = this.dueDate
    const date_format = 'DD MMM, yyyy'
    const deadline = moment(deadline_raw).format(date_format)
    return deadline
  }

  getDeadlineClass() {
    let date = this.getDeadline()
    return DateUtil.getDeadlineClass(new Date(date))
  }

  private getStart() {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe()
    const occurences = occurenceGenrator.generateOccurences({
      fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP,
      requestGroup: this,
      numOccurrences: 2
    })
    const date_format = 'DD MMM, yyyy'
    const start_raw = occurences[0].sendOutDay
    const start = moment(start_raw).format(date_format)
    return start
  }

  private getEnd() {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe()
    const occurences = occurenceGenrator.generateOccurences({
      fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP,
      requestGroup: this,
      numOccurrences: 2
    })
    const date_format = 'DD MMM, yyyy'
    const end_raw = occurences[0].nextDeadline
    const end = moment(end_raw).format(date_format)
    return end
  }

  getTitle(): string {
    if (this.recurrence) {
      let namedOccurence = this.recurrence.namedOccurences.find((o) => {
        const sendOutDate = moment(this.recurrence.sendOutDate).utc(true)
        const nextDeadline = moment(this.recurrence.nextDeadline).utc(true)
        const osendOutDate = moment(o.sendOutDate).utc(true)
        const onextDeadline = moment(o.nextDeadline).utc(true)
        return sendOutDate.isSame(osendOutDate, 'd') && nextDeadline.isSame(onextDeadline, 'd')
      })
      if (namedOccurence && namedOccurence.name?.length > 0) {
        return `${this.title} : ${namedOccurence.name}`
      }
    }
    return this.title
  }
}
