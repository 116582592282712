import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import { InputColumnType } from '../model/InputColumnType'

@Directive({
  selector: '[validateData]',
  providers: [{ provide: NG_VALIDATORS, useExisting: BooleanValidator, multi: true }]
})
export class BooleanValidator implements Validator {
  @Input() colType = ''

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      if (this.colType === 'number') {
        let val = control.value
        if (typeof val == 'string') {
          val = val.trim()
        }
        if (val != '') {
          if (/^\s*-?\d+(\.\d+)?\s*$/.test(val)) {
            return null
          } else {
            return { notValidNumber: true }
          }
        }
      } else if (this.colType == InputColumnType.date && typeof control.value == 'string') {
        let index = control.value.indexOf('-')
        let year = control.value.substring(0, index)
        if (year.length > 4 || year < '1900' || year > '2200') {
          return { notValidDate: true }
        }
      }
    }
    // else if (this.colType === 'checkbox'){
    //   if(control.value == true || control.value == false ){
    //     return null;
    //   }
    //   else{
    //     return {notValidBoolean: true};
    //   }
    // }
    // else if (this.colType === 'date'){
    //   var regEx = /^\d{4}-\d{2}-\d{2}$/;
    //   console.log(control.value.match(regEx) != null , control.value instanceof Date
    //   if( control.value.match(regEx) != null || control.value instanceof Date || control.value==''){
    //     return null;
    //   }
    //   else{
    //     return {notValidDate: true};
    //   }
    // }

    return null
  }
}
