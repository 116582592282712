<div class="modal-header" style="border-radius: 0px; border: none">
  <h4 class="modal-title mt-2 text-uppercase ms-4" id="myModalLabel99" *ngIf="requestGroup">
    {{ locale('locale_key.pages.data_request.details.title') }}
  </h4>
</div>
<div class="m-0 p-0">
  <div class="details">
    <div class="modal-body p-2 mb-2" style="height: 80vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="content clearfix">
        <div style="overflow-x: hidden">
          <ng-container
            *ngIf="(datahubService.isDOSubmissionsView() || datahubService.isRgResultView()) && requestGroup"
          >
            <div class="row">
              <div class="col-3 text-center">
                <i class="la la-envelope-o m-auto ms-2 dark-text" style="font-size: 4rem"></i>
              </div>
              <div class="col-9 text-start my-auto">
                <h5 class="text-start my-auto bold" style="font-weight: 700 !important">{{ requestGroup.title }}</h5>
              </div>
            </div>
            <div class="ms-1 row">
              <h5>{{ locale('locale_key.master_table.data_point.desc') }}</h5>
              <p>{{ requestGroup.description }}</p>
              <hr />
            </div>
            <div class="mt-2">
              <h5 class="ms-3 mb-2 mb-0">{{ locale('locale_key.heappening.timing') }}</h5>
              <div class="row">
                <div class="col-6">
                  <span class="ms-3 mt-4 mb-0 text-muted">
                    {{ locale('locale_key.pages.data_request.title.created') }}
                  </span>
                  <p class="ms-3 multiline" style="font-weight: 500 !important">{{ requestGroup.getCreated() }}</p>
                </div>
                <div class="col-6">
                  <span class="ms-3 mt-4 mb-0 text-muted">
                    {{ locale('locale_key.pages.data_request.title.deadline') }}
                  </span>
                  <p class="ms-3 multiline bold">{{ requestGroup.getDeadline() }}</p>
                </div>
              </div>
              <hr />
            </div>
            <div class="mt-2 ms-1">
              <div class="row ms-1">
                <div class="col-6 ps-1">
                  <h6 class="mb-3 bold" style="font-weight: 700 !important">
                    {{ locale('locale_key.pages.data_reporters.toolbar.button.add_contact') }}
                  </h6>
                  <span *ngFor="let req of requestGroup.requests">
                    <div class="d-flex flex-row justify-content-start my-auto mb-3">
                      <div class="icon-circle-dark me-2 my-auto">
                        <span class="text-white"><i class="la la-user fs-5 bold"></i></span>
                      </div>
                      <span class="my-auto text-truncate text-nowrap">{{ req.getAssigneeName() }}</span>
                    </div>
                  </span>
                </div>
                <div class="col-6">
                  <h6 class="ms-3 mb-3 bold" style="font-weight: 700 !important">
                    {{ locale('locale_key.general.user_information.user_status') }}
                  </h6>
                  <span
                    class="ms-3 text-muted badge mb-3"
                    [ngClass]="req.getStatusClass()"
                    *ngFor="let req of requestGroup.requests"
                  >
                    {{ locale(req.getStatusTextKeyword()) }}
                  </span>
                </div>
              </div>
              <hr />
            </div>
          </ng-container>
          <ng-container
            *ngIf="(datahubService.isMasterTablePortalView() || datahubService.isMasterTableView()) && entity"
          >
            <div class="row">
              <div class="col-3 text-center">
                <i class="la la-table m-auto" style="font-size: 5rem"></i>
              </div>
              <div class="col-9 text-start my-auto">
                <h6 class="text-start my-auto">{{ entity.getTaxonomyTitle(taxonomy) }}</h6>
              </div>
            </div>
          </ng-container>
          <div class="ms-3">
            <div class="mt-4 row">
              <h6 class="mb-3">
                {{
                  datahubService.isDOSubmissionsView() || datahubService.isRgResultView()
                    ? locale('locale_key.pages.datahub.data_hub_home.related_data_in')
                    : locale('locale_key.pages.datahub.data_hub_home.data_used_in')
                }}
              </h6>
            </div>
            <div class="mt-0 row">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div class="d-flex flex-row justify-content-start my-auto">
                        <div class="icon-circle-dark me-2 my-auto">
                          <span class="text-white">{{ filteredInsights.length }}</span>
                        </div>
                        <span class="my-auto text-truncate text-nowrap" style="font-weight: 700 !important">
                          {{ locale('locale_key.pages.landing.sm.kpis.title') }}
                        </span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body mx-0 px-0">
                      <ng-container *ngFor="let kpi of filteredInsights; let last = last">
                        <div class="d-flex flex-row justify-content-between my-auto px-4">
                          <a class="my-auto cursor">{{ kpi.title }}</a>
                          <span>
                            <i
                              class="la la-arrow-right fs-5 my-auto cursor"
                              routerLink="/ws/kpi/user"
                              (click)="selectKpi(kpi)"
                            ></i>
                          </span>
                        </div>
                        <hr *ngIf="!last" />
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-3" />
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div class="d-flex flex-row justify-content-start my-auto">
                        <div class="icon-circle-dark me-2 my-auto">
                          <span class="text-white">{{ filteredDashboards.length }}</span>
                        </div>
                        <span class="my-auto text-truncate text-nowrap" style="font-weight: 700 !important">
                          {{ locale('locale_key.pages.datahub.data_hub_home.button.dashboards') }}
                        </span>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body mx-0 px-0">
                      <ng-container *ngFor="let dashboard of filteredDashboards; let last = last">
                        <div class="d-flex flex-row justify-content-between my-auto px-4">
                          <a class="my-auto cursor">{{ dashboard.name }}</a>
                          <span>
                            <i
                              class="la la-arrow-right fs-4 my-auto cursor"
                              routerLink="/ws/dashboard"
                              (click)="selectDashboard(dashboard)"
                            ></i>
                          </span>
                        </div>
                        <hr *ngIf="!last" />
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
