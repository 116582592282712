import { IntervalFe } from './IntervalFe'
import { NamedOccurenceFe } from './NamedOccurenceFe'
import { ReminderScheduleFe } from './ReminderScheduleFe'
import { RepeatScheduleFe } from './RepeatScheduleFe'

export class RecurrenceFe {
  type: string
  sendOutType: string
  sendOutDate: string
  sendOutInterval: IntervalFe
  nextDeadline: string
  namedOccurences: NamedOccurenceFe[]
  repeatSchedule: RepeatScheduleFe
  ends: string
  maxDate: string
  maxOccurences: number
  reminders: ReminderScheduleFe[]
  sendReminderToCreator: boolean
  sendScheduledReminders: boolean
  stopped: boolean

  constructor(
    type: string,
    sendOutType: string,
    sendOutDate: string,
    sendOutInterval: IntervalFe,
    nextDeadline: string,
    namedOccurences: NamedOccurenceFe[],
    repeatSchedule: RepeatScheduleFe,
    ends: string,
    maxDate: string,
    maxOccurences: number,
    reminders: ReminderScheduleFe[],
    sendReminderToCreator: boolean,
    sendScheduledReminders: boolean,
    stopped: boolean
  ) {
    this.type = type
    this.sendOutType = sendOutType
    this.sendOutDate = sendOutDate
    this.sendOutInterval = sendOutInterval
    this.nextDeadline = nextDeadline
    this.namedOccurences = namedOccurences
    this.repeatSchedule = repeatSchedule
    this.ends = ends
    this.maxDate = maxDate
    this.maxOccurences = maxOccurences
    this.reminders = reminders
    this.sendReminderToCreator = sendReminderToCreator
    this.sendScheduledReminders = sendScheduledReminders
    this.stopped = stopped
  }

  public static fromTransfer(transfer: any): RecurrenceFe {
    let recurrence = new RecurrenceFe(
      transfer.type,
      transfer.sendOutType,
      transfer.sendOutDate,
      IntervalFe.fromTransfer(transfer.sendOutInterval),
      transfer.nextDeadline,
      transfer.namedOccurences.map((o: any) => NamedOccurenceFe.fromTransfer(o)),
      RepeatScheduleFe.fromTransfer(transfer.repeatSchedule),
      transfer.ends,
      transfer.maxDate,
      transfer.maxOccurences,
      transfer.reminders.map((r: any) => ReminderScheduleFe.fromTransfer(r)),
      transfer.sendReminderToCreator,
      transfer.sendScheduledReminders,
      transfer.stopped
    )
    return recurrence
  }
}
