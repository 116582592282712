import moment from 'moment'
import { RecurrenceFe } from '../data-suppliers/request/recurrence/RecurrenceFe'
import { ReminderScheduleFe } from '../data-suppliers/request/recurrence/ReminderScheduleFe'
import { RequestGroupFe } from '../data-suppliers/request/RequestGroupFe'
import { NamedOccurenceFe } from '../data-suppliers/request/recurrence/NamedOccurenceFe'

export enum SchedulingOccurenceGeneratorFromTypes {
  REQUEST_GROUP = 'REQUEST_GROUP',
  RECURRENCE = 'RECURRENCE'
}

interface GenerateOccurencesOptions {
  fromType: SchedulingOccurenceGeneratorFromTypes
  numOccurrences: number
  requestGroup?: RequestGroupFe
  recurrence?: RecurrenceFe
}

interface GetUpcomingOccurrencesOptions {
  fromType: SchedulingOccurenceGeneratorFromTypes
  requestGroup?: RequestGroupFe
  recurrence?: RecurrenceFe
  isoDate?: string
  print?: boolean
}

interface GenerateReminderOccurencesOptions {
  deadline: string
  reminders: ReminderScheduleFe[]
}

export class SchedulingOccurenceGeneratorFe {
  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]
  unitToMomentMap = {
    DAY: 'd',
    WEEK: 'w',
    MONTH: 'M',
    YEAR: 'y',
    weekly: 'w',
    monthly: 'M',
    quaterly: 'Q',
    yearly: 'y',
    daily: 'd'
  }

  private getDayOccurencesInMonth = ({ day, month, year }: { day: string; month: string; year: string }) => {
    const dayIdx = this.weekDays.indexOf(day)
    const monthIdx = this.months.indexOf(month)

    const momentObj = moment()
    momentObj.year(parseInt(year))
    momentObj.month(monthIdx)
    momentObj.startOf('month')
    momentObj.startOf('day')

    while (momentObj.day() != dayIdx) {
      momentObj.add(1, 'day')
    }

    const occurrences = []

    while (momentObj.month() == monthIdx) {
      occurrences.push(momentObj.toISOString())
      momentObj.add(7, 'days')
    }

    return occurrences
  }

  private getDayOccurencesInMonthByIsoDate = (isoDate: string) => {
    const momentObj = moment(isoDate)
    const day = this.weekDays[momentObj.day()]
    const month = this.months[momentObj.month()]
    const year = momentObj.year() + ''
    const occurrences = this.getDayOccurencesInMonth({ day, month, year })
    return occurrences
  }

  private getDayOccurenceInMonth = (isoDate: string) => {
    const occurrences = this.getDayOccurencesInMonthByIsoDate(isoDate)
    const occurrencesv2 = occurrences.map((occurrence) => moment(occurrence).format('YYYY-MM-DD'))
    const dayOccurence = occurrencesv2.indexOf(moment(isoDate).format('YYYY-MM-DD'))
    return dayOccurence + 1
  }

  private genFromRecurrence(options: GenerateOccurencesOptions) {
    const recurrence = options.recurrence
    if (!recurrence) return []

    const unitToMomentMap: any = {
      DAY: 'd',
      WEEK: 'w',
      MONTH: 'M',
      YEAR: 'y',
      weekly: 'w',
      monthly: 'M',
      quaterly: 'Q',
      yearly: 'y',
      daily: 'd'
    }

    const firstNextDeadline = recurrence.nextDeadline
    let firstSendOutDay = moment(recurrence.sendOutDate)
    let genOccurences = [
      {
        sendOutDay: firstSendOutDay.toISOString(),
        nextDeadline: firstNextDeadline
      }
    ]

    if (!recurrence.sendOutInterval) {
      return genOccurences
    }

    const sendOutIntervalUnit = recurrence.sendOutInterval.unit
    const sendOutIntervalValue = recurrence.sendOutInterval.value

    const sendOutMomentDuration = unitToMomentMap[sendOutIntervalUnit]
    firstSendOutDay = moment(firstNextDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)

    genOccurences = [
      {
        sendOutDay: firstSendOutDay.toISOString(),
        nextDeadline: firstNextDeadline
      }
    ]

    let repeatMomentDuration
    let repeatMomentValue = 1
    let newDeadline: any = firstNextDeadline
    let newSendOutDay

    switch (recurrence.repeatSchedule.type) {
      case 'PRESET':
        const repeatPreset = (recurrence.repeatSchedule.preset + '').toLowerCase()
        repeatMomentDuration = unitToMomentMap[repeatPreset]

        for (let i = 1; i < options.numOccurrences; i++) {
          newDeadline = moment(newDeadline).add(repeatMomentValue, repeatMomentDuration)
          newSendOutDay = moment(newDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)
          genOccurences.push({
            sendOutDay: newSendOutDay.toISOString(),
            nextDeadline: newDeadline.toISOString()
          })
        }
        break
      case 'CUSTOM':
        const repeatCustomUnit = (recurrence.repeatSchedule.custom.unit + '').toLowerCase()
        repeatMomentDuration = unitToMomentMap[repeatCustomUnit]
        repeatMomentValue = recurrence.repeatSchedule.custom.value

        switch (repeatCustomUnit) {
          case 'daily':
          case 'yearly':
            for (let i = 1; i < options.numOccurrences; i++) {
              newDeadline = moment(newDeadline).add(repeatMomentValue, repeatMomentDuration)
              newSendOutDay = moment(newDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)
              genOccurences.push({
                sendOutDay: newSendOutDay.utc().startOf('day').toISOString(),
                nextDeadline: newDeadline.utc().startOf('day').toISOString()
              })
            }
            break
          case 'weekly':
            const isoWeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            const expDayOfWeek = (recurrence.repeatSchedule.custom.dayOfWeek + '').toLowerCase()
            const expDayOfWeekIdx = isoWeekDays.indexOf(expDayOfWeek) + 1

            for (let i = 1; i < options.numOccurrences; i++) {
              newDeadline = moment(newDeadline).add(repeatMomentValue, repeatMomentDuration)
              const newDeadlineDayOfWeekIdx = newDeadline.isoWeekday()

              if (newDeadlineDayOfWeekIdx > expDayOfWeekIdx) {
                const diffDays = newDeadlineDayOfWeekIdx - expDayOfWeekIdx
                newDeadline = newDeadline.subtract(diffDays, 'days')
              } else {
                const diffDays = expDayOfWeekIdx - newDeadlineDayOfWeekIdx
                newDeadline = newDeadline.add(diffDays, 'days')
              }

              newSendOutDay = moment(newDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)

              genOccurences.push({
                sendOutDay: newSendOutDay.utc().startOf('day').toISOString(),
                nextDeadline: newDeadline.utc().startOf('day').toISOString()
              })
            }
            break
          case 'monthly':
            const monthType = (recurrence.repeatSchedule.custom.monthType + '').toLowerCase()
            switch (monthType) {
              case 'date_of_month':
                for (let i = 1; i < options.numOccurrences; i++) {
                  newDeadline = moment(newDeadline).add(repeatMomentValue, repeatMomentDuration)
                  newSendOutDay = moment(newDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)
                  genOccurences.push({
                    sendOutDay: newSendOutDay.utc().startOf('day').toISOString(),
                    nextDeadline: newDeadline.utc().startOf('day').toISOString()
                  })
                }
                break

              case 'day_occurence_in_month':
              case 'last_day_occurence_in_month':
                const dom = moment(newDeadline)
                const domIdx = this.getDayOccurenceInMonth(dom.toISOString()) - 1
                const domDay = this.weekDays[dom.day()]
                let domMonth = this.months[dom.month()]
                let domYear = dom.year() + ''

                for (let i = 1; i < options.numOccurrences; i++) {
                  newDeadline = moment(newDeadline).add(repeatMomentValue, repeatMomentDuration)
                  domMonth = this.months[newDeadline.month()]
                  domYear = newDeadline.year() + ''

                  const dayOccurrences = this.getDayOccurencesInMonth({ day: domDay, month: domMonth, year: domYear })

                  let occurence
                  if (monthType == 'day_occurence_in_month' && domIdx < dayOccurrences.length) {
                    occurence = dayOccurrences[domIdx]
                  } else {
                    occurence = dayOccurrences[dayOccurrences.length - 1]
                  }
                  newDeadline = moment(occurence)

                  newSendOutDay = moment(newDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration)
                  genOccurences.push({
                    sendOutDay: newSendOutDay.utc().startOf('day').toISOString(),
                    nextDeadline: newDeadline.utc().startOf('day').toISOString()
                  })
                }
                break
            }
            break
        }

        break
    }

    return genOccurences
  }

  private genFromRequestGroup(options: GenerateOccurencesOptions) {
    const requestGroup = options.requestGroup
    if (!requestGroup) return []

    const recurrence = requestGroup.recurrence
    options.recurrence = recurrence

    return this.genFromRecurrence(options)
  }

  generateOccurences(options: GenerateOccurencesOptions) {
    switch (options.fromType) {
      case SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP:
        return this.genFromRequestGroup(options)

      case SchedulingOccurenceGeneratorFromTypes.RECURRENCE:
        return this.genFromRecurrence(options)
    }
  }

  private getUpcomingOccurrencesFromRecurrence(options: GetUpcomingOccurrencesOptions) {
    const recurrence = options.recurrence
    if (!recurrence) return []

    let occurences: { nextDeadline: string; sendOutDay: string }[] = []
    const recurrenceBuffer = { ...recurrence }
    switch (recurrence.ends) {
      case 'AFTER_MAX_OCCURENCES':
        occurences = this.genFromRecurrence({
          recurrence,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
          numOccurrences: recurrence.maxOccurences
        })
        break

      case 'ON_DATE':
        let occurenceBuffer = this.genFromRecurrence({
          recurrence: recurrenceBuffer,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
          numOccurrences: 2
        })
        let shouldContinue = true
        do {
          occurences.push(occurenceBuffer[0])
          if (
            !recurrence.maxDate ||
            !occurenceBuffer[1].nextDeadline ||
            moment(occurenceBuffer[1].nextDeadline).isAfter(recurrence.maxDate)
          ) {
            shouldContinue = false
          } else {
            recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline
            occurenceBuffer = this.genFromRecurrence({
              recurrence: recurrenceBuffer,
              fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
              numOccurrences: 2
            })
          }
        } while (shouldContinue)
        break
      case 'NEVER':
        const current = this.getCurrentOccurrenceFromRecurrence({
          recurrence: recurrenceBuffer,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE
        })
        if (!current) {
          occurences = this.genFromRecurrence({
            recurrence: recurrenceBuffer,
            fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
            numOccurrences: 2
          })
        } else {
          recurrenceBuffer.nextDeadline = current.nextDeadline
          occurences = this.genFromRecurrence({
            recurrence: recurrenceBuffer,
            fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
            numOccurrences: 3
          })
        }
        break
    }

    const filterIsoDate = options.isoDate ?? moment().utc().startOf('day').toISOString()
    const upcoming = occurences.filter((occurence) => moment(occurence.sendOutDay).isAfter(filterIsoDate))
    return upcoming
  }

  private getUpcomingOccurrencesFromRequestGroup(options: GetUpcomingOccurrencesOptions) {
    const requestGroup = options.requestGroup
    if (!requestGroup) return []

    const recurrence = requestGroup.recurrence
    options.recurrence = recurrence

    return this.getUpcomingOccurrencesFromRecurrence(options)
  }

  getUpcomingOccurrences(options: GetUpcomingOccurrencesOptions) {
    switch (options.fromType) {
      case SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP:
        return this.getUpcomingOccurrencesFromRequestGroup(options)

      case SchedulingOccurenceGeneratorFromTypes.RECURRENCE:
        return this.getUpcomingOccurrencesFromRecurrence(options)
    }
  }

  public getPreviousOccurrencesFromRecurrence(options: GetUpcomingOccurrencesOptions) {
    const recurrence = options.recurrence
    if (!recurrence) return []

    let occurences: { nextDeadline: string; sendOutDay: string }[] = []
    const recurrenceBuffer = { ...recurrence }
    let occurenceBuffer = this.genFromRecurrence({
      recurrence: recurrenceBuffer,
      fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
      numOccurrences: 2
    })
    let shouldContinue = true
    switch (recurrence.ends) {
      case 'AFTER_MAX_OCCURENCES':
        occurences = this.genFromRecurrence({
          recurrence,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
          numOccurrences: recurrence.maxOccurences
        })
        break

      case 'ON_DATE':
        do {
          occurences.push(occurenceBuffer[0])
          if (
            !recurrence.maxDate ||
            !occurenceBuffer[1].nextDeadline ||
            moment(occurenceBuffer[1].nextDeadline).isAfter(recurrence.maxDate)
          ) {
            shouldContinue = false
          } else {
            recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline
            occurenceBuffer = this.genFromRecurrence({
              recurrence: recurrenceBuffer,
              fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
              numOccurrences: 2
            })
          }
        } while (shouldContinue)
        break
      case 'NEVER':
        do {
          occurences.push(occurenceBuffer[0])
          if (moment(occurenceBuffer[1].nextDeadline).isAfter(moment().utc().startOf('day').toISOString())) {
            shouldContinue = false
          } else {
            recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline
            occurenceBuffer = this.genFromRecurrence({
              recurrence: recurrenceBuffer,
              fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
              numOccurrences: 2
            })
          }
        } while (shouldContinue)
        break
    }

    const filterIsoDate = options.isoDate ?? moment().utc().startOf('day').toISOString()
    const previous = occurences.filter((occurence) => moment(occurence.sendOutDay).isSameOrBefore(filterIsoDate))

    return previous
  }

  private getPreviousOccurrencesFromRequestGroup(options: GetUpcomingOccurrencesOptions) {
    const requestGroup = options.requestGroup
    if (!requestGroup) return []

    const recurrence = requestGroup.recurrence
    options.recurrence = recurrence

    return this.getPreviousOccurrencesFromRecurrence(options)
  }

  getPreviousOccurrences(options: GetUpcomingOccurrencesOptions) {
    switch (options.fromType) {
      case SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP:
        return this.getPreviousOccurrencesFromRequestGroup(options)

      case SchedulingOccurenceGeneratorFromTypes.RECURRENCE:
        return this.getPreviousOccurrencesFromRecurrence(options)
    }
  }

  private getCurrentOccurrenceFromRecurrence(options: GetUpcomingOccurrencesOptions) {
    const recurrence = options.recurrence
    if (!recurrence) {
    }

    const previous = this.getPreviousOccurrencesFromRecurrence(options)
    const current = previous?.pop()
    return current
  }

  private getCurrentOccurrenceFromRequestGroup(options: GetUpcomingOccurrencesOptions) {
    const requestGroup = options.requestGroup
    if (!requestGroup) return []

    const recurrence = requestGroup.recurrence
    options.recurrence = recurrence

    return this.getCurrentOccurrenceFromRecurrence(options)
  }

  getCurrentOccurrence(options: GetUpcomingOccurrencesOptions) {
    switch (options.fromType) {
      case SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP:
        return this.getCurrentOccurrenceFromRequestGroup(options)

      case SchedulingOccurenceGeneratorFromTypes.RECURRENCE:
        return this.getCurrentOccurrenceFromRecurrence(options)
    }
  }

  getPreviousRequestPeriods(requestGroup: RequestGroupFe) {
    const date_format = 'MMM D, YYYY'
    const prevPeriods = this.getPreviousOccurrences({
      fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP,
      requestGroup
    }).map((_period) => {
      const period: any = _period
      let namedOccurence = this.getOccurenceName(requestGroup, _period)
      if (namedOccurence) {
        period.title = namedOccurence.name
      } else {
        period.title = requestGroup.title
      }
      period.start = moment(_period.sendOutDay).format(date_format)
      period.end = moment(_period.nextDeadline).format(date_format)
      return period
    })
    prevPeriods.pop()
    return prevPeriods
  }

  getCurrentRequestPeriod(requestGroup: RequestGroupFe) {
    const date_format = 'MMM D, YYYY'
    const currentPeriod: any = this.getCurrentOccurrence({
      fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP,
      requestGroup
    })

    if (!currentPeriod) return []
    let namedOccurence = this.getOccurenceName(requestGroup, currentPeriod)

    if (namedOccurence) {
      currentPeriod.title = namedOccurence.name
    } else {
      currentPeriod.title = requestGroup.title
    }

    currentPeriod.start = moment(currentPeriod.sendOutDay).format(date_format)
    currentPeriod.end = moment(currentPeriod.nextDeadline).format(date_format)
    return [currentPeriod]
  }

  getOccurenceName(
    requestGroup: RequestGroupFe,
    period: { nextDeadline: string; sendOutDay: string }
  ): NamedOccurenceFe | null {
    let namedOccurence = requestGroup.recurrence.namedOccurences.find((o) => {
      const sendOutDate = moment(period.sendOutDay).utc(true)
      const nextDeadline = moment(period.nextDeadline).utc(true)
      const osendOutDate = moment(o.sendOutDate).utc(true)
      const onextDeadline = moment(o.nextDeadline).utc(true)
      return sendOutDate.isSame(osendOutDate, 'd') && nextDeadline.isSame(onextDeadline, 'd')
    })
    return namedOccurence
  }

  getUpcomingRequestPeriods(requestGroup: RequestGroupFe) {
    const date_format = 'MMM D, YYYY'
    const periods = this.getUpcomingOccurrences({
      fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP,
      requestGroup
    }).map((_period) => {
      const period: any = _period
      let namedOccurence = this.getOccurenceName(requestGroup, _period)
      if (namedOccurence) {
        period.title = namedOccurence.name
      } else {
        period.title = requestGroup.title
      }
      period.start = moment(_period.sendOutDay).format(date_format)
      period.end = moment(_period.nextDeadline).format(date_format)
      return period
    })
    return periods
  }

  getLastRequestPeriod(requestGroup: RequestGroupFe) {
    const upcomingPeriods = this.getUpcomingRequestPeriods(requestGroup)
    const lastPeriod = upcomingPeriods.pop()
    if (!lastPeriod) return []
    return [lastPeriod]
  }

  getScheduledReminders(requestGroup: RequestGroupFe) {
    const reminders = requestGroup.recurrence.reminders.map((_reminder) => {
      let value: any = _reminder.value
      let unit = `${_reminder.unit.toLowerCase()}${value > 1 ? 's' : ''}`

      if (_reminder.preset != 'CUSTOM') {
        const presetTokens = _reminder.preset.split('_')
        value = presetTokens[1]
        unit = `${presetTokens[0].toLowerCase()}${value > 1 ? 's' : ''}`
      }

      const direction = `${_reminder.type == 'BEFORE_DEADLINE' ? 'before deadline' : 'after deadline'}`
      const reminder = `${value} ${unit} ${direction}`
      return reminder
    })
    return reminders
  }

  genReminderOccurrences(options: GenerateReminderOccurencesOptions) {
    const occurences = options.reminders.map((reminder) => {
      let value: any = reminder.value
      let unit = reminder.unit
      if (reminder.preset != 'CUSTOM') {
        const presetTokens = reminder.preset.split('_')
        value = presetTokens[1]
        unit = presetTokens[0]
      }
      unit = this.unitToMomentMap[unit]

      let _occurence = moment(options.deadline).subtract(value, unit)
      if (reminder.type == 'AFTER_DEADLINE') {
        _occurence = moment(options.deadline).add(value, unit)
      }

      const occurence = _occurence.utc().startOf('day').toISOString()
      return occurence
    })

    return occurences.sort()
  }

  getLastSentReminder(requestGroup: RequestGroupFe) {
    const currentPeriod = this.getCurrentRequestPeriod(requestGroup)[0]
    if (!currentPeriod) return []
    const reminderOccurrences = this.genReminderOccurrences({
      reminders: requestGroup.recurrence.reminders,
      deadline: currentPeriod.nextDeadline
    })
    const prevReminderOccurrences = reminderOccurrences.filter((occurence) =>
      moment(occurence).isSameOrBefore(moment())
    )
    let lastReminderSent = prevReminderOccurrences.pop()
    const date_format = 'MMM D, YYYY'
    lastReminderSent = moment(lastReminderSent).format(date_format)
    return [lastReminderSent]
  }
}
