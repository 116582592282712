import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import _ from 'lodash'
import { UnitSystems, getUnitSystemName } from '../model/UnitSystems'
import {
  UnitMeasurementTypes,
  getUnitMeasurementTypeDefaultUnitSymbol,
  getUnitMeasurementTypeName
} from '../model/UnitMeasurementType'
import { UnitFe } from '../model/UnitFe'
import { UnitEditorComponent } from '../unit-editor/unit-editor.component'
import { UNIT_EDITOR_STATE } from '../model/UNIT_EDITOR_STATE'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { Subject } from 'rxjs'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { groupUnitsByMeasurementTypeAndSystem } from '../model/utils'
import { UnitServiceFe } from 'src/app/services/UnitServiceFe'
import { ConditionsBuilderComponent } from '../../data-categories/calculation-builder/condition-builder/conditions-builder.component'

export enum NoUnit {
  UNIT = 'No unit',
  SYMBOL = 'No unit ()',
  MEASUREMENT_KEY = 'NO_UNIT'
}

enum UnitSelectorModes {
  search = 'search',
  filter = 'filter',
  select = 'select'
}

@Component({
  selector: 'unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: ['./unit-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitSelectorComponent extends AbstractLanguageComponent implements OnInit, OnChanges {
  @Input() isHeader: boolean = false
  @Output() createCustomUnitModalOpened: Subject<boolean> = new Subject()
  @Input() mode: UnitSelectorModes
  @Input() filterBy
  @Input() filter: Set<any> = new Set()
  @Input() includeMeasurementTypes: Set<any> = new Set()

  @Input() selectBy
  @Input() selectedUnitSymbol = null
  @Input() selectedUnitContainer = { selectedUnit: null }
  @Input() selectedMeasurementTypeContainer
  @Input() source

  @Input() unitsByMeasurementType
  @Input() customUnits = []
  @Input() shouldBeConvertible = false
  @Input() isDropdownHighlighted: boolean = false
  @Input() skipInitNoUnit: boolean = false
  @ViewChild(`unitEdtiorModalForMeasurementTypeFilter`, { static: false }) unitEdtiorModal: UnitEditorComponent

  @Output() selectedUnitChanged = new EventEmitter<any>()
  @Output() selectedMeasurementTypeChanged = new EventEmitter<any>()
  @Output() filterChanged = new EventEmitter<any>()

  viewMoreUnits = false
  commonUnitSymbols = ['kg', 'g', 'lb', 'hour', 'm2', 'm3', '']

  symbolSelectorInput = ''

  measurementTypes = []
  measurementTypesWithData = []
  measurementTypeSelectorInput = ''

  tracked = {
    filteredCustomUnits: [],
    filteredUnitsByMeasurementType: [],
    filteredMeasurementTypes: []
  }

  constructor(
    public languageSerice: LanguageService,
    private stateService: StateServiceFe,
    private unitService: UnitServiceFe
  ) {
    super(languageSerice)
    this.filterUnitsByMeasurementTypeForSymbolSelect()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.includeMeasurementTypes) {
      const prevMT: Set<string> = changes.includeMeasurementTypes.previousValue
      const currMT: Set<string> = changes.includeMeasurementTypes.currentValue
      const includeMeasurementTypesAreEqual =
        prevMT?.size == currMT?.size && [...prevMT?.values()]?.every((p) => currMT.has(p))
      if (!includeMeasurementTypesAreEqual) {
        this.filterUnitsByMeasurementTypeForSymbolSelect()
      }
    }

    if (changes.customUnits && changes.customUnits.previousValue != changes.customUnits.currentValue) {
      if (this.includeMeasurementTypes?.size > 0) {
        //Only return units whose measurement types is in includeMeasurementTypes
        this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => {
          let measurementTypeFound = false
          if (this.includeMeasurementTypes.has(unit.measurementType)) {
            measurementTypeFound = true
          }
          return measurementTypeFound && unit.shouldDisplay
        })
      } else {
        this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => unit.shouldDisplay)
      }
      this.setUnit()
    }

    if (
      changes.unitsByMeasurementType &&
      changes.unitsByMeasurementType.previousValue != changes.unitsByMeasurementType.currentValue
    ) {
      // If there is data in includeMeasurementTypes
      if (this.includeMeasurementTypes?.size > 0) {
        //Only return units whose measurement types is in includeMeasurementTypes
        this.tracked.filteredUnitsByMeasurementType = this.unitsByMeasurementType.filter((measurementType) => {
          let measurementTypeFound = false
          if (this.includeMeasurementTypes.has(measurementType.key)) {
            measurementTypeFound = true
          }
          return measurementTypeFound
        })
      } else {
        this.tracked.filteredUnitsByMeasurementType = this.unitsByMeasurementType
      }
      if (this.getCommonUnits().length != 0) {
        this.viewMoreUnits = false
      }
      this.setUnit()
    }

    if (
      changes.selectedUnitSymbol &&
      changes.selectedUnitSymbol.previousValue != changes.selectedUnitSymbol.currentValue
    ) {
      this.clearFilteredSymbol()
      this.setUnit()
    }
  }

  async ngOnInit() {
    // If there is data in includeMeasurementTypes
    if (this.includeMeasurementTypes?.size > 0) {
      //Only return units whose measurement types is in includeMeasurementTypes

      this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => {
        let measurementTypeFound = false
        if (this.includeMeasurementTypes.has(unit.measurementType)) {
          measurementTypeFound = true
        }
        return measurementTypeFound && unit.shouldDisplay
      })

      this.tracked.filteredUnitsByMeasurementType = this.unitsByMeasurementType.filter((measurementType) => {
        let measurementTypeFound = false
        if (this.includeMeasurementTypes.has(measurementType.key)) {
          measurementTypeFound = true
        }
        return measurementTypeFound
      })
    } else {
      this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => unit.shouldDisplay)
      this.tracked.filteredUnitsByMeasurementType = this.unitsByMeasurementType
    }

    this.setUnit()

    //Get measurment type keys from units that are allowed to display
    const units = await this.stateService.getUnits()
    const measurementTypesWithDataKeys = new Set()
    units
      .filter((unit) => unit.shouldDisplay)
      .forEach((unit) => {
        measurementTypesWithDataKeys.add(unit.measurementType)
      })
    this.measurementTypesWithData = Array.from(measurementTypesWithDataKeys)

    Object.keys(UnitMeasurementTypes).forEach((key) => {
      const measurementType = {
        key,
        name: getUnitMeasurementTypeName(UnitMeasurementTypes[key]),
        defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[key])
      }
      this.measurementTypes.push(measurementType)
    })

    let measurementTypes = _.cloneDeep(this.measurementTypes)
    //filter out measurement type
    const exludeMeausurementTypes = [UnitMeasurementTypes.NO_UNIT, UnitMeasurementTypes.BINARY]
    measurementTypes = measurementTypes.filter(
      (measurementType) => !exludeMeausurementTypes.includes(measurementType.key)
    )
    this.tracked.filteredMeasurementTypes = measurementTypes

    this.filterUnitsByMeasurementTypeForSymbolSelect()
  }

  setUnit() {
    if (this.selectedUnitSymbol) {
      this.tracked.filteredUnitsByMeasurementType?.forEach((measurementType) =>
        measurementType.systems.forEach((system) => {
          system.units.forEach((unit) => {
            if (unit.symbol == this.selectedUnitSymbol) {
              this.selectedUnitContainer.selectedUnit = unit
            }
          })
        })
      )
      this.tracked.filteredCustomUnits.forEach((unit) => {
        if (unit.symbol == this.selectedUnitSymbol) {
          this.selectedUnitContainer.selectedUnit = unit
        }
      })
    } else {
      //select no unit
      const noUnit = this.unitService.getNoUnit()

      if (!this.skipInitNoUnit) {
        this.selectedUnitContainer.selectedUnit = noUnit
      } else {
        this.selectedUnitContainer.selectedUnit = null
      }

      if (this.skipInitNoUnit || this.isNoUnit(this.selectedUnitContainer.selectedUnit?.name)) {
        this.enableMoreUnits()
      }
    }
  }

  getSystems() {
    // for each key, need to get unit system name, sort by unit system name and return list of keys
    const systems = _.sortBy(
      Object.keys(UnitSystems).map((key) => ({ key, name: this.getSystemName(key) })),
      ['name']
    ).map((obj) => obj.key)
    return systems
  }

  getSystemName(system) {
    return getUnitSystemName(system)
  }

  getMeasurementTypes() {
    // for each key, need to get measurement type name, sort by measurement type name and return list of keys
    const measurementTypes = _.sortBy(
      this.measurementTypesWithData.map((key) => ({ key, name: this.getMeasureTypeName(key) })),
      ['name']
    ).map((obj) => obj.key)
    return measurementTypes
  }

  getMeasureTypeName(measurementType) {
    return getUnitMeasurementTypeName(measurementType)
  }

  getUnitsByMeasurementType() {
    return this.unitsByMeasurementType
  }

  async filterUnitsInSameMeasurementTypeAsSelectedUnitSymbol() {
    /*
        If there is a selected unit symbol, 
        filter units that have the same MTCode as selected unit
      */
    if (!_.isEmpty(this.selectedUnitSymbol)) {
      const units = await this.stateService.getUnits()
      const selectedUnit = this.unitService.getUnit({ symbol: this.selectedUnitSymbol, units })
      if (!_.isEmpty(selectedUnit)) {
        this.tracked.filteredUnitsByMeasurementType = this.tracked.filteredUnitsByMeasurementType.filter(
          (measurementType) => {
            measurementType.systems = measurementType.systems.filter((system) => {
              system.units = system.units.filter((unit) => unit.MTCode == selectedUnit.MTCode)
              return system.units.length > 0
            })
            return measurementType.systems.length > 0
          }
        )

        this.tracked.filteredCustomUnits = this.tracked.filteredCustomUnits?.filter(
          (unit) => unit.MTCode == selectedUnit.MTCode
        )
      }
    }
  }

  async filterUnitsByMeasurementTypeForSymbolSelect() {
    this.filter.clear()
    if (_.isEmpty(this.symbolSelectorInput)) {
      const units = await this.stateService.getUnits()
      const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(units)

      // If there is data in includeMeasurementTypes
      if (this.includeMeasurementTypes?.size > 0) {
        //Only return units whose measurement types is in includeMeasurementTypes
        this.tracked.filteredUnitsByMeasurementType = unitsByMeasurementType.filter((measurementType) => {
          let measurementTypeFound = false
          if (this.includeMeasurementTypes.has(measurementType.key)) {
            measurementTypeFound = true
          }
          return measurementTypeFound
        })

        this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => {
          let measurementTypeFound = false
          if (this.includeMeasurementTypes.has(unit.measurementType)) {
            measurementTypeFound = true
          }
          return measurementTypeFound && unit.shouldDisplay
        })
      } else {
        this.tracked.filteredUnitsByMeasurementType = this.unitsByMeasurementType
        this.tracked.filteredCustomUnits = this.customUnits.filter((unit) => unit.shouldDisplay)
      }

      //filter out units that shouldn't be displayed
      this.tracked.filteredUnitsByMeasurementType = this.tracked.filteredUnitsByMeasurementType?.filter(
        (measurementType) => {
          measurementType.systems = measurementType.systems.filter((system) => {
            system.units = system.units.filter((unit) => unit.shouldDisplay)
            return system.units.length > 0
          })
          return measurementType.systems.length > 0
        }
      )

      await this.filterUnitsInSameMeasurementTypeAsSelectedUnitSymbol()

      this.filterChanged.emit({})
      return
    }

    this.filter.add(this.symbolSelectorInput)
    this.filterChanged.emit({})

    const units = await this.stateService.getUnits()
    let unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(units)

    //Combine units and their prefixes into one array to search once
    unitsByMeasurementType = unitsByMeasurementType.map((measurementType) => {
      measurementType.systems = measurementType.systems.map((system) => {
        let unitPrefixes = []
        system.units.forEach((unit) => {
          unitPrefixes = [...unitPrefixes, ...unit.prefixList]
        })
        system.units = [...system.units, ...unitPrefixes]
        return system
      })
      return measurementType
    })

    this.tracked.filteredUnitsByMeasurementType = unitsByMeasurementType.filter((measurementType) => {
      // If there is data in includeMeasurementTypes
      let skipMeasurementType = false
      if (this.includeMeasurementTypes?.size > 0) {
        skipMeasurementType = true
        //Only work on measurement types in includeMeasurementTypes
        if (this.includeMeasurementTypes.has(measurementType.key)) {
          skipMeasurementType = false
        }
      }

      if (skipMeasurementType) {
        return false
      }

      measurementType.systems = measurementType.systems.filter((system) => {
        system.units = system.units.filter((unit) => {
          if (
            unit.name
              .toLowerCase()
              .split('~')
              .join('')
              .includes(this.symbolSelectorInput.toLowerCase().split('~').join('')) ||
            unit.symbol
              .toLowerCase()
              .split('~')
              .join('')
              .includes(this.symbolSelectorInput.toLowerCase().split('~').join('')) ||
            unit.aliases
              .toLowerCase()
              .split('~')
              .join('')
              .includes(this.symbolSelectorInput.toLowerCase().split('~').join(''))
          ) {
            return true
          }
          return false
        })

        if (system.units.length > 0) {
          return true
        }
        return false
      })

      if (measurementType.systems.length > 0) {
        return true
      }

      return false
    })

    //filter out units that shouldn't be displayed
    this.tracked.filteredUnitsByMeasurementType = this.tracked.filteredUnitsByMeasurementType.filter(
      (measurementType) => {
        measurementType.systems = measurementType.systems.filter((system) => {
          system.units = system.units.filter((unit) => unit.shouldDisplay)
          return system.units.length > 0
        })
        return measurementType.systems.length > 0
      }
    )

    // filter custom units
    const customUnits = _.cloneDeep(this.customUnits)
    this.tracked.filteredCustomUnits = customUnits.filter((unit) => {
      let measurementTypeFound = false

      if (this.includeMeasurementTypes?.size == 0) {
        measurementTypeFound = true
      }

      if (this.includeMeasurementTypes?.has(unit.measurementType)) {
        measurementTypeFound = true
      }

      let unitFound = false
      if (
        unit.name.toLowerCase().includes(this.symbolSelectorInput.toLowerCase()) ||
        unit.symbol.toLowerCase().includes(this.symbolSelectorInput.toLowerCase()) ||
        unit.aliases.toLowerCase().includes(this.symbolSelectorInput.toLowerCase())
      ) {
        unitFound = true
      }

      return measurementTypeFound && unitFound && unit.shouldDisplay
    })

    await this.filterUnitsInSameMeasurementTypeAsSelectedUnitSymbol()
  }

  getSymbol(unit: UnitFe) {
    if (_.isEmpty(unit)) return ''

    let symbol = unit.symbol
    if (!_.isEmpty(unit.aliases)) {
      symbol = unit.aliases
    }

    return symbol
  }

  clearFilter() {
    this.filter.clear()
    this.filterChanged.emit({})
  }

  updateFilter({ term, evt }) {
    if (evt.target.checked) {
      this.filter.add(term)
    } else {
      this.filter.delete(term)
    }
    this.filterChanged.emit({})
  }

  toggleMeasurementTypeFilter(measurementType) {
    if (measurementType == 'ALL') {
      this.filter.clear()
      this.filterChanged.emit({})
      return
    }

    if (this.filter.has(measurementType)) {
      this.filter.delete(measurementType)
      this.filterChanged.emit({})
      return
    }

    this.filter.add(measurementType)
    this.filterChanged.emit({})
  }

  startUnitEditor() {
    this.createCustomUnitModalOpened.next(true)
    this.unitEdtiorModal.open({ state: UNIT_EDITOR_STATE.ADD_UNIT, shouldBeConvertible: this.shouldBeConvertible })
  }

  selectUnit(unit) {
    this.selectedUnitContainer.selectedUnit = unit
    this.symbolSelectorInput = unit.name
    this.skipInitNoUnit = false
    this.filterUnitsByMeasurementTypeForSymbolSelect()
    this.selectedUnitChanged.emit(unit)
    this.closeDropdowns()
  }

  closeDropdowns() {
    document.querySelectorAll(`.unit-selector-dropdown-menu`).forEach((dropdownMenu) => {
      dropdownMenu.classList.remove('show')
    })
  }

  clearSelectedUnit() {
    //should select no unit
    this.clearFilteredSymbol()
    const noUnit = this.unitService.getNoUnit()
    this.selectedUnitContainer.selectedUnit = noUnit
    this.selectedUnitChanged.emit(noUnit)
  }

  enableClearFilteredSymbol() {
    return !_.isEmpty(this.symbolSelectorInput)
  }

  clearFilteredSymbol() {
    this.symbolSelectorInput = ''
    this.filterUnitsByMeasurementTypeForSymbolSelect()
  }

  isSelectedUnitEmpty() {
    return _.isEmpty(this.selectedUnitContainer.selectedUnit)
  }

  selectMeasurementType(measurementType) {
    this.selectedMeasurementTypeContainer.selectedMeasurementType = measurementType
    this.selectedMeasurementTypeChanged.emit(measurementType)
  }

  clearSelectedMeasurementType() {
    this.selectedMeasurementTypeContainer.selectedMeasurementType = null
    this.selectedMeasurementTypeChanged.emit(null)
  }

  isSelectedMeasurementTypeEmpty() {
    return _.isEmpty(this.selectedMeasurementTypeContainer.selectedMeasurementType)
  }

  filterMeasurementTypesSelect() {
    let measurementTypes = _.cloneDeep(this.measurementTypes)
    //filter out nounit measurement type
    const exludeMeausurementTypes = [UnitMeasurementTypes.NO_UNIT, UnitMeasurementTypes.BINARY]
    measurementTypes = measurementTypes.filter(
      (measurementType) => !exludeMeausurementTypes.includes(measurementType.key)
    )

    if (_.isEmpty(this.measurementTypeSelectorInput)) {
      this.tracked.filteredMeasurementTypes = measurementTypes
      return
    }

    this.tracked.filteredMeasurementTypes = measurementTypes.filter((measurementType) => {
      return measurementType.name.toLowerCase().includes(this.measurementTypeSelectorInput.toLowerCase())
    })
  }

  enableMoreUnits() {
    this.viewMoreUnits = true
  }

  getCommonUnits() {
    let commonUnits = []

    this.tracked.filteredCustomUnits.forEach((unit) => {
      if (unit.isCommon || this.commonUnitSymbols.includes(unit.symbol)) {
        commonUnits.push(unit)
      }
    })

    this.tracked.filteredUnitsByMeasurementType?.forEach((measurementType) =>
      measurementType.systems.forEach((system) => {
        system.units.forEach((unit) => {
          if (unit.isCommon || this.commonUnitSymbols.includes(unit.symbol)) {
            commonUnits.push(unit)
          }
        })
      })
    )

    // return unique symbols
    commonUnits = _.uniqBy(commonUnits, 'symbol')

    // If common units is empty, open expanded list.
    if (commonUnits.length == 0) {
      this.viewMoreUnits = true
    }

    // sort by order
    const order = [
      'kg',
      'tonne',
      'liter',
      'm3',
      'kWh',
      'MWh',
      'm2',
      'kgCO2e',
      'tCO2e',
      'kgCO2',
      'tCO2',
      'km',
      'tkm',
      'lb',
      '%',
      'number',
      'unit'
    ]

    const orderedCommonUnits = []

    order.forEach((symbol) => {
      const unit = _.find(commonUnits, { symbol })
      if (unit) {
        orderedCommonUnits.push(unit)
      }
    })

    return orderedCommonUnits
  }

  isNoUnit(unitName: string): boolean {
    return unitName === NoUnit.UNIT
  }
}
