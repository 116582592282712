import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { ColumnSchemaFe } from 'src/app/model/schema/ColumnSchemaFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { TabFileDataGridIntergationService } from './TabFileDataGridIntergationService'
import { DataGridTableData } from '../projects/data-grid-ui/model/DataGridTableData'
import { DataGridTableDef } from '../projects/data-grid-ui/model/DataGridTableDef'
import { DataGridTaxonomyEntity } from '../projects/data-grid-ui/model/DataGridTaxonomyEntity'
import { DataExtractorObserverInterface } from '../projects/data-grid-ui/service/DataExtractorObserverInterface'
import { DataExtractorServiceInterface } from '../projects/data-grid-ui/service/DataExtractorServiceInterface'
import { Language } from 'src/app/utils/language/Language'
import { LanguageService } from 'src/app/services/LanguageServiceFe'

export class FileDataExtractorIntegrationService implements DataExtractorServiceInterface {
  stateService: StateServiceFe
  fileData: FileDataFe
  observer: DataExtractorObserverInterface | undefined
  tableData: TableDataFe
  viewer: string

  constructor(stateService: StateServiceFe, fileData: FileDataFe) {
    this.stateService = stateService
    this.fileData = fileData
    this.setMainViewer()
  }

  registerObserver(observer: DataExtractorObserverInterface): void {
    this.observer = observer
  }

  getViewer(): string {
    console.log(this.viewer)
    return this.viewer
  }

  setMainViewer() {
    this.viewer = 'main'
    this.fileData.isConvertedToImg = false
    this.fileData.isCropped = false
  }

  isMainViewer(): boolean {
    return this.viewer == 'main'
  }

  setCropperViewer() {
    this.viewer = 'cropper'
    this.fileData.isConvertedToImg = true
    this.fileData.isCropped = false
  }

  isCropperViewer(): boolean {
    return this.viewer == 'cropper'
  }

  setExtractorViewer() {
    this.viewer = 'extractor'
    this.fileData.isCropped = true
  }

  isExtractorViewer(): boolean {
    return this.viewer == 'extractor'
  }

  async getTaxonomyEntities(): Promise<DataGridTaxonomyEntity[]> {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    let taxonomy = depTaxonomy
    let tableEntiteies = taxonomy.entities.filter((e) => e.table == true)
    let entities = tableEntiteies.map((e) => {
      let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
      return entity
    })
    return entities
  }

  async getEntityTableDef(entity: DataGridTaxonomyEntity): Promise<DataGridTableDef> {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    let taxonomy = depTaxonomy
    let e = taxonomy.entityByKey(entity.key)
    let tableDef = new DataGridTableDef()
    let schema: ColumnSchemaFe[] = e.columnSchemas()
    tableDef.dataSchema = schema.map((s) => s.toDataGridColumnSchema())
    return tableDef
  }

  async exportTableData(dgtd: DataGridTableData): Promise<void> {
    let tableData = TableDataFe.fromDataGridTableData(dgtd)
    this.tableData = tableData
    this.fileData.dataGridService = new TabFileDataGridIntergationService(tableData)
    this.fileData.setTableViewer()
  }

  async getPDFBlob(): Promise<Blob> {
    if (this.fileData.isPdf()) {
      this.setMainViewer()
      return this.fileData.blob
    } else return null
  }

  async getImageBlob(): Promise<Blob> {
    if (this.fileData.isImage()) {
      this.setMainViewer()
      return this.fileData.blob
    } else return null
  }

  async getFileName(): Promise<string> {
    return this.fileData.label
  }
}
