import { CompanyFe } from '../data-suppliers/company/CompanyFe'

export class CustomerSuccess_AddFe {
  firstName: string
  lastName: string
  email: string
  assignedCompanys: CompanyFe[]

  constructor(firstName: string, lastName: string, email: string, assignedCompanys: CompanyFe[]) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.assignedCompanys = assignedCompanys
  }
}
