import { QUESTION_TYPE } from '../../../request/questionnaire/QuestionTypeFe'
import { AnswerFe } from '../../answer/AnswerFe'
import { Answer_AddFe } from './Answer_AddFe'

export class AnswerMultipleChoice_AddFe extends Answer_AddFe {
  selectedOptionIds: string[]

  constructor(id: string, sectionId: string, questionId: string, comments: string | null, selectedOptionIds: string[]) {
    super(id, sectionId, questionId, QUESTION_TYPE.MULTIPLE_CHOICE, comments)
    this.selectedOptionIds = selectedOptionIds
  }

  addOption(optionId: string, event: Event) {
    if (event.currentTarget instanceof HTMLInputElement && event.currentTarget.checked) {
      this.selectedOptionIds.push(optionId)
    } else {
      let index = this.selectedOptionIds.findIndex((id) => id == optionId)
      this.selectedOptionIds.splice(index, 1)
    }
  }

  public isInvalid(isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return this.selectedOptionIds.length != 0
  }

  public reset(): void {
    this.selectedOptionIds = []
  }

  public toTransfer() {
    let ans = new AnswerMultipleChoice_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.selectedOptionIds
    )
    return ans
  }
}
