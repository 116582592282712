import { DataGridColumnSchema } from './DataGridColumnSchema'

export class ActionFe {
  public static ADD = 'ADD'
  public static EDIT = 'EDIT'
}

export class ColumnModifiedSubjectFe {
  constructor(
    public action: ActionFe,
    public obj: DataGridColumnSchema
  ) {}
}
