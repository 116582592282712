import { ChartType } from 'chart.js'

export class ChartTypeFe {
  static readonly BAR = new ChartTypeFe('bar', 'bar', 'Bar Chart', false)
  static readonly LINE = new ChartTypeFe('line', 'line', 'Line Chart', false)
  static readonly STACK = new ChartTypeFe('stack', 'bar', 'Stack Chart', true)
  static readonly DOUGHNUT = new ChartTypeFe('doughnut', 'doughnut', 'Doughnut Chart', false)
  static readonly PIE = new ChartTypeFe('pie', 'pie', 'Pie Time', false)
  static readonly TABLE = new ChartTypeFe('table', 'table', 'Table View', false)

  private constructor(
    public readonly id: string,
    public readonly chartjsKey: ChartType | string,
    public readonly title: any,
    public readonly stacked: boolean
  ) {}

  public toTransfer(): string {
    return this.id
  }

  public static fromTransfer(id: string): ChartTypeFe {
    if (id == this.BAR.id) return this.BAR
    else if (id == this.LINE.id) return this.LINE
    else if (id == this.STACK.id) return this.STACK
    else if (id == this.DOUGHNUT.id) return this.DOUGHNUT
    else if (id == this.PIE.id) return this.PIE
    else if (id == this.TABLE.id) return this.TABLE
    else return this.LINE
  }
}
