<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill py-2">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="text-uppercase ms-2" [ngClass]="{ 'ms-5': screenSize.isSmallerSize() || screenSize.isXSmallSize()}">{{ locale('locale_key.pages.settings.title') }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-row me-2 w-100">
          <section class="row w-100 ms-0" style="overflow: hidden">
            <div class="col-12 card px-0 mx-0" style="overflow: hidden; height: var( --page-content-minus-1-page-header-footer)">
              <div>
                <app-tabs [selectedIndex]="activeTabIndex">
                  <app-tab tabTitle="{{ locale('locale_key.pages.settings.user_setting') }}" [isActive]="true">
                    <div>
                      <app-user-settings></app-user-settings>
                    </div>
                  </app-tab>
                  <app-tab *ngIf="!isDO()" tabTitle="{{ locale('locale_key.pages.settings.company_setting') }}">
                    <app-company-settings></app-company-settings>
                  </app-tab>
                </app-tabs>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
