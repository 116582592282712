<div class="condition_builder">
  <div *ngIf="isEmptyCondition() && showEditMode" class="create_condition_control" (click)="createCondition()">
    <i class="create_condition_icon las la-plus-circle"></i>
    <div class="create_condition_label">Click to create condition</div>
  </div>

  <condition-container
    *ngIf="!isEmptyCondition()"
    [isRoot]="true"
    [showEditMode]="showEditMode"
    [selectedDatapoint]="selectedDatapoint"
    [selectedDataCategory]="selectedDataCategory"
    [level3DataCategory]="level3DataCategory"
    [condition]="calculation.condition"
  ></condition-container>

  <!-- ERRORS -->
  <div class="error_msgs" *ngIf="showEditMode && showConditionErrors && hasConditionError()">
    <ul>
      <li *ngFor="let errorMsg of conditionErrorMsgs">{{ errorMsg }}</li>
    </ul>
  </div>

  <div *ngIf="!isEmptyCondition() && showEditMode" class="and_or_controls">
    <div class="and_control" (click)="wrapCondition({ comparator: 'and' })">
      <i class="and_control_icon las la-plus-circle"></i>
      <div class="and_control_label">AND</div>
    </div>
    <div class="or_control" (click)="wrapCondition({ comparator: 'or' })">
      <i class="or_control_icon las la-plus-circle"></i>
      <div class="or_control_label">OR</div>
    </div>
  </div>
</div>
