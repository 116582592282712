import { Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { error } from 'protractor'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { VisitorServiceFe } from 'src/app/services/VisitorServiceFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'app-verify-shared-dashboard',
  templateUrl: './verifySharedDashboard.component.html'
})
export class VerifySharedDashboardComponent extends AbstractLanguageComponent {
  inProgress = false
  objectSharedId: string
  viewNo = 1
  email = new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)])
  enteredCode = new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.NumberRegExp)])
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  lowerCaseEmail = this.locale('locale_key.general.validation_message.invalid_email')
  verificationCodeMessage = ValidationMessages.verificationCode[0].message
  verificationCodeValidMessage = ValidationMessages.verificationCode[1].message

  constructor(
    private stateService: StateServiceFe,
    private router: Router,
    private route: ActivatedRoute,
    private visitorService: VisitorServiceFe,
    private alertService: AlertServiceFe,
    languageService: LanguageService,
    private ErrorsFe: ErrorsFe
  ) {
    super(languageService)
  }

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.objectSharedId = params.objectSharedId
    })
  }

  async sendVerificationCode() {
    if (this.email.invalid) {
      this.email.markAllAsTouched()
      return
    }
    try {
      this.inProgress = true
      let verified = await this.stateService.verifySharedDashboardEmail(this.objectSharedId, this.email.value)
      await this.generateNewCode()
      this.viewNo++
      this.inProgress = false
    } catch (err) {
      let knownError = this.ErrorsFe.matchError(err.error)
      if (
        knownError == OpenErrorsFe.NO_ACCESS_TO_SHARED_DASHBOARD ||
        knownError == OpenErrorsFe.SHARED_DASHBOARD_NOT_FOUND
      ) {
        this.alertService.showError(knownError.message)
      }
      this.inProgress = false
    }
  }

  async generateNewCode() {
    try {
      this.inProgress = true
      let generated = await this.stateService.generateSharedDashboardVerificationCode(this.email.value)
      this.inProgress = false
    } catch (err) {
      this.inProgress = false
    }
  }

  async verifyCode() {
    if (this.enteredCode.invalid) {
      this.enteredCode.markAllAsTouched()
      return
    }
    try {
      this.inProgress = true
      await this.visitorService.verify(this.email.value, this.enteredCode.value)
      if (this.visitorService.user.emailLang) {
        this.languageService.changeLanguage(this.visitorService.user.emailLang)
      }
      this.inProgress = false
    } catch (err) {
      let knownError = this.ErrorsFe.matchError(err.error)
      if (knownError == OpenErrorsFe.ENTERED_WRONG_VERIFICATION_CODE) {
        this.alertService.showError(knownError.message)
      }
      this.inProgress = false
    }
  }
}
