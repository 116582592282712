import { OrganizationEntityFe } from '../organization/OrganizationEntityFe'
import { OrganizationInfoFe } from '../organization/OrganizationInfoFe'
import { TableDataFe } from '../schema/TableDataFe'

export class OrganizaationFilter {
  orgInfo: OrganizationInfoFe
  orgTableDatas: TableDataFe[]
  public selectedEntity: OrganizationEntityFe
  public selectedEntityTable: TableDataFe

  constructor(orgInfo: OrganizationInfoFe, orgTableDatas: TableDataFe[]) {
    this.orgInfo = orgInfo
    this.orgTableDatas = orgTableDatas
  }

  getEntityTable(entityKey: string): TableDataFe {
    let dataTable = this.orgTableDatas.find((dt) => dt.key == entityKey)
    return dataTable
  }

  selectEntity(entityKey: string) {
    this.selectedEntity = this.orgInfo.entities.find((e) => e.key == entityKey)
    this.selectedEntityTable = this.orgTableDatas.find((dt) => dt.key == entityKey)
  }

  addDataToFilter(entityKey: string, dataRowId: string) {
    let dataTable = this.orgTableDatas.find((dt) => dt.key == entityKey)
    dataTable.addDataToFilter(dataRowId)
  }

  removeDataFromFilter(entityKey: string, dataRowId) {
    let dataTable = this.orgTableDatas.find((dt) => dt.key == entityKey)
    dataTable.addDataToFilter(dataRowId)
  }
}
