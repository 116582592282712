<ng-container *ngIf="showAsEditBtn; else efBtn">
  <i class="la la-edit" (click)="openModal(emissionModal)"></i>
</ng-container>
<ng-template #efBtn>
  <button
    [ngClass]="{
      'border-blue border-radius-10 py-1 ps-2': !insideTable,
      'w-100': isButtonFullWidth,
      'border-0': customButtonHtml
    }"
    class="text-start"
    style="background: none; min-width: 20rem; display: flex"
    [disabled]="!selectedEF && !isEditable"
  >
    <ng-container *ngIf="!isEmpty(selectedEF); else selectEF">
      <span (click)="isEditable ? openModal(emissionModal) : null" class="d-flex align-items-center" style="flex: 1">
        <i class="la la-fire-alt"></i>
        <ng-container *ngIf="displayStyle === 'DEFAULT'">
          <span>
            1 {{ selectedEF?.sourceUnit }} {{ selectedEF?.sourceName }}
            {{ locale('locale_key.general.data_type.date_range.to') }}
          </span>
          <b class="ms-1">{{ selectedEF?.conversionFactor }} {{ selectedEF?.conversionUnit }}</b>
        </ng-container>

        <ng-container *ngIf="displayStyle === 'SOURCE_UNIT'">
          <span>
            {{
              locale('locale_key.pages.data_request.emissionfactor_display_value', {
                conversionFactor: selectedEF?.conversionFactor,
                conversionUnit: selectedEF?.conversionUnit,
                sourceName: selectedEF?.sourceName
              })
            }}
          </span>
        </ng-container>
      </span>
      <i class="la la-info-circle float-end info-popover-arrow ms-2 pt-2" type="button" (click)="openEfDetailTab()"></i>
      <div class="dropdown-menu" style="background-color: white; width: 20rem; font-size: 10px">
        <div class="row mx-1">
          <div class="col-5 word-break-all px-2 py-1">{{ locale('ocale_key.pages.emission.emission_source') }}</div>
          <div class="col-7 word-break-all px-2 py-1">{{ selectedEF.sourceName }}</div>
        </div>
        <div class="row mx-1">
          <div class="col-5 word-break-all px-2 py-1">{{ locale('locale_key.pages.emission.conversion_factor') }}</div>
          <div class="col-7 word-break-all px-2 py-1">{{ selectedEF?.conversionFactor }}</div>
        </div>
        <div class="row mx-1">
          <div class="col-5 word-break-all px-2 py-1">{{ locale('locale_key.pages.emission.conversion_unit') }}</div>
          <div class="col-7 word-break-all px-2 py-1">{{ selectedEF?.conversionUnit }}</div>
        </div>
        <div class="row mx-1">
          <div class="col-5 word-break-all px-2 py-1">{{ locale('locale_key.pages.emission.source_unit') }}</div>
          <div class="col-7 word-break-all px-2 py-1">{{ selectedEF?.sourceUnit }}</div>
        </div>
      </div>
    </ng-container>
    <ng-template #selectEF>
      <div class="custom-button-container" *ngIf="customButtonHtml" (click)="openModal(emissionModal)">
        <div class="px-2">
          <i class="icon las la-plus fs-5" style="position: relative; top: 2px"></i>
          {{ locale('locale_key.general.select_different_option') }}
        </div>
      </div>
      <div *ngIf="!customButtonHtml" (click)="openModal(emissionModal)">
        <i class="la la-fire-alt text-stroke"></i>
        {{ locale('locale_key.pages.emission.select_emission_factor') }}
      </div>
    </ng-template>
  </button>
</ng-template>

<ng-template #emissionModal>
  <emission-factor-detail
    [mgmtCompanyId]="mgmtCompanyId"
    [datapointEFUnit]="datapointEFUnit"
    (closeEvent)="closeModal()"
    (emissionFactorSelected)="setEmissionFactor($event)"
  ></emission-factor-detail>
</ng-template>
