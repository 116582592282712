<div class="btn-group p-0 w-100" dropdown #catdropdown="bs-dropdown">
  <button dropdownToggle type="button" class="border text-start bg-white btn selectCatBtn">
    <ng-container *ngIf="selectedEntity">
      <div class="item2 bg-light-gray p-1">
        <i class="{{ taxonomy.icon(selectedEntity) }} me-2 fs-5"></i>
        {{ selectedRootEntity.getLabel() }}
      </div>
      /
      <div class="item2 bg-light-gray p-1">
        <i class="{{ taxonomy.icon(selectedEntity) }} me-2 fs-5"></i>
        {{ selectedParentEntity.getLabel() }}
      </div>
      /
      <div class="item2 bg-light-gray p-1">
        <i class="{{ taxonomy.icon(selectedEntity) }} me-2 fs-5"></i>
        {{ selectedEntity.getLabel() }}
      </div>
      <div class="newTag" *ngIf="selectedEntity.isNew">{{ locale('locale_key.general.label.new') }}</div>
    </ng-container>
    <ng-container *ngIf="!selectedEntity">
      {{ locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_data_category.placholder') }}
    </ng-container>
    <i class="la la-caret-down mt-2 float-inline-end"></i>
  </button>
  <div id="category-dropdown" *dropdownMenu class="dropdown-menu p-0" role="menu" aria-labelledby="category-dropdown">
    <div class="card">
      <div *ngFor="let item0 of taxonomy?.childrenSortedByOrdinal()" class="mb-2">
        <div class="bg-gray ps-3 py-1" title="{{ item0.getLabel() }}">
          <i class="{{ taxonomy.icon(item0) }} gradient-end ms-1 fs-5"></i>
          {{ item0.getLabel() }}
        </div>
        <div class="row my-2" *ngFor="let item1 of taxonomy?.childrenSortedByOrdinal(item0.key)">
          <div class="col-3 truncate" title="{{ item1.getLabel() }}">
            <span class="item1 mt-1 ps-2 mx-2 truncate bold">{{ item1.getLabel() }}:</span>
          </div>
          <div class="col-8 mb-2">
            <div
              class="item2 cursor bg-light-gray p-1 mt-1 me-2"
              *ngFor="let item2 of taxonomy?.childrenSortedByOrdinal(item1.key)"
              (click)="select(item0, item1, item2, catdropdown)"
              [ngClass]="{ 'selected white': selectedEntity == item2 }"
              title="{{ item2.getLabel() }}"
            >
              <i class="{{ taxonomy.icon(item0) }} me-2 fs-5"></i>
              {{ item2.getLabel() }}
              <div class="newTag" *ngIf="item2.isNew">{{ locale('locale_key.general.label.new') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
