import { saveAs } from 'file-saver'
import { FileTypeFe } from './FileTypeFe'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ImageCroppedEvent, CropperPosition } from 'ngx-image-cropper'
import { FileDataExtractorIntegrationService } from 'src/app/components/file/FileDataExtractorIntegrationService'
import { TabFileDataGridIntergationService } from '../../components/file/TabFileDataGridIntergationService'
import { QUESTION_TYPE } from '../data-suppliers/request/questionnaire/QuestionTypeFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { TableDataFe } from '../schema/TableDataFe'
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { FileSourceFe } from './FileSourceFe'
import { DataGridTableMode } from 'src/app/components/projects/data-grid-ui/model/DataGridTableMode'
import { DateUtil } from 'src/app/utils/DateUtil'
import * as XLSX from 'xlsx'

export class FileDataFe {
  public id: string
  public fullName: string
  public type: FileTypeFe
  public contentType: string
  public timeCreated: Date
  public publicUrl: string
  public uploaderFirstName: string
  public uploaderLastName: string
  public uploaderCompany: string
  public uplaoderAffId: string
  public uploaderCompanyId: string
  public source: string
  public requestId: string | null
  public requestGroupId: string | null
  public questionId: string | null
  public status: string | null
  public label: string | null
  public questionType: string | null // ATTACHMENT OR DATA_TABLE

  public blob: Blob
  public txtContent: string | ArrayBuffer
  public imgContent: string | ArrayBuffer
  public url: string
  public safeUrl: SafeUrl
  public viewer: string = 'main'
  public imagePages: { pageNumber: number; base64: any }[] = []
  public cropperPosition: CropperPosition
  public currentPageNumber: number = 1
  public isSelected = false
  public isOpen = false
  public isActive = false
  public loadingFileInProgress = false
  public loadingImagePageInProgress = false
  public convertingToTextInProgress = false
  public isCropped = false
  public isConvertedToImg = false
  public dataExtractorService: FileDataExtractorIntegrationService
  public dataGridService: TabFileDataGridIntergationService
  private exportType: string | null

  constructor(backendFile: any, isFromStorage = true) {
    if (isFromStorage) {
      this.fullName = backendFile.fullName
      this.id = backendFile.fullName.replace(/data_table\//gi, '').replace(/attachment\//gi, '')
      this.type = FileTypeFe.fromName(this.fullName)
      this.contentType = backendFile.contentType
      this.timeCreated = new Date(backendFile.timeCreated)
      this.publicUrl = backendFile.publicUrl
      this.uploaderFirstName = backendFile.uploaderFirstName
      this.uploaderLastName = backendFile.uploaderLastName
      this.uploaderCompany = backendFile.uploaderCompany
      this.uplaoderAffId = backendFile.uplaoderAffId
      this.uploaderCompanyId = backendFile.uploaderCompanyId
      this.source = backendFile.source ? backendFile.source : FileSourceFe.EXPLORER
      this.requestId = backendFile.requestId
      this.requestGroupId = backendFile.requestGroupId
      this.questionId = backendFile.questionId
      this.status = backendFile.status
      this.label = backendFile.label ? backendFile.label : this.fullName.replace(/explorer\//gi, '')
      this.questionType = backendFile.questionType
    }
  }

  public static fromTransfer(transfer: any): FileDataFe {
    let file = new FileDataFe(transfer, true)
    return file
  }

  inProgress(): boolean {
    return this.loadingFileInProgress || this.loadingImagePageInProgress || this.convertingToTextInProgress
      ? true
      : false
  }

  setMainViewer() {
    this.viewer = 'main'
  }

  isMainViewer(): boolean {
    return this.viewer == 'main' ? true : false
  }

  setCropperViewer() {
    this.viewer = 'cropper'
  }

  isCropperViewer(): boolean {
    return this.viewer == 'cropper' ? true : false
  }

  setTextViewer() {
    this.viewer = 'text'
  }

  isTextViewer(): boolean {
    return this.viewer == 'text' ? true : false
  }

  setTableViewer() {
    this.viewer = 'table'
  }

  isTableViewer(): boolean {
    return this.viewer == 'table' ? true : false
  }

  isPdf(): boolean {
    return this.type == FileTypeFe.PDF ? true : false
  }

  isImage(): boolean {
    return this.type == FileTypeFe.PNG || this.type == FileTypeFe.JPG || this.type == FileTypeFe.JPEG ? true : false
  }

  isDoc(): boolean {
    return this.type == FileTypeFe.DOC || this.type == FileTypeFe.DOCX ? true : false
  }

  isExcel(): boolean {
    return this.type == FileTypeFe.CSV || this.type == FileTypeFe.XLS || this.type == FileTypeFe.XLSX ? true : false
  }

  isZip(): boolean {
    return this.type == FileTypeFe.ZIP || this.type == FileTypeFe.ZIPX ? true : false
  }

  isTable(): boolean {
    return this.questionType == QUESTION_TYPE.DATA_TABLE
  }

  localTimeCreated(): string {
    return DateUtil.toString3(this.timeCreated)
  }

  localTimeMillisecond(): number {
    return this.timeCreated.getTime()
  }

  hasContent(): boolean {
    return this.blob ? true : false
  }

  setExportType(exportType: string) {
    this.exportType = exportType
  }

  addBlobContent(content: any, sanitizer: DomSanitizer, saveFile = false) {
    this.blob = new Blob([content], { type: this.contentType })
    this.url = URL.createObjectURL(this.blob)
    this.safeUrl = sanitizer.bypassSecurityTrustUrl(this.url)
    if (this.isImage()) {
      var reader = new FileReader()
      reader.onloadend = () => {
        var base64data = reader.result
        this.imagePages.push({ pageNumber: 1, base64: base64data })
        if (saveFile) {
          this.saveFile()
        }
        this.loadingFileInProgress = false
      }
      reader.readAsDataURL(this.blob)
    } else {
      if (saveFile) {
        this.saveFile()
      }
      this.loadingFileInProgress = false
    }
  }

  saveFile(name = this.label) {
    let blobData: any
    let newName: string = name

    if (this.exportType) {
      newName = this.replaceExtension(name, this.exportType)
    }

    if (this.exportType && this.isExcel()) {
      if (this.type === FileTypeFe.XLSX && this.exportType === 'csv') {
        // Convert XLSX to CSV
        this.blob
          .stream()
          .getReader()
          .read()
          .then(({ value }: any) => {
            const decoder = new TextDecoder('utf-8')
            decoder.decode(value)
            const csvData = this.xlsxToCsv(value)
            blobData = new Blob([csvData], { type: 'text/csv' })
            saveAs(blobData, newName)
            return
          })
      } else if (this.type === FileTypeFe.CSV && this.exportType === 'xlsx') {
        // Convert CSV to XLSX
        this.blob
          .stream()
          .getReader()
          .read()
          .then(({ value }: any) => {
            const decoder = new TextDecoder('utf-8')
            const csvString = decoder.decode(value)
            const csvArray = csvString.split('\n').map((row: string) => row.split(','))
            const excelBuffer = this.csvToXlsx(csvArray)
            blobData = new Blob([excelBuffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blobData, newName)
            return
          })
      }
    }
    // Save the file with the original name if no exportType is provided
    // or exportType same extension of the excel file
    saveAs(this.blob, newName)
  }

  addTextContent(content: any) {
    var reader = new FileReader()
    reader.onload = (e) => {
      this.txtContent = reader.result
      this.convertingToTextInProgress = false
    }
    reader.readAsText(content, 'UTF-8')
  }

  containsCurrentPageImage() {
    let pageBlob = this.imagePages.find((pb) => pb.pageNumber == this.currentPageNumber)
    return pageBlob ? true : false
  }

  addPageImageContent(pageNumber: number, content: any) {
    let pageBlob = this.imagePages.find((pb) => pb.pageNumber == pageNumber)
    if (!pageBlob) {
      let blob = new Blob([content], { type: this.contentType })
      var reader = new FileReader()
      reader.onloadend = () => {
        var base64data = reader.result
        this.imagePages.push({ pageNumber: pageNumber, base64: base64data })
        this.loadingImagePageInProgress = false
      }
      reader.readAsDataURL(blob)
    }
  }

  currentPageImageBase64() {
    let pageBlob = this.imagePages.find((pb) => pb.pageNumber == this.currentPageNumber)
    if (pageBlob) {
      return pageBlob.base64
    } else {
      return undefined
    }
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.cropperPosition = event.imagePosition
  }

  public static sortByNameAsc(f1: FileDataFe, f2: FileDataFe) {
    return f1.label > f2.label ? -1 : 1
  }

  public static sortByNameDesc(f1: FileDataFe, f2: FileDataFe) {
    return f1.label > f2.label ? 1 : -1
  }

  public static sortByTimeAsc(f1: FileDataFe, f2: FileDataFe) {
    return f1.localTimeMillisecond() > f2.localTimeMillisecond() ? -1 : 1
  }

  public static sortByTimeDesc(f1: FileDataFe, f2: FileDataFe) {
    return f1.localTimeMillisecond() > f2.localTimeMillisecond() ? 1 : -1
  }

  public static sortFunction(property, asc: boolean = true) {
    if (property == 'name' && asc) {
      return FileDataFe.sortByNameAsc
    } else if (property == 'name' && !asc) {
      return FileDataFe.sortByNameDesc
    } else if (property == 'time' && asc) {
      return FileDataFe.sortByTimeAsc
    } else if (property == 'time' && !asc) {
      return FileDataFe.sortByTimeDesc
    }
  }

  public static fromBlob(name: string): FileDataFe {
    let file = new FileDataFe(null, false)
    file.label = name
    return file
  }

  getUploaderName() {
    return `${this.uploaderFirstName} ${this.uploaderLastName}`
  }

  async loadFile(
    stateService: StateServiceFe,
    domSanitizer: DomSanitizer,
    tableStorageService: TableStorageServiceFe | null,
    backendService: RouterFe,
    saveFile = false
  ): Promise<void> {
    if (!this.hasContent()) {
      this.loadingFileInProgress = true
      if (this.questionType == QUESTION_TYPE.ATTACHMENT) {
        let content = await stateService.downloadAttachmentFile(stateService.activeWorkspace.companyId, this.id)
        this.addBlobContent(content, domSanitizer, saveFile)
      } else if (this.questionType == QUESTION_TYPE.DATA_TABLE) {
        let content = await stateService.downloadDataTableFile(stateService.activeWorkspace.companyId, this.id)
        this.addBlobContent(content, domSanitizer, saveFile)
        this.dataGridService = new TabFileDataGridIntergationService(new TableDataFe())
        this.dataGridService.mode = DataGridTableMode.VIEW
        await tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
          this.dataGridService.currentPageData.key = this.id
          this.dataGridService.currentPageData.rows = table.rows
          this.dataGridService.currentPageData.rowCount = table.rows.length
          this.dataGridService.currentPageData.dataSchema = table.dataSchema
          this.dataGridService.observer?.renderNewDataTable()
        })
      } else {
        backendService.downloadExplorerFile(this.label).subscribe(
          (content) => {
            this.addBlobContent(content, domSanitizer, saveFile)
          },
          (error) => {
            this.loadingFileInProgress = false
          }
        )
      }
    }
  }

  private xlsxToCsv(data: any): string {
    const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' })
    const firstSheetName = workbook.SheetNames[0]
    const worksheet: XLSX.WorkSheet = workbook.Sheets[firstSheetName]
    return XLSX.utils.sheet_to_csv(worksheet)
  }

  private csvToXlsx(data: any): ArrayBuffer {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    return excelBuffer
  }

  private replaceExtension(name: string, newExtension: string): string {
    const lastIndex = name.lastIndexOf('.')
    const nameWithoutExtension = lastIndex !== -1 ? name.substring(0, lastIndex) : name
    return `${nameWithoutExtension}.${newExtension}`
  }
}
