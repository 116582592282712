import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractAttributeFe } from './AbstractAttributeFe'

export class GlobalAttributeFe extends AbstractAttributeFe {
  public taxonomyKey: string
  constructor(attr, languageService: LanguageService) {
    super(attr, languageService)
    this.taxonomyKey = attr['taxonomyKey']
  }
}
