import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  SecurityContext,
  SimpleChange,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { ViewEncapsulation } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DataGridIntegrationService } from '../excel-table/importDataGrid.service'
import { MappingModalComponent } from '../modal/mappingModal/mappingModal.component'
import { DataExtractorServiceInterface } from '../service/DataExtractorServiceInterface'
import { createWorker } from 'tesseract.js'
import { DataGridTableData } from '../model/DataGridTableData'
import { DataGridColumnSchema } from '../model/DataGridColumnSchema'
import { base64ToFile } from 'ngx-image-cropper'
import { NgxCaptureService } from 'ngx-capture'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { tap } from 'rxjs'

@Component({
  selector: 'data-extractor',
  templateUrl: './dataExtractor.component.html',
  styleUrls: ['./dataExtractor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataExtractorComponent {
  // imgService= new ImageExtractorService();
  imgFileToBeCropped!: any
  private _c!: ElementRef<HTMLCanvasElement>
  private _text!: ElementRef
  private _wrapper!: ElementRef

  currentCursor = 'grab'
  currentCol!: number
  isDragging = false
  cols: number[] = []
  colsToBeMapped: number[] = []
  isLoading = false
  private ctx!: CanvasRenderingContext2D
  private offsetX: number = 0
  private offsetY: number = 0
  private content!: any
  oldColPos!: number
  isDraggable = false
  croppedImgHeight: any = ''
  dataGrid!: DataGridTableData
  dataGridService!: DataGridIntegrationService
  delBtnIndex: number = -1
  //for image cropping
  croppedImgSrc: any = ''
  cropperPosition: any
  container: any
  isCropped = false
  initialCropperSize = { x1: 0, y1: 0, x2: 200, y2: 300 }

  //for pdf
  pdfBlob: Blob | undefined
  pdfSrc: string = ''
  imgBlob: Blob | undefined
  imgSrc: any = ''

  //for pdf screenshot
  @ViewChild('screen') screen!: ElementRef
  screenshot: SafeUrl = ''
  screenshotSize: any

  viewer: string = 'cropper'
  inProgress = false

  unlistenMouseOver = () => {}
  unlistenMouseUp = () => {}

  @Input() imgExtractor!: DataExtractorServiceInterface

  @ViewChild('text') set text(value: ElementRef) {
    this._text = value
  }

  @ViewChild('contentWrapper') set wrapper(value: ElementRef) {
    this._wrapper = value
  }

  @ViewChild('canvasContent') set c(value: ElementRef<HTMLCanvasElement>) {
    this._c = value
  }

  get text(): ElementRef {
    return this._text
  }

  get wrapper(): ElementRef {
    return this._wrapper
  }

  get c(): ElementRef<HTMLCanvasElement> {
    return this._c
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private modalService: BsModalService,
    private captureService: NgxCaptureService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.unlistenMouseUp = this.renderer.listen(this.el.nativeElement, 'mouseup', (event) => {
      this.onDragEnd(event)
    })

    this.unlistenMouseOver = this.renderer.listen(this.el.nativeElement, 'mousemove', (event) => {
      this.handleMouseMove(event)
    })

    this.renderer.listen(this.el.nativeElement, 'mousedown', (event) => {
      var mouseX = event.clientX - this.c?.nativeElement.getBoundingClientRect().x //get mouse pos wrt to canvas
      var mouseY = event.clientY - this.c?.nativeElement.getBoundingClientRect().y //mouse pos wrt to canvas

      if (this.isCropperViewer()) {
        const image = document.getElementsByClassName('source-image')[0] as HTMLElement
        if (image) {
          const isXInside =
            event.clientX >= image.getBoundingClientRect().left + this.cropperPosition.x1 &&
            event.clientX <= image.getBoundingClientRect().left + this.cropperPosition.x2
          const isYInside =
            event.clientY >= image.getBoundingClientRect().top + this.cropperPosition.y1 &&
            event.clientY <= image.getBoundingClientRect().top + this.cropperPosition.y2
          const buttons = document.getElementsByClassName('action-buttons')[0] as HTMLElement
          if (isXInside && isYInside && buttons) {
            buttons.style.visibility = 'hidden'
          }
        }
      }

      if (this.isDraggable) {
        this.definePath(this.cols[this.currentCol])
        if (this.ctx.isPointInStroke(mouseX, mouseY)) {
          this.isDragging = true
        }
      }
    })

    let pdfBlob = await this.imgExtractor.getPDFBlob()
    if (pdfBlob) {
      this.pdfBlob = pdfBlob
      let reader = new FileReader()
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result
      }
      reader.readAsArrayBuffer(this.pdfBlob)
    } else {
      this.imgFileToBeCropped = await this.imgExtractor.getImageBlob()
      this.imgBlob = this.imgFileToBeCropped
      const url = URL.createObjectURL(this.imgFileToBeCropped)
      this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(url)
    }

    this.viewer = this.imgExtractor.getViewer()
    this.imgExtractor.registerObserver(this)
  }

  setDataGridMode(mode: string) {}

  getExtractedTableData(): DataGridTableData {
    return this.dataGrid
  }

  async setImage() {
    this.isLoading = true
    //to set the col margins

    setTimeout(() => {
      const originalImg = document.getElementById('originalImg')
      if (originalImg) {
        originalImg.setAttribute('src', this.screenshot as string)
        originalImg.style.width = this.screenshotSize.width + 'px'
        originalImg.style.height = this.screenshotSize.height + 'px'
        const croppedImg = document.getElementById('croppedImg')
        this.offsetX = originalImg?.getBoundingClientRect().x! - this.c?.nativeElement.getBoundingClientRect().x //x coordinate of image wrt to canvas
        const buttons = document.getElementsByClassName('action-buttons')[0] as HTMLElement
        const overlay = document.getElementsByClassName('originalImg-overlay')[0] as HTMLElement
        const popover = document.getElementById('popover') as HTMLElement
        const appWrapper = document.getElementById('app-wrapper') as HTMLElement
        if (croppedImg && buttons && overlay) {
          overlay.style.width = originalImg.clientWidth + 'px'
          buttons.style.top = this.cropperPosition.y2 + 'px'
          buttons.style.left = this.cropperPosition.x2 - buttons.clientWidth + 40 + 'px'
          popover.style.top = this.cropperPosition.y1 - 5 + 'px'
          popover.style.left = this.offsetX + this.cropperPosition.x2 + 10 + 'px'
          croppedImg.style.height = this.cropperPosition.y2 - this.cropperPosition.y1 + 'px'
          croppedImg.style.width = this.cropperPosition.x2 - this.cropperPosition.x1 + 'px'
          croppedImg.style.top = this.cropperPosition.y1 + 'px'
          croppedImg.style.left = this.offsetX + this.cropperPosition.x1 + 'px'

          appWrapper.style.height = this.container.height + 'px'
          appWrapper.style.width = this.container.width + 'px'
          this.croppedImgHeight = croppedImg?.offsetHeight
        }
      }
    }, 1000)
    await this.parseData()

    this._createGrid()
    this.isLoading = false
  }

  handleMouseMove(event: any) {
    //if canvas element exists
    if (this.c) {
      // tell the browser we're handling this event
      var mouseX = event.clientX - this.c.nativeElement.getBoundingClientRect().x //mouse pos wrt to canvas
      var mouseY = event.clientY - this.c.nativeElement.getBoundingClientRect().y //mouse pos wrt to canvas

      // var imgX = event.clientX - document.getElementById("originalImg")?.getBoundingClientRect().x! + this.offsetX; //mouse pos wrt to img

      if (!this.isDragging) {
        //is on first col
        if (
          Math.abs(mouseX - this.offsetX - this.cropperPosition.x1) <= 5 &&
          mouseY >= this.cropperPosition.y1 &&
          mouseY <= this.cropperPosition.y2
        ) {
          this.currentCol = 0
          this.isDraggable = true
        } else {
          this.isDraggable = false
          for (var i = 1; i < this.cols.length; i++) {
            this.definePath(this.cols[i])
            if (this.ctx.isPointInStroke(mouseX, mouseY)) {
              this.oldColPos = mouseX
              this.currentCol = i
              this.isDraggable = true
              break
            }
            this.isDraggable = false
          }
        }
      }

      if (this.isDragging) {
        if (this.delBtnIndex > -1) {
          const delBtn = document.getElementsByClassName('delBtn')[this.delBtnIndex] as HTMLElement
          const addBtn = document.getElementsByClassName('addBtn')[this.delBtnIndex] as HTMLElement
          if (delBtn && addBtn) {
            delBtn.style.display = 'none'
            addBtn.style.display = 'none'
          }
        }
        //diff b/w neighbouring col should be >=10px
        const firstColConditions =
          this.currentCol == 0 &&
          (this.cols.length > 1 ? this.cols[1] >= mouseX + 10 : true) &&
          event.clientX >= document.getElementById('croppedImg')?.getBoundingClientRect().left!
        const middleColConditions =
          (this.currentCol == 1
            ? mouseX >= this.offsetX + this.cropperPosition.x1 + 10
            : mouseX >= this.cols[this.currentCol - 1] + 10) &&
          this.currentCol != 0 &&
          this.currentCol != this.cols.length - 1 &&
          (this.cols.length > this.currentCol + 1 ? this.cols[this.currentCol + 1] >= mouseX + 10 : true)
        const lastColConditions =
          this.currentCol == this.cols.length - 1 &&
          (this.currentCol == 1
            ? mouseX >= this.offsetX + this.cropperPosition.x1 + 10
            : mouseX >= this.cols[this.currentCol - 1] + 10) &&
          event.clientX <= document.getElementById('croppedImg')?.getBoundingClientRect().right!
        if (
          this.cols.length == 1
            ? firstColConditions && lastColConditions
            : firstColConditions || middleColConditions || lastColConditions
        ) {
          //remove present one
          this.oldColPos = mouseX
          this.cols[this.currentCol] = mouseX
          this.colsToBeMapped[this.currentCol] = mouseX - this.offsetX - this.cropperPosition.x1
          this._createGrid()
        }
      } else {
        if (this.isDraggable) {
          if (this.currentCol == 0) {
            this.cols.forEach((col, index) => {
              let addBtn = document.getElementById('addBtn' + index)
              let delBtn = document.getElementById('delBtn' + index)
              if (addBtn && index != this.currentCol) {
                addBtn.style.top = this.cropperPosition.y1 + 'px'
                addBtn.style.left = col + 'px'
                addBtn.style.display = 'block'
                if (delBtn && this.cols.length > 1) {
                  delBtn.style.top = this.cropperPosition.y1 + 25 + 'px'
                  delBtn.style.left = col + 'px'
                  delBtn.style.display = 'block'
                }
              }
            })

            this.delBtnIndex = this.currentCol
            let btnPos = this.offsetX + this.cropperPosition.x1
            const addBtn = document.getElementById('addBtn0')
            if (addBtn) {
              addBtn.style.top = this.cropperPosition.y1 + 'px'
              addBtn.style.left = btnPos + 'px'
              addBtn.style.display = 'block'
            }
          } else {
            this.delBtnIndex = this.currentCol
            let btnPos
            if (mouseX < 20) {
              btnPos = this.cols[this.currentCol]
            } else {
              btnPos = this.cols[this.currentCol]
            }
            const delBtn = document.getElementById('delBtn' + this.currentCol)
            const addBtn = document.getElementById('addBtn' + this.currentCol)
            if (addBtn) {
              addBtn.style.top = this.cropperPosition.y1 + 'px'
              addBtn.style.left = btnPos + 'px'
              addBtn.style.display = 'block'
              if (delBtn && this.cols.length > 1) {
                delBtn.style.top = this.cropperPosition.y1 + 25 + 'px'
                delBtn.style.left = btnPos + 'px'
                delBtn.style.display = 'block'
              }
            }
          }
        } else if (!this.isDraggable && this.delBtnIndex > -1) {
          const delBtn = document.getElementsByClassName('delBtn')[this.delBtnIndex] as HTMLElement
          const addBtn = document.getElementsByClassName('addBtn')[this.delBtnIndex] as HTMLElement
          if (
            (mouseX - this.cols[this.delBtnIndex] > 10 || this.cols[this.delBtnIndex] > mouseX) &&
            this.currentCol != 0
          ) {
            addBtn.style.display = 'none'
            if (this.cols.length > 1) {
              delBtn.style.display = 'none' //hide delete button
            }
          } else if (
            (mouseX - this.offsetX - this.cropperPosition.x1 > 10 || this.offsetX + this.cropperPosition.x1 > mouseX) &&
            this.currentCol == 0
          ) {
            ;(Array.from(document.getElementsByClassName('addBtn')) as HTMLElement[]).forEach((btn) => {
              btn.style.display = 'none'
            })
            ;(Array.from(document.getElementsByClassName('delBtn')) as HTMLElement[]).forEach((btn) => {
              btn.style.display = 'none'
            })
          }
        }
      }
    }
  }

  onDragEnd(event: DragEvent) {
    if (this.isDragging) {
      this.isDraggable = false
      this.isDragging = false
    }
  }

  definePath(x: number) {
    this.ctx.beginPath()
    this.ctx.moveTo(x, this.cropperPosition.y1)
    this.ctx.lineTo(x, this.cropperPosition.y1 + this.croppedImgHeight)
  }

  async parseData() {
    const worker = await createWorker()

    await worker.load()
    await worker.loadLanguage('eng')
    await worker.initialize('eng')

    this.content = await worker.recognize(this.croppedImgSrc)
    let txt = ''
    const firstLine = this.content.data.lines[0]
    var space = 1
    let values: any[] = []
    let value = ''
    let isSameCol = -2
    this.cols = []
    this.colsToBeMapped = []
    for (let word of firstLine.words) {
      this.cols.push(this.offsetX + word.bbox.x0 + this.cropperPosition.x1)
      this.colsToBeMapped.push(word.bbox.x0)
      //giving blank spaces
      for (isSameCol = -2; space <= word.bbox.x0; space += 5, isSameCol++) {
        txt = `${txt} `
        if (isSameCol < 0) {
          //considering more than 2 spaces as another column's data
          value = `${value} `
        }
      }
      txt += word.text
      if (isSameCol >= 0 || word == firstLine.words[firstLine.words.length - 1]) {
        //has more than 2 spaces
        values.push(value)
        value = ''
      }
      value += word.text
      space = word.bbox.x1
    }
    values.push(value) // push the last word
    //generating next line
    txt += '\n'
  }

  async extractData() {
    this.dataGrid = new DataGridTableData()
    let values = []
    for (let line of this.content.data.lines) {
      values = []
      for (let i = 0; i < this.colsToBeMapped.length; i++) {
        let text = ''
        for (let word of line.words) {
          if (
            word.bbox.x0 >= this.colsToBeMapped[i] &&
            word.bbox.x1 <= (this.colsToBeMapped[i + 1] ? this.colsToBeMapped[i + 1] : word.bbox.x1)
          ) {
            text = `${text} ${word.text}`
          }
        }
        values.push(text)
      }
      if (line == this.content.data.lines[0]) {
        values.forEach((val) => {
          const col: DataGridColumnSchema = {
            name: val,
            label: val,
            mode: 'NULLABLE',
            type: 'STRING',
            status: '',
            width: '10rem',
            deployed: false,
            modified: true,
            isCalculated: false
          }
          this.dataGrid.dataSchema ? this.dataGrid.dataSchema.push(col) : (this.dataGrid.dataSchema = [col])
        })
      } else {
        this.dataGrid.rows.push({
          rowId: '',
          values,
          status: 'STORED',
          ordinal: -1
        })
      }
    }
    this.dataGrid.rowCount = this.dataGrid.rows.length
  }

  _createGrid(): void {
    this.ctx = this.c.nativeElement.getContext('2d')!
    const c = this.ctx.canvas
    const originalImg = document.getElementById('originalImg') as HTMLElement
    if (originalImg) {
      c.width = originalImg.clientWidth
      c.height = originalImg.clientHeight + 5
    } else {
      c.width = this.wrapper.nativeElement.clientWidth
      c.height = this.wrapper.nativeElement.clientHeight
    }

    this.ctx.translate(0.5, 0.5)
    this.ctx.clearRect(0, 0, c.width, c.height)
    // X-axis positive
    for (let i = 1; i < this.cols.length; i++ && this.cols[i] < this.cropperPosition.x2 - 10) {
      this.ctx.beginPath()
      const initialY = this.cropperPosition.y1
      const finalY = this.cropperPosition.y1 + this.croppedImgHeight
      this.ctx.moveTo(this.cols[i], initialY)
      this.ctx.lineTo(this.cols[i], finalY)
      this.ctx.strokeStyle = '#405369'
      this.ctx.lineWidth = 2
      this.ctx.stroke()
    }
  }

  addNewColumn(): void {
    const lastColPos = this.cols[this.cols.length - 1]
    const lastColToBeMappedPos = this.colsToBeMapped[this.colsToBeMapped.length - 1]
    this.definePath(lastColPos + 20)
    this.ctx.lineWidth = 2
    this.ctx.stroke()
    this.cols.push(lastColPos + 20)
    this.colsToBeMapped.push(lastColToBeMappedPos + 20)
  }

  delCol() {
    this.cols.splice(this.currentCol, 1)
    this.colsToBeMapped.splice(this.currentCol, 1)
    this._createGrid()
    if (this.delBtnIndex > -1) {
      const delBtn = document.getElementsByClassName('delBtn')[this.delBtnIndex] as HTMLElement
      const addBtn = document.getElementsByClassName('addBtn')[this.delBtnIndex] as HTMLElement
      addBtn.style.display = 'none'
      delBtn.style.display = 'none'
    }
  }

  async mapToTaxonomy() {
    await this.extractData()

    const initialState = {
      extractedColNames: this.dataGrid.dataSchema!.map((col, index) => {
        return {
          label: col.label!,
          mappedColIndex: index
        }
      }),
      taxonomyEntities: await this.imgExtractor.getTaxonomyEntities(),
      imgExtractor: this.imgExtractor,
      extractedRows: this.dataGrid.rows,
      isExtractingFromImg: true,
      fileName: await this.imgExtractor.getFileName(),
      fileType: this.pdfSrc ? 'pdf' : 'img'
    }
    this.modalService.show(MappingModalComponent, { initialState })
  }

  setCroppedImage(event: any) {
    this.croppedImgSrc = event.base64
    this.cropperPosition = event.cropperPosition
    const containerElement = document.getElementsByTagName('image-cropper')[0] as HTMLElement
    this.container = { width: containerElement.clientWidth, height: containerElement.clientHeight }

    const buttons = document.getElementsByClassName('action-buttons')[0] as HTMLElement
    if (buttons) {
      buttons.style.visibility = 'visible'
      buttons.style.top = event.cropperPosition.y2 + 'px'
      buttons.style.left = event.cropperPosition.x2 - buttons.clientWidth + 20 + 'px'
    }
    this.isCropped = false
  }

  async convertCroppedToText() {
    const screenshot = document.getElementsByClassName('ngx-ic-source-image')[0] as HTMLElement
    this.screenshotSize = { height: screenshot.clientHeight, width: screenshot.clientWidth }
    this.isCropped = true
    this.setImage()
    this.imgExtractor.setExtractorViewer()
  }

  async convertToImg() {
    this.inProgress = true
    this.captureService
      .getImage(this.screen.nativeElement, true)
      .pipe(
        tap((img) => {
          this.screenshot = img
          this.imgFileToBeCropped = base64ToFile(this.screenshot as string)
          this.inProgress = false
          this.imgExtractor.setCropperViewer()
        })
      )
      .subscribe()
  }

  cropperLoaded() {
    setTimeout(() => {
      this.initialCropperSize = { x1: 0, y1: 0, x2: 200, y2: 300 }
    }, 2)
  }

  isMainViewer() {
    return this.imgExtractor.getViewer() == 'main'
  }

  isCropperViewer() {
    return this.imgExtractor.getViewer() == 'cropper'
  }

  isExtractorViewer() {
    return this.imgExtractor.getViewer() == 'extractor'
  }

  ngOnDestroy() {
    this.unlistenMouseOver()
    this.unlistenMouseUp()
  }
}
