import { CompanyFe } from './CompanyFe'
import { PartnerInvitationFe } from './PartnerInvitationFe'

export class PartnerInvitationnExistedUserCompaniesFe {
  invitation: PartnerInvitationFe
  existingUserCompanies: CompanyFe[]

  constructor(invitation: PartnerInvitationFe, existingUserCompanies: CompanyFe[]) {
    this.invitation = invitation
    this.existingUserCompanies = existingUserCompanies
  }

  public static fromTransfer(trasnfer: any) {
    let companies = trasnfer.existingUserCompanies.map((company) => CompanyFe.fromTransfer(company))
    let invitation = new PartnerInvitationnExistedUserCompaniesFe(
      PartnerInvitationFe.fromTransfer(trasnfer.invitation),
      companies
    )
    return invitation
  }
}
