import { AbstractProjectInfoFe } from './AbstractProjectInfoFe'
import { PROJECT_TYPE } from './ProjectTypeFe'

export class CCProjectInfoFe extends AbstractProjectInfoFe {
  constructor(
    public id: string,
    public name: string,
    public draft: boolean,
    public emissionCategoryKeys: string[],
    public scope1Emission: number,
    public scope2Emission: number,
    public scope3Emission: number,
    public relatedRequestIds: string[],
    public noOfDataEntries: number,
    public createdAt: string
  ) {
    super(id, PROJECT_TYPE.CC, name, draft, createdAt)
  }

  public static fromTransfer(transfer: any): CCProjectInfoFe {
    // TODO: add extra fields
    let cc = new CCProjectInfoFe(
      transfer.id,
      transfer.name,
      transfer.draft,
      transfer.emissionCategoryKeys,
      0,
      0,
      0,
      [],
      0,
      transfer.createdAt
    )
    return cc
  }
}
