export class ReminderScheduleFe {
  type: string
  unit: string
  value: number
  preset: string

  constructor(type: string, unit: string, value: number, preset: string) {
    this.type = type
    this.unit = unit
    this.value = value
    this.preset = preset
  }

  public static fromTransfer(transfer: any): ReminderScheduleFe {
    let occurence = new ReminderScheduleFe(transfer.type, transfer.unit, transfer.value, transfer.preset)
    return occurence
  }

  toJSON(): any {
    const { ...data } = this
    return data
  }
}
