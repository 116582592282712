import { DOCUMENT } from '@angular/common'
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { View } from 'src/app/model/datahub/View'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { PROJECTS_PAGES } from '../../projects.component'
import { CSRDProjectDefinition, CSRDProjectInfoFe } from 'src/app/model/project/CSRDProjectInfoFe'
import { CSRDProjectInfoAddFe } from 'src/app/model/project/add/CSRDProjectInfoAddFe'
import COUNTRIES_DATA from 'src/app/model/COUNTRIES.json'
import _ from 'lodash'
import moment from 'moment'

const enum CSRD_SETUP_FORMS {
  LEARN = 'LEARN',
  DEFINE = 'DEFINE',
  COMPANY_INFO = 'COMPANY_INFO'
}

@Component({
  selector: 'create-new-csrd-project',
  templateUrl: './create-new-csrd-project.component.html',
  styleUrls: ['./create-new-csrd-project.component.scss']
})
export class CreateNewCSRDProjectComponent extends AbstractLanguageComponent implements OnInit {
  @Input() activeProjectsPage: string
  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>()
  @Output() viewNewCsrdProject = new EventEmitter<CSRDProjectInfoFe>()
  @ViewChild('addProjectModal') addProjectModal: TemplateRef<any>
  @ViewChild('cancelNewProjectModal') cancelNewProjectModal: TemplateRef<any>
  @ViewChild('saveDraft') saveDraft: TemplateRef<any>
  @ViewChild('saveAsDraft') saveAsDraft: TemplateRef<any>
  @ViewChild('exportTableModal') exportTableModal: TemplateRef<any>
  @ViewChild('removeLevel') removeLevel: TemplateRef<any>

  // Form controls
  yearControls: FormGroup

  //Disable Dissagregation levels
  dissagregationLevelsDisabled = false

  loadingInProgress: boolean

  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingData: boolean = false

  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  inProgress = true
  cardFooter: boolean = true
  cardHeader: boolean = true
  projCSRD: boolean = true
  savingAsDraft: boolean = false

  reportingYearInput: string
  nameInput: string
  projectNameUpdated: boolean = false
  firstReportingYearInput: string
  companyNameInput: string
  companyHQCountryInput: string = ''
  companyHQTownInput: string
  companyNoEmployeesInput: string = ''
  countries = Object.entries(COUNTRIES_DATA)
  _activeCsrdProject: CSRDProjectInfoFe

  hasError = {
    reportingYear: false,
    name: false,
    firstReportingYear: false,
    companyName: false,
    companyHQCountry: false,
    companyHQTown: false,
    companyNoEmployees: false
  }

  errorMsg = {
    reportingYear: '',
    name: '',
    firstReportingYear: '',
    companyName: '',
    companyHQCountry: '',
    companyHQTown: '',
    companyNoEmployees: ''
  }

  showErrors: boolean = false

  pageToolbar = [
    [
      {
        shortLabel: this.slocale('All projects'),
        longLabel: this.slocale('All projects'),
        tooltip: this.slocale('All projects'),
        icon: 'la la-project-diagram',
        visible: () => true,
        actionName: 'all_projects'
      }
    ]
  ]

  stepsCSRD = [
    { title: this.slocale('Learn about CSRD module'), completed: false },
    { title: this.slocale('Define project'), completed: false },
    { title: this.slocale('Add company info'), completed: false }
  ]

  activeCSRDSetupForm = CSRD_SETUP_FORMS.LEARN

  @Input()
  set activeCsrdProject(project: CSRDProjectInfoFe) {
    this._activeCsrdProject = project
    this.reportingYearInput = project?.definition?.reportingYear
    this.nameInput = project?.name
    this.firstReportingYearInput = project?.definition?.firstReportingYear
    this.companyNameInput = project?.definition?.company.name
    this.companyHQCountryInput = project?.definition?.company?.hq?.country
    this.companyHQTownInput = project?.definition?.company?.hq?.town
    this.companyNoEmployeesInput = project?.definition?.company?.noEmployees

    this.yearControls.patchValue(
      {
        reportingYear: this.reportingYearInput,
        firstReportingYear: this.firstReportingYearInput
      },
      { emitEvent: false }
    )
  }

  @Input() csrdProjects: CSRDProjectInfoFe[]

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService)

    this.yearControls = this.fb.group({
      reportingYear: [
        '',
        [Validators.required, Validators.min(2000), Validators.max(2100), Validators.pattern('^[0-9]{4}$')]
      ],
      firstReportingYear: [
        '',
        [Validators.required, Validators.min(2000), Validators.max(2100), Validators.pattern('^[0-9]{4}$')]
      ]
    })

    this.yearControls.valueChanges.subscribe((values) => {
      if (values.reportingYear) {
        this.reportingYearInput = values.reportingYear
        if (!this.projectNameUpdated) {
          this.nameInput = `CSRD ${values.reportingYear}`
        }
      }
      if (values.firstReportingYear) {
        this.firstReportingYearInput = values.firstReportingYear
      }
    })

    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {}

  toggleTips() {
    this.displayService.toggleTips()
  }

  openModal(templateRef: TemplateRef<any>, size: string = '') {
    this.modalService.show(templateRef, { class: size })
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'all_projects':
        this.activeFormNo == 1 || this.activeFormNo == 2 || this.activeFormNo == 3
          ? this.startCancelNewProject()
          : this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
        break
      case 'export_table':
        this.openModal(this.exportTableModal, 'modal-md')
        break
    }
  }

  update() {
    this.circles = this._document.querySelectorAll('.circle1')

    if (this.circles) {
      this.circles.forEach((circle: any, index: number) => {
        if (index < this.currentActive) {
          circle.classList.add('active-circle1')
        } else {
          circle.classList.remove('active-circle1')
        }
      })
    }

    if (this.progress) {
      const actives = this._document.querySelectorAll('.active-circle1')
      const width = ((actives.length - 1) / (this.circles.length - 1)) * 100
      this.progress.style.width = `${width}%`
    }
  }

  next() {
    if (this.activeFormNo > 1) {
      if (this.yearControls.invalid) {
        this.yearControls.markAllAsTouched()
        this.showErrors = true
        return
      }

      const hasErrors = this.checkInputs()
      if (hasErrors) {
        this.showErrors = true
        return
      }
    }

    this.currentActive++
    if (this.currentActive > this.stepsCSRD.length) {
      this.currentActive = this.stepsCSRD.length
    }

    this.activeFormNo++

    if (this.activeFormNo > 1 && this.activeFormNo <= this.stepsCSRD.length) {
      this.stepsCSRD[this.activeFormNo - 2].completed = true
    }

    this.update()

    this.showErrors = false
    this.resetErrorObject()
  }

  prev(): void {
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  closeModal() {
    this.modalService.hide()
  }

  saveDraftModal(saveDraft: boolean) {
    this.modalService.show(this.saveDraft, { class: 'modal-md' })
  }

  saveProject() {
    this.modalService.hide()
  }

  startCancelNewProject() {
    this.modalService.show(this.cancelNewProjectModal, { class: 'modal-md' })
  }

  confirmCancelNewProject() {
    this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
    this.modalService.hide()
  }

  goToPrevCSRDSetupForm() {
    this.prev()
  }

  goToNextCSRDSetupForm() {
    if (this.activeFormNo === 2) {
      if (this.yearControls.invalid) {
        this.yearControls.markAllAsTouched()
        this.showErrors = true
        return
      }
    }

    const hasErrors = this.checkInputs()
    if (hasErrors) {
      this.showErrors = true
      return
    }

    this.next()
  }

  preventInvalidInput(event: KeyboardEvent) {
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault()
    }
  }

  reportingYearChanged() {
    if (this.yearControls.get('reportingYear')?.invalid) {
      this.hasError['reportingYear'] = true
      this.errorMsg.reportingYear = this.slocale('Please enter a valid year between 2000 and 2100')
    } else {
      this.hasError['reportingYear'] = false
      this.errorMsg.reportingYear = ''
    }

    if (!this.projectNameUpdated && this.reportingYearInput != null) {
      this.nameInput = `CSRD ${this.reportingYearInput}`
    } else {
      this.nameInput = ''
      this.checkInputs()
    }
  }

  projectNameChanged() {
    this.projectNameUpdated = true
    this.checkInputs()
  }

  resetErrorObject() {
    this.hasError = {
      reportingYear: false,
      name: false,
      firstReportingYear: false,
      companyName: false,
      companyHQCountry: false,
      companyHQTown: false,
      companyNoEmployees: false
    }

    this.errorMsg = {
      reportingYear: '',
      name: '',
      firstReportingYear: '',
      companyName: '',
      companyHQCountry: '',
      companyHQTown: '',
      companyNoEmployees: ''
    }
  }

  checkInputs() {
    this.resetErrorObject()
    let hasErrors = false

    switch (this.activeFormNo) {
      case 2:
        if (this.yearControls.get('reportingYear')?.invalid) {
          hasErrors = true
          this.hasError['reportingYear'] = true
          this.errorMsg['reportingYear'] = this.slocale('Reporting year should be 4 numbers and start with 20XX')
        }

        if (this.yearControls.get('firstReportingYear')?.invalid) {
          hasErrors = true
          this.hasError['firstReportingYear'] = true
          this.errorMsg['firstReportingYear'] = this.slocale(
            'First reporting year should be 4 numbers and start with 20XX'
          )
        }

        if (_.isEmpty(this.nameInput)) {
          hasErrors = true
          this.hasError['name'] = true
          this.errorMsg['name'] = this.slocale('Project name is required')
        }

        if (!_.isEmpty(this.nameInput)) {
          const duplicateName = this.csrdProjects?.some(
            (project) =>
              project.name.toLowerCase().trim() === this.nameInput.toLowerCase().trim() &&
              project.id !== this._activeCsrdProject?.id
          )

          if (duplicateName) {
            hasErrors = true
            this.hasError['name'] = true
            this.errorMsg['name'] = this.slocale(
              'A project with that name already exists. Please choose a different name'
            )
          }
        }
        break

      case 3:
        if (_.isEmpty(this.companyNameInput)) {
          hasErrors = true
          this.hasError['companyName'] = true
          this.errorMsg['companyName'] = this.slocale('Company name is required')
        }

        if (_.isEmpty(this.companyHQCountryInput)) {
          hasErrors = true
          this.hasError['companyHQCountry'] = true
          this.errorMsg['companyHQCountry'] = this.slocale('Country is required')
        }

        if (_.isEmpty(this.companyHQTownInput)) {
          hasErrors = true
          this.hasError['companyHQTown'] = true
          this.errorMsg['companyHQTown'] = this.slocale('Town is required')
        }

        if (_.isEmpty(this.companyNoEmployeesInput)) {
          hasErrors = true
          this.hasError['companyNoEmployees'] = true
          this.errorMsg['companyNoEmployees'] = this.slocale('Number of employees is required')
        }
        break
    }

    return hasErrors
  }

  firstReportingYearInputChanged(evt) {
    if (evt.key.toLowerCase() == 'e') {
      evt.preventDefault()
    }
    this.checkInputs()
  }

  async finishCSRDSetup(draft = false) {
    const hasErrors = this.checkInputs()
    if (hasErrors) {
      this.showErrors = true
      return
    }

    this.loadingInProgress = true
    const name = this.nameInput
    const definition: CSRDProjectDefinition = {
      isStarted: false,
      reportingYear: this.reportingYearInput,
      firstReportingYear: this.firstReportingYearInput,
      company: {
        name: this.companyNameInput,
        noEmployees: this.companyNoEmployeesInput,
        hq: {
          country: this.companyHQCountryInput,
          town: this.companyHQTownInput
        }
      },
      csrdSettings: this._activeCsrdProject?.definition.csrdSettings || []
    }

    let csrdProject
    const createdAt = moment().toISOString()
    if (_.isEmpty(this._activeCsrdProject?.id)) {
      csrdProject = new CSRDProjectInfoAddFe(name, draft, definition, createdAt)
      csrdProject = await this.stateService.createNewProject(csrdProject)
    } else {
      csrdProject = new CSRDProjectInfoFe({
        id: this._activeCsrdProject.id,
        name,
        draft,
        definition,
        createdAt
      })
      csrdProject = await this.stateService.updateCsrdProject(csrdProject)
    }

    this.loadingInProgress = false
    if (!draft) {
      this.viewNewCsrdProject.emit(csrdProject)
    } else {
      this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
    }

    if (draft) {
      this.closeModal()
    }
  }

  isNextButtonDisabled(): boolean {
    return this.activeFormNo === 2 && (this.yearControls.invalid || !this.nameInput)
  }

  isFinishSetupButtonDisabled(): boolean {
    return (
      !this.companyNameInput || !this.companyHQCountryInput || !this.companyHQTownInput || !this.companyNoEmployeesInput
    )
  }

  resolveCountryName(locales) {
    if (!locales) {
      return ''
    }
    return locales[this.activeLanguage?.code] || locales['en'] || locales
  }

  startSaveAsDraft() {
    this.modalService.show(this.saveAsDraft, { class: 'modal-md' })
  }
}
