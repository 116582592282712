import { Component, OnInit } from '@angular/core'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { ContactInvitationFe } from 'src/app/model/data-suppliers/company/ContactInvitationFe'
import { ContactInvitation_AddFe } from 'src/app/model/data-suppliers/company/ContactInvitation_AddFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import COUNTRIES_DATA from 'src/app/model/COUNTRIES.json'
import { DateUtil } from 'src/app/utils/DateUtil'
import { RequestFe, REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe'
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe'
import { CompanyFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { SupplierCompanyFe } from 'src/app/model/data-suppliers/company/SupplierCompanyFe'
import { ViewFe } from 'src/app/model/subject/ViewFe'

@Component({
  selector: 'network-details',
  templateUrl: './details.component.html',
  styleUrls: ['../details/details.component.scss', './details.component.scss']
})
export class NetworkDetailsComponent extends AbstractLanguageComponent implements OnInit {
  contact: ContactFe
  primaryPerson: { name: string; email: string }
  viewNo: number = 1
  pendingRequests: RequestFe[] = []
  inProgressRequests: RequestFe[] = []
  completedRequests: RequestFe[] = []
  requestGroups: RequestGroupFe[] = []
  company: CompanyFe | SupplierCompanyFe

  constructor(
    private displayService: DisplayServiceFe,
    public stateService: StateServiceFe,
    languageService: LanguageService
  ) {
    super(languageService)
  }

  async ngOnInit() {
    if (this.displayService.detailsData) {
      let data = this.displayService.detailsData.data
      if (data instanceof ContactFe) {
        this.contact = data
      } else if (data instanceof CompanyFe || data instanceof SupplierCompanyFe) {
        this.company = data
      }
    }
    this.displayService.detailsDataUpdatedSubject.subscribe((isUpdated) => {
      if (isUpdated) {
        const data = this.displayService.detailsData
        if (data.data instanceof ContactFe) {
          this.contact = data.data
        }
      }
    })

    await this.renderNewData()
  }

  async renderNewData() {
    if (this.contact) {
      let requests = this.contact.dataRequests
      this.pendingRequests = requests.filter((request) => request.status == REQUEST_TASK_STATUS.CREATED)
      this.inProgressRequests = requests.filter((request) => request.status == REQUEST_TASK_STATUS.IN_PROGRESS)
      this.completedRequests = requests.filter(
        (request) => request.status == REQUEST_TASK_STATUS.CLOSED || request.status == REQUEST_TASK_STATUS.APPROVED
      )
      this.requestGroups = await this.stateService.getRequestGroups()
    }
  }

  getDeadline(request: RequestFe) {
    let rg = this.getRequestGroup(request)
    if (rg) {
      return rg.getDeadlineClass()
    }
  }

  resolveLabel(label: any): string {
    if (!label) {
      return ''
    }

    return label[this.activeLanguage?.code] || label['en'] || label
  }

  getCountry(code: string) {
    let data = Object.entries(COUNTRIES_DATA).find((data) => data[0] == code)
    return this.resolveLabel(data[1])
  }

  getRequestGroup(request: RequestFe) {
    let rg = this.requestGroups.find((g) => g.id == request.requestGroupId)
    return rg
  }

  openDashboard(dashboard: DashboardFe) {
    this.stateService.selectDashboard(dashboard)
  }

  openCreateRequest() {
    this.stateService.setView(ViewFe.CREATE_REQUEST)
  }
}
