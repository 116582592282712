import { MathStatementFe } from './MathStatementFe'
import { EmissionFactorStatementFe } from './EmissionFactorStatementFe'
import { TwoOperandStatementFe } from './TwoOperandStatementFe'
import { ValueStatementFe } from './ValueStatementFe'
import { VariableStatementFe } from './VariableStatementFe'
import { OperatorFe } from './OperatorFe'

export class StatementBuilderFe {
  public static build(jsonStr): MathStatementFe {
    let type: string = jsonStr['type']
    switch (type) {
      case MathStatementFe.TYPE_KEY:
        return new MathStatementFe()
      case ValueStatementFe.TYPE_KEY:
        return this.buildValueStatement(jsonStr)
      case EmissionFactorStatementFe.TYPE_KEY:
        return this.buildEmissionFactorStatement(jsonStr)
      case VariableStatementFe.TYPE_KEY:
        return this.buildVariableStatement(jsonStr)
      case TwoOperandStatementFe.TYPE_KEY:
        return this.buildTwoOperandStatement(jsonStr)
    }
  }

  public static buildValueStatement(jsonStr): ValueStatementFe {
    let unit = jsonStr['unit']
    let value = jsonStr['value']
    return new ValueStatementFe(unit, value)
  }

  public static buildEmissionFactorStatement(jsonStr): EmissionFactorStatementFe {
    let databaseInfo = jsonStr['databaseInfo']
    let emissionData = jsonStr['emissionData']
    let data = { databaseInfo: databaseInfo, emissionData: emissionData }
    return new EmissionFactorStatementFe(data)
  }

  public static buildVariableStatement(jsonStr): VariableStatementFe {
    let unit = jsonStr['unit']
    let entityKey = jsonStr['entityKey']
    let entityLabel = jsonStr['entityLabel']
    let attributeKey = jsonStr['attributeKey']
    let attributeLabel = jsonStr['attributeLabel']
    return new VariableStatementFe(unit, entityKey, entityLabel, attributeKey, attributeLabel)
  }

  public static buildTwoOperandStatement(jsonStr): TwoOperandStatementFe {
    let leftStatementStr = jsonStr['leftStatement']
    let leftStatement = StatementBuilderFe.build(leftStatementStr)
    let rightStatementStr = jsonStr['rightStatement']
    let rightStatement = StatementBuilderFe.build(rightStatementStr)
    let operatorStr = jsonStr['operator']
    let operatorKey = operatorStr['key']
    let statement = new TwoOperandStatementFe()
    statement.leftStatement = leftStatement
    statement.rightStatement = rightStatement
    statement.operator = OperatorFe.opByKey(operatorKey)
    return statement
  }
}
