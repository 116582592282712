import { NotificationFe } from './NotificationFe'

export class NotificationOverviewFe {
  count: number
  notifications: NotificationFe[]

  constructor(count: number, notificatons: NotificationFe[]) {
    this.count = count
    this.notifications = notificatons
  }

  public static fromTransfer(transfer: any): NotificationOverviewFe {
    let count = transfer.count
    let notifications = transfer.notifications.map((n) => NotificationFe.fromTransfer(n))
    let overview = new NotificationOverviewFe(count, notifications)
    return overview
  }
}
