import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractActivityFe } from './AbstractActivityFe'
import { AcceptSubmissionActivityFe } from './AcceptSubmissionActivityFe'
import { ActivityStatusFe } from './ActivityStatusFe'
import { ActivityTypeFe } from './ActivityTypeFe'
import { AnswerFe } from './answer/AnswerFe'
import { AnswerTypeConversionFe } from './answer/AnswerTypeConversionFe'
import { RejectSubmissionActivityFe } from './RejectSubmissionActivityFe'
import { Answer_AddFe } from './add/answer/Answer_AddFe'

export class SubmitAnswersActivityFe extends AbstractActivityFe {
  answers: AnswerFe[]
  icon = 'las la-envelope-open'

  status = ActivityStatusFe.SUBMITTED

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    answers: AnswerFe[],
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.SUBMIT_ANSWERS,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,
      requestId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.answers = answers
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.answers_submitted`
    this.homePageLocaleKeyDO = `locale_key.pages.data_request.activity.answers_submitted`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.answers_submitted`
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): SubmitAnswersActivityFe {
    let activity = new SubmitAnswersActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      transfer.answers.map((a) => AnswerTypeConversionFe.fromTransfer(a)),
      languageService
    )
    return activity
  }

  public homePageTextDO(): string {
    if (this.draft) {
      return this.locale('locale_key.pages.data_request.activity.saved_draft')
    } else {
      return this.locale(this.homePageLocaleKeyDO)
    }
  }

  public activityHistoryText(): string {
    return this.draft
      ? this.locale('locale_key.pages.data_request.activity.saved_draft')
      : this.locale(this.activityHistoryLocaleKey)
  }

  public setStatus(activities: AbstractActivityFe[]) {
    let relatedActivity = activities.find((a) => {
      if (a instanceof AcceptSubmissionActivityFe && a.acceptedActivityId == this.id) {
        return true
      } else if (a instanceof RejectSubmissionActivityFe && a.rejectedActivityId == this.id) {
        return true
      }
    })

    if (relatedActivity instanceof AcceptSubmissionActivityFe) {
      this.status = ActivityStatusFe.APPROVED
    } else if (relatedActivity instanceof RejectSubmissionActivityFe) {
      this.status = ActivityStatusFe.REJECTED
    }
  }

  toJSON(): any {
    const { languageService, ...data } = this
    return data
  }
}
