<body class="ml-4">
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
          <h3 class="content-header-title mb-0 d-inline-block">
            {{ locale('locale_key.wizard.account_setting.label') }}
          </h3>
          <div class="row breadcrumbs-top d-inline-block">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a routerLink="/ws/home">{{ locale('locale_key.main_navbar.home') }}</a>
                </li>
                <li class="breadcrumb-item active">{{ locale('locale_key.wizard.account_setting.label') }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="page-account-settings">
          <div class="row">
            <div class="col-md-3 mb-2 mb-md-0">
              <ul class="nav nav-pills flex-column mt-md-0 mt-1">
                <li class="nav-item">
                  <a
                    (click)="displayForm()"
                    [ngClass]="{ active: formOpen }"
                    class="nav-link d-flex"
                    id="account-pill-password"
                    data-bs-toggle="pill"
                    href="#account-vertical-password"
                    aria-expanded="false"
                  >
                    <i class="ft-lock mr-50"></i>
                    {{ locale('locale_key.pages.profile.security.change_password') }}
                  </a>
                </li>
              </ul>
            </div>
            <div *ngIf="formOpen === true" class="col-md-9">
              <div class="card m-0">
                <div class="main-modal-header modal-header">
                  <h4 class="modal-title white">{{ locale('locale_key.pages.profile.security.change_password') }}</h4>
                  <button type="button" class="ml-auto close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="false" class="list-inline white">
                      <a (click)="displayForm()"><i class="las la-times white" style="color: white !important"></i></a>
                    </span>
                  </button>
                </div>

                <div class="modal-body">
                  <div
                    class="tab-pane fade active show"
                    id="account-vertical-password"
                    role="tabpanel"
                    aria-labelledby="account-pill-password"
                    aria-expanded="false"
                  >
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                      <div
                        class="alert-message text-center alert alert-warning alert-dismissible"
                        *ngIf="invalidLogin"
                        role="alert"
                      >
                        <strong>{{ message }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2 mr-0 pr-0 pl-3" for="currentPassword">
                          {{ locale('locale_key.wizard.old_password.message') }}
                        </label>
                        <div class="col-md-10 pr-3">
                          <input
                            type="password"
                            [(ngModel)]="currentPassword"
                            formControlName="currentPassword"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && formFields.currentPassword.errors }"
                            placeholder="Enter Current Password"
                          />
                          <div *ngIf="submitted && formFields.currentPassword.errors" class="invalid-feedback">
                            <div *ngIf="formFields.currentPassword.errors.required">
                              {{ locale('locale_key.wizard.password_is_required.message') }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2 mr-0 pr-0 pl-3" for="password">
                          {{ locale('locale_key.wizard.new_password.message') }}
                        </label>
                        <div class="col-md-10 pr-3">
                          <input
                            type="password"
                            [(ngModel)]="newPassword"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                            formControlName="newPassword"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && formFields.newPassword.errors }"
                            placeholder="Enter New Password"
                          />
                          <div *ngIf="submitted && formFields.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="formFields.newPassword.errors.required">Password is required</div>
                            <div *ngIf="formFields.newPassword.errors.pattern">
                              Must contain at least one number and one uppercase and lowercase letter, and at least 6 or
                              more characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2 mr-0 pr-0 pl-3" for="confirmPassword">
                          {{ locale('locale_key.wizard.retype_password.message') }}
                        </label>
                        <div class="col-md-10 pr-3">
                          <input
                            type="password"
                            [(ngModel)]="confirmPassword"
                            pattern="{{ formFields.newPassword.value }}"
                            formControlName="confirmPassword"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && formFields.confirmPassword.errors }"
                            placeholder="Confirm New Password"
                          />
                          <div *ngIf="submitted && formFields.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="formFields.confirmPassword.errors.required">Password is required</div>
                            <div *ngIf="formFields.confirmPassword.errors.pattern">
                              New Password & Confirm Password does not match.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer m-0 p-0 pt-1">
                        <button
                          type="reset"
                          class="btn btn-danger btn-block custom-button"
                          id="reset-btn"
                          ng-click="reset()"
                        >
                          {{ locale('locale_key.pages.view_template.reset.button') }}
                        </button>
                        <button type="submit" class="btn btn-add custom-button">
                          {{ locale('locale_key.wizard.change.label') }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</body>
