import { ActivityTypeFe } from '../ActivityTypeFe'
import { AnswerFe } from '../answer/AnswerFe'
import { AbstractActivity_AddFe } from './AbstractActivity_AddFe'
import { Answer_AddFe } from './answer/Answer_AddFe'
export class SubmitAnswersActivity_AddFe extends AbstractActivity_AddFe {
  constructor(
    id: string,
    message: string | null,
    draft: boolean,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    public managementCompanyId: string,
    public answers: Answer_AddFe[]
  ) {
    super(id, ActivityTypeFe.SUBMIT_ANSWERS, message, draft, requestTaskId, requestGroupId, requestGroupLabel)
  }
}
