import { Component } from '@angular/core'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { RouteFe } from 'src/app/route/RouteFe'
import { RouteServiceFe } from 'src/app/route/RouteServiceFe'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { CsrdDatapointFe } from '../projects/csrd/model/CsrdDatapointFe'
import { CSRDDataPointSettings } from 'src/app/model/project/CSRDProjectInfoFe'
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe'

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent extends AbstractLanguageComponent {
  activeRoute: RouteFe | undefined
  tabTitle: string

  isDetailsTab = false
  isQuickTipsTab = false
  isConnectedActionsTab = false
  isEFDetailsTab = false
  isCsrdQuestionTab = false
  isCsrdDatapointTab = false

  quickTipsTitleKey: string
  quickTipsDescKey: string

  selectedEf: AbstractEmissionFactorFe
  selectedCsrdQuestion: QuestionFe
  selectedCsrdDatapoint: CsrdDatapointFe
  selectedCsrdDatapointSettings: CSRDDataPointSettings

  constructor(
    private routeService: RouteServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService
  ) {
    super(languageService)
    routeService.activeRouteSubject.subscribe((activeRoute: RouteFe | undefined) => {
      this.activeRoute = activeRoute
      this.postRouteChange()
    })
    this.activeRoute = routeService.activeRoute
    this.postRouteChange()

    displayService.openTipsSubject.subscribe((isOpen) => {
      this.closeAllTabs()
      this.isQuickTipsTab = isOpen
      if (isOpen) {
        this.showTips()
      } else {
        this.openOtherTab()
      }
    })

    displayService.isConnectedActionsTabOpenSubject.subscribe((isOpen) => {
      this.closeAllTabs()
      this.isConnectedActionsTab = isOpen
      if (isOpen) {
        this.showConnectedActions()
      } else {
        this.openOtherTab()
      }
    })

    displayService.isEFDetailsTabOpenSubject.subscribe((ef) => {
      this.closeAllTabs()
      this.isEFDetailsTab = ef ? true : false
      this.selectedEf = ef
      if (this.isEFDetailsTab) {
        this.showEF()
      } else {
        this.openOtherTab()
      }
    })

    displayService.isEFDetailsTabOpenSubject.subscribe((ef) => {
      this.closeAllTabs()
      this.isEFDetailsTab = ef ? true : false
      this.selectedEf = ef
      if (this.isEFDetailsTab) {
        this.showEF()
      } else {
        this.openOtherTab()
      }
    })

    displayService.isCsrdDatapointTabOpenSubject.subscribe(({ datapoint, datapointSettings }) => {
      this.closeAllTabs()
      this.isCsrdDatapointTab = true
      this.selectedCsrdDatapoint = datapoint
      this.selectedCsrdDatapointSettings = datapointSettings
      this.showCsrdDatapoint()
    })

    displayService.isCsrdQuestionTabOpenSubject.subscribe(({ question, datapoint, datapointSettings }) => {
      this.closeAllTabs()
      this.isCsrdQuestionTab = true
      this.selectedCsrdQuestion = question
      this.selectedCsrdDatapoint = datapoint
      this.selectedCsrdDatapointSettings = datapointSettings
      this.showCsrdQuestion()
    })

    const data = this.displayService.quickTipsData
    if (data && data.page == this.activeRoute?.rootPath) {
      if (data.viewMode) {
        this.quickTipsDescKey = `locale_key.general.quick_tips.${data.page}.${data.viewMode}`
        if (this.activeRoute == RoutesFe.ORGANIZATION) {
          this.quickTipsTitleKey = `locale_key.general.quick_tips.${data.page}.${data.viewMode}.title`
        }
      } else {
        this.quickTipsDescKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}`
      }
    }

    displayService.quickTipsDataUpdatedSubject.subscribe((details) => {
      const data = this.displayService.quickTipsData
      if (data && data.page == this.activeRoute?.rootPath) {
        if (data.viewMode) {
          this.quickTipsDescKey = `locale_key.general.quick_tips.${data.page}.${data.viewMode}`
          if (this.activeRoute == RoutesFe.ORGANIZATION) {
            this.quickTipsTitleKey = `locale_key.general.quick_tips.${data.page}.${data.viewMode}.title`
          }
        } else {
          this.quickTipsDescKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}`
        }
      }
    })

    displayService.detailsDataUpdatedSubject.subscribe((dataToBeDisplayed) => {
      this.closeAllTabs()
      this.showDetails()
    })

    displayService.closeDetailsSubject.subscribe((close) => {
      this.closeAllTabs()
      this.isDetailsTab = false
      this.openOtherTab()
    })

    displayService.closeSubject.subscribe(() => {
      this.isCsrdQuestionTab = false
      this.isCsrdDatapointTab = false
      this.closeAllTabs()
    })
  }
  ngOnInit(): void {
    this.postRouteChange()
  }

  postRouteChange() {
    this.closeAllTabs()
    this.isDetailsTab = false
    this.isQuickTipsTab = false
    this.quickTipsTitleKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}.title`
    this.quickTipsDescKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}`
    this.tabTitle = this.locale('locale_key.general.info_message.title')
    if (this.activeRoute == RoutesFe.DO_INBOX || this.activeRoute == RoutesFe.DO_REQUESTS) {
      this.tabTitle = this.locale('locale_key.pages.task_portal.details.task_info.title')
      this.isQuickTipsTab = false
      this.quickTipsTitleKey = `locale_key.general.quick_tips.tasks.title`
      this.quickTipsDescKey = `locale_key.general.quick_tips.tasks`
    } else if (this.activeRoute == RoutesFe.REQUESTS) {
      this.tabTitle = this.locale('locale_key.pages.data_request.details.title')
      this.quickTipsDescKey = `locale_key.general.quick_tips.requests.view_1`
    } else if (this.activeRoute == RoutesFe.DASHBOARD || this.activeRoute == RoutesFe.VISITOR_SHARED_DASHBOARD) {
      this.tabTitle = this.locale('locale_key.pages.dashboard.chart_settings.title')
    } else if (this.activeRoute == RoutesFe.ADMINS) {
      this.tabTitle = 'Details'
    } else if (this.activeRoute == RoutesFe.ADVANCED_KPI || this.activeRoute == RoutesFe.USER_KPI) {
      this.quickTipsDescKey = `locale_key.general.quick_tips.kpi`
      this.quickTipsTitleKey = `locale_key.general.quick_tips.kpi.title`
    } else if (this.activeRoute == RoutesFe.ORGANIZATION) {
      this.quickTipsTitleKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}.view_mode.title`
      this.quickTipsDescKey = `locale_key.general.quick_tips.${this.activeRoute?.rootPath}.view_mode`
    } else if (this.activeRoute == RoutesFe.TAXONOMY) {
      this.quickTipsTitleKey = `locale_key.general.quick_tips.data_category.title`
      this.quickTipsDescKey = `locale_key.general.quick_tips.data_category`
    }
  }

  showTips() {
    this.isQuickTipsTab = true
    this.displayService.openTab = 'QUICK_TIPS'
  }

  closeTips() {
    this.isQuickTipsTab = false
    this.openOtherTab()
  }

  showDetails() {
    this.isDetailsTab = true
    this.displayService.openTab = 'DETAILS'
  }

  closeDetails() {
    this.isDetailsTab = false
    this.openOtherTab()
  }

  showConnectedActions() {
    this.isConnectedActionsTab = true
    this.displayService.openTab = 'CONNECTED_ACTIONS'
  }

  closeConnectedActions() {
    this.isConnectedActionsTab = false
    this.openOtherTab()
  }

  showEF() {
    this.isEFDetailsTab = true
    this.displayService.openTab = 'EF_INFO'
  }

  closeEF() {
    this.isConnectedActionsTab = false
    this.openOtherTab()
  }

  showCsrdQuestion() {
    this.isCsrdQuestionTab = true
    this.displayService.openTab = 'CSRD_QUESTION'
  }

  showCsrdDatapoint() {
    this.isCsrdDatapointTab = true
    this.displayService.openTab = 'CSRD_DATAPOINT'
  }

  isTaskPage() {
    return this.activeRoute == RoutesFe.DO_INBOX || this.activeRoute == RoutesFe.DO_REQUESTS
  }

  closeAllTabs() {
    this.displayService.openTab = null
  }

  openOtherTab() {
    this.closeAllTabs()
    if (this.isQuickTipsTab) {
      this.showTips()
    } else if (this.isDetailsTab) {
      this.showDetails()
    } else if (this.isConnectedActionsTab) {
      this.showConnectedActions()
    } else if (this.isEFDetailsTab) {
      this.showEF()
    }
  }
}
