export abstract class AnswerFe {
  constructor(
    public id: string,
    public sectionId: string,
    public questionId: string,
    public questionType: string,
    public comments: string | null
  ) {
    this.id = id
    this.sectionId = sectionId
    this.questionId = questionId
    ;(this.questionType = questionType), (this.comments = comments)
  }

  public abstract hasAnyValue(): boolean
}
