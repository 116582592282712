export class ContactInvitation_AddFe {
  managementCompanyId: string
  contactCompanyId: string
  contactFirstName: string
  contactLastName: string
  contactEmail: string
  emailTitle: string
  emailBody: string
  uuid: string
  link: string
  emailLanguage: string
  includeEnglish: boolean
  internalContact: boolean

  constructor(
    uuid: string,
    managementCompanyId: string,
    contactCompanyId: string,
    contactFirstName: string,
    contactLastName: string,
    contactEmail: string,
    emailTitle: string,
    emailBody: string,
    link: string,
    emailLanguage: string,
    includeEnglish: boolean,
    internalContact?: boolean
  ) {
    this.managementCompanyId = managementCompanyId
    this.contactCompanyId = contactCompanyId
    this.contactFirstName = contactFirstName
    this.contactLastName = contactLastName
    this.contactEmail = contactEmail
    this.uuid = uuid
    this.emailTitle = emailTitle
    this.emailBody = emailBody
    this.link = link
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
    this.internalContact = internalContact
  }
}
