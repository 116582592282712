import { ManagerInvitationFe } from './ManagerInvitationFe'

export class ManagerInvitationExtendedFe {
  managerEmailExist: boolean
  invitation: ManagerInvitationFe

  constructor(managerEmailExist: boolean, invitation: ManagerInvitationFe) {
    this.managerEmailExist = managerEmailExist
    this.invitation = invitation
  }

  public static fromTransfer(transfer: any): ManagerInvitationExtendedFe {
    let invitation = ManagerInvitationFe.fromTransfer(transfer.invitation)
    let invitationExtended = new ManagerInvitationExtendedFe(transfer.managerEmailExist, invitation)
    return invitationExtended
  }
}
