import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { ActionFe } from 'src/app/model/subject/ActionFe'
import { AdminFe } from 'src/app/model/user/AdminFe'
import { CustomerSuccessFe } from 'src/app/model/user/CustomerSuccessFe'
import { AffiliationRoleFe } from 'src/app/model/user/AffiliationRoleFe'
import { CompanyFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { AccessibleCompanyFe } from 'src/app/model/org/AccessibleCompanyFe'
import { CustomerSuccess_UpdateFe } from 'src/app/model/user/CustomerSuccess_UpdateFe'
import { AbstractAffiliationFe } from 'src/app/model/user/AbstractAffiliationFe'
import { AffiliationStatus } from 'src/app/model/user/AffiliationStatusFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
@Component({
  selector: 'app-customer-success',
  templateUrl: './customer-success.component.html',
  styleUrls: ['./customer-success.component.scss']
})
export class CustomerSuccessComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('inviteCustomerSuccessModal', { static: true }) inviteCustomerSuccessModal: TemplateRef<any>
  @ViewChild('inviteClientModal', { static: true }) inviteClientModal: TemplateRef<any>
  csAdmin: boolean = true
  dectivatedAdmin: boolean = false
  onSelectedChange(arg0: any) {
    throw new Error('Method not implemented.')
  }
  selectedOptions: any
  cancelInvitation() {
    throw new Error('Method not implemented.')
  }
  isLoadingData: any
  isLoading: any
  options = [
    { label: 'Customer success admin', value: 'CSA' },
    { label: 'Customer success', value: 'CS' },
    { label: 'Deactivated admins', value: 'DEACTIVATED_CSA' },
    { label: 'Deactivated customer success', value: 'DEACTIVATED_CS' }
  ]
  selectedOption = 'CSA'
  csadmins: AdminFe[] = []
  cs: CustomerSuccessFe[] = []
  inactiveCS: CustomerSuccessFe[] = []
  selectedUser = null
  inviteMode
  mgmtCompanies: CompanyFe[] = []

  companiesToBeRemoved: AccessibleCompanyFe[] = []
  companiesToBeAdded: CompanyFe[] = []

  signInSettingsType: string
  url = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.admin'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.add_admin'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.add_admin'),
        icon: 'la la-plus',
        visible: () => true,
        actionName: 'add_admin'
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.customer_success'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.add_customer_success_manager'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.add_customer_success_manager'),
        icon: 'la la-plus',
        visible: () => true,
        actionName: 'add_csm'
      }
    ]
  ]

  constructor(
    private modalRef: BsModalRef,
    private stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    private modalService: BsModalService,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)

    this.stateService.csUpdatedSubject.subscribe(async (sub) => {
      if (sub.action == ActionFe.CREATED) {
        this.cs = await this.stateService.getAllCustomerSuccess()
      } else if (sub.action == ActionFe.UPDATED) {
        let index = this.cs.findIndex((c) => c == this.selectedUser)
        this.cs[index] = sub.cs
      } else if (sub.action == ActionFe.DELETED) {
        this.cs = await this.stateService.getAllCustomerSuccess()
        this.inactiveCS = this.stateService.getInactiveCS()
      }
    })

    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    await this.renderNewData()
  }

  async renderNewData() {
    this.isLoadingData = true
    let admins = await this.stateService.getAllAdmins()
    this.csadmins = admins.filter((a) => a.affiliationRole == AffiliationRoleFe.CUSTOMER_SUCCESS_ADMIN)
    this.cs = await this.stateService.getAllCustomerSuccess()
    this.inactiveCS = this.stateService.getInactiveCS()
    this.mgmtCompanies = await this.stateService.getAllManagementCompanies()
    this.isLoadingData = false
  }

  openModal(templateRef: TemplateRef<any>, size: string) {
    this.companiesToBeRemoved = []
    this.companiesToBeAdded = []
    this.modalRef = this.modalService.show(templateRef, { class: size })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  isAlreadyAssigned(company: CompanyFe) {
    if (this.selectedUser instanceof CustomerSuccessFe) {
      let assigned = this.selectedUser.accessibleCompanies.find((c) => c.companyId === company.id)
      return assigned
    }
  }

  toggleAssignedCompany(event, company) {
    if (event.target['checked']) {
      this.companiesToBeRemoved = this.companiesToBeRemoved.filter((c) => c.companyId != company.id)
    } else {
      this.companiesToBeRemoved.push(company)
    }
  }

  toggleMgmtCompany(event, company) {
    if (event.target['checked']) {
      this.companiesToBeAdded.push(company)
    } else {
      this.companiesToBeAdded = this.companiesToBeAdded.filter((c) => c.id != company.id)
    }
  }

  async assignCompanies() {
    this.isLoading = true
    if (this.selectedUser instanceof CustomerSuccessFe) {
      if (
        (this.companiesToBeRemoved.length == this.selectedUser.accessibleCompanies.length ||
          this.companiesToBeRemoved.length == 0) &&
        this.companiesToBeAdded.length == 0
      ) {
        return
      }
      let removedCompanyIds = this.companiesToBeRemoved.map((company) => company.companyId)
      let cs = new CustomerSuccess_UpdateFe(
        this.selectedUser.userId,
        this.selectedUser.email,
        removedCompanyIds,
        this.companiesToBeAdded
      )
      let updated = await this.stateService.updateCS(cs)
    }
    this.isLoading = false
    this.closeModal()
  }

  getCompanyName(aff: AbstractAffiliationFe) {
    let company = this.mgmtCompanies.find((c) => c.id == aff.affiliationCompanyId)
    return company?.name
  }

  async deactivate() {
    this.isLoading = true
    if (this.selectedUser instanceof CustomerSuccessFe) {
      await this.stateService.deactivateAllCSAff(this.selectedUser.userId)
      this.cs = await this.stateService.getAllCustomerSuccess()
    } else if (this.selectedUser instanceof AdminFe) {
      await this.stateService.deactivateAff({ affiliationId: this.selectedUser.affiliationId })
      let index = this.csadmins.findIndex((c) => c == this.selectedUser)
      this.csadmins[index].affiliationStatus = AffiliationStatus.INACTIVE
      this.csadmins[index].deactivationDate = new Date()
    }
    this.isLoading = false
    this.closeModal()
  }

  openSignInSettings(signInSettings: TemplateRef<any>, type: string) {
    this.signInSettingsType = type
    this.openModal(signInSettings, 'modal-lg')
  }

  async closeSignInSettings() {
    this.closeModal()
    await this.renderNewData()
  }

  toggleAllCompanies(event: any) {
    this.mgmtCompanies.forEach((c) => {
      if (!this.isAlreadyAssigned(c)) {
        c.isSelected = event.target.checked
        this.toggleMgmtCompany(event, c)
      }
    })
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'add_admin':
        this.inviteMode = 'admin'
        this.openModal(this.inviteCustomerSuccessModal, 'modal-lg')
        break
      case 'add_csm':
        this.inviteMode = 'cs'
        this.openModal(this.inviteCustomerSuccessModal, 'modal-lg')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }
}
