import { InvitationStatusFe } from './InvitationStatusFe'

export abstract class AbstractInvitationFe {
  uuid: string
  inviterFirstName: string
  inviterLastName: string
  invitationDate: Date
  invitationStatus: string

  constructor(
    uuid: string,
    inviterFirstName: string,
    inviterLastName: string,
    invitationDate: Date,
    invitationStatus: string
  ) {
    this.uuid = uuid
    this.inviterFirstName = inviterFirstName
    this.inviterLastName = inviterLastName
    this.invitationDate = invitationDate
    this.invitationStatus = invitationStatus
  }

  getInviterName() {
    return `${this.inviterFirstName} ${this.inviterLastName}`
  }

  getStatus() {
    switch (this.invitationStatus) {
      case InvitationStatusFe.ACTIVE:
        return 'Invited'
      case InvitationStatusFe.ACCEPTED:
        return 'Connected'
      case InvitationStatusFe.REJECTED:
        return 'Rejected'
      case InvitationStatusFe.EXPIRED:
        return 'Expired'
      case InvitationStatusFe.CANCELED:
        return 'Canceled'
    }
  }
}
