import { StringUtilFe } from 'src/app/utils/StringUtilFe'
import { TwoOperandOperatorFe } from './TwoOperandOperatorFe'
import { ValueStatementFe } from './ValueStatementFe'

export class StringValueStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'STRING'
  public type = StringValueStatementFe.TYPE_KEY
  public static START_KEYWORD = '"'
  public static END_KEYWORD = '"'

  constructor(value: string) {
    super(value)
  }

  public toFormulaString(): string {
    let str = [
      `${StringValueStatementFe.START_KEYWORD}`,
      `${StringUtilFe.escapeCharacters(this.value)}`,
      `${StringValueStatementFe.END_KEYWORD}`
    ].join('')
    return str
  }

  public static fromFormulaString(formula: string): StringValueStatementFe {
    let nextIndex = formula.indexOf(StringValueStatementFe.END_KEYWORD, StringValueStatementFe.START_KEYWORD.length)
    let value = formula.substring(StringValueStatementFe.START_KEYWORD.length, nextIndex)
    return new StringValueStatementFe(value)
  }

  public evaluate(values: Map<string, any>): string {
    return this.value
  }

  public static fromTransfer(transfer: any): StringValueStatementFe {
    let value = transfer['value']
    let statement = new StringValueStatementFe(value)
    return statement
  }

  public static compareVaue(value1: any, operator: TwoOperandOperatorFe, value2: any): string {
    value1 = StringUtilFe.escapeCharacters(value1)
    value2 = StringUtilFe.escapeCharacters(value2)
    let formula = `"${value1}" ${operator.symbol} "${value2}"`
    return formula
  }
}
