export enum UnitMeasurementTypes {
  'LENGTH' = 'LENGTH',
  'SURFACE_AREA' = 'SURFACE_AREA',
  'VOLUME' = 'VOLUME',
  'ANGLES' = 'ANGLES',
  'TIME' = 'TIME',
  'FREQUENCY' = 'FREQUENCY',
  'MASS' = 'MASS',
  'ELECTRIC_CURRENT' = 'ELECTRIC_CURRENT',
  'TEMPERATURE' = 'TEMPERATURE',
  'AMOUNT_OF_SUBSTANCE' = 'AMOUNT_OF_SUBSTANCE',
  'LUMINOUS_INTENSITY' = 'LUMINOUS_INTENSITY',
  'FORCE' = 'FORCE',
  'ENERGY' = 'ENERGY',
  'POWER' = 'POWER',
  'PRESSURE' = 'PRESSURE',
  'ELECTRICITY_AND_MAGNETISM' = 'ELECTRICITY_AND_MAGNETISM',
  'BINARY' = 'BINARY',
  'NO_UNIT' = 'NO_UNIT',
  'NUMBER' = 'NUMBER',
  'PIECES' = 'PIECES',
  'PERCENTAGE' = 'PERCENTAGE',
  'PEOPLE' = 'PEOPLE',
  'CURRENCY' = 'CURRENCY'
}

export const StandardUnitMeasurementTypes = [
  UnitMeasurementTypes.LENGTH,
  UnitMeasurementTypes.SURFACE_AREA,
  UnitMeasurementTypes.VOLUME,
  UnitMeasurementTypes.ANGLES,
  UnitMeasurementTypes.TIME,
  UnitMeasurementTypes.FREQUENCY,
  UnitMeasurementTypes.MASS,
  UnitMeasurementTypes.ELECTRIC_CURRENT,
  UnitMeasurementTypes.TEMPERATURE,
  UnitMeasurementTypes.AMOUNT_OF_SUBSTANCE,
  UnitMeasurementTypes.LUMINOUS_INTENSITY,
  UnitMeasurementTypes.FORCE,
  UnitMeasurementTypes.ENERGY,
  UnitMeasurementTypes.POWER,
  UnitMeasurementTypes.PRESSURE,
  UnitMeasurementTypes.ELECTRICITY_AND_MAGNETISM,
  UnitMeasurementTypes.BINARY
]

export const getUnitMeasurementTypeName = (name: UnitMeasurementTypes) => {
  switch (name) {
    case UnitMeasurementTypes.LENGTH:
      return 'Length'
    case UnitMeasurementTypes.SURFACE_AREA:
      return 'Surface area'
    case UnitMeasurementTypes.VOLUME:
      return 'Volume'
    case UnitMeasurementTypes.ANGLES:
      return 'Angles'
    case UnitMeasurementTypes.TIME:
      return 'Time'
    case UnitMeasurementTypes.FREQUENCY:
      return 'Frequency'
    case UnitMeasurementTypes.MASS:
      return 'Mass'
    case UnitMeasurementTypes.ELECTRIC_CURRENT:
      return 'Electric current'
    case UnitMeasurementTypes.TEMPERATURE:
      return 'Temperature'
    case UnitMeasurementTypes.AMOUNT_OF_SUBSTANCE:
      return 'Amount of substance'
    case UnitMeasurementTypes.LUMINOUS_INTENSITY:
      return 'Luminous intensity'
    case UnitMeasurementTypes.FORCE:
      return 'Force'
    case UnitMeasurementTypes.ENERGY:
      return 'Energy'
    case UnitMeasurementTypes.POWER:
      return 'Power'
    case UnitMeasurementTypes.PRESSURE:
      return 'Pressure'
    case UnitMeasurementTypes.ELECTRICITY_AND_MAGNETISM:
      return 'Electricity and magnetism'
    case UnitMeasurementTypes.BINARY:
      return 'Binary'
    case UnitMeasurementTypes.NO_UNIT:
      return 'No Unit'
    case UnitMeasurementTypes.NUMBER:
      return 'Number'
    case UnitMeasurementTypes.PIECES:
      return 'Pieces'
    case UnitMeasurementTypes.PERCENTAGE:
      return 'Percentage'
    case UnitMeasurementTypes.PEOPLE:
      return 'People'
    case UnitMeasurementTypes.CURRENCY:
      return 'Currency'
    default:
      return `${name}`
  }
}

export const getUnitMeasurementTypeDefaultUnitSymbol = (name: UnitMeasurementTypes) => {
  switch (name) {
    case UnitMeasurementTypes.LENGTH:
      return 'm'
    case UnitMeasurementTypes.SURFACE_AREA:
      return 'm2'
    case UnitMeasurementTypes.VOLUME:
      return 'm3'
    case UnitMeasurementTypes.ANGLES:
      return 'rad'
    case UnitMeasurementTypes.TIME:
      return 'second'
    case UnitMeasurementTypes.FREQUENCY:
      return 'Hz'
    case UnitMeasurementTypes.MASS:
      return 'g'
    case UnitMeasurementTypes.ELECTRIC_CURRENT:
      return 'A'
    case UnitMeasurementTypes.TEMPERATURE:
      return 'K'
    case UnitMeasurementTypes.AMOUNT_OF_SUBSTANCE:
      return 'mol'
    case UnitMeasurementTypes.LUMINOUS_INTENSITY:
      return 'cd'
    case UnitMeasurementTypes.FORCE:
      return 'N'
    case UnitMeasurementTypes.ENERGY:
      return 'J'
    case UnitMeasurementTypes.POWER:
      return 'W'
    case UnitMeasurementTypes.PRESSURE:
      return 'Pa'
    case UnitMeasurementTypes.ELECTRICITY_AND_MAGNETISM:
      return 'V'
    case UnitMeasurementTypes.BINARY:
      return 'b'
    default:
      return ''
  }
}
