import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'confirm-cancel-modal',
  templateUrl: './confirm-cancel-modal.component.html',
  styleUrls: ['./confirm-cancel-modal.component.scss']
})
export class ConfirmCancelModalComponent implements OnInit {
  @Input() isVisible = false
  @Input() inProgress = false
  @Input() bodyText: String
  @Input() headerText: String
  @Input() actionButtonText: String
  @Input() cancelButtonText: String

  @Output() actionClicked = new EventEmitter<void>()
  @Output() cancelClicked = new EventEmitter<void>()

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  hide(): void {
    this.isVisible = false
  }

  action(): void {
    this.actionClicked.emit()
  }

  cancel(): void {
    this.cancelClicked.emit()
    this.hide()
    this.cdr.detectChanges()
  }
}
