<div
  class="card text-center node"
  style="max-width: 20rem; width: 20rem"
  #canvasContent
  [ngClass]="isRootElement() ? 'parentNode' : 'notAParentNode'"
>
  <div class="card-body" style="height: 5rem">
    <a
      class="badge text-decoration-none"
      [ngClass]="data.deployed ? (data.modified ? 'orange' : 'green') : 'yellowBg'"
      *ngIf="shareDataService.orgChartService.getMode() == 'edit' && !isRootElement()"
      data-bs-toggle="tooltip"
      data-placement="right"
      title="{{
        data.deployed
          ? data.modified
            ? 'The modifications ' + data.label + ' will be available after upgrade'
            : 'No modifications in ' + data.label
          : 'The new ' + data.label + ' will be available after upgrade'
      }}"
    >
      {{ data.deployed ? (data.modified ? 'modified' : 'unchanged') : 'new' }}
    </a>

    <div class="content clearfix">
      <div>
        <div class="row details pt-3">
          <a class="text-white instIcon pt-2" *ngIf="isRootElement()">
            <i class="la la-institution" style="font-size: 1.75rem"></i>
          </a>
          <div class="col-12" style="z-index: 1">
            <h5 class="card-title" [ngClass]="{ 'text-white': isRootElement() }">
              {{ data.label ? data.label : '' }}
            </h5>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <a
            (click)="deleteNode()"
            *ngIf="shareDataService.orgChartService.getMode() == 'edit' && !isRootElement()"
            title="{{ locale('locale_key.pages.organization.edit_mode.tooltip.delete_data_group') }}"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
          >
            <i class="las la-minus-square" style="font-size: 1.5rem; color: #374659"></i>
          </a>
          <a
            (click)="addNewNode()"
            *ngIf="shareDataService.orgChartService.getMode() == 'edit'"
            title="{{ locale('locale_key.pages.organization.edit_mode.tooltip.add_data_group') }}"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
          >
            <i
              class="las la-plus-square"
              style="font-size: 1.5rem; color: #374659"
              [ngClass]="{ 'text-white': isRootElement() }"
            ></i>
          </a>
          <a
            (click)="editDetails()"
            *ngIf="shareDataService.orgChartService.getMode() == 'edit' && !isRootElement()"
            title="{{ locale('locale_key.pages.organization.edit_mode.tooltip.edit_data_group') }}"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
          >
            <i class="las la-edit" style="font-size: 1.5rem; color: #374659"></i>
          </a>
          <a
            (click)="sendDetails()"
            *ngIf="shareDataService.orgChartService.getMode() == 'view' && !isRootElement()"
            data-bs-toggle="tooltip"
            data-placement="bottom"
            title="Open {{ data.label }} table"
            container="body"
            placement="bottom"
            containerClass="tooltipClass"
          >
            <i class="la la-table" style="font-size: 1.5rem; color: #374659"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
