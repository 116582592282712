<div class="modal-header" style="border-radius: 0px; border: none">
  <h4 class="modal-title mt-50 text-uppercase" id="myModalLabel99">
    {{ locale('locale_key.pages.network.business_partner_detail.title') }}
  </h4>
</div>
<div class="m-0 p-0">
  <div class="details">
    <div class="modal-body p-50 mb-50" style="height: 70vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="content clearfix">
        <div class="form-group">
          <div class="row">
            <div class="col-4 text-center">
              <i class="la la-industry" style="font-size: 5rem"></i>
            </div>
            <div class="col-8 text-left">
              <h3 class="text-left pt-1 mb-0 pb-25 pl-1">
                {{ company.name || company.supplierCompanyname }}
              </h3>
              <p class="text-left mb-0 pb-25 pl-1" *ngIf="company">
                <span class="badge badge-green">{{ locale('locale_key.general.state.connected') }}</span>
              </p>
              <p class="text-left mb-0 pb-25 pl-1" *ngIf="invitation">
                <span
                  class="badge white"
                  [ngClass]="
                    invitation.invitationStatus == 'ACTIVE'
                      ? 'badge-turquoise'
                      : invitation.invitationStatus == 'ACCEPTED'
                        ? 'badge-green'
                        : 'badge-red'
                  "
                >
                  {{
                    invitation.invitationStatus == 'ACTIVE'
                      ? locale('locale_key.general.state.invited')
                      : invitation.invitationStatus == 'CANCELED'
                        ? locale('locale_key.general.state.cancelled')
                        : invitation.invitationStatus == 'ACCEPTED'
                          ? locale('locale_key.general.state.accepted')
                          : locale('locale_key.general.state.rejected')
                  }}
                </span>
              </p>
            </div>
          </div>
          <div class="mt-1">
            <table class="table table-borderless mx-0">
              <tbody class="mx-0 px-0" *ngIf="company">
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry_group.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ company.supplierIndustryGroup }}
                  </td>
                </tr>
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ company.supplierIndustry }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.website.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ company.supplierWebsite }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.country.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ getCountry(company.supplierCountryCode) || getCountry(company.countryCode) }}
                  </td>
                </tr>
              </tbody>
              <tbody class="mx-0 px-0" *ngIf="invitation">
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry_group.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ invitation.partnerIndustryGroup }}
                  </td>
                </tr>
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ invitation.partnerIndustry }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.website.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ invitation.partnerWebsite }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.country.title') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ getCountry(invitation.partnerCountryCode) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-container *ngIf="invitation">
            <h4 class="ml-1 mt-1 mb-0">
              {{ locale('locale_key.pages.network.business_partner_detail.primary_contact_person.title') }}
            </h4>
            <table class="table table-borderless mx-0">
              <tbody class="mx-0 px-0" *ngIf="invitation">
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.primary_contact_person.name.title') }}
                  </td>
                  <td class="text-left truncate mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ invitation.primaryContactFirstName + ' ' + invitation.primaryContactLastName }}
                  </td>
                </tr>
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.primary_contact_person.email.title') }}
                  </td>
                  <td class="text-left truncate mx-0 col-8 mt-auto" style="font-size: 1rem; font-weight: 400">
                    {{ invitation.primaryContactEmail }}
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="ml-1 mt-1 mb-0">
              {{ locale('locale_key.pages.network.business_partner_detail.invitation.title') }}
            </h4>
            <table class="table table-borderless mx-0 px-0">
              <tbody class="mx-0 px-0">
                <tr class="mx-0 px-0">
                  <td class="text-left mx-0 col-4" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.general.state.invited') }}
                  </td>
                  <td class="text-left mx-0 col-8 mt-auto">
                    <span class="badge badge-gray">
                      {{ invitation['invitationDate'] | date: 'dd MMM, yyyy (HH:mm)' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-1 mb-3">
      <button type="button" class="btn tertiary-button" routerLink="/ws/contacts">
        {{ locale('locale_key.pages.network.business_partner_detail.button.view_all_data_reporters') }}
      </button>
    </div>
  </div>
</div>
