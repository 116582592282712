import { RouteFe } from './RouteFe'

export class RoutesFe {
  public static LAYOUT_PATH_OPEN = 'op'
  public static LAYOUT_PATH_PRE_WS = 'pr'
  public static LAYOUT_PATH_WORKSPACE = 'ws'
  public static LAYOUT_PATH_VISITOR = 'vs'

  public static WORKSPACES = new RouteFe(RoutesFe.LAYOUT_PATH_PRE_WS, 'workspaces', null)
  public static LOADING = new RouteFe(RoutesFe.LAYOUT_PATH_PRE_WS, 'loading', null)
  public static REFERENCE_WIDGETS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'dashboard', null)
  public static ROTATE_PASS = new RouteFe(RoutesFe.LAYOUT_PATH_PRE_WS, 'rotatepassword', null)

  public static RESET_PASS = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'resetpassword', null)
  public static LOGIN = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'login', null)
  public static FORGOT_PASS = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'forgotpassword', null)
  public static RESET_FORGOT_PASS = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'resetforgotpassword', 'uuid')
  public static MANAGER_REGISTRATION = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'register/manager', 'uuid')
  public static PARTNER_REGISTRATION = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'register/partner', 'uuid')
  public static CONTACT_REGISTRATION = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'register/contact', 'uuid')

  public static HOME = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'home', null)
  public static PROJECTS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'projects', null)
  public static PROFILE = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'profile', null)
  public static COMPANY_SETTINGS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'company-settings', null)
  public static VIEW_TEMPLATE = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'view-template', 'html')
  public static DASHBOARD = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'dashboard', null)
  public static USER_KPI = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'kpi/user', null)
  public static INSIGHTS_HUB = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'insights_hub', null)
  public static ADVANCED_KPI = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'kpi/advanced', null)
  public static FILES = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'files', null)
  public static DATA = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'data', null)
  public static EMISSION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'emission', null)
  public static ORGANIZATION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'organization', null)
  public static TAXONOMY = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'taxonomy', null)
  public static DATA_CATEGORIES = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'categories', null)
  public static UNIT_SYSTEMS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'unitsystems', null)
  public static NOTIFICATION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'notification', null)
  public static DETAILS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'details', null)
  public static NETWORK = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'network', null)
  public static SM_INBOX = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'inbox', null)
  public static REQUESTS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'requests', null)
  public static DRAFTS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'drafts', null)
  public static ATTACHMENT_REQUEST = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'attachment/request', null)
  public static ATTACHMENT_TASK = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'attachment/task', null)
  public static CLIENT_INVITATION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'invitation/client', 'uuid')
  public static PARTNER_INVITATION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'invitation/partner', 'uuid')
  public static CONTACT_INVITATION = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'invitation/contact', 'uuid')
  public static CLIENTS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'clients', null)
  public static ADMINS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'admins', null)
  public static EMAIL = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'emails', null)

  public static DO_INBOX = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'tasks/inbox', null)
  public static DO_REQUESTS = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'tasks', null)

  public static VERIFY_SHARED_DASHBOARD = new RouteFe(RoutesFe.LAYOUT_PATH_OPEN, 'verify/dashboard', 'objectSharedId')
  public static VISITOR_SHARED_DASHBOARD = new RouteFe(RoutesFe.LAYOUT_PATH_VISITOR, 'dashboard', null)
  public static DATA_HUB = new RouteFe(RoutesFe.LAYOUT_PATH_WORKSPACE, 'hub', null)
  public static VISITOR_LOADING = new RouteFe(RoutesFe.LAYOUT_PATH_VISITOR, 'loading', null)

  public static ALL_ROUTES = [
    RoutesFe.WORKSPACES,
    RoutesFe.HOME,
    RoutesFe.PROJECTS,
    RoutesFe.DASHBOARD,
    RoutesFe.USER_KPI,
    RoutesFe.ADVANCED_KPI,
    RoutesFe.FILES,
    RoutesFe.DATA,
    RoutesFe.EMISSION,
    RoutesFe.ORGANIZATION,
    RoutesFe.TAXONOMY,
    RoutesFe.DATA_CATEGORIES,
    RoutesFe.UNIT_SYSTEMS,
    RoutesFe.NOTIFICATION,
    RoutesFe.EMAIL,
    RoutesFe.ATTACHMENT_REQUEST,
    RoutesFe.ATTACHMENT_TASK,
    RoutesFe.RESET_PASS,
    RoutesFe.LOGIN,
    RoutesFe.FORGOT_PASS,
    RoutesFe.MANAGER_REGISTRATION,
    RoutesFe.PARTNER_REGISTRATION,
    RoutesFe.CONTACT_REGISTRATION,
    RoutesFe.RESET_FORGOT_PASS,
    RoutesFe.DETAILS,
    RoutesFe.NETWORK,
    RoutesFe.SM_INBOX,
    RoutesFe.REQUESTS,
    RoutesFe.DRAFTS,
    RoutesFe.REFERENCE_WIDGETS,
    RoutesFe.ROTATE_PASS,
    RoutesFe.PROFILE,
    RoutesFe.VIEW_TEMPLATE,
    RoutesFe.VERIFY_SHARED_DASHBOARD,
    RoutesFe.VISITOR_SHARED_DASHBOARD,
    RoutesFe.DATA_HUB,
    RoutesFe.VISITOR_LOADING,
    RoutesFe.CLIENTS,
    RoutesFe.ADMINS,
    RoutesFe.DO_INBOX,
    RoutesFe.DO_REQUESTS,
    RoutesFe.INSIGHTS_HUB
  ]

  public static matchRootPath(path: string): RouteFe | undefined {
    let route = RoutesFe.ALL_ROUTES.find((R) => R.doesRouteMatch(path))
    return route
  }

  public static matchExactPath(path: string): RouteFe | undefined {
    let route = RoutesFe.ALL_ROUTES.find((R) => R.isRouteSame(path))
    return route
  }
}
