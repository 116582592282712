import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { NgFlowchart } from './model/flow.model'
import { NgFlowchartStepRegistry } from './services/ng-flowchart-step-registry.service'
import { NgFlowchartCanvasDirective } from './directives/ng-flowchart-canvas.directive'
import { CustomStepComponent } from './custom-step/custom-step.component'
import { OrganizationChartIntegrationInterface } from './services/OrganizationChartIntegrationInterface'
import { FlowChartOrganizationInfo } from './model/FlowChartOrganizationInfo'
import { FlowChartOrganizationEntity } from './model/FlowChartOrganizationEntity'
import { ShareDataService } from './services/shareData.service'
import { NgFlowchartStepComponent } from './ng-flowchart-step/ng-flowchart-step.component'

interface jsonSchema {
  id: string
  type: string
  data: FlowChartOrganizationEntity
  children: jsonSchema[]
}

@Component({
  selector: 'flowChart',
  templateUrl: './flowChart.component.html',
  styleUrls: ['./flowChart.component.scss']
})
export class FlowChartComponent implements OnInit {
  @Input() orgChartService!: OrganizationChartIntegrationInterface

  callbacks: NgFlowchart.Callbacks = {}
  options: NgFlowchart.Options = {
    stepGap: 40,
    rootPosition: 'TOP_CENTER',
    zoom: {
      mode: 'MANUAL'
    }
  }

  sampleJson = {
    root: {
      id: 's1634721457801',
      type: 'router',
      data: {
        deployed: false,
        modified: false,
        ordinal: 0,
        key: '',
        label: 'Parent Node',
        parents: []
      },
      children: [
        {
          id: 's1634721465031',
          type: 'router',
          data: {
            deployed: false,
            modified: false,
            ordinal: 1,
            key: '',
            label: 'k',
            parents: []
          },
          children: []
        }
      ]
    }
  }

  json!: { root: jsonSchema }
  customOps = {
    paletteName: 'Router',
    step: {
      template: CustomStepComponent,
      type: 'router',
      data: {
        deployed: false,
        ordinal: 0,
        key: '',
        label: this.orgChartService ? this.orgChartService.getOrganizationName() : 'Parent Node',
        parents: []
      }
    }
  }
  lastRef: any = ''

  @ViewChild(NgFlowchartCanvasDirective)
  canvas!: NgFlowchartCanvasDirective

  disabled = false
  info: FlowChartOrganizationInfo = { entities: [] }
  newFlow: FlowChartOrganizationEntity[] = []

  constructor(
    private stepRegistry: NgFlowchartStepRegistry,
    private shareDataService: ShareDataService
  ) {
    this.callbacks.onDropError = this.onDropError
    this.callbacks.onMoveError = this.onMoveError
    this.callbacks.afterDeleteStep = this.afterDeleteStep
    this.callbacks.beforeDeleteStep = this.beforeDeleteStep
  }

  async ngOnInit() {
    this.orgChartService.registerObserver(this)
    this.shareDataService.orgChartService = this.orgChartService

    //for updating entity details
    this.shareDataService.dataUpdated$.subscribe(async (data) => {
      let flow = await this.canvas.getFlow().toObject()
      const entity = flow.find((x) => x.key == data.key)!
      //update details of entity
      entity.label = data.label
      entity.dataSchema = data.dataSchema
      entity.deployed = data.deployed
      entity.modified = data.modified
      await this.updateOrganizationalInfo({ entities: flow })
    })

    //change flow variables when chart is updated
    this.shareDataService.chartUpdated$.subscribe(async () => {
      await this.refreshFlowVars()
    })

    this.shareDataService.refreshChart$.subscribe(async (flow: FlowChartOrganizationEntity[]) => {
      //recreate chart and update flow variables in shareDataService
      await this.updateOrganizationalInfo({ entities: flow })
      await this.refreshFlowVars()
    })
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      const canvas = document.getElementById('canvas')
      const loading = document.getElementById('loading')
      if (canvas && loading) {
        canvas.style.visibility = 'visible'
        loading.style.display = 'none'
      }
    }, 1000)
    this.stepRegistry.registerStep('router', CustomStepComponent)
    await this.updateOrganizationalInfo(this.orgChartService.getOrganizationalInfo())

    //update flow variables on load
    await this.refreshFlowVars()
  }

  async updateOrganizationalInfo(info: FlowChartOrganizationInfo): Promise<void> {
    this.info = { ...info } //copy
    this.refreshJSON()
    this.convertData()
    await this.createChart()
    await this.refreshFlowVars()
  }

  //update flow variables in shareDataService
  async refreshFlowVars() {
    let flow = await this.canvas.getFlow().toObject()
    this.shareDataService.newOrgFlow = { entities: JSON.parse(await this.canvas.getFlow().toJSON(flow, 4)) }
    this.shareDataService.canvasFlow = this.canvas.getCanvas().flow
  }

  refreshJSON() {
    this.json = {
      root: {
        id: 'PARENT_NODE',
        type: 'router',
        data: {
          deployed: false,
          modified: false,
          ordinal: 0,
          key: 'PARENT_NODE',
          label: this.orgChartService ? this.orgChartService.getOrganizationName() : 'Parent Node',
          parents: [],
          dataSchema: [],
          referenceSchema: []
        },
        children: []
      }
    }
  }

  convertData() {
    //keep only last nodes
    this.info.entities.forEach((node) => {
      this.deleteExtraData(node)
    })
    this.info.entities.forEach((node) => {
      this.addChildren(node)
    })
  }

  deleteExtraData(node: FlowChartOrganizationEntity) {
    node.parents.forEach((parentNode) => {
      this.info.entities = this.info.entities.filter((x) => x.key != parentNode.key)
      if (parentNode.parents.length > 0) this.deleteExtraData(parentNode)
      else return
    })
  }

  addChildren(node: FlowChartOrganizationEntity) {
    const newNode = {
      id: node.key,
      type: 'router',
      data: node,
      children: []
    }
    if (node.parents.length > 0)
      node.parents.forEach((parentNode) => {
        this.addChildren(parentNode)
        this.addLastNode(newNode, node.key)
      })
    else {
      const index = this.json.root.children.findIndex((x) => x.data.key == node.key)
      if (index != -1) {
        this.lastRef = this.json.root.children[index]
      } else {
        this.json.root.children.push(newNode)
        this.lastRef = this.json.root.children[this.json.root.children.length - 1]
      }
      return
    }
  }

  addLastNode(newNode: jsonSchema, parentKey: string) {
    const index = this.lastRef.children.findIndex((x: jsonSchema) => x.data.key == parentKey)
    if (index != -1) {
      this.lastRef = this.lastRef.children[index]
    } else {
      this.lastRef.children.push(newNode)
      this.lastRef = this.lastRef.children[this.lastRef.children.length - 1]
    }
  }

  onDropError(error: NgFlowchart.DropError) {}

  onMoveError(error: NgFlowchart.MoveError) {}

  beforeDeleteStep(step: any) {}

  afterDeleteStep(step: any) {}

  async createChart() {
    await this.canvas.getFlow().upload(JSON.stringify(this.json))
    this.shareDataService.canvasFlow = await this.canvas.getCanvas().flow
  }

  getUpdatedOrganizationalInfo(): FlowChartOrganizationInfo {
    return this.shareDataService.newOrgFlow
  }

  toFlowChartEntity(flow: any) {
    this.newFlow = flow
      .sort((a: NgFlowchartStepComponent, b: NgFlowchartStepComponent) => a.data.ordinal - b.data.ordinal)
      .map((step: NgFlowchartStepComponent) => step.data)

    //removes parent node
    this.newFlow = this.newFlow.filter((x) => x.key !== 'PARENT_NODE')

    this.newFlow.forEach((data, index) => {
      //removes duplicate nodes from array
      this.newFlow = [
        ...this.newFlow.slice(0, index + 1),
        ...this.newFlow.slice(index + 1).filter((x) => x.key !== data.key)
      ]
      this.newFlow[index] ? (this.newFlow[index].ordinal = index) : null
    })

    //edits extra ordinals from parents
    this.newFlow.forEach((data: FlowChartOrganizationEntity) =>
      data.parents.forEach((parent, index) => this.changeOrdinal(parent, index))
    )

    return this.newFlow
  }

  changeOrdinal(node: FlowChartOrganizationEntity, ordinal: number) {
    const ordi = this.newFlow.find((x) => x.key == node.key)?.ordinal
    if (ordi) {
      node.ordinal = ordi
      if (node.parents.length > 0) {
        node.parents.forEach((parent, index) => this.changeOrdinal(parent, index))
      } else return
    } else return
  }

  async clearData() {
    await this.canvas.getFlow().clear()
  }

  async onDelete() {
    await this.canvas.getFlow().getStep(this.json.root.id).destroy(true)
  }
}
