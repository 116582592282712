import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core'
import { FormArray } from '@angular/forms'
import { BehaviorSubject, Subject } from 'rxjs'
import { DataGridRow } from '../model/DataGridRow'
import { DataGridStatus } from '../model/DataGridStatus'
import { DataGridTableMode } from '../model/DataGridTableMode'

export class SelectedData {
  constructor(
    public rowNo: number,
    public values: { colNo: number; value: any }[]
  ) {}
}

class Position {
  constructor(
    public column: number = 0,
    public row: number = 0
  ) {}
}
export class DragSelectClasses {
  public static selectionClass = 'selected-item'
  public static selectionClassForCopying = 'selected-item-copy'
  public static selectionClassAfterCopying = 'copied'
  public static activeCellClass = 'activeCell'
  public static startCellClass = 'firstCell'
  public static lastCellClass = 'lastCell'
  public static horizontalClass = 'horizontal'
  public static verticalClass = 'vertical'

  public static getAllClassesRelatedToCopySelection(): string[] {
    return [
      DragSelectClasses.selectionClassForCopying,
      DragSelectClasses.selectionClassAfterCopying,
      DragSelectClasses.activeCellClass,
      DragSelectClasses.startCellClass,
      DragSelectClasses.lastCellClass,
      DragSelectClasses.horizontalClass,
      DragSelectClasses.verticalClass
    ]
  }
}

@Directive({
  selector: '[dragSelect]'
})
export class DragSelectDirective implements OnInit, OnDestroy {
  dragging = false
  startCell: HTMLInputElement | null = null
  tagName = 'TD'
  dragSelectIds: string[] = []
  data: SelectedData[] = []
  colNumbers: number[] = []
  dataSelected = false

  secondCell: HTMLInputElement | null = null
  endCell: HTMLInputElement | null = null
  isHorizontal = false
  previousCell: any

  @Input() mode: string | null = null
  @Input() startSelectionForCopying = false
  @Input() exportIt = new Subject<boolean>()
  @Input() tableComponent!: HTMLDivElement
  @Input() rowArray = new FormArray([])
  @Input() addedRows: DataGridRow[] = []
  @Output() selectedData = new BehaviorSubject<{ value?: SelectedData[]; element?: HTMLDivElement }>({})
  @Output() pasteComplete = new Subject<boolean>()

  unlistenMouseDown!: () => void
  unlistenMouseOver!: () => void
  unlistenMouseUp!: () => void

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.el = this.el.nativeElement

    // this.exportIt.subscribe((x:boolean)=>{
    //   if(x){
    //     this.selectedData.next({value:{data:this.data , colNumbers:this.colNumbers}, element:this.tableComponent});
    //   }
    // });

    this.unlistenMouseDown = this.renderer.listen(this.el, 'mousedown', (event) => {
      //detect right click (event.which == 3 || event.button == 2)

      // if(this.selectedData.getValue()!={}){
      //   const id = event.target.tagName == 'TD' ?  event.target.id + 'value' : event.target.id;
      //   if( this.dragSelectIds.includes(id)){
      //     this.selectedData.next({value:{data:this.data , colNumbers:this.colNumbers}, element:this.tableComponent});
      //     return ;
      //   }
      // }
      if (
        this.mode == DataGridTableMode.EXPORT_EXCEL_TABLE_DATA &&
        (event.button === 0 || event.button === 1 || event.shiftKey)
      ) {
        // if (this.dataSelected && this.data.length != 0) {
        //   this.dataSelected = false;
        //   this.data = []
        //   this.colNumbers = []
        //   this.removeSelectedCellClass([DragSelectClasses.selectionClass]);
        //   this.selectedData.next({ value: this.data, element: this.tableComponent });
        // } else if (this.data.length == 0)
        if (event.target.classList.contains('rowNumber')) {
          return
        }
        if (this.previousCell && event.shiftKey) {
          this.setStartCell(this.previousCell)
          this.setEndCell(event.target, [DragSelectClasses.selectionClass])
          this.selectedData.next({ value: this.data, element: this.tableComponent })
          this.dataSelected = true
          this.previousCell = null
        } else {
          this.dragging = true
          this.tagName = (event.target as Element)?.tagName
          this.previousCell = event.target
          this.setStartCell(event.target)
          this.setEndCell(event.target, [DragSelectClasses.selectionClass])

          this.ngZone.runOutsideAngular(() => {
            this.unlistenMouseOver = this.renderer.listen(this.el, 'mouseover', (event) => {
              if (this.mode == DataGridTableMode.EXPORT_EXCEL_TABLE_DATA) {
                if (!this.dragging) {
                  return
                }
                this.setEndCell(event.target, [DragSelectClasses.selectionClass])
              }
            })

            this.unlistenMouseUp = this.renderer.listen(this.el, 'mouseup', (event) => {
              if (this.mode == DataGridTableMode.EXPORT_EXCEL_TABLE_DATA) {
                this.dragging = false
                this.selectedData.next({ value: this.data, element: this.tableComponent })
                this.dataSelected = true
                this.unlistenMouseOver()
                this.unlistenMouseUp()
              }
            })
          })
        }
      } else if (
        this.mode == DataGridTableMode.EDIT_TABLE_DATA ||
        this.mode == DataGridTableMode.EDIT_REFERENCE_TABLE_DATA
      ) {
        if (this.startSelectionForCopying) {
          let tdEl = event.target.offsetParent as Element
          if (tdEl.tagName == 'TD' && tdEl.children.length > 0) {
            this.dragging = true
            let inputEl = tdEl.querySelector('input')
            this.tagName = (inputEl as Element)?.tagName
            this.setStartCell(inputEl)
            tdEl.classList.add(DragSelectClasses.activeCellClass)
            this.setEndCell(inputEl, [DragSelectClasses.selectionClassForCopying])

            this.ngZone.runOutsideAngular(() => {
              this.unlistenMouseOver = this.renderer.listen(this.el, 'mouseover', (event) => {
                if (
                  this.startSelectionForCopying &&
                  (this.mode == DataGridTableMode.EDIT_TABLE_DATA ||
                    this.mode == DataGridTableMode.EDIT_REFERENCE_TABLE_DATA)
                ) {
                  if (!this.dragging) {
                    return
                  }

                  if (event.target.tagName == 'INPUT' && event.target != this.startCell) {
                    const coords = this.getCoords(event.target)
                    const coordsStart = this.getCoords(this.startCell)
                    if (this.startSelectionForCopying && !this.secondCell) {
                      this.secondCell = event.target
                      event.target.offsetParent.classList.add(DragSelectClasses.startCellClass)
                      if (coords.column == coordsStart.column) {
                        this.isHorizontal = false
                      } else {
                        this.isHorizontal = true
                      }
                    }
                    let className = this.isHorizontal
                      ? DragSelectClasses.horizontalClass
                      : DragSelectClasses.verticalClass
                    this.setEndCell(event.target, [DragSelectClasses.selectionClassForCopying, className])
                  }
                }
              })

              this.unlistenMouseUp = this.renderer.listen(this.el, 'mouseup', (event) => {
                if (
                  this.mode == DataGridTableMode.EDIT_TABLE_DATA ||
                  this.mode == DataGridTableMode.EDIT_REFERENCE_TABLE_DATA
                ) {
                  this.copyDataInSelectedCells()
                  this.resetCopySelection()
                  this.unlistenMouseOver()
                  this.unlistenMouseUp()
                }
              })
            })
          }
        } else {
          let classes = DragSelectClasses.getAllClassesRelatedToCopySelection()
          this.resetCopySelection()
          this.removeSelectedCellClass(classes)
        }
      }
    })
  }

  resetCopySelection() {
    this.dragging = false
    this.secondCell = null
    this.startCell = null
    this.endCell = null
  }

  copyDataInSelectedCells() {
    let dataSource = this.rowArray.controls
    if (this.startCell) {
      let content: string = this.startCell.value
      this.cellsBetween(this.startCell, this.endCell).forEach((element: HTMLInputElement) => {
        if (element.className.includes('rowData')) {
          element.offsetParent?.classList.add(DragSelectClasses.selectionClassAfterCopying)
          let rowNo: any = element.getAttribute('rowNo')
          let colNo: any = element.getAttribute('colNo')
          if (rowNo && colNo) {
            rowNo = parseInt(rowNo)
            colNo = parseInt(colNo)
            dataSource[rowNo].get(`col${colNo}`)?.setValue(content)
            if (
              this.addedRows[rowNo].values[colNo] != this.rowArray.value[rowNo]['col' + colNo] &&
              this.addedRows[rowNo].status != 'ADDED'
            ) {
              this.addedRows[rowNo].status = DataGridStatus.MODIFIED
            }
          }
        }
      })
      this.pasteComplete.next(true)
    }
  }

  setStartCell(el: any): void {
    this.startCell = el
  }

  setEndCell(el: any, className: string[]): void {
    this.dragSelectIds = []
    this.endCell = el
    this.data = []
    this.colNumbers = []
    this.removeSelectedCellClass(className)
    let betweenCells = this.cellsBetween(this.startCell, el)
    let allValues: { rowNo: number; colNo: number; value: any }[] = []
    let colNos = new Set<number>()
    betweenCells.forEach((element: HTMLElement) => {
      let isHeadline = element.classList.contains('headline')
      if (this.tagName === 'INPUT' && !isHeadline) {
        let rowIndex: any = element.getAttribute('rowNo')
        let colNo: any = element.getAttribute('colNo')
        if (rowIndex && colNo) {
          rowIndex = parseInt(rowIndex)
          colNo = parseInt(colNo)
          colNos.add(colNo)
          let value = this.rowArray.value[rowIndex]['col' + colNo]
          allValues.push({ rowNo: rowIndex, colNo, value })
        }
        this.dragSelectIds.push(element.id)
        element = element.parentElement!
      }
      if (element.tagName == 'DIV') {
        element = element.parentElement
      }
      if (element.id && !isHeadline) {
        this.tableComponent.querySelector(`#${element.id}`)?.classList.add(...className)
      }
    })

    for (let index = 0; index < allValues.length; index += colNos.size) {
      let rowData = allValues.slice(index, index + colNos.size)
      let values = rowData.map((v) => {
        return { colNo: v.colNo, value: v.value }
      })
      this.data.push(new SelectedData(rowData[0].rowNo, values))
    }

    if (this.startSelectionForCopying) {
      betweenCells = betweenCells.filter((cell: any) => cell != this.startCell)
      let length = betweenCells.length
      if (length > 0) {
        this.removeSelectedCellClass([DragSelectClasses.lastCellClass, DragSelectClasses.startCellClass])
        betweenCells[0].offsetParent.classList.add(DragSelectClasses.startCellClass)
        betweenCells[length - 1].offsetParent.classList.add(DragSelectClasses.lastCellClass)
      }
    }
  }

  cellsBetween(start: any, end: any): any {
    const coordsStart = this.getCoords(start)
    const coordsEnd = this.getCoords(end)
    let topLeftCell: Position, bottomRightCell: Position
    if (this.startSelectionForCopying) {
      if (this.isHorizontal) {
        topLeftCell = new Position(Math.min(coordsStart.column, coordsEnd.column), coordsStart.row)
        bottomRightCell = new Position(Math.max(coordsStart.column, coordsEnd.column), coordsStart.row)
      } else {
        topLeftCell = new Position(coordsStart.column, Math.min(coordsStart.row, coordsEnd.row))
        bottomRightCell = new Position(coordsStart.column, Math.max(coordsStart.row, coordsEnd.row))
      }
    } else {
      topLeftCell = new Position(
        Math.min(coordsStart.column, coordsEnd.column),
        Math.min(coordsStart.row, coordsEnd.row)
      )
      bottomRightCell = new Position(
        Math.max(coordsStart.column, coordsEnd.column),
        Math.max(coordsStart.row, coordsEnd.row)
      )
    }

    let arr = Object.entries(this.tableComponent.querySelectorAll('input.rowData'))
    let a = arr
      .filter((element: any) => {
        const coords = this.getCoords(element[1])
        return (
          coords.column >= topLeftCell.column &&
          coords.column <= bottomRightCell.column &&
          coords.row >= topLeftCell.row &&
          coords.row <= bottomRightCell.row
        )
      })
      .map((data) => data[1])

    return a
  }

  removeSelectedCellClass(className: string[]) {
    let allCells = Object.entries(this.tableComponent.querySelectorAll('td'))
    allCells.forEach((tag) => tag[1].classList.remove(...className))
  }

  getCoords(cell: any): Position {
    if (this.tagName === 'INPUT') {
      cell = cell.offsetParent
    }
    return new Position(cell.cellIndex, cell.parentNode.rowIndex)
  }

  ngOnDestroy() {
    this.unlistenMouseDown()
  }
}
