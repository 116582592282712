import { OrganizationAttributeFe } from 'src/app/model/organization/OrganizationAttributeFe'
import { OrganizationEntityFe } from 'src/app/model/organization/OrganizationEntityFe'
import { OrganizationInfoFe } from 'src/app/model/organization/OrganizationInfoFe'
import { RowStatusFe } from 'src/app/model/schema/RowStatusFe'
import { OpenOrgTableCallback } from './OpenOrgTableCallback'
import { DataGridColumnSchema } from '../projects/data-grid-ui/model/DataGridColumnSchema'
import { DataGridReferenceColumnSchema } from '../projects/data-grid-ui/model/DataGridReferenceColumnSchema'
import { FlowChartOrganizationEntity } from '../projects/ng-flowchart/src/lib/model/FlowChartOrganizationEntity'
import { FlowChartOrganizationInfo } from '../projects/ng-flowchart/src/lib/model/FlowChartOrganizationInfo'
import { OrganizationChartIntegrationInterface } from '../projects/ng-flowchart/src/lib/services/OrganizationChartIntegrationInterface'
import { OrganizationChartObserverInterface } from '../projects/ng-flowchart/src/lib/services/OrganizationChartObserverInterface'

export class OrganizationChartFLowChartIntegration implements OrganizationChartIntegrationInterface {
  observer: OrganizationChartObserverInterface
  orgName: string
  orgInfo: OrganizationInfoFe
  mode: string = 'view'
  modified: boolean = false
  openTableCallback: OpenOrgTableCallback

  constructor(orgName: string, orgInfo: OrganizationInfoFe, mode: string, openTableCallback: OpenOrgTableCallback) {
    this.orgName = orgName
    this.orgInfo = orgInfo
    this.mode = mode
    this.openTableCallback = openTableCallback
  }

  getMode(): string {
    return this.mode
  }

  getOrganizationalInfo(): FlowChartOrganizationInfo {
    let fcOrgInfo = this.convertOrgInfo(this.orgInfo)
    return fcOrgInfo
  }

  getOrganizationName(): string {
    return this.orgName
  }

  markChartAsModified(): void {
    this.modified = true
  }

  openOrgTable(node: FlowChartOrganizationEntity): void {
    if (this.mode == 'view') {
      this.openTableCallback.openOrgTable(node.key)
    }
  }

  registerObserver(observer: OrganizationChartObserverInterface): void {
    this.observer = observer
  }

  async getUpdatedOrganizationalInfo(): Promise<OrganizationInfoFe> {
    let updatedInfo: FlowChartOrganizationInfo = await this.observer.getUpdatedOrganizationalInfo()
    let entities = updatedInfo.entities.map((e, i) => {
      let attribues = e.dataSchema.map((cs) => {
        let attr = new OrganizationAttributeFe(cs.name, cs.label, cs.type)
        return attr
      })
      let parentKeys = e.parents.map((p) => p.key)
      let fce = new OrganizationEntityFe(e.ordinal, e.key, e.label, attribues, parentKeys)
      return fce
    })

    let orgInfo = new OrganizationInfoFe(this.orgInfo.version, entities)
    return orgInfo
  }

  async updateOrganizationInfo(orgInfo: OrganizationInfoFe) {
    this.orgInfo = orgInfo
    let fcOrgInfo = this.convertOrgInfo(orgInfo)
    await this.observer.updateOrganizationalInfo(fcOrgInfo)
    this.modified = false
  }

  private convertOrgInfo(orgInfo: OrganizationInfoFe): FlowChartOrganizationInfo {
    let fcEntities = orgInfo.entities.map((e, i) => {
      let dataSchema = e.attributes.map((attr) => {
        let schema = new DataGridColumnSchema()
        schema.label = attr.label
        schema.name = attr.label
        schema.type = attr.datatype
        schema.status = RowStatusFe.STORED
        return schema
      })
      let fce = new FlowChartOrganizationEntity(i, e.key, e.label, e.deployed, e.modified, dataSchema, [], [])
      return fce
    })

    this.orgInfo.entities.forEach((e) => {
      let parentKeys = e.parentKeys
      let entity = fcEntities.find((fce) => fce.key == e.key)
      let parents: FlowChartOrganizationEntity[] = parentKeys.map((pk) => fcEntities.find((e) => e.key == pk))
      entity.parents = parents
      let refereceSchema = parents.map((p) => {
        let refSchema = new DataGridReferenceColumnSchema()
        refSchema.referenceKey = p.key
        return refSchema
      })
      entity.referenceSchema = refereceSchema
    })
    let fcOrgInfo = new FlowChartOrganizationInfo(fcEntities)
    return fcOrgInfo
  }
}
