<div *ngIf="loadingInProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card-content" *ngIf="!loadingInProgress">
  <div class="card-body p-3">
    <div class="row">
      <div class="col-md-6 col-lg-3 mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{ locale('locale_key.general.search') }}"
          (input)="applySearchFilter()"
          [(ngModel)]="searchKeyword"
        />
      </div>
      <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body" [insideClick]="true">
        <button
          type="button"
          class="form-control filter-button"
          id="filter-button"
          dropdownToggle
          aria-controls="filter-dropdown-menu"
          style="text-align: left"
        >
          <p class="d-inline m-0">
            <i class="la la-filter"></i>
            {{ locale('locale_key.pages.datahub.filter_dropdown') }}
          </p>
          <p
            class="text-muted filter-text text-truncate col-7 m-0"
            *ngIf="customMultipleFilters.length > 0 || selectedSatusesLocaleKey.size > 0"
            title="{{ getCustomFilterText() }}"
          >
            : {{ getCustomFilterText() }}
          </p>
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="filter-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="filter-button"
        >
          <div class="accordion-body px-0 pt-0">
            <div class="bg-light-gray col pt-3">
              <div class="d-flex flex-row justify-content-between px-2">
                <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                <a class="underline" (click)="clearAllFilters()">
                  {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                </a>
              </div>
              <div class="row px-2">
                <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && selectedSatusesLocaleKey.size == 0">
                  {{ locale('locale_key.pages.datahub.filter_none') }}
                </i>
                <p
                  class="text-muted text-truncate"
                  *ngIf="customMultipleFilters.length > 0 || selectedSatusesLocaleKey.size > 0"
                >
                  {{ getCustomFilterText() }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
              <div class="d-flex flex-row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="isStatusSelected('locale_key.general.state.added')"
                    (change)="changeShowFilter($event)"
                    name="status"
                    id="locale_key.general.state.added"
                  />
                  <label class="form-check-label" for="locale_key.general.state.added">
                    {{ locale('locale_key.pages.datahub.button.show_added_tables') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="isStatusSelected('locale_key.general.state.modified')"
                    (change)="changeShowFilter($event)"
                    name="status"
                    id="locale_key.general.state.modified"
                  />
                  <label class="form-check-label" for="locale_key.general.state.modified">
                    {{ locale('locale_key.pages.datahub.button.show_modified_tables') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="isStatusSelected('locale_key.general.state.not_added')"
                    (change)="changeShowFilter($event)"
                    name="status"
                    id="locale_key.general.state.not_added"
                  />
                  <label class="form-check-label" for="locale_key.general.state.not_added">
                    {{ locale('locale_key.pages.datahub.button.show_not_added_tables') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#systemNameCollapse"
                      aria-expanded="true"
                      aria-controls="systemNameCollapse"
                    >
                      {{ locale('locale_key.pages.datahub.automated_feeds_portal.system_name') }}
                    </button>
                  </h2>
                  <div
                    id="systemNameCollapse"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterSystems(systemSearch.value)"
                            #systemSearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <a class="underline" (click)="selectAll('systemCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('systemCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let system of filteredSystemsForCustomFilters; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="systemCheckbox"
                          id="system{{ i }}"
                          [checked]="isCustomFilterSelected(system)"
                          (change)="changeCustomFilters($event, system)"
                        />
                        <label for="system{{ i }}">{{ system.name | titlecase }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      [disabled]="datahubService.isDataCategoryFilter()"
                      [ngClass]="{ 'text-muted': datahubService.isDataCategoryFilter() }"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#companyCollapse"
                      aria-expanded="false"
                      aria-controls="companyCollapse"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}
                      </span>
                    </button>
                  </h2>
                  <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterEntities(entitySearch.value)"
                            #entitySearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <a class="underline" (click)="selectAll('entityCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('entityCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let item0 of filteredEntities; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="entityCheckbox"
                          id="{{ item0.key }}"
                          [checked]="isCustomFilterSelected(item0)"
                          (change)="changeCustomFilters($event, item0, 0)"
                        />
                        <i
                          class="{{ depTaxonomy.icon(item0) }} me-2 fs-5"
                          [ngStyle]="{ color: depTaxonomy.color(item0) }"
                        ></i>
                        <label>{{ item0.getLabel() }}</label>
                        <div class="ms-3">
                          <div *ngFor="let item1 of depTaxonomy.childrenSortedByOrdinal(item0.key); let i = index">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              class="entityCheckbox"
                              id="{{ item1.key }}"
                              [checked]="isCustomFilterSelected(item1)"
                              (change)="changeCustomFilters($event, item1, 1)"
                            />
                            <label>{{ item1.getLabel() }}</label>
                            <div class="ms-3">
                              <div *ngFor="let item2 of depTaxonomy.childrenSortedByOrdinal(item1.key); let i = index">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  class="entityCheckbox"
                                  id="{{ item2.key }}"
                                  [checked]="isCustomFilterSelected(item2)"
                                  (change)="changeCustomFilters($event, item2, 2)"
                                />
                                <label>{{ item2.getLabel() }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {{ locale('locale_key.pages.datahub.table_header.reporting_period') }}
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="startIs"
                            value="startIs"
                          />
                          <label class="form-check-label" for="startIs">
                            {{ locale('locale_key.pages.datahub.table_header.starting_date_is') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="startIsBetween"
                            value="startIsBetween"
                          />
                          <label class="form-check-label" for="startIsBetween">
                            {{ locale('locale_key.pages.datahub.table_header.starting_date_is_between') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="startIsBefore"
                            value="startIsBefore"
                          />
                          <label class="form-check-label" for="startIsBefore">
                            {{ locale('locale_key.pages.datahub.table_header.starting_date_is_before') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="startIsAfter"
                            value="startIsAfter"
                          />
                          <label class="form-check-label" for="startIsAfter">
                            {{ locale('locale_key.pages.datahub.table_header.starting_date_is_after') }}
                          </label>
                        </div>
                      </div>
                      <div class="d-flex flex-row">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="endIs"
                            value="endIs"
                          />
                          <label class="form-check-label" for="endIs">
                            {{ locale('locale_key.pages.datahub.table_header.end_date_is') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="endIsBetween"
                            value="endIsBetween"
                          />
                          <label class="form-check-label" for="endIsBetween">
                            {{ locale('locale_key.pages.datahub.table_header.end_date_is_between') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="endIsBefore"
                            value="endIsBefore"
                          />
                          <label class="form-check-label" for="endIsBefore">
                            {{ locale('locale_key.pages.datahub.table_header.end_date_is_before') }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="periodFilter"
                            (change)="date1 = null; date2 = null"
                            name="period"
                            id="endIsAfter"
                            value="endIsAfter"
                          />
                          <label class="form-check-label" for="endIsAfter">
                            {{ locale('locale_key.pages.datahub.table_header.end_date_is_after') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="ms-5">
                      <input
                        class="form-control d-inline ms-1"
                        type="date"
                        [(ngModel)]="date1"
                        (change)="handleDateCustomFilters()"
                      />
                      <ng-container *ngIf="periodFilter == 'endIsBetween' || periodFilter == 'startIsBetween'">
                        {{ locale('locale_key.pages.datahub.data_entries.and') }}
                        <input
                          class="form-control d-inline ms-1"
                          [min]="date1"
                          type="date"
                          [(ngModel)]="date2"
                          (change)="handleDateCustomFilters()"
                        />
                      </ng-container>
                      <span class="underline text-muted" (click)="clearDateFilter()">
                        {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
        <button
          type="button"
          class="form-control"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ this.locale('locale_key.pages.datahub.filter_sort_by') }}
          {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <ng-container *ngFor="let option of sortedByOptions">
            <div class="dropdown-divider" *ngIf="option.divider"></div>
            <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="scroll" style="max-height: 65vh" *ngIf="!searchKeyword || searchKeyword == ''">
      <ng-container *ngFor="let system of automatedFeedSystems; let i = index">
        <a
          class="accordion accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
          id="systemHeader{{ i }}"
          data-bs-toggle="collapse"
          href="#systems{{ i }}"
          aria-expanded="true"
          attr.aria-controls="systems{{ i }}"
        >
          <div class="col-xl-11 col-lg-11 col-md-11 col-10">
            <h5 class="text-truncate dark-text d-inline">
              <img class="basic-cropper main-demo-image" [src]="system.imgSrc" alt="Picture" style="max-width: 100%" />
              {{ system.name | titlecase }}
            </h5>
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-0">
            <a class="p-0 my-auto ms-auto float-end" style="max-height: 75px">
              <i class="icon float-end me-4"></i>
            </a>
          </div>
        </a>
        <div
          id="systems{{ i }}"
          role="tabpanel"
          attr.aria-labelledby="systemHeader{{ i }}"
          class="border show"
          style="overflow: hidden !important; background-color: var(--complementary-color1)"
        >
          <div class="card-content">
            <div class="card-body px-2">
              <div class="border-l border-r border-t m-0 p-0">
                <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                  <div class="col-xl-2 col-lg-2 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                    <span
                      class="my-auto text-truncate bold"
                      [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                    >
                      <b>{{ locale('locale_key.pages.datahub.data_entries.table_name') }}</b>
                    </span>
                  </div>
                  <div class="col-xl-3 col-3 d-none d-lg-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.button.data_category') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.data_entries.table_header.report_period') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.data_entries.table_header.status') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.data_entries.table_header.last_updated') }}
                    </span>
                  </div>
                  <div class="col-xl-1 col-1 d-none d-lg-flex justify-content-end my-auto">
                    <span class="my-auto text-truncate bold"></span>
                  </div>
                </div>
                <ng-container *ngFor="let info of system.automatedFeeds">
                  <div
                    class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                    [ngClass]="{ selected: selectedInfo == info }"
                    (click)="selectedInfo = info"
                  >
                    <div class="col-xl-2 col-lg-2 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                      <h5
                        class="text-bold-400 my-auto text-truncate"
                        [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                      >
                        {{ info.taxonomyEntity ? info.taxonomyEntity.getLabel() : info.taxonomyKey }}
                      </h5>
                    </div>
                    <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                      <label *ngIf="info.taxonomyEntity">
                        <a class="text-truncate fs-5">
                          <i
                            class="{{ depTaxonomy.icon(info.rootParentEntity) }} fs-5"
                            [ngStyle]="{ color: depTaxonomy.color(info.rootParentEntity) }"
                          ></i>
                          <h5 class="dark-text d-inline text-truncate">
                            {{ info.rootParentEntity?.getLabel() }} > {{ info.parentEntity?.getLabel() }}
                          </h5>
                        </a>
                      </label>
                    </div>
                    <div class="col-xl-2 col-2 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                      <span class="my-auto badge badge-turquoise">{{ info.timeFromString }}</span>
                      -
                      <span class="my-auto badge badge-turquoise">{{ info.timeToString }}</span>
                    </div>
                    <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto text-truncate">
                      <div class="badge" [ngStyle]="{ 'background-color': info.statusColor() }" style="color: white">
                        <i class="la {{ info.statusIcon() }} font-small-3"></i>
                        <span class="font-small-3">{{ locale(info.statusLocaleKey()) }}</span>
                      </div>
                    </div>
                    <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                      <span class="my-auto text-truncate">{{ info.lastModifiedString }}</span>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-6 col-2 d-flex justify-content-end float-end pe-0">
                      <div class="btn-group my-auto">
                        <i
                          class="las la-cloud my-auto fs-5 me-2 cursor"
                          title="{{ locale('locale_key.general.buttons.add_to_master_table') }}"
                          (click)="connecToPipeline(info)"
                          *ngIf="
                            !info.transformed &&
                            !(info.loadInProgress || info.saveInProgress || info.connectionInProgress)
                          "
                        ></i>
                        <i
                          class="las la-cloud-off my-auto fs-5 me-2 cursor"
                          title="{{ locale('locale_key.general.buttons.remove_from_maser_table') }}"
                          (click)="disconnectFromPipeline(info)"
                          *ngIf="
                            info.transformed &&
                            !(info.loadInProgress || info.saveInProgress || info.connectionInProgress)
                          "
                        ></i>
                        <i
                          class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 mx-2"
                          (click)="openAutomatedFeed(info)"
                        ></i>
                        <div
                          class="description-btn d-flex justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="la la-ellipsis-h fs-5 my-auto" style="rotate: 90deg"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="scroll" style="max-height: 65vh" *ngIf="searchKeyword && searchKeyword != ''">
      <a
        class="accordion accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
        id="systemHeader"
        data-bs-toggle="collapse"
        href="#systems"
        aria-expanded="true"
        attr.aria-controls="systems"
      >
        <div class="col-xl-11 col-lg-11 col-md-11 col-10">
          <h5 class="text-truncate dark-text d-inline ps-2">
            {{ locale('locale_key.pages.datahub.search_result', { keyword: searchKeyword }) }}
          </h5>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-0">
          <a class="p-0 my-auto ms-auto float-end" style="max-height: 75px">
            <i class="icon float-end me-4"></i>
          </a>
        </div>
      </a>
      <div
        id="systems"
        role="tabpanel"
        attr.aria-labelledby="systemHeader"
        class="border show"
        style="overflow: hidden !important; background-color: var(--complementary-color1)"
      >
        <div class="card-content">
          <div class="card-body px-2">
            <div class="border-l border-r border-t m-0 p-0">
              <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                <div class="col-xl-2 col-lg-2 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                  <span
                    class="my-auto text-truncate bold"
                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                  >
                    <b>{{ locale('locale_key.pages.datahub.data_entries.table_name') }}</b>
                  </span>
                </div>
                <div class="col-xl-3 col-3 d-none d-lg-flex justify-content-start my-auto">
                  <span class="my-auto text-truncate bold">
                    {{ locale('locale_key.pages.datahub.button.data_category') }}
                  </span>
                </div>
                <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                  <span class="my-auto text-truncate bold">
                    {{ locale('locale_key.pages.datahub.data_entries.table_header.report_period') }}
                  </span>
                </div>
                <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                  <span class="my-auto text-truncate bold">
                    {{ locale('locale_key.pages.datahub.data_entries.table_header.status') }}
                  </span>
                </div>
                <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                  <span class="my-auto text-truncate bold">
                    {{ locale('locale_key.pages.datahub.data_entries.table_header.last_updated') }}
                  </span>
                </div>
                <div class="col-xl-1 col-1 d-none d-lg-flex justify-content-end my-auto">
                  <span class="my-auto text-truncate bold"></span>
                </div>
              </div>
              <ng-container *ngFor="let info of filteredAutomatedFeeds">
                <div
                  class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                  [ngClass]="{ selected: selectedInfo == info }"
                  (click)="selectedInfo = info"
                >
                  <div class="col-xl-2 col-lg-2 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                    <h5
                      class="text-bold-400 my-auto text-truncate"
                      [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                    >
                      {{ info.taxonomyEntity ? info.taxonomyEntity.getLabel() : info.taxonomyKey }}
                    </h5>
                  </div>
                  <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                    <label *ngIf="info.taxonomyEntity">
                      <a class="text-truncate fs-5">
                        <i
                          class="{{ depTaxonomy.icon(info.rootParentEntity) }} fs-5"
                          [ngStyle]="{ color: depTaxonomy.color(info.rootParentEntity) }"
                        ></i>
                        <h5 class="dark-text d-inline text-truncate">
                          {{ info.rootParentEntity?.getLabel() }} > {{ info.parentEntity?.getLabel() }}
                        </h5>
                      </a>
                    </label>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                    <span class="my-auto badge badge-turquoise">{{ info.timeFromString }}</span>
                    -
                    <span class="my-auto badge badge-turquoise">{{ info.timeToString }}</span>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto text-truncate">
                    <div class="badge" [ngStyle]="{ 'background-color': info.statusColor() }" style="color: white">
                      <i class="la {{ info.statusIcon() }} font-small-3"></i>
                      <span class="font-small-3">{{ locale(info.statusLocaleKey()) }}</span>
                    </div>
                  </div>
                  <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                    <span class="my-auto text-truncate">{{ info.lastModifiedString }}</span>
                  </div>
                  <div class="col-xl-1 col-lg-1 col-md-6 col-2 d-flex justify-content-end float-end pe-0">
                    <div class="btn-group my-auto">
                      <i
                        class="las la-cloud my-auto fs-5 me-2 cursor"
                        title="{{ locale('locale_key.general.buttons.add_to_master_table') }}"
                        (click)="connecToPipeline(info)"
                        *ngIf="
                          !info.transformed &&
                          !(info.loadInProgress || info.saveInProgress || info.connectionInProgress)
                        "
                      ></i>
                      <i
                        class="las la-cloud-off my-auto fs-5 me-2 cursor"
                        title="{{ locale('locale_key.general.buttons.remove_from_maser_table') }}"
                        (click)="disconnectFromPipeline(info)"
                        *ngIf="
                          info.transformed && !(info.loadInProgress || info.saveInProgress || info.connectionInProgress)
                        "
                      ></i>
                      <i
                        class="la la-arrow-right cursor fs-4 my-auto mb-0 pb-0 mx-2"
                        (click)="openAutomatedFeed(info)"
                      ></i>
                      <div
                        class="description-btn d-flex justify-content-center"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="la la-ellipsis-h fs-5 my-auto" style="rotate: 90deg"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
