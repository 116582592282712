<div
  id="traceModal"
  class="modal-content m-0 p-0 mx-auto"
  style="border-top: none !important"
  [ngStyle]="screenSize.isXSmallSize() || screenSize.isSmallerSize() ? { left: '0px', right: '0px' } : {}"
>
  <div class="modal-header px-1 bg-blue" style="max-height: 40px">
    <div class="col-10 d-flex flex-row">
      <h5 class="modal-title text-white pb-1 text-truncate ms-0 px-0">
        <i class="la la-table me-2 ms-0"></i>
        {{
          isTracingMasterTable
            ? masterTableDataGridServices[0].currentPageData.label
            : isDashboardPage && chartWrapper.setting.titleOption != 'kpi'
              ? chartWrapper.setting.selectTitle(chartWrapper.insight)
              : chartWrapper.insight.title
        }}
      </h5>
    </div>
    <div class="col-2 d-flex flex-row">
      <span class="ms-auto">
        <a data-action="expand" class="text-white">
          <i
            class="las la-expand-arrows-alt text-white mx-0 px-0"
            style="position: relative; top: 2px"
            (click)="expandModal()"
          ></i>
        </a>
        <a class="ms-auto btn close mx-0 px-0">
          <span aria-hidden="false" class="list-inline text-white fs-4 my-auto">
            <a (click)="closeModal(true)"><i class="las la-times pt-1 my-auto mx-0 px-0 text-white"></i></a>
          </span>
        </a>
      </span>
    </div>
  </div>
  <div class="modal-body px-1 mx-0">
    <div class="nav nav-underline nav-tabs px-3">
      <ul
        class="nav nav-underline nav-tabs no-hover-bg nav-justified"
        style="border-bottom: none !important"
        [ngStyle]="screenSize.isSmallerSize() ? { width: '100%' } : { width: '49%' }"
      >
        <li class="nav-item" *ngIf="isTracingKPI">
          <a
            class="nav-link tab1 active"
            id="baseKpiDataTab"
            data-bs-toggle="tab"
            aria-controls="kpiDataTab"
            href="#kpiDataTab"
            aria-expanded="true"
          >
            {{ locale('locale_key.pages.trace_data_modal.section.kpi_data.title') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link tab2 w-50"
            [ngClass]="{ active: isTracingMasterTable }"
            id="baseSourceTab"
            data-bs-toggle="tab"
            aria-controls="sourceTab"
            href="#sourceTab"
            aria-expanded="false"
          >
            {{ locale('locale_key.pages.trace_data_modal.section.trace_source.title') }}
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content px-0 h-100 scroll-y">
      <div
        role="tabpanel"
        class="tab-pane px-0 mx-0 active"
        id="kpiDataTab"
        aria-expanded="true"
        aria-labelledby="baseKpiDataTab"
        *ngIf="isTracingKPI"
      >
        <div
          class="d-flex flex-column h-100 scroll-y"
          style="max-width: 100% !important; box-sizing: border-box !important"
        >
          <!-- empty toolbar + page-header and 3dots menu columns-->
          <div class="d-flex flex-row">
            <!-- header + 3 dots-->
            <div class="flex-fill">
              <div class="d-flex flex-row justify-content-between">
                <!-- <span class="accordion mb-3 mt-3 ms-2 collapsed" id="showDef" data-bs-toggle="collapse" href='#definition' aria-expanded="true" aria-controls='definition' (click)="showDefinition=!showDefinition">
                  <span class="underline">{{ showDefinition ? locale('locale_key.pages.trace_data_modal.section.kpi_data.button.show_definition') : locale('locale_key.pages.trace_data_modal.section.kpi_data.button.hide_definition')}}</span>
                  <li class="las cursor" [ngClass]="showDefinition ? 'la-angle-down' : 'la-angle-up'"></li>
                </span> -->
                <!-- dropdown menu instead of toolbar for smaller pages-->
                <div class="btn-group mb-auto" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
                  <button
                    id="button-alignment"
                    dropdownToggle
                    type="button"
                    class="btn dropdown-toggle page-toolbar-dropdown-toggle mt-0 pt-3"
                    aria-controls="dropdown-alignment"
                  >
                    <i class="la la-ellipsis-h fs-3 mt-0 pt-0" style="rotate: 90deg"></i>
                  </button>
                  <ul
                    id="dropdown-alignment"
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
                    role="menu"
                    aria-labelledby="button-alignment"
                  >
                    <ng-container *ngIf="!isDownloading()">
                      <div *ngFor="let toolbarGroup of kpiToolbarModal; let groupIndex = index">
                        <ng-container *ngFor="let toolbarButton of toolbarGroup">
                          <li role="menuitem">
                            <div *ngIf="toolbarButton.visible()" class="dropdown">
                              <button
                                type="button"
                                class="btn page-toolbar-button"
                                data-bs-toggle="dropdown"
                                data-placement="top"
                                [title]="toolbarButton.tooltip"
                                show-delay="300"
                              >
                                <i [class]="toolbarButton.icon" class="fs-4"></i>
                                <span>{{ toolbarButton.shortLabel }}</span>
                              </button>
                              <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                  >
                                    xlsx
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                  >
                                    csv
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ng-container>
                        <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
                      </div>
                    </ng-container>
                    <div class="mx-auto" *ngIf="isDownloading()">
                      <div class="ball-pulse loader-primary">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- toolbar + content columns -->
          <div class="flex-fill">
            <div class="d-flex flex-row h-100">
              <!-- toolbar column-->
              <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
                <ng-container *ngIf="!isDownloading()">
                  <div class="page-toolbar-button-group" *ngFor="let toolbarGroup of kpiToolbarModal">
                    <ng-container *ngFor="let toolbarButton of toolbarGroup">
                      <div *ngIf="toolbarButton.visible()" class="dropdown">
                        <button
                          type="button"
                          class="btn page-toolbar-button"
                          data-bs-toggle="dropdown"
                          data-placement="top"
                          [title]="toolbarButton.tooltip"
                          show-delay="300"
                        >
                          <i [class]="toolbarButton.icon" class="fs-4"></i>
                          <span>{{ toolbarButton.shortLabel }}</span>
                        </button>
                        <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                          <li>
                            <a class="dropdown-item" (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')">
                              xlsx
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" (click)="handleToolbarAction(toolbarButton.actionName, 'csv')">
                              csv
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="mx-auto" *ngIf="isDownloading()">
                  <div class="ball-pulse loader-primary">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <!-- content column-->
              <div class="flex-fill ps-2">
                <div class="d-flex h-100 flex-column me-2 px-3" style="max-width: 100vw !important">
                  <!-- <div class="border pt-3 px-3 collapse mb-3" id="definition" aria-labelledby="showDef" style="border-radius: 5px;">
                    <p>
                      {{ locale('locale_key.pages.trace_data_modal.kpi_definition.title') }} <b>{{ chartWrapper.insight.title }}</b>
                      {{ locale('locale_key.pages.trace_data_modal.kpi_definition.tracks') }}
                      <span class="ps-2" *ngFor="let field of getMeasurementFields(); let last = last">
                        <div class="badge px-2 badge-purple">{{chartWrapper.insight.pipeline.definition.name == 'sum' ?
                          locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_calculation.option.total') :
                          locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_calculation.option.average')}}</div>
                        <div class="badge px-2 badge-info">{{field.label}}</div>
                        <small class="ms-1" *ngIf="!last">{{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.and') }}</small>
                      </span>
                      {{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.in_the_unit') }}
                      <span class="badge px-2 badge-info bold">{{chartWrapper.insight.pipeline.definition.units[0]}}</span>
                      {{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.is_displayed') }}
                      <span class="badge px-2 badge-info bold">{{chartWrapper.insight.pipeline.getTimeScaleText()}}</span>
                    </p>
                  </div> -->
                  <div class="w-100">
                    <p>{{ locale('locale_key.pages.trace_data_modal.filters.title') }}</p>
                    <div class="btn-toolbar d-flex justify-content-between" role="toolbar">
                      <div
                        class="d-flex flex-row p-0 flex-grow-1 m-0"
                        style="max-width: 100% !important; box-sizing: border-box !important"
                      >
                        <ul class="list-inline m-0" style="box-sizing: border-box !important">
                          <a class="me-3">{{ locale('locale_key.pages.kpi_library.compare_on.title') }}</a>
                          <li class="list-inline-item">
                            <button
                              data-bs-toggle="dropdown"
                              type="button"
                              class="btn btn-icon action-icon d-flex justify-content-between px-0"
                            >
                              <span class="btn-min-width-5">{{ chartWrapper.setting.principalComparisionText() }}</span>
                              <li class="las la-angle-down me-1 my-auto"></li>
                            </button>
                            <ul class="dropdown-menu btn-min-width p-0" id="menuChoices" role="menu">
                              <a
                                class="dropdown-item"
                                (click)="chartWrapper.setting.selectPrincipalComparisonTime(); updateTable()"
                                *ngIf="chartWrapper.insight.isTimed()"
                              >
                                Time
                              </a>
                              <a
                                class="dropdown-item"
                                (click)="chartWrapper.setting.selectPrincipalCategoryFieldIndex(j); updateTable()"
                                *ngFor="let category of chartWrapper.options.categoryOptions; let j = index"
                              >
                                {{ category.getLabel() }}
                              </a>
                            </ul>
                          </li>
                        </ul>

                        <ul
                          class="list-inline m-0"
                          *ngIf="chartWrapper.insight.isTimed()"
                          style="box-sizing: border-box !important"
                        >
                          <a class="me-3">
                            {{ locale('locale_key.pages.data_request.data_requests_section.button.show_results') }}:
                          </a>
                          <li class="list-inline-item">
                            <button
                              data-bs-toggle="dropdown"
                              type="button"
                              class="btn btn-icon action-icon d-flex justify-content-between px-0"
                            >
                              <span class="btn-min-width-5">
                                {{
                                  locale(
                                    'locale_key.insight-detail.time_options.' +
                                      chartWrapper.setting.timeOption()?.timeScaleToAdverb().toLowerCase()
                                  )
                                }}
                              </span>
                              <li class="las la-angle-down me-1 my-auto"></li>
                            </button>
                            <ul class="dropdown-menu p-0 btn-min-width" id="menuChoices" role="menu">
                              <a
                                class="dropdown-item"
                                *ngFor="let timeOption of chartWrapper.options.timeOptions; let timeOptionIndex = index"
                                (click)="chartWrapper.setting.setTimeScaleIndex(timeOptionIndex); updateTable()"
                              >
                                {{
                                  locale(
                                    'locale_key.insight-detail.time_options.' +
                                      timeOption.timeScaleToAdverb().toLowerCase()
                                  )
                                }}
                              </a>
                            </ul>
                          </li>
                        </ul>

                        <ul
                          class="list-inline m-0"
                          *ngIf="
                            chartWrapper.insight.isTimed() && chartWrapper.setting.principalCategoryFieldIndex > -1
                          "
                          style="max-width: 20% !important; box-sizing: border-box !important"
                        >
                          <li class="list-inline-item text-truncate">
                            <button
                              data-bs-toggle="dropdown"
                              type="button"
                              class="btn btn-icon action-icon d-flex justify-content-between px-0"
                            >
                              <span class="btn-min-width-3">{{ chartWrapper.setting.timeValue }}</span>
                              <li class="las la-angle-down me-1 my-auto"></li>
                            </button>
                            <ul class="dropdown-menu btn-min-width-5 p-0" id="menuChoices" role="menu">
                              <a
                                class="dropdown-item"
                                *ngFor="let timeValue of chartWrapper.setting.timeValues()"
                                (click)="chartWrapper.setting.setTimeValue(timeValue); updateTable()"
                              >
                                {{ timeValue }}
                              </a>
                            </ul>
                          </li>
                        </ul>

                        <ul
                          class="list-inline m-0"
                          *ngFor="let categoryOption of chartWrapper.setting.nonPricipalCategoryOptions()"
                          style="box-sizing: border-box !important"
                        >
                          <a class="me-3">{{ categoryOption.getLabel() }}:</a>
                          <li class="list-inline-item">
                            <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon arrow">
                              <span class="btn-min-width-5">
                                {{ chartWrapper.setting.categoryValue(categoryOption.field) }}
                              </span>
                              <li class="las la-angle-down me-1 my-auto"></li>
                            </button>
                            <ul class="dropdown-menu p-0" id="menuChoices" role="menu">
                              <li>
                                <a
                                  class="dropdown-item text-truncate"
                                  *ngFor="let catVal of categoryOption.values"
                                  (click)="
                                    chartWrapper.setting.setCategoryValue(categoryOption.field, catVal); updateTable()
                                  "
                                >
                                  {{ catVal }}
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <datagrid-table-editor [dataGrid]="tableDataGridService"></datagrid-table-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        role="tabpanel"
        class="tab-pane px-0 mx-0"
        id="sourceTab"
        aria-labelledby="baseSourceTab"
        [ngClass]="{ active: isTracingMasterTable }"
      >
        <div id="accordionWrap1" role="tablist" aria-multiselectable="true" class="scroll-y mx-0 px-0">
          <div
            id="acc0"
            class="accordion accordion-icon-rotate mb-2 border mt-4 mx-2"
            (click)="addActiveClass($event)"
            *ngIf="isTracingKPI"
          >
            <span
              id="item"
              class="p-0 border-b"
              data-bs-toggle="collapse"
              href="#accordion"
              aria-expanded="true"
              [attr.aria-controls]="'accordion'"
              style="max-height: 75px"
            >
              <div class="row mx-auto w-100 my-1 py-3">
                <div class="my-auto px-1" [ngClass]="!isshowMoreButton ? 'col-9' : 'col-7'">
                  <div class="d-flex flex-row justify-content-start align-items-center h-100 my-auto">
                    <h6 class="pt-3 px-3">
                      {{ locale('locale_key.pages.trace_data_modal.kpi_definition.title') }}
                      <b>{{ chartWrapper.insight.title }}</b>
                      <!-- <ng-container *ngIf="!isshowMoreButton">
                        {{ locale('locale_key.pages.trace_data_modal.kpi_definition.tracks') }}
                        <span class="ps-2" *ngFor="let field of getMeasurementFields(); let last = last">
                          <div class="badge px-2 badge-purple">{{chartWrapper.insight.pipeline.definition.name == 'sum' ?
                            locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_calculation.option.total') :
                            locale('locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_calculation.option.average')}}</div>
                          <div class="badge px-2 badge-info">{{field.label}}</div>
                          <small class="ms-1" *ngIf="!last">{{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.and') }}</small>
                        </span>
                        {{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.in_the_unit') }}
                        <span class="badge px-2 badge-info bold">{{chartWrapper.insight.pipeline.definition.units[0]}}</span>
                        {{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.is_displayed') }}
                        <span class="badge px-2 badge-info bold">{{chartWrapper.insight.pipeline.getTimeScaleText()}}</span>
                      </ng-container> -->
                    </h6>
                  </div>
                </div>
                <!-- <div class="my-auto border-l text-start mx-0 px-1" [ngClass]="isshowMoreButton ? 'col-4' : 'col-2'">
                  <div class="d-flex flex-row justify-content-start h-100 align-content-center text-start">
                    <a id="link0" class="underline dark-text me-auto" (click)="showMoreShowLess('isshowMoreButton', $event)"><small class="text-muted"> {{ isshowMoreButton ? locale('locale_key.pages.trace_data_modal.button.show_more') :
                        locale('locale_key.pages.trace_data_modal.button.show_less') }}</small></a>
                  </div>
                </div> -->
                <div
                  class="mx-0 px-1 my-auto d-flex flex-row justify-content-end ms-auto"
                  [ngClass]="!screenSize.isXLargeSize() ? 'col-1' : 'col-2'"
                >
                  <button
                    class="btn tertiary-button me-auto"
                    style="min-width: fit-content; max-height: 37px"
                    *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize() && !screenSize.isLargeSize()"
                    (click)="openKpiLibrary()"
                  >
                    {{ locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_kpi') }}
                  </button>
                  <div
                    class="btn-group ms-auto"
                    *ngIf="screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()"
                  >
                    <button
                      type="button"
                      class="description-btn text-end mx-0 px-0 ms-auto"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="la la-ellipsis-h fs-3 mx-0 px-0" style="rotate: 90deg"></i>
                    </button>
                    <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                      <button class="dropdown-item ms-0 ps-1" type="button" (click)="openKpiLibrary()">
                        {{ locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_kpi') }}
                      </button>
                    </div>
                  </div>
                  <i class="icon m-1 text-end"></i>
                </div>
              </div>
            </span>
            <div
              [id]="'accordion'"
              role="tabpanel"
              data-bs-parent="#accordionWrap1"
              [attr.aria-labelledby]="'item'"
              class="collapse show border-t"
            >
              <div class="card-content">
                <div class="card-body px-1 pt-0">
                  <div class="row border-shade py-3 px-1 suppliers-filter m-1 mb-1 bg-text-white mb-2">
                    <div
                      class="d-flex flex-column h-100"
                      style="max-width: 100% !important; box-sizing: border-box !important"
                    >
                      <!-- empty toolbar + page-header and 3dots menu columns-->
                      <div class="d-flex flex-row">
                        <!-- empty-->
                        <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
                        <!-- header + 3 dots-->
                        <div class="flex-fill">
                          <div class="d-flex flex-row justify-content-between">
                            <!-- dropdown menu instead of toolbar for smaller pages-->
                          </div>
                        </div>
                      </div>
                      <!-- toolbar + content columns -->
                      <div class="flex-fill">
                        <div class="d-flex flex-row h-100">
                          <!-- toolbar column-->
                          <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
                            <ng-container *ngIf="!isDownloading()">
                              <div class="page-toolbar-button-group" *ngFor="let toolbarGroup of kpiToolbarModal">
                                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                  <div *ngIf="toolbarButton.visible()" class="dropdown">
                                    <button
                                      type="button"
                                      class="btn page-toolbar-button"
                                      data-bs-toggle="dropdown"
                                      data-placement="top"
                                      [title]="toolbarButton.tooltip"
                                      show-delay="300"
                                    >
                                      <i [class]="toolbarButton.icon" class="fs-4"></i>
                                      <span>{{ toolbarButton.shortLabel }}</span>
                                    </button>
                                    <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                        >
                                          xlsx
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                        >
                                          csv
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                            <div class="mx-auto" *ngIf="isDownloading()">
                              <div class="ball-pulse loader-primary">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <!-- content column-->
                          <div class="flex-fill ps-2">
                            <div class="d-flex h-100 flex-column me-2" style="max-width: 100vw !important">
                              <div class="w-100 pt-4">
                                <p class="small">{{ locale('locale_key.pages.trace_data_modal.filters.title') }}</p>
                                <div class="btn-toolbar d-flex justify-content-between" role="toolbar">
                                  <div
                                    class="d-flex flex-row p-0 flex-grow-1 m-0"
                                    style="max-width: 100% !important; box-sizing: border-box !important"
                                  >
                                    <ul class="list-inline m-0" style="box-sizing: border-box !important">
                                      <a class="me-3">{{ locale('locale_key.pages.kpi_library.compare_on.title') }}</a>
                                      <li class="list-inline-item">
                                        <button
                                          data-bs-toggle="dropdown"
                                          type="button"
                                          class="btn btn-icon action-icon d-flex justify-content-between px-0"
                                        >
                                          <span class="btn-min-width-5">
                                            {{ chartWrapper.setting.principalComparisionText() }}
                                          </span>
                                          <li class="las la-angle-down me-1 my-auto"></li>
                                        </button>
                                        <ul class="dropdown-menu btn-min-width p-0" id="menuChoices" role="menu">
                                          <a
                                            class="dropdown-item"
                                            (click)="
                                              chartWrapper.setting.selectPrincipalComparisonTime(); updateTable()
                                            "
                                            *ngIf="chartWrapper.insight.isTimed()"
                                          >
                                            Time
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            (click)="
                                              chartWrapper.setting.selectPrincipalCategoryFieldIndex(j); updateTable()
                                            "
                                            *ngFor="let category of chartWrapper.options.categoryOptions; let j = index"
                                          >
                                            {{ category.getLabel() }}
                                          </a>
                                        </ul>
                                      </li>
                                    </ul>

                                    <ul
                                      class="list-inline m-0"
                                      *ngIf="chartWrapper.insight.isTimed()"
                                      style="box-sizing: border-box !important"
                                    >
                                      <a class="me-3">
                                        {{
                                          locale(
                                            'locale_key.pages.data_request.data_requests_section.button.show_results'
                                          )
                                        }}:
                                      </a>
                                      <li class="list-inline-item">
                                        <button
                                          data-bs-toggle="dropdown"
                                          type="button"
                                          class="btn btn-icon action-icon d-flex justify-content-between px-0"
                                        >
                                          <span class="btn-min-width-5">
                                            {{ chartWrapper.setting.timeOption().timeScaleToAdverb() }}
                                          </span>
                                          <li class="las la-angle-down me-1 my-auto"></li>
                                        </button>
                                        <ul class="dropdown-menu p-0 btn-min-width" id="menuChoices" role="menu">
                                          <a
                                            class="dropdown-item"
                                            *ngFor="
                                              let timeOption of chartWrapper.options.timeOptions;
                                              let timeOptionIndex = index
                                            "
                                            (click)="
                                              chartWrapper.setting.setTimeScaleIndex(timeOptionIndex); updateTable()
                                            "
                                          >
                                            {{
                                              locale(
                                                'locale_key.insight-detail.time_options.' +
                                                  timeOption.timeScaleToAdverb().toLowerCase()
                                              )
                                            }}
                                          </a>
                                        </ul>
                                      </li>
                                    </ul>

                                    <ul
                                      class="list-inline m-0"
                                      *ngIf="
                                        chartWrapper.insight.isTimed() &&
                                        chartWrapper.setting.principalCategoryFieldIndex > -1
                                      "
                                      style="max-width: 20% !important; box-sizing: border-box !important"
                                    >
                                      <li class="list-inline-item text-truncate">
                                        <button
                                          data-bs-toggle="dropdown"
                                          type="button"
                                          class="btn btn-icon action-icon d-flex justify-content-between px-0"
                                        >
                                          <span class="btn-min-width-3">{{ chartWrapper.setting.timeValue }}</span>
                                          <li class="las la-angle-down me-1 my-auto"></li>
                                        </button>
                                        <ul class="dropdown-menu btn-min-width-5 p-0" id="menuChoices" role="menu">
                                          <a
                                            class="dropdown-item"
                                            *ngFor="let timeValue of chartWrapper.setting.timeValues()"
                                            (click)="chartWrapper.setting.setTimeValue(timeValue); updateTable()"
                                          >
                                            {{ timeValue }}
                                          </a>
                                        </ul>
                                      </li>
                                    </ul>

                                    <ul
                                      class="list-inline m-0"
                                      *ngFor="let categoryOption of chartWrapper.setting.nonPricipalCategoryOptions()"
                                      style="box-sizing: border-box !important"
                                    >
                                      <a class="me-3">{{ categoryOption.getLabel() }}:</a>
                                      <li class="list-inline-item">
                                        <button
                                          data-bs-toggle="dropdown"
                                          type="button"
                                          class="btn btn-icon action-icon arrow"
                                        >
                                          <span class="btn-min-width-5">
                                            {{ chartWrapper.setting.categoryValue(categoryOption.field) }}
                                          </span>
                                          <li class="las la-angle-down me-1 my-auto"></li>
                                        </button>
                                        <ul class="dropdown-menu p-0" id="menuChoices" role="menu">
                                          <li>
                                            <a
                                              class="dropdown-item text-truncate"
                                              *ngFor="let catVal of categoryOption.values"
                                              (click)="
                                                chartWrapper.setting.setCategoryValue(categoryOption.field, catVal);
                                                updateTable()
                                              "
                                            >
                                              {{ catVal }}
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <p>{{ locale('locale_key.pages.trace_data_modal.section.trace_source.info') }}</p>
                              <datagrid-table-editor [dataGrid]="kpiDataGridService"></datagrid-table-editor>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row align-items-start justify-content-between">
            <ng-container *ngIf="masterTableDataGridServices; else noKPIRowSelection">
              <div
                class="mx-0 mt-3 pe-0"
                style="max-width: 3%; width: 3%"
                *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize() && isTracingKPI"
              >
                <span>
                  <i class="ft-corner-down-right bold fs-4 ms-auto ps-3" style="color: var(--blue-color)"></i>
                </span>
              </div>
              <div
                class="mx-0 pe-0 pe-2 ms-auto"
                style="max-width: 97%; width: 97%"
                [ngStyle]="
                  screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()
                    ? { 'max-width': '98%', width: '98%' }
                    : { 'max-width': '97%', width: '97%' }
                "
              >
                <div
                  id="acc1"
                  class="accordion accordion-icon-rotate mb-2 border mt-2 mx-0"
                  (click)="addActiveClass($event)"
                >
                  <span
                    [id]="'item1'"
                    class="p-0 collapsed border-b"
                    data-bs-toggle="collapse"
                    href="#accordion1"
                    aria-expanded="true"
                    [attr.aria-controls]="'accordion1'"
                    style="max-height: 75px !important"
                  >
                    <div class="row mx-auto w-100 my-1 py-3">
                      <div
                        class="my-auto px-1"
                        [ngClass]="
                          !isShowMoreButton1 && screenSize.isXLargeSize()
                            ? 'col-8'
                            : !isShowMoreButton1
                              ? 'col-9'
                              : 'col-7'
                        "
                      >
                        <div class="d-flex flex-row justify-content-start align-items-center h-100 my-auto">
                          <h6 class="pt-3 px-3">
                            <span *ngIf="isShowMoreButton1">
                              {{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables') }}:
                              <ng-container
                                *ngFor="let service of masterTableDataGridServices; let first = first; let last = last"
                              >
                                <ng-container *ngIf="!first">,</ng-container>
                                <b>{{ service.entity?.getLabel() }}</b>
                              </ng-container>
                            </span>
                            <span *ngIf="!isShowMoreButton1">
                              {{ locale('locale_key.pages.trace_data_modal.section.trace_source.info.master_table') }}
                              <b>{{ getTaxonomyEntitiesText() }}</b>
                            </span>
                            <i class="icon m-1 text-end" *ngIf="isTracingMasterTable"></i>
                          </h6>
                        </div>
                      </div>
                      <div
                        class="my-auto border-l text-start mx-0 px-1"
                        *ngIf="isTracingKPI"
                        [ngClass]="
                          isShowMoreButton1 && screenSize.isXLargeSize()
                            ? 'col-2'
                            : isShowMoreButton1
                              ? 'col-4'
                              : 'col-2'
                        "
                      >
                        <div class="d-flex flex-row justify-content-end h-100 align-content-center text-start">
                          <a
                            id="link1"
                            class="underline dark-text me-auto"
                            (click)="showMoreShowLess('isShowMoreButton1', $event)"
                          >
                            <small class="text-muted">
                              {{
                                isShowMoreButton1
                                  ? locale('locale_key.pages.trace_data_modal.button.show_more')
                                  : locale('locale_key.pages.trace_data_modal.button.show_less')
                              }}
                            </small>
                          </a>
                        </div>
                      </div>
                      <div
                        class="mx-0 px-1 my-auto d-flex flex-row justify-content-end ms-auto"
                        [ngClass]="!screenSize.isXLargeSize() ? 'col-1' : 'col-2'"
                        *ngIf="isTracingKPI"
                      >
                        <button
                          class="btn tertiary-button me-auto"
                          style="min-width: fit-content"
                          (click)="openMasterTable()"
                          *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize() && !screenSize.isLargeSize()"
                        >
                          {{ locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_datahub') }}
                        </button>
                        <div
                          class="btn-group ms-auto"
                          *ngIf="screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()"
                        >
                          <button
                            type="button"
                            class="description-btn text-end mx-0 px-0 ms-auto"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="la la-ellipsis-h fs-3 mx-0 px-0" style="rotate: 90deg"></i>
                          </button>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openMasterTable()">
                              {{
                                locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_datahub')
                              }}
                            </button>
                          </div>
                        </div>
                        <i class="icon m-1 text-end"></i>
                      </div>
                    </div>
                  </span>
                  <div
                    [id]="'accordion1'"
                    role="tabpanel"
                    data-bs-parent="#accordionWrap1"
                    [attr.aria-labelledby]="'item1'"
                    class="border-t collapse"
                  >
                    <div class="card-content">
                      <div class="card-body px-1 pt-0">
                        <div class="row m-2">
                          <div
                            class="d-flex flex-column h-100"
                            style="max-width: 100% !important; box-sizing: border-box !important"
                          >
                            <!-- empty toolbar + page-header and 3dots menu columns-->
                            <div class="d-flex flex-row">
                              <!-- empty-->
                              <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
                              <!-- header + 3 dots-->
                              <div class="flex-fill">
                                <div class="d-flex flex-row justify-content-start">
                                  <!-- dropdown menu instead of toolbar for smaller pages-->
                                  <div
                                    class="btn-group mb-auto ms-5"
                                    dropdown
                                    placement="bottom left"
                                    *ngIf="screenSize.isSmallerSize()"
                                  >
                                    <button
                                      id="button-alignment"
                                      dropdownToggle
                                      type="button"
                                      class="btn dropdown-toggle page-toolbar-dropdown-toggle mt-0 pt-0"
                                      aria-controls="dropdown-alignment"
                                    >
                                      <i class="la la-ellipsis-h fs-3 mt-0 pt-0" style="rotate: 90deg"></i>
                                    </button>
                                    <ul
                                      id="dropdown-alignment"
                                      *dropdownMenu
                                      class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
                                      role="menu"
                                      aria-labelledby="button-alignment"
                                    >
                                      <ng-container *ngIf="!isDownloading()">
                                        <div *ngFor="let toolbarGroup of masterToolbarModal; let groupIndex = index">
                                          <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                            <li role="menuitem">
                                              <div *ngIf="toolbarButton.visible()" class="dropdown">
                                                <button
                                                  type="button"
                                                  class="btn page-toolbar-button"
                                                  data-bs-toggle="dropdown"
                                                  data-placement="top"
                                                  [title]="toolbarButton.tooltip"
                                                  show-delay="300"
                                                >
                                                  <i [class]="toolbarButton.icon" class="fs-4"></i>
                                                  <span>{{ toolbarButton.shortLabel }}</span>
                                                </button>
                                                <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                                  <li>
                                                    <a
                                                      class="dropdown-item"
                                                      (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                                    >
                                                      xlsx
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      class="dropdown-item"
                                                      (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                                    >
                                                      csv
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </li>
                                          </ng-container>
                                          <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
                                        </div>
                                      </ng-container>
                                      <div class="mx-auto" *ngIf="isDownloading()">
                                        <div class="ball-pulse loader-primary">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- toolbar + content columns -->
                            <div class="flex-fill">
                              <div class="d-flex flex-row h-100">
                                <!-- toolbar column-->
                                <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
                                  <ng-container *ngIf="!isDownloading()">
                                    <div
                                      class="page-toolbar-button-group"
                                      *ngFor="let toolbarGroup of masterToolbarModal"
                                    >
                                      <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                        <div *ngIf="toolbarButton.visible()" class="dropdown">
                                          <button
                                            type="button"
                                            class="btn page-toolbar-button"
                                            data-bs-toggle="dropdown"
                                            data-placement="top"
                                            [title]="toolbarButton.tooltip"
                                            show-delay="300"
                                          >
                                            <i [class]="toolbarButton.icon" class="fs-4"></i>
                                            <span>{{ toolbarButton.shortLabel }}</span>
                                          </button>
                                          <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                              >
                                                xlsx
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                              >
                                                csv
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </ng-container>
                                  <div class="mx-auto" *ngIf="isDownloading()">
                                    <div class="ball-pulse loader-primary">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                                <!-- content column-->
                                <div class="flex-fill ps-2">
                                  <div
                                    class="d-flex h-100 flex-column me-2"
                                    *ngIf="!loadingMasterTable"
                                    style="max-width: 100vw !important"
                                  >
                                    <div class="w-100" *ngIf="!isTracingMasterTable">
                                      <p>
                                        <span>
                                          {{ locale('locale_key.pages.trace_data_modal.section.trace_source.info') }}
                                        </span>
                                      </p>
                                    </div>
                                    <div
                                      class="pb-4"
                                      [ngClass]="{ 'border-b': !last, 'py-4': !first }"
                                      *ngFor="
                                        let service of masterTableDataGridServices;
                                        let last = last;
                                        let first = first
                                      "
                                    >
                                      <datagrid-table-editor [dataGrid]="service"></datagrid-table-editor>
                                    </div>
                                  </div>
                                  <div class="ball-pulse loader-primary" *ngIf="loadingMasterTable">
                                    <span>
                                      {{
                                        locale(
                                          'locale_key.pages.trace_data_modal.section.trace_source.title.in_progress'
                                        )
                                      }}
                                    </span>
                                    <div style="background-color: var(--main-dark-color1)"></div>
                                    <div style="background-color: var(--main-dark-color1)"></div>
                                    <div style="background-color: var(--main-dark-color1)"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noKPIRowSelection>
              <div
                class="mx-0 mt-3 pe-0"
                style="max-width: 3%; width: 3%"
                *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize()"
              >
                <span><i class="ft-corner-down-right bold fs-4 ms-auto ps-3" style="color: var(--gray)"></i></span>
              </div>
              <div
                class="mx-0 pe-0 pe-2 ms-auto"
                style="max-width: 97%; width: 97%"
                [ngStyle]="
                  screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()
                    ? { 'max-width': '98%', width: '98%' }
                    : { 'max-width': '97%', width: '97%' }
                "
              >
                <div class="accordion accordion-icon-rotate my-50 border mx-0">
                  <span class="p-0 collapsed border-b" style="max-height: 75px">
                    <div class="row mx-auto w-100 my-1 py-3">
                      <div
                        class="my-auto px-1"
                        [ngClass]="
                          !isShowMoreButton1 && screenSize.isXLargeSize()
                            ? 'col-8'
                            : !isShowMoreButton1
                              ? 'col-9'
                              : 'col-7'
                        "
                      >
                        <div class="d-flex flex-row justify-content-start align-items-center h-100 my-auto">
                          <h6 class="pt-3 px-3" style="color: var(--dark-gray) !important">
                            {{ locale('locale_key.pages.trace_data_modal.section.trace_source.title.master_table') }}
                            <i class="ms-2">
                              {{
                                locale('locale_key.pages.trace_data_modal.section.trace_source.make_selection_in_kpi')
                              }}
                            </i>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </ng-template>
          </div>
          <div
            class="d-flex flex-row align-items-start justify-content-between"
            [ngClass]="
              !screenSize.isSmallerSize() || !screenSize.isMediumSize() || !screenSize.isLargeSize() ? 'ms-3' : 'ms-5'
            "
          >
            <ng-container *ngIf="!request && !requestGroup && !stageInfo; else masterTableRowSelected">
              <div
                class="mx-0 mt-3 pe-0"
                style="max-width: 3%; width: 3%"
                *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize()"
              >
                <span><i class="ft-corner-down-right bold fs-4 ms-auto ps-3" style="color: var(--gray)"></i></span>
              </div>
              <div
                class="mx-0 pe-0 pe-2 ms-auto"
                [ngStyle]="
                  screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()
                    ? { 'max-width': '98%', width: '98%' }
                    : { 'max-width': '97%', width: '97%' }
                "
              >
                <div class="accordion accordion-icon-rotate my-2 border mx-0">
                  <span class="p-0 collapsed border-b" style="max-height: 75px">
                    <div class="row mx-auto w-100 my-1 py-3">
                      <div
                        class="my-auto px-1"
                        [ngClass]="
                          !isShowMoreButton2 && screenSize.isXLargeSize()
                            ? 'col-8'
                            : !isShowMoreButton2
                              ? 'col-9'
                              : 'col-7'
                        "
                      >
                        <div class="d-flex flex-row justify-content-start align-items-center h-100 my-auto">
                          <h6 class="pt-3 px-3" style="color: var(--dark-gray) !important">
                            {{ locale('locale_key.pages.trace_data_modal.section.trace_source.title.source') }}
                            <i class="ms-50">
                              {{
                                locale(
                                  'locale_key.pages.trace_data_modal.section.trace_source.make_selection_in_master_table'
                                )
                              }}
                            </i>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-template #masterTableRowSelected>
              <div
                class="mx-0 mt-3 pe-0"
                style="max-width: 3%; width: 3%"
                *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize()"
              >
                <span>
                  <i class="ft-corner-down-right bold fs-4 ms-auto ps-3" style="color: var(--blue-color)"></i>
                </span>
              </div>
              <div
                class="mx-0 pe-0 pe-2 ms-auto"
                [ngStyle]="
                  screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()
                    ? { 'max-width': '98%', width: '98%' }
                    : { 'max-width': '97%', width: '97%' }
                "
              >
                <div
                  id="acc2"
                  class="accordion accordion-icon-rotate mb-2 border mt-2 mx-0"
                  (click)="addActiveClass($event)"
                >
                  <span
                    [id]="'item2'"
                    class="p-0 border-b"
                    data-bs-toggle="collapse"
                    href="#accordion2"
                    aria-expanded="true"
                    [attr.aria-controls]="'accordion1'"
                    style="height: 75px !important; max-height: 75px !important"
                    [ngClass]="{ collapsed: !isTracingMasterTable }"
                  >
                    <div class="row mx-auto w-100 py-3" style="height: 75px !important; max-height: 75px !important">
                      <div
                        class="my-auto px-1"
                        [ngClass]="
                          !isShowMoreButton2 && screenSize.isXLargeSize()
                            ? 'col-8'
                            : !isShowMoreButton2
                              ? 'col-9'
                              : 'col-7'
                        "
                      >
                        <div class="d-flex flex-row justify-content-start align-items-center h-100 my-auto">
                          <h6 class="my-auto px-3 d-inline">
                            {{ locale('locale_key.pages.trace_data_modal.section.trace_source.title.source') }}
                            <b *ngIf="isShowMoreButton2">{{ isSourceDirectEntry() ? 'Direct Entry' : 'Request' }}</b>
                            <b *ngIf="!isShowMoreButton2">
                              {{
                                isSourceDataRequest()
                                  ? requestGroup.title
                                  : isSourceDirectEntry()
                                    ? stageTableDataGridService.entity.getLabel()
                                    : ''
                              }}
                            </b>
                          </h6>
                          <ul
                            class="list-inline m-0 d-flex flex-row flex-grow"
                            *ngIf="!isShowMoreButton2 && isSourceDirectEntry()"
                          >
                            <li class="list-inline-item m-0" style="width: 50%">
                              <div class="badge badge-info">
                                {{ stageTableDataGridService.stageTableInfo.timeFromString }}
                              </div>
                            </li>
                            <a class="m-0">-</a>
                            <li class="list-inline-item m-0" style="width: 50%">
                              <div class="badge badge-info">
                                {{ stageTableDataGridService.stageTableInfo.timeToString }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="my-auto border-l text-start mx-0 px-1"
                        [ngClass]="
                          isShowMoreButton2 && screenSize.isXLargeSize()
                            ? 'col-2'
                            : isShowMoreButton2
                              ? 'col-4'
                              : 'col-2'
                        "
                      >
                        <div class="d-flex flex-row justify-content-start h-100 align-content-center text-start">
                          <a
                            id="link2"
                            class="underline dark-text me-auto"
                            (click)="showMoreShowLess('isShowMoreButton2', $event)"
                          >
                            <small class="text-muted">
                              {{
                                isShowMoreButton2
                                  ? locale('locale_key.pages.trace_data_modal.button.show_more')
                                  : locale('locale_key.pages.trace_data_modal.button.show_less')
                              }}
                            </small>
                          </a>
                        </div>
                      </div>
                      <div
                        class="mx-0 px-1 my-auto d-flex flex-row justify-content-end ms-auto"
                        [ngClass]="!screenSize.isXLargeSize() ? 'col-1' : 'col-2'"
                        *ngIf="requestGroup"
                      >
                        <button
                          class="btn tertiary-button me-auto"
                          style="min-width: fit-content; max-height: 37px"
                          *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize() && !screenSize.isLargeSize()"
                          (click)="openInReq()"
                        >
                          {{ locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_request') }}
                        </button>
                        <div
                          class="btn-group ms-auto"
                          *ngIf="screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()"
                        >
                          <button
                            type="button"
                            class="description-btn text-end mx-0 px-0 ms-auto"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="la la-ellipsis-h fs-3 mx-0 px-0" style="rotate: 90deg"></i>
                          </button>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openInReq()">
                              {{
                                locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_request')
                              }}
                            </button>
                          </div>
                        </div>
                        <i class="icon m-1 text-end"></i>
                      </div>
                      <div
                        class="mx-0 px-1 my-auto d-flex flex-row justify-content-end ms-auto"
                        [ngClass]="!screenSize.isXLargeSize() ? 'col-1' : 'col-2'"
                        *ngIf="stageTableDataGridService"
                      >
                        <button
                          class="btn tertiary-button me-auto"
                          style="min-width: fit-content; max-height: 37px"
                          *ngIf="!screenSize.isSmallerSize() && !screenSize.isMediumSize() && !screenSize.isLargeSize()"
                          (click)="openDirectEntry()"
                        >
                          {{ locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_datahub') }}
                        </button>
                        <div
                          class="btn-group ms-auto"
                          *ngIf="screenSize.isSmallerSize() || screenSize.isMediumSize() || screenSize.isLargeSize()"
                        >
                          <button
                            type="button"
                            class="description-btn text-end mx-0 px-0 ms-auto"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="la la-ellipsis-h fs-3 mx-0 px-0" style="rotate: 90deg"></i>
                          </button>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openDirectEntry()">
                              {{
                                locale('locale_key.pages.trace_data_modal.section.trace_source.button.open_in_datahub')
                              }}
                            </button>
                          </div>
                        </div>
                        <i class="icon m-1 text-end"></i>
                      </div>
                    </div>
                  </span>
                  <div
                    [id]="'accordion2'"
                    role="tabpanel"
                    data-bs-parent="#accordionWrap1"
                    [attr.aria-labelledby]="'item2'"
                    class="collapse border-t"
                    [ngClass]="{ show: isTracingMasterTable }"
                  >
                    <div
                      class="d-flex flex-column h-100 m-3 p-3"
                      style="max-width: 100% !important; box-sizing: border-box !important"
                    >
                      <!-- empty toolbar + page-header and 3dots menu columns-->
                      <div class="d-flex flex-row">
                        <!-- empty-->
                        <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
                        <!-- header + 3 dots-->
                        <div class="flex-fill">
                          <div class="d-flex flex-row justify-content-start">
                            <!-- dropdown menu instead of toolbar for smaller pages-->
                            <div
                              class="btn-group mb-auto ms-5"
                              dropdown
                              placement="bottom left"
                              *ngIf="screenSize.isSmallerSize()"
                            >
                              <button
                                id="button-alignment"
                                dropdownToggle
                                type="button"
                                class="btn dropdown-toggle page-toolbar-dropdown-toggle mt-0 pt-0"
                                aria-controls="dropdown-alignment"
                              >
                                <i class="la la-ellipsis-h fs-3 mt-0 pt-0" style="rotate: 90deg"></i>
                              </button>
                              <ul
                                id="dropdown-alignment"
                                *dropdownMenu
                                class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu"
                                role="menu"
                                aria-labelledby="button-alignment"
                              >
                                <ng-container *ngIf="!isDownloading()">
                                  <div *ngFor="let toolbarGroup of sourceToolbar; let groupIndex = index">
                                    <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                      <li role="menuitem">
                                        <div *ngIf="toolbarButton.visible()" class="dropdown">
                                          <button
                                            type="button"
                                            class="btn page-toolbar-button"
                                            data-bs-toggle="dropdown"
                                            data-placement="top"
                                            [title]="toolbarButton.tooltip"
                                            show-delay="300"
                                          >
                                            <i [class]="toolbarButton.icon" class="fs-4"></i>
                                            <span>{{ toolbarButton.shortLabel }}</span>
                                          </button>
                                          <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                              >
                                                xlsx
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                              >
                                                csv
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ng-container>
                                    <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
                                  </div>
                                </ng-container>
                                <div class="mx-auto" *ngIf="isDownloading()">
                                  <div class="ball-pulse loader-primary">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- toolbar + content columns -->
                      <div class="flex-fill">
                        <div class="d-flex flex-row h-100">
                          <!-- toolbar column-->
                          <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
                            <ng-container *ngIf="!isDownloading()">
                              <div class="page-toolbar-button-group" *ngFor="let toolbarGroup of sourceToolbar">
                                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                  <div *ngIf="toolbarButton.visible()" class="dropdown">
                                    <button
                                      type="button"
                                      class="btn page-toolbar-button"
                                      data-bs-toggle="dropdown"
                                      data-placement="top"
                                      [title]="toolbarButton.tooltip"
                                      show-delay="300"
                                    >
                                      <i [class]="toolbarButton.icon" class="fs-4"></i>
                                      <span>{{ toolbarButton.shortLabel }}</span>
                                    </button>
                                    <ul class="dropdown-menu min-width-auto custom-dropdown-menu">
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          (click)="handleToolbarAction(toolbarButton.actionName, 'xlsx')"
                                        >
                                          xlsx
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          (click)="handleToolbarAction(toolbarButton.actionName, 'csv')"
                                        >
                                          csv
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                            <div class="mx-auto" *ngIf="isDownloading()">
                              <div class="ball-pulse loader-primary">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <!-- content column-->
                          <div class="flex-fill ps-2">
                            <div class="loader-wrapper mt-5" *ngIf="loadingSource">
                              <div class="loader-container">
                                <div class="ball-grid-pulse loader-primary">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!loadingSource" style="max-width: 100vw !important">
                              <data-inventory
                                [requestGroup]="requestGroup"
                                [exportResult]="exportResult"
                                [selectedRequest]="request"
                                *ngIf="isSourceDataRequest()"
                              ></data-inventory>
                              <datagrid-table-editor
                                [dataGrid]="stageTableDataGridService"
                                *ngIf="isSourceDirectEntry()"
                              ></datagrid-table-editor>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-3" style="border-top: none"></div>
  </div>
</div>
