<body
  class="horizontal-layout horizontal-menu-padding 1-column blank-page pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar"
  data-open="click"
  data-menu="horizontal-menu"
  data-col="1-column"
  data-gr-c-s-loaded="true"
>
  <div class="pace pace-inactive">
    <div
      class="pace-progress"
      data-progress-text="100%"
      data-progress="99"
      style="transform: translate3d(100%, 0px, 0px)"
    >
      <div class="pace-progress-inner"></div>
    </div>
    <div class="pace-activity"></div>
  </div>
  <div class="app-content container center-layout">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row"></div>
      <div class="content-body">
        <section class="row flexbox-container">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <p></p>
                  <div class="card-body">
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0" for="password">New password</label>
                        <div>
                          <span class="mb-0 password-info" for="currentPassword">
                            Minimum password length is 8 characters. it must include a combination of upper and lower
                            case characters, digits, and symbols
                          </span>
                        </div>
                        <input
                          type="password"
                          formControlName="newPassword"
                          name="newPassword"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && resetPasswordForm.get('newPassword').errors }"
                          placeholder="Confirm New Password"
                        />
                        <div *ngIf="submitted && resetPasswordForm.get('newPassword').errors" class="invalid-feedback">
                          <div *ngIf="resetPasswordForm.get('newPassword').errors.required">Password is required</div>
                          <div *ngIf="resetPasswordForm.get('newPassword').errors.pattern">
                            The password does not meet the requirements. Please review them and try again.
                          </div>
                          <div *ngIf="resetPasswordForm.get('newPassword').errors.previouslyUsed"></div>
                        </div>
                      </fieldset>
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0" for="confirmPassword">Retype Password*</label>
                        <input
                          type="password"
                          formControlName="confirmPassword"
                          name="confirmPassword"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && resetPasswordForm.get('confirmPassword').errors }"
                          placeholder="Confirm New Password"
                        />
                        <div
                          *ngIf="submitted && resetPasswordForm.get('confirmPassword').errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="resetPasswordForm.get('confirmPassword').errors.required">
                            Password is required
                          </div>
                          <div *ngIf="resetPasswordForm.get('confirmPassword').errors.notMatch">
                            The password does not meet the requirements. Please review them and try again.
                          </div>
                          <div *ngIf="resetPasswordForm.get('confirmPassword').errors.previouslyUsed">
                            This password has been previously used as one of the last five passwords. Please choose a
                            new and unique password.
                          </div>
                        </div>
                      </fieldset>

                      <div style="border-top: 1px solid #e4e5ec; margin-bottom: 15px"></div>
                      <div class="form-group">
                        <button [disabled]="loading" type="submit" class="btn btn-outline-info btn-block">
                          <i class="fas fa-unlock"></i>
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          Change password
                        </button>
                        <button
                          type="reset"
                          class="btn btn-outline-danger btn-block m-2"
                          id="reset-btn"
                          ng-click="reset()"
                        >
                          <i class="fas fa-unlock"></i>
                          Reset
                        </button>
                      </div>
                      <div
                        class="alert-message text-center alert alert-warning alert-dismissible"
                        *ngIf="validLogin"
                        role="alert"
                      >
                        <strong>{{ Message }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div
                        class="alert-message text-center alert alert-warning alert-dismissible"
                        *ngIf="validLogin"
                        role="alert"
                      >
                        <strong>{{ successMessage }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</body>
