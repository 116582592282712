<main>
  <div class="ball-pulse" id="loading">
    <div style="background-color: #6dc49b"></div>
    <div style="background-color: #6dc49b"></div>
    <div style="background-color: #6dc49b"></div>
  </div>
  <div
    id="canvas"
    ngFlowchartCanvas
    [ngFlowchartOptions]="options"
    [disabled]="true"
    [attr.mode]="orgChartService.getMode()"
    style="visibility: hidden"
  ></div>
</main>
