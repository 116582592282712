import * as XLSX from 'xlsx'
import { Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { ImportedDataService } from '../service/ImportDataService'
import { DataGridIntegrationService } from './importDataGrid.service'
import { ExcelViewerServiceInterface } from '../service/ExcelViewerServiceInterface'
import { DataGridTableData } from '../model/DataGridTableData'
import { DataGridTableMode } from '../model/DataGridTableMode'
import { DataGridColumnType } from '../model/DataGridColumnType'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import moment from 'moment'

@Component({
  selector: 'datagrid-excel-viewer',
  templateUrl: './excel-table.component.html',
  styleUrls: ['./excel-table.component.css']
})
export class ExcelTableComponent implements OnInit {
  ids = []
  keys!: string[]
  // selectedSheetDataSubject = new Subject();
  // selectedSheetData = [];

  @ViewChild('inputFile') inputFile!: ElementRef
  dataGrid: DataGridIntegrationService = new DataGridIntegrationService()
  isExcelFile!: boolean
  sheetNames: string[] = []
  selectedSheetNo = 0
  selectedSheetName = ''
  data: any[] = []
  fileName: string = ''
  tableData = new DataGridTableData()
  readingFile = false
  selectedData: string[] = []
  @Input() excelViewerService!: ExcelViewerServiceInterface
  @Output() exportIt = new Subject<{ table: DataGridTableData; method: string }>()
  @Input() mode = DataGridTableMode.VIEW
  // @ViewChild(TableComponent) tableComponent!: TableComponent;

  constructor(private importedDataService: ImportedDataService) {}

  async ngOnInit(): Promise<void> {
    this.dataGrid.mode = this.mode
    await this.readFile()
    this.excelViewerService.registerObserver(this)
    this.importedDataService.excelViewerService = this.excelViewerService
    // this.selectedSheetDataSubject.subscribe((data: any) => { this.selectedSheetData = data; });
    this.importedDataService.selectedDataTableAdded.subscribe((isAdded: boolean) => {
      if (isAdded) {
        this.exportIt.next({
          table: this.importedDataService.getSelectedDataTable(),
          method: this.importedDataService.getMethod()
        })
      }
    })
  }

  async readFile(): Promise<void> {
    this.readingFile = true
    const file = await this.excelViewerService.getExcelBlob()
    const reader: FileReader = new FileReader()

    reader.onload = async (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellStyles: true, cellText: false, cellDates: true })

      /* grab sheet names*/
      this.sheetNames = wb.SheetNames
      const ws: XLSX.WorkSheet = wb.Sheets

      /* save data */
      this.data = []
      Object.values(ws).forEach((el: XLSX.WorkSheet) => {
        const json = XLSX.utils.sheet_to_json(el, { raw: true, defval: null, dateNF: 'yyyy-mm-dd' })
        this.data.push(json)
      })
      this.keys = Object.keys(this.data[0][0])
      this.importedDataService.name = await this.excelViewerService.getFileName()
      this.importedDataService.addData(this.data[0], this.keys)
      this.keys = this.keys.map((key) => (key.includes('EMPTY') ? '' : key))
      this.dataGrid.database = this.importedDataService.getData()
      this.dataGrid.observer?.renderNewDataTable()
      this.readingFile = false
    }

    if (file.type !== 'text/csv') {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsText(file, 'UTF-8')
    }
  }

  removeData(): void {
    this.inputFile.nativeElement.value = ''
    // this.selectedSheetDataSubject.next(null);
    this.isExcelFile = false
    this.keys = []
    this.importedDataService.addData([], [])
  }

  async changeSheet(no: number): Promise<void> {
    if (this.selectedSheetNo != no) {
      this.readingFile = true
      this.selectedSheetNo = no
      this.keys = Object.keys(this.data[no][0])
      this.importedDataService.addData(this.data[no], this.keys)
      this.dataGrid.database = this.importedDataService.getData()
      this.dataGrid.observer?.renderNewDataTable()
      this.importedDataService.excelViewerService.markDataSelected(false)
      setTimeout(() => {
        this.readingFile = false
      }, 1)
    }
  }

  mapToTaxonomy() {
    this.dataGrid.mode = DataGridTableMode.MAP_EXTRACTED_DATA
    this.importedDataService.mapToTaxonomy.next(true)
  }

  setDataGridMode(mode: string) {
    this.dataGrid.mode = mode
  }

  isModeExportExcelTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.EXPORT_EXCEL_TABLE_DATA
  }

  isModeViewTableData() {
    return this.dataGrid.getMode() == DataGridTableMode.VIEW
  }

  getExtractedTableData() {
    const rows = this.importedDataService.getSelectedDataTable().rows
    const units = this.importedDataService.unitsForMappedToCols
    rows.forEach((row, rowNo) => {
      row.values.forEach((val, colNo) => {
        if (row.values[colNo]) {
          let type = this.importedDataService.cols[colNo].col.type
          if (type == DataGridColumnType.DATE) {
            row.values[colNo] = this.isValidDate(val)
          } else if (type == DataGridColumnType.NUMERIC || type == 'FLOAT') {
            if (this.isValidNumber(val)) {
              row.values[colNo] = { quantity: val, unit: units.get(colNo) }
            } else {
              row.values[colNo] = { quantity: null, unit: units.get(colNo) }
            }
          }
        }
      })
    })
    let table = this.importedDataService.getSelectedDataTable()
    return table
  }

  isValidDate(date: any) {
    if (typeof date == 'string' && date.match(ValidationRegex.DateRegex) != null) {
      return date
    } else if (date instanceof Date) {
      return date
    } else if (moment(date, 'DD/MM/YYYY')?.toDate()) {
      return moment(date, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
    }
    return null
  }

  isValidNumber(val: any) {
    if (typeof val == 'string') {
      val = val.trim()
      if (val != '') {
        return ValidationRegex.NumberRegExp.test(val)
      }
    }
    return true
  }
}
