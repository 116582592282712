import { StringUtilFe } from 'src/app/utils/StringUtilFe'

export class PartnerInvitation_AddFe {
  managementCompanyId: string
  partnerName: string
  partnerPurpose: string
  partnerCountryCode: string
  partnerIndustryGroup: string
  partnerIndustry: string
  partnerWebsite: string
  primaryContactFirstName: string
  primaryContactLastName: string
  primaryContactEmail: string
  uuid: string
  link: string
  emailLanguage: string
  includeEnglish: boolean

  constructor(
    uuid: string,
    managementCompanyId: string,
    partnerName: string,
    partnerPurpose: string,
    partnerCountryCode: string,
    partnerIndustryGroup: string,
    partnerIndustry: string,
    partnerWebsite: string,
    primaryContactFirstName: string,
    primaryContactLastName: string,
    primaryContactEmail: string,
    link: string,
    emailLanguage?: string,
    includeEnglish?: boolean
  ) {
    this.managementCompanyId = managementCompanyId
    this.partnerName = partnerName
    this.partnerPurpose = partnerPurpose
    this.partnerCountryCode = partnerCountryCode
    this.partnerIndustryGroup = partnerIndustryGroup
    this.partnerIndustry = partnerIndustry
    this.partnerWebsite = partnerWebsite
    this.primaryContactFirstName = primaryContactFirstName
    this.primaryContactLastName = primaryContactLastName
    this.primaryContactEmail = StringUtilFe.trimEmail(primaryContactEmail)
    this.uuid = uuid
    this.link = link
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
  }
}
