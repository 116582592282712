export class ScreenHeightSizeFe {
  static readonly HEIGHT_XSMALL = new ScreenHeightSizeFe('HEIGHT_XSMALL', 0, 479)
  static readonly HEIGHT_SMALL = new ScreenHeightSizeFe('HEIGHT_SMALL', 480, 599)
  static readonly HEIGHT_MEDIUM = new ScreenHeightSizeFe('HEIGHT_MEDIUM', 600, 719)
  static readonly HEIGHT_LARGE = new ScreenHeightSizeFe('HEIGHT_LARGE', 720, 100000)

  constructor(
    readonly name: string,
    readonly heightRangeStart: number,
    readonly heightRangeEnd: number
  ) {}

  public static toScreenSize(height: number): ScreenHeightSizeFe {
    switch (true) {
      case height < this.HEIGHT_XSMALL.heightRangeEnd:
        return this.HEIGHT_XSMALL
      case height < this.HEIGHT_SMALL.heightRangeEnd:
        return this.HEIGHT_SMALL
      case height < this.HEIGHT_MEDIUM.heightRangeEnd:
        return this.HEIGHT_MEDIUM
      default:
        return this.HEIGHT_LARGE
    }
  }

  public largerThanXsmall(): boolean {
    return this.heightRangeEnd > ScreenHeightSizeFe.HEIGHT_XSMALL.heightRangeEnd
  }

  public largerThanSmall(): boolean {
    return this.heightRangeEnd > ScreenHeightSizeFe.HEIGHT_SMALL.heightRangeEnd
  }

  public largerThanMedium(): boolean {
    return this.heightRangeEnd > ScreenHeightSizeFe.HEIGHT_MEDIUM.heightRangeEnd
  }
}
