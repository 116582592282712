<div class="modal-header main-modal-header" style="border-bottom: none">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
    {{ locale('locale_key.pages.data_category.add_data_point') }}
  </h4>
  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body" [formGroup]="dataPointFormGroup">
  <div class="heading" style="display: flex; align-items: center; margin-bottom: 20px">
    <div class="title" style="margin-right: 10px; white-space: nowrap">
      {{ locale('locale_key.add_data_point.adding_to_data_category.title') }}
      <span class="badge badge-info w-auto py-auto m-2">
        {{ getTaxonomyTitle() }}
      </span>
    </div>
  </div>
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.add_data_point.description.title') }}</p>
    <div *ngIf="!isLanguageEnglish()" class="d-flex flex justify-items-center">
      <div class="me-3 mb-1 mt-2">
        <i [class]="getCategoryLanguageFlag()" style="margin-right: 12px"></i>
      </div>
      <input
        class="form-control w-50 mb-0"
        placeholder="{{ locale('locale_key.add_data_point.description.placeholder') }}"
        formControlName="descriptionLang"
        [ngClass]="{
          'is-invalid':
            dataPointFormGroup.controls.descriptionLang.errors && dataPointFormGroup.controls.descriptionLang.touched
        }"
      />
    </div>
    <br *ngIf="!isLanguageEnglish()" />
    <div class="d-flex flex justify-items-center">
      <div class="me-3 mb-1 mt-2">
        <i class="fs-4 fi fi-us" style="margin-right: 12px"></i>
      </div>
      <input
        class="form-control w-50 mb-0"
        placeholder="{{ locale('locale_key.add_data_point.description.placeholder') }}"
        formControlName="descriptionEn"
        [ngClass]="{
          'is-invalid':
            dataPointFormGroup.controls.descriptionEn.errors && dataPointFormGroup.controls.descriptionEn.touched
        }"
      />
    </div>
  </div>
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.add_data_point.short_name.title') }}</p>
    <div *ngIf="!isLanguageEnglish()" class="d-flex flex justify-items-center">
      <div class="me-3 mb-1 mt-2">
        <i [class]="getCategoryLanguageFlag()" style="margin-right: 12px"></i>
      </div>
      <input
        class="form-control w-50 mb-0"
        placeholder="{{ locale('locale_key.add_data_point.short_name.placeholder') }}"
        formControlName="shortNameLang"
        [ngClass]="{
          'is-invalid':
            dataPointFormGroup.controls.shortNameLang.errors && dataPointFormGroup.controls.shortNameLang.touched
        }"
      />
    </div>
    <br *ngIf="!isLanguageEnglish()" />
    <div class="d-flex flex justify-items-center">
      <div class="me-3 mb-1 mt-2">
        <i class="fs-4 fi fi-us" style="margin-right: 12px"></i>
      </div>
      <div class="form-group w-100 m-0">
        <input
          class="form-control w-50 mb-0"
          placeholder="{{ locale('locale_key.add_data_point.short_name.placeholder') }}"
          formControlName="shortNameEn"
          [ngClass]="{
            'is-invalid':
              dataPointFormGroup.controls.shortNameEn.errors && dataPointFormGroup.controls.shortNameEn.touched
          }"
        />
        <div
          *ngIf="
            dataPointFormGroup.get('shortNameEn').invalid &&
            !dataPointFormGroup.get('shortNameEn').errors.required &&
            (dataPointFormGroup.get('shortNameEn').dirty || dataPointFormGroup.get('shortNameEn').touched)
          "
          class="alert alert-warning-custom d-flex flex-row border-1 mt-2 p-2 ng-star-inserted my-1 w-50"
        >
          <span *ngIf="dataPointFormGroup.get('shortNameEn').errors.pattern">
            {{ locale('locale_key.pages.data_category.data_point.validation.message') }}
          </span>
          <span *ngIf="dataPointFormGroup.get('shortNameEn').errors.duplicateDatapointName">
            {{ locale('locale_key.pages.data_category.label.duplicate_name.message') }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2">
    <p class="mb-2">{{ locale('locale_key.add_data_point.data_type.title') }}</p>
    <select
      class="form-control w-50 col-11 mb-1"
      formControlName="dataType"
      [ngClass]="{
        'is-invalid': dataPointFormGroup.controls.dataType.errors && dataPointFormGroup.controls.dataType.touched
      }"
    >
      <option value="STRING">{{ locale('locale_key.general.data_type.text') }}</option>
      <option value="NUMERIC">{{ locale('locale_key.general.data_type.number') }}</option>
      <option value="DATE">{{ locale('locale_key.general.data_type.date') }}</option>
      <option value="BOOLEAN">{{ locale('locale_key.general.data_type.boolean') }}</option>
      <option value="EMISSION_FACTOR">{{ locale('locale_key.general.data_type.emission_factor') }}</option>
    </select>
  </div>
  <div class="mb-2" *ngIf="dataPointFormGroup.controls.dataType.value == 'NUMERIC'">
    <p class="mb-2">{{ locale('locale_key.add_data_point.unit.title') }}</p>
    <unit-selector
      [mode]="'select'"
      [selectBy]="'symbol'"
      [source]="'add_datapoint_modal'"
      [customUnits]="customUnits"
      (selectedUnitChanged)="unit = $event"
      [unitsByMeasurementType]="unitsByMeasurementType"
    ></unit-selector>
    <div class="form-check form-switch mt-2" *ngIf="isCalculatedColumn">
      <input
        [(ngModel)]="showCalculationBuilder"
        (change)="builderChanged($event)"
        class="form-check-input"
        type="checkbox"
        id="createCalculationCheck"
      />
      <label class="form-check-label" for="createCalculationCheck">Create calculation</label>
    </div>
    <calculation-builder
      *ngIf="dataPointToCreate.providedBy == 'calculation' && isCalculatedColumn"
      [showEditMode]="true"
      [showAddNewDataPointBtn]="false"
      [selectedDataCategory]="selectedDataCategory"
      [selectedDatapoint]="dataPointToCreate"
      (updateHasCalculationErrors)="updateHasCalculationErrors($event)"
      [showCalculationErrors]="showCalculationErrors"
    ></calculation-builder>
  </div>
  <!-- BEGIN : EMISSION FACTOR INPUTS -->
  <div
    class="section providedBy d-flex flex-column align-items-start mb-4"
    *ngIf="providedByInput == 'user' && dataPointFormGroup.controls.dataType.value == 'EMISSION_FACTOR'"
  >
    <div class="name mb-2">
      <span class="label fw-bold me-2">
        {{ locale('locale_key.pages.data_category.specify_acceptable_measurement_types') }}
      </span>
      <i
        class="info las la-info-circle dropdown-toggle my-auto ms-auto"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></i>
      <div class="dropdown-menu arrow p-2 info-popover">
        {{ locale('locale_key.pages.data_category.you_can_define_specific_conditions') }}
      </div>
    </div>

    <select
      class="measurement-type-input"
      [(ngModel)]="conversionFactorMeasurementTypeInput"
      [ngModelOptions]="{ standalone: true }"
    >
      <option value="" disabled selected>
        {{ locale('locale_key.pages.data_category.select_conversion_factor.measurement_type') }}
      </option>
      <ng-container *ngFor="let measurementType of getMeasurementTypes()">
        <option [value]="measurementType.key">
          {{ measurementType.name }}
          <span *ngIf="measurementType.defaultUnitName">(e.g. {{ measurementType.defaultUnitName }})</span>
        </option>
      </ng-container>
    </select>

    <span class="per mt-3 mb-3">per</span>

    <select
      class="measurement-type-input"
      [(ngModel)]="sourceMeasurementTypeInput"
      [ngModelOptions]="{ standalone: true }"
    >
      <option value="" disabled selected>
        {{ locale('locale_key.pages.data_category.select_source_factor.measurement_type') }}
      </option>
      <ng-container *ngFor="let measurementType of getMeasurementTypes()">
        <option [value]="measurementType.key">
          {{ measurementType.name }}
          <span *ngIf="measurementType.defaultUnitName">(e.g. {{ measurementType.defaultUnitName }})</span>
        </option>
      </ng-container>
    </select>
  </div>

  <div class="info" style="display: flex; align-items: center">
    <i class="info las la-info-circle" style="font-size: 20px; margin-right: 6px"></i>
    <span>{{ locale('locale_key.pages.data_category.label.any_new_data_categories') }}</span>
  </div>
</div>
<div class="modal-footer" style="border-top: none">
  <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
    {{ locale('locale_key.general.buttons.cancel') }}
  </button>
  <button
    type="button"
    class="btn primary-button custom-button"
    [disabled]="this.dataPointFormGroup.invalid"
    (click)="!this.dataPointFormGroup.invalid && addDatapoint()"
  >
    {{ locale('locale_key.general.buttons.add') }}
  </button>
</div>
