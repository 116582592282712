import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe'

export class DataGridTableMode {
  public static VIEW: string = 'VIEW' //VIEW
  public static EDIT_TABLE_DATA: string = 'EDIT_TABLE_DATA' //DATA
  public static EDIT_TABLE_SCHEMA: string = 'EDIT_TABLE_SCHEMA' //SCHEMA
  public static EDIT_REFERENCE_TABLE_DATA: string = 'EDIT_REFERENCE_TABLE_DATA' //MULTI DATA
  public static GET_SELECTED_TABLE_ROWS: string = 'GET_SELECTED_TABLE_ROWS' //SELECT MULTIPLE
  public static SELECT_TABLE_ROW: string = 'SELECT_TABLE_ROW' //SELECT SINGLE
  public static MAP_EXTRACTED_DATA: string = 'MAP_EXTRACTED_DATA' //FOR MAPPING

  //FOR INTERNAL USE ONLY
  public static EXPORT_EXCEL_TABLE_DATA: string = 'EXPORT_EXCEL_TABLE_DATA' //EXPORT
  public static SELECT_REFERENCE_TABLE_DATA: string = 'SELECT_REFERENCE_TABLE_DATA' //MUTLI SELECT

  public static VIEW_WITH_PREDEFINED_OPTIONS: string = 'VIEW_WITH_PREDEFINED_OPTIONS' // View Table Mode with the Ability to Add Predefined Values
}
