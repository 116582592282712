<span class="dropdown">
  <span data-bs-toggle="dropdown" data-bs-auto-close="outside">
    <i class="la la-filter ms-2" [ngClass]="{ pink: appliedFilter && appliedFilter.size != 0 }"></i>
  </span>
  <div class="dropdown-menu p-2 ef-filter-dropdown">
    <input
      class="form-control thin-border-b ps-2 w-100"
      style="border: none; font-size: 12px; width: 10rem"
      placeholder="{{ locale('locale_key.general.search') }}"
      (input)="applySearch($event.target.value)"
    />
    <div class="d-flex justify-content-around thin-border-b my-1">
      <span
        style="font-size: 12px"
        class="mx-1 cursor-pointer"
        [ngClass]="{ disabled: isSelectAllBtnDisabled() }"
        (click)="isSelectAllBtnDisabled() ? null : selectAll()"
      >
        {{ locale('locale_key.general.action.select_all') }}
      </span>
      <span
        style="font-size: 12px"
        class="mx-1 cursor-pointer"
        [ngClass]="{ disabled: isDeSelectAllBtnDisabled() }"
        (click)="isDeSelectAllBtnDisabled() ? null : deselectAll()"
      >
        {{ locale('locale_key.general.action.deselect_all') }}
      </span>
    </div>
    <div *ngFor="let value of filteredValues; let i = index">
      <input
        type="checkbox"
        class="cursor-pointer"
        id="{{ i }}"
        [checked]="appliedFilter ? appliedFilter.has(value) : null"
        (change)="updateFilter(value, $event)"
      />
      <label class="ms-1 cursor-pointer" for="{{ i }}">{{ value }}</label>
    </div>
  </div>
</span>
