export abstract class AbstractSharedResourceFe {
  objectSharedId: string
  type: string
  sharingPersonCompanyId: string
  sharingPersonCompanyName: string
  sharingPersonAffId: string
  sharingPersonFirstName: string
  sharingPersonLastName: string
  accessType: string

  constructor(
    objectSharedId: string,
    type: string,
    sharingPersonCompanyId: string,
    sharingPersonCompanyName: string,
    sharingPersonAffId: string,
    sharingPersonFirstName: string,
    sharingPersonLastName: string,
    accessType: string
  ) {
    this.objectSharedId = objectSharedId
    this.type = type
    this.sharingPersonCompanyId = sharingPersonCompanyId
    this.sharingPersonCompanyName = sharingPersonCompanyName
    this.sharingPersonAffId = sharingPersonAffId
    this.sharingPersonFirstName = sharingPersonFirstName
    this.sharingPersonLastName = sharingPersonLastName
    this.accessType = accessType
  }
}
