<div class="calculation_builder" [class.showFormulaOnly]="showFormulaOnly">
  <!-- CALCULATIONS! -->
  <ng-container *ngIf="buildMode == 'calculation'">
    <div class="heading">
      <div class="info">
        <span class="highlight">
          {{ resolveLabel({ token: { datapoint: selectedDatapoint }, deployed: showEditMode }) }}
        </span>
        {{ locale('locale_key.calculation-builder.will_be_calculated_as') }}
      </div>
      <div class="calculations_controls" *ngIf="showEditMode && createAdvancedCalculation">
        <div class="calculations_control create_advanced_calculation">
          <div class="form-check form-switch">
            <input
              [(ngModel)]="showAdvancedCalculationBuilder"
              (change)="calculationBuilderChanged()"
              class="form-check-input"
              type="checkbox"
              id="createAdvancedCalculationCheck"
            />
            <label class="form-check-label" for="createAdvancedCalculationCheck">
              {{ locale('locale_key.calculation_builder.create_advanced_calculation') }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!showAdvancedCalculationBuilder" class="simple_calculation_builder">
      <formula-builder
        [showAddNewDataPointBtn]="showAddNewDataPointBtn"
        [showEditMode]="showEditMode"
        [calculation]="selectedDatapoint.calculations[0]"
        [selectedDataCategory]="selectedDataCategory"
        [selectedDatapoint]="selectedDatapoint"
        [showCalculationErrors]="showCalculationErrors"
      ></formula-builder>
      <div class="simple_calculation_builder_controls" *ngIf="showEditMode">
        <div class="clear_calculation" (click)="clearSimpleCalculationBuilder()">
          <i class="las la-times"></i>
          <span class="label">{{ locale('locale_key.calculation_builder.clear_calculation') }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showAdvancedCalculationBuilder" class="advanced_calculation_builder">
      <div class="calculations" *ngFor="let calculation of selectedDatapoint.calculations; let calculationIdx = index">
        <div class="calculation" [class.is_fallback]="calculation.isFallback">
          <div class="number_indicator_container">
            <div class="number_indicator">
              <svg
                class="number_indicator_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#374659"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="number_indicator_label">{{ calculationIdx + 1 }}</div>
            </div>
          </div>
          <div class="conditions_formula_container">
            <div class="conditions_container">
              <div class="conditions_heading">
                <div class="condition_icon_container">
                  <svg
                    class="condition_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M21.5 6.75C21.5 4.67812 19.8219 3 17.75 3C15.6781 3 14 4.67812 14 6.75C14 8.45625 15.1391 9.89531 16.6953 10.35C16.6672 11.1047 16.4984 11.6859 16.1797 12.0797C15.4578 12.9797 13.8688 13.1297 12.1859 13.2844C10.8641 13.4062 9.49531 13.5375 8.375 14.0766V7.32656C9.89844 6.84844 11 5.42812 11 3.75C11 1.67812 9.32188 0 7.25 0C5.17812 0 3.5 1.67812 3.5 3.75C3.5 5.42812 4.60156 6.84844 6.125 7.32656V16.6688C4.60156 17.1516 3.5 18.5719 3.5 20.25C3.5 22.3219 5.17812 24 7.25 24C9.32188 24 11 22.3219 11 20.25C11 18.6562 10.0063 17.2922 8.6 16.7531C8.74531 16.5094 8.96562 16.2938 9.29844 16.125C10.0578 15.7406 11.1922 15.6375 12.3969 15.525C14.375 15.3422 16.6156 15.1312 17.9375 13.4906C18.5938 12.675 18.9266 11.625 18.95 10.3078C20.4312 9.80156 21.5 8.4 21.5 6.75ZM7.25 3C7.6625 3 8 3.3375 8 3.75C8 4.1625 7.6625 4.5 7.25 4.5C6.8375 4.5 6.5 4.1625 6.5 3.75C6.5 3.3375 6.8375 3 7.25 3ZM7.25 21C6.8375 21 6.5 20.6625 6.5 20.25C6.5 19.8375 6.8375 19.5 7.25 19.5C7.6625 19.5 8 19.8375 8 20.25C8 20.6625 7.6625 21 7.25 21ZM17.75 6C18.1625 6 18.5 6.3375 18.5 6.75C18.5 7.1625 18.1625 7.5 17.75 7.5C17.3375 7.5 17 7.1625 17 6.75C17 6.3375 17.3375 6 17.75 6Z"
                      fill="#374659"
                    />
                  </svg>
                </div>
                <div class="condition_heading_label">
                  {{ locale('locale_key.calculation-builder.if') }}
                  <span *ngIf="!calculation.isFallback" class="highlight">
                    {{ locale('locale_key.calculation_builder.this_condition_applies') | lowercase }}
                  </span>
                  <span *ngIf="calculation.isFallback" class="highlight">
                    {{ locale('locale_key.calculation-builder.none_of_conditions_apply') }}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#374659"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  class="remove_calculation_control"
                  (click)="adv_removeCalculation(calculationIdx)"
                  *ngIf="showEditMode"
                >
                  <div class="remove_calculation_icon_container">
                    <i class="remove_calculation_icon la la-trash-alt"></i>
                  </div>
                  <div class="remove_calculation_label">
                    <span *ngIf="!calculation.isFallback">
                      {{
                        locale('locale_key.calculation_builder.remove_calculation_index', { index: calculationIdx + 1 })
                      }}
                    </span>
                    <span *ngIf="calculation.isFallback">
                      {{ locale('locale_key.calculation_builder.remove_fallback') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="conditions_body">
                <div class="conditions_line_container">
                  <div class="conditions_line"></div>
                </div>
                <conditions-builder
                  *ngIf="!calculation.isFallback"
                  [showEditMode]="showEditMode"
                  [calculation]="calculation"
                  [level3DataCategory]="selectedDataCategory?.level_3"
                  [selectedDatapoint]="selectedDatapoint"
                  [showConditionErrors]="showCalculationErrors"
                ></conditions-builder>
                <div class="conditions_controls" *ngIf="showEditMode">
                  <div
                    *ngIf="!calculation.isFallback"
                    class="conditions_control clear_condition"
                    (click)="adv_clearCondition(calculationIdx)"
                  >
                    <i class="las la-times"></i>
                    <span class="label">{{ locale('locale_key.calculation_builder.clear_condition') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="formula_container">
              <div class="formula_heading">
                <div class="formula_icon_container">
                  <svg
                    class="formula_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M20.25 0H3.75C2.55 0 1.5 1.05 1.5 2.25V21.75C1.5 22.95 2.55 24 3.75 24H20.25C21.45 24 22.5 22.95 22.5 21.75V2.25C22.5 1.05 21.45 0 20.25 0ZM7.5 20.4C7.5 20.7 7.2 21 6.9 21H5.1C4.8 21 4.5 20.7 4.5 20.4V18.6C4.5 18.3 4.8 18 5.1 18H6.9C7.2 18 7.5 18.3 7.5 18.6V20.4ZM7.5 14.4C7.5 14.7 7.2 15 6.9 15H5.1C4.8 15 4.5 14.7 4.5 14.4V12.6C4.5 12.3 4.8 12 5.1 12H6.9C7.2 12 7.5 12.3 7.5 12.6V14.4ZM13.5 20.4C13.5 20.7 13.2 21 12.9 21H11.1C10.8 21 10.5 20.7 10.5 20.4V18.6C10.5 18.3 10.8 18 11.1 18H12.9C13.2 18 13.5 18.3 13.5 18.6V20.4ZM13.5 14.4C13.5 14.7 13.2 15 12.9 15H11.1C10.8 15 10.5 14.7 10.5 14.4V12.6C10.5 12.3 10.8 12 11.1 12H12.9C13.2 12 13.5 12.3 13.5 12.6V14.4ZM19.5 20.4C19.5 20.7 19.2 21 18.9 21H17.1C16.8 21 16.5 20.7 16.5 20.4V12.6C16.5 12.3 16.8 12 17.1 12H18.9C19.2 12 19.5 12.3 19.5 12.6V20.4ZM19.5 8.4C19.5 8.7 19.2 9 18.9 9H5.1C4.8 9 4.5 8.7 4.5 8.4V3.6C4.5 3.3 4.8 3 5.1 3H18.9C19.2 3 19.5 3.3 19.5 3.6V8.4Z"
                      fill="#374659"
                    />
                  </svg>
                </div>
                <div class="formula_heading_label">
                  {{ locale('locale_key.calculation_builder.then') }}

                  <div class="dropdown then_dropdown">
                    <div class="dropdown-toggle" data-bs-toggle="dropdown">
                      <span class="highlight" *ngIf="calculation.then == 'FORMULA' || !calculation.then">
                        {{ locale('locale_key.calculation_builder.calculate_using_formula') }}
                      </span>
                      <span class="highlight" *ngIf="calculation.then == 'FIXED'">
                        {{ locale('locale_key.calculation_builder.use_fixed_value') }}
                      </span>
                      <span class="highlight" *ngIf="calculation.then == 'NOTHING'">
                        {{ locale('locale_key.calculation-builder.do_not_enter_anything') }}
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="#374659"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div class="dropdown-menu">
                      <div class="dropdown-item" (click)="setCalculationThen({ calculation, then: 'FORMULA' })">
                        {{ locale('locale_key.calculation_builder.calculate_using_formula') }}
                      </div>
                      <div class="dropdown-item" (click)="setCalculationThen({ calculation, then: 'FIXED' })">
                        {{ locale('locale_key.calculation_builder.use_fixed_value') }}
                      </div>
                      <div class="dropdown-item" (click)="setCalculationThen({ calculation, then: 'NOTHING' })">
                        {{ locale('locale_key.calculation-builder.do_not_enter_anything') }}
                      </div>
                    </div>
                  </div>

                  <div class="adv_clear_calculation" (click)="adv_clearCalculation(calculationIdx)">
                    <i class="las la-times"></i>
                    <span class="label">
                      {{ locale('locale_key.calculation_builder.clear_calculation') | lowercase }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="formula_body">
                <div class="formula_line_container">
                  <div class="formula_line"></div>
                </div>

                <formula-builder
                  *ngIf="calculation.then == 'FORMULA' || !calculation.then"
                  [showAddNewDataPointBtn]="showAddNewDataPointBtn"
                  [showEditMode]="showEditMode"
                  [calculation]="calculation"
                  [selectedDataCategory]="selectedDataCategory"
                  [selectedDatapoint]="selectedDatapoint"
                  [showCalculationErrors]="showCalculationErrors"
                ></formula-builder>

                <input
                  *ngIf="calculation.then == 'FIXED' && showEditMode"
                  [(ngModel)]="calculation.value"
                  (ngModelChange)="updateFixedNumber({ calculation })"
                  type="number"
                  step="any"
                  class="calulation_fixed_value"
                />

                <span *ngIf="calculation.then == 'FIXED' && !showEditMode">
                  {{ calculation.value }}
                </span>

                <div class="spaced calculation_nothing" *ngIf="calculation.then == 'NOTHING'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add_new_fallback_container" *ngIf="showEditMode">
        <div class="number_indicator">
          <svg
            class="number_indicator_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#374659"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="number_indicator_label">
            {{ selectedDatapoint.calculations.length + 1 }}
          </div>
        </div>
        <div class="add_new_calculation_control" (click)="adv_addCalculation()">
          <div class="add_new_calculation_icon_container">
            <i class="add_new_calculation_icon las la-plus"></i>
          </div>
          <div class="add_new_calculation_label">
            {{ locale('locale_key.calculation_builder.add_new_calculation') }}
          </div>
        </div>
        <div class="or">{{ locale('locale_key.general.or') | uppercase }}</div>
        <div
          class="add_fallback_calculation_control"
          [class.has_fallback]="adv_hasFallback"
          (click)="adv_addFallbackCalculation()"
        >
          <div class="add_fallback_calculation_icon_container">
            <i class="add_fallback_calculation_icon las la-plus"></i>
          </div>
          <div class="add_fallback_calculation_label">
            {{ locale('locale_key.calculation_builder.add_fallback_calculation') }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--EMISSION FACTORS! -->
  <ng-container *ngIf="buildMode == 'emission_factor'">
    <div class="heading">
      <div class="info">
        <span class="highlight">
          {{ resolveLabel({ token: { datapoint: selectedDatapoint }, deployed: showEditMode }) }}
        </span>
        {{ locale('locale_key.pages.data_categories_formula.will_be') }}
      </div>
      <div class="emission_factors_controls" *ngIf="showEditMode">
        <div class="">
          <div class="form-check form-switch">
            <input
              (click)="toggleMultipleEmissionFactorBuilder()"
              class="form-check-input"
              type="checkbox"
              id="defineMultipleEmissionFactors"
              [checked]="showMultipleEmissionFactorBuilder"
            />
            <label class="form-check-label" for="defineMultipleEmissionFactors">
              {{ locale('locale_key.pages.emission.define_multiple_emission_factors') }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!showMultipleEmissionFactorBuilder" class="simple_emission_factor_builder">
      <emission-factor
        [selectedEF]="selectedDatapoint.emissionFactors[0].value"
        [isEditable]="showEditMode"
        (emissionFactorSelected)="emissionFactorChanged({ emissionFactor: $event, emissionFactorIdx: 0 })"
        [datapointEFUnit]="filterEmissionFactor({ emissionFactor: selectedDatapoint.emissionFactors[0].value })"
      ></emission-factor>
      <div class="simple_emission_factor_builder_controls" *ngIf="showEditMode"></div>
    </div>

    <div *ngIf="showMultipleEmissionFactorBuilder" class="multiple_emission_factor_builder">
      <div
        class="emission_factors"
        *ngFor="let emissionFactor of selectedDatapoint.emissionFactors; let emissionFactorIdx = index"
      >
        <div class="emission_factor" [class.is_fallback]="emissionFactor.isFallback">
          <div class="number_indicator_container">
            <div class="number_indicator">
              <svg
                class="number_indicator_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#374659"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="number_indicator_label">{{ emissionFactorIdx + 1 }}</div>
            </div>
          </div>
          <div class="conditions_formula_container">
            <div class="conditions_container">
              <div class="conditions_heading">
                <div class="condition_icon_container">
                  <svg
                    class="condition_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M21.5 6.75C21.5 4.67812 19.8219 3 17.75 3C15.6781 3 14 4.67812 14 6.75C14 8.45625 15.1391 9.89531 16.6953 10.35C16.6672 11.1047 16.4984 11.6859 16.1797 12.0797C15.4578 12.9797 13.8688 13.1297 12.1859 13.2844C10.8641 13.4062 9.49531 13.5375 8.375 14.0766V7.32656C9.89844 6.84844 11 5.42812 11 3.75C11 1.67812 9.32188 0 7.25 0C5.17812 0 3.5 1.67812 3.5 3.75C3.5 5.42812 4.60156 6.84844 6.125 7.32656V16.6688C4.60156 17.1516 3.5 18.5719 3.5 20.25C3.5 22.3219 5.17812 24 7.25 24C9.32188 24 11 22.3219 11 20.25C11 18.6562 10.0063 17.2922 8.6 16.7531C8.74531 16.5094 8.96562 16.2938 9.29844 16.125C10.0578 15.7406 11.1922 15.6375 12.3969 15.525C14.375 15.3422 16.6156 15.1312 17.9375 13.4906C18.5938 12.675 18.9266 11.625 18.95 10.3078C20.4312 9.80156 21.5 8.4 21.5 6.75ZM7.25 3C7.6625 3 8 3.3375 8 3.75C8 4.1625 7.6625 4.5 7.25 4.5C6.8375 4.5 6.5 4.1625 6.5 3.75C6.5 3.3375 6.8375 3 7.25 3ZM7.25 21C6.8375 21 6.5 20.6625 6.5 20.25C6.5 19.8375 6.8375 19.5 7.25 19.5C7.6625 19.5 8 19.8375 8 20.25C8 20.6625 7.6625 21 7.25 21ZM17.75 6C18.1625 6 18.5 6.3375 18.5 6.75C18.5 7.1625 18.1625 7.5 17.75 7.5C17.3375 7.5 17 7.1625 17 6.75C17 6.3375 17.3375 6 17.75 6Z"
                      fill="#374659"
                    />
                  </svg>
                </div>
                <div class="condition_heading_label">
                  {{ locale('locale_key.calculation-builder.if') }}
                  <span *ngIf="!emissionFactor.isFallback" class="highlight">
                    {{ locale('locale_key.calculation_builder.this_condition_applies') | lowercase }}
                  </span>
                  <span *ngIf="emissionFactor.isFallback" class="highlight">
                    {{ locale('locale_key.calculation-builder.none_of_conditions_apply') }}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#374659"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  class="remove_emission_factor_control"
                  (click)="adv_removeEmissionFactor(emissionFactorIdx)"
                  *ngIf="showEditMode"
                >
                  <div class="remove_emission_factor_icon_container">
                    <i class="remove_emission_factor_icon la la-trash-alt"></i>
                  </div>
                  <div class="remove_emission_factor_label">
                    <span *ngIf="!emissionFactor.isFallback">
                      {{
                        locale('locale_key.pages.data_categories_formula.remove_emission_factor', {
                          index: emissionFactorIdx + 1
                        })
                      }}
                    </span>
                    <span *ngIf="emissionFactor.isFallback">
                      {{ locale('locale_key.calculation_builder.remove_fallback') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="conditions_body">
                <div class="conditions_line_container">
                  <div class="conditions_line"></div>
                </div>
                <conditions-builder
                  *ngIf="!emissionFactor.isFallback"
                  [showEditMode]="showEditMode"
                  [calculation]="emissionFactor"
                  [level3DataCategory]="selectedDataCategory?.level_3"
                  [selectedDatapoint]="selectedDatapoint"
                ></conditions-builder>
                <div class="conditions_controls" *ngIf="showEditMode">
                  <div
                    *ngIf="!emissionFactor.isFallback"
                    class="conditions_control clear_condition"
                    (click)="adv_clearEmissionFactorCondition(emissionFactorIdx)"
                  >
                    <i class="las la-times"></i>
                    <span class="label">{{ locale('locale_key.calculation_builder.clear_condition') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="formula_container">
              <div class="formula_heading">
                <div class="formula_icon_container">
                  <svg
                    class="formula_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M20.25 0H3.75C2.55 0 1.5 1.05 1.5 2.25V21.75C1.5 22.95 2.55 24 3.75 24H20.25C21.45 24 22.5 22.95 22.5 21.75V2.25C22.5 1.05 21.45 0 20.25 0ZM7.5 20.4C7.5 20.7 7.2 21 6.9 21H5.1C4.8 21 4.5 20.7 4.5 20.4V18.6C4.5 18.3 4.8 18 5.1 18H6.9C7.2 18 7.5 18.3 7.5 18.6V20.4ZM7.5 14.4C7.5 14.7 7.2 15 6.9 15H5.1C4.8 15 4.5 14.7 4.5 14.4V12.6C4.5 12.3 4.8 12 5.1 12H6.9C7.2 12 7.5 12.3 7.5 12.6V14.4ZM13.5 20.4C13.5 20.7 13.2 21 12.9 21H11.1C10.8 21 10.5 20.7 10.5 20.4V18.6C10.5 18.3 10.8 18 11.1 18H12.9C13.2 18 13.5 18.3 13.5 18.6V20.4ZM13.5 14.4C13.5 14.7 13.2 15 12.9 15H11.1C10.8 15 10.5 14.7 10.5 14.4V12.6C10.5 12.3 10.8 12 11.1 12H12.9C13.2 12 13.5 12.3 13.5 12.6V14.4ZM19.5 20.4C19.5 20.7 19.2 21 18.9 21H17.1C16.8 21 16.5 20.7 16.5 20.4V12.6C16.5 12.3 16.8 12 17.1 12H18.9C19.2 12 19.5 12.3 19.5 12.6V20.4ZM19.5 8.4C19.5 8.7 19.2 9 18.9 9H5.1C4.8 9 4.5 8.7 4.5 8.4V3.6C4.5 3.3 4.8 3 5.1 3H18.9C19.2 3 19.5 3.3 19.5 3.6V8.4Z"
                      fill="#374659"
                    />
                  </svg>
                </div>
                <div class="formula_heading_label">
                  {{ locale('locale_key.calculation_builder.then') }}

                  <div class="dropdown then_dropdown">
                    <div class="dropdown-toggle">
                      <span class="highlight">{{ locale('locale_key.pages.emission.use_this_emission_factor') }}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="#374659"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <div class="adv_clear_emission_factor" (click)="adv_clearEmissionFactor(emissionFactorIdx)">
                    <i class="las la-times"></i>
                    <span class="label">
                      {{ locale('locale_key.pages.emission.clear_emission_factor') | lowercase }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="formula_body">
                <div class="formula_line_container">
                  <div class="formula_line"></div>
                </div>
                <emission-factor
                  [selectedEF]="emissionFactor.value"
                  [isEditable]="showEditMode"
                  (emissionFactorSelected)="
                    updateEmissionFactorAtIdx({ emissionFactor: $event, idx: emissionFactorIdx })
                  "
                  [datapointEFUnit]="filterEmissionFactor({ emissionFactor: emissionFactor.value })"
                ></emission-factor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add_new_fallback_container" *ngIf="showEditMode">
        <div class="number_indicator">
          <svg
            class="number_indicator_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#374659"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="number_indicator_label">
            {{ selectedDatapoint.emissionFactors.length + 1 }}
          </div>
        </div>
        <div class="add_new_emission_factor_control" (click)="adv_addEmissionFactor()">
          <div class="add_new_emission_factor_icon_container">
            <i class="add_new_emission_factor_icon las la-plus"></i>
          </div>
          <div class="add_new_emission_factor_label">
            {{ locale('locale_key.pages.emission.add_new_emission_factor') }}
          </div>
        </div>
        <div class="or">{{ locale('locale_key.general.or') | uppercase }}</div>
        <div
          class="add_fallback_emission_factor_control"
          [class.has_fallback]="adv_hasFallbackEmissionFactor"
          (click)="adv_addFallbackEmissionFactor()"
        >
          <div class="add_fallback_emission_factor_icon_container">
            <i class="add_fallback_emission_factor_icon las la-plus"></i>
          </div>
          <div class="add_fallback_emission_factor_label">
            {{ locale('locale_key.pages.emission.add_fallback_emission_factor') }}
          </div>
        </div>
      </div>
    </div>

    <!-- ERRORS -->
    <div class="error_msgs" *ngIf="showEmissionFactorErrors">
      <ul>
        <li *ngFor="let errorMsg of emissionFactorErrorMsgs">{{ errorMsg }}</li>
      </ul>
    </div>
  </ng-container>

  <ng-template #chooseSingleEmissionFactorModal>
    <div class="choose_single_emission_factor_modal">
      <div class="modal-header main-modal-header p-3 d-flex justify-content-between align-items-center">
        <h4 class="modal-title">{{ locale('locale_key.pages.emission.return_to_single_emission_factor') }}</h4>
        <button
          type="button"
          class="btn close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelChooseSingleEmissionFactor()"
        >
          <span aria-hidden="true"><i class="las la-times"></i></span>
        </button>
      </div>
      <div class="body p-4">
        <p>
          {{ locale('locale_key.pages.emission.switching_back_to_single_emission_factor') }}
        </p>
        <p>
          {{ locale('locale_key.pages.emission.please_select_emission_factor_keep') }}
        </p>
        <div class="emissionFactors mb-2">
          <div
            class="emissionFactor d-flex align-items-center"
            *ngFor="let emissionFactor of this.selectedDatapoint.emissionFactors; let idx = index"
          >
            <input
              [id]="'emissionFactorOption_' + idx"
              type="radio"
              name="emissionFactor"
              value="{{ idx }}"
              [(ngModel)]="selectedSingleEmissionFactorIdx"
            />
            <Label
              class="ms-1"
              [innerHTML]="
                sanitizeHtml(
                  locale(
                    'locale_key.pages.data_categories_formula.span_margin.source_unit_name_to_conversion_factor_emission_factor',
                    {
                      sourceUnit: emissionFactor.value.sourceUnit,
                      sourceName: emissionFactor.value.sourceName,
                      conversionFactor: emissionFactor.value.conversionFactor,
                      conversionUnit: emissionFactor.value.conversionUnit
                    }
                  )
                )
              "
            ></Label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="controls d-flex justify-content-end align-items-center">
          <button type="button" class="btn secondary-button mx-2" (click)="cancelChooseSingleEmissionFactor()">
            {{ locale('locale_key.general.buttons.cancel') }}
          </button>
          <button
            type="button"
            class="btn primary-button mx-2 custom-button"
            (click)="confirmChooseSingleEmissionFactor()"
          >
            {{ locale('locale_key.general.buttons.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
