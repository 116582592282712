<body
  class="horizontal-layout horizontal-menu-padding 1-column blank-page pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar"
  data-open="click"
  data-menu="horizontal-menu"
  data-col="1-column"
  data-gr-c-s-loaded="true"
>
  <div class="pace pace-inactive">
    <div
      class="pace-progress"
      data-progress-text="100%"
      data-progress="99"
      style="transform: translate3d(100%, 0px, 0px)"
    >
      <div class="pace-progress-inner"></div>
    </div>
    <div class="pace-activity"></div>
  </div>
  <div class="app-content container center-layout">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row"></div>
      <div class="content-body">
        <section class="row flexbox-container">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-4 col-md-8 col-10 box-shadow-2 pb-0 pr-0 pl-0 mb-5">
              <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <p></p>
                  <div class="card-body pb-0">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0">{{ locale('locale_key.forgot-password.email_address') }}</label>
                        <input
                          placeholder="email@domain.com"
                          type="text"
                          formControlName="email"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">
                            {{ locale('locale_key.forgot-password.email_is_required') }}
                          </div>
                          <div *ngIf="f.email.errors.email">
                            {{ locale('locale_key.forgot-password.email_is_invalid') }}
                          </div>
                        </div>
                      </fieldset>
                      <div style="border-top: 1px solid #e4e5ec; margin-bottom: 15px"></div>
                      <div class="form-group">
                        <button [disabled]="loading" type="submit" class="btn btn-outline-info btn-block">
                          <i class="fas fa-unlock"></i>
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          {{ locale('locale_key.general.buttons.submit') }}
                        </button>
                        <button routerLink="/op/login" type="submit" class="btn btn-outline-danger btn-block mx-2">
                          <i class="fas fa-unlock"></i>
                          {{ locale('locale_key.pages.insight_detail.cancel') }}
                        </button>
                      </div>
                    </form>
                    <br />
                    <div
                      class="alert-message text-center alert alert-warning alert-dismissible"
                      *ngIf="invalidRegistration == false"
                      role="alert"
                    >
                      <strong>{{ Message }}</strong>
                      <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div
                      class="alert-message text-center alert alert-warning alert-dismissible"
                      *ngIf="recievedError == false"
                      role="alert"
                    >
                      <strong>{{ locale('locale_key.forgot-password.error_message', { EMessage: EMessage }) }}</strong>
                      <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</body>
