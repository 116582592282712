import { stat } from 'fs'
import { TextUtils } from 'src/app/utils/text.utils'
import { BooleanStatementBuilderFe } from '../../data-suppliers/request/questionnaire/condition/BooleanStatementBuilderFe'
import { BooleanStatementFe } from '../../data-suppliers/request/questionnaire/condition/BooleanStatementFe'
import { NullValueStatementFe } from '../../data-suppliers/request/questionnaire/condition/NullValueStatementFe'
import { TwoOperandStatementFe } from '../../data-suppliers/request/questionnaire/condition/TwoOperandStatementFe'
import { ValueStatementFe } from '../../data-suppliers/request/questionnaire/condition/ValueStatementFe'
import { VariableStatementFe } from '../../data-suppliers/request/questionnaire/condition/VariableStatementFe'
import {
  Calculation,
  Condition
} from 'src/app/components/data-categories/calculation-builder/model/CalculationBuilderModels'
import { StatementBuilderFe } from '../../calculation/StatementBuilderFe'

export class KpiFilterConditionFe2 {
  public static INCLUDE = 'include'
  public static EXCLUDE = 'exclude'
}

export class KpiFilterFe2 {
  public taxonomyKey: string
  public filterCondition: string
  public calculation: Calculation

  constructor(taxonomyKey: string, filterCondition: string, calculation: any) {
    this.taxonomyKey = taxonomyKey
    this.filterCondition = filterCondition
    this.calculation = calculation
  }

  static fromTransfer(transfer: any): KpiFilterFe2 {
    if (transfer) {
      let filterCondition = transfer['filterCondition']
      let calculation = transfer['calculation']
      let filter = new KpiFilterFe2(transfer.taxonomyKey, filterCondition, calculation)
      return filter
    } else {
      return null
    }
  }

  // getInnerHTML () {
  //   let label = `${this.filterCondition == KpiFilterConditionFe.INCLUDE ? 'Only includes' : 'Excludes'} the data where`;
  //   let statementsConnector = (this.statement as TwoOperandStatementFe).operator
  //   this.unitTwoOperandStatements.forEach((statement, index) => {
  //     if (index!=0) {
  //       label += ` ${statementsConnector.label.toLowerCase()}`
  //     }
  //     if (statement.leftStatement instanceof VariableStatementFe) {
  //       let field = TextUtils.capitalizeFirstLetterAllWords(statement.leftStatement.reference)
  //       label += ` <b>${field}</b>`
  //     }
  //     label += ` ${statement.operator.label}`
  //     if (statement.rightStatement instanceof ValueStatementFe && !(statement.rightStatement instanceof NullValueStatementFe)) {
  //       label += ` <b>${statement.rightStatement.value}</b>`
  //     }
  //   })
  //   return label
  // }

  // getLabel () {
  //   let label = `${this.filterCondition == KpiFilterConditionFe.INCLUDE ? 'Only includes' : 'Excludes'} the data where`;
  //   let statementsConnector = (this.statement as TwoOperandStatementFe).operator
  //   this.unitTwoOperandStatements.forEach((statement, index) => {
  //     if (index!=0) {
  //       label += ` ${statementsConnector.label.toLowerCase()}`
  //     }
  //     if (statement.leftStatement instanceof VariableStatementFe) {
  //       let field = TextUtils.capitalizeFirstLetterAllWords(statement.leftStatement.reference)
  //       label += ` ${field}`
  //     }
  //     label += ` ${statement.operator.label}`
  //     if (statement.rightStatement instanceof ValueStatementFe && !(statement.rightStatement instanceof NullValueStatementFe)) {
  //       label += ` ${statement.rightStatement.value}`
  //     }
  //   })
  //   return label
  // }
}
