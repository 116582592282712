import { AbstractSignInMethodFe } from './AbstractSignInMethodFe'
import { SignInMethodProviderFe } from './SignInMethodProviderFe'

export class AffPasswordSignInMethodFe extends AbstractSignInMethodFe {
  constructor(isEnabled: boolean) {
    super(SignInMethodProviderFe.PASSWORD, isEnabled)
  }

  public static fromTransfer(transfer: any): AffPasswordSignInMethodFe {
    let singInMethod = new AffPasswordSignInMethodFe(transfer.isEnabled)
    return singInMethod
  }
}
