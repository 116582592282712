<div class="modal-header main-modal-header">
  <h4 class="modal-title text-white">Edit {{ table.name }}</h4>
  <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="false" class="list-inline text-white">
      <a (click)="closeModal()"><i class="las la-times"></i></a>
    </span>
  </button>
</div>
<div class="modal-body">
  <div id="swal2-content" style="display: block">
    Do you want to place {{ childTable.name }} ({{ selectedChildRowValues.join(',') }}) inside another
    {{ table.name }} or do you want to edit the information of this {{ table.name }} ?
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger btn-block custom-button" (click)="closeModal()">Cancel</button>
  <button type="submit" class="btn btn-add custom-button mt-0 ms-3" (click)="type = 'add Parent'; closeModal()">
    Change place
  </button>
  <button type="submit" class="btn btn-add custom-button mt-0 ms-3" (click)="type = 'edit'; closeModal()">
    Edit info
  </button>
</div>
