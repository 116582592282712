import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { MasterTableDataGridIntergationService } from 'src/app/model/taxonomy/MasterTableDataGridIntergationService'
import { DatahubService } from './DatahubService'

@Component({
  selector: 'master-table-portal',
  templateUrl: './masterTablePortal.component.html',
  styleUrls: ['./data-hub.component.scss']
})
export class MasterTablePortalComponent extends AbstractLanguageComponent implements OnInit {
  loadingInProgress = false
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  isCollapsed: boolean[] = []
  isCollapsed2: boolean[][] = []
  depTaxonomy: TaxonomyInfoFe
  selectedEntity: EntityFe = null
  filteredRootParentEntites: EntityFe[] = []
  allRootEntities: EntityFe[] = []
  downloadingMasterTable = false
  filteredRootEntities: EntityFe[] = []
  filteredChildEntities: EntityFe[] = []
  sortedBy = { name: this.locale('locale_key.pages.datahub.filter_none'), value: null }
  sortedByOptions = [
    { name: this.locale('locale_key.pages.datahub.sort.option.category_name'), value: 'categoryName' },
    { name: this.locale('locale_key.pages.datahub.sort.option.category_name_desc'), value: 'categoryNameDes' },
    { name: this.locale('locale_key.pages.datahub.sort.option.table_name'), value: 'tableName', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.table_name_desc'), value: 'tableNameDes' }
  ]

  constructor(
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private stateService: StateServiceFe,
    public datahubService: DatahubService,
    private displayService: DisplayServiceFe,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    super(languageService)

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    this.loadingInProgress = true
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.depTaxonomy = depTaxonomy
    this.allRootEntities = depTaxonomy.childrenSortedByOrdinal()
    if (this.datahubService.isMasterTablePortalView()) {
      if (
        this.datahubService.isDataCategoryFilter() &&
        this.datahubService.selectedItemToFilter &&
        this.datahubService.previousView.name == 'homeView'
      ) {
        this.showEntityMasterTable(this.datahubService.selectedItemToFilter)
      } else if (this.datahubService.isAnyFilter()) {
        this.allRootEntities = []
        this.filteredChildEntities = await this.datahubService.getFilteredEntites()
        this.filteredChildEntities.forEach((entity) => {
          let parentEntity = depTaxonomy.parentEntity(entity.key)
          this.filteredRootEntities.push(parentEntity)
          let rootParentEntity = depTaxonomy.rootParentEntity(entity.key)
          this.allRootEntities.push(rootParentEntity)
        })
      }
    }
    this.filter('')
    this.setCollapsed()
    this.loadingInProgress = false
  }

  getParentEntities(key: string) {
    let entities = this.depTaxonomy.childrenSortedByOrdinal(key)
    if (this.datahubService.isAnyFilter() && this.datahubService.isMasterTablePortalView()) {
      entities = this.filteredRootEntities.filter((entity) => this.depTaxonomy.rootParentKey(entity.key) == key)
    }
    if (this.sortedBy) {
      let sortFun
      if (this.sortedBy.value == 'categoryName') {
        sortFun = (a: EntityFe, b: EntityFe) => {
          if (a.getLabel().toLowerCase() < b.getLabel().toLowerCase()) {
            return -1
          } else if (a.getLabel().toLowerCase() > b.getLabel().toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'categoryNameDes') {
        sortFun = (a: EntityFe, b: EntityFe) => {
          if (a.getLabel().toLowerCase() < b.getLabel().toLowerCase()) {
            return 1
          } else if (a.getLabel().toLowerCase() > b.getLabel().toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      }
      if (sortFun) {
        entities = entities.sort((a, b) => sortFun(a, b))
      }
    }
    return entities
  }

  getChildEntities(key: string) {
    let entities = this.depTaxonomy.childrenSortedByOrdinal(key)
    if (this.datahubService.isAnyFilter() && this.datahubService.isMasterTablePortalView()) {
      entities = this.filteredChildEntities.filter((entity) => this.depTaxonomy.parentKey(entity.key) == key)
    }

    if (this.sortedBy) {
      let sortFun
      if (this.sortedBy.value == 'tableName') {
        sortFun = (a: EntityFe, b: EntityFe) => {
          if (a.getLabel().toLowerCase() < b.getLabel().toLowerCase()) {
            return -1
          } else if (a.getLabel().toLowerCase() > b.getLabel().toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'tableNameDes') {
        sortFun = (a: EntityFe, b: EntityFe) => {
          if (a.getLabel().toLowerCase() < b.getLabel().toLowerCase()) {
            return 1
          } else if (a.getLabel().toLowerCase() > b.getLabel().toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      }
      if (sortFun) {
        entities = entities.sort((a, b) => sortFun(a, b))
      }
    }

    return entities
  }

  setCollapsed() {
    this.filteredRootParentEntites.forEach((root) => {
      this.isCollapsed.push(false)
      let parents = this.depTaxonomy.childrenSortedByOrdinal(root.key)
      let collapsed2 = parents.map((c) => true)
      this.isCollapsed2.push(collapsed2)
    })
  }

  collapseAll() {
    this.isCollapsed = this.isCollapsed.map((c) => true)
    this.isCollapsed2 = this.isCollapsed2.map((c) => (c = c.map((c) => true)))
  }

  expandAll() {
    this.isCollapsed = this.isCollapsed.map((c) => false)
    this.isCollapsed2 = this.isCollapsed2.map((c) => (c = c.map((c) => false)))
  }

  updateDetailsData(entity: EntityFe) {
    let detailsData = { entity }
    this.displayService.updateDetailsData(detailsData)
  }

  filter(keyword: string) {
    this.filteredRootParentEntites = this.allRootEntities.filter((root) => {
      if (root.getLabel().match(new RegExp(keyword, 'i'))) {
        return true
      }
      let parents = this.depTaxonomy.childrenSortedByOrdinal(root.key)
      if (parents.find((p) => p.getLabel().match(new RegExp(keyword, 'i')))) {
        return true
      }
      let found = false
      for (let i = 0; i < parents.length; i++) {
        let children = this.depTaxonomy.childrenSortedByOrdinal(parents[i].key)
        if (children.find((p) => p.getLabel().match(new RegExp(keyword, 'i')))) {
          found = true
          return true
        }
      }
      return found
    })
    this.setCollapsed()
    this.expandAll()
  }

  showEntityMasterTable(item) {
    this.datahubService.showEntityMasterTable(item)
  }

  async downloadMasterTable(item: EntityFe, event: MouseEvent, exportType: string) {
    event.stopPropagation()
    this.selectedEntity = item
    if (!item.dataGridService) {
      item.dataGridService = new MasterTableDataGridIntergationService(item, this.stateService, this.languageService)
    }
    await item.dataGridService.downloadWholeTable(exportType)
  }

  selectEntity(entity: EntityFe) {
    this.selectedEntity = entity
    if (this.datahubService.isHomeView()) {
      this.datahubService.selectItemToFilter(entity)
    }
    if (this.displayService.openTab == 'DETAILS') {
      this.updateDetailsData(entity)
    }
  }

  noOfDataCategories(rootKey: string): number {
    let parent = this.getParentEntities(rootKey)
    return parent.length
  }

  noOfSubDataCategories(rootKey: string): number {
    let parent = this.getParentEntities(rootKey)
    let child = []
    parent.forEach((item) => child.push(...this.getChildEntities(item.key)))
    return child.length
  }

  sort(option) {
    this.sortedBy = option
  }
}
