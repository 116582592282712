import { StringUtilFe } from 'src/app/utils/StringUtilFe'
import { AbstractInvitationFe } from '../../invitation/AbstractInvitationFe'

export class PartnerInvitationFe extends AbstractInvitationFe {
  inviterCompanyId: string
  inviterCompanyName: string
  managementCompanyId: string
  managementCompanyName: string
  partnerName: string
  partnerPurpose: string
  partnerCountryCode: string
  partnerIndustryGroup: string
  partnerIndustry: string
  partnerWebsite: string
  primaryContactFirstName: string
  primaryContactLastName: string
  primaryContactEmail: string

  constructor(
    uuid: string,
    inviterFirstName: string,
    inviterLastName: string,
    inviterCompanyId: string,
    inviterCompanyName: string,
    managementCompanyId: string,
    managementCompanyName: string,
    partnerName: string,
    partnerPurpose: string,
    partnerCountryCode: string,
    partnerIndustryGroup: string,
    partnerIndustry: string,
    partnerWebsite: string,
    primaryContactFirstName: string,
    primaryContactLastName: string,
    primaryContactEmail: string,
    invitationDate: Date,
    invitationStatus: string
  ) {
    super(uuid, inviterFirstName, inviterLastName, invitationDate, invitationStatus)
    this.inviterCompanyId = inviterCompanyId
    this.inviterCompanyName = inviterCompanyName
    this.managementCompanyId = managementCompanyId
    this.managementCompanyName = managementCompanyName
    this.partnerName = partnerName
    this.partnerPurpose = partnerPurpose
    this.partnerCountryCode = partnerCountryCode
    this.partnerIndustryGroup = partnerIndustryGroup
    this.partnerIndustry = partnerIndustry
    this.partnerWebsite = partnerWebsite
    this.primaryContactFirstName = primaryContactFirstName
    this.primaryContactLastName = primaryContactLastName
    this.primaryContactEmail = StringUtilFe.trimEmail(primaryContactEmail)
  }

  public static fromTransfer(transfer: any): PartnerInvitationFe {
    let partnerInvitationFe = new PartnerInvitationFe(
      transfer.uuid,
      transfer.inviterFirstName,
      transfer.inviterLastName,
      transfer.inviterCompanyId,
      transfer.inviterCompanyName,
      transfer.managementCompanyId,
      transfer.managementCompanyName,
      transfer.partnerName,
      transfer.partnerPurpose,
      transfer.partnerCountryCode,
      transfer.partnerIndustryGroup,
      transfer.partnerIndustry,
      transfer.partnerWebsite,
      transfer.primaryContactFirstName,
      transfer.primaryContactLastName,
      transfer.primaryContactEmail,
      transfer.invitationTime,
      transfer.status
    )
    return partnerInvitationFe
  }
}
