import { StringUtilFe } from 'src/app/utils/StringUtilFe'
import { AbstractInvitationFe } from './AbstractInvitationFe'

export class ManagerInvitationFe extends AbstractInvitationFe {
  inviterAffId: string
  clientCompanyId: string
  clientCompanyName: string
  managerFirstName: string
  managerLastName: string
  managerEmail: string

  constructor(
    uuid: string,
    inviterAffId: string,
    inviterFirstName: string,
    inviterLastName: string,
    clientCompanyId: string,
    clientCompanyName: string,
    managerFirstName: string,
    managerLastName: string,
    managerEmail: string,
    invitationDate: Date,
    invitationStatus: string
  ) {
    super(uuid, inviterFirstName, inviterLastName, invitationDate, invitationStatus)
    this.inviterAffId = inviterAffId
    this.inviterFirstName = inviterFirstName
    this.inviterLastName = inviterLastName
    this.clientCompanyId = clientCompanyId
    this.clientCompanyName = clientCompanyName
    this.managerFirstName = managerFirstName
    this.managerLastName = managerLastName
    this.managerEmail = StringUtilFe.trimEmail(managerEmail)
    this.invitationDate = invitationDate
  }

  getName() {
    return `${this.managerFirstName} ${this.managerLastName}`
  }

  public static fromTransfer(transfer: any): ManagerInvitationFe {
    let invitation = new ManagerInvitationFe(
      transfer.uuid,
      transfer.adminAffId,
      transfer.adminFirstName,
      transfer.adminLastName,
      transfer.clientCompanyId,
      transfer.clientCompanyName,
      transfer.managerFirstName,
      transfer.managerLastName,
      transfer.managertEmail,
      transfer.invitationTime,
      transfer.status
    )
    return invitation
  }
}
