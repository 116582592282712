import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import * as _ from 'lodash'
import { GlobalDataTaxonomyFe } from 'src/app/model/taxonomy/GlobalDataTaxonomyFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { TaxonomyInfoSubjectFe } from 'src/app/model/subject/TaxonomyInfoSubjectFe'
import { AddDatapointDialogComponent } from './add-datapoint-dialog/add-datapoint-dialog.component'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { DatahubService } from '../data-hub/DatahubService'
import { Router } from '@angular/router'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { UnitFe } from '../unit-systems/model/UnitFe'
import { groupUnitsByMeasurementTypeAndSystem } from '../unit-systems/model/utils'
import { UnitsByMeasurementType } from '../unit-systems/model/UnitsByMeasurementType'
import { Subscription } from 'rxjs'
import { Language } from 'src/app/utils/language/Language'
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import {
  UnitMeasurementTypes,
  getUnitMeasurementTypeName,
  getUnitMeasurementTypeDefaultUnitSymbol
} from '../unit-systems/model/UnitMeasurementType'
import { DatapointDatatype } from 'src/app/model/taxonomy/DatapointDatatypeFe'
import { DatapointProvidedBy } from 'src/app/model/taxonomy/DatapointProvidedByFe'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { CustomEmissionFactorFe } from 'src/app/model/emissions/CustomEmissionFactorFe'
import { TaxonomyEmissionFactorFe } from 'src/app/model/taxonomy/TaxonomyEmissionFactorFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import {
  CloseBracketOperator,
  FixedNumberOperand,
  Formula,
  OpenBracketOperator,
  Operand,
  OperandTypes,
  TimesOperator,
  TokenTypes,
  cloneCalculation
} from './calculation-builder/model/CalculationBuilderModels'
import Big from 'big.js'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { UnitServiceFe } from 'src/app/services/UnitServiceFe'
import { CalculationHarmonization } from './CalculationHarmonization'

enum ViewStates {
  viewing = 'viewing',
  editing = 'editing'
}

enum PageStates {
  datacategories = 'datacategories',
  datapoints = 'datapoints',
  datapoint = 'datapoint'
}

enum ModalNames {
  categorySelectorModal = 'categorySelectorModal',
  editDatapointModal = 'editDatapointModal',
  duplicateCategoryModal = 'duplicateCategoryModal',
  editCategoryModal = 'editCategoryModal',
  deleteCategoryModal = 'deleteCategoryModal',
  deleteDatapointModal = 'deleteDatapointModal',
  saveAsDraftModal = 'saveAsDraftModal',
  cancelEditingDatacategoriesModal = 'cancelEditingDatacategoriesModal',
  addDatapointDialog = 'addDatapointDialog',
  deleteCategoryTranslationsModal = 'deleteCategoryTranslationsModal',
  deleteDatapointTranslationsModal = 'deleteDatapointTranslationsModal',
  brokenCalculationsModal = 'brokenCalculationsModal'
}

@Component({
  selector: 'data-categories',
  templateUrl: './data-categories.component.html',
  styleUrls: ['./data-categories.component.scss']
})
export class DataCategoriesComponent extends AbstractLanguageComponent implements OnInit {
  globalTaxonomy: GlobalDataTaxonomyFe
  currentState = ViewStates.viewing
  currentPage = PageStates.datacategories
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left',
        actionName: 'back',
        visible: () => this.currentPage != PageStates.datacategories || this.currentState == ViewStates.editing,
        disabled: false
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.edit'),
        longLabel: this.slocale('Edit data categories'),
        tooltip: this.locale('Edit data categories'),
        icon: 'la la-edit',
        actionName: 'edit',
        visible: () => this.currentState == ViewStates.viewing,
        disabled: false
      }
    ]
  ]

  dcIcons = {
    environmental: 'las la-globe-europe',
    social: 'las la-user',
    governance: 'las la-gavel',
    economic: 'las la-chart-bar'
  }

  dataCategories = {
    next: { level_1: [], level_2: {}, level_3: {} },
    deployed: { level_1: [], level_2: {}, level_3: {} }
  }

  taxonomies: {
    depTaxonomy: TaxonomyInfoFe
    newTaxonomy: TaxonomyInfoFe
  }

  // To support undo
  staticTaxonomies: {
    depTaxonomy: TaxonomyInfoFe
    newTaxonomy: TaxonomyInfoFe
  }

  selectedDataCategory: {
    level_1: EntityFe
    level_2: EntityFe
    level_3: EntityFe
  } = {
    level_1: new EntityFe(this.languageService),
    level_2: new EntityFe(this.languageService),
    level_3: new EntityFe(this.languageService)
  }

  @ViewChild(`${ModalNames.categorySelectorModal}`, { static: true })
  categorySelectorModal: TemplateRef<any>

  @ViewChild(`${ModalNames.editDatapointModal}`, { static: true })
  editDatapointModal: TemplateRef<any>

  @ViewChild(`${ModalNames.duplicateCategoryModal}`, { static: true })
  duplicateCategoryModal: TemplateRef<any>

  @ViewChild(`${ModalNames.editCategoryModal}`, { static: true })
  editCategoryModal: TemplateRef<any>

  @ViewChild(`${ModalNames.deleteCategoryModal}`, { static: true })
  deleteCategoryModal: TemplateRef<any>

  @ViewChild(`${ModalNames.deleteDatapointModal}`, { static: true })
  deleteDatapointModal: TemplateRef<any>

  @ViewChild(`${ModalNames.saveAsDraftModal}`, { static: true })
  saveAsDraftModal: TemplateRef<any>

  @ViewChild(`${ModalNames.cancelEditingDatacategoriesModal}`, { static: true })
  cancelEditingDatacategoriesModal: TemplateRef<any>

  @ViewChild(`${ModalNames.addDatapointDialog}`, { static: false })
  addDatapointDialog: AddDatapointDialogComponent

  @ViewChild(`${ModalNames.deleteCategoryTranslationsModal}`, { static: false })
  deleteCategoryTranslationsModal: TemplateRef<any>

  @ViewChild(`${ModalNames.deleteDatapointTranslationsModal}`, { static: false })
  deleteDatapointTranslationsModal: TemplateRef<any>

  @ViewChild(`${ModalNames.brokenCalculationsModal}`, { static: false })
  brokenCalculationsModal: TemplateRef<any>

  showDetailsDataCategoryAccordion: boolean = false

  private languageSubscription: Subscription
  private displayPageLanguageSubject: Subscription
  activeLanguage: Language
  lang: string
  isLoading: boolean = true

  modals

  searchFilter = ''
  datapointSearchFilter = ''

  deletingCategory
  editingCategory
  editingCategoryLabel = ''
  isEditingDatapointLabel: boolean
  editingDatapointEnLabelDesc = ''
  editingDatapointLabel = ''
  editingDatapointDescription = ''

  pageLanguage: Language

  inProgress = false
  isDisabledTranslation: boolean = true

  selectedDatapoint: TaxonomyAttributeFe
  selectedMeasurementType: Set<string> = new Set()
  categoryTranslations: any[] = []
  datapointLabelTranslations: any[] = []
  datapointDescriptionTranslations: any[] = []
  addCustomCategoryInput = ''
  deletingDatapoint

  savingInProgress = false
  showSaveSuccessfulMsg = false
  hasDraft = false
  languages = this.languageService.availableLanguages
  availableLanguages: Language[] = []
  loadCalculationBuilderForDatapoint: string = null
  hasCalculationErrors: boolean = false
  showCalculationErrors: boolean = false
  isEditingDisplayLangDataCategoryDeleted = false
  datapointTranslationDuplicateErrorMap: Map<String, boolean> = new Map()

  selectedUnitContainer: {
    selectedUnit?: UnitFe
  } = {}
  units: UnitFe[] = []
  customUnits: UnitFe[] = []
  unitsByMeasurementType: UnitsByMeasurementType[] = []

  selectedLevel2Categories: Set<EntityFe> = new Set()

  datapointForm1 = new FormGroup({
    shortNameEnText: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationRegex.DatapointLabelEnglish),
      this.checkDatapointDuplicateEnglishShortName.bind(this)
    ])
  })

  datapointForm2: FormGroup

  categoryFormGroup = new FormGroup({
    categoryNameText: new FormControl('')
  })

  categoryFormGroup2 = new FormGroup({
    categoryNameText: new FormControl('', {
      validators: [
        Validators.required,
        Validators.pattern(ValidationRegex.DatapointLabelEnglish),
        this.checkCategoryDuplicateName.bind(this)
      ]
    })
  })

  measurementTypesWithData = []
  measurementTypes = []
  filteredMeasurementTypes = []
  conversionFactorMeasurementTypeInput = ''
  sourceMeasurementTypeInput = ''
  showEmissionFactorErrors: boolean = false
  emissionFactorErrorMsgs = new Set()
  brokenCalculationsDatapoints = []

  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  constructor(
    languageService: LanguageService,
    private stateService: StateServiceFe,
    private modalService: BsModalService,
    private backendService: RouterFe,
    private ErrorsFe: ErrorsFe,
    private alertService: AlertServiceFe,
    private displayService: DisplayServiceFe,
    public datahubService: DatahubService,
    private router: Router,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private responsive: ResponsiveService,
    private unitService: UnitServiceFe
  ) {
    super(languageService)
    if (stateService.activeWorkspace) {
      this.loadDataCategories()
    }
    stateService.depTaxonomyInfoSubject.subscribe((subject: TaxonomyInfoSubjectFe) => {
      this.taxonomies.depTaxonomy = subject.taxonomyInfo
    })
    stateService.newTaxonomyInfoSubject.subscribe((subject: TaxonomyInfoSubjectFe) => {
      this.taxonomies.newTaxonomy = subject.taxonomyInfo
    })
    stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit() {
    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    this.displayPageLanguageSubject = this.languageService.pageLanguageSubject.subscribe((language: Language) => {
      this.pageLanguage = language
    })

    // initial display language
    this.pageLanguage = this.languageService.activeLanguage

    await this.loadDataCategories()
    this.setupModals()
    await this.loadUnits()

    this.isLoading = false

    this.updateDatapointForm2Validators()

    //Get measurment type keys from units that are allowed to display
    const units = await this.stateService.getUnits()
    const measurementTypesWithDataKeys = new Set()
    units
      .filter((unit) => unit.shouldDisplay)
      .forEach((unit) => {
        measurementTypesWithDataKeys.add(unit.measurementType)
      })

    this.measurementTypesWithData = Array.from(measurementTypesWithDataKeys)

    Object.keys(UnitMeasurementTypes).forEach((key) => {
      const measurementType = {
        key,
        name: getUnitMeasurementTypeName(UnitMeasurementTypes[key]),
        defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[key])
      }
      this.measurementTypes.push(measurementType)
    })

    let measurementTypes = _.cloneDeep(this.measurementTypes)
    //filter out measurement type
    const exludeMeausurementTypes = [UnitMeasurementTypes.NO_UNIT, UnitMeasurementTypes.BINARY]
    measurementTypes = measurementTypes.filter(
      (measurementType) => !exludeMeausurementTypes.includes(measurementType.key)
    )
    this.filteredMeasurementTypes = measurementTypes
  }

  updateDatapointForm2Validators() {
    let shortNameValidators

    if (this.pageLanguage.code === 'en') {
      shortNameValidators = [
        Validators.required,
        Validators.pattern(ValidationRegex.DatapointLabelEnglish),
        this.checkDatapointDuplicateEnglishShortName.bind(this)
      ]
    } else {
      shortNameValidators = [this.checkDatapointDuplicateShortName.bind(this)]
    }

    if (this.datapointForm2) {
      // Update validators of existing form control
      const shortNameControl = this.datapointForm2.get('shortNamePageLangText')
      shortNameControl.clearValidators()
      shortNameControl.setValidators(shortNameValidators)
      shortNameControl.updateValueAndValidity()
    } else {
      // Create new form group with validators
      this.datapointForm2 = this.fb.group({
        shortNamePageLangText: new FormControl('', shortNameValidators),
        descriptionPageLangText: new FormControl('', [Validators.required])
      })
    }
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }

    if (this.displayPageLanguageSubject) {
      this.displayPageLanguageSubject.unsubscribe()
    }

    this.languageService.clearDisplayLanguage()
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  async loadDataCategories(reload = false) {
    await this.loadGloblTaxonomy()
    const taxonomies = await this.stateService.getTaxonomyInfos(reload)
    taxonomies.depTaxonomy.entities = _.sortBy(taxonomies.depTaxonomy.entities, 'ordinal')
    taxonomies.newTaxonomy.entities = _.sortBy(taxonomies.newTaxonomy.entities, 'ordinal')
    this.taxonomies = taxonomies
    this.seedTaxonomy()
    this.consolidateTaxonomyDiversion()
    this.checkForDraft()
    this.staticTaxonomies = {
      depTaxonomy: taxonomies.depTaxonomy.toJSON(),
      newTaxonomy: taxonomies.newTaxonomy.toJSON()
    }
    this.dataCategories = this.convertToDataCategories(taxonomies)
  }

  private seedTaxonomy() {
    if (_.isEmpty(this.taxonomies.depTaxonomy.entities) && _.isEmpty(this.taxonomies.newTaxonomy.entities)) {
      const lvl1s = this.getSuggestedlvl1s()
      lvl1s.forEach((lvl1) => {
        this.taxonomies.depTaxonomy.addEntity(lvl1)
        this.taxonomies.newTaxonomy.addEntity(lvl1)
      })
    }
  }

  private checkForDraft() {
    let _hasDraft = this.hasDraft
    this.taxonomies.newTaxonomy.entities.forEach((entity) => {
      if (!_hasDraft) {
        if (entity.isNew || entity.isEdited || entity.isRemoved) {
          _hasDraft = true
        }
        if (!_hasDraft) {
          entity.columns.forEach((column) => {
            if (!_hasDraft) {
              if (column.isNew || column.isEdited || column.isRemoved) {
                _hasDraft = true
              }
            }
          })
        }
      }
    })
    this.hasDraft = _hasDraft
  }

  /*
    Fix any wrong state ( isNew, isEdited, isRemoved )
    context : when trying to find a way to handle the edit states
    without sending it to the backend (in the end had to send them to backend) 
    alot of entities got out of sync and weren't easy to consolidate, 
    so created this function to fix them.. and decided leaving it here would be useful
   */
  private consolidateTaxonomyDiversion() {
    //isNew
    this.taxonomies.newTaxonomy.entities.forEach((nEntity) => {
      const dEntity = _.find(this.taxonomies.depTaxonomy.entities, ['key', nEntity.key])
      if (_.isEmpty(dEntity)) {
        nEntity.isNew = true
        this.hasDraft = true
      }
      if (!_.isEmpty(dEntity)) {
        nEntity.columns.forEach((nColumn) => {
          const dColumn = _.find(dEntity.columns, ['key', nColumn.key])
          if (_.isEmpty(dColumn)) {
            nColumn.isNew = true
            this.hasDraft = true
          }
        })
      }
    })
  }

  async loadGloblTaxonomy() {
    let globalTaxonomy = await this.backendService.getGlobalDataTaxonomy().toPromise()
    this.globalTaxonomy = new GlobalDataTaxonomyFe(globalTaxonomy, this.languageService)
  }

  private filterTaxonomies(query) {
    const ndep = this.taxonomies.depTaxonomy.toJSON()
    //filter pass
    ndep.entities = ndep.entities.filter((entity) => {
      return entity.getLabel().toLowerCase().includes(query.toLowerCase())
    })

    //includes pass
    const ndepIncludes = []
    ndep.entities.forEach((entity) => {
      const tokens = entity.key.split('.')
      const level = tokens.length
      // If level 1, include level 2 children and level 3 children
      if (level == 1) {
        const level1ParentKey = `${tokens[0]}`
        this.taxonomies.depTaxonomy.entities.forEach((entity) => {
          if (entity.key.split('.').length == 2 && entity.key.startsWith(level1ParentKey)) {
            ndepIncludes.push(entity)
          }
          if (entity.key.split('.').length == 3 && entity.key.startsWith(level1ParentKey)) {
            ndepIncludes.push(entity)
          }
        })
      }

      // If level 2, include level 1 parent and level 3 children
      if (level == 2) {
        const level1ParentKey = `${tokens[0]}`
        const level2ParentKey = `${tokens[0]}.${tokens[1]}`
        this.taxonomies.depTaxonomy.entities.forEach((entity) => {
          if (entity.key == level1ParentKey) {
            ndepIncludes.push(entity)
          }
          if (entity.key.split('.').length == 3 && entity.key.includes(level2ParentKey)) {
            ndepIncludes.push(entity)
          }
        })
      }

      // If level 3, include level 2 parent and level 1 parent
      if (level == 3) {
        const level1ParentKey = `${tokens[0]}`
        const level2ParentKey = `${tokens[0]}.${tokens[1]}`
        this.taxonomies.depTaxonomy.entities.forEach((entity) => {
          if (entity.key == level1ParentKey || entity.key == level2ParentKey) {
            ndepIncludes.push(entity)
          }
        })
      }
    })
    ndep.entities = [...ndepIncludes, ...ndep.entities]

    // de-deuplicate
    const ndepChecked = new Set()
    ndep.entities = ndep.entities.filter((entity) => {
      if (ndepChecked.has(entity.key)) {
        return false
      }
      ndepChecked.add(entity.key)
      return true
    })
    ndep.entities = _.sortBy(ndep.entities, 'ordinal')

    const nnew = this.taxonomies.newTaxonomy.toJSON()
    //filter pass
    nnew.entities = nnew.entities.filter((entity) => {
      return entity.getLabel().toLowerCase().includes(query.toLowerCase())
    })

    //includes pass
    const nnewIncludes = []
    nnew.entities.forEach((entity) => {
      const tokens = entity.key.split('.')
      const level = tokens.length
      // If level 1, include level 2 children and level 3 children
      if (level == 1) {
        const level1ParentKey = `${tokens[0]}`
        this.taxonomies.newTaxonomy.entities.forEach((entity) => {
          if (entity.key.split('.').length == 2 && entity.key.startsWith(level1ParentKey)) {
            nnewIncludes.push(entity)
          }
          if (entity.key.split('.').length == 3 && entity.key.startsWith(level1ParentKey)) {
            nnewIncludes.push(entity)
          }
        })
      }

      // If level 2, include level 1 parent and level 3 children
      if (level == 2) {
        const level1ParentKey = `${tokens[0]}`
        const level2ParentKey = `${tokens[0]}.${tokens[1]}`
        this.taxonomies.newTaxonomy.entities.forEach((entity) => {
          if (entity.key == level1ParentKey) {
            nnewIncludes.push(entity)
          }
          if (entity.key.split('.').length == 3 && entity.key.startsWith(level2ParentKey)) {
            nnewIncludes.push(entity)
          }
        })
      }

      // If level 3, include level 2 parent and level 1 parent
      if (level == 3) {
        const level1ParentKey = `${tokens[0]}`
        const level2ParentKey = `${tokens[0]}.${tokens[1]}`
        this.taxonomies.newTaxonomy.entities.forEach((entity) => {
          if (entity.key == level1ParentKey || entity.key == level2ParentKey) {
            nnewIncludes.push(entity)
          }
        })
      }
    })
    nnew.entities = [...nnewIncludes, ...nnew.entities]

    // de-deuplicate
    const nnewChecked = new Set()
    nnew.entities = nnew.entities.filter((entity) => {
      if (nnewChecked.has(entity.key)) {
        return false
      }
      nnewChecked.add(entity.key)
      return true
    })

    nnew.entities = _.sortBy(nnew.entities, 'ordinal')

    const ntaxonomies = {
      depTaxonomy: ndep,
      newTaxonomy: nnew
    }

    return ntaxonomies
  }

  getSuggestedlvl1s() {
    const taxonomyInfo = this.taxonomies.newTaxonomy
    const existingLvls: any[] = taxonomyInfo?.childrenSortedByOrdinal(null)
    let lvl1s = this.globalTaxonomy.children(null)
    if (lvl1s) {
      lvl1s = lvl1s.filter((gitem) => {
        let item = existingLvls.find((litem) => litem.key == gitem.key)
        return item ? false : true
      })
      return lvl1s
    }
    return lvl1s
  }

  resolveString(str: any): string {
    return str[this.pageLanguage?.code] || str[this.activeLanguage.code] || str['en']
  }

  addCategoryTranslation() {
    this.updateAvailableLanguages(true)

    const langObj = this.availableLanguages[0]

    if (!langObj) {
      return
    }

    this.categoryTranslations.push({ language: langObj, text: this.editingCategory.label[langObj.code] || '' })
    this.toggleTranslationDisabled(false)
  }

  checkCategoryEnglishAndGetFlag() {
    this.updateDatacategoryValidators()

    if (this.editingCategory.label[this.pageLanguage?.code]) {
      return `fi fi-${this.pageLanguage?.icon}`
    }
    return `fi fi-us`
  }

  updateDatacategoryValidators() {
    const isEnglish = this.pageLanguage?.code === 'en'
    const validators = isEnglish
      ? [
          Validators.required,
          Validators.pattern(ValidationRegex.DatapointLabelEnglish),
          this.checkCategoryDuplicateName.bind(this)
        ]
      : [Validators.required, this.checkCategoryDuplicateName.bind(this)]

    this.categoryFormGroup.get('categoryNameText').setValidators(validators)
    this.categoryFormGroup.get('categoryNameText').updateValueAndValidity()
  }

  applyAllTranslations(isDataCategory: boolean) {
    if (isDataCategory) {
      this.applyDataCategoryTranslations()
      this.toggleTranslationDisabled(false)
    } else {
      this.applyDatapointTranslations()
      this.toggleTranslationDisabled(false)
    }
  }

  applyDataCategoryTranslations() {
    this.categoryTranslations.length = 0
    const gTaxonomy = this.globalTaxonomy.entities.find((e) => e.key === this.editingCategory.key)

    this.categoryTranslations = this.languages.map((lang) => ({
      language: lang,
      text: gTaxonomy.label[lang.code] || gTaxonomy.label['en']
    }))

    if (!this.editingCategory.label.hasOwnProperty(this.pageLanguage.code) || this.pageLanguage.code === 'en') {
      this.categoryTranslations = this.categoryTranslations.filter((translation) => translation.language.code !== 'en')
    }
  }

  applyDatapointTranslations() {
    const list = this.getDatapointTranslations()
    list.length = 0
    const gTaxonomy = this.globalTaxonomy.entities.find((e) => e.key === this.selectedDataCategory.level_3.key)
    const suggestedDatapoint = gTaxonomy.attributes.find((attr) => attr.key === this.selectedDatapoint.key)

    this.languages
      .filter((lang) => lang.code !== 'en')
      .forEach((lang) => {
        const text = this.isEditingDatapointLabel
          ? (suggestedDatapoint.label && suggestedDatapoint.label[lang.code]) || suggestedDatapoint.label['en']
          : (suggestedDatapoint.description && suggestedDatapoint.description[lang.code]) ||
            suggestedDatapoint.description['en']

        if (!list[lang.code]) {
          list.push({ language: lang, text: text || this.editingDatapointEnLabelDesc })
        }
      })
  }

  clearTranslations(isDataCategory: boolean) {
    if (isDataCategory) {
      this.categoryTranslations = []
      this.updateAvailableLanguages(true)
      this.cancelCategoryTranslationsModal()
    } else {
      this.clearDatapointTranslations()
      this.updateAvailableLanguages(false)
      this.cancelDatapointTranslationsModal()
    }

    this.toggleTranslationDisabled(true)
  }

  setTranslationLanguage(translation: any, language: any, isDataCategory: boolean) {
    translation.language = language

    if (isDataCategory) {
      translation.text = this.editingCategory.label[language.code] || ''
    } else if (this.isEditingDatapointLabel) {
      translation.text = this.selectedDatapoint.label[language.code] || ''
    } else {
      translation.text = this.selectedDatapoint.description[language.code] || ''
    }

    this.updateAvailableLanguages(isDataCategory)
  }

  setPageLanguage(language: Language) {
    this.resetDatapointFormValidators()

    this.pageLanguage = language
    this.languageService.changePageLanguage(language.code)

    this.editingDatapointLabel = this.selectedDatapoint?.label[this.pageLanguage.code]
    this.editingDatapointDescription = this.selectedDatapoint?.description[this.pageLanguage.code]

    this.updateDatapointForm2Validators()
  }

  isLanguageSelected(language: Language, isDataCategory: boolean): boolean {
    if (isDataCategory) {
      return this.isLanguageSelectedForCategory(language)
    }
    return this.isLanguageSelectedForDatapoint(language)
  }

  isLanguageSelectedForCategory(language: Language): boolean {
    return (
      this.categoryTranslations.some((translation) => translation.language?.code === language.code) ||
      (this.editingCategory.label?.[language.code] &&
        (this.pageLanguage.code === language.code || this.pageLanguage.code !== 'en'))
    )
  }

  isLanguageSelectedForDatapoint(language: Language): boolean {
    const list = this.getDatapointTranslations()
    return list.some((translation) => translation.language && translation.language.code === language.code)
  }

  updateAvailableLanguages(isDataCategory: boolean, excludeLangCode?: string) {
    this.availableLanguages = this.languages.filter((l) => !this.isLanguageSelected(l, isDataCategory))

    if (excludeLangCode) {
      this.availableLanguages = this.availableLanguages.filter((l) => l.code !== excludeLangCode)
    }
  }

  deleteCategoryTranslation(index: number) {
    if (index < 0 || index > this.categoryTranslations.length) return
    this.categoryTranslations.splice(index, 1)
    this.updateAvailableLanguages(true)

    if (this.categoryTranslations.length == 0) {
      this.toggleTranslationDisabled(true)
    }
  }

  getSuggestedDataCategories(parentKey) {
    const taxonomyInfo = this.taxonomies.newTaxonomy
    const existingDataCategories: any[] = taxonomyInfo.childrenSortedByOrdinal(parentKey)

    let suggestedDataCategories = this.globalTaxonomy.children(parentKey)

    if (suggestedDataCategories) {
      suggestedDataCategories = suggestedDataCategories.filter((gitem) => {
        let item = existingDataCategories.find((litem) => litem.key == gitem.key)
        return item ? false : true
      })
    }
    return suggestedDataCategories
  }

  getDataCategories() {
    if (this.currentState == ViewStates.viewing && !_.isEmpty(this.searchFilter)) {
      const ntaxonomies = this.filterTaxonomies(this.searchFilter)
      this.dataCategories = this.convertToDataCategories(ntaxonomies)
    } else {
      this.dataCategories = this.convertToDataCategories(this.taxonomies)
    }

    if (this.currentState == ViewStates.viewing) {
      return this.dataCategories.deployed.level_1
    }

    if (this.currentState == ViewStates.editing) {
      return this.dataCategories.next.level_1
    }
  }

  getDatapoints() {
    let columns = this.selectedDataCategory.level_3.columns
    if (!_.isEmpty(this.datapointSearchFilter)) {
      const query = this.datapointSearchFilter.toLowerCase()
      columns = columns.filter((column) => {
        return column.getLabel().toLowerCase().includes(query) || column.getDescription().toLowerCase().includes(query)
      })
    }

    // sort columns by ordinal
    columns = _.sortBy(columns, ['ordinal'])
    return columns
  }

  getLevel2DataCategories(level_1) {
    if (this.currentState == ViewStates.viewing) {
      return this.dataCategories.deployed.level_2[level_1.key] ?? []
    }
    if (this.currentState == ViewStates.editing) {
      return this.dataCategories.next.level_2[level_1.key] ?? []
    }
  }

  getLevel3DataCategories(level_2) {
    if (this.currentState == ViewStates.viewing) {
      return this.dataCategories.deployed.level_3[level_2.key]
    }
    if (this.currentState == ViewStates.editing) {
      return this.dataCategories.next.level_3[level_2.key]
    }
  }

  private setupModals() {
    this.modals = {
      [`${ModalNames.categorySelectorModal}`]: {
        template: this.categorySelectorModal,
        class: `modal-lg ${ModalNames.categorySelectorModal}`
      },
      [`${ModalNames.editDatapointModal}`]: {
        template: this.editDatapointModal,
        class: `modal-lg ${ModalNames.editDatapointModal}`
      },
      [`${ModalNames.duplicateCategoryModal}`]: {
        template: this.duplicateCategoryModal,
        class: `modal-lg ${ModalNames.duplicateCategoryModal}`
      },
      [`${ModalNames.editCategoryModal}`]: {
        template: this.editCategoryModal,
        class: `modal-lg ${ModalNames.editCategoryModal}`
      },
      [`${ModalNames.deleteCategoryModal}`]: {
        template: this.deleteCategoryModal,
        class: `modal-md ${ModalNames.deleteCategoryModal}`
      },
      [`${ModalNames.deleteDatapointModal}`]: {
        template: this.deleteDatapointModal,
        class: `modal-md ${ModalNames.deleteDatapointModal}`
      },
      [`${ModalNames.saveAsDraftModal}`]: {
        template: this.saveAsDraftModal,
        class: `modal-md ${ModalNames.saveAsDraftModal}`
      },
      [`${ModalNames.cancelEditingDatacategoriesModal}`]: {
        template: this.cancelEditingDatacategoriesModal,
        class: `modal-md ${ModalNames.cancelEditingDatacategoriesModal}`
      },
      [`${ModalNames.deleteCategoryTranslationsModal}`]: {
        template: this.deleteCategoryTranslationsModal,
        class: `modal-md ${ModalNames.deleteCategoryTranslationsModal}`
      },
      [`${ModalNames.deleteDatapointTranslationsModal}`]: {
        template: this.deleteDatapointTranslationsModal,
        class: `modal-md ${ModalNames.deleteDatapointTranslationsModal}`
      },
      [`${ModalNames.brokenCalculationsModal}`]: {
        template: this.brokenCalculationsModal,
        class: `modal-lg ${ModalNames.brokenCalculationsModal}`
      }
    }
  }

  private openModal(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal
    const customClass = options.class ?? this.modals[modal].class
    const template = this.modals[modal].template
    const ignoreBackdropClick = options.ignoreBackdropClick ?? true
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    })
  }

  closeModal({ modal }) {
    const modalRef = this.modals[modal].ref
    if (modalRef) {
      this.modalService.hide(modalRef.id)
    }
  }

  private convertToDataCategories(taxonomies: { depTaxonomy: TaxonomyInfoFe; newTaxonomy: TaxonomyInfoFe }) {
    const next: any = { level_1: [], level_2: {}, level_3: {} }
    const deployed: any = { level_1: [], level_2: {}, level_3: {} }

    /*
        breakdown into level_1, level_2 and level_3 for easier
        ui management  
    */
    taxonomies.depTaxonomy.entities.forEach((entity) => {
      const tokens = entity.key.split('.')
      const level = tokens.length

      let parent: any = []
      for (let i = 0; i < level - 1; i++) {
        parent.push(tokens[i])
      }
      parent = parent.join('.')

      if (level > 1) {
        deployed[`level_${level}`][parent] = deployed[`level_${level}`][parent] || []
        deployed[`level_${level}`][parent].push(entity)
      } else {
        deployed['level_1'] = deployed['level_1'] || []
        deployed['level_1'].push(entity)
      }
    })

    taxonomies.newTaxonomy.entities.forEach((entity) => {
      const tokens = entity.key.split('.')
      const level = tokens.length

      let parent: any = []
      for (let i = 0; i < level - 1; i++) {
        parent.push(tokens[i])
      }
      parent = parent.join('.')

      if (level > 1) {
        next[`level_${level}`][parent] = next[`level_${level}`][parent] || []
        next[`level_${level}`][parent].push(entity)
      } else {
        next['level_1'] = next['level_1'] || []
        next['level_1'].push(entity)
      }
    })

    return { deployed, next }
  }

  private handleBackClick() {
    if (this.currentPage == PageStates.datapoint) {
      //Check for errors in datapoint
      if (
        (this.selectedDatapoint.providedBy == DatapointProvidedBy.calculation ||
          this.selectedDatapoint.datatype == DatapointDatatype.EMISSION_FACTOR) &&
        this.hasCalculationErrors
      ) {
        this.showCalculationErrors = true
        return
      }

      if (this.selectedDatapoint.datatype == DatapointDatatype.EMISSION_FACTOR && this.hasEmissionFactorErrors()) {
        this.showEmissionFactorErrors = true
        return
      }

      this.currentPage = PageStates.datapoints
      return
    }

    if (this.currentPage == PageStates.datapoints) {
      this.currentPage = PageStates.datacategories
      return
    }

    if (this.currentPage == PageStates.datacategories && this.currentState == ViewStates.editing) {
      this.currentState = ViewStates.viewing
      return
    }
  }

  editDraft() {
    const _currentPage = this.currentPage
    this.currentState = ViewStates.editing

    if (this.selectedDataCategory) {
      const level_1_key = this.selectedDataCategory.level_1.key
      const level_2_key = this.selectedDataCategory.level_2.key
      const level_3_key = this.selectedDataCategory.level_3.key

      const level_1 = _.find(this.taxonomies.newTaxonomy.entities, ['key', level_1_key])
      const level_2 = _.find(this.taxonomies.newTaxonomy.entities, ['key', level_2_key])
      const level_3 = _.find(this.taxonomies.newTaxonomy.entities, ['key', level_3_key])
      this.viewDatapoints({ level_1, level_2, level_3 })
    }

    if (this.selectedDatapoint) {
      const datapoint_key = this.selectedDatapoint.key
      const datapoint = _.find(this.selectedDataCategory.level_3.columns, ['key', datapoint_key])
      if (!_.isEmpty(datapoint)) {
        this.viewDatapoint(datapoint)
        this.selectedUnitContainer.selectedUnit = datapoint.unit
      }
    }

    this.currentPage = _currentPage
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'back':
        return this.handleBackClick()
      case 'edit':
        this.editDraft()
        return
      case 'info':
        return this.displayService.toggleTips()
    }
  }

  viewDatapoints(opts: { level_1: EntityFe; level_2: EntityFe; level_3: EntityFe }) {
    if (!opts.level_3 || opts.level_3.isRemoved) {
      return
    }
    this.currentPage = PageStates.datapoints
    this.selectedDataCategory.level_1 = opts.level_1
    this.selectedDataCategory.level_2 = opts.level_2
    this.selectedDataCategory.level_3 = opts.level_3

    // if all column ordinals are zero, reset ordinals
    let resetOrdinals = false
    let col = _.find(this.selectedDataCategory.level_3.columns, (col) => col.ordinal > 0)
    if (_.isEmpty(col)) {
      resetOrdinals = true
    }

    this.selectedDataCategory.level_3.columns.forEach((col, idx) => {
      // if even one column has no ordinal, reset ordinals
      if (_.isEmpty(col.ordinal)) {
        resetOrdinals = true
      }

      // if ordinal is 0, yet index isn't 0, reset ordinals
      if (col.ordinal == 0 && idx != 0) {
        resetOrdinals = true
      }
    })

    if (resetOrdinals) {
      this.selectedDataCategory.level_3.columns = this.selectedDataCategory.level_3.columns.map((col, idx) => {
        col.ordinal = idx
        return col
      })
    }
  }

  viewDatapoint(datapoint: TaxonomyAttributeFe) {
    this.selectedDatapoint = datapoint
    this.selectedMeasurementType.clear()
    this.selectedMeasurementType.add(datapoint.unit?.measurementType)
    this.selectedUnitContainer.selectedUnit = datapoint.unit
    this.currentPage = PageStates.datapoint
    this.datapointTranslationDuplicateErrorMap.clear()

    this.editingDatapointLabel = this.selectedDatapoint.label[this.pageLanguage.code]
    this.editingDatapointDescription = this.selectedDatapoint.description[this.pageLanguage.code]

    if (this.isEditingDatapointLabel) {
      this.editingDatapointEnLabelDesc = this.selectedDatapoint.label['en']
    } else {
      this.editingDatapointEnLabelDesc = this.selectedDatapoint.description['en']
    }

    this.resetDatapointFormValidators()

    if (datapoint.datatype == DatapointDatatype.EMISSION_FACTOR && datapoint.providedBy == DatapointProvidedBy.user) {
      const emissionFactor = datapoint.emissionFactors[0]
      const ef = emissionFactor.value as AbstractEmissionFactorFe
      if (!_.isEmpty(ef)) {
        const conversionUnit = _.find(this.units, { symbol: ef?.conversionUnit })
        const sourceUnit = _.find(this.units, { symbol: ef?.sourceUnit })
        this.conversionFactorMeasurementTypeInput = conversionUnit.measurementType
        this.sourceMeasurementTypeInput = sourceUnit.measurementType
      }
    }
  }

  onSelectedUnitChanged(evt) {
    const prevUnit = _.cloneDeep(this.selectedDatapoint.unit)
    this.selectedDatapoint.prevUnit = prevUnit
    this.selectedDatapoint.unit = evt
  }

  onCategorySwitched(evt) {
    if (this.currentPage == PageStates.datapoint) {
      this.currentPage = PageStates.datapoints
    }
  }

  changeCategory() {
    this.openModal({
      modal: ModalNames.categorySelectorModal,
      ignoreBackdropClick: false
    })
  }

  closeCategorySelector() {
    this.closeModal({ modal: ModalNames.categorySelectorModal })
  }

  showSearchCategory() {
    if (this.currentState == ViewStates.editing) {
      return false
    }
    return this.currentPage == PageStates.datacategories
  }

  startEditCategory(category) {
    this.editingCategory = category
    this.editingCategoryLabel = this.resolveString(category.label)
    this.isEditingDisplayLangDataCategoryDeleted = false

    this.resetPropValues()

    this.openModal({
      modal: ModalNames.editCategoryModal,
      ignoreBackdropClick: false
    })

    if (
      this.isGlobalTaxonomy() &&
      (this.selectedDataCategory.level_1.isNew ||
        this.selectedDataCategory.level_2.isNew ||
        this.selectedDataCategory.level_3.isNew)
    ) {
      this.applyAllTranslations(true)
    } else {
      this.addCategoryTranslations()
    }
  }

  resetPropValues() {
    this.categoryTranslations = []
    this.toggleTranslationDisabled(true)
    this.showDetailsDataCategoryAccordion = false
  }

  addCategoryTranslations() {
    // check if editingCategory label object keys more than 1
    // add them into categoryTranslations list
    const labelKeys = Object.keys(this.editingCategory.label)
    const hasMultipleLanguages = labelKeys.length > 1

    if (hasMultipleLanguages) {
      labelKeys.forEach((languageCode) => {
        if (languageCode !== this.pageLanguage.code && this.editingCategory.label.hasOwnProperty(languageCode)) {
          // Skip adding English translation if the display language not exists
          if (languageCode === 'en' && !this.editingCategory.label.hasOwnProperty(this.pageLanguage.code)) {
            return
          }

          const languageObj = this.languageService.getLanguageObject(languageCode)
          const text = this.editingCategory.label[languageCode]

          this.categoryTranslations.push({ language: languageObj, text })
        }
      })
      this.toggleTranslationDisabled(false)
    } else {
      // if display language exists in label then add english to translations
      const isDisplayLanguageInLabel = this.pageLanguage && this.editingCategory.label[this.pageLanguage.code]

      if (isDisplayLanguageInLabel && this.pageLanguage.code !== 'en') {
        const languageObj = this.languageService.getLanguageObject('en')
        const text = this.editingCategory.label['en'] || ''
        this.categoryTranslations.push({ language: languageObj, text })
        this.toggleTranslationDisabled(true)
      }
    }
    this.updateAvailableLanguages(true)
  }

  startEditDatapoint(isLabelEdit: boolean) {
    this.isEditingDatapointLabel = isLabelEdit
    this.resetPreviousDatapointEdit()
    if (this.isEditingDatapointLabel) {
      this.editingDatapointEnLabelDesc = this.selectedDatapoint.label['en']
    } else {
      this.editingDatapointEnLabelDesc = this.selectedDatapoint.description['en']
    }

    this.updateDatapointTranslations(this.selectedDatapoint, isLabelEdit)

    this.openModal({
      modal: ModalNames.editDatapointModal,
      ignoreBackdropClick: false
    })
  }

  resetPreviousDatapointEdit() {
    this.datapointTranslationDuplicateErrorMap.clear()
    this.clearDatapointTranslations()
    this.toggleTranslationDisabled(true)
  }

  updateDatapointTranslations(datapoint: any, isLabelEdit: boolean) {
    if (isLabelEdit) {
      Object.entries(datapoint.label).forEach(([k, v]) => {
        if (k !== 'en' && !this.datapointLabelTranslations[k]) {
          this.datapointLabelTranslations.push({
            language: this.languageService.getLanguageObject(k),
            text: v,
            isNew: false
          })
        }
      })

      this.editingDatapointEnLabelDesc = datapoint.label['en']
      this.editingDatapointLabel = datapoint.label[this.pageLanguage.code]
    } else {
      Object.entries(datapoint.description).forEach(([k, v]) => {
        if (k !== 'en' && !this.datapointDescriptionTranslations[k]) {
          this.datapointDescriptionTranslations.push({
            language: this.languageService.getLanguageObject(k),
            text: v,
            isNew: false
          })
        }
      })

      this.editingDatapointEnLabelDesc = datapoint.description['en']
      this.editingDatapointDescription = datapoint.description[this.pageLanguage.code]
    }
  }

  openDuplicateCategoryModal(level3: EntityFe, level2: EntityFe, level1: EntityFe) {
    this.selectedDataCategory.level_1 = level1
    this.selectedDataCategory.level_2 = level2
    this.selectedDataCategory.level_3 = level3
    this.selectedLevel2Categories = new Set()
    this.openModal({
      modal: ModalNames.duplicateCategoryModal,
      ignoreBackdropClick: false
    })
  }

  cancelDuplicateCategory() {
    this.closeModal({ modal: ModalNames.duplicateCategoryModal })
  }

  duplicateCategory() {
    this.selectedLevel2Categories.forEach((parent) => {
      const label = { en: `Copy of ${this.selectedDataCategory.level_3.getLabel()}` }
      let newEntity = this.taxonomies.newTaxonomy.addCustomEntity(label, parent)
      this.selectedDataCategory.level_3.columns.forEach((taxonomyAttr, index) => {
        const attr_buff = {
          key: taxonomyAttr.key,
          label: taxonomyAttr.label,
          datatype: taxonomyAttr.datatype,
          description: taxonomyAttr.description,
          unit: taxonomyAttr.unit,
          providedBy: taxonomyAttr.providedBy,
          calculations: taxonomyAttr.calculations,
          emissionFactors: taxonomyAttr.emissionFactors
        }

        const attr = _.cloneDeep(attr_buff)

        const duplicateAttr = new TaxonomyAttributeFe(attr, this.languageService)
        duplicateAttr.ordinal = index
        duplicateAttr.isNew = true
        newEntity.columns.push(duplicateAttr)
      })

      newEntity.columns.forEach((attr) => {
        let calcs = attr.calculations.map((calc, index) => {
          return cloneCalculation(calc, newEntity.columns)
        })
        attr.calculations = calcs
      })
    })

    this.cancelDuplicateCategory()
  }

  handleDuplicateDataCategorySelection(level_2, event) {
    if (event.target.checked) {
      this.selectedLevel2Categories.add(level_2)
    } else {
      this.selectedLevel2Categories.delete(level_2)
    }
  }

  getDataCategoriesForDuplicateDataCategoryModal(searchKeyword: string = '') {
    const ntaxonomies = this.filterTaxonomies(searchKeyword)
    this.dataCategories = this.convertToDataCategories(ntaxonomies)
    return this.dataCategories.next.level_1
  }

  cancelEditCategory() {
    this.closeModal({ modal: ModalNames.editCategoryModal })
  }

  cancelEditDatapoint() {
    this.isEditingDatapointLabel = undefined
    this.editingDatapointEnLabelDesc = ''
    this.closeModal({ modal: ModalNames.editDatapointModal })
  }

  confirmEditCategory() {
    let labelTranslations = this.categoryTranslations
      .filter((t) => t.text && t.text.trim())
      .reduce((acc, translation) => {
        acc[translation.language.code] = translation.text
        return acc
      }, {})

    // check if display language is english then add english transation
    if (
      this.pageLanguage.code === 'en' ||
      Object.keys(this.editingCategory.label).length == 1 ||
      !this.editingCategory.label.hasOwnProperty(this.pageLanguage.code)
    ) {
      labelTranslations = { en: this.editingCategoryLabel, ...labelTranslations }
    } else if (this.pageLanguage.code !== 'en' && this.editingCategory.label.hasOwnProperty(this.pageLanguage.code)) {
      labelTranslations = { [this.pageLanguage.code]: this.editingCategoryLabel, ...labelTranslations }
    }

    this.editingCategory.label = labelTranslations
    this.editingCategory.labelIsModified()
    this.editingCategory.isEdited = true

    this.closeModal({ modal: ModalNames.editCategoryModal })
    this.clearTranslations(true)
  }

  confirmEditDatapoint() {
    const list = this.getDatapointTranslations()

    const propertyName = this.isEditingDatapointLabel ? 'label' : 'description'

    let translations = list
      .filter((t) => t.text && t.text.trim())
      .reduce((acc, translation) => {
        acc[translation.language.code] = translation.text
        return acc
      }, {})

    translations = { en: this.editingDatapointEnLabelDesc, ...translations }

    try {
      let element = this.renderer.selectRootElement('#datapointEnglishText')

      if (!translations.hasOwnProperty('en') || !translations['en']) {
        if (element) {
          element.focus()
          element.classList.add('focused-input') // Add focused class
        } else {
          element.blur() // Remove focus
          element.classList.remove('focused-input') // Remove focused class
        }
        return
      }
    } catch (error) {}

    this.selectedDatapoint[propertyName] = translations

    this.editingDatapointDescription = this.selectedDatapoint.description[this.pageLanguage.code]
    this.editingDatapointLabel = this.selectedDatapoint.label[this.pageLanguage.code]

    this.isEditingDatapointLabel = undefined

    this.onDatapointEdited({ what: 'translations' })

    this.closeModal({ modal: ModalNames.editDatapointModal })
    this.clearTranslations(false)
  }

  startDeleteCategory(category: EntityFe) {
    /*
      If new just remove it
    */
    if (category.isNew) {
      this.taxonomies.newTaxonomy.removeEntity(category)
      return
    }

    this.deletingCategory = category
    this.openModal({
      modal: ModalNames.deleteCategoryModal,
      ignoreBackdropClick: false
    })
  }

  cancelDeleteCategory() {
    this.closeModal({ modal: ModalNames.deleteCategoryModal })
  }

  cancelCategoryTranslationsModal() {
    this.closeModal({ modal: ModalNames.deleteCategoryTranslationsModal })
  }

  cancelDatapointTranslationsModal() {
    this.closeModal({ modal: ModalNames.deleteDatapointTranslationsModal })
  }

  confirmDeleteCategory() {
    const key = this.deletingCategory.key
    this.taxonomies.newTaxonomy.entities = this.taxonomies.newTaxonomy.entities.map((entity) => {
      if (entity.key == key) {
        entity.isRemoved = true
      }
      return entity
    })
    this.closeModal({ modal: ModalNames.deleteCategoryModal })
  }

  undoDeleteCategory(category) {
    const key = category.key
    this.taxonomies.newTaxonomy.entities = this.taxonomies.newTaxonomy.entities.map((entity) => {
      if (entity.key == key) {
        entity.isRemoved = false
      }
      return entity
    })
  }

  private closeDropdown(category) {
    document.getElementById(`dropdown_menu_${category.key}`).classList.remove('show')
  }

  addControlDropdownOpened(category) {
    document.getElementById(`dropdown_menu_${category.key}`).classList.remove('has_error')
    const container = document.getElementById(`add_custom_category_container_${category.key}`)
    container.classList.remove('adding')
    container.classList.add('viewing')
  }

  addsuggestedLvl1({ suggestedLvl1 }) {
    this.taxonomies.newTaxonomy.addEntity(suggestedLvl1)
    this.closeDropdown({ key: 'add_lvl1' })
  }

  isSuggestedDatapoint: boolean = false

  addSuggestedCategory({ suggestedCategory, parent }) {
    this.isSuggestedDatapoint = true

    const copySuggestCategory = _.cloneDeep(suggestedCategory.toJSON())

    this.taxonomies.newTaxonomy.addEntity(copySuggestCategory)
    this.closeDropdown(parent)
  }

  startAddCustomLvl1Category() {
    document.getElementById(`dropdown_menu_add_lvl1`).classList.remove('has_error')
    const container = document.getElementById(`add_custom_lvl1_category_container`)
    container.classList.remove('viewing')
    container.classList.add('adding')
  }

  startAddCustomCategory(category) {
    document.getElementById(`dropdown_menu_${category.key}`).classList.remove('has_error')
    const container = document.getElementById(`add_custom_category_container_${category.key}`)
    container.classList.remove('viewing')
    container.classList.add('adding')
  }

  addCustomLvl1Category() {
    const newLabel = `${this.addCustomCategoryInput}`

    // Check category name exists
    let labelExists = false
    this.taxonomies.newTaxonomy.entities.forEach((entity) => {
      if (this.resolveString(entity.label).trim().toLowerCase() == newLabel.trim().toLowerCase()) {
        labelExists = true
      }
    })

    if (labelExists) {
      document.getElementById(`dropdown_menu_add_lvl1`).classList.add('has_error')
      return
    }

    const label = { en: newLabel }
    this.taxonomies.newTaxonomy.addCustomEntityByParentKey(label, '')
    this.addCustomCategoryInput = ''
    this.closeDropdown(parent)
  }

  addCustomCategory(parent) {
    const newLabel = `${this.addCustomCategoryInput}`
    // Check category name exists
    let labelExists = false
    this.taxonomies.newTaxonomy.entities.forEach((entity) => {
      if (this.resolveString(entity.label).trim().toLowerCase() == newLabel.trim().toLowerCase()) {
        labelExists = true
      }
    })

    if (labelExists) {
      document.getElementById(`dropdown_menu_${parent.key}`).classList.add('has_error')
      return
    }

    const label = { en: newLabel }
    this.taxonomies.newTaxonomy.addCustomEntity(label, parent)
    this.addCustomCategoryInput = ''
    this.closeDropdown(parent)
  }

  cancelEditingDataCategories() {
    const _currentPage = this.currentPage
    this.currentState = ViewStates.viewing
    this.taxonomies = {
      depTaxonomy: this.staticTaxonomies.depTaxonomy.toJSON(),
      newTaxonomy: this.staticTaxonomies.newTaxonomy.toJSON()
    }
    this.dataCategories = this.convertToDataCategories(this.taxonomies)

    this.viewDeployedDatapoints()

    if (this.selectedDatapoint) {
      const datapoint_key = this.selectedDatapoint.key
      const datapoint = _.find(this.selectedDataCategory.level_3.columns, ['key', datapoint_key])
      if (!_.isEmpty(datapoint)) {
        this.viewDatapoint(datapoint)
      }
      if (_.isEmpty(datapoint)) {
        this.selectedDatapoint = null
      }
    }

    if (!_.isEmpty(this.selectedDatapoint)) {
      this.currentPage = _currentPage
    }
  }

  updateHasCalculationErrors(value: boolean) {
    this.hasCalculationErrors = value
    if (!this.hasCalculationErrors && this.showCalculationErrors) {
      this.showCalculationErrors = false
    }
  }

  // Check for calculations that reference datapoints that no longer exist
  private checkBrokenCalculations() {
    const newTaxonomy = this.taxonomies.newTaxonomy

    newTaxonomy.entities.forEach((entity) => {
      entity.columns.forEach((column) => {
        if (column.providedBy == DatapointProvidedBy.calculation) {
          column.calculations.forEach((calculation) => {
            calculation.formula.forEach((token) => {
              if (token.type == TokenTypes.operand && token.operandType == OperandTypes.dataPoint) {
                const key = token.datapoint.key
                const found = _.find(entity.columns, { key: key })
                if (_.isEmpty(found)) {
                  this.brokenCalculationsDatapoints.push({
                    entity: entity,
                    category: newTaxonomy.categoryFQN(entity.key),
                    calculatingDp: column,
                    referenceDp: token.datapoint
                  })
                }
              }
            })
          })
        }
      })
    })

    return this.brokenCalculationsDatapoints
  }

  async saveDataCategories() {
    this.brokenCalculationsDatapoints = []
    const brokenCalculationsDatapoints = this.checkBrokenCalculations()
    if (!_.isEmpty(brokenCalculationsDatapoints)) {
      this.brokenCalculationsDatapoints = brokenCalculationsDatapoints
      this.openModal({
        modal: ModalNames.brokenCalculationsModal,
        ignoreBackdropClick: false
      })
      return
    }

    if (
      this.selectedDatapoint &&
      this.selectedDatapoint.providedBy == DatapointProvidedBy.calculation &&
      this.hasCalculationErrors
    ) {
      this.showCalculationErrors = true
      return
    }

    if (
      this.selectedDatapoint &&
      this.selectedDatapoint.datatype == DatapointDatatype.EMISSION_FACTOR &&
      this.hasEmissionFactorErrors()
    ) {
      this.showEmissionFactorErrors = true
      return
    }

    try {
      this.savingInProgress = true
      const newTaxonomy = this.taxonomies.newTaxonomy
      // remove deleted categories
      newTaxonomy.entities = newTaxonomy.entities.filter((entity) => !entity.isRemoved)
      // remove deleted datapoints
      newTaxonomy.entities = newTaxonomy.entities.map((entity) => {
        entity.columns = entity.columns.filter((column) => !column.isRemoved)
        return entity
      })

      //re-harmonize formulas for calculated datapoints in the event depended on datapoints were updated
      newTaxonomy.entities = newTaxonomy.entities.map((entity) => {
        entity.columns = entity.columns.map((column) => {
          if (column.providedBy == DatapointProvidedBy.calculation) {
            column.calculations = column.calculations.map((calculation) => {
              try {
                const reHarmonizedFormula = CalculationHarmonization.harmonizeFormula({
                  tokens: calculation.formula,
                  toUnit: column.unit?.symbol,
                  unitEvaluator: this.stateService.getUnitEvaluator(),
                  entity,
                  noUnit: this.unitService.getNoUnit()
                })
                calculation.harmonizedFormula = reHarmonizedFormula
              } catch (err) {
                //TO DO : handle Calculation definition error
                console.log(err)
              }
              return calculation
            })
          }
          return column
        })
        return entity
      })

      const depTaxonomy = this.taxonomies.depTaxonomy
      newTaxonomy.diffDeployment(depTaxonomy)

      await this.stateService.updateNewTaxonomyInfo(newTaxonomy)
      await this.stateService.upgradeTaxonomyInfo()
      await this.loadDataCategories(true)

      const _currentPage = this.currentPage
      this.currentState = ViewStates.viewing

      if (this.selectedDataCategory) {
        const level_1_key = this.selectedDataCategory.level_1.key
        const level_2_key = this.selectedDataCategory.level_2.key
        const level_3_key = this.selectedDataCategory.level_3.key

        const level_1 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_1_key])
        const level_2 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_2_key])
        const level_3 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_3_key])

        this.viewDatapoints({ level_1, level_2, level_3 })
      }

      if (this.selectedDatapoint) {
        const datapoint_key = this.selectedDatapoint.key
        const datapoint = _.find(this.selectedDataCategory.level_3.columns, ['key', datapoint_key])
        if (!_.isEmpty(datapoint)) {
          this.viewDatapoint(datapoint)
        }
        if (_.isEmpty(datapoint)) {
          this.selectedDatapoint = null
        }
      }

      if (!_.isEmpty(this.selectedDatapoint)) {
        this.currentPage = _currentPage
      }

      this.savingInProgress = false
      this.showSaveSuccessfulMsg = true

      setTimeout(() => {
        this.showSaveSuccessfulMsg = false
        this.hasDraft = false
      }, 2000)
    } catch (error) {
      this.savingInProgress = false
      let knownError = this.ErrorsFe.matchError(error.error)
      if (
        knownError == this.ErrorsFe.TAXONOMY_NOT_DEPLOYED_ERROR ||
        knownError == this.ErrorsFe.MASTER_TABLE_NAME_NOT_GENERATED_ERROR ||
        knownError == this.ErrorsFe.MASTER_TABLE_ALREADY_EXISTS
      ) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(this.ErrorsFe.TAXONOMY_NOT_DEPLOYED_ERROR.message)
      }
    }
  }

  openMasterTable() {
    this.datahubService.showEntityMasterTable(this.selectedDataCategory.level_3)
    this.router.navigate([RoutesFe.DATA_HUB.fullPath()])
  }

  startAddDatapoint() {
    this.addDatapointDialog.open()
  }

  startDeleteDatapoint(datapoint: TaxonomyAttributeFe) {
    this.deletingDatapoint = datapoint
    this.openModal({
      modal: ModalNames.deleteDatapointModal,
      ignoreBackdropClick: false
    })
  }

  cancelDeleteDatapoint() {
    this.closeModal({ modal: ModalNames.deleteDatapointModal })
  }

  confirmDeleteDatapoint() {
    this.taxonomies.newTaxonomy.entities = this.taxonomies.newTaxonomy.entities.map((entity) => {
      if (entity.key == this.selectedDataCategory.level_3.key) {
        entity.columns = entity.columns.map((column) => {
          if (column.key == this.deletingDatapoint.key) {
            column.isRemoved = true
          }
          return column
        })
      }
      return entity
    })

    this.closeModal({ modal: ModalNames.deleteDatapointModal })
  }

  undoDeleteDatapoint(datapoint) {
    this.taxonomies.newTaxonomy.entities = this.taxonomies.newTaxonomy.entities.map((entity) => {
      if (entity.key == this.selectedDataCategory.level_3.key) {
        entity.columns = entity.columns.map((column) => {
          if (column.key == datapoint.key) {
            column.isRemoved = false
          }
          return column
        })
      }
      return entity
    })
  }

  onDatapointEdited({ what }) {
    if (what == 'label') {
      if (this.selectedDatapoint.isNew) {
        this.selectedDataCategory.level_3.adjustKey(this.selectedDatapoint)
      }
    }
  }

  onChangeDatapointLabelDescription(isLabel: boolean) {
    if (isLabel) {
      if (!this.isDuplicateDatapointShortName()) {
        this.selectedDatapoint.label[this.pageLanguage.code] = this.editingDatapointLabel?.trim()
      }
    } else {
      this.selectedDatapoint.description[this.pageLanguage.code] = this.editingDatapointDescription?.trim()
    }

    this.selectedDatapoint.isEdited = true
  }

  checkCategoryDuplicateName(control: AbstractControl): ValidationErrors | null {
    const categoryName = control.value
    return this.isDuplicateCategory(categoryName) ? { duplicateCategoryName: true } : null
  }

  checkDatapointDuplicateShortName(control: AbstractControl): ValidationErrors | null {
    const shortName = control.value?.trim()
    return this.checkDuplicateDatapointName(shortName)
  }

  checkDatapointDuplicateEnglishShortName(control: AbstractControl): ValidationErrors | null {
    const shortName = control.value?.trim()
    return this.checkDuplicateDatapointName(shortName, true)
  }

  checkDuplicateDatapointName(shortName: string, isEnglishCheck: boolean = false): ValidationErrors | null {
    if (!shortName || !shortName.trim()) {
      return null
    }
    const count = this.calculateDuplicateShortNameCount(shortName, isEnglishCheck) - 1
    return count >= 1 ? { duplicateDatapointName: true } : null
  }

  calculateDuplicateShortNameCount(shortName: string, isEnglishCheck: boolean): number {
    if (isEnglishCheck) {
      return this.selectedDataCategory.level_3.columns.filter(
        (col) =>
          col.label['en'] === shortName &&
          shortName !== this.editingDatapointEnLabelDesc &&
          shortName !== this.editingDatapointLabel
      ).length
    }
    return this.selectedDataCategory.level_3.columns.filter(
      (col) => col.label[this.pageLanguage.code] === shortName && shortName !== this.editingDatapointLabel
    ).length
  }

  isDuplicateDatapointShortName(): boolean {
    const labelToCheck = this.editingDatapointLabel?.trim()
    if (!labelToCheck) return false

    const count = this.selectedDataCategory.level_3.columns.filter(
      (col) => col.label[this.pageLanguage.code] === labelToCheck
    ).length
    return count >= 2
  }

  checkDatapointTranslationDuplicate(translation: any) {
    const labelToCheck = this.editingDatapointLabel?.trim()
    if (!labelToCheck) {
      this.datapointTranslationDuplicateErrorMap.set(translation.language.code, false)
    }

    const count = this.selectedDataCategory.level_3.columns.filter(
      (col) => col.label[translation.language.code] === translation.text
    ).length

    if (count >= 1) {
      this.datapointTranslationDuplicateErrorMap.set(translation.language.code, true)
    } else {
      this.datapointTranslationDuplicateErrorMap.set(translation.language.code, false)
    }
  }

  get hasDatapointDuplicateError(): boolean {
    return Array.from(this.datapointTranslationDuplicateErrorMap.values()).some((value) => value)
  }

  hasDatapointDuplicateErrorOf(translation: any): boolean {
    return this.datapointTranslationDuplicateErrorMap.get(translation.language.code)
  }

  isDuplicateCategory(categoryName): boolean {
    const depTaxonomy = this.taxonomies?.depTaxonomy

    if (depTaxonomy) {
      return depTaxonomy.entities.some(
        (entity) =>
          entity.key !== this.editingCategory.key &&
          entity.label['en'].toLowerCase() === categoryName.trim().toLowerCase()
      )
    }

    return false
  }

  changeDatapointProvidedBy(providedBy) {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.providedBy = providedBy

    if (providedBy == DatapointProvidedBy.user) {
      this.showCalculationErrors = false
    }

    if (providedBy == DatapointProvidedBy.pre_determined && _.isEmpty(this.selectedDatapoint.emissionFactors[0])) {
      const ef: TaxonomyEmissionFactorFe = {
        isFallback: false,
        value: null
      }
      this.selectedDatapoint.emissionFactors[0] = ef
    }
  }

  moveDatapointUp({ datapointIdx, datapoint }) {
    const prevIdx = datapointIdx - 1
    const prevOrdinal = this.selectedDataCategory.level_3.columns[prevIdx].ordinal
    const currOrdinal = this.selectedDataCategory.level_3.columns[datapointIdx].ordinal
    this.selectedDataCategory.level_3.columns[prevIdx].ordinal = currOrdinal
    this.selectedDataCategory.level_3.columns[datapointIdx].ordinal = prevOrdinal
    this.selectedDataCategory.level_3.columns = _.sortBy(this.selectedDataCategory.level_3.columns, ['ordinal'])
  }

  moveDatapointDown({ datapointIdx, datapoint }) {
    const nextIdx = datapointIdx + 1
    const nextOrdinal = this.selectedDataCategory.level_3.columns[nextIdx].ordinal
    const currOrdinal = this.selectedDataCategory.level_3.columns[datapointIdx].ordinal
    this.selectedDataCategory.level_3.columns[nextIdx].ordinal = currOrdinal
    this.selectedDataCategory.level_3.columns[datapointIdx].ordinal = nextOrdinal
    this.selectedDataCategory.level_3.columns = _.sortBy(this.selectedDataCategory.level_3.columns, ['ordinal'])
  }

  startCancelEditingDataCategories() {
    this.openModal({
      modal: ModalNames.cancelEditingDatacategoriesModal,
      ignoreBackdropClick: false
    })
  }

  exitCancelEditingDatacategories() {
    this.closeModal({ modal: ModalNames.cancelEditingDatacategoriesModal })
  }

  confirmCancelEditingDatacategories() {
    const _currentPage = this.currentPage
    this.currentState = ViewStates.viewing
    this.taxonomies = {
      depTaxonomy: this.staticTaxonomies.depTaxonomy,
      newTaxonomy: this.staticTaxonomies.newTaxonomy
    }
    this.dataCategories = this.convertToDataCategories(this.taxonomies)

    this.viewDeployedDatapoints()

    if (this.selectedDatapoint) {
      const datapoint_key = this.selectedDatapoint.key
      const datapoint = _.find(this.selectedDataCategory.level_3.columns, ['key', datapoint_key])
      if (!_.isEmpty(datapoint)) {
        this.viewDatapoint(datapoint)
      }
      if (_.isEmpty(datapoint)) {
        this.selectedDatapoint = null
      }
    }

    if (!_.isEmpty(this.selectedDatapoint)) {
      this.currentPage = _currentPage
    }

    this.closeModal({ modal: ModalNames.cancelEditingDatacategoriesModal })
  }

  startSaveAsDraft() {
    this.openModal({
      modal: ModalNames.saveAsDraftModal,
      ignoreBackdropClick: false
    })
  }

  cancelSaveAsDraft() {
    this.closeModal({ modal: ModalNames.saveAsDraftModal })
  }

  async confirmSaveAsDraft() {
    if (this.hasCalculationErrors) {
      this.showCalculationErrors = true
      return
    }
    try {
      this.savingInProgress = true
      const newTaxonomy = this.taxonomies.newTaxonomy
      const depTaxonomy = this.taxonomies.depTaxonomy
      newTaxonomy.diffDeployment(depTaxonomy)
      await this.stateService.updateNewTaxonomyInfo(newTaxonomy)
      this.savingInProgress = false
    } catch (error) {
      this.savingInProgress = false
      let knownError = this.ErrorsFe.matchError(error.error)
      if (knownError == this.ErrorsFe.TAXONOMY_NOT_SAVED_ERROR) {
        this.alertService.showError(knownError.message)
      } else {
        this.alertService.showError(this.ErrorsFe.TAXONOMY_NOT_SAVED_ERROR.message)
      }
    }
    this.closeModal({ modal: ModalNames.saveAsDraftModal })
    this.checkForDraft()
    this.viewDeployedDatapoints()
    this.currentState = ViewStates.viewing
  }

  viewDeployedDatapoints() {
    if (this.selectedDataCategory) {
      const level_1_key = this.selectedDataCategory.level_1.key
      const level_2_key = this.selectedDataCategory.level_2.key
      const level_3_key = this.selectedDataCategory.level_3.key

      const level_1 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_1_key])
      const level_2 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_2_key])
      const level_3 = _.find(this.taxonomies.depTaxonomy.entities, ['key', level_3_key])

      this.viewDatapoints({ level_1, level_2, level_3 })
    }
  }

  getSymbol(unit: UnitFe) {
    if (_.isEmpty(unit)) return ''

    let symbol = unit.symbol
    if (!_.isEmpty(unit.aliases)) {
      symbol = unit.aliases
    }

    return symbol
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  addDatapointTranslation() {
    const list = this.getDatapointTranslations()
    this.updateAvailableLanguages(false, 'en')

    const defaultLanguage = this.availableLanguages[0]

    if (this.isEditingDatapointLabel) {
      list.push({
        language: defaultLanguage,
        text: this.selectedDatapoint.label[defaultLanguage.code] || '',
        isNew: true
      })
    } else {
      list.push({
        language: defaultLanguage,
        text: this.selectedDatapoint.description[defaultLanguage.code] || '',
        isNew: true
      })
    }

    this.toggleTranslationDisabled(false)
  }

  deleteDatapointTranslation(index: number) {
    let list = this.getDatapointTranslations()

    if (this.isEditingDatapointLabel) {
      const langCode = list[index].language.code
      if (this.selectedDatapoint.label.hasOwnProperty(langCode)) {
        delete this.selectedDatapoint.label[langCode]
      }
      if (this.datapointTranslationDuplicateErrorMap.has(langCode)) {
        this.datapointTranslationDuplicateErrorMap.delete(langCode)
      }
    } else {
      const languageCode = list[index].language.code
      if (this.selectedDatapoint.description.hasOwnProperty(languageCode)) {
        delete this.selectedDatapoint.description[languageCode]
      }
    }

    if (index >= 0 && index < list.length) {
      list.splice(index, 1)
    }

    this.updateAvailableLanguages(false, 'en')
    if (list.length == 0) {
      this.toggleTranslationDisabled(true)
    }
  }

  getDatapointTranslations(): any {
    if (this.isEditingDatapointLabel) {
      return this.datapointLabelTranslations
    }

    return this.datapointDescriptionTranslations
  }

  clearDatapointTranslations() {
    if (this.isEditingDatapointLabel) {
      this.datapointLabelTranslations = []
    } else {
      this.datapointDescriptionTranslations = []
    }
  }
  getDatapointLabelLocale() {
    return this.getNumberOfTranslationLocale(this.selectedDatapoint.label)
  }

  getDatapointDescriptionLocale() {
    return this.getNumberOfTranslationLocale(this.selectedDatapoint.description)
  }

  getNumberOfTranslationLocale(list: any) {
    const translationsCount = this.calculateTranslationsCount(list)
    return translationsCount > 0
      ? this.locale('locale_key.pages.data_category.no_translations_added.dynamic', { count: translationsCount })
      : this.locale('locale_key.pages.data_category.no_translations_added')
  }

  calculateTranslationsCount(list: any): number {
    if (!list || Object.keys(list).length - 1 <= 0) {
      return 0
    }
    let translationsCount = Object.values(list).filter((value: any) => value && value.trim()).length
    if (list[this.pageLanguage.code]) {
      translationsCount--
    }
    return translationsCount
  }

  showConfirmationModal() {
    this.openModal({
      modal: ModalNames.deleteCategoryTranslationsModal,
      ignoreBackdropClick: false
    })
  }

  showDpConfirmationModal() {
    this.openModal({
      modal: ModalNames.deleteDatapointTranslationsModal,
      ignoreBackdropClick: false
    })
  }

  toggleTranslationDisabled(val: boolean) {
    this.isDisabledTranslation = val
  }

  isDpRemoveAllTranslationDisabled() {
    if (this.isEditingDatapointLabel) {
      return Object.keys(this.selectedDatapoint.label).length - 1 <= 0
    } else {
      return Object.keys(this.selectedDatapoint.description).length - 1 <= 0
    }
  }

  isGlobalTaxonomy() {
    if (this.editingCategory && this.editingCategory.key) {
      return this.globalTaxonomy.entities.some((e) => e.key === this.editingCategory.key)
    }
    return false
  }

  isGlobalAttributeTaxonomy() {
    const gTaxnomoy = this.globalTaxonomy.entities.find((e) => {
      return e.key === this.selectedDataCategory.level_3.key
    })

    if (gTaxnomoy) {
      const suggestedDp = gTaxnomoy.attributes.find((attr) => {
        return attr.key === this.selectedDatapoint.key
      })

      return suggestedDp && Object.keys(suggestedDp.label).length > 1 && Object.keys(suggestedDp.description).length > 1
    }
    return false
  }

  showSuggestedTranslationAlert() {
    return this.categoryTranslations.length == 1
  }

  undoTranslationCategory(translation) {
    const gTaxnomoy = this.globalTaxonomy.entities.find((e) => e.key === this.editingCategory.key)
    translation.text = gTaxnomoy.label[translation.language.code] || gTaxnomoy.label['en']
  }

  disabledUndoTranslation(translation) {
    return translation.text
  }

  getEditCategoryModalIcon() {
    return this.dcIcons[this.editingCategory.key.split('.')[0]]
  }

  getEditCategoryModalTitle(): string {
    const splits = this.editingCategory.key.split('.')
    if (splits.length == 1) {
      return ''
    }

    const cats = this.getDataCategories()
    let level_1: any
    let level_2: any

    cats.some((l1: any) => {
      const level2Categories = this.getLevel2DataCategories(l1)
      level_2 = level2Categories.find((l2) => l2.key === this.editingCategory.key)

      if (!level_2) {
        level2Categories.some((l2) => {
          if (level_2) return false

          const level3Categories = this.getLevel3DataCategories(l2)
          const foundL3 = level3Categories?.find((l3) => l3.key === this.editingCategory.key)

          if (foundL3) {
            level_2 = l2
            return true
          }
          return false
        })
      }

      if (level_2) {
        level_1 = l1
        return true
      }
      return false
    })

    if (level_2) {
      return this.resolveString(level_1.label) + ' / ' + this.resolveString(level_2.label) + ' / '
    }

    return ''
  }

  getEditCategoryModalTitleLastString(): string {
    const splits = this.editingCategory.getLabel()?.split('.')
    const lastSegment = splits[splits.length - 1].replace(/_/g, ' ')
    const cSplits = lastSegment.split(' ')
    return cSplits.map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
  }

  getConfirmationModalBodyText(isDataCategory = true) {
    const cmp = isDataCategory ? this.isGlobalTaxonomy() : this.isGlobalAttributeTaxonomy()
    return cmp
      ? this.locale('locale_key.pages.data_categories.remove_all_translation.body.message')
      : this.locale('locale_key.pages.data_categories.remove_all_translation.non_strandart.body.message')
  }

  isDataContentEmpty(content, languageCode): boolean {
    const text = content?.[languageCode] || ''
    return !text || !text.trim()
  }

  isDpDescriptionEnglishEmpty(): boolean {
    return this.isDataContentEmpty(this.selectedDatapoint?.description, 'en')
  }

  isDpLabelEnglishEmpty(): boolean {
    return this.isDataContentEmpty(this.selectedDatapoint?.label, 'en')
  }

  isDpDescriptionDislayLangEmpty(): boolean {
    return this.isDataContentEmpty(this.selectedDatapoint?.description, this.pageLanguage.code)
  }

  isDpLabelDisplayLangEmpty(): boolean {
    return this.isDataContentEmpty(this.selectedDatapoint?.label, this.pageLanguage.code)
  }

  isActionDatapointDisabled() {
    if (this.currentState != ViewStates.editing) return false
    return (
      (this.currentPage == PageStates.datapoint &&
        (this.isDpLabelEnglishEmpty() || this.isDpDescriptionEnglishEmpty() || this.isDuplicateDatapointShortName())) ||
      (this.pageLanguage.code === 'en' && this.datapointForm2.get('shortNamePageLangText').errors?.pattern)
    )
  }

  isDCatContainsDisplayLang(dataCategory: any): boolean {
    return this.pageLanguage?.code && dataCategory.label[this.pageLanguage.code]
  }

  isEditingCategoryEnglish() {
    const isEnglish = this.pageLanguage?.code === 'en'
    const isLabelMissing = !this.editingCategory.label?.hasOwnProperty(this.pageLanguage?.code)

    return isEnglish || this.isEditingDisplayLangDataCategoryDeleted || isLabelMissing
  }

  disabledUndoDataCategoryTranslation() {
    return this.editingCategoryLabel
  }

  undoDataCategoryTranslation() {
    const gTaxnomoy = this.globalTaxonomy.entities.find((e) => e.key === this.editingCategory.key)
    this.editingCategoryLabel = gTaxnomoy.label[this.pageLanguage.code]
  }

  removeDataCategoryTranslation() {
    if (this.editingCategory.label.hasOwnProperty(this.pageLanguage.code)) {
      delete this.editingCategory.label[this.pageLanguage.code]
    }
    this.isEditingDisplayLangDataCategoryDeleted = true
  }

  disableCategoryConfirm() {
    return this.categoryFormGroup.invalid || (this.categoryFormGroup2.dirty && this.categoryFormGroup2.invalid)
  }

  isBackButtonDisabled() {
    return (
      (this.currentState === ViewStates.editing &&
        this.selectedDatapoint &&
        (this.isDpLabelEnglishEmpty() || this.isDpDescriptionEnglishEmpty() || this.isDuplicateDatapointShortName())) ||
      (this.pageLanguage.code === 'en' && this.datapointForm2?.get('shortNamePageLangText').errors?.pattern)
    )
  }

  getMeasurementTypeLimits() {
    const limits = new Set()

    //if not deployed, do not limit measurement type
    if (!this.selectedDatapoint.deployed) {
      return limits
    }

    // It's deployed
    // If current unit is no unit and previous unit is no unit, do not limit
    if (
      _.isEmpty(this.selectedDatapoint?.unit?.symbol) &&
      (_.isEmpty(this.selectedDatapoint.prevUnit) || _.isEmpty(this.selectedDatapoint.prevUnit.symbol))
    ) {
      return limits
    }

    // we have to limit
    // If current unit is no unit, limit by previous unit else limit by current unit
    if (_.isEmpty(this.selectedDatapoint?.unit?.symbol)) {
      limits.add(this.selectedDatapoint?.prevUnit?.measurementType)
    } else {
      limits.add(this.selectedDatapoint?.unit?.measurementType)
    }

    return limits
  }

  resetDatapointFormValidators() {
    this.datapointForm1.reset()
    this.datapointForm2.reset()
  }

  getMeasurementTypes() {
    let finalMeasurementTypes = this.filteredMeasurementTypes
    // filter out measurement types without default unit symbol
    finalMeasurementTypes = finalMeasurementTypes.filter(
      (measurementType) => !_.isEmpty(measurementType.defaultUnitSymbol)
    )
    // map unit name
    finalMeasurementTypes = finalMeasurementTypes.map((measurementType) => {
      const unit = _.find(this.units, { symbol: measurementType.defaultUnitSymbol })
      if (unit) {
        measurementType.defaultUnitName = unit.name
      }
      return measurementType
    })
    return finalMeasurementTypes
  }

  updateMeasurementTypeInput() {
    if (
      this.selectedDatapoint.datatype == DatapointDatatype.EMISSION_FACTOR &&
      this.selectedDatapoint.providedBy == DatapointProvidedBy.user
    ) {
      // create example emission factor
      const sourceMeasurementType = _.find(this.measurementTypes, { key: this.sourceMeasurementTypeInput })
      const sourceUnit = _.find(this.units, { symbol: sourceMeasurementType.defaultUnitSymbol })

      const conversionMeasurementType = _.find(this.measurementTypes, {
        key: this.conversionFactorMeasurementTypeInput
      })
      const conversionUnit = _.find(this.units, { symbol: conversionMeasurementType.defaultUnitSymbol })

      const emissionFactor = new CustomEmissionFactorFe(
        IdUtil.next(),
        '',
        sourceUnit?.name ?? '',
        sourceUnit?.symbol ?? '',
        1,
        conversionUnit?.symbol ?? '',
        'SYSTEM_EXAMPLE',
        {}
      )
      const ef: TaxonomyEmissionFactorFe = {
        isFallback: false,
        value: emissionFactor
      }

      this.selectedDatapoint.emissionFactors[0] = ef
    }
  }

  hasEmissionFactorErrors() {
    this.showEmissionFactorErrors = false
    let hasError = false
    if (this.selectedDatapoint.datatype == DatapointDatatype.EMISSION_FACTOR) {
      if (_.isEmpty(this.selectedDatapoint.emissionFactors)) {
        hasError = true
        this.emissionFactorErrorMsgs.add(this.locale('locale_key.pages.emission.emission_factor_required'))
      }

      const emptyEmissionFactorIdxs = []
      this.selectedDatapoint.emissionFactors.forEach((emissionFactor, idx) => {
        if (_.isEmpty(emissionFactor.value)) {
          emptyEmissionFactorIdxs.push(idx)
        }
      })

      if (emptyEmissionFactorIdxs.length > 0) {
        hasError = true
        this.emissionFactorErrorMsgs.add(this.locale('locale_key.pages.emission.emission_factor_required'))
      }
    }
    return hasError
  }

  resolveLabel({ token, deployed }) {
    let datapoint = token.datapoint

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    }

    const langCode = this.languageService.getDisplayActiveLanguage()

    if (datapoint && datapoint.label) {
      const label = datapoint.label[langCode] || datapoint.label['en']
      if (label) {
        return label
      }
    }

    return datapoint?.label['en']
  }

  resolveUnit({ token, deployed }) {
    let datapoint = token.datapoint

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    }

    let unit = ''
    if (datapoint.datatype == DatapointDatatype.EMISSION_FACTOR) {
      const ef = (datapoint.emissionFactors[0] || { value: datapoint.emissionFactor } || { value: {} })
        .value as AbstractEmissionFactorFe

      let sourceUnit = `${ef?.sourceUnit}`
      if (sourceUnit.includes('/')) {
        sourceUnit = `(${sourceUnit})`
      }

      let conversionUnit = `${ef?.conversionUnit}`
      if (conversionUnit.includes('/')) {
        conversionUnit = `(${conversionUnit})`
      }

      unit = `${conversionUnit}/${sourceUnit}`
    }

    if (datapoint.datatype != DatapointDatatype.EMISSION_FACTOR) {
      let symbol = `${datapoint?.unit?.symbol ?? ''}`
      if (symbol.includes('/')) {
        symbol = `(${symbol})`
      }
      unit = symbol
    }

    return unit
  }

  hasUnit({ datapoint }: { datapoint: TaxonomyAttributeFe }) {
    const datatypesWithNoUnit = [DatapointDatatype.STRING, DatapointDatatype.DATE, DatapointDatatype.BOOLEAN]

    if (datatypesWithNoUnit.includes(DatapointDatatype[datapoint.datatype])) {
      return false
    }

    const deployed = this.currentState === 'viewing'
    const unit = this.resolveUnit({ token: { datapoint }, deployed })
    return !_.isEmpty(unit)
  }

  viewBrokenCalculationsDatapoint(entity: EntityFe, datapoint: TaxonomyAttributeFe) {
    const level_3 = entity
    const level_2 = this.taxonomies.newTaxonomy.parentEntity(level_3.key)
    const level_1 = this.taxonomies.newTaxonomy.parentEntity(level_2.key)
    this.viewDatapoints({ level_1, level_2, level_3 })
    this.viewDatapoint(datapoint)
  }
}
