import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'

@Component({
  selector: 'app-reset-request',
  templateUrl: './reset-request.component.html',
  styleUrls: ['./reset-request.component.scss']
})
export class ResetRequestComponent extends AbstractLanguageComponent implements OnInit {
  resetPasswordForm: FormGroup
  forbiddenEmails: any
  errorMessage: string
  successMessage: string
  IsvalidForm = true
  currentPassword = ''
  newPassword = ''
  confirmPassword = ''

  submitted = false
  public formOpen: boolean = false
  message: string
  invalidLogin: boolean
  constructor(
    private formBuilder: FormBuilder,
    private backendService: RouterFe,
    private router: Router,
    private loginService: LoginServiceFe,
    private alertService: AlertServiceFe,
    private ErrorsFe: ErrorsFe,
    languageService: LanguageService
  ) {
    super(languageService)
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

  get formFields() {
    return this.resetPasswordForm.controls
  }

  async onSubmit() {
    this.submitted = true
    if (this.resetPasswordForm.invalid) {
      return
    }
    try {
      let changed = await this.backendService.changePassword(this.resetPasswordForm.value)
      if (changed) {
        this.invalidLogin = true
        this.alertService.showSuccess(`Your password was successfully changed!`)
        this.loginService.logout()
      }
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (
        knownError == OpenErrorsFe.INCORRECT_PASS_ERROR ||
        knownError == OpenErrorsFe.UNMATCHED_PASSES_ERROR ||
        knownError == OpenErrorsFe.USER_NOT_FOUND_ERROR
      ) {
        this.alertService.showError(knownError.message)
      }
    }
  }

  public displayForm(): void {
    this.formOpen = !this.formOpen
  }
}
