<h3 *ngIf="showHeader">{{ locale('locale_key.pages.data_request.create.questions') }}</h3>
<div class="accordion mt-2" id="sectionParent">
  <div class="accordion-item" *ngFor="let section of sections; let sectionIndex = index">
    <h2 class="accordion-header">
      <button
        class="accordion-button px-0 pe-3 bg-white"
        type="button"
        data-bs-toggle="collapse"
        attr.data-bs-target="#sectionAccordion{{ sectionIndex }}"
        aria-expanded="true"
        attr.aria-controls="sectionAccordion{{ sectionIndex }}"
        id="section{{ sectionIndex }}"
      >
        <div class="d-flex flex-row my-auto p-2">
          <span class="me-2">
            <h5 class="my-auto ms-2">
              {{ locale('locale_key.wizard.create_questionnaire.section.title', { sectionNumber: sectionIndex + 1 }) }}
            </h5>
          </span>
          <span class="badge badge-info w-auto py-auto my-auto" *ngIf="section.entity && taxonomyInfo">
            {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}
          </span>
          <span class="badge badge-info w-auto py-auto my-auto custom-line-spacing" *ngIf="section.name">
            {{ section.name }}
          </span>
          <span (click)="stopImmediatePropagation($event)">
            <div class="btn-group" (click)="stopImmediatePropagation($event)">
              <div
                class="description-btn d-flex justify-content-end remove-arrow-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="la la-ellipsis-v size-15 mt-auto my-auto" (click)="stopImmediatePropagation($event)"></i>
              </div>
              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                <button class="dropdown-item ms-0 ps-1" type="button" (click)="section.editTaxonomy = true">
                  <i class="las la-edit mr-25" style="position: relative; top: 1px"></i>
                  {{ locale('locale_key.general.button.edit') }}
                </button>
                <button class="dropdown-item ms-0 ps-1" type="button" (click)="duplicateSection(section, $event)">
                  <i class="las la-copy mr-25" style="position: relative; top: 1px"></i>
                  {{ locale('locale_key.general.buttons.duplicate') }}
                </button>
                <button class="dropdown-item ms-0 ps-1" type="button" (click)="deleteSection(section, $event)">
                  <i class="la la-trash-o"></i>
                  {{ locale('locale_key.pages.kpi_library.delete_kpi_modal.button.delete') }}
                </button>
              </div>
            </div>
          </span>
        </div>
      </button>
    </h2>
    <div
      id="sectionAccordion{{ sectionIndex }}"
      class="accordion-collapse collapse show"
      data-bs-parent="#sectionParent"
    >
      <div
        class="accordion-body"
        [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'pe-0' : 'px-0'"
        style="max-width: 100% !important"
      >
        <div class="d-flex w-100 flex-column scroll">
          <div class="row-design mx-2 border p-2" *ngIf="section.editTaxonomy">
            <h6>
              {{ locale('locale_key.wizard.create_questionnaire.store_answers_question') }}
            </h6>
            <div class="d-flex flex-row px-1 mt-1">
              <button
                class="btn tertiary-button custom-button me-2"
                (click)="section.initializeTaxonomy()"
                [ngClass]="{ 'btn-pink': section.isTaxonomyConnected }"
              >
                {{ locale('locale_key.general.buttons.yes') }}
              </button>
              <button
                class="btn tertiary-button custom-button"
                (click)="section.intializeNameControl()"
                [ngClass]="{ 'btn-pink': !section.isTaxonomyConnected }"
              >
                {{ locale('locale_key.general.buttons.no') }}
              </button>
            </div>
            <ng-container *ngIf="section.isTaxonomyConnected">
              <p class="pt-3 pb-0 mb-0">
                {{ locale('locale_key.wizard.create_questionnaire.related_category_question') }}
              </p>
              <div class="w-100" [ngClass]="{ 'pe-3': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                <category-selection
                  [entityKey]="section.taxonomyKey"
                  [editedTaxonomy]="true"
                  (entitySelected)="section.setTaxonomy($event, taxonomyInfo)"
                ></category-selection>
              </div>
              <div class="d-flex">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#addCategory"
                  (click)="openModal(addDataCategory, 'modal-md')"
                >
                  <span class="bold mx-1 size-15" style="position: relative; top: 2px">+</span>
                  {{ locale('locale_key.wizard.create_questionnaire.add_new_category') }}
                </a>
              </div>
              <div class="d-flex text-end">
                <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.editTaxonomy = false">
                  {{ locale('locale_key.general.buttons.confirm') }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="!section.isTaxonomyConnected">
              <p class="pt-3 pb-0 mb-0">{{ locale('locale_key.wizard.create_questionnaire.section_name') }}</p>
              <input
                class="form-control w-50"
                type="text"
                [formControl]="section.nameControl"
                [ngClass]="{ 'is-invalid': section.nameControl.errors && section.nameControl.touched }"
              />
              <div *ngIf="section.nameControl.errors && section.nameControl.touched" class="invalid-feedback">
                <div *ngIf="section.nameControl.errors.required">
                  {{ locale('locale_key.general.validation_message.mandtory') }}
                </div>
              </div>
              <div class="d-flex text-end">
                <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.confirmSectionName()">
                  {{ locale('locale_key.general.buttons.confirm') }}
                </button>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="section.taxonomyKey || section.name || section.name == ''">
            <div
              class="m-2"
              *ngFor="let question of filteredQuestions(section); let i = index"
              [id]="'question' + question.id"
            >
              <label>
                {{ getQuestionTitleNumber(question, i) }}
                <span
                  class="ms-3"
                  *ngIf="
                    section.taxonomyKey &&
                    question.mappingConfirmedOnce &&
                    !question.editMapping &&
                    !requestService.isAttachmentQues(question)
                  "
                >
                  <ng-container *ngIf="question.isMapped && question.mappedToCols">
                    <span class="text-muted small me-1">
                      {{ locale('locale_key.wizard.create_questionnaire.data_added_to') }}
                    </span>
                    <span class="badge badge-info w-auto py-auto my-auto me-1 text-wrap mb-2 custom-line-spacing">
                      {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}
                    </span>
                    <span class="badge badge-info w-auto py-auto my-auto me-1 text-wrap mb-2 custom-line-spacing">
                      <ng-container *ngFor="let mappedToCol of question.mappedToCols; let firstCol = first">
                        <span *ngIf="!firstCol">/</span>
                        {{ mappedToCol?.getLabel() }}
                      </ng-container>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="question.isMapped && question.range && question.mappedToColsRange.length > 0">
                    <span class="badge badge-info w-auto py-auto my-auto text-wrap mb-2 custom-line-spacing">
                      <ng-container *ngFor="let mappedToCol of question.mappedToColsRange; let firstCol = first">
                        <span *ngIf="!firstCol">/</span>
                        {{ mappedToCol?.getLabel() }}
                      </ng-container>
                    </span>
                  </ng-container>
                  <span class="text-muted small" *ngIf="section.taxonomyKey && !question.isMapped">
                    {{ locale('locale_key.wizard.create_questionnaire.not_added_to_data_categories') }}
                  </span>
                  <a
                    (click)="syncDataFromObserverToTable(i);question.setEditMapping()"
                    *ngIf="requestService.isDataTableQues(question) || section.taxonomyKey"
                  >
                    <i class="la la-edit size-15 my-auto" style="position: relative; top: 2px"></i>
                  </a>
                </span>
              </label>
              <ng-container *ngIf="question.editMapping">
                <div
                  class="row-design border p-2"
                  *ngIf="section.taxonomyKey && !requestService.isDataTableQues(question)"
                >
                  <h6>
                    {{ locale('locale_key.wizard.create_questionnaire.which_data_points_question') }}
                    <span class="badge badge-info w-auto py-auto my-auto">
                      {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}
                    </span>
                    {{ locale('locale_key.wizard.create_questionnaire.collect_with_this_question') }}
                  </h6>
                  <div class="d-flex flex-row mt-1">
                    <div class="flex-grow-1 d-flex">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="!question.isMapped"
                          (click)="requestService.toggleQuesMapping($event, question)"
                          id="sec{{ sectionIndex }}ques{{ i }}"
                          style="position: relative; top: 1px"
                        />
                        <label class="form-check-label" for="sec{{ sectionIndex }}ques{{ i }}">
                          {{ locale('locale_key.wizard.create_questionnaire.exclude_from_mapping') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2" *ngIf="question.isMapped">
                    <div *ngIf="question.range">
                      {{ locale('locale_key.wizard.create_questionnaire.map_first_data_input') }}
                    </div>
                    <ng-container *ngFor="let c of requestService.findColumnsByType(question, section)">
                      <div
                        class="card columnCard me-3 mb-2"
                        [ngClass]="{
                          colSelected: question.isFieldSelected(c),
                          disabled: question.range && question.isRangeFieldSelected(c)
                        }"
                        *ngIf="!requestService.isNumberQues(question) && !requestService.isEmissionFactorQues(question)"
                        (click)="
                          question.range
                            ? !question.isRangeFieldSelected(c) && question.toggleField(c)
                            : question.toggleField(c)
                        "
                      >
                        <div class="row px-3">
                          <span class="p-0 col-10 label" title="{{ c.getLabel() }}">{{ c.getLabel() }}</span>
                          <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                            <i class="la la-check fieldSelected"></i>
                          </span>
                        </div>
                        <div class="desc" title="{{ c.getDescription() }}">{{ c.getDescription() }}</div>
                        <span>
                          {{ locale('locale_key.pages.kpi_library.data_type') }}:
                          <b>{{ getDatatype(c.datatype) | titlecase }}</b>
                        </span>
                        <div class="newTag" *ngIf="c.isNew">
                          {{ locale('locale_key.wizard.create_questionnaire.new') }}
                        </div>
                      </div>
                      <div
                        class="card columnCard me-3 mb-2"
                        [ngClass]="{
                          colSelected: question.isFieldSelected(c),
                          disabled: !sameMeasurementType(c, question)
                        }"
                        *ngIf="requestService.isNumberQues(question) || requestService.isEmissionFactorQues(question)"
                        (click)="sameMeasurementType(c, question) ? question.toggleField(c) : null"
                      >
                        <div class="row px-3">
                          <span
                            class="p-0 col-10 label"
                            triggers="focus"
                            title="{{
                              sameMeasurementType(c, question)
                                ? c.getLabel()
                                : 'The unit of this data point does not match the one of the already selected data point, so they can not be chosen together.'
                            }}"
                          >
                            {{ c.getLabel() }}
                          </span>
                          <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                            <i class="la la-check fieldSelected"></i>
                          </span>
                        </div>
                        <div
                          class="desc"
                          title="{{
                            sameMeasurementType(c, question)
                              ? c.getDescription()
                              : 'The unit of this data point does not match the one of the already selected data point, so they can not be chosen together.'
                          }}"
                        >
                          >{{ c.getDescription() }}
                        </div>
                        <span>
                          {{ locale('locale_key.pages.kpi_library.data_type') }}:
                          <b>{{ getDatatype(c.datatype) | titlecase }}</b>
                        </span>
                        <div class="newTag" *ngIf="c.isNew">
                          {{ locale('locale_key.wizard.create_questionnaire.new') }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="question.range">
                      <div>{{ locale('locale_key.wizard.create_questionnaire.map_second_data_input') }}</div>
                      <div
                        class="card columnCard me-3 mb-2"
                        *ngFor="let c of requestService.findColumnsByType(question, section)"
                        [ngClass]="{
                          colSelected: question.isRangeFieldSelected(c),
                          disabled: question.isFieldSelected(c)
                        }"
                        (click)="!question.isFieldSelected(c) && question.toggleRangeField(c)"
                      >
                        <div class="row px-3">
                          <span
                            class="p-0 col-10 label"
                            title="{{ c.getLabel() }}"
                            placement="right"
                            container="body"
                            delay="500"
                          >
                            {{ c.getLabel() }}
                          </span>
                          <span class="col-2 p-0" *ngIf="question.isRangeFieldSelected(c)">
                            <i class="la la-check fieldSelected"></i>
                          </span>
                        </div>
                        <div
                          class="desc"
                          title="{{ c.getDescription() }}"
                          placement="right"
                          container="body"
                          delay="500"
                        >
                          {{ c.getDescription() }}
                        </div>
                        <span>
                          {{ locale('locale_key.pages.kpi_library.data_type') }}:
                          <b>{{ getDatatype(c.datatype) | titlecase }}</b>
                        </span>
                        <div class="newTag" *ngIf="c.isNew">
                          {{ locale('locale_key.wizard.create_questionnaire.new') }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="d-flex">
                    <a (click)="selectedEntityKey = section.taxonomyKey; openModal(addDataPoint, 'modal-lg')">
                      <span class="bold mx-1 size-15" style="position: relative; top: 2px">+</span>
                      {{ locale('locale_key.general.button.add_new_data_point') }}
                    </a>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn primary-button custom-button me-2"
                      [disabled]="
                        question.isMapped
                          ? question.mappedToColKeys.length == 0 &&
                            (question.range ? question.mappedToColKeysRange.length == 0 : true)
                          : false
                      "
                      (click)="question.confirmMapping()"
                    >
                      {{ locale('locale_key.general.buttons.confirm') }}
                    </button>
                  </div>
                </div>
                <div class="row-design border p-2" *ngIf="requestService.isDataTableQues(question)">
                  <h6 *ngIf="section.taxonomyKey">
                    {{ locale('locale_key.wizard.create_questionnaire.which_data_points_question') }}
                    <span class="badge badge-info w-auto py-auto my-auto">
                      {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}
                    </span>
                    {{ locale('locale_key.wizard.create_questionnaire.collect_with_this_question') }}
                  </h6>
                  <div class="d-flex flex-row mt-1" *ngIf="section.taxonomyKey">
                    <div class="flex-grow-1 d-flex">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="!question.isMapped"
                          (click)="requestService.toggleQuesMapping($event, question)"
                          id="sec{{ sectionIndex }}ques{{ i }}"
                          style="position: relative; top: 1px"
                        />
                        <label class="form-check-label" for="sec{{ sectionIndex }}ques{{ i }}">
                          {{ locale('locale_key.wizard.create_questionnaire.exclude_from_mapping') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-7 me-0 pe-1">
                      <div class="col-12 px-1" *ngIf="!question.isMapped">
                        <p class="pt-3">{{ locale('locale_key.wizard.create_questionnaire.create_columns') }}</p>
                        <div class="d-flex flex-row justify-content-center btn-add text-white align-items-center py-1">
                          <div class="col-3">
                            <p class="m-0 text-white">{{ locale('locale_key.master_table.data_point.short_name') }}</p>
                          </div>
                          <div class="col-4">
                            <p class="m-0 text-white">{{ locale('locale_key.master_table.data_point.desc') }}</p>
                          </div>
                          <div class="col-2">
                            <p class="m-0 text-white">{{ locale('locale_key.master_table.data_point.type') }}</p>
                          </div>
                          <div class="col-2">
                            <p class="m-0 text-white">{{ locale('locale_key.pages.data_category.unit') }}</p>
                          </div>
                        </div>
                        <div style="max-height: 300px; overflow-y: auto">
                          <ng-container *ngFor="let field of question.customFields; let i = index">
                            <div class="d-flex flex-row justify-content-center border-shade mb-1 align-items-center">
                              <div class="col-3 px-1">
                                <input
                                  type="text"
                                  class="form-control ps-0"
                                  [(ngModel)]="field.label"
                                  (change)="question.setRequestDataGridService()"
                                  placeholder="{{ locale('locale_key.master_table.data_point.short_name') }}"
                                  required
                                  style="border: none"
                                />
                              </div>
                              <div class="col-4 px-1">
                                <input
                                  type="text"
                                  class="form-control ps-0"
                                  [(ngModel)]="field.description"
                                  placeholder="{{ locale('locale_key.master_table.data_point.desc') }}"
                                  required
                                  style="border: none"
                                />
                              </div>
                              <div class="col-2 px-1">
                                <select
                                  class="form-control p-0"
                                  [(ngModel)]="field.datatype"
                                  required
                                  style="border: none"
                                >
                                  <option value="STRING">{{ locale('locale_key.general.data_type.text') }}</option>
                                  <option value="NUMERIC">{{ locale('locale_key.general.data_type.number') }}</option>
                                  <option value="DATE">{{ locale('locale_key.general.data_type.date') }}</option>
                                  <option value="BOOLEAN">{{ locale('locale_key.general.data_type.boolean') }}</option>
                                </select>
                              </div>
                              <div class="col-2 px-1">
                                <unit-selector
                                  [mode]="'select'"
                                  [selectBy]="'symbol'"
                                  (selectedUnitChanged)="field.setUnit($event)"
                                  [source]="'add_datapoint_modal'"
                                  [selectedUnitSymbol]="field.unitSymbol"
                                  *ngIf="field.datatype == 'NUMERIC'"
                                  [includeMeasurementTypes]="getMeasurementType(question)"
                                  [customUnits]="getCustomUnits()"
                                  [unitsByMeasurementType]="getUnitsByMeasurementType()"
                                ></unit-selector>
                                <span *ngIf="field.datatype != 'NUMERIC'">--</span>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="text-center" style="max-width: auto">
                          <button
                            class="btn btn-light my-3 w-100 me-0 pe-0"
                            style="background-color: var(--bs-btn-hover-bg)"
                            (click)="requestService.addDataTableField(question)"
                          >
                            <i class="las la-plus"></i>
                            {{ locale('locale_key.pages.data_request.create_question.table.button.add_field') }}
                          </button>
                        </div>
                      </div>
                      <div class="col-12 px-1" *ngIf="question.isMapped">
                        <div
                          class="card columnCard me-3 mb-2"
                          *ngFor="let c of requestService.findColumnsByType(question, section)"
                          [ngClass]="{ colSelected: question.isFieldSelected(c) }"
                          (click)="question.toggleField(c, question)"
                        >
                          <div class="row px-3">
                            <span
                              class="p-0 col-10 label"
                              title="{{ c.getLabel() }}"
                              placement="right"
                              container="body"
                              delay="500"
                            >
                              {{ c.getLabel() }}
                            </span>
                            <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                              <i class="la la-check fieldSelected"></i>
                            </span>
                          </div>
                          <div
                            class="desc"
                            title="{{ c.getDescription() }}"
                            placement="right"
                            container="body"
                            delay="500"
                          >
                            {{ c.getDescription() }}
                          </div>
                          <span>
                            {{ locale('locale_key.pages.kpi_library.data_type') }}:
                            <b>{{ getDatatype(c.datatype) | titlecase }}</b>
                          </span>
                          <div class="newTag" *ngIf="c.isNew">
                            {{ locale('locale_key.wizard.create_questionnaire.new') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 ms-0 ps-1">
                      <div class="row my-5 px-0 mx-0">
                        <div class="col-1 mx-0 px-0 m-auto">
                          <span class="mx-0 px-0"><i class="la la-caret-right"></i></span>
                        </div>
                        <div class="col-11 mx-0 px-0">
                          <datagrid-table-editor
                            [dataGrid]="requestItemDataGridServices[i]"
                          ></datagrid-table-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex" *ngIf="section.taxonomyKey">
                    <a (click)="selectedEntityKey = section.taxonomyKey; openModal(addDataPoint, 'modal-lg')">
                      <span class="bold mx-1 size-15" style="position: relative; top: 2px">+</span>
                      {{ locale('locale_key.general.button.add_new_data_point') }}
                    </a>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn primary-button custom-button me-2"
                      [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 : false"
                      (click)="syncDataFromObserverToTable(i);question.confirmMapping()"
                    >
                      {{ locale('locale_key.general.buttons.confirm') }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <div
                class="d-flex flex-lg-row flex-column mt-3 scroll scroll-container"
                *ngIf="
                  requestService.isDataTableQues(question) || section.taxonomyKey ? question.mappingConfirmedOnce : true
                "
                (click)="enableEditMode(question, section)"
                [ngClass]="{ viewMode: !question.isEditMode }"
                style="max-width: 87vw !important"
              >
                <div class="col-md-12 pe-0 mb-3 col-lg-8">
                  <div class="row my-1" *ngIf="question.isFollowUpQues">
                    <div class="col-6 pe-0">
                      <label>{{ locale('locale_key.pages.data_request.create_question.follow_up_question') }}</label>
                      <select class="form-control mb-1" type="text" [(ngModel)]="question.parentQuestionId" disabled>
                        <option>
                          {{ locale('locale_key.pages.data_request.create_question.follow_up_question') }}
                        </option>
                        <ng-container *ngFor="let q of section.questions; let quesIndex = index">
                          <option [value]="q.id" *ngIf="quesIndex != i">{{ q.question }}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-6">
                      <label>{{ locale('locale_key.pages.data_request.create.follow_up_condition.title') }}</label>
                      <form>
                        <div class="form-group">
                          <ng-select
                            *ngIf="isParentMultipleChoiceQues(question.parentQuestionId, section)"
                            [items]="getParentQuesOptions(question.parentQuestionId, section)"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            placeholder="{{
                              locale('locale_key.pages.data_request.create.follow_up_condition.placeholder')
                            }}"
                            (change)="handleCondition($event, question)"
                            [(ngModel)]="question.followUpOptions"
                            [ngModelOptions]="{ standalone: true }"
                            [compareWith]="compareFn"
                          >
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline">
                                <input
                                  id="{{ item.id }}"
                                  type="checkbox"
                                  [ngModel]="item$.selected"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                                {{ item.value }}
                              </div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items">
                              <div class="ng-value1" *ngFor="let item of items | slice: 0 : 1">
                                {{ item.value }}
                              </div>
                              <div class="ng-value1" *ngIf="items.length > 1">
                                <span class="ng-value-label1">+{{ items.length - 1 }} more...</span>
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <div class="ng-value1">
                                {{ item.name }}
                              </div>
                            </ng-template>
                          </ng-select>

                          <ng-select
                            *ngIf="!isParentMultipleChoiceQues(question.parentQuestionId, section)"
                            [items]="getParentQuesOptions(question.parentQuestionId, section)"
                            placeholder="{{
                              locale('locale_key.pages.data_request.create.follow_up_condition.placeholder')
                            }}"
                            (change)="handleCondition($event, question)"
                            [(ngModel)]="question.followUpOptions"
                            [ngModelOptions]="{ standalone: true }"
                            [compareWith]="compareFn"
                          >
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <div class="d-inline">
                                <input
                                  id="{{ item.id }}"
                                  type="checkbox"
                                  [ngModel]="item$.selected"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                                {{ item.value }}
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <div class="ps-2">
                                {{ item.value }}
                              </div>
                            </ng-template>
                          </ng-select>
                          <div *ngIf="!hasCondition(question)" class="invalid-feedback d-block">
                            {{ followUpConditionMessage }}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="mb-3">
                    <input
                      class="form-control w-50"
                      type="text"
                      [formControl]="question.titleControl"
                      [(ngModel)]="question.question"
                      placeholder="{{ locale('locale_key.pages.data_request.create.question.placeholder') }}"
                      [ngClass]="{ 'is-invalid': question.titleControl.errors && question.titleControl.touched }"
                    />
                    <div *ngIf="question.titleControl.errors && question.titleControl.touched" class="invalid-feedback">
                      <div *ngIf="question.titleControl.errors.required">
                        {{ questionMessage }}
                      </div>
                    </div>
                  </div>
                  <textarea
                    class="form-control w-50"
                    style="min-height: 50px"
                    [disabled]="section.taxonomyKey ? question.editMapping : false"
                    [(ngModel)]="question.desc"
                    placeholder="{{ locale('locale_key.pages.data_request.create.question.description.placeholder') }}"
                    (keyup)="autoGrowTextZone($event)"
                  ></textarea>
                  <div id="question" *ngIf="requestService.isSingleChoiceQues(question)">
                    <div class="col-6 p-0">
                      <div class="row pt-2" *ngFor="let option of question.options; let optionIndex = index">
                        <div class="col-1">
                          <label class="my-auto mx-auto">
                            <input class="" type="radio" disabled style="position: relative; top: 5px; left: 5px" />
                          </label>
                        </div>
                        <div class="col-10">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="{{
                              locale('locale_key.pages.data_request.option.placeholder', {
                                option_number: optionIndex + 1
                              })
                            }}"
                            [(ngModel)]="option.value"
                            [formControl]="option.valueControl"
                            [ngClass]="{ 'is-invalid': option.valueControl.errors && option.valueControl.touched }"
                          />
                          <div
                            *ngIf="option.valueControl.errors && option.valueControl.touched"
                            class="invalid-feedback"
                          >
                            <div *ngIf="option.valueControl.errors.required">
                              {{ choiceTitleMessage }}
                            </div>
                          </div>
                          <div class="cursor" *ngIf="!option.showDesc" (click)="option.showDesc = true">
                            +
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.question.option.add_description.title'
                              )
                            }}
                          </div>
                        </div>
                        <div class="col-1">
                          <button
                            class="description-btn"
                            (click)="deleteOption(question.options, optionIndex)"
                            style="position: relative; top: 5px; right: 3px"
                          >
                            <i class="la la-trash cursor"></i>
                          </button>
                        </div>
                        <div class="row w-100 mx-0 px-0" *ngIf="option.showDesc">
                          <div class="col-1"></div>
                          <div class="col-1">
                            <span><i class="ft-corner-down-right ms-4"></i></span>
                          </div>
                          <div class="col-9">
                            <textarea
                              class="form-control my-2"
                              style="min-height: 50px"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create.question.description.placeholder')
                              }}"
                              [(ngModel)]="option.desc"
                              [formControl]="option.descControl"
                              [ngClass]="{ 'is-invalid': option.descControl.errors && option.descControl.touched }"
                              (keyup)="autoGrowTextZone($event)"
                            ></textarea>
                            <div
                              *ngIf="option.descControl.errors && option.descControl.touched"
                              class="invalid-feedback"
                            >
                              <div *ngIf="option.descControl.errors.required">
                                {{ choiceDescMessage }}
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            <button
                              id="delDesc-1"
                              class="description-btn mt-2 me-4"
                              (click)="deleteDescription(option)"
                            >
                              <i class="la la-trash cursor"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      class="description-btn ms-4"
                      (click)="addOption(question.options)"
                      id="addOption-1"
                      value="+ {{ locale('locale_key.pages.data_request.create_wizard.question.add_option.title') }}"
                    />
                  </div>
                  <div id="question" class="mt-3" *ngIf="requestService.isMultipleChoiceQues(question)">
                    <div class="col-6 p-0">
                      <div
                        class="row pt-1 mb-2"
                        id="option-1"
                        *ngFor="let option of question.options; let optionIndex = index"
                      >
                        <div class="col-1">
                          <label class="my-auto mx-auto">
                            <input class="" type="checkbox" disabled style="position: relative; top: 5px; left: 5px" />
                          </label>
                        </div>
                        <div class="col-10">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="{{
                              locale('locale_key.pages.data_request.option.placeholder', {
                                option_number: optionIndex + 1
                              })
                            }}"
                            [(ngModel)]="option.value"
                            [formControl]="option.valueControl"
                            [ngClass]="{ 'is-invalid': option.valueControl.errors && option.valueControl.touched }"
                          />
                          <div
                            *ngIf="option.valueControl.errors && option.valueControl.touched"
                            class="invalid-feedback"
                          >
                            <div *ngIf="option.valueControl.errors.required">
                              {{ choiceTitleMessage }}
                            </div>
                          </div>
                          <div class="cursor" *ngIf="!option.showDesc" (click)="option.showDesc = true">
                            + {{ locale('locale_key.general.buttons.add_description') }}
                          </div>
                        </div>
                        <div class="col-1">
                          <button
                            class="description-btn"
                            (click)="deleteOption(question.options, optionIndex)"
                            style="position: relative; top: 5px; right: 3px"
                          >
                            <i class="la la-trash cursor"></i>
                          </button>
                        </div>
                        <div class="row w-100 mx-0 px-0 mt-2" *ngIf="option.showDesc">
                          <div class="col-1"></div>
                          <div class="col-1">
                            <span><i class="ft-corner-down-right ms-4"></i></span>
                          </div>
                          <div class="col-9">
                            <textarea
                              class="form-control"
                              style="min-height: 50px"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create.question.description.placeholder')
                              }}"
                              [(ngModel)]="option.desc"
                              [formControl]="option.descControl"
                              [ngClass]="{ 'is-invalid': option.descControl.errors && option.descControl.touched }"
                              (keyup)="autoGrowTextZone($event)"
                            ></textarea>
                            <div
                              *ngIf="option.descControl.errors && option.descControl.touched"
                              class="invalid-feedback"
                            >
                              <div *ngIf="option.descControl.errors.required">
                                {{ choiceDescMessage }}
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            <button
                              id="delDesc-1"
                              class="description-btn mt-2 me-4"
                              (click)="deleteDescription(option)"
                            >
                              <i class="la la-trash cursor"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      class="description-btn ms-4"
                      (click)="addOption(question.options)"
                      id="addOption-1"
                      value="+ {{ locale('locale_key.pages.data_request.create_wizard.question.add_option.title') }}"
                    />
                  </div>
                  <div
                    *ngIf="
                      (requestService.isMultipleChoiceQues(question) || requestService.isSingleChoiceQues(question)) &&
                      question.options.length < 2
                    "
                    class="invalid-feedback"
                    style="display: block"
                  >
                    {{ choiceLengthMessage }}
                  </div>
                  <div id="question" *ngIf="requestService.isTextQues(question)">
                    <div class="d-flex flex-row mt-3 me-0">
                      <div class="w-50 ms-0 me-4">
                        <textarea
                          class="form-control me-3"
                          style="min-height: 50px"
                          disabled
                          placeholder="{{
                            locale('locale_key.pages.data_request.create.question.type.text.comments.placeholder')
                          }}"
                          (keyup)="autoGrowTextZone($event)"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div id="question" *ngIf="requestService.isEmissionFactorQues(question)" class="mt-3">
                    <div class="col-6 d-flex flex-row">
                      <div *ngIf="!containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
                        <emission-factor [displayStyle]="'SOURCE_UNIT'" [isDisabled]="true"></emission-factor>
                      </div>
                      <div *ngIf="containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
                        <div class="form-group position-relative ms-0 m-0 p-0">
                          <div class="dropdown w-57">
                            <button
                              class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-auto-close="outside"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-display="static"
                            >
                              {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
                            </button>
                            <ul class="dropdown-menu wider-dropdown-menu bg-light" aria-labelledby="dropdownMenuButton">
                              <li *ngFor="let option of question.predefinedOptions; let i = index">
                                <div class="dropdown-item user-select-none text-wrap">
                                  <span class="user-select-none">
                                    <i class="la la-fire-alt"></i>
                                    <span>
                                      {{
                                        locale('locale_key.pages.data_request.emissionfactor_display_value', {
                                          conversionFactor: option.selectedEmissionFactor?.conversionFactor,
                                          conversionUnit: option.selectedEmissionFactor?.conversionUnit,
                                          sourceName: option.selectedEmissionFactor?.sourceName
                                        })
                                      }}
                                    </span>
                                  </span>
                                  <span class="float-end ms-2">
                                    <i
                                      class="la la-info-circle info-popover-arrow"
                                      type="button"
                                      (click)="openEfDetailTab(option.selectedEmissionFactor)"
                                    ></i>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div
                                  class="dropdown-item user-select-none"
                                  (click)="handlePrefineModal(question, emissionFactorQuestionOption)"
                                >
                                  <i class="icon las la-plus fs-5 p-2"></i>
                                  <span class="add_label">
                                    {{ locale('locale_key.general.select_different_option') }}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="btn-group col-1">
                        <i
                          class="sidebar-item-icon las la-cog size-15 mt-auto my-auto"
                          (click)="handlePrefineModal(question, emissionFactorQuestionOption)"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div id="question" *ngIf="requestService.isNumberQues(question)" class="mt-3">
                    <div class="col-6 d-flex flex-row align-items-center">
                      <div *ngIf="!containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
                        <div class="d-flex align-items-center justify-content-between w-100">
                          <div class="flex-grow-1 me-2 pt-0 w-50">
                            <input
                              class="form-control rounded-0 h-40"
                              type="text"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create.question.value.placeholder')
                              }}"
                              disabled
                            />
                          </div>
                          <div class="flex-grow-1 pt-0 h-40 w-50">
                            <div class="dropdown w-100">
                              <button
                                class="form-control disabled rounded-0 d-flex align-items-center justify-content-between h-40 w-100"
                                type="button"
                                id="dropdownMenuButton"
                                aria-expanded="false"
                                disabled
                              >
                                <span>{{ locale('locale_key.pages.unit-selector.select_unit') }}</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="12"
                                  viewBox="0 0 11 12"
                                  fill="none"
                                  class="ms-2"
                                >
                                  <path
                                    d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                                    stroke="#6B6E80"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>

                          <div
                            *ngIf="question.isUnitRequiredFromSM() && !question.unit && isQuestionnaireInvalid"
                            class="invalid-feedback show"
                          >
                            {{ unitMessage }}
                          </div>
                        </div>
                      </div>

                      <div *ngIf="containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
                        <div class="d-flex flex-row align-items-center p-0">
                          <div
                            *ngIf="question.predefinedOptions[0].value == null"
                            class="d-flex align-items-center justify-content-between"
                          >
                            <input
                              type="text"
                              class="form-control mb-1 rounded-0 me-2 flex-grow-1 h-40"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create.question.value.placeholder')
                              }}"
                              disabled
                            />
                            <input
                              type="text"
                              class="form-control mb-1 rounded-0 me-2 flex-grow-1 h-40"
                              value="{{ getUnit(question.predefinedOptions[0]) }}"
                              disabled
                            />
                          </div>

                          <ng-container
                            *ngIf="containsPredefinedOptions(question) && question.predefinedOptions[0].value != null"
                          >
                            <div class="form-group position-relative ms-0 m-0 p-0">
                              <div class="dropdown">
                                <button
                                  class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-auto-close="outside"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-bs-display="static"
                                >
                                  {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
                                </button>
                                <ul
                                  class="dropdown-menu wider-dropdown-menu bg-light"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <li *ngFor="let option of getSortedPredfinedNumberOptions(question); let i = index">
                                    <span class="dropdown-item">{{ option.value }} {{ getUnit(option) }}</span>
                                  </li>
                                  <li>
                                    <div
                                      *ngIf="!isCustomOptionAdded"
                                      class="dropdown-item user-select-none"
                                      (click)="isCustomOptionAdded = true"
                                    >
                                      <i class="icon las la-plus fs-5 p-2"></i>
                                      <span class="add_label">
                                        {{ locale('locale_key.general.add_custom_option') }}
                                      </span>
                                    </div>
                                    <div
                                      *ngIf="isCustomOptionAdded"
                                      class="dropdown-item d-flex align-items-center user-select-none"
                                    >
                                      <input
                                        type="number"
                                        placeholder="{{
                                          locale(
                                            'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.enter_value.placeholder'
                                          )
                                        }}"
                                        #customPredefineAnswerNumber
                                        class="form-control input-m me-2 h-40"
                                        style="width: 40%"
                                      />
                                      <unit-selector
                                        [mode]="'select'"
                                        [selectBy]="'symbol'"
                                        (selectedUnitChanged)="setUnitForPredefinedAnswer(question, $event)"
                                        [source]="'add_datapoint_modal'"
                                        [selectedUnitSymbol]="question.unit"
                                        [includeMeasurementTypes]="getMeasurementType(question)"
                                        [selectedUnitContainer]="selectedUnitContainer"
                                        [customUnits]="getCustomUnits()"
                                        [unitsByMeasurementType]="getUnitsByMeasurementType()"
                                        [skipInitNoUnit]="skipInitNoUnit(question)"
                                        style="width: 40%"
                                        class="me-2 h-40"
                                      ></unit-selector>
                                      <div
                                        class="user-select-none d-flex align-items-center ms-auto"
                                        (click)="addCustomPredefineNumberOption(question, customPredefineAnswerNumber)"
                                        style="width: auto; font-size: 0.85em"
                                      >
                                        <i class="icon las la-plus fs-5"></i>
                                        <span class="add_label ms-2">
                                          {{ locale('locale_key.general.buttons.add') }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>

                      <div class="btn-group col-1">
                        <i
                          class="sidebar-item-icon las la-cog size-15"
                          style="cursor: pointer"
                          (click)="handlePrefineModal(question, numberQuestionOption)"
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div id="question" *ngIf="requestService.isDateQues(question)">
                    <div class="col-6 p-0">
                      <div class="row mt-3">
                        <div class="col-12 row p-0">
                          <div class="row ps-4 mt-1 p-0">
                            <div class="d-flex flex-wrap align-items-center">
                              <fieldset
                                *ngIf="!containsPredefinedOptions(question)"
                                class="form-group position-relative mb-2"
                              >
                                <input
                                  type="date"
                                  class="form-control form-control-sm rounded-0"
                                  id="specific"
                                  disabled
                                />
                                <div class="position-absolute top-50 translate-middle-y" style="left: 8rem">
                                  <i class="la la-calendar font-medium-4"></i>
                                </div>
                              </fieldset>

                              <ng-container *ngIf="question.range && !containsPredefinedOptions(question)">
                                <span class="mx-2 mb-2">
                                  {{ locale('locale_key.pages.data_request.create_question.date.options.range.to') }}
                                </span>
                                <fieldset class="form-group position-relative me-2 mb-2" style="max-width: 140px">
                                  <input
                                    type="date"
                                    class="form-control form-control-sm rounded-0"
                                    id="range"
                                    disabled
                                  />
                                  <div class="position-absolute top-50 translate-middle-y" style="left: 8rem">
                                    <i class="la la-calendar font-medium-4"></i>
                                  </div>
                                </fieldset>
                              </ng-container>

                              <div class="d-flex align-items-center mb-2">
                                <ng-container *ngIf="containsPredefinedOptions(question)" class="form-group me-2">
                                  <div class="dropdown">
                                    <button
                                      class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-bs-auto-close="outside"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-display="static"
                                    >
                                      {{
                                        locale('locale_key.pages.data_request.create.follow_up_condition.placeholder')
                                      }}
                                    </button>
                                    <ul
                                      class="dropdown-menu bg-light"
                                      aria-labelledby="dropdownMenuButton"
                                      [ngClass]="question.range ? 'wider-dropdown-menu' : 'wide-dropdown-menu'"
                                    >
                                      <li *ngFor="let option of getSortedPredfinedDateOptions(question); let i = index">
                                        <span *ngIf="!question.range" class="dropdown-item">
                                          {{ option.from | date: 'dd MMM, yyyy' }}
                                        </span>
                                        <span *ngIf="question.range" class="dropdown-item">
                                          {{ option.from | date: 'dd MMM, yyyy' }}
                                          {{ locale('locale_key.general.email.to') }}
                                          {{ option.to | date: 'dd MMM, yyyy' }}
                                        </span>
                                      </li>
                                      <li>
                                        <div
                                          *ngIf="!isCustomOptionAdded"
                                          class="dropdown-item user-select-none"
                                          (click)="isCustomOptionAdded = true"
                                        >
                                          <i class="icon las la-plus fs-5 p-2"></i>
                                          <span class="add_label">
                                            {{ locale('locale_key.general.add_custom_option') }}
                                          </span>
                                        </div>
                                        <div
                                          *ngIf="isCustomOptionAdded"
                                          class="dropdown-item d-flex align-items-center"
                                        >
                                          <input
                                            type="date"
                                            #customPredefineAnswerDateFrom
                                            class="form-control input-m"
                                            placeholder="dd MMM, yyyy"
                                            [ngClass]="{
                                              'is-invalid': isInvalidDate(
                                                customPredefineAnswerDateFrom,
                                                customPredefineAnswerDateTo
                                              )
                                            }"
                                          />
                                          <span *ngIf="question.range" class="px-3">
                                            {{ locale('locale_key.general.email.to') }}
                                          </span>
                                          <input
                                            type="date"
                                            #customPredefineAnswerDateTo
                                            class="form-control input-m"
                                            [ngClass]="{
                                              hidden: !question.range,
                                              'is-invalid': isInvalidDate(
                                                customPredefineAnswerDateFrom,
                                                customPredefineAnswerDateTo
                                              )
                                            }"
                                            placeholder="dd MMM, yyyy"
                                            [attr.min]="getMinDate(customPredefineAnswerDateFrom)"
                                          />
                                          <div
                                            class="user-select-none d-flex align-items-center ms-auto"
                                            (click)="
                                              addCustomPredefinedDateOption(
                                                question,
                                                question.range,
                                                customPredefineAnswerDateFrom,
                                                question.range ? customPredefineAnswerDateTo : undefined
                                              )
                                            "
                                          >
                                            <i class="icon las la-plus fs-5"></i>
                                            <span class="add_label ms-2">
                                              {{ locale('locale_key.general.toolbar.button.add') }}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </ng-container>

                                <div class="btn-group ms-1">
                                  <i
                                    class="sidebar-item-icon las la-cog size-15 align-self-center"
                                    (click)="
                                      handlePrefineModal(
                                        question,
                                        question.range ? dateRangeQuestionOption : dateQuestionOption
                                      )
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="question" *ngIf="requestService.isAttachmentQues(question)">
                    <div class="d-flex flex-row mt-1">
                      <button class="btn btn-light custom-button text-white mt-2" id="select-file">
                        {{ locale('locale_key.pages.data_request.create_question.file.button.select_file') }}
                      </button>
                    </div>
                  </div>
                  <!-- <div id="question" *ngIf="requestService.isDataTableQues(question)">
                    <div class="row mt-1" style="position: relative; left: 10px">
                      <datagrid-table-editor *ngIf="!question.editMapping" [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
                    </div>
                  </div> -->
                  <div class="mt-2" id="comments" *ngIf="question.comments">
                    <label class="text-muted">{{ locale('locale_key.pages.data_request.comments.placeholder') }}</label>
                    <textarea
                      class="form-control w-50"
                      style="min-height: 100px"
                      placeholder="{{ locale('locale_key.pages.data_request.create.question.comments.placeholder') }}"
                      disabled
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12 px-2 scroll-y text-start ms-0 ps-0 col-lg-4" *ngIf="question.isEditMode">
                  <div class="d-flex justify-content-start align-items-start">
                    <div class="d-flex flex-column bg-comp-grey p-3 thin-border rounded text-nowrap ms-lg-2">
                      <h5 class="ms-auto">{{ slocale('Settings') }}</h5>
                      <div class="form-group text-end col mt-3">
                        <label class="text-end me-2">
                          {{ locale('locale_key.pages.data_request.create_question.button.mandatory') }}
                        </label>
                        <label class="switch text-end">
                          <input type="checkbox" [(ngModel)]="question.required" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="form-group text-end col">
                        <label class="text-end me-2">
                          {{ locale('locale_key.pages.data_request.create_question.button.space_for_comments') }}
                        </label>
                        <label class="switch text-end">
                          <input type="checkbox" [(ngModel)]="question.comments" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="form-group text-end col" *ngIf="isCSRDProjectPage">
                        <label class="text-end me-2">{{ slocale('Additional explanation ') }}</label>
                        <i
                          class="la la-info-circle dropdown-toggle info-popover-arrow fs-5"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale('Description will be added here.') }}</p>
                        </div>
                        <label class="switch text-end">
                          <input type="checkbox" [(ngModel)]="question.comments" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex flex-column ms-2 me-auto">
                      <div class="side-menu text-end ms-auto" style="overflow-y: hidden">
                        <div class="mx-auto">
                          <button id="btn-arrange" type="button" class="btn first" (click)="showMenu('btn-arrange')">
                            <i class="las la-bars mx-auto"></i>
                            <span class="mx-auto">{{ locale('locale_key.general.toolbar.button.arrange') }}</span>
                          </button>
                          <div
                            id="menu-arrange"
                            class="hide me-auto text-start dropdown-menu"
                            style="
                              position: absolute;
                              right: 0px;
                              width: 400px !important;
                              z-index: 1000 !important;
                              height: 100px;
                            "
                          >
                            <ng-select
                              *ngIf="!question.isFollowUpQues"
                              style="height: 2rem !important"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create_wizard.question.arrange.desc')
                              }}"
                              [(ngModel)]="selectedQuestion"
                              [allowClear]="true"
                              (ngModelChange)="reorderParentQuestions(i, section)"
                            >
                              <ng-container *ngFor="let q of section.questions; let i = index">
                                <ng-option *ngIf="!q.isFollowUpQues && q != question" [value]="q.question">
                                  {{ i + 1 + '. ' }}{{ q.question }}
                                </ng-option>
                              </ng-container>
                            </ng-select>
                            <ng-select
                              *ngIf="question.isFollowUpQues"
                              placeholder="{{
                                locale('locale_key.pages.data_request.create_wizard.question.arrange.desc')
                              }}"
                              [(ngModel)]="selectedChildQuestion"
                              [allowClear]="true"
                              (ngModelChange)="reorderChildQuestions(i, section)"
                            >
                              <ng-container *ngFor="let q of children; let i = index">
                                <ng-option
                                  *ngIf="q != question"
                                  [value]="q.question"
                                  data-bs-toggle="tooltip"
                                  data-placement="bottom"
                                  title="{{ i + 1 + '. ' + q.question }}"
                                >
                                  {{ i + 1 + '. ' }}{{ q.question }}
                                </ng-option>
                              </ng-container>
                            </ng-select>
                          </div>
                        </div>
                        <div class="mx-auto">
                          <button
                            type="button"
                            class="btn btn-float first"
                            (click)="duplicateQues(question, i, section)"
                          >
                            <i class="la la-copy mx-auto"></i>
                            <span class="me-auto" style="position: relative; left: -5px">
                              {{ locale('locale_key.general.toolbar.button.duplicate') }}
                            </span>
                          </button>
                        </div>
                        <div class="mx-auto">
                          <button
                            id="tips"
                            type="button"
                            class="btn btn-float last"
                            (click)="openDeleteQuesModal(i, section, confirmDeleteQues)"
                          >
                            <i class="la la-trash cursor mx-auto"></i>
                            <span class="mx-auto">{{ locale('locale_key.general.toolbar.button.delete') }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mb-1 ms-lg-2"
                    *ngIf="requestService.isSingleChoiceQues(question) || requestService.isMultipleChoiceQues(question)"
                  >
                    <button
                      id="addFollowUpQuesMenu{{ sectionIndex }}"
                      class="btn btn-light btn-block w-100 mt-1 ms-auto"
                      data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#addFollowUpQuesMenu' + sectionIndex + '-follow'"
                      aria-expanded="false"
                      [attr.aria-controls]="'addFollowUpQuesMenu' + sectionIndex + '-follow'"
                      style="max-width: 265px"
                    >
                      <i class="las la-plus"></i>
                      {{ locale('locale_key.pages.data_request.create_question.button.add_follow_up') }}
                    </button>

                    <!-- <button id="addFollowUpQuesMenu{{sectionIndex}}" class="btn btn-light btn-block w-100 mt-1 dropdown ms-auto" (click)="showMenu('addFollowUpQuesMenu{{sectionIndex}}-follow')">
                      <i class="las la-plus"></i> {{ locale("locale_key.pages.data_request.create_question.button.add_follow_up") }}
                    </button> -->
                    <div
                      id="addFollowUpQuesMenu{{ sectionIndex }}-follow"
                      class="position-relative dropdown-menu dropdown-demo"
                      style="height: 230px; max-width: 265px !important"
                    >
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATA TABLE', i, true, question)"
                        *ngIf="!hasDataTableQues(section)"
                      >
                        <i class="me-3 la la-table"></i>
                        {{ locale('locale_key.pages.data_request.question_type.data_table') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'SINGLE CHOICE', i, true, question)"
                      >
                        <i class="me-3 la la-circle-o bold"></i>
                        {{ locale('locale_key.pages.data_request.question_type.single_choice') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'MULTIPLE CHOICE', i, true, question)"
                      >
                        <i class="me-3 la la-check-square bold"></i>
                        {{ locale('locale_key.pages.data_request.question_type.multi_choice') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'TEXT', i, true, question)"
                      >
                        <i class="me-4 ps-1 bold">A</i>
                        {{ locale('locale_key.pages.data_request.question_type.text') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'NUMBER', i, true, question)"
                      >
                        <i class="me-4 ps-1 bold">1</i>
                        {{ locale('locale_key.pages.data_request.question_type.number') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATE', i, true, question, false)"
                      >
                        <i class="me-3 la la-calendar"></i>
                        {{ locale('locale_key.pages.data_request.create_question.date.options.specific') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATE', i, true, question, true)"
                      >
                        <i class="me-3 la la-calendar"></i>
                        {{ locale('locale_key.pages.data_request.create_question.date.options.range') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'ATTACHMENT', i, true, question)"
                      >
                        <i class="me-3 la la-upload"></i>
                        {{ locale('locale_key.pages.data_request.question_type.file_upload') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'EMISSION_FACTOR', i, true, question)"
                      >
                        <i class="me-3 la la-fire-alt"></i>
                        {{ slocale('Emission factor') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!--Form View starts-->
              <div class="mx-0 mt-3">
                <div
                  class="d-flex flex-lg-row flex-column align-items-center justify-content-between"
                  *ngIf="requestService.isDataTableQues(question)"
                >
                  <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 m-0 p-0">
                    <div class="nav nav-tabs px-1" style="width: 200px; position: sticky; top: 0; border: none">
                      <ul
                        class="nav nav-underline nav-tabs no-hover-bg nav-justified"
                        style="width: 100%; border-bottom: none !important"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            [ngClass]="{ active: !isFormView }"
                            id="tableView-tab"
                            data-bs-toggle="tab"
                            href="#view"
                            aria-expanded="true"
                            (click)="setTableView()"
                          >
                            {{ slocale('Table View') }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            [ngClass]="{ active: isFormView }"
                            id="formView-tab"
                            data-bs-toggle="tab"
                            href="#view"
                            aria-expanded="false"
                            (click)="setFormView(); selectedQuestion = question; selectedQuestionIndex = i"
                          >
                            {{ slocale('Form View') }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 m-0 p-0">
                    <div
                      class="d-flex flex-row my-3 h-100 w-fit-content orange-border p-2"
                      [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'ms-auto' : 'me-auto'"
                      *ngIf="false"
                    >
                      <span class="">{{ slocale('Reporters can add additional rows ') }}</span>
                      <i
                        class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 mx-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="position: relative; top: 3px"
                      ></i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale('If this option is enabled, data reporters will be able to add new rows.') }}</p>
                      </div>
                      <label class="switch text-end">
                        <input type="checkbox" [(ngModel)]="question.required" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-content w-100"
                  [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'px-2' : 'px-0'"
                >
                  <div role="tabpanel" class="tab-pane active" id="view" aria-expanded="true">
                    <div id="question" *ngIf="requestService.isDataTableQues(question)">
                      <div
                        class="row m-0 p-0"
                        [ngClass]="{ 'table-view': !isFormView, 'form-view': isFormView }"
                        style="width: 100% !important; margin: 0 !important; padding: 0 !important"
                      >
                        <datagrid-table-editor
                          *ngIf="!question.editMapping"
                          [dataGrid]="requestItemDataGridServices[i]"
                          [isFormView]="isFormView"
                          [isPreviewMode]="false"
                          [isSMView]="true"
                          style="width: 100% !important; margin: 0 !important; padding: 0 !important"
                        ></datagrid-table-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Form View ends-->
          </ng-container>
          <div class="row my-2" style="max-width: 100%" *ngIf="!section.editTaxonomy">
            <div class="col-12 ps-sm-3">
              <div class="form-group">
                <div class="row me-auto mx-1">
                  <button
                    id="addNewQuesMenu{{ sectionIndex }}"
                    class="btn secondary-button btn-block mt-1"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#addNewQuesMenu' + sectionIndex + '-follow'"
                    aria-expanded="false"
                    [attr.aria-controls]="'addNewQuesMenu' + sectionIndex + '-follow'"
                    style="max-width: 265px"
                  >
                    <i class="las la-plus"></i>
                    {{ locale('locale_key.pages.data_request.create.button.add_question') }}
                  </button>
                  <div class="collapse" id="addNewQuesMenu{{ sectionIndex }}-follow">
                    <div class="card card-body" style="max-width: 265px; position: relative; left: -10px">
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATA TABLE')"
                        *ngIf="!hasDataTableQues(section)"
                      >
                        <i class="me-3 la la-table"></i>
                        {{ locale('locale_key.pages.data_request.question_type.data_table') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'SINGLE CHOICE')">
                        <i class="me-3 la la-circle-o"></i>
                        {{ locale('locale_key.pages.data_request.question_type.single_choice') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'MULTIPLE CHOICE')">
                        <i class="me-3 la la-check-square"></i>
                        {{ locale('locale_key.pages.data_request.question_type.multi_choice') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'TEXT')">
                        <i class="me-3 ps-1 bold">A</i>
                        {{ locale('locale_key.pages.data_request.question_type.text') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'NUMBER')">
                        <i class="me-3 ps-1 bold">1</i>
                        {{ locale('locale_key.pages.data_request.question_type.number') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATE', null, null, null, false)"
                      >
                        <i class="me-3 la la-calendar"></i>
                        {{ locale('locale_key.pages.data_request.create_question.date.options.specific') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="addNewQuestion(section, 'DATE', null, null, null, true)"
                      >
                        <i class="me-3 la la-calendar"></i>
                        {{ locale('locale_key.pages.data_request.create_question.date.options.range') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'ATTACHMENT')">
                        <i class="me-3 la la-upload"></i>
                        {{ locale('locale_key.pages.data_request.question_type.file_upload') }}
                      </button>
                      <button class="dropdown-item" type="button" (click)="addNewQuestion(section, 'EMISSION_FACTOR')">
                        <i class="me-3 la la-fire-alt"></i>
                        {{ slocale('Emission factor') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isQuestionnaireInvalid && section.questions.length == 0" class="invalid-feedback d-inline ms-4">
          {{ questionnaireLengthMessage }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex">
  <div class="flex-grow-1 my-auto border-dotted"></div>
  <div class="p-2">
    <button class="btn btn-block w-100 mt-1 tertiary-button" (click)="addSection()">
      {{ locale('locale_key.questionnaire.add_new_section') }}
    </button>
  </div>
  <div class="flex-grow-1 my-auto border-dotted"></div>
</div>

<ng-template #confirmDeleteQues>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale('locale_key.pages.data_request.edit.delete_question_with_follow_up') }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p class="m-0">{{ locale('locale_key.pages.data_request.edit.deleting_this_question_follow_up') }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button
        type="button"
        class="btn px-0 delete-custom-button custom-button"
        (click)="deleteQues(selectedQuestionIndex, selectedSection)"
      >
        {{ locale('locale_key.general.buttons.delete') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #addDataCategory>
  <add-data-category (closeEvent)="closeModal()"></add-data-category>
</ng-template>

<ng-template #addDataPoint>
  <add-data-point
    (closeEvent)="closeModal()"
    [isCalculatedColumn]="false"
    [entityKey]="selectedEntityKey"
  ></add-data-point>
</ng-template>

<ng-template #numberQuestionOption>
  <questionnaire-predefined-option-editor
    [question]="selectedQuestion"
    [questionType]="'number'"
    (closeEvent)="closeModal()"
  ></questionnaire-predefined-option-editor>
</ng-template>

<ng-template #dateQuestionOption>
  <questionnaire-predefined-option-editor
    [question]="selectedQuestion"
    [questionType]="'date'"
    (closeEvent)="closeModal()"
  ></questionnaire-predefined-option-editor>
</ng-template>

<ng-template #dateRangeQuestionOption>
  <questionnaire-predefined-option-editor
    [question]="selectedQuestion"
    [questionType]="'dateRange'"
    (closeEvent)="closeModal()"
  ></questionnaire-predefined-option-editor>
</ng-template>

<ng-template #emissionFactorQuestionOption>
  <questionnaire-predefined-option-editor
    [question]="selectedQuestion"
    [questionType]="'emissionFactor'"
    (closeEvent)="closeModal()"
  ></questionnaire-predefined-option-editor>
</ng-template>
