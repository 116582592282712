import { DateUtil } from 'src/app/utils/DateUtil'
import { AbstractSignInMethodFe } from '../signInMethod/AbstractSignInMethodFe'

export abstract class AbstractAffiliationFe {
  affiliationCompanyId: string
  userId: string
  userFirstName: string
  userLastName: string
  affiliationId: string
  affiliationRole: string
  affiliationEmail: string
  affiliationStatus: string
  activationDate: Date | null
  deactivationDate: Date | null
  lastLoggedAt: Date | null
  signInMethods: AbstractSignInMethodFe[]
  mfaEnabled: boolean

  constructor(
    affiliationCompanyId: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    affiliationId: string,
    affiliationRole: string,
    affiliationEmail: string,
    affiliationStatus: string,
    activationDate: Date | null,
    deactivationDate: Date | null,
    lastLoggedAt: Date | null,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    this.affiliationCompanyId = affiliationCompanyId
    this.userId = userId
    this.userFirstName = userFirstName
    this.userLastName = userLastName
    this.affiliationId = affiliationId
    this.affiliationRole = affiliationRole
    this.affiliationEmail = affiliationEmail
    this.affiliationStatus = affiliationStatus
    this.activationDate = activationDate
    this.deactivationDate = deactivationDate
    this.lastLoggedAt = lastLoggedAt
    this.signInMethods = signInMethods
    this.mfaEnabled = mfaEnabled
  }

  getName() {
    return `${this.userFirstName} ${this.userLastName}`
  }

  getActivationDate() {
    if (this.activationDate) {
      let date = new Date(this.activationDate)
      return DateUtil.toString3(date)
    }
  }

  getDeactivationDate() {
    if (this.deactivationDate) {
      let date = new Date(this.deactivationDate)
      return DateUtil.toString3(date)
    }
  }

  getlastLoggedAt() {
    if (this.lastLoggedAt) {
      let date = new Date(this.lastLoggedAt)
      return DateUtil.toString3(date)
    }
  }
}
