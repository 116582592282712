import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { ActivityTypeFe } from './ActivityTypeFe'

export abstract class AbstractActivityFe extends AbstractLanguageComponent {
  id: string
  type: string
  submitterAffId: string
  submitterFirstName: string
  submitterLastName: string
  submitterCompanyId: string
  submitterCompanyName: string
  submissionDate: Date | null
  message: string | null
  draft: boolean | null
  readStatus: string
  requestId: string
  requestGroupId: string
  requestGroupLabel: string
  requestAssigneeAffId: string
  requestAssigneeFirstName: string
  requestAssigneeLastName: string
  requestAssigneeCompanyName: string

  homePageLocaleKey: string | null
  homePageLocaleKeyDO: string | null
  activityHistoryLocaleKey: string | null
  icon: string

  constructor(
    id: string,
    type: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    languageService: LanguageService
  ) {
    super(languageService)
    this.id = id
    this.type = type
    this.submitterAffId = submitterAffId
    this.submitterFirstName = submitterFirstName
    this.submitterLastName = submitterLastName
    this.submitterCompanyId = submitterCompanyId
    this.submitterCompanyName = submitterCompanyName
    this.submissionDate = submissionDate ? new Date(submissionDate) : null
    this.message = message
    this.draft = draft
    this.readStatus = readStatus
    this.requestId = requestId
    this.requestGroupId = requestGroupId
    this.requestGroupLabel = requestGroupLabel
  }

  public homePageText(): string {
    return this.locale(this.homePageLocaleKey)
  }

  public homePageTextDO(): string {
    if (this.draft && this.type == ActivityTypeFe.SUBMIT_ANSWERS) {
      return this.locale('locale_key.pages.data_request.activity.saved_draft')
    } else {
      return this.locale(this.homePageLocaleKeyDO)
    }
  }

  public activityHistoryText(): string {
    if (this.draft && this.type == ActivityTypeFe.SUBMIT_ANSWERS) {
      return this.locale('locale_key.pages.data_request.activity.saved_draft')
    } else {
      return this.locale(this.activityHistoryLocaleKey)
    }
  }

  public getSubmitterName(): string {
    return this.submitterAffId != 'SYSTEM_AFF' ? `${this.submitterFirstName} ${this.submitterLastName}` : 'System'
  }

  public getSubmissionTime(): string {
    if (this.submissionDate) {
      return DateUtil.toString3(this.submissionDate)
    }
  }
}
