<div class="m-0 p-0">
  <div class="details">
    <!-- max-height: 41rem has been removed -->
    <div class="modal-body p-3" style="height: 70vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="content clearfix">
        <div class="form-group" *ngIf="contact">
          <h4 class="cursor-arrow">{{ locale('locale_key.pages.network.details_modal.title') }}</h4>
          <div class="d-flex flex-row">
            <div class="col-2 text-center my-auto" style="display: grid">
              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0">
                <i class="las la-user fs-4 text-white"></i>
              </div>
            </div>
            <div class="col-10 text-start">
              <h5 class="text-start inline-block mb-0 pb-1 ps-3 pt-3 cursor-arrow">{{ contact.getName() }}</h5>
              <p class="text-start mb-0 pb-1 ps-3 cursor-arrow" *ngIf="contact">
                <span class="badge badge-green cursor-arrow">{{ locale('locale_key.general.state.connected') }}</span>
              </p>
            </div>
          </div>
          <div class="text-muted cursor-arrow">{{ locale('locale_key.general.email') }}</div>
          <p class="text-start cursor-arrow">{{ contact.affiliationEmail }}</p>

          <div class="pt-3 border-t">
            <div class="d-flex flex-row">
              <div class="col-4 text-start">
                <h4 class="cursor-arrow">
                  {{ locale('locale_key.sustainability_manager_portal.main_navbar.connect.data_requests') }}
                </h4>
              </div>
              <div class="col-8 text-end">
                <span class="btn tertiary-button" routerLink="/ws/requests" (click)="openCreateRequest()">
                  <i class="la la-envelope font-small-5 my-auto me-2" style="position: relative; top: 2px"></i>
                  {{ locale('locale_key.pages.network.details_modal.button.create_request') }}
                </span>
              </div>
            </div>
            <div id="accordionWrap1" role="tablist">
              <!--PENDING REQUESTS-->
              <div class="accordion accordion-icon-rotate box my-2" *ngIf="pendingRequests.length > 0">
                <div
                  id="item0"
                  class="p-0 collapsed"
                  data-bs-toggle="collapse"
                  href="#accordion0"
                  aria-expanded="true"
                  aria-controls="accordion0"
                >
                  <div class="d-flex flex-row mx-auto w-100 row-design cursor my-1 p-1">
                    <div class="d-flex mx-auto" style="width: 80%">
                      <div class="icon-circle circle-info me-2 my-auto">
                        <span class="text-white cursor-arrow">{{ pendingRequests.length }}</span>
                      </div>
                      <a class="dark-text me-3">
                        {{ locale('locale_key.pages.network.details_modal.pending_requests') }}
                      </a>
                    </div>
                    <i class="icon my-auto mx-3"></i>
                  </div>
                </div>
                <div
                  id="accordion0"
                  role="tabpanel"
                  data-bs-parent="#accordionWrap1"
                  aria-labelledby="item0"
                  class="collapse border-t"
                >
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div
                        class="m-1 requests"
                        [ngClass]="{ 'border-b': !last }"
                        *ngFor="let request of pendingRequests; let i = index; let last = last"
                      >
                        <div class="d-flex flex-row m-0 p-0">
                          <h5 class="text-truncate text-center cursor-arrow" style="font-weight: 600 !important">
                            {{ getRequestGroup(request)?.title }}
                          </h5>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.general.state.created') }}</div>
                            <div class="cursor-arrow">{{ getRequestGroup(request)?.date | date: 'dd MMM, yyyy' }}</div>
                          </div>
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.heappening.deadline') }}</div>
                            <h6 class="badge due w-50 cursor-arrow" [ngClass]="this.getDeadline(request)">
                              {{ getRequestGroup(request)?.dueDate | date: 'dd MMM, yyyy' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--INPROGRESS REQUESTS-->
              <div class="accordion accordion-icon-rotate box my-2" *ngIf="inProgressRequests.length > 0">
                <div
                  id="item1"
                  class="p-0 collapsed"
                  data-bs-toggle="collapse"
                  href="#accordion1"
                  aria-expanded="true"
                  aria-controls="accordion1"
                >
                  <div class="d-flex flex-row mx-auto w-100 row-design cursor my-2 p-1">
                    <div class="d-flex mx-auto" style="width: 80%">
                      <div class="icon-circle circle-orange me-2 my-auto">
                        <span class="text-white cursor-arrow">{{ inProgressRequests.length }}</span>
                      </div>
                      <a class="dark-text me-3">{{ locale('locale_key.general.state.in_progress') }}</a>
                    </div>
                    <i class="icon my-auto mx-3"></i>
                  </div>
                </div>
                <div
                  id="accordion1"
                  role="tabpanel"
                  data-bs-parent="#accordionWrap1"
                  aria-labelledby="item0"
                  class="collapse border-t"
                >
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div
                        class="m-1 requests"
                        [ngClass]="{ 'border-b': !last }"
                        *ngFor="let request of inProgressRequests; let i = index; let last = last"
                      >
                        <div class="d-flex flex-row m-0 p-0">
                          <h5 class="text-truncate text-center cursor-arrow" style="font-weight: 600 !important">
                            {{ getRequestGroup(request)?.title }}
                          </h5>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.general.state.created') }}</div>
                            <div class="cursor-arrow">{{ getRequestGroup(request)?.date | date: 'dd MMM, yyyy' }}</div>
                          </div>
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.heappening.deadline') }}</div>
                            <h6 class="badge due w-50 cursor-arrow" [ngClass]="this.getDeadline(request)">
                              {{ getRequestGroup(request)?.dueDate | date: 'dd MMM, yyyy' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--COMPLETED REQUESTS-->
              <div class="accordion accordion-icon-rotate box my-2" *ngIf="completedRequests.length > 0">
                <div
                  id="item2"
                  class="p-0 collapsed"
                  data-bs-toggle="collapse"
                  href="#accordion2"
                  aria-expanded="true"
                  aria-controls="accordion2"
                >
                  <div class="d-flex flex-row mx-auto w-100 row-design cursor my-2 p-1">
                    <div class="d-flex mx-auto" style="width: 80%">
                      <div class="icon-circle circle-green me-2 my-auto">
                        <span class="text-white">{{ completedRequests.length }}</span>
                      </div>
                      <a class="dark-text me-3">{{ locale('locale_key.general.state.completed') }}</a>
                    </div>
                    <i class="icon my-auto mx-3"></i>
                  </div>
                </div>
                <div
                  id="accordion2"
                  role="tabpanel"
                  data-bs-parent="#accordionWrap1"
                  aria-labelledby="item0"
                  class="collapse border-t"
                >
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div
                        class="m-3 requests"
                        [ngClass]="{ 'border-b': !last }"
                        *ngFor="let request of completedRequests; let i = index; let last = last"
                      >
                        <div class="d-flex flex-row m-0 p-0">
                          <h5 class="text-truncate text-center cursor-arrow" style="font-weight: 600 !important">
                            {{ getRequestGroup(request)?.title }}
                          </h5>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.general.state.created') }}</div>
                            <div class="cursor-arrow">{{ getRequestGroup(request)?.date | date: 'dd MMM, yyyy' }}</div>
                          </div>
                          <div class="col-6">
                            <div class="text-muted cursor-arrow">{{ locale('locale_key.heappening.deadline') }}</div>
                            <h6 class="badge due w-50 cursor-arrow" [ngClass]="this.getDeadline(request)">
                              {{ getRequestGroup(request)?.dueDate | date: 'dd MMM, yyyy' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row mx-auto w-100 row-design cursor my-2 p-1 cursor-arrow">
              <div class="d-flex me-auto ps-3" style="width: 80%">
                <div class="icon-circle me-1 my-auto">
                  <span class="text-white cursor-arrow">{{ contact.dataRequests.length }}</span>
                </div>
                <span class="dark-text me-2 cursor-arrow">
                  {{ locale('locale_key.pages.network.details_modal.total_requests') }}
                </span>
              </div>
              <i class="my-auto mx-2"></i>
            </div>
          </div>

          <!--SHARED DASHBOARDS-->
          <div class="mt-1 border-t">
            <div id="accordionWrap2" role="tablist" aria-multiselectable="false">
              <div class="accordion accordion-icon-rotate my-2" *ngIf="contact.sharedDashboards.length > 0">
                <div
                  id="item4"
                  class="p-0 collapsed"
                  data-bs-toggle="collapse"
                  href="#accordion4"
                  aria-expanded="true"
                  aria-controls="accordion4"
                >
                  <div class="d-flex flex-row mx-auto w-100 row-design cursor my-1 p-1">
                    <div class="d-flex mx-auto" style="width: 80%">
                      <div class="icon-circle me-2 my-auto">
                        <span class="text-white">{{ contact.sharedDashboards.length }}</span>
                      </div>
                      <a class="dark-text me-3">
                        {{ locale('locale_key.pages.network.business_partners_table.shared_dashboards_column') }}
                      </a>
                    </div>
                    <i class="icon my-auto mx-3"></i>
                  </div>
                </div>
                <div
                  id="accordion4"
                  role="tabpanel"
                  data-bs-parent="#accordionWrap2"
                  aria-labelledby="item4"
                  class="collapse"
                >
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="p-1 box my-auto" *ngFor="let sharedDashboard of contact.sharedDashboards">
                        <span class="cursor-arrow my-auto">
                          {{ sharedDashboard.name }}
                          <i
                            class="la la-arrow-right float-end font-medium-1 cursor my-auto dark-text"
                            style="position: relative; top: 4px"
                            routerLink="/ws/dashboard"
                            (click)="openDashboard(sharedDashboard)"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="company">
          <div class="d-flex flex-row">
            <div class="col-4 text-center">
              <i class="la la-industry" style="font-size: 5rem"></i>
            </div>
            <div class="col-8 text-start">
              <h3 class="text-start pt-1 mb-0 pb-1 ps-3 cursor-arrow">
                {{ company['name'] || company['supplierCompanyname'] }}
              </h3>
              <p class="text-start mb-0 pb-1 ps-3" *ngIf="company">
                <span class="badge badge-green cursor-arrow">{{ locale('locale_key.general.state.connected') }}</span>
              </p>
            </div>
          </div>
          <div class="mt-3">
            <table class="table table-borderless mx-0">
              <tbody class="mx-0 px-0">
                <tr class="mx-0 px-0">
                  <td class="text-start mx-0 col-4 cursor-arrow" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry_group.title') }}
                  </td>
                  <td class="text-start mx-0 col-8 mt-auto cursor-arrow" style="font-size: 1rem; font-weight: 400">
                    {{ company['supplierIndustryGroup'] }}
                  </td>
                </tr>
                <tr class="mx-0 px-0">
                  <td class="text-start mx-0 col-4 cursor-arrow" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.industry.title') }}
                  </td>
                  <td class="text-start mx-0 col-8 mt-auto cursor-arrow" style="font-size: 1rem; font-weight: 400">
                    {{ company['supplierIndustry'] }}
                  </td>
                </tr>
                <tr>
                  <td class="text-start mx-0 col-4 cursor-arrow" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.website.title') }}
                  </td>
                  <td class="text-start mx-0 col-8 mt-auto cursor-arrow" style="font-size: 1rem; font-weight: 400">
                    {{ company['supplierWebsite'] }}
                  </td>
                </tr>
                <tr>
                  <td class="text-start mx-0 col-4 cursor-arrow" style="font-size: 1.2rem; font-weight: 300">
                    {{ locale('locale_key.pages.network.business_partner_detail.country.title') }}
                  </td>
                  <td class="text-start mx-0 col-8 mt-auto cursor-arrow" style="font-size: 1rem; font-weight: 400">
                    {{ getCountry(company['supplierCountryCode']) || getCountry(company['countryCode']) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
