import { AbstractSignInMethodFe } from '../signInMethod/AbstractSignInMethodFe'
import { SignInMethodConversionFe } from '../signInMethod/SignInMethodConversionFe'
import { AbstractAffiliationFe } from './AbstractAffiliationFe'
import { AffiliationRoleFe } from './AffiliationRoleFe'

export class ManagerFe extends AbstractAffiliationFe {
  isMainManager: boolean

  constructor(
    affiliationCompanyId: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    affiliationId: string,
    affiliationEmail: string,
    affiliationStatus: string,
    activationDate: Date | null,
    deactivationDate: Date | null,
    lastLoggedAt: Date | null,
    isMainManager: boolean,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    super(
      affiliationCompanyId,
      userId,
      userFirstName,
      userLastName,
      affiliationId,
      AffiliationRoleFe.SUSTAINABILITY_MANAGER,
      affiliationEmail,
      affiliationStatus,
      activationDate,
      deactivationDate,
      lastLoggedAt,
      signInMethods,
      mfaEnabled
    )
    this.isMainManager = isMainManager
  }

  public static fromTransfer(transfer: any): ManagerFe {
    let signInMethods = transfer['signInMethods']?.map((signInMethod) =>
      SignInMethodConversionFe.fromTransfer(signInMethod)
    )
    let manager = new ManagerFe(
      transfer['affiliationCompanyId'],
      transfer['userId'],
      transfer['userFirstName'],
      transfer['userLastName'],
      transfer['affiliationId'],
      transfer['affiliationEmail'],
      transfer['affiliationStatus'],
      transfer['activationDate'],
      transfer['deactivationDate'],
      transfer['lastLoggedAt'],
      transfer['isMainManager'],
      signInMethods,
      transfer['mfaEnabled']
    )
    return manager
  }
}
