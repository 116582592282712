import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { QuestionnaireFe } from '../questionnaire/QuestionnaireFe'
import { SectionFe } from '../questionnaire/SectionFe'

export class CsrdQuestionnaireFe extends QuestionnaireFe {
  constructor(sections: SectionFe[], autoConnect: boolean, requestId: string) {
    super(sections, autoConnect, requestId)
  }

  public static fromCsrdDefinitionJson(json: any, taxonomyInfo?: TaxonomyInfoFe): CsrdQuestionnaireFe {
    let sections = json.sections.map((section) => SectionFe.fromTransfer(section, taxonomyInfo))
    const csrdQuestionnaire = new CsrdQuestionnaireFe(sections, json.autoConnect, json.requestId)
    return csrdQuestionnaire
  }
}
