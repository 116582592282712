import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
  Language,
  ENGLISH,
  SWEDISH,
  SPANISH,
  FINNISH,
  DANISH,
  NORWEGIAN,
  GERMAN,
  DUTCH,
  POLISH,
  ITALIAN,
  FRENCH,
  PORTUGUESE,
  HEBREW,
  JAPANESE,
  CHINESE,
  HINDI
} from '../utils/language/Language'
import { LoginServiceFe } from './LoginServiceFe'
import locales from '../utils/language/AUTO_GENERATED_LOCALE_DB.json'
import emailKeysFe from '../utils/language/locale.email.keysfe.json'
import emailValuesFe from '../utils/language/locale.email.valuesfe.json'
import { AlertServiceFe } from './AlertServiceFe'
import { StateServiceFe } from './StateServiceFe'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public availableLanguages: Language[] = [
    ENGLISH,
    SWEDISH,
    FINNISH,
    DANISH,
    NORWEGIAN,
    GERMAN,
    DUTCH,
    POLISH,
    SPANISH,
    ITALIAN,
    FRENCH,
    PORTUGUESE,
    HEBREW,
    JAPANESE,
    CHINESE,
    HINDI
  ]

  public activeLanguage: Language = ENGLISH
  public languageSubject = new BehaviorSubject<Language>(ENGLISH)
  public pageLanguage: Language | undefined
  public pageLanguageSubject = new BehaviorSubject<Language>(ENGLISH)
  public activeEmailLanguage: Language = ENGLISH
  public emailLanguageSubject = new BehaviorSubject<Language>(ENGLISH)

  constructor(
    private loginService: LoginServiceFe,
    private alertService: AlertServiceFe
  ) {
    this.loginService.loggedInSubject.subscribe(async (loggedIn: boolean) => {
      if (loggedIn) {
        const lang = loginService.loginUser.language
        if (lang) {
          this.changeLanguage(lang)
        } else {
          this.changeLanguage(ENGLISH.code)
        }
      }
    })
  }

  changeLanguage(code: string) {
    let language = this.availableLanguages.find((lang) => lang.code == code)
    if (language && language != this.activeLanguage) {
      this.activeLanguage = language
      this.pageLanguage = language
      this.languageSubject.next(language)
    }
  }

  changePageLanguage(code: string) {
    let language = this.availableLanguages.find((lang) => lang.code == code)
    if (language && language != this.pageLanguage) {
      this.pageLanguage = language
      this.pageLanguageSubject.next(language)
    }
  }

  changeCompanyEmailLanguage(code: string) {
    let language = this.availableLanguages.find((lang) => lang.code == code)
    if (language && language != this.activeEmailLanguage) {
      this.activeEmailLanguage = language
      this.emailLanguageSubject.next(language)
    }
  }

  getLanguageObject(langCode: string): Language {
    return this.availableLanguages.find((lang) => lang.code == langCode)
  }

  getActiveLanguage(): Language {
    return this.activeLanguage
  }

  public getLocale(key: string, kv: any = undefined, languageCode?: string): string {
    let locale = locales.find((l) => l.key === key)
    if (!locale) {
      return 'NOT_COVERED'
    }

    let label = locale[languageCode || this.activeLanguage.code]

    if (!label || label.length == 0) {
      return 'NOT_TRANSLATED'
    }

    if (kv) {
      const variableKeys = Object.keys(kv)

      variableKeys.forEach((key) => {
        const regex = new RegExp('\\{\\{' + key + '\\}\\}', 'g')
        label = label.replace(regex, kv[key])
      })
    }

    return label
  }

  getDisplayActiveLanguage() {
    return this.pageLanguage?.code || this.activeLanguage.code
  }

  clearDisplayLanguage() {
    this.pageLanguage = undefined
  }
}
