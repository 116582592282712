<div *ngIf="loadingInProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card w-100 pt-1 me-2" *ngIf="!loadingInProgress">
  <div class="card-content">
    <div class="card-body px-2">
      <ng-container *ngIf="datahubService.isFilter('allData')">
        <div class="d-flex flex-xl-row flex-column p-3 h-100 mb-3">
          <div
            class="col-xl-4 col-12 d-flex flex-column justify-content-between"
            style="height: 400px; max-height: 400px"
          >
            <div class="w-100 btn-group" dropdown container="body" [insideClick]="true">
              <input
                type="text"
                id="search-button"
                class="form-control w-auto"
                placeholder="{{ locale('locale_key.pages.datahub.data_hub_home.placeholder.search_data_hub') }}"
                #searchInDatahub
                (input)="showFiltered = false"
                (change)="searchDatahub(searchInDatahub.value)"
                dropdownToggle
                aria-controls="filter-dropdown-menu"
              />
              <ng-container *dropdownMenu role="menu" aria-labelledby="search-button" id="filter-dropdown-menu">
                <div class="ms-3 searchDropdown" [ngClass]="{ 'd-none': !showFiltered }">
                  <p class="text-muted">
                    {{
                      locale('locale_key.pages.datahub.data_hub_home.search_results', {
                        keyword: searchInDatahub.value
                      })
                    }}
                  </p>
                  <div class="col" *ngIf="showFiltered">
                    <div class="border-b py-2">
                      <h5 class="text-bold-400 my-auto text-truncate">
                        <i
                          class="la la-envelope-o my-auto mx-2 dark-text"
                          style="font-size: 2rem"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.data_reporter_submission') }}"
                        ></i>
                        {{ locale('locale_key.pages.datahub.data_hub_home.data_reporter_submission') }}
                      </h5>
                      <div
                        class="my-0 ps-2 cursor hoverColor"
                        *ngFor="let rg of filteredItems.rg"
                        (click)="datahubService.showRgResult(rg)"
                      >
                        {{ rg.title }}
                      </div>
                      <i class="text-muted ps-2" *ngIf="filteredItems.rg.length == 0">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </i>
                    </div>
                    <div class="border-b py-2">
                      <h5 class="text-bold-400 my-auto text-truncate">
                        <i
                          class="la la-file my-auto mx-2 dark-text"
                          style="font-size: 2rem"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.file') }}"
                        ></i>
                        {{ locale('locale_key.pages.datahub.data_hub_home.file') }}
                      </h5>
                      <div
                        class="my-0 ps-2 cursor hoverColor"
                        *ngFor="let file of filteredItems.files"
                        (click)="datahubService.showFile(file)"
                      >
                        {{ file.label }}
                      </div>
                      <i class="text-muted ps-2" *ngIf="filteredItems.files.length == 0">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </i>
                    </div>
                    <div class="border-b py-2">
                      <h5 class="text-bold-400 my-auto text-truncate">
                        <i
                          class="la la-keyboard-o my-auto mx-2 dark-text"
                          style="font-size: 2rem"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_entry') }}"
                        ></i>
                        {{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_entry') }}
                      </h5>
                      <div
                        class="my-0 ps-2 cursor hoverColor"
                        *ngFor="let entry of filteredItems.entries"
                        (click)="datahubService.showDataEntry(entry)"
                      >
                        {{
                          entry.taxonomyEntity ? entry.taxonomyEntity.getLabel(this.activeLanguage) : entry.taxonomyKey
                        }}
                      </div>
                      <i class="text-muted ps-2" *ngIf="filteredItems.entries.length == 0">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </i>
                    </div>
                    <div class="border-b py-2">
                      <h5 class="text-bold-400 my-auto text-truncate">
                        <i
                          class="la la-desktop my-auto mx-2 dark-text"
                          style="font-size: 2rem"
                          title="{{
                            locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed')
                          }}"
                        ></i>
                        {{ locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed') }}
                      </h5>
                      <div
                        class="my-0 ps-2 cursor hoverColor"
                        *ngFor="let feed of filteredItems.feeds"
                        (click)="datahubService.showFeed(feed)"
                      >
                        {{ feed.taxonomyEntity ? feed.taxonomyEntity.getLabel(this.activeLanguage) : feed.taxonomyKey }}
                      </div>
                      <i class="text-muted ps-2" *ngIf="filteredItems.feeds.length == 0">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </i>
                    </div>
                    <div class="border-b py-2">
                      <h5 class="text-bold-400 my-auto text-truncate">
                        <img
                          class="brand-logo img-responsive img-fluid my-auto mx-2"
                          src="/assets/img/logos/Logo-Sustainlab.png"
                          style="height: 2rem"
                          title="{{ locale('locale_key.pages.data_hub.master_table.title') }}"
                        />
                        {{ locale('locale_key.pages.data_hub.master_table.title') }}
                      </h5>
                      <div
                        class="my-0 ps-2 cursor hoverColor"
                        *ngFor="let entity of filteredItems.entities"
                        (click)="datahubService.showEntityMasterTable(entity)"
                      >
                        {{ entity.label }}
                      </div>
                      <i class="text-muted ps-2" *ngIf="filteredItems.entities.length == 0">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="d-flex flex-row border p-2 mt-2">
              <i class="la la-envelope-o my-auto mx-2 dark-text" style="font-size: 4rem"></i>
              <span class="my-auto">
                <h5 class="mb-0 pb-0">
                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.data_reporter_submissions') }}
                </h5>
                <small class="mt-0 pt-0">
                  {{
                    locale('locale_key.pages.datahub.data_hub_home.table_header.data_reporter_submissions.description')
                  }}
                </small>
              </span>
              <i
                class="la la-arrow-right cursor my-auto ms-auto me-2 thin-border btn-hover"
                (click)="setView('doSubmissionsView')"
              ></i>
            </div>
            <div class="m-0 p-0 w-100 m-auto text-center">
              <i class="la la-arrow-down blue m-auto fs-4 text-center"></i>
            </div>
            <div class="d-flex flex-row border p-2 mb-2">
              <i class="la la-file my-auto m-3 py-1 dark-text" style="font-size: 3.5rem"></i>
              <span class="my-auto">
                <h5 class="mb-0 pb-0">{{ locale('locale_key.pages.datahub.data_hub_home.table_header.files') }}</h5>
                <small class="mt-0 pt-0">
                  {{
                    locale('locale_key.pages.datahub.data_hub_home.table_header.files_from_requests_and_uploaded_files')
                  }}
                </small>
              </span>
              <i
                class="la la-arrow-right cursor my-auto ms-auto me-2 thin-border btn-hover"
                (click)="setView('filesPortalView')"
              ></i>
            </div>
            <div class="d-flex flex-row border p-2 my-2">
              <i class="la la-keyboard-o my-auto mx-2 dark-text" style="font-size: 4rem"></i>
              <span class="my-auto">
                <h5 class="mb-0 pb-0">
                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_data_entries') }}
                </h5>
                <small class="mt-0 pt-0">
                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_data_entries.description') }}
                </small>
              </span>
              <i
                class="la la-arrow-right cursor my-auto ms-auto me-2 thin-border btn-hover"
                (click)="setView('dataEntriesPortalView')"
              ></i>
            </div>
            <div
              class="line-down"
              *ngIf="
                !screenSize.isSmallerSize() &&
                !screenSize.isXSmallSize() &&
                !screenSize.isMediumSize() &&
                !screenSize.isLargeSize()
              "
            >
              <div class="arrow-down"></div>
            </div>
            <div
              class="line-straight"
              *ngIf="
                !screenSize.isSmallerSize() &&
                !screenSize.isXSmallSize() &&
                !screenSize.isMediumSize() &&
                !screenSize.isLargeSize()
              "
            >
              <div class="arrow-right"></div>
            </div>
            <div
              class="line-up"
              *ngIf="
                !screenSize.isSmallerSize() &&
                !screenSize.isXSmallSize() &&
                !screenSize.isMediumSize() &&
                !screenSize.isLargeSize()
              "
            >
              <div class="arrow-up"></div>
            </div>
            <div class="d-flex flex-row border p-2 my-2">
              <i class="la la-desktop my-auto mx-2 dark-text" style="font-size: 4rem"></i>
              <span class="my-auto text-wrap text-truncate multiline">
                <h5 class="mb-0 pb-0">
                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed') }}
                </h5>
                <small class="mt-0 pt-0 text-wrap multiline">
                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.data_imported_from_system') }}
                </small>
              </span>
              <i
                class="la la-arrow-right cursor my-auto ms-auto me-2 thin-border btn-hover"
                (click)="setView('automatedFeedPortalView')"
              ></i>
            </div>
          </div>

          <div class="col-xl-8 col-12 my-auto" [ngClass]="{ 'ps-3 pt-4': screenSize.isXLargeSize() }">
            <div
              class="d-flex flex-xl-row flex-lg-row flex-column justify-content-around justify-content-lg-around align-content-between align-content-lg-between my-auto"
              [ngClass]="{ 'ps-3': screenSize.isXLargeSize() }"
            >
              <div
                class="d-flex flex-column col-xl-3 col-lg-3 col-12 border py-3 px-2"
                [ngClass]="{
                  'table-height': !screenSize.isXSmallSize() || !screenSize.isSmallerSize(),
                  'my-2 me-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize(),
                  'm-top': screenSize.isXSmallSize()
                }"
                [ngStyle]="{ 'margin-top': screenSize.isXSmallSize() ? '5.5rem !important' : '0rem' }"
              >
                <img
                  class="brand-logo img-responsive img-fluid pb-1"
                  src="/assets/img/logos/Logo-Sustainlab.png"
                  style="width: 1.5rem"
                />
                <div class="d-flex flex-row truncate text-wrap" style="height: 5rem">
                  <div class="col truncate text-wrap multiline">
                    <h5 class="mb-0 pb-0">
                      {{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables') }}
                    </h5>
                    <small
                      class="mt-0 pt-0 text-wrap multiline cursor"
                      data-bs-toggle="tooltip"
                      title="{{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables.description') }}"
                      show-delay="2000"
                      data-placement="top"
                    >
                      {{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables.description') }}
                    </small>
                  </div>
                </div>
                <div class="mt-auto text-start">
                  <button class="btn tertiary-button ms-auto me-2" (click)="setView('masterTablePortalView')">
                    {{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables') }}
                  </button>
                </div>
              </div>
              <div
                class="dot1 horizontal"
                *ngIf="
                  !screenSize.isSmallerSize() &&
                  !screenSize.isXSmallSize() &&
                  !screenSize.isMediumSize() &&
                  !screenSize.isLargeSize()
                "
              ></div>
              <div
                class="d-flex flex-column col-xl-3 col-lg-3 col-12 border py-3 px-2"
                [ngClass]="{
                  'table-height': !screenSize.isXSmallSize() || !screenSize.isSmallerSize(),
                  'my-2 me-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()
                }"
              >
                <i class="las la-chart-bar" style="font-size: 1.7rem"></i>
                <div class="row truncate text-wrap" style="height: 5rem">
                  <div class="col truncate text-wrap multiline">
                    <h5 class="mb-0 pb-0">{{ locale('locale_key.pages.landing.sm.kpis.title') }}</h5>
                    <small
                      class="mt-0 pt-0 text-wrap multiline cursor"
                      data-bs-toggle="tooltip"
                      title="{{ locale('locale_key.pages.datahub.data_hub_home.calculated_key_measurements') }}"
                      show-delay="2000"
                      data-placement="top"
                    >
                      {{ locale('locale_key.pages.datahub.data_hub_home.calculated_key_measurements') }}
                    </small>
                  </div>
                </div>
                <div class="mt-auto">
                  <button class="btn tertiary-button ms-auto text-nowrap me-2" routerLink="/ws/insights_hub">
                    {{ locale('locale_key.pages.datahub.data_hub_home.button.kpis_library') }}
                  </button>
                </div>
              </div>
              <div
                class="dot1 horizontal"
                *ngIf="
                  !screenSize.isSmallerSize() &&
                  !screenSize.isXSmallSize() &&
                  !screenSize.isMediumSize() &&
                  !screenSize.isLargeSize()
                "
              ></div>
              <div
                class="d-flex flex-column col-xl-3 col-lg-3 col-12 border py-3 px-2"
                [ngClass]="{
                  'table-height': !screenSize.isXSmallSize() || !screenSize.isSmallerSize(),
                  'my-2 me-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()
                }"
              >
                <i class="la la-dashboard" style="font-size: 2rem"></i>
                <div class="d-flex flex-row truncate text-wrap" style="height: 5rem">
                  <div class="col truncate text-wrap">
                    <h5 class="mb-0 pb-0">{{ locale('locale_key.pages.datahub.data_hub_home.button.dashboards') }}</h5>
                    <small
                      class="mt-0 pt-0 text-wrap multiline cursor"
                      data-bs-toggle="tooltip"
                      title="{{ locale('locale_key.pages.datahub.data_hub_home.dashboards_description') }}"
                      show-delay="2000"
                      data-placement="top"
                    >
                      {{ locale('locale_key.pages.datahub.data_hub_home.dashboards_description') }}
                    </small>
                  </div>
                </div>
                <div class="mt-auto">
                  <button class="btn tertiary-button ms-auto me-2" routerLink="/ws/dashboard">
                    {{ locale('locale_key.pages.datahub.data_hub_home.button.dashboards') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-column p-3" *ngIf="getRecentlyOpenedItems().length > 0">
          <a
            class="accordion accordion-icon-rotate accordion-button m-auto row-design py-3 flex-wrap d-flex flex-row align-content-center border-t"
            id="requestsHeader"
            data-bs-toggle="collapse"
            href="#requests"
            aria-expanded="true"
            attr.aria-controls="requests"
          >
            <div class="col-xl-10 col-lg-10 col-11 my-auto">
              <h5 class="text-wrap dark-text d-inline ps-2">
                {{ locale('locale_key.pages.datahub.data_hub_home.table_header.recently_opened') }}
              </h5>
            </div>
            <div class="col-xl col-lg d-lg-flex d-none">
              <h5>{{ locale('locale_key.pages.datahub.data_hub_home.table_header.date_opened') }}</h5>
            </div>
          </a>
          <div
            id="requests"
            role="tabpanel"
            attr.aria-labelledby="requestsHeader"
            class="border show"
            style="overflow: hidden !important; background-color: var(--complementary-color1)"
          >
            <div class="card-content">
              <div class="card-body px-2">
                <div class="border-l border-r border-t m-0 p-0">
                  <ng-container *ngFor="let item of getRecentlyOpenedItems()">
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                      [ngClass]="{ 'selected-row': item.item == selected }"
                      *ngIf="item.isItemRg()"
                      (click)="datahubService.showRgResult(item.item); selected = item"
                    >
                      <div class="col-xl-3 col-lg-3 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                        <i
                          class="la la-envelope-o my-auto mx-2"
                          style="font-size: 2rem"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.data_reporter_submission') }}"
                        ></i>
                        <h5
                          class="text-bold-400 my-auto text-wrap"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{ item.item?.title }}
                        </h5>
                      </div>
                      <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                        <div class="icon-circle-dark me-2 my-auto">
                          <span class="text-white">{{ item.item?.requestCount }}</span>
                        </div>
                        <span class="my-auto text-wrap">
                          {{
                            item.item?.requestCount == 1
                              ? locale('locale_key.pages.datahub.button.data_reporter')
                              : locale('locale_key.general.user_role.data_reporters')
                          }}
                        </span>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-center my-auto">
                        <h6
                          class="badge m-auto"
                          [ngClass]="item.item?.getDeadlineClass()"
                          *ngIf="item.item?.status !== 'APPROVED'"
                        >
                          <ng-container>{{ item.item?.dueDate | date: 'dd MMM, yyyy (HH:mm)' }}</ng-container>
                        </h6>
                        <h6 class="badge badge-success m-auto" *ngIf="item.item?.status == 'APPROVED'">
                          {{ locale('locale_key.general.state.completed') }}
                        </h6>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-center my-auto">
                        <h6 class="badge m-auto" [ngClass]="item.item?.getStatusClass()">
                          {{ locale(item.item?.getStatusTextKeyword()) | titlecase }}
                        </h6>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                        <span class="my-auto">{{ item.getLastOpened() }}</span>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                      [ngClass]="{ 'selected-row': item.item == selected }"
                      *ngIf="item.isItemDirectEntry() || item.isItemAutomatedFeed()"
                      (click)="
                        selected = item;
                        item.isItemDirectEntry()
                          ? datahubService.showDataEntry(item.item)
                          : datahubService.showFeed(item.item)
                      "
                    >
                      <div class="col-xl-3 col-lg-3 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                        <i
                          class="la la-keyboard-o my-auto mx-2"
                          style="font-size: 2rem"
                          *ngIf="item.isItemDirectEntry()"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_entry') }}"
                        ></i>
                        <i
                          class="la la-desktop my-auto mx-2"
                          style="font-size: 2rem"
                          *ngIf="item.isItemAutomatedFeed()"
                          title="{{
                            locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed')
                          }}"
                        ></i>
                        <h5
                          class="text-bold-400 my-auto text-wrap"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{
                            item.item?.taxonomyEntity
                              ? item.item?.taxonomyEntity.getLabel(this.activeLanguage)
                              : item.item?.taxonomyKey
                          }}
                        </h5>
                      </div>
                      <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                        <label *ngIf="item.item?.taxonomyEntity">
                          <a class="text-truncate fs-5">
                            <i
                              class="{{ taxonomy.icon(item.item?.rootParentEntity) }} fs-5"
                              [ngStyle]="{ color: taxonomy.color(item.item?.rootParentEntity) }"
                            ></i>
                            <h5 class="dark-text d-inline text-truncate">
                              {{ item.item?.rootParentEntity?.getLabel(this.activeLanguage) }} >
                              {{ item.item?.parentEntity?.getLabel(this.activeLanguage) }}
                            </h5>
                          </a>
                        </label>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex flex-row justify-content-center m-auto text-truncate">
                        <span class="my-auto badge badge-turquoise">{{ item.item?.timeFromString }}</span>
                        -
                        <span class="my-auto badge badge-turquoise">{{ item.item?.timeToString }}</span>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start m-auto text-truncate">
                        <div
                          class="badge mx-auto"
                          [ngStyle]="{ 'background-color': item.item?.statusColor() }"
                          style="color: white"
                        >
                          <i class="la {{ item.item?.statusIcon() }} font-small-3"></i>
                          <span class="font-small-3">{{ locale(item.item?.statusLocaleKey()) }}</span>
                        </div>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                        <span class="my-auto">{{ item.getLastOpened() }}</span>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                      [ngClass]="{ 'selected-row': item.item == selected }"
                      *ngIf="item.isItemFile()"
                      (click)="datahubService.showFile(item.item); selected = item"
                    >
                      <div class="col-xl-3 col-lg-3 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                        <i
                          class="la la-file my-auto mx-2"
                          style="font-size: 2rem"
                          title="{{ locale('locale_key.pages.datahub.data_hub_home.file') }}"
                        ></i>
                        <h5
                          class="text-bold-400 my-auto text-wrap"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{ item.item?.label }}
                        </h5>
                      </div>
                      <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                        <h5 class="dark-text d-inline text-truncate">{{ item.item?.getUploaderName() }}</h5>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex flex-row justify-content-center m-auto text-truncate">
                        <h5 class="dark-text d-inline text-truncate">{{ item.item?.uploaderCompany }}</h5>
                      </div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start m-auto"></div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto text-truncate">
                        <span class="my-auto">{{ item.getLastOpened() }}</span>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                      [ngClass]="{ 'selected-row': item.item == selected }"
                      *ngIf="item.isItemMasterTable()"
                      (click)="datahubService.showEntityMasterTable(item.item); selected = item"
                    >
                      <div class="col-xl-3 col-lg-3 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                        <img
                          class="brand-logo img-responsive img-fluid my-auto mx-2"
                          src="/assets/img/logos/Logo-Sustainlab.png"
                          style="height: 2rem"
                          title="{{ locale('locale_key.pages.data_hub.master_table.title') }}"
                        />
                        <h5
                          class="text-bold-400 my-auto text-truncate"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{ item.item?.getLabel(this.activeLanguage) }}
                        </h5>
                      </div>
                      <div class="col-xl-3 col-3 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate">
                        <label>
                          <a class="text-truncate fs-5">
                            <i
                              class="{{ taxonomy.rootParentEntity(item.item?.key)?.icon }} fs-5"
                              [ngStyle]="{ color: taxonomy.rootParentEntity(item.item?.key)?.color }"
                            ></i>
                            <h5 class="dark-text d-inline text-truncate">
                              {{ taxonomy.rootParentEntity(item.item?.key)?.getLabel(this.activeLanguage) }} >
                              {{ taxonomy.parentEntity(item.item?.key)?.getLabel(this.activeLanguage) }}
                            </h5>
                          </a>
                        </label>
                      </div>
                      <div
                        class="col-xl-2 col-2 d-none d-lg-flex flex-row justify-content-start my-auto text-truncate"
                      ></div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto text-truncate"></div>
                      <div class="col-xl-2 col-2 d-none d-lg-flex justify-content-start my-auto">
                        <span class="my-auto">{{ item.getLastOpened() }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="!datahubService.isFilter('allData')">
        <div class="col-xl-6 col-12">
          <master-table-portal *ngIf="datahubService.isFilter('dataCategory')"></master-table-portal>
          <do-submissions *ngIf="datahubService.isFilter('dataRequest')"></do-submissions>
          <ng-container *ngIf="datahubService.isFilter('company')">
            <div class="card-content">
              <div class="card-body p-3">
                <p class="h6">{{ locale('locale_key.pages.datahub.data_hub_home.click_on_company') }}</p>
                <div class="row">
                  <div class="col-md-6 col-lg-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ locale('locale_key.general.search') }}"
                      #searchCompany
                      (input)="applySearchFilterCompany(searchCompany.value)"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4 mb-3 btn-group" dropdown container="body">
                    <button
                      type="button"
                      class="form-control"
                      id="group-button"
                      dropdownToggle
                      aria-controls="group-dropdown-menu"
                      style="text-align: left"
                    >
                      {{ locale('locale_key.pages.datahub.filter_group_by') }}
                      {{ groupedBy ? groupedBy.name : locale('locale_key.pages.datahub.filter_none') }}
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="group-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="group-button"
                    >
                      <a class="dropdown-item" (click)="groupedBy = null">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item"
                        *ngFor="let option of groupedByOptionsForCompany"
                        (click)="group(option)"
                      >
                        {{ option.name }}
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 mb-3 btn-group" dropdown container="body">
                    <button
                      type="button"
                      class="form-control"
                      id="group-button"
                      dropdownToggle
                      aria-controls="group-dropdown-menu"
                      style="text-align: left"
                    >
                      {{ locale('locale_key.pages.datahub.filter_sort_by') }}
                      {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="group-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="group-button"
                    >
                      <ng-container *ngFor="let option of sortedByOptionsForCompany">
                        <div class="dropdown-divider" *ngIf="option.divider"></div>
                        <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12 mb-3 btn-group" dropdown container="body" [insideClick]="true">
                    <button
                      type="button"
                      class="form-control filter-button"
                      id="filter-button"
                      dropdownToggle
                      aria-controls="filter-dropdown-menu"
                      style="text-align: left"
                      (click)="resetCustomFiltersSearch()"
                    >
                      <p class="d-inline m-0">
                        <i class="la la-filter"></i>
                        {{ locale('locale_key.pages.datahub.filter_dropdown') }}
                      </p>
                      <p
                        class="text-muted filter-text text-truncate col-7 m-0"
                        *ngIf="customMultipleFiltersForCompany.length > 0"
                      >
                        :
                        <ng-container *ngFor="let filter of customMultipleFiltersForCompany; let first = first">
                          <ng-container *ngIf="!first">,</ng-container>
                          {{ filter.name }}
                        </ng-container>
                      </p>
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="filter-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="filter-button"
                    >
                      <div class="accordion-body px-0 pt-0">
                        <div class="bg-light-gray col pt-3">
                          <div class="d-flex flex-row justify-content-between px-2">
                            <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                            <a class="underline" (click)="clearAllFilters()">
                              {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                            </a>
                          </div>
                          <div class="row px-2">
                            <i class="text-muted" *ngIf="customMultipleFiltersForCompany.length == 0">
                              {{ locale('locale_key.pages.datahub.filter_none') }}
                            </i>
                            <p class="text-muted text-truncate" *ngIf="customMultipleFiltersForCompany.length > 0">
                              <ng-container *ngFor="let filter of customMultipleFiltersForCompany; let first = first">
                                <ng-container *ngIf="!first">,</ng-container>
                                {{ filter.name }}
                              </ng-container>
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="accordion" id="accordionExample1">
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  <span class="cursor dark-text">
                                    {{ locale('locale_key.general.company_information.industry') }}
                                  </span>
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body scroll" style="max-height: 20rem">
                                  <div class="row">
                                    <div class="col-md-6 col-lg-6 mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="{{ locale('locale_key.general.search') }}"
                                        (input)="filterIndustries(industrySearch.value)"
                                        #industrySearch
                                      />
                                    </div>
                                    <div class="col-md-6 col-lg-6 mb-3 text-end">
                                      <a class="underline" (click)="selectAll('industryCheckbox')">
                                        {{ locale('locale_key.general.action.select_all') }}
                                      </a>
                                      <a class="underline" (click)="deselectAll('industryCheckbox')">
                                        {{ locale('locale_key.general.action.deselect_all') }}
                                      </a>
                                    </div>
                                  </div>
                                  <div *ngFor="let industry of filteredIndustries; let i = index">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      class="industryCheckbox"
                                      id="industry{{ i }}"
                                      [checked]="isCustomFilterSelected(industry)"
                                      (change)="changeCustomFilters($event, industry)"
                                    />
                                    <label>{{ industry }}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll" style="max-height: 65vh" *ngIf="!groupedBy">
                  <a
                    class="accordion accordion-button accordion-icon-rotate m-auto row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
                    id="companiesHeader"
                    data-bs-toggle="collapse"
                    href="#companies"
                    aria-expanded="true"
                    attr.aria-controls="companies"
                  >
                    <div class="flex-grow-1">
                      <h5 class="text-truncate dark-text d-inline">
                        {{ locale('locale_key.pages.datahub.data_hub_home.table_header.all_companies') }}
                      </h5>
                    </div>
                  </a>
                  <div
                    id="companies"
                    role="tabpanel"
                    attr.aria-labelledby="companiesHeader"
                    class="border show"
                    style="overflow: hidden !important; background-color: var(--complementary-color1)"
                  >
                    <div class="card-content" style="overflow-y: hidden !important">
                      <div class="card-body px-2" style="overflow-y: hidden !important">
                        <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                          <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                            <div class="col-6 d-flex flex-row my-auto ps-0">
                              <span
                                class="my-auto text-truncate bold dark-text"
                                [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                              >
                                {{ locale('locale_key.pages.datahub.data_hub_home.table_header.company_name') }}
                              </span>
                            </div>
                            <div class="col-6 d-none d-sm-block d-flex flex-row my-auto ps-0">
                              <span
                                class="my-auto text-truncate bold dark-text"
                                [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                              >
                                {{ locale('locale_key.general.company_information.industry') }}
                              </span>
                            </div>
                          </div>
                          <ng-container *ngFor="let company of filteredCompanies">
                            <div
                              class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                              [ngClass]="{ selected: selectedCompany == company }"
                              (click)="selectCompany(company)"
                            >
                              <div class="col-6 d-flex flex-row my-auto ps-0 truncate">
                                <h5
                                  class="text-bold-400 my-auto text-wrap"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ company.supplierCompanyname }}
                                </h5>
                              </div>
                              <div class="col-6 d-none d-sm-block d-flex flex-row my-auto ps-0 truncate">
                                <h5
                                  class="text-bold-400 my-auto text-wrap"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ company.supplierIndustry }}
                                </h5>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll" style="max-height: 65vh" *ngIf="groupedBy">
                  <div class="mb-3" *ngFor="let group of filteredGroupedCompanies; let i = index">
                    <a
                      class="accordion accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
                      id="companiesHeader{{ i }}"
                      data-bs-toggle="collapse"
                      href="#companies{{ i }}"
                      aria-expanded="true"
                      attr.aria-controls="companies{{ i }}"
                    >
                      <div class="col-xl-11 col-lg-11 col-md-11 col-10">
                        <h5 class="text-truncate dark-text d-inline">{{ group.name }}</h5>
                      </div>
                      <div class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-0">
                        <a class="p-0 my-auto ms-auto float-end" style="max-height: 75px">
                          <i class="icon float-end me-4"></i>
                        </a>
                      </div>
                    </a>
                    <div
                      id="companies{{ i }}"
                      role="tabpanel"
                      attr.aria-labelledby="companiesHeader{{ i }}"
                      class="border show"
                      style="overflow: hidden !important; background-color: var(--complementary-color1)"
                    >
                      <div class="card-content" style="overflow-y: hidden !important">
                        <div class="card-body px-2" style="overflow-y: hidden !important">
                          <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                            <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                              <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                <span
                                  class="my-auto text-truncate bold dark-text"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.company_name') }}
                                </span>
                              </div>
                              <div
                                class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0 d-none d-sm-flex"
                              >
                                <span
                                  class="my-auto text-truncate bold dark-text"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ locale('locale_key.general.company_information.industry') }}
                                </span>
                              </div>
                            </div>
                            <ng-container *ngFor="let company of group.companies">
                              <div
                                class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                                [ngClass]="{ selected: selectedCompany == company }"
                                (click)="selectCompany(company)"
                              >
                                <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                  <h5
                                    class="text-bold-400 my-auto text-wrap"
                                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                  >
                                    {{ company.supplierCompanyname }}
                                  </h5>
                                </div>
                                <div
                                  class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0 d-none d-sm-flex"
                                >
                                  <h5
                                    class="text-bold-400 my-auto text-truncate"
                                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                  >
                                    {{ company.supplierIndustry }}
                                  </h5>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="datahubService.isFilter('dataReporter')">
            <div class="card-content">
              <div class="card-body p-3">
                <p class="h6">{{ locale('locale_key.pages.datahub.data_hub_home.click_on_data_reporter') }}</p>
                <div class="row">
                  <div class="col-md-6 col-lg-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ locale('locale_key.general.search') }}"
                      #searchCotact
                      (input)="applySearchFilterContact(searchCotact.value)"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4 mb-3 btn-group" dropdown container="body">
                    <button
                      type="button"
                      class="form-control"
                      id="group-button"
                      dropdownToggle
                      aria-controls="group-dropdown-menu"
                      style="text-align: left"
                    >
                      {{ locale('locale_key.pages.datahub.filter_group_by') }}:
                      {{ groupedBy ? groupedBy.name : locale('locale_key.pages.datahub.filter_none') }}
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="group-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="group-button"
                    >
                      <a class="dropdown-item" (click)="groupedBy = null">
                        {{ locale('locale_key.pages.datahub.filter_none') }}
                      </a>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item"
                        *ngFor="let option of groupedByOptionsForContact"
                        (click)="group(option)"
                      >
                        {{ option.name }}
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 mb-3 btn-group" dropdown container="body">
                    <button
                      type="button"
                      class="form-control"
                      id="group-button"
                      dropdownToggle
                      aria-controls="group-dropdown-menu"
                      style="text-align: left"
                    >
                      {{ locale('locale_key.pages.datahub.filter_sort_by') }}
                      {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="group-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="group-button"
                    >
                      <ng-container *ngFor="let option of sortedByOptionsForContact">
                        <div class="dropdown-divider" *ngIf="option.divider"></div>
                        <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 mb-3 btn-group" dropdown container="body" [insideClick]="true">
                    <button
                      type="button"
                      class="form-control filter-button"
                      id="filter-button"
                      dropdownToggle
                      aria-controls="filter-dropdown-menu"
                      style="text-align: left"
                      (click)="resetCustomFiltersSearch()"
                    >
                      <p class="d-inline m-0">
                        <i class="la la-filter"></i>
                        {{ locale('locale_key.pages.datahub.filter_dropdown') }}
                      </p>
                      <p
                        class="text-muted filter-text text-truncate col-7 m-0"
                        *ngIf="customMultipleFiltersForDO.length > 0 || selectedContactType"
                        title="{{ getCustomFilterTextContact() }}"
                      >
                        :{{ getCustomFilterTextContact() }}
                      </p>
                    </button>
                    <div
                      class="ms-3 dropdown-menu"
                      id="filter-dropdown-menu"
                      *dropdownMenu
                      role="menu"
                      aria-labelledby="filter-button"
                    >
                      <div class="accordion-body px-0 pt-0">
                        <div class="bg-light-gray col pt-3">
                          <div class="d-flex flex-row justify-content-between px-2">
                            <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                            <a class="underline" (click)="clearAllFilters()">
                              {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                            </a>
                          </div>
                          <div class="row px-2">
                            <i
                              class="text-muted"
                              *ngIf="customMultipleFiltersForDO.length == 0 && !selectedContactType"
                            >
                              {{ locale('locale_key.pages.datahub.filter_none') }}
                            </i>
                            <p
                              class="text-muted text-truncate"
                              *ngIf="customMultipleFiltersForDO.length > 0 || selectedContactType"
                            >
                              {{ getCustomFilterTextContact() }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                          <div class="d-flex flex-row">
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                [(ngModel)]="selectedContactType"
                                (change)="applyFilters()"
                                name="status"
                                value="INTERNAL"
                                id="internal"
                              />
                              <label class="form-check-label" for="completed">
                                {{ locale('locale_key.pages.datahub.data_hub_home.filter_internal_contacts') }}
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                [(ngModel)]="selectedContactType"
                                (change)="applyFilters()"
                                name="status"
                                value="EXTERNAL"
                                id="external"
                              />
                              <label class="form-check-label" for="inProgress">
                                {{ locale('locale_key.pages.datahub.data_hub_home.filter_external_contacts') }}
                              </label>
                            </div>
                          </div>
                          <div class="d-flex flex-row">
                            <a class="underline" (click)="selectedContactType = null; applyFilters()">
                              {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                            </a>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="accordion" id="accordionExample1">
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  <span class="cursor">{{ locale('locale_key.pages.datahub.button.company') }}</span>
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                data-bs-parent="#accordionExample1"
                              >
                                <div class="accordion-body scroll" style="max-height: 20rem">
                                  <div class="row">
                                    <div class="col-md-6 col-lg-6 mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="{{ locale('locale_key.general.search') }}"
                                        (input)="filterCompany(companySearch.value)"
                                        #companySearch
                                      />
                                    </div>
                                    <div class="col-md-6 col-lg-6 mb-3 text-end">
                                      <a class="underline" (click)="selectAll('companyCheckbox')">
                                        {{ locale('locale_key.general.action.select_all') }}
                                      </a>
                                      <a class="underline" (click)="deselectAll('companyCheckbox')">
                                        {{ locale('locale_key.general.action.deselect_all') }}
                                      </a>
                                    </div>
                                  </div>
                                  <div *ngFor="let company of filteredCompanies; let i = index">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      class="companyCheckbox"
                                      id="company{{ i }}"
                                      [checked]="isCustomFilterSelected(company)"
                                      (change)="changeCustomFilters($event, company)"
                                    />
                                    <label>{{ company.supplierCompanyname }}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll" style="max-height: 65vh" *ngIf="!groupedBy">
                  <a
                    class="accordion accordion-button accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
                    id="companiesHeader"
                    data-bs-toggle="collapse"
                    href="#companies"
                    aria-expanded="true"
                    attr.aria-controls="companies"
                  >
                    <div class="flex-grow-1">
                      <h5 class="text-truncate dark-text d-inline">
                        {{ locale('locale_key.general.user_role.data_reporters') }}
                      </h5>
                    </div>
                  </a>
                  <div
                    id="companies"
                    role="tabpanel"
                    attr.aria-labelledby="companiesHeader"
                    class="border show"
                    style="overflow: hidden !important; background-color: var(--complementary-color1)"
                  >
                    <div class="card-content" style="overflow-y: hidden !important">
                      <div class="card-body px-2" style="overflow-y: hidden !important">
                        <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                          <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                            <div class="col-sm-6 col-12 d-flex flex-row my-auto ps-0">
                              <span
                                class="my-auto text-truncate bold dark-text"
                                [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                              >
                                {{ locale('locale_key.pages.datahub.data_hub_home.data_reporter_name') }}
                              </span>
                            </div>
                            <div class="col-6 d-none d-sm-block d-flex flex-row my-auto ps-0">
                              <span
                                class="my-auto text-truncate bold dark-text"
                                [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                              >
                                {{ locale('locale_key.pages.datahub.data_hub_home.table_header.company_name') }}
                              </span>
                            </div>
                          </div>
                          <ng-container *ngFor="let contact of filteredContacts">
                            <div
                              class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                              [ngClass]="{ selected: selectedContact == contact }"
                              (click)="selectContact(contact)"
                            >
                              <div class="col-sm-6 col-12 d-flex flex-row my-auto ps-0">
                                <h5
                                  class="text-bold-400 my-auto text-wrap"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ contact.getName() }}
                                </h5>
                              </div>
                              <div class="col-6 d-none d-sm-block d-flex flex-row my-auto ps-0">
                                <h5
                                  class="text-bold-400 my-auto text-truncate"
                                  [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                >
                                  {{ contact.getCompanyName(stateService.activeWorkspace) }}
                                </h5>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll" style="max-height: 65vh" *ngIf="groupedBy">
                  <div class="mb-3" *ngFor="let group of filteredGroupedContacts; let i = index">
                    <a
                      class="accordion accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t px-2"
                      id="contactsHeader{{ i }}"
                      data-bs-toggle="collapse"
                      href="#contacts{{ i }}"
                      aria-expanded="true"
                      attr.aria-controls="contacts{{ i }}"
                    >
                      <div class="col-xl-11 col-lg-11 col-md-11 col-10">
                        <h5 class="text-truncate dark-text d-inline">{{ group.name }}</h5>
                      </div>
                      <div class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-0">
                        <a class="p-0 my-auto ms-auto float-end" style="max-height: 75px">
                          <i class="icon float-end me-4"></i>
                        </a>
                      </div>
                    </a>
                    <div
                      id="contacts{{ i }}"
                      role="tabpanel"
                      attr.aria-labelledby="contactsHeader{{ i }}"
                      class="border show"
                      style="overflow: hidden !important; background-color: var(--complementary-color1)"
                    >
                      <div class="card-content" style="overflow-y: hidden !important">
                        <div class="card-body px-2" style="overflow-y: hidden !important">
                          <div class="border-l border-r border-t m-0 p-0" style="overflow: hidden !important">
                            <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white my-auto">
                              <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                <span class="my-auto text-truncate bold dark-text">
                                  {{ locale('locale_key.pages.datahub.data_hub_home.data_reporter_name') }}
                                </span>
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                <span class="my-auto text-truncate bold dark-text">
                                  {{ locale('locale_key.pages.datahub.data_hub_home.table_header.company_name') }}
                                </span>
                              </div>
                            </div>
                            <ng-container *ngFor="let contact of group.contacts">
                              <div
                                class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                                [ngClass]="{ selected: selectedContact == contact }"
                                (click)="selectContact(contact)"
                              >
                                <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                  <h5
                                    class="text-bold-400 my-auto text-truncate"
                                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                  >
                                    {{ contact.getName() }}
                                  </h5>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-10 d-flex flex-row my-auto ps-0">
                                  <h5
                                    class="text-bold-400 my-auto text-truncate"
                                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                                  >
                                    {{ contact.getCompanyName(stateService.activeWorkspace) }}
                                  </h5>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-xl-6 col-12">
          <div class="loader-wrapper mt-5" *ngIf="filteringData">
            <div class="loader-container">
              <div class="ball-grid-pulse loader-primary">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!filteringData">
            <div class="row ps-5">
              <p class="header-text-blue ps-3 pt-2" *ngIf="datahubService.selectedItemToFilter">
                {{ locale('locale_key.pages.datahub.data_hub_home.data_related_to') }}:
                <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataCategory')">
                  {{ taxonomyName() }}
                </span>
                <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataRequest')">
                  {{ datahubService.selectedItemToFilter['title'] }}
                </span>
                <span class="bold header-text-blue" *ngIf="datahubService.isFilter('company')">
                  {{ datahubService.selectedItemToFilter['supplierCompanyname'] }}
                </span>
                <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataReporter')">
                  {{ getContactName() }}
                </span>
              </p>
              <p class="header-text-blue ps-2" *ngIf="!datahubService.selectedItemToFilter">
                {{ locale('locale_key.pages.datahub.data_hub_home.data_related_to') }}:
                <i class="text-secondary">{{ locale('locale_key.pages.datahub.data_hub_home.select_from_list') }}</i>
              </p>
            </div>
            <div
              class="filtered h-100 d-flex flex-column justify-xl-content-center justify-content-start align-content-center"
            >
              <div
                class="d-flex flex-xl-row flex-column p-1 w-100"
                style="height: 390px"
                *ngIf="datahubService.selectedItemToFilter"
                [ngClass]="{ 'my-auto': screenSize.isXLargeSize() }"
              >
                <div class="col-xl-7 col-12 d-flex flex-column justify-content-around px-5">
                  <div
                    class="d-flex flex-row justify-content-between p-2 mt-2 border bg-white max-h"
                    [ngClass]="{ noData: noOfDOSubmissions == 0 }"
                  >
                    <i class="la la-envelope-o my-auto mx-2" style="font-size: 4rem"></i>
                    <h6 class="me-auto my-auto">
                      {{ locale('locale_key.pages.datahub.data_hub_home.table_header.data_reporter_submissions') }}
                    </h6>
                    <div class="icon-circle-dark my-auto me-2">
                      <span class="white">{{ noOfDOSubmissions }}</span>
                    </div>
                    <i
                      class="la la-arrow-right my-auto py-auto thin-border"
                      [ngClass]="{ 'btn-hover cursor': noOfDOSubmissions != 0 }"
                      (click)="noOfDOSubmissions == 0 ? null : setView('doSubmissionsView')"
                    ></i>
                  </div>
                  <div
                    class="line-down-data-cat"
                    *ngIf="
                      !screenSize.isSmallerSize() &&
                      !screenSize.isXSmallSize() &&
                      !screenSize.isMediumSize() &&
                      !screenSize.isLargeSize()
                    "
                  >
                    <div class="arrow-down-data-cat"></div>
                  </div>
                  <div class="m-0 p-0 w-100 m-auto text-center">
                    <i class="la la-arrow-down blue m-auto fs-4 text-center"></i>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between p-2 mt-2 border bg-white max-h"
                    [ngClass]="{ noData: noOfFiles == 0 }"
                  >
                    <i class="la la-file my-auto m-2 py-1" style="font-size: 3.5rem"></i>
                    <h6 class="me-auto my-auto">
                      {{ locale('locale_key.pages.datahub.data_hub_home.table_header.files') }}
                    </h6>
                    <div class="icon-circle-dark my-auto me-2">
                      <span class="white">{{ noOfFiles }}</span>
                    </div>
                    <i
                      class="la la-arrow-right my-auto py-auto thin-border"
                      [ngClass]="{ 'btn-hover cursor': noOfFiles != 0 }"
                      (click)="noOfFiles != 0 ? setView('filesPortalView') : null"
                    ></i>
                  </div>
                  <div
                    class="d-flex flex-row border p-2 mt-2 bg-white max-h"
                    [ngClass]="{ noData: noOFDirectEntries == 0 }"
                  >
                    <i class="la la-keyboard-o my-auto mx-2" style="font-size: 4rem"></i>
                    <h6 class="me-auto my-auto">
                      {{ locale('locale_key.pages.datahub.data_hub_home.table_header.direct_data_entries') }}
                    </h6>
                    <div class="icon-circle-dark my-auto me-2">
                      <span class="white">{{ noOFDirectEntries }}</span>
                    </div>
                    <i
                      class="la la-arrow-right my-auto py-auto thin-border"
                      [ngClass]="{ 'btn-hover cursor': noOFDirectEntries != 0 }"
                      (click)="noOFDirectEntries != 0 ? setView('dataEntriesPortalView') : null"
                    ></i>
                  </div>
                  <div
                    class="line-straight-data-cat"
                    *ngIf="
                      !screenSize.isSmallerSize() &&
                      !screenSize.isXSmallSize() &&
                      !screenSize.isMediumSize() &&
                      !screenSize.isLargeSize()
                    "
                  >
                    <div class="arrow-right-data-cat"></div>
                  </div>
                  <div
                    class="d-flex flex-row justify-content-between p-2 mt-2 border bg-white max-h"
                    [ngClass]="{ noData: noOfAutomatedFeeds == 0 }"
                  >
                    <i class="la la-desktop my-auto mx-2" style="font-size: 4rem"></i>
                    <h6 class="me-auto my-auto">
                      {{ locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed') }}
                    </h6>
                    <div class="icon-circle-dark my-auto me-2">
                      <span class="white">{{ noOfAutomatedFeeds }}</span>
                    </div>
                    <i
                      class="la la-arrow-right my-auto py-auto thin-border"
                      [ngClass]="{ 'btn-hover cursor': noOfAutomatedFeeds != 0 }"
                      (click)="noOfAutomatedFeeds != 0 ? setView('automatedFeedPortalView') : null"
                    ></i>
                  </div>
                  <div
                    class="line-up-data-cat"
                    *ngIf="
                      !screenSize.isSmallerSize() &&
                      !screenSize.isXSmallSize() &&
                      !screenSize.isMediumSize() &&
                      !screenSize.isLargeSize()
                    "
                  >
                    <div class="arrow-up-data-cat"></div>
                  </div>
                </div>
                <div class="col-xl-4 col-12 m-auto">
                  <div
                    class="d-flex flex-row justify-content-center align-center border my-auto p-3 pe-2 bg-white"
                    [ngClass]="{
                      'table-height': screenSize.isXLargeSize(),
                      'ps-3': screenSize.isXLargeSize(),
                      'mx-5 mt-2': !screenSize.isXLargeSize(),
                      noData: noOfMasterTable == 0
                    }"
                  >
                    <img
                      class="brand-logo img-responsive img-fluid my-auto me-2"
                      src="/assets/img/logos/Logo-Sustainlab.png"
                      style="height: 3rem"
                    />
                    <h6 class="my-auto me-auto">
                      {{ locale('locale_key.pages.datahub.data_hub_home.button.master_tables') }}
                    </h6>
                    <div class="icon-circle-dark my-auto me-2">
                      <span class="white">{{ noOfMasterTable }}</span>
                    </div>
                    <i
                      class="la la-arrow-right my-auto py-auto thin-border"
                      [ngClass]="{ 'btn-hover cursor': noOfMasterTable != 0 }"
                      (click)="noOfMasterTable != 0 ? setView('masterTablePortalView') : null"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
