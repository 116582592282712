import { Component, Input, TemplateRef } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe'
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe'
import { QUESTION_TYPE } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe'
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe'
import { AttachmentFe } from 'src/app/model/details/AttachmentFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { RequestServiceFe } from 'src/app/services/RequestServiceFe'
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { RequestEntryDataGridIntegrationService } from '../RequestEntryDataGridIntegrationService'
import { RequestFe } from 'src/app/model/data-suppliers/request/RequestFe'
import { QuestionAttachmentFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe'
import { FileTypeFe } from 'src/app/model/file/FileTypeFe'
import { AbstractActivityFe } from 'src/app/model/data-suppliers/timeline/AbstractActivityFe'
import { RejectSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe'
import { AcceptSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe'
import { QUESTIONNAIRE_STATUS } from 'src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe'
import { AbstractActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/AbstractActivity_AddFe'
import { AcceptSubmissionActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/AcceptSubmissionActivity_AddFe'
import { RejectSubmissionActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/RejectSubmissionActivity_AddFe'
import { SectionFe } from 'src/app/model/data-suppliers/request/questionnaire/SectionFe'
import { AnswerFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { ConnectSectionsActivityFe } from 'src/app/model/data-suppliers/timeline/ConnectSectionsActivityFe'
import { DataGridServiceInterface } from '../../projects/data-grid-ui/service/DataGridServiceInterface'
import { AnswerAttachmentFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerAttachmentFe'
import { DatahubService } from '../../data-hub/DatahubService'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { Router } from '@angular/router'
import { NoUnit } from '../../unit-systems/unit-selector/unit-selector.component'
import { UnitUtilFe } from 'src/app/utils/UnitUtilFe'

@Component({
  selector: 'request-answer',
  templateUrl: './request-answer.component.html',
  styleUrls: ['./request-answer.component.scss', '../../common/data-suppliers.component.scss']
})
export class RequestAnswerComponent extends AbstractLanguageComponent {
  @Input() requestGroup: RequestGroupFe
  @Input() request: RequestFe
  answer: AbstractActivityFe
  message = null
  loadingInProgress = false
  menuCollapsed
  screenSize
  selectedSection: SectionFe
  selectedQuestion: QuestionFe
  taxonomyInfo

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    private tableStorageService: TableStorageServiceFe,
    public loginService: LoginServiceFe,
    private domSanitizer: DomSanitizer,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    public datahubService: DatahubService,
    private router: Router
  ) {
    super(languageService)
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnChanges() {
    this.taxonomyInfo = (await this.stateService.getTaxonomyInfos()).depTaxonomy
    this.setAnswer()
  }

  setAnswer() {
    let answer = this.request.timelineItems
      .slice()
      .reverse()
      .find((a) => a instanceof SubmitAnswersActivityFe)
    this.answer = answer
  }

  async openTableFile(question: QuestionDataTableFe) {
    let answer = question.answer
    answer.isLoading = true
    let service = new RequestEntryDataGridIntegrationService(new TableDataFe())
    if (!answer.dataTable) {
      let content = await this.stateService.downloadDataTableFile(
        this.stateService.activeWorkspace.companyId,
        answer.dataStorageId
      )
      service.mode = 'view'
      await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
        service.table.key = answer.id
        service.table.rows = table.rows
        service.table.rowCount = table.rows.length
        service.table.dataSchema = table.dataSchema
        answer['dataTable'] = table
        answer['dataGridService'] = service
        service.observer?.renderNewDataTable()
        this.openAttachment(answer.id, service, null, this.request.questionnaire.relatedActivity.submissionDate)
      })
    } else {
      this.openAttachment(
        answer.id,
        answer.dataGridService,
        null,
        this.request.questionnaire.relatedActivity.submissionDate
      )
    }
    answer.isLoading = false
  }

  openAttachment(
    id: string,
    dataGridService: DataGridServiceInterface | null,
    file: FileDataFe | null,
    submissionDate: Date
  ) {
    let attachment = new AttachmentFe(id, dataGridService, file, submissionDate)
    if (dataGridService) {
      attachment.isTable = true
      attachment.isFile = false
    } else {
      attachment.isFile = true
      attachment.isTable = false
    }
    this.displayService.openAttachment(attachment)
  }

  async export(question: QuestionAttachmentFe | QuestionDataTableFe, exportType: string) {
    question.answer.isLoading = true
    let name = `${this.request.questionnaire.relatedActivity.requestGroupLabel}_${this.request.questionnaire.relatedActivity.submitterFirstName}`
    if (question instanceof QuestionAttachmentFe) {
      name = this.getAttachmentTitle(question)
      if (!question.answer.file) {
        let file = await this.downloadFileFromServer(question.answer.attachmentStorageId, name)
        file.label = this.getAttachmentTitle(question)
        question.answer.file = file
      }
      question.answer.file.setExportType(exportType)
      question.answer.file.saveFile()
    } else {
      if (!question.answer.dataTable) {
        let service = new RequestEntryDataGridIntegrationService(new TableDataFe())
        let content = await this.stateService.downloadDataTableFile(
          this.stateService.activeWorkspace.companyId,
          question.answer.dataStorageId
        )
        service.mode = 'view'
        await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
          service.table.key = question.answer.id
          service.table.rows = table.rows
          service.table.rowCount = table.rows.length
          service.table.dataSchema = table.dataSchema
          question.answer['dataTable'] = table
          question.answer['dataGridService'] = service
          service.downloadSheet(name, exportType)
        })
      } else {
        ;(question.answer.dataGridService as RequestEntryDataGridIntegrationService).downloadSheet(name, exportType)
      }
    }
    question.answer.isLoading = false
  }

  async openFile(question: QuestionAttachmentFe) {
    let answer = question.answer
    answer.isLoading = true
    if (!answer.file) {
      let name = this.getAttachmentTitle(question)
      let file = await this.downloadFileFromServer(answer.attachmentStorageId, name)
      file.label = this.getAttachmentTitle(question)
      answer.file = file
    }
    answer.isLoading = false
    this.openAttachment(answer.id, null, answer.file, this.request.questionnaire.relatedActivity.submissionDate)
  }

  async downloadFileFromServer(fileId: string, fileName: string): Promise<FileDataFe> {
    let fileType = this.getFileExtension(fileId)
    let file = FileDataFe.fromBlob(`${fileName}.${fileType.extension}`)
    file.loadingFileInProgress = true
    file.id = fileId
    let content = await this.stateService.downloadAttachmentFile(this.stateService.activeWorkspace.companyId, file.id)
    file.addBlobContent(content, this.domSanitizer)
    file.type = fileType
    file.loadingFileInProgress = false
    return file
  }

  getFileExtension(fileId: string): FileTypeFe {
    return FileTypeFe.fromName(fileId)
  }

  getLastActionableActivity(): AbstractActivityFe {
    let activity = this.request.timelineItems
      .slice()
      .reverse()
      .find(
        (item) =>
          item instanceof SubmitAnswersActivityFe ||
          item instanceof RejectSubmissionActivityFe ||
          item instanceof AcceptSubmissionActivityFe
      )
    return activity
  }

  async handleData(type: string) {
    this.loadingInProgress = true
    let addActivity: AbstractActivity_AddFe
    let activity = this.request.questionnaire.relatedActivity

    if (type === 'ACCEPT') {
      addActivity = new AcceptSubmissionActivity_AddFe(
        IdUtil.next(),
        activity.id,
        this.message,
        activity.requestId,
        activity.requestGroupId,
        activity.requestGroupLabel
      )
      await this.stateService.addRequestTimelineItem(addActivity, activity.requestGroupId, activity.requestId)
      this.request.questionnaire.status = QUESTIONNAIRE_STATUS.APPROVED
      this.request.questionnaire.sections.forEach((section) => (section.isConnected = true))
    } else {
      addActivity = new RejectSubmissionActivity_AddFe(
        IdUtil.next(),
        activity.id,
        this.message,
        activity.requestId,
        activity.requestGroupId,
        activity.requestGroupLabel
      )
      await this.stateService.addRequestTimelineItem(addActivity, activity.requestGroupId, activity.requestId)
      this.request.questionnaire.status = QUESTIONNAIRE_STATUS.REJECTED
    }
    this.requestGroup.setStatus()
    this.loadingInProgress = false
    this.message = null
    this.closeModal()
  }

  openModal(template: TemplateRef<any>, size: string = 'modal-md') {
    this.modalRef = this.modalService.show(template, { class: size, backdrop: 'static', keyboard: true })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
    this.message = null
  }

  async disconnectSection() {
    try {
      this.loadingInProgress = true
      await this.requestService.disconnectSections(this.request, [this.selectedSection])
      this.closeModal()
      this.loadingInProgress = false
    } catch (err) {
      this.closeModal()
      this.loadingInProgress = false
    }
  }

  async connectSection() {
    try {
      this.loadingInProgress = true
      await this.requestService.connectSection(this.request, this.selectedSection)
      this.closeModal()
      this.loadingInProgress = false
    } catch (err) {
      this.closeModal()
      this.loadingInProgress = false
    }
  }

  showMappingDetails(section, question, mappingDetails) {
    this.selectedSection = section
    this.selectedQuestion = question
    this.openModal(mappingDetails, 'modal-md')
  }

  getAttachmentTitle(question: QuestionAttachmentFe) {
    let title = AnswerAttachmentFe.getTitle(
      this.requestGroup.title,
      question.question,
      this.request.getAssigneeName(),
      this.answer.submissionDate,
      question.answer.attachmentLabel
    )
    return title
  }

  openMasterTable(section: SectionFe) {
    if (section.taxonomyKey) {
      let rowId = `${this.request.id}.${section.id}`
      if (section.entity.dataGridService) {
        section.entity.dataGridService.setSelectedRowId(rowId)
      } else {
        section.entity.selectedRowId = rowId
      }
      this.datahubService.showEntityMasterTable(section.entity)
      this.router.navigate([RoutesFe.DATA_HUB.fullPath()])
    }
  }

  getUnitString(unit) {
    return UnitUtilFe.getUnitString(unit)
  }
}
