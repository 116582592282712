import { AbstractEmissionFactorFe, EmissionFactorTypeFe } from './AbstractEmissionFactorFe'
import { CustomEmissionFactorFe } from './CustomEmissionFactorFe'
import { GlobalEmissionFactorFe } from './GlobalEmissionFactorFe'

export class EmissionFactorConversionFe {
  public static fromTransfer(transfer: any): AbstractEmissionFactorFe {
    if (transfer.type == EmissionFactorTypeFe.CUSTOM_EMISSION_FACTOR) {
      return CustomEmissionFactorFe.fromTransfer(transfer)
    } else if (transfer.type == EmissionFactorTypeFe.GLOBAL_EMISSION_FACTOR) {
      return GlobalEmissionFactorFe.fromTransfer(transfer)
    }
  }
}
