<!-- VIEW MODE -->
<ng-container *ngIf="activeDrDatapointsPage == DR_DATAPOINTS_PAGES.VIEW">
  <csrd-dr-datapoints-view
    [activeTopic]="activeTopic"
    [activeDr]="activeDr"
    [activeEsrs]="activeEsrs"
    [activeCsrdProject]="activeCsrdProject"
    (switchDrDatapointsPage)="switchDrDatapointsPage($event)"
  ></csrd-dr-datapoints-view>
</ng-container>

<!-- EDIT MODE -->
<ng-container *ngIf="activeDrDatapointsPage == DR_DATAPOINTS_PAGES.EDIT">
  <csrd-dr-datapoints-edit
    [activeTopic]="activeTopic"
    [activeDr]="activeDr"
    [activeEsrs]="activeEsrs"
    [activeCsrdProject]="activeCsrdProject"
    (switchActiveDrTab)="switchActiveDrTab.emit($event)"
    (switchDrDatapointsPage)="switchDrDatapointsPage($event)"
    (switchProjectsPage)="goSwitchProjectsPage()"
    (switchCsrdProjectPage)="goSwitchCsrdProjectPage()"
  ></csrd-dr-datapoints-edit>
</ng-container>
