import { Component, Input, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { ActionFe, ColumnModifiedSubjectFe } from '../../model/ColumnModifiedSubjectFe'
import { DataGridColumnSchema } from '../../model/DataGridColumnSchema'
import { DataGridStatus } from '../../model/DataGridStatus'
import { DataService } from '../../service/data.service'

@Component({
  selector: 'modify-column',
  templateUrl: './modifyColumnModal.component.html'
})
export class ModifyColumnModalComponent {
  column = new DataGridColumnSchema()
  disableDataType = false
  @Input() mode = 'add'
  unit = ''

  public contentAdded = new Subject<boolean>()
  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.disableDataType = this.column.status == DataGridStatus.STORED || this.column.status == DataGridStatus.MODIFIED
  }

  modifyCol(): void {
    if (!(this.column.label === '' || (this.column.label === 'INTEGER' && this.unit === ''))) {
      if (this.mode == 'add') {
        let subject = new ColumnModifiedSubjectFe(ActionFe.ADD, this.column)
        this.dataService.columnModified.next(subject)
        this.closeModal()
        this.modalService.hide(2)
      } else {
        let subject = new ColumnModifiedSubjectFe(ActionFe.EDIT, this.column)
        this.dataService.columnModified.next(subject)
        this.closeModal()
        this.modalService.hide(2)
      }
    }
  }

  closeModal(): void {
    this.modalRef.hide()
    document.body.classList.remove('modal-open')
  }
}
