import { LanguageService } from 'src/app/services/LanguageServiceFe'

export abstract class AbstractEntityFe {
  public key: string
  public label: { [key: string]: string } = {}
  public icon: string
  public color: string

  private languageService: LanguageService

  constructor(languageService: LanguageService) {
    this.languageService = languageService
  }

  public getLabel(): string {
    const langCode = this.languageService.getDisplayActiveLanguage()
    return this.label[langCode] || this.label['en']
  }

  toJSON(): any {
    const { languageService, getLabel, ...data } = this
    return data
  }
}
