import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractActivityFe } from './AbstractActivityFe'
import { ActivityTypeFe } from './ActivityTypeFe'

export class CloseRequestActivityFe extends AbstractActivityFe {
  icon = 'la la-ban'

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.CLOSE_REQUEST,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,
      requestId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.closed`
    this.homePageLocaleKeyDO = `locale_key.pages.data_request.activity.closed`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.closed_and_marked_as_complete`
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): CloseRequestActivityFe {
    let activity = new CloseRequestActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      languageService
    )
    return activity
  }
}
