import { NoUnit } from '../components/unit-systems/unit-selector/unit-selector.component'

export class UnitUtilFe {
  public static getUnitString(unit) {
    if (unit === null || unit === undefined || unit === '') {
      return NoUnit.SYMBOL
    }
    return unit
  }
}
