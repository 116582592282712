import { Component, Input, Output, OnInit, ViewChild, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { Subject } from 'rxjs'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DataCategoryServiceFe } from 'src/app/services/DataCategoryServiceFe'
import { RequestServiceFe } from 'src/app/services/RequestServiceFe'
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'
import { QuestionaireCreatorMainFormComponent } from './questionaire-creator-main-form.component'

@Component({
  selector: 'questionaire-creator-full-page',
  templateUrl: './questionaire-creator-full-page.component.html',
  styleUrls: ['./questionaire-creator-full-page.component.scss', '../data-suppliers.component.scss'],
  providers: [{ provide: BsDropdownDirective }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class QuestionaireCreatorFullPageComponent extends AbstractLanguageComponent implements OnInit {
  @Input() reqGroupToBeDuplicated: RequestGroupFe
  @Input() isFromDraft = false

  @Output() close = new Subject<boolean>()
  @Output() createdRequest = new EventEmitter<RequestGroupFe>()

  @ViewChild(QuestionaireCreatorMainFormComponent) childComponent!: QuestionaireCreatorMainFormComponent

  loadingInProgress = false
  initCacheInProgress: boolean

  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  requestGroupTitle: string = ''

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left',
        actionName: 'back',
        visible: () => true,
        disabled: () => this.loadingInProgress,
        additionalClasses: 'nav-link'
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.preview'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.preview_questionnaire'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.preview_questionnaire'),
        icon: 'la la-eye',
        actionName: 'preview_questionnaire',
        visible: () => this.childComponent?.canSwitchToPreviewMode(),
        disabled: () => this.loadingInProgress
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.edit'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.edit_questionnaire'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.edit_questionnaire'),
        icon: 'la la-edit',
        actionName: 'edit_questionnaire',
        visible: () => this.childComponent?.canSwitchToEditMode(),
        disabled: () => this.loadingInProgress
      }
    ]
  ]

  constructor(
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public categoryService: DataCategoryServiceFe,
    public requestService: RequestServiceFe
  ) {
    super(languageService)
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    if (this.reqGroupToBeDuplicated) {
      this.requestGroupTitle = this.reqGroupToBeDuplicated.title
    }
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'back':
        this.childComponent.handleBackButton()
        break
      case 'preview_questionnaire':
        this.childComponent.enterQuestionnairePreviewMode()
        break
      case 'edit_questionnaire':
        this.childComponent.enterQuestionnaireEditMode()
        break
    }
  }

  handleBackButton() {
    this.close.next(true)
  }
}
