import { RowStatusFe } from 'src/app/model/schema/RowStatusFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import * as XLSX from 'xlsx'
import { AutomatedFeedInfoFe } from 'src/app/model/automatedFeed/AutomatedFeedInfoFe'
import { DataGridTableData } from '../projects/data-grid-ui/model/DataGridTableData'
import { DataGridRow } from '../projects/data-grid-ui/model/DataGridRow'
import { DataGridObserverInterface } from '../projects/data-grid-ui/service/DataGridObserverInterface'
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode'
import { DataGridServiceInterface } from '../projects/data-grid-ui/service/DataGridServiceInterface'
import { LanguageService } from 'src/app/services/LanguageServiceFe'

export class AutomatedFeedDataGridIntergationService implements DataGridServiceInterface {
  backendService: RouterFe
  automatedFeedInfo: AutomatedFeedInfoFe
  entity: EntityFe
  currentPageData: TableDataFe
  backendTableData: any
  observer: DataGridObserverInterface | undefined
  mode: string = DataGridTableMode.EDIT_TABLE_DATA
  public modified: boolean
  public isDataValid: boolean
  hasTablePagination = true
  downloading = false

  constructor(
    automatedFeedInfo: AutomatedFeedInfoFe,
    entity: EntityFe,
    backendService: RouterFe,
    public languageService: LanguageService
  ) {
    this.automatedFeedInfo = automatedFeedInfo
    this.entity = entity
    this.backendService = backendService
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode(): string {
    return this.mode
  }

  getMainTableData(): DataGridTableData {
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  hasMainTablePagination(): boolean {
    return this.hasTablePagination
  }

  async loadMainTablePage(pageSize: number = 10, pageNumber: number = 1): Promise<DataGridTableData> {
    this.automatedFeedInfo.loadInProgress = true
    this.backendTableData = await this.backendService.readAutomatedFeedTable(
      this.automatedFeedInfo.id,
      pageSize,
      pageNumber
    )
    this.currentPageData = TableDataFe.fromTransfer(
      this.backendTableData,
      this.entity,
      this.languageService.getDisplayActiveLanguage()
    )
    this.currentPageData.key = this.automatedFeedInfo.taxonomyKey
    this.automatedFeedInfo.tableData = this.currentPageData
    this.automatedFeedInfo.loadInProgress = false
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  async saveChages() {
    this.automatedFeedInfo.saveInProgress = true
    let dgmt: DataGridTableData = this.observer.getModifiedTableData()
    let modifedTable = TableDataFe.fromDataGridTableData(dgmt)
    modifedTable.name = this.automatedFeedInfo.tableData.name
    modifedTable.rows = modifedTable.rows.filter(
      (row) =>
        row.status == RowStatusFe.MODIFIED || row.status == RowStatusFe.ADDED || row.status == RowStatusFe.DELETED
    )
    try {
      let backendTableData = await this.backendService.updateStageTable(modifedTable, this.automatedFeedInfo.id)
      this.currentPageData = TableDataFe.fromTransfer(
        backendTableData,
        this.entity,
        this.languageService.getDisplayActiveLanguage()
      )
      this.automatedFeedInfo.tableData = this.currentPageData
      this.automatedFeedInfo.lastModified = new Date()
      this.automatedFeedInfo.lastModifiedString = DateUtil.toString3(this.automatedFeedInfo.lastModified)
      this.automatedFeedInfo.modified = true
      await this.observer.renderNewDataTable()
      this.automatedFeedInfo.saveInProgress = false
    } catch (err) {
      this.automatedFeedInfo.saveInProgress = false
    }
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    this.modified = modified
    this.isDataValid = isDataValid
  }

  hasDataModifiedElseWhere(): boolean {
    return false
  }

  saveReferenceTable(table: DataGridTableData): void {}
  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {}
  getReferenceTables(): DataGridTableData[] {
    return []
  }

  getNoOfMinRows(): number {
    return 5
  }

  async downloadDataSource() {
    this.downloading = true
    let fileName = `${this.automatedFeedInfo.taxonomyEntity.getLabel()}_${this.automatedFeedInfo.timeFromString}_${this.automatedFeedInfo.timeToString}.csv`
    let backendTableData = await this.backendService.readWholeStageTable(this.automatedFeedInfo.id)
    let table = TableDataFe.fromTransfer(backendTableData, this.entity, this.languageService.getDisplayActiveLanguage())

    var data = []
    data[0] = []
    for (let col of table.dataSchema) {
      data[0].push(col.label)
    }

    table.rows.forEach((r, i) => {
      data.push([])
      r.values.map((val) => data[i + 1].push(val))
    })

    const sheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet)
    await XLSX.writeFile(workbook, fileName)
    this.downloading = false
  }
}
