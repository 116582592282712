import { EntityFe } from '../taxonomy/EntityFe'
import { InsightProcessingPipelineDefFe } from './InsightProcessingPipelineDefFe'

export class InsightDefFe {
  constructor(
    public id: string,
    public title: string,
    public createdAt: Date,
    public definition: InsightProcessingPipelineDefFe
  ) {}

  public static fromTransfer(transfer: any): InsightDefFe {
    let insightDef = new InsightDefFe(
      transfer.id,
      transfer.title,
      new Date(transfer.createdAt),
      InsightProcessingPipelineDefFe.fromTransfer(transfer.definition)
    )
    return insightDef
  }
}
