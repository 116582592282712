export class IntervalFe {
  type: string
  unit: string
  value: number

  constructor(type: string, unit: string, value: number) {
    this.type = type
    this.unit = unit
    this.value = value
  }

  public static fromTransfer(transfer: any): IntervalFe {
    let interval = new IntervalFe(transfer.type, transfer.unit, transfer.value)
    return interval
  }

  toJSON(): any {
    const { ...data } = this
    return data
  }
}
