import { MathJsInstance } from 'mathjs'
import Big from 'big.js'

import {
  CloseBracketOperator,
  FixedNumberOperand,
  Formula,
  OpenBracketOperator,
  Operand,
  OperandTypes,
  TimesOperator,
  TokenTypes
} from './calculation-builder/model/CalculationBuilderModels'
import { UnitFe } from '../unit-systems/model/UnitFe'
import { EntityFe } from 'src/app/model/taxonomy/EntityFe'
import { isEmpty } from 'lodash'

export enum UNIT_SYMBOL {
  percentage = '%'
}

export class CalculationHarmonization {
  public static formulaToExpression({ formula, harmonize = false }) {
    let exp = ''
    formula.forEach((token) => {
      switch (token.type) {
        case 'operand':
          switch (token.operandType) {
            case 'selectDataInput':
              exp += ''
              break
            case 'dataPoint':
              switch (token.datapoint?.datatype) {
                case 'NUMERIC':
                  const symbol = `${this.parseUnitSymbol(token.datapoint?.unit?.symbol)}`
                  exp += `1${symbol}`
                  break
                case 'EMISSION_FACTOR':
                  const emissionFactor: any = token.datapoint?.emissionFactors[0]?.value

                  const sourceUnit = `${this.parseUnitSymbol(emissionFactor?.sourceUnit)}`

                  const conversionUnit = `${this.parseUnitSymbol(emissionFactor?.conversionUnit)}`

                  exp += `1${conversionUnit}/${sourceUnit}`
                  break
              }
              break
            case 'emissionFactor':
              const emissionFactor: any = token.emissionFactor

              const sourceUnit = `${this.parseUnitSymbol(emissionFactor?.sourceUnit)}`

              const conversionUnit = `${this.parseUnitSymbol(emissionFactor?.conversionUnit)}`

              exp += `1${conversionUnit}/${sourceUnit}`
              break
            case 'fixedNumber':
              const symbol = `${this.parseUnitSymbol(token.unit?.symbol)}`

              exp += harmonize ? `1${symbol}` : `${token.value}${symbol}`
              break
          }
          break
        case 'operator':
          switch (token.operatorType) {
            case 'control':
              exp += ''
              break
            case 'plus':
              exp += ' + '
              break
            case 'minus':
              // 1cm - 1cm = 0m, so to prevent this we use +
              exp += harmonize ? ' + ' : ' - '
              break
            case 'times':
              exp += ' * '
              break
            case 'divide':
              exp += ' / '
              break
            case 'openBracket':
              exp += '('
              break
            case 'closeBracket':
              exp += ')'
              break
          }
          break
      }
    })

    return exp
  }

  public static harmonizeFormula = ({
    tokens,
    toUnit,
    unitEvaluator,
    noUnit,
    entity
  }: {
    tokens: Formula
    toUnit: string
    unitEvaluator: MathJsInstance
    noUnit: UnitFe
    entity: EntityFe
  }) => {
    const harmonizedFormula = []
    tokens.forEach((token) => {
      // Get latest datapoint, in the event it was updated
      if (token.type == TokenTypes.operand && token.operandType == OperandTypes.dataPoint) {
        let latestDatapoint
        entity?.columns?.forEach((column) => {
          if (column.key == token.datapoint?.key) {
            latestDatapoint = column
          }
        })
        if (!isEmpty(latestDatapoint)) {
          token.datapoint = latestDatapoint
        }
      }
      harmonizedFormula.push(token)

      if (toUnit === UNIT_SYMBOL.percentage || token.type !== TokenTypes.operand) return

      if (token.datapoint?.unit?.symbol === UNIT_SYMBOL.percentage || token.unit?.symbol === UNIT_SYMBOL.percentage) {
        const percentageMultiplier: Operand = new FixedNumberOperand({ value: 0.01, unit: noUnit })
        harmonizedFormula.push(new TimesOperator(), percentageMultiplier)
      }
    })

    if (!toUnit || toUnit === UNIT_SYMBOL.percentage || !tokens.length) return harmonizedFormula

    const exp = CalculationHarmonization.formulaToExpression({ formula: tokens, harmonize: true })
    const evaluatedExp = unitEvaluator.evaluate(exp).toString()

    const evaluatedUnit = evaluatedExp.split(' ')[1]

    let conversionFactor = '1'
    // if the evaluated unit is not the same as the target unit, we need to convert
    if (evaluatedUnit !== toUnit) {
      const conversionString = `1${evaluatedUnit} to ${toUnit}`
      const conversionResult = unitEvaluator.evaluate(conversionString).toString()
      const conversionResultTokens = conversionResult.split(' ')
      // we use Big.js to handle scenarios where math.js returns values in scientific notation
      conversionFactor = new Big(conversionResultTokens[0]).toString()
    }
    // wrap formula <f> in conversion => ( <f> ) * <global conversion factor> to get final target datapoint conversion
    const conversionFactorToken = new FixedNumberOperand({ value: conversionFactor, unit: noUnit })

    harmonizedFormula.unshift(new OpenBracketOperator())
    harmonizedFormula.push(new CloseBracketOperator(), new TimesOperator(), conversionFactorToken)

    return harmonizedFormula
  }

  private static parseUnitSymbol = (symbol: string): string => {
    if (!symbol || !symbol.includes) return ''

    if (symbol.includes('/')) return (symbol = `(${symbol})`)

    return symbol === UNIT_SYMBOL.percentage ? '' : symbol
  }
}
