import { ContactInvitationFe } from './ContactInvitationFe'

export class ContactInvitationExtendedFe {
  invitation: ContactInvitationFe
  contactEmailExists: boolean

  constructor(contactEmailExists: boolean, invitation: ContactInvitationFe) {
    this.invitation = invitation
    this.contactEmailExists = contactEmailExists
  }

  public static fromTransfer(transfer: any): ContactInvitationExtendedFe {
    let invitation = ContactInvitationFe.fromTransfer(transfer.invitation)
    let invitationExtended = new ContactInvitationExtendedFe(transfer.contactEmailExists, invitation)
    return invitationExtended
  }
}
