import { AbstractInvitationFe } from '../../invitation/AbstractInvitationFe'

export class ContactInvitationFe extends AbstractInvitationFe {
  inviterCompanyId: string
  inviterCompanyName: string
  managementCompanyId: string
  managementCompanyName: string
  contactCompanyId: string
  contactCompanyName: string
  contactFirstName: string
  contactLastName: string
  contactEmail: string

  constructor(
    uuid: string,
    inviterFirstName: string,
    inviterLastName: string,
    inviterCompanyId: string,
    inviterCompanyName: string,
    managementCompanyId: string,
    managementCompanyName: string,
    contactCompanyId: string,
    contactCompanyName: string,
    contactFirstName: string,
    contactLastName: string,
    contactEmail: string,
    invitationDate: Date,
    invitationStatus: string
  ) {
    super(uuid, inviterFirstName, inviterLastName, invitationDate, invitationStatus)
    this.inviterCompanyId = inviterCompanyId
    this.inviterCompanyName = inviterCompanyName
    this.managementCompanyId = managementCompanyId
    this.managementCompanyName = managementCompanyName
    this.contactCompanyId = contactCompanyId
    this.contactCompanyName = contactCompanyName
    this.contactFirstName = contactFirstName
    this.contactLastName = contactLastName
    this.contactEmail = contactEmail
  }

  public static fromTransfer(transfer: any): ContactInvitationFe {
    let invitation = new ContactInvitationFe(
      transfer.uuid,
      transfer.inviterFirstName,
      transfer.inviterLastName,
      transfer.inviterCompanyId,
      transfer.inviterCompanyName,
      transfer.managementCompanyId,
      transfer.managementCompanyName,
      transfer.contactCompanyId,
      transfer.contactCompanyName,
      transfer.contactFirstName,
      transfer.contactLastName,
      transfer.contactEmail,
      transfer.invitationTime,
      transfer.status
    )
    return invitation
  }

  getName() {
    return `${this.contactFirstName} ${this.contactLastName}`
  }
}
