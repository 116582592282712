import { Directive, HostListener, ElementRef, Input } from '@angular/core'
import { KeyBoardService } from '../service/keyboard.service'

@Directive({
  selector: '[arrowDirective]'
})
export class ArrowDivDirective {
  isClicked = false
  constructor(
    private keyboardService: KeyBoardService,
    public element: ElementRef
  ) {}

  @HostListener('keydown', ['$event']) onKeyUp(e: any): void {
    switch (e.keyCode) {
      case 37:
        if (this.isClicked) {
          if (
            this.element.nativeElement.selectionStart === undefined ||
            this.element.nativeElement.selectionStart <= 0
          ) {
            this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'LEFT' })
            e.preventDefault()
          }
        } else {
          this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'LEFT' })
          e.preventDefault()
        }
        break

      case 38:
        this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'UP' })
        break

      case 9:
      case 39:
        if (this.isClicked) {
          if (
            this.element.nativeElement.selectionStart === undefined ||
            this.element.nativeElement.selectionStart >= this.element.nativeElement.value.length
          ) {
            this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'RIGHT' })
            e.preventDefault()
          }
        } else {
          this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'RIGHT' })
          e.preventDefault()
        }
        break

      case 13:
        if (this.isClicked) {
          this.keyboardService.sendMessage({ element: this.element.nativeElement })
        } else {
          this.element.nativeElement.click()
          this.isClicked = true
        }
        break

      case 40:
        this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'DOWN' })
        break
    }
  }

  @HostListener('click') onClick(): void {
    this.isClicked = true
    this.keyboardService.sendMessage({ element: this.element.nativeElement, action: 'CLICKED' })
    // this.element.nativeElement.setSelectionRange(this.element.nativeElement.value.length,this.element.nativeElement.value.length);
  }
}
