<div class="card-body pt-0 overflow-x-hidden overflow-y-auto" id="accordionWrap">
  <div>
    <div role="tablist" aria-multiselectable="true" class="card px-3 pt-1 mt-1 border-0" style="z-index: 2">
      <div class="accordion accordion-icon-rotate mb-2 row-design border">
        <a
          [id]="'item0'"
          class="p-0 collapsed"
          data-bs-toggle="collapse"
          [href]="'#accordion0'"
          aria-expanded="true"
          [attr.aria-controls]="'accordion2'"
          style="max-height: 45px"
          (click)="isSettingsOpen = !isSettingsOpen"
        >
          <div class="row mx-auto w-100 py-1 leftBorder">
            <div class="col-11">
              <div class="d-flex align-items-center h-100">
                <i class="fs-3 la la-language"></i>
                <h6 class="m-0 icon-text">{{ locale('locale_key.pages.settings.email_language.label') }}</h6>
              </div>
            </div>
            <div class="col-1 float-end">
              <div class="d-flex flex-row ms-auto h-100 w-100">
                <i
                  class="mt-3 ms-auto float-end mb-3"
                  [ngClass]="isSettingsOpen ? 'la la-angle-up' : 'la la-angle-down'"
                ></i>
              </div>
            </div>
          </div>
        </a>
        <div
          [id]="'accordion0'"
          role="tabpanel"
          data-bs-parent="#accordionWrap"
          attr.aria-labelledby="item0"
          class="collapse mt-0 pt-0"
          style="position: sticky"
        >
          <div class="card-content">
            <div class="card-body px-1">
              <div class="d-flex align-items-center ps-3">
                <div class="row">
                  <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                    <p>{{ locale('locale_key.pages.settings.email_language.outgoing') }}</p>
                    <div id="lang-dropdown" class="btn-group me-3 mb-1">
                      <button
                        class="btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <span *ngIf="selectedAllLang">
                          <i class="{{ selectedAllLang.icon }} me-2"></i>
                          {{ selectedAllLang.label }}
                        </span>
                      </button>
                      <div class="dropdown-menu">
                        <ng-container *ngFor="let language of languages">
                          <a
                            class="dropdown-item m-0 px-3"
                            style="color: var(--main-dark-color); cursor: pointer"
                            *ngIf="selectedAllLang?.code != language.code"
                            (click)="changeCompanyLanguage(language)"
                          >
                            <i class="{{ language.icon }} me-2"></i>
                            {{ language.label }}
                          </a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-9 col-md-9 col-lg-8">
                    <p class="p-3">{{ locale('locale_key.pages.settings.email_language.selected.language') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer float-end p-1" style="background: transparent; border: none">
            <ng-container *ngIf="!inProgress">
              <button
                type="button"
                [disabled]="isSaveButtonDisabled"
                class="btn primary-button custom-button me-3 mb-1"
                (click)="changeAllEmailLanguage()"
              >
                {{ locale('locale_key.general.buttons.save') }}
              </button>
            </ng-container>
            <div class="ball-pulse loader-primary" *ngIf="inProgress">
              <div style="background-color: var(--main-dark-color1)"></div>
              <div style="background-color: var(--main-dark-color1)"></div>
              <div style="background-color: var(--main-dark-color1)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
    <div *ngIf="!loadingInProgress" role="tablist" aria-multiselectable="true" class="card px-3 pt-1 mt-1 border-0">
      <div class="accordion accordion-icon-rotate mb-2 row-design border-0">
        <a
          [id]="'item1'"
          class="p-0 collapsed"
          data-bs-toggle="collapse"
          [href]="'#accordion1'"
          aria-expanded="true"
          [attr.aria-controls]="'accordion2'"
          style="max-height: 45px"
          (click)="isTemplateOpen = !isTemplateOpen"
        >
          <div class="row mx-auto w-100 py-1 leftBorder">
            <div class="col-11">
              <div class="d-flex align-items-center h-100">
                <i class="fs-3 la la-mail-bulk"></i>
                <h6 class="m-0 icon-text">{{ locale('locale_key.pages.settings.email_language.templates') }}</h6>
              </div>
            </div>
            <div class="col-1 float-end">
              <div class="d-flex flex-row ms-auto h-100 w-100">
                <i
                  class="mt-3 ms-auto float-end mb-3"
                  [ngClass]="isTemplateOpen ? 'la la-angle-up' : 'la la-angle-down'"
                ></i>
              </div>
            </div>
          </div>
        </a>

        <div
          #accordion1Element
          [id]="'accordion1'"
          role="tabpanel"
          data-bs-parent="#accordionWrap"
          attr.aria-labelledby="item1"
          class="collapse mt-0 pt-0 h-100"
        >
          <div class="card-content mb-0 pb-0">
            <div class="card-body px-1 mb-0 pb-0">
              <div class="container-fluid mb-0 pb-0">
                <!-- Header -->
                <div *ngIf="!showDetailedView" class="row py-3">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-12 col-xl-7 border border-light">
                        <div class="row align-items-center m-0 p-2 border border-slight">
                          <div class="col-lg-6 col-xs-12 p-0">
                            <div class="row d-flex flex-row align-items-center m-0">
                              <div class="col-md-2 col-xl-5 p-2">
                                <div>
                                  <i class="fs-4 la la-filter"></i>
                                  <span>{{ locale('locale_key.pages.datahub.filter_dropdown') }}</span>
                                </div>
                              </div>
                              <div class="col-md-10 col-xl-7 p-md-0">
                                <div id="lang-dropdown" class="border border-solid btn-group me-3 pl-5">
                                  <button
                                    class="btn dropdown-toggle text-left"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    <span *ngIf="selectedCategory">
                                      {{ getCategoryLocaleByKey(selectedCategory.key) }}
                                    </span>
                                    <span *ngIf="!selectedCategory">{{ getCategoryLocaleByKey(allOption.key) }}</span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ng-container *ngFor="let cat of getAllCategories(false); let first = first">
                                      <ng-container *ngIf="first">
                                        <a
                                          class="dropdown-item m-0 px-3"
                                          style="color: var(--main-dark-color); cursor: pointer"
                                          (click)="selectCategory(allOption)"
                                        >
                                          {{ getCategoryLocaleByKey(allOption.key) }}
                                        </a>
                                      </ng-container>

                                      <a
                                        class="dropdown-item m-0 px-3"
                                        style="color: var(--main-dark-color); cursor: pointer"
                                        (click)="selectCategory(cat)"
                                      >
                                        {{ getCategoryLocaleByKey(cat.key) }}
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-lg-6 col-xl-6 pt-sm-2 pt-lg-0">
                            <div class="input-group border border-solid">
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-transparent border-0" id="searchIcon">
                                  <i class="fs-4 la la-search"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                class="form-control border-0"
                                placeholder="{{ locale('locale_key.company.settings.search.templates') }}"
                                aria-label="Search"
                                aria-describedby="searchIcon clearIcon"
                                [(ngModel)]="searchQuery"
                                (input)="filterTemplates($event)"
                                (keydown.Backspace)="handleBackspace($event)"
                              />
                              <div class="input-group-append">
                                <span
                                  class="input-group-text bg-transparent border-0"
                                  id="clearIcon"
                                  style="cursor: pointer"
                                  (click)="resetFilter()"
                                >
                                  <i class="fs-4 la la-times"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-xl-5 pt-md-2 pt-lg-2">
                        <div class="row d-flex flex-row align-items-center m-0">
                          <div class="col-lg-7 col-xs-12 p-0 pt-md-2 pt-lg-0">
                            <div class="row d-flex flex-row align-items-center m-0">
                              <div class="col-xs-2 col-lg-3 col-md-2 col-xl-5 py-sm-2 pt-sm-3 pt-md-0">
                                <div>
                                  <span>{{ locale('locale_key.pages.settings.email_language.shown') }}</span>
                                </div>
                              </div>
                              <div class="col-lg-9 col-md-10 col-xl-7 p-md-2">
                                <div id="lang-dropdown" class="border border-solid btn-group me-3 mb-1">
                                  <button
                                    class="btn dropdown-toggle text-lefttext-left"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    <span *ngIf="selectedShowAllLang">
                                      <i class="{{ selectedShowAllLang.icon }} me-2"></i>
                                      {{ selectedShowAllLang.label }}
                                    </span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ng-container *ngFor="let language of languages">
                                      <a
                                        class="dropdown-item m-0 px-3"
                                        style="color: var(--main-dark-color); cursor: pointer"
                                        *ngIf="selectedShowAllLang?.code != language.code"
                                        (click)="selectShowAllLanguage(language)"
                                      >
                                        <i class="{{ language.icon }} me-2"></i>
                                        {{ language.label }}
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5 col-xs-12 pt-sm-2 pt-lg-0">
                            <button
                              class="btn border border-secondary border-2"
                              style="border-radius: 0"
                              (click)="openModal()"
                            >
                              {{ locale('locale_key.pages.settings.email_language.reset_all') }}
                            </button>
                            <confirm-cancel-modal
                              (actionClicked)="resetAllTemplates()"
                              (cancelClicked)="onCancel()"
                              [isVisible]="showModal"
                              [inProgress]="inProgress"
                              [headerText]="locale('locale_key.copmany.settings.reset_all')"
                              [bodyText]="locale('locale_key.copmany.settings.reset_all.message')"
                              [cancelButtonText]="locale('locale_key.delete_confirmation_modal.button.cancel')"
                              [actionButtonText]="locale('locale_key.pages.settings.email_language.reset_all')"
                            ></confirm-cancel-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Email Templates Content -->
                <div class="row mt-3 flex-grow-1">
                  <!-- Main Content Area -->
                  <div class="h-100 mb-0 pb-0" [ngClass]="!showDetailedView ? 'overflow-x-hidden overflow-y-auto' : ''">
                    <!-- Template Category row -->
                    <div
                      *ngIf="!showDetailedView"
                      [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'pb-5' : 'pb-2'"
                    >
                      <div *ngFor="let cat of getCategories()" class="row">
                        <div class="category">
                          <h4>{{ getCategoryLocaleByKey(cat.key) }}</h4>
                        </div>

                        <div
                          *ngFor="let emailTemplate of cat.emails; let index = index"
                          class="col-xl-2 col-lg-4 col-md-6 mb-3"
                        >
                          <div class="card d-flex flex-column h-100">
                            <div class="d-flex flex-column align-items-center card-header px-0">
                              <div>
                                <i
                                  class="la-6x la"
                                  [ngClass]="{
                                    'la-share-alt-square mb-3': cat.category === 'Dashboard',
                                    'la-clock-o mb-3': cat.category === 'Data requests and reminders',
                                    'la-group mb-3': cat.category === 'Invitations'
                                  }"
                                ></i>
                              </div>
                              <div
                                class="card-body flex-grow-1 d-flex flex-column w-100 align-items-center m-2 p-0 h-100"
                                style="background-color: #e6e6e6"
                              >
                                <div id="lang-dropdown" class="btn-group">
                                  <button
                                    class="btn dropdown-toggle text-lefttext-left"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    <span *ngIf="getLanguage(emailTemplate)">
                                      <i class="{{ getLanguage(emailTemplate).icon }} me-3"></i>
                                      {{ getLanguage(emailTemplate).label }}
                                    </span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ng-container *ngFor="let language of languages">
                                      <a
                                        class="dropdown-item m-0 px-3"
                                        style="color: var(--main-dark-color); cursor: pointer"
                                        *ngIf="getLanguage(emailTemplate)?.code != language.code"
                                        (click)="selectLanguage(emailTemplate, language)"
                                      >
                                        <i class="{{ language.icon }} me-3"></i>
                                        {{ language.label }}
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-body flex-grow-1 d-flex flex-column">
                              <div class="align-self-start">
                                <!-- Aligns to the start (top) of the flex container -->
                                <p class="card-title">{{ getUserFriendly(emailTemplate) }}</p>
                              </div>
                              <div class="flex-grow-1">
                                <!-- Aligns to the center and grows to fill available space -->
                                <p class="card-text">
                                  {{ locale('locale_key.pages.settings.email_language.sent_to') }}
                                  <strong>{{ getSendTo(emailTemplate) }}</strong>
                                </p>
                              </div>
                              <div class="row justify-content-between align-items-center pt-3">
                                <div class="col-auto">
                                  <span
                                    *ngIf="isTemplateEdited(emailTemplate)"
                                    class="px-2"
                                    style="background-color: #e066ba; border-radius: 5px; color: white"
                                  >
                                    {{ locale('locale_key.pages.data_category.edited') }}
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <button
                                    class="btn btn-light border border-2 border-secondary py-2"
                                    style="border-radius: 0"
                                    (click)="selectTemplate(emailTemplate)"
                                  >
                                    {{ locale('locale_key.pages.settings.email_language.view_template') }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="showDetailedView" class="h-100 mb-0 pb-0">
                      <app-view-template
                        [template]="selectedTemplate"
                        (close)="closeDetailedView()"
                      ></app-view-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
