export class Request_AddFe {
  id: string
  supplierCompanyId: string
  assigneeAffId: string

  constructor(id: string, supplierCompanyId: string, assigneeAffId: string) {
    this.id = id
    this.supplierCompanyId = supplierCompanyId
    this.assigneeAffId = assigneeAffId
  }

  public static fromJsomObj(obj: any): Request_AddFe {
    let request = new Request_AddFe(obj['id'], obj['supplierCompanyId'], obj['assigneeAffId'])
    return request
  }
}
