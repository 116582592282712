import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { AffiliationFe } from '../../../model/user/AffiliationFe'
import { CompanyFe } from '../../../model/data-suppliers/company/CompanyFe'
import { UserFe } from '../../../model/data-suppliers/company/UserFe'
import { ContactInvitationFe } from '../../../model/data-suppliers/company/ContactInvitationFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ValidationMessages } from '../../../model/form-validation/FormValidationMessages'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ContactInvitation_AddFe } from 'src/app/model/data-suppliers/company/ContactInvitation_AddFe'
import { SupplierCompanyFe } from 'src/app/model/data-suppliers/company/SupplierCompanyFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { InvitationLinkFe } from 'src/app/model/email/InvitationLinkFe'

import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ContactFe } from 'src/app/model/user/ContactFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { Language } from 'src/app/utils/language/Language'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { RoutesFe } from 'src/app/route/RoutesFe'

@Component({
  selector: 'data-supplier-personnel-entry',
  templateUrl: './data-supplier-personnel-entry.component.html',
  styleUrls: ['./data-supplier-personnel-entry.component.css', '../data-suppliers.component.scss']
})
export class DataSupplierPersonnelEntryComponent extends AbstractLanguageComponent implements OnInit {
  form1 = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationRegex.NonWhitespaceRegExp),
      Validators.minLength(2),
      Validators.maxLength(30)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationRegex.NonWhitespaceRegExp),
      Validators.minLength(2),
      Validators.maxLength(30)
    ]),
    email: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]),
    companyId: new FormControl('', [Validators.required])
  })

  form2 = new FormGroup({
    emailSubject: new FormControl(``, [Validators.required]),
    emailContent: new FormControl(``, [Validators.required])
  })

  @Output() userInfo = new Subject<ContactInvitationFe>()
  @Input() selectedContact: ContactFe
  selectedAffiliatedUser!: { user: UserFe; affiliation: AffiliationFe; company: CompanyFe }
  companyNetwork: SupplierCompanyFe[] = []
  invitationLink: InvitationLinkFe
  newUser: ContactInvitation_AddFe
  invitationLinkGenerated = false
  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  firstNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_first_name')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  lastNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_last_name')
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  emailFormatMessage = this.locale('locale_key.general.validation_message.invalid_email')
  companyMessage = this.locale('locale_key.general.validation_message.company_required')
  internalCompanyName: string
  internalCompanyId: string
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  isLoading = false
  languages = this.languageService.availableLanguages
  selectedLang: Language
  private languageSubscription: Subscription
  activeLanguage: Language
  lang: string
  includeEnglish: boolean = false
  disableEnglish: boolean
  showNavigateModal = false
  modalHeaderText = this.locale('locale_key.pages.dashboard.email_template.message.title')
  modalBodyText = this.locale('locale_key.pages.dashboard.email_template.message.body')
  cancelButtonText = this.locale('locale_key.delete_confirmation_modal.button.cancel')
  actionButtonText = this.locale('locale_key.pages.dashboard.email_template.action.button')

  steps = [
    {
      title: this.locale('locale_key.pages.data_reporters.add_data_reporter_wizard.personal_information'),
      completed: false
    },
    {
      title: this.locale('locale_key.pages.data_reporters.add_data_reporter_wizard.invitation_email'),
      completed: false
    }
  ]

  constructor(
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    private stateService: StateServiceFe,
    private loginService: LoginServiceFe,
    private routerFe: RouterFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private router: Router
  ) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    this.internalCompanyName = this.stateService.activeWorkspace.companyName
    this.internalCompanyId = this.stateService.activeWorkspace.companyId
    this.companyNetwork = await this.stateService.getPartners()
    if (this.selectedContact) {
      this.form1.controls.firstName.patchValue(this.selectedContact.userFirstName)
      this.form1.controls.lastName.patchValue(this.selectedContact.userLastName)
      this.form1.controls.email.patchValue(this.selectedContact.affiliationEmail)
      this.form1.controls.companyId.patchValue(this.selectedContact.affiliationCompanyId)
    }

    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    let langCode: string = await this.getCompanyEmailLanguage()

    this.selectedLang = this.languageService.getLanguageObject(langCode)

    this.updateDisableEnglish(this.selectedLang.code)
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  async getCompanyEmailLanguage(): Promise<any> {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode ? langCode : 'en'
    } catch (err) {}
  }

  updateIncludeEnglish(langCode) {
    this.includeEnglish = langCode !== 'en'
  }

  updateDisableEnglish(langCode) {
    this.disableEnglish = langCode === 'en'
  }

  updateLanguage(lang: Language) {
    this.selectedLang = lang
    this.updateDisableEnglish(lang.code)
  }

  isCompleted(index: number) {
    return this.steps[index - 1].completed
  }

  async addNewUser() {
    this.isLoading = true
    this.steps[this.currentActive - 1].completed = true
    this.newUser.emailLanguage = this.selectedLang.code
    this.newUser.includeEnglish = this.includeEnglish
    await this.stateService.inviteNewContact(this.newUser)
    this.isLoading = false
    this.closeModal()
  }

  async getLink() {
    this.isLoading = true
    this.invitationLinkGenerated = false
    this.invitationLink = await this.routerFe.generateInvitation()
    this.newUser = new ContactInvitation_AddFe(
      this.invitationLink.uuid,
      this.stateService.activeWorkspace.companyId,
      this.form1.controls.companyId.value,
      this.form1.controls.firstName.value,
      this.form1.controls.lastName.value,
      this.form1.controls.email.value,
      '',
      '',
      `${this.invitationLink.serverAddress}/op/register/contact/${this.invitationLink.uuid}`,
      this.selectedLang?.code,
      this.includeEnglish,
      this.loginService.loginUser.isAdmin
    )

    if (this.newUser.contactCompanyId == this.internalCompanyId) {
      this.newUser.internalContact = true
    } else {
      this.newUser.internalContact = false
    }
    this.invitationLinkGenerated = true
    this.isLoading = false
    this.next()
  }

  closeModal(): void {
    this.modalService.hide()
    document.body.classList.remove('modal-open')
  }

  next(): void {
    this.steps[this.currentActive - 1].completed = true
    const circles = this._document.querySelectorAll('.circle1')
    const steps = this._document.querySelectorAll('.stepper')
    this.currentActive++
    if (this.currentActive > circles.length) {
      if (this.currentActive > steps.length) this.currentActive = circles.length
    }
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.steps[this.currentActive - 1].completed = false // current
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.steps[this.currentActive - 1].completed = false // prev

    this.activeFormNo--
    this.update()
  }

  update() {
    this.progress = this._document.getElementById('progress1') as HTMLElement
    this.circles = this._document.querySelectorAll('.circle1')
    this.prevButton = this._document.getElementById('prev') as HTMLButtonElement
    this.nextButton = this._document.getElementById('next') as HTMLButtonElement

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add('active-circle1')
      } else {
        circle.classList.remove('active-circle1')
      }
    })

    const actives = this._document.querySelectorAll('.active-circle1')

    this.progress.style.width = ((actives.length - 1) / (this.circles.length - 1)) * 62 + '%'
  }

  get companyId() {
    return this.form1.get('companyId')
  }

  onIncludeEnglishChange(event: Event) {
    this.includeEnglish = event.target['checked']
  }

  navigateToEditTemplate() {
    this.onCancel()
    this.closeModal()
    this.router.navigate([RoutesFe.PROFILE.fullPath()], { queryParams: { tab: 'company-settings' } })
  }

  openModal() {
    this.showNavigateModal = true
  }

  onCancel() {
    this.showNavigateModal = false
  }
}
