import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { CompanyCustomEmailTemplateFe } from 'src/app/model/email/CompanyCustomEmailTemplateFe'
import { EmailTemplateFe } from 'src/app/model/email/EmailTemplateFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { Language } from 'src/app/utils/language/Language'
import emailKeysFe from '../../../../utils/language/locale.email.keysfe.json'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'

@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent extends AbstractLanguageComponent implements OnInit {
  @Input() emailName: string
  @Input() template: EmailTemplateFe

  @Output() modified = new EventEmitter<void>()
  @Output() close = new EventEmitter<void>()

  @ViewChild('iframeTemplate') iframe: ElementRef

  inEditing: boolean = false
  showModal: boolean = false
  inProgress: boolean = false
  diableEditButton = false
  isBucketTemplate = false

  flipBold = true
  flipItalic = true
  flipUnderline = true
  flipCenter = true
  flipList = true

  safeHtml: SafeHtml
  private transitHtml: any

  cancelButtonText = this.locale('locale_key.delete_confirmation_modal.button.cancel')
  actionButtonText = this.locale('locale_key.pages.view_template.reset.button')

  selectedFontFamily: string = 'Arial, sans-serif'
  selectedFontSize: string = '12px'

  editTemplateText = this.locale('locale_key.pages.view_template.edit_template')
  saveText = this.locale('locale_key.general.buttons.save')
  buttonText = this.editTemplateText

  modalHeaderText = this.locale('locale_key.pages.view_template.reset_template')
  modalBodyText = this.locale('locale_key.pages.view_template.reset.message')

  languages = this.languageService.availableLanguages
  selectedLang: Language
  private languageSubscription: Subscription
  activeLanguage: Language
  lang: string
  loadingInProgress: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  constructor(
    private sanitizer: DomSanitizer,
    private stateService: StateServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {
    this.loadingInProgress = true
    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    this.initializeHtml()
    this.loadingInProgress = false
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    this.setupIframeLoadEvent()
  }

  private initializeHtml(): void {
    //. if bucket exist use bucket template
    const bucketHtml = this.template.companyCustomEmailTemplates?.find(
      (b) => b.langCode === this.template.transitLanguage.code
    )
    if (bucketHtml) {
      this.transitHtml = bucketHtml.html
      this.isBucketTemplate = true
    } else {
      this.transitHtml = this.template.html
    }
    const modifiedHtml = this.makeEditable(this.transitHtml, false)
    const resolvedHtml = this.resolveLocales(modifiedHtml)
    this.sanitizeHtml(resolvedHtml)
  }

  private setupIframeLoadEvent(): void {
    if (this.iframe) {
      this.iframe.nativeElement.onload = () => this.adjustIframeHeight()
    }
  }

  private adjustIframeHeight(): void {
    const contentHeight = this.iframe.nativeElement.contentWindow.document.body.scrollHeight + 'px'
    this.iframe.nativeElement.style.height = contentHeight
  }

  private sanitizeHtml(html: string): void {
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(html)
    this.transitHtml = html
  }

  resolveLocales(html: string) {
    return this.resolveEmailLocales(html, this.template.fileName)
  }

  makeEditable(html: string, isEditable: boolean): string {
    const doc = new DOMParser().parseFromString(html, 'text/html')

    if (isEditable) {
      const style = `
        <head>
          
        </head>
      `
      const head = doc.querySelector('head')
      head.insertAdjacentHTML('beforeend', style)
    }

    // Find elements with class editable
    const editableElements = doc.querySelectorAll('.editable')

    // Iterate over editable elements and set contenteditable attribute
    editableElements.forEach((element) => {
      element.setAttribute('contenteditable', isEditable ? 'true' : 'false')
    })

    const modifiedHtml = new XMLSerializer().serializeToString(doc)

    return modifiedHtml
  }

  cancel() {
    this.close.emit()
  }

  modify() {
    this.modified.emit()
  }

  async toggleEditing(): Promise<void> {
    if (!this.inEditing) {
      this.startEditing()
      this.modify()
    } else {
      await this.saveTemplate()
      this.finishEditing()
    }
  }

  private startEditing(): void {
    this.inEditing = true
    this.toggleButtonText(this.saveText)
    this.transitHtml = this.makeEditable(this.transitHtml, true)
    this.sanitizeHtml(this.transitHtml)
    this.updateIframe()
  }

  changeFontFamily() {
    this.applyStyleToIframe('font-family', this.selectedFontFamily)
  }

  changeFontSize() {
    this.applyStyleToIframe('font-size', this.selectedFontSize)
  }

  toggleBold() {
    this.applyStyleToIframe('font-weight', 'bold')
  }

  toggleItalic() {
    this.applyStyleToIframe('font-style', 'italic')
  }

  toggleUnderline() {
    this.applyStyleToIframe('text-decoration', 'underline')
  }

  alignCenter() {
    this.applyStyleToIframe('text-align', 'center')
  }

  insertLink() {
    const url = prompt(this.locale('locale_key.view.template.add.link.propmpt'))
    if (url) {
      this.applyStyleToIframe('createLink', url, true)
    }
  }

  applyStyleToIframe(property: string, value?: string, isCommand?: boolean, event?: Event): void {
    const iframeDoc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow.document

    if (!isCommand) {
      const selection = iframeDoc.getSelection()

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)

        const isMultilineSelection = range.startContainer !== range.endContainer

        if (isMultilineSelection) {
          const startLine = this.getLineElement(range.startContainer)
          const endLine = this.getLineElement(range.endContainer)

          if (startLine && endLine) {
            this.applyStyleToLines(startLine, endLine, property, value)
          }
        } else {
          const selectedNode = range.commonAncestorContainer
          const editableElement = this.findEditableParent(iframeDoc, selectedNode)

          if (editableElement && editableElement.getAttribute('contenteditable') === 'true') {
            if (property === 'insertParagraph' && selectedNode.nodeType === 1) {
              const newParagraph = iframeDoc.createElement('p')
              range.deleteContents()
              range.insertNode(newParagraph)
              range.selectNodeContents(newParagraph)
              selection.removeAllRanges()
              selection.addRange(range)
              this.updateIframeContent(iframeDoc.body.innerHTML)
            } else if (selectedNode.nodeType === 3) {
              const textNode = selectedNode as Text
              const parentBlockElement = this.findParentBlockElement(textNode)

              if (parentBlockElement) {
                let currProp = parentBlockElement.style[property]
                if (property === 'text-align') {
                  parentBlockElement.style[property] = currProp !== 'center' ? 'center' : 'left'
                } else if (property === 'text-decoration') {
                  parentBlockElement.style[property] = currProp === 'underline' ? 'none' : 'underline'
                } else if (property === 'font-weight') {
                  parentBlockElement.style[property] = currProp === 'bold' ? 'normal' : 'bold'
                } else if (property === 'font-style') {
                  parentBlockElement.style[property] = currProp === 'italic' ? 'normal' : 'italic'
                } else {
                  parentBlockElement.style[property] = value
                }

                parentBlockElement.setAttribute('contenteditable', 'true')
              }
            } else {
              if (property === 'text-align') {
                const currentAlignValue = editableElement.style[property]
                editableElement.style[property] = currentAlignValue !== 'center' ? 'center' : 'left'
              } else if (property === 'text-decoration') {
                const currentAlignValue = editableElement.style[property]
                editableElement.style[property] = currentAlignValue === 'underline' ? 'none' : 'underline'
              } else if (property === 'font-weight') {
                const currentAlignValue = editableElement.style[property]
                editableElement.style[property] = currentAlignValue === 'bold' ? 'normal' : 'bold'
              } else if (property === 'font-style') {
                const currentAlignValue = editableElement.style[property]
                editableElement.style[property] = currentAlignValue === 'italic' ? 'normal' : 'italic'
              } else {
                editableElement.style[property] = value
              }
            }
          }
        }
      }
    } else {
      if (property === 'paste') {
        const clipboardData = (event as ClipboardEvent).clipboardData
        const pastedText = clipboardData.getData('text/plain')
        iframeDoc.execCommand(property, false, pastedText)
      } else {
        iframeDoc.execCommand(property, false, value)
      }

      this.updateIframeContent(iframeDoc.body.innerHTML)
      this.trustHtml(iframeDoc)
    }
  }

  private applyStyleToLines(startLine: HTMLElement, endLine: HTMLElement, property: string, value: string): void {
    let currentLine = startLine

    while (currentLine && currentLine !== endLine) {
      let currProp = currentLine.style[property]

      if (property === 'text-align') {
        currentLine.style[property] = currProp !== 'center' ? 'center' : 'left'
      } else if (property === 'text-decoration') {
        currentLine.style[property] = currProp === 'underline' ? 'none' : 'underline'
      } else if (property === 'font-weight') {
        currentLine.style[property] = currProp === 'bold' ? 'normal' : 'bold'
      } else if (property === 'font-style') {
        currentLine.style[property] = currProp === 'italic' ? 'normal' : 'italic'
      } else {
        currentLine.style[property] = value
      }

      currentLine.setAttribute('contenteditable', 'true')
      currentLine = this.getNextLineElement(currentLine)
    }

    if (endLine) {
      let currProp = currentLine.style[property]

      if (property === 'text-align') {
        currentLine.style[property] = currProp === 'left' ? 'center' : 'left'
      } else if (property === 'text-decoration') {
        currentLine.style[property] = currProp === 'underline' ? 'none' : 'underline'
      } else if (property === 'font-weight') {
        currentLine.style[property] = currProp === 'bold' ? 'normal' : 'bold'
      } else if (property === 'font-style') {
        currentLine.style[property] = currProp === 'italic' ? 'normal' : 'italic'
      } else {
        currentLine.style[property] = value
      }
      endLine.setAttribute('contenteditable', 'true')
    }
  }

  private getLineElement(node: Node): HTMLElement | null {
    while (node) {
      if (node.nodeType === 1 && this.isBlockElement(node as HTMLElement)) {
        return node as HTMLElement
      }
      node = node.parentNode
    }
    return null
  }

  private getNextLineElement(currentLine: HTMLElement): HTMLElement | null {
    let nextSibling = currentLine.nextElementSibling
    while (nextSibling) {
      if (this.isBlockElement(nextSibling as HTMLElement)) {
        return nextSibling as HTMLElement
      }
      nextSibling = nextSibling.nextElementSibling
    }
    return null
  }

  private findEditableParent(iframeDoc: any, node: Node): HTMLElement {
    while (node && node !== iframeDoc.body) {
      if (node.nodeType === 1 && (node as HTMLElement).hasAttribute('contenteditable')) {
        return node as HTMLElement
      }
      node = node.parentNode
    }
    return null
  }

  private findParentBlockElement(node: Node): HTMLElement | null {
    let parent = node.parentElement

    while (parent && !this.isBlockElement(parent)) {
      parent = parent.parentElement
    }

    return parent as HTMLElement
  }

  private isBlockElement(element: Element): boolean {
    const blockElements = ['div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li', 'blockquote']

    return blockElements.includes(element.tagName.toLowerCase())
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault()

    const pastedText = event.clipboardData.getData('text/plain')

    this.applyStyleToIframe('paste', pastedText, false, event)
  }

  private trustHtml(iframeDoc) {
    const sanitizedHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(iframeDoc.documentElement.outerHTML)
    this.safeHtml = sanitizedHtml
  }

  private updateIframeContent(content: string) {
    const iframeBody = this.iframe.nativeElement.contentDocument.body
    iframeBody.innerHTML = content
  }

  private toggleButtonText(text: string) {
    this.buttonText = text
  }

  private async saveTemplate(): Promise<void> {
    this.inProgress = true
    this.transitHtml = this.iframe.nativeElement.contentDocument.documentElement.outerHTML
    this.transitHtml = this.transitHtml.replace(/<head>[\s\S]*?<\/head>/i, '<head></head>')

    const bucketTemplate = this.template.companyCustomEmailTemplates.find(
      (b) => b.langCode === this.template.transitLanguage.code
    )
    if (bucketTemplate) {
      bucketTemplate.html = this.transitHtml
      bucketTemplate.langCode = this.template.transitLanguage.code
    } else {
      const companyCustomEmailTemplate = new CompanyCustomEmailTemplateFe(
        this.transitHtml,
        this.template.transitLanguage.code,
        this.template.fileName
      )
      this.template.companyCustomEmailTemplates.push(companyCustomEmailTemplate)
    }

    this.stateService.saveTemplate(this.template.fileName, this.template.transitLanguage?.code, this.transitHtml)

    this.transitHtml = this.makeEditable(this.transitHtml, false)
    this.sanitizeHtml(this.transitHtml)
  }

  private finishEditing(): void {
    this.inEditing = false
    this.inProgress = false
    this.toggleButtonText(this.editTemplateText)
    this.updateIframe()
  }

  updateIframe() {
    this.iframe.nativeElement.contentWindow.document.open()
    this.iframe.nativeElement.contentWindow.document.write(this.safeHtml)
    this.iframe.nativeElement.contentWindow.document.close()
  }

  openModal() {
    this.showModal = true
  }

  async resetTemplate() {
    this.inProgress = true
    this.initializeHtml()
    await this.stateService.resetTemplate(this.template.fileName)
    this.inProgress = false

    this.resetBucketTemplateLocally()

    this.inEditing = false
    this.toggleButtonText(this.editTemplateText)
    this.onCancel()
  }

  resetBucketTemplateLocally() {
    const bucketIndex = this.template.companyCustomEmailTemplates.findIndex(
      (b) => b.langCode === this.template.transitLanguage.code
    )
    if (bucketIndex !== -1) {
      this.template.companyCustomEmailTemplates.splice(bucketIndex, 1)
    }

    this.isBucketTemplate = false

    this.initializeHtml()
  }

  onCancel() {
    this.showModal = false
  }

  resolveEmailLocales(html: string, emailTemplate: string) {
    const keys = emailKeysFe.find((l) => l.name == emailTemplate).keys || []
    keys.forEach((key) => {
      html = html.replaceAll(key, this.locale(key, undefined, this.template.transitLanguage.code) || '')
    })

    return html
  }

  isTemplateEdited() {
    if (this.template.companyCustomEmailTemplates) {
      return !!this.template.companyCustomEmailTemplates.some((b) => b.langCode === this.template.transitLanguage.code)
    }
    return false
  }

  isResetButtonDisabled() {
    return !this.isTemplateEdited()
  }
}
