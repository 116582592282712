import { TextUtils } from 'src/app/utils/text.utils'

export class ValueColumnFe {
  constructor(
    public valueField: string,
    public valueLabel: string,
    public valueVector: number[],
    public percentageVector: string[],
    public unitVector: string[]
  ) {
    this.unitVector = this.unitVector.map((u) => TextUtils.convert(u))
  }
}
