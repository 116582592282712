import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import {
  Calculation,
  CalculationCondition,
  CalculationThenOptions,
  ConditionComparators
} from './model/CalculationBuilderModels'
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe'
import _, { extend } from 'lodash'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { TaxonomyEmissionFactorFe } from 'src/app/model/taxonomy/TaxonomyEmissionFactorFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { BsModalService } from 'ngx-bootstrap/modal'
import { AbstractEmissionFactorFe } from 'src/app/model/emissions/AbstractEmissionFactorFe'
import { DatapointDatatype } from 'src/app/model/taxonomy/DatapointDatatypeFe'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

enum ModalNames {
  chooseSingleEmissionFactorModal = 'chooseSingleEmissionFactorModal'
}

@Component({
  selector: 'calculation-builder',
  templateUrl: './calculation-builder.component.html',
  styleUrls: ['./calculation-builder.component.scss']
})
export class CalculationBuilderComponent extends AbstractLanguageComponent implements OnInit, AfterViewChecked {
  @Input() buildMode: String = 'calculation'
  @Input() showEditMode: boolean = true
  @Input() createAdvancedCalculation: boolean = true
  @Input() showAddNewDataPointBtn: boolean = true
  @Input() showFormulaOnly: boolean = false
  @Input() selectedDatapoint: TaxonomyAttributeFe
  @Input() selectedDataCategory
  @Output() updateHasCalculationErrors = new EventEmitter<boolean>()
  @Input() isEditMode: boolean = false
  @Input() showCalculationErrors: boolean = false
  @Input() showEmissionFactorErrors: boolean = false
  @Input() emissionFactorErrorMsgs = new Set()
  showAdvancedCalculationBuilder: boolean = false
  selectedSingleEmissionFactorIdx = 0

  showMultipleEmissionFactorBuilder: boolean = false
  taxonomies: {
    depTaxonomy: TaxonomyInfoFe
    newTaxonomy: TaxonomyInfoFe
  }

  @ViewChild(`${ModalNames.chooseSingleEmissionFactorModal}`, { static: true })
  chooseSingleEmissionFactorModal: TemplateRef<any>

  modals

  //Emission factors
  constructor(
    private stateService: StateServiceFe,
    public languageService: LanguageService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer
  ) {
    super(languageService)
  }

  ngAfterViewChecked(): void {
    //TODO: check performance
    this.checkAnyCalculationHasError()
  }

  private checkAnyCalculationHasError() {
    let errorFound = false
    this.selectedDatapoint.calculations.forEach((calculation) => {
      if (calculation.condition && calculation.condition.hasError) {
        errorFound = true
      }

      calculation.formula.forEach((token) => {
        if (token.highlightError) {
          errorFound = true
        }
      })
      /*
        This is needed because bracket errors
        aren't registered on a specific token
      */
      if (calculation.hasError) {
        errorFound = true
      }
    })
    this.updateHasCalculationErrors.emit(errorFound)
  }

  async ngOnInit() {
    if (_.isEmpty(this.selectedDatapoint.calculations)) {
      this.selectedDatapoint.calculations.push({
        isFallback: false,
        formula: [],
        then: CalculationThenOptions.FORMULA
      })
    }

    if (this.selectedDatapoint.calculations.length > 1 || this.selectedDatapoint.calculations[0].condition) {
      this.showAdvancedCalculationBuilder = true
    }

    if (_.isEmpty(this.selectedDatapoint.emissionFactors)) {
      this.selectedDatapoint.emissionFactors.push({ isFallback: false, value: {} })
    }

    if (this.selectedDatapoint.emissionFactors.length > 1 || this.selectedDatapoint.emissionFactors[0].condition) {
      this.showMultipleEmissionFactorBuilder = true
      ;(document.querySelector('#defineMultipleEmissionFactors') as HTMLInputElement).checked = true
    }

    this.setupModals()

    this.taxonomies = await this.stateService.getTaxonomyInfos()
  }

  calculationBuilderChanged() {
    if (_.isEmpty(this.selectedDatapoint.calculations)) {
      this.selectedDatapoint.calculations.push({
        isFallback: false,
        formula: [],
        then: CalculationThenOptions.FORMULA
      })
    }

    if (!this.showAdvancedCalculationBuilder) {
      // Switching from advanced calculation to simple calculation
      const firstCalculation = { ...this.selectedDatapoint.calculations[0] }
      this.selectedDatapoint.calculations = []
      this.selectedDatapoint.calculations.push({
        isFallback: false,
        formula: firstCalculation.formula,
        then: firstCalculation.then
      })
    }
  }

  clearSimpleCalculationBuilder() {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.calculations = [
      {
        isFallback: false,
        formula: [],
        then: CalculationThenOptions.FORMULA
      }
    ]
  }

  adv_addCalculation() {
    this.selectedDatapoint.isEdited = true
    const calculation: Calculation = {
      formula: [],
      isFallback: false,
      then: CalculationThenOptions.FORMULA
    }
    this.selectedDatapoint.calculations.push(calculation)
  }

  adv_addFallbackCalculation() {
    if (this.adv_hasFallback) {
      return
    }
    this.selectedDatapoint.isEdited = true
    const calculation: Calculation = {
      formula: [],
      isFallback: true,
      then: CalculationThenOptions.FORMULA
    }
    this.selectedDatapoint.calculations.push(calculation)
  }

  adv_removeCalculation(idx) {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.calculations.splice(idx, 1)

    if (_.isEmpty(this.selectedDatapoint.calculations)) {
      this.adv_addCalculation()
    }
  }

  adv_clearCondition(idx) {
    this.selectedDatapoint.isEdited = true
    const newCondition = new CalculationCondition({
      comparator: ConditionComparators.NONE_COMPARATOR,
      criteria: []
    })
    this.selectedDatapoint.calculations[idx].condition = newCondition
  }

  adv_clearCalculation(idx) {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.calculations[idx].formula = []
  }

  get adv_hasFallback() {
    const calc = _.find(this.selectedDatapoint.calculations, ['isFallback', true])
    return !_.isEmpty(calc)
  }

  setCalculationThen({ calculation, then }) {
    calculation.then = then
    calculation.formula = []
  }

  updateFixedNumber({ calculation }) {
    calculation.formula[0] = {
      type: 'operand',
      operandType: 'fixedNumber',
      value: calculation.value,
      highlightError: false
    }
  }

  get adv_hasFallbackEmissionFactor() {
    const ef = _.find(this.selectedDatapoint.emissionFactors, ['isFallback', true])
    return !_.isEmpty(ef)
  }

  emissionFactorChanged({ emissionFactor, emissionFactorIdx }) {
    console.log(`Emission factor changed`)
    console.log({ emissionFactor, emissionFactorIdx })
    this.selectedDatapoint.isEdited = true
    const ef: TaxonomyEmissionFactorFe = {
      isFallback: false,
      value: emissionFactor
    }
    this.selectedDatapoint.emissionFactors[emissionFactorIdx] = ef
    console.log({ datapoint: this.selectedDatapoint })
  }

  adv_clearEmissionFactorCondition(idx) {
    this.selectedDatapoint.isEdited = true
    const newCondition = new CalculationCondition({
      comparator: ConditionComparators.NONE_COMPARATOR,
      criteria: []
    })
    this.selectedDatapoint.emissionFactors[idx].condition = newCondition
  }

  adv_clearEmissionFactor(idx) {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.emissionFactors[idx].value = {}
  }

  updateEmissionFactorAtIdx({ emissionFactor, idx }) {
    this.selectedDatapoint.emissionFactors[idx].value = emissionFactor
  }

  adv_addEmissionFactor() {
    this.selectedDatapoint.isEdited = true
    const ef: TaxonomyEmissionFactorFe = {
      isFallback: false,
      value: {}
    }
    this.selectedDatapoint.emissionFactors.push(ef)
  }

  adv_addFallbackEmissionFactor() {
    if (this.adv_hasFallbackEmissionFactor) {
      return
    }
    this.selectedDatapoint.isEdited = true
    const ef: TaxonomyEmissionFactorFe = {
      isFallback: true,
      value: {}
    }
    this.selectedDatapoint.emissionFactors.push(ef)
  }

  adv_removeEmissionFactor(idx) {
    this.selectedDatapoint.isEdited = true
    this.selectedDatapoint.emissionFactors.splice(idx, 1)
  }

  resolveLabel({ token, deployed }) {
    let datapoint = token.datapoint

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column
            }
          })
        }
      })
    }

    let result = ''

    if (
      datapoint &&
      datapoint.label &&
      datapoint.label.hasOwnProperty(this.languageService.getDisplayActiveLanguage())
    ) {
      result = datapoint.label[this.languageService.getDisplayActiveLanguage()]
    }

    if (result) {
      return result
    }

    return datapoint?.label['en'] ?? ''
  }

  private setupModals() {
    this.modals = {
      [`${ModalNames.chooseSingleEmissionFactorModal}`]: {
        template: this.chooseSingleEmissionFactorModal,
        class: `modal-md ${ModalNames.chooseSingleEmissionFactorModal}`
      }
    }
  }

  private openModal(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal
    if (_.isEmpty(this.modals)) {
      this.setupModals()
    }

    const customClass = options.class ?? this.modals[modal].class
    const template = this.modals[modal].template
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    })
  }

  private closeModal({ modal }) {
    if (_.isEmpty(this.modals)) {
      this.setupModals()
    }

    const modalRef = this.modals[modal].ref
    if (modalRef) {
      this.modalService.hide(modalRef.id)
    }
  }

  toggleMultipleEmissionFactorBuilder() {
    // Toggling from simple to multiple or emission factor length <= 1, just toggle and return
    if (!this.showMultipleEmissionFactorBuilder) {
      this.showMultipleEmissionFactorBuilder = !this.showMultipleEmissionFactorBuilder
      return
    }

    //Toggling from multiple to simple, open modal if emission factor length > 1
    if (this.selectedDatapoint.emissionFactors.length > 1) {
      this.openModal({
        modal: ModalNames.chooseSingleEmissionFactorModal,
        ignoreBackdropClick: false
      })

      //select first emission factor option
      setTimeout(() => {
        ;(document.querySelector('#emissionFactorOption_0') as HTMLInputElement).checked = true
      }, 250)
    } else {
      this.selectedSingleEmissionFactorIdx = 0
      this.confirmChooseSingleEmissionFactor()
    }
  }

  cancelChooseSingleEmissionFactor() {
    this.selectedSingleEmissionFactorIdx = 0
    this.closeModal({
      modal: ModalNames.chooseSingleEmissionFactorModal
    })
    //check the radio
    ;(document.querySelector('#defineMultipleEmissionFactors') as HTMLInputElement).checked = true
  }

  confirmChooseSingleEmissionFactor() {
    const oldEf = _.cloneDeep(this.selectedDatapoint.emissionFactors[this.selectedSingleEmissionFactorIdx])
    const ef: TaxonomyEmissionFactorFe = {
      isFallback: false,
      value: oldEf.value
    }
    this.selectedDatapoint.emissionFactors = []
    this.selectedDatapoint.emissionFactors[0] = ef
    this.showMultipleEmissionFactorBuilder = false
    this.closeModal({
      modal: ModalNames.chooseSingleEmissionFactorModal
    })
    //uncheck the radio
    ;(document.querySelector('#defineMultipleEmissionFactors') as HTMLInputElement).checked = false
  }

  filterEmissionFactor({ emissionFactor }) {
    let filter = {}
    if (this.selectedDatapoint.deployed && !_.isEmpty(emissionFactor)) {
      filter = { sourceUnit: emissionFactor.sourceUnit, conversionUnit: emissionFactor.conversionUnit }
    }
    return filter
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
