import { Component, Input } from '@angular/core'
import { RequestGroupFe } from '../../model/data-suppliers/request/RequestGroupFe'
import { AbstractActivityFe } from '../../model/data-suppliers/timeline/AbstractActivityFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'inbox-card',
  template: `
    <div
      class="card tasks-card mx-auto w-100 ms-2 mb-3 py-3 px-2"
      *ngIf="activity"
      [ngClass]="activity.readStatus == 'UNREAD' ? 'unread' : ''"
    >
      <h5 class="bold">{{ activity.requestGroupLabel }}</h5>
      <div class="d-flex flex-row media ps-0">
        <div class="w-100">
          <p class="m-0 p-0" style="line-height: 1.3">
            <span class="pb-0 mb-0 mt-2" *ngIf="activity.submitterAffId == 'SYSTEM_AFF'">
              {{ locale('locale_key.general.user_role.system') }}
            </span>
            <span class="pb-0 mb-0 mt-2" *ngIf="activity.submitterAffId !== 'SYSTEM_AFF'">
              {{ activity.getSubmitterName() }}
            </span>
            <span class="text-muted mt-0 pt-0 mb-2 ms-2">({{ activity.submitterCompanyName }})</span>
          </p>
          <div class="d-flex flex-row justify-content-between w-100">
            <span class="blue my-auto text-left">
              <i class="{{ activity.icon }} fs-4" style="position: relative; top: 3px"></i>
              {{ locale(activity.homePageLocaleKey) }}
            </span>
            <small class="my-auto text-muted me-2">{{ activity.getSubmissionTime() }}</small>
          </div>
          <div class="d-flex flex-row w-100 pt-2">
            <span class="me-2 dark-text">{{ locale('locale_key.pages.inbox.request_deadline') }}</span>
            <span class="badge {{ requestGroup?.getDeadlineClass() }} dark-text">
              {{ requestGroup?.getDeadline() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./data-suppliers.component.scss']
})
export class InboxCardComponent extends AbstractLanguageComponent {
  @Input() requestGroup: RequestGroupFe
  @Input() activity: AbstractActivityFe

  constructor(languageService: LanguageService) {
    super(languageService)
  }
}
