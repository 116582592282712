import { Operator } from './OperatorFe'

export class TwoOperandOperatorFe extends Operator {
  static readonly AND = new TwoOperandOperatorFe(
    'and',
    '&&',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.and'
  )
  static readonly OR = new TwoOperandOperatorFe(
    'or',
    '||',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.or'
  )
  static readonly EQ = new TwoOperandOperatorFe(
    'eq',
    '==',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_equal_to'
  )
  static readonly GT = new TwoOperandOperatorFe(
    'gt',
    '>',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_greater_than'
  )
  static readonly LT = new TwoOperandOperatorFe(
    'lt',
    '<',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_less_than'
  )
  static readonly GTQ = new TwoOperandOperatorFe(
    'gtq',
    '>=',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_greater_than_or_equal'
  )
  static readonly LTQ = new TwoOperandOperatorFe(
    'ltq',
    '<=',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_less_than_or_equal'
  )
  static readonly IN = new TwoOperandOperatorFe(
    'in',
    'in',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.contains'
  )
  static readonly NOTNULL = new TwoOperandOperatorFe(
    'not',
    '!=',
    'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.is_not_empty'
  )
  // static readonly RANGE = new TwoOperandOperatorFe('range', '', 'contains');

  private constructor(key: string, symbol: any, label: string) {
    super(key, symbol, label)
  }

  public static opByKey(key: string): TwoOperandOperatorFe {
    switch (key) {
      case TwoOperandOperatorFe.AND.key:
        return TwoOperandOperatorFe.AND
      case TwoOperandOperatorFe.OR.key:
        return TwoOperandOperatorFe.OR
      case TwoOperandOperatorFe.EQ.key:
        return TwoOperandOperatorFe.EQ
      case TwoOperandOperatorFe.GT.key:
        return TwoOperandOperatorFe.GT
      case TwoOperandOperatorFe.LT.key:
        return TwoOperandOperatorFe.LT
      case TwoOperandOperatorFe.GTQ.key:
        return TwoOperandOperatorFe.GTQ
      case TwoOperandOperatorFe.LTQ.key:
        return TwoOperandOperatorFe.LTQ
      case TwoOperandOperatorFe.IN.key:
        return TwoOperandOperatorFe.IN
      case TwoOperandOperatorFe.NOTNULL.key:
        return TwoOperandOperatorFe.NOTNULL
      default:
      // throw new ServerError(ServerErrorCode.UNRECOGNIZED_ONE_OPERAND_OPERATOR)
    }
  }
}
