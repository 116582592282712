import { DataGridRow } from 'src/app/components/projects/data-grid-ui/model/DataGridRow'

export class RowFe {
  public rowId: string
  public status: string
  public values: any[] = []
  public ordinal: number
  public mgmtRowValues: any[] = [] //Master Table: [source, sourceId]

  public selectedInFilter: boolean = false

  public static fromTransfer(transfer: any, ordinal: number) {
    let row = new RowFe()
    row.rowId = transfer['rowId']
    row.status = transfer['status']
    row.values = transfer['values']
    row.mgmtRowValues = transfer['mgmtRowValues']
    row.ordinal = ordinal
    return row
  }

  public toDataGridRow(): DataGridRow {
    let dgRow = new DataGridRow()
    dgRow.ordinal = this.ordinal
    dgRow.rowId = this.rowId
    dgRow.status = this.status
    dgRow.values = this.values
    return dgRow
  }

  public static fromDataGridRow(dgr: DataGridRow): RowFe {
    let row = new RowFe()
    row.ordinal = dgr.ordinal
    row.rowId = dgr.rowId
    row.status = dgr.status
    row.values = dgr.values
    return row
  }
}
