<div *ngIf="loadingInProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card-content" *ngIf="!loadingInProgress" style="max-width: 100% !important">
  <div class="card-body p-1">
    <div class="d-flex flex-row justify-content-between">
      <span class="mb-0 pb-0">
        <p *ngIf="datahubService.isHomeView()" class="pb-0 mb-0">
          {{ locale('locale_key.pages.datahub.do_submissions.description') }}
        </p>
      </span>
      <a class="underline mb-0 pb-0" (click)="groupedBy = null; clearAllFilters()">
        <span *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
          {{ locale('locale_key.general.action.clear_filters') }}
        </span>
        <span *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize()">
          {{ locale('locale_key.pages.datahub.button.clear_filter') }}
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3" [ngClass]="datahubService.isDOSubmissionsView() ? 'col-lg-3' : 'col-lg-4'">
        <input
          type="text"
          class="form-control"
          placeholder="{{ locale('locale_key.general.search') }}"
          #search
          (input)="applySearchFilter(search.value)"
        />
      </div>
      <div
        class="col-md-6 mb-3 btn-group"
        dropdown
        container="body"
        [ngClass]="datahubService.isDOSubmissionsView() ? 'col-lg-3' : 'col-lg-4'"
      >
        <button
          type="button"
          class="form-control"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ locale('locale_key.pages.datahub.filter_group_by') }}
          {{ groupedBy ? groupedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <a class="dropdown-item" (click)="groupedBy = null">{{ locale('locale_key.pages.datahub.filter_none') }}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" *ngFor="let option of groupedByOptions" (click)="group(option)">{{ option.name }}</a>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-4 mb-3 btn-group"
        dropdown
        container="body"
        *ngIf="!datahubService.isDOSubmissionsView()"
      >
        <button
          type="button"
          class="form-control"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ locale('locale_key.pages.datahub.filter_sort_by') }}
          {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <ng-container *ngFor="let option of groupedByOptions">
            <div class="dropdown-divider" *ngIf="option.divider"></div>
            <a class="dropdown-item" (click)="group(option)">{{ option.name }}</a>
          </ng-container>
        </div>
      </div>
      <div
        class="col-md-12 mb-3 btn-group"
        [ngClass]="datahubService.isDOSubmissionsView() ? 'col-lg-3' : 'col-lg-12'"
        dropdown
        container="body"
        [insideClick]="true"
      >
        <button
          type="button"
          class="form-control filter-button"
          id="filter-button"
          dropdownToggle
          aria-controls="filter-dropdown-menu"
          style="text-align: left"
        >
          <p class="d-inline m-0">
            <i class="la la-filter"></i>
            {{ locale('locale_key.pages.datahub.filter_dropdown') }}
          </p>
          <p
            class="text-muted filter-text text-truncate col-7 m-0"
            [title]="getCustomFilterText()"
            *ngIf="customMultipleFilters.length > 0 || selectedStatus"
          >
            :{{ getCustomFilterText() }}
          </p>
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="filter-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="filter-button"
          [ngClass]="{ 'width-dropdown': screenSize.isXSmallSize() }"
        >
          <div class="accordion-body px-0 pt-0">
            <div class="bg-light-gray col pt-3">
              <div class="d-flex flex-row justify-content-between px-2">
                <span class="bold">{{ locale('locale_key.pages.datahub.applied_filters') }}</span>
                <a class="underline" (click)="clearAllFilters()">
                  {{ locale('locale_key.pages.datahub.button.clear_all_filters') }}
                </a>
              </div>
              <div class="row px-2">
                <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">
                  {{ locale('locale_key.pages.datahub.filter_none') }}
                </i>
                <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedStatus">
                  {{ getCustomFilterText() }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
              <div class="d-flex" [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="selectedStatus"
                    (change)="applyFilters()"
                    name="status"
                    value="CREATED"
                    id="sent"
                  />
                  <label class="form-check-label" for="sent">
                    {{ locale('locale_key.pages.datahub.do_submissions.filter.show_requests_sent') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="selectedStatus"
                    (change)="applyFilters()"
                    name="status"
                    value="APPROVED"
                    id="completed"
                  />
                  <label class="form-check-label" for="completed">
                    {{ locale('locale_key.pages.datahub.do_submissions.filter.show_completed_requests') }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    [(ngModel)]="selectedStatus"
                    (change)="applyFilters()"
                    name="status"
                    value="IN_PROGRESS"
                    id="inProgress"
                  />
                  <label class="form-check-label" for="inProgress">
                    {{ locale('locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests') }}
                  </label>
                </div>
              </div>
              <div class="d-flex flex-row">
                <a class="underline" (click)="selectedStatus = null; applyFilters()">
                  {{ locale('locale_key.pages.datahub.button.clear_filter') }}
                </a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item" *ngIf="!datahubService.isDataCategoryFilter()">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      [ngClass]="{ 'text-muted': datahubService.isDataCategoryFilter() }"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#companyCollapse"
                      aria-expanded="false"
                      aria-controls="companyCollapse"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}
                      </span>
                    </button>
                  </h2>
                  <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterEntities(entitySearch.value)"
                            #entitySearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <a class="underline" (click)="selectAll('entityCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('entityCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let item0 of filteredEntities; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="entityCheckbox"
                          id="{{ item0.key }}"
                          [checked]="isCustomFilterSelected(item0)"
                          (change)="changeCustomFilters($event, item0, 0)"
                        />
                        <i
                          class="{{ depTaxonomy.icon(item0) }} me-2 fs-5"
                          [ngStyle]="{ color: depTaxonomy.color(item0) }"
                        ></i>
                        <label>{{ item0.getLabel() }}</label>
                        <div class="ms-3">
                          <div *ngFor="let item1 of depTaxonomy.childrenSortedByOrdinal(item0.key); let i = index">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              class="entityCheckbox"
                              id="{{ item1.key }}"
                              [checked]="isCustomFilterSelected(item1)"
                              (change)="changeCustomFilters($event, item1, 1)"
                            />
                            <label>{{ item1.getLabel() }}</label>
                            <div class="ms-3">
                              <div *ngFor="let item2 of depTaxonomy.childrenSortedByOrdinal(item1.key); let i = index">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  class="entityCheckbox"
                                  id="{{ item2.key }}"
                                  [checked]="isCustomFilterSelected(item2)"
                                  (change)="changeCustomFilters($event, item2, 2)"
                                />
                                <label>{{ item2.getLabel() }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.general.user_role.data_reporters') }}
                      </span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterDataReporters(contactSearch.value)"
                            #contactSearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <a class="underline" (click)="selectAll('contactCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('contactCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let contact of filteredDataReporters; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="contactCheckbox"
                          id="company{{ i }}"
                          [checked]="isCustomFilterSelected(contact)"
                          (change)="changeCustomFilters($event, contact)"
                        />
                        <label class="col-4">{{ contact.getName() }}</label>
                        <label class="col-4">{{ contact.getCompanyName(stateService.activeWorkspace) }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span class="cursor" data-bs-toggle="tooltip" data-placement="top">
                        {{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}
                      </span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                    <div class="accordion-body scroll" style="max-height: 20rem">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ locale('locale_key.general.search') }}"
                            (input)="filterCompanies(companySearch.value)"
                            #companySearch
                          />
                        </div>
                        <div class="col-md-6 col-lg-6 mb-3 text-end">
                          <a class="underline" (click)="selectAll('companyCheckbox')">
                            {{ locale('locale_key.general.action.select_all') }}
                          </a>
                          <a class="underline" (click)="deselectAll('companyCheckbox')">
                            {{ locale('locale_key.general.action.deselect_all') }}
                          </a>
                        </div>
                      </div>
                      <div *ngFor="let company of filteredCompanies; let i = index">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          class="companyCheckbox"
                          id="company{{ i }}"
                          [checked]="isCustomFilterSelected(company)"
                          (change)="changeCustomFilters($event, company)"
                        />
                        <label>{{ company.supplierCompanyname }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-3 mb-3 btn-group"
        dropdown
        container="body"
        *ngIf="datahubService.isDOSubmissionsView()"
      >
        <button
          type="button"
          class="form-control"
          id="group-button"
          dropdownToggle
          aria-controls="group-dropdown-menu"
          style="text-align: left"
        >
          {{ locale('locale_key.pages.datahub.filter_sort_by') }}
          {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none') }}
        </button>
        <div
          class="ms-3 dropdown-menu"
          id="group-dropdown-menu"
          *dropdownMenu
          role="menu"
          aria-labelledby="group-button"
        >
          <ng-container *ngFor="let option of sortedByOptions">
            <div class="dropdown-divider" *ngIf="option.divider"></div>
            <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="scroll" style="max-height: 65vh; max-width: 100% !important" *ngIf="!groupedBy">
      <a
        class="accordion accordion-button m-auto row-design py-3 flex-wrap d-flex flex-row flex-container align-content-center border-t"
        id="requestsHeader"
        data-bs-toggle="collapse"
        href="#requests"
        aria-expanded="false"
        attr.aria-controls="requests"
        style="max-width: 100% !important"
      >
        <div class="flex-grow-1">
          <h5 class="text-truncate dark-text d-inline" *ngIf="!searchKeyword || searchKeyword == ''">
            {{ locale('locale_key.pages.datahub.do_submissions.table_header.all_requests') }}
          </h5>
          <h5 class="text-truncate dark-text d-inline" *ngIf="searchKeyword && searchKeyword != ''">
            {{ locale('locale_key.pages.datahub.search_result', { keyword: searchKeyword }) }}
          </h5>
        </div>
      </a>
      <div
        id="requests"
        role="tabpanel"
        attr.aria-labelledby="requestsHeader"
        class="border show flex-container"
        style="overflow: hidden !important; background-color: var(--complementary-color1); max-width: 100% !important"
      >
        <div class="card-content" style="max-width: 100% !important">
          <div class="card-body px-2" style="max-width: 100% !important">
            <div class="border-l border-r border-t m-0 p-0" style="max-width: 100% !important">
              <div
                class="d-flex flex-row flex-container py-3 px-2 mx-auto border-b bg-white cursor my-auto truncate"
                style="max-width: 100% !important"
              >
                <div
                  class="d-flex flex-row flex-container my-auto ps-0 truncate"
                  [ngClass]="
                    datahubService.isDOSubmissionsView()
                      ? 'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-11'
                      : 'col-xl-5 col-lg-5 col-md-5 col-11'
                  "
                >
                  <span
                    class="my-auto text-truncate bold dark-text"
                    [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                  >
                    {{ locale('locale_key.pages.datahub.do_submissions.table_header.request_name') }}
                  </span>
                </div>
                <div
                  class="justify-content-start my-auto d-none d-md-block"
                  [ngClass]="{ 'col-xl-2 col-lg-3 col-md-3 col-4': datahubService.isDOSubmissionsView() }"
                  *ngIf="datahubService.isDOSubmissionsView()"
                >
                  <span class="my-auto text-wrap bold dark-text">
                    {{ locale('locale_key.pages.datahub.do_submissions.table_header.number_of_data_reporters') }}
                  </span>
                </div>
                <div
                  class="d-none d-xl-block justify-content-start my-auto"
                  [ngClass]="datahubService.isDOSubmissionsView() ? 'col-xl-2 col-2' : 'col-xl-3 col-3'"
                >
                  <span class="my-auto text-truncate bold dark-text">
                    {{ locale('locale_key.pages.datahub.do_submissions.table_header.created_date') }}
                  </span>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-5 col-4 justify-content-start my-auto d-none d-sm-block">
                  <span class="my-auto text-truncate bold dark-text">
                    {{ locale('locale_key.pages.data_request.title.deadline') }}
                  </span>
                </div>
                <div class="col-xl-2 col-2 d-none d-md-block justify-content-start my-auto">
                  <span class="my-auto text-truncate bold dark-text">
                    {{ locale('locale_key.pages.datahub.data_entries.table_header.status') }}
                  </span>
                </div>
              </div>
              <ng-container *ngFor="let rg of filteredRequestGroups">
                <div [attr.id]="'groupRgDiv_' + rg.id">
                  <div
                    class="d-flex flex-row flex-container py-3 px-2 mx-auto border-b bg-white cursor my-auto"
                    [ngClass]="{ 'selected-row': checkSelectRg(rg) }"
                    (click)="selectRg(rg)"
                    style="max-width: 100% !important"
                  >
                    <div
                      class="d-flex flex-row flex-container my-auto ps-0"
                      [ngClass]="
                        datahubService.isDOSubmissionsView()
                          ? 'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-11'
                          : 'col-xl-5 col-lg-5 col-md-5 col-11'
                      "
                    >
                      <h5
                        class="my-auto text-wrap"
                        [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                      >
                        {{ rg.title }}
                      </h5>
                    </div>
                    <div
                      class="d-flex flex-row justify-content-start my-auto d-none d-md-flex"
                      [ngClass]="{ 'col-xl-2 col-lg-3 col-md-3 col-4': datahubService.isDOSubmissionsView() }"
                      *ngIf="datahubService.isDOSubmissionsView()"
                    >
                      <div class="icon-circle-dark me-2 my-auto">
                        <span class="text-white">{{ rg.requestCount }}</span>
                      </div>
                      <span class="my-auto text-wrap">
                        {{
                          rg.requestCount == 1
                            ? locale('locale_key.pages.datahub.button.data_reporter')
                            : locale('locale_key.general.user_role.data_reporters')
                        }}
                      </span>
                    </div>
                    <div
                      class="d-none d-xl-block justify-content-start my-auto"
                      [ngClass]="datahubService.isDOSubmissionsView() ? 'col-xl-2 col-3' : 'col-xl-3 col-3'"
                    >
                      <span class="my-auto">{{ rg.getDate() | date: 'dd MMM, yyyy' }}</span>
                    </div>
                    <div
                      class="col-xl-2 col-lg-3 col-md-3 col-sm-5 col-4 justify-content-start my-auto d-none d-sm-block"
                    >
                      <h6
                        class="badge due w-50"
                        [ngClass]="rg.getDeadlineClass()"
                        style="min-width: max-content !important"
                      >
                        <ng-container>{{ rg?.dueDate | date: 'dd MMM, yyyy' }}</ng-container>
                      </h6>
                    </div>
                    <div class="col-xl-2 col-2 d-none d-md-block justify-content-start my-auto">
                      <h6 class="badge mt-1" [ngClass]="rg.getStatusClass()">
                        {{ locale(rg.getStatusTextKeyword()) | titlecase }}
                      </h6>
                    </div>
                    <div
                      class="d-flex justify-content-end float-end pe-0"
                      [ngClass]="datahubService.isDOSubmissionsView() ? 'col-xl-1 col-lg-1 col-md-1 col-1 ' : 'd-none'"
                      *ngIf="datahubService.isDOSubmissionsView()"
                    >
                      <div class="btn-group my-auto">
                        <ng-container *ngIf="!screenSize.isXSmallSize()">
                          <i
                            class="la la-arrow-right fs-4 my-auto mb-0 pb-0 mx-2"
                            (click)="showRgResult(rg)"
                            *ngIf="anyRequestApproved(rg)"
                          ></i>
                        </ng-container>
                        <div
                          class="description-btn d-flex justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="la la-ellipsis-h fs-2 my-auto" style="rotate: 90deg"></i>
                        </div>
                        <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                          <button class="dropdown-item ms-0 ps-1" type="button" (click)="updateDetailsData(rg)">
                            <i class="la la-info me-1"></i>
                            {{ locale('locale_key.general.buttons.show_details') }}
                          </button>
                          <button class="dropdown-item ms-0 ps-1" type="button" (click)="openRequestGroup(rg)">
                            <i class="la la-envelope me-1"></i>
                            {{ locale('locale_key.pages.inventory.button.show_in_request') }}
                          </button>
                          <ng-container *ngIf="screenSize.isXSmallSize()">
                            <button
                              class="dropdown-item ms-0 ps-0"
                              type="button"
                              (click)="showRgResult(rg)"
                              *ngIf="anyRequestApproved(rg)"
                            >
                              <i class="la la-arrow-right fs-5 my-auto mb-0 pb-0 mx-1"></i>
                              {{ locale('locale_key.general.buttons.open') }}
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll" style="max-height: 65vh" *ngIf="groupedBy">
      <div class="mb-3" *ngFor="let group of filteredGroupedRequestGroups; let i = index">
        <a
          class="accordion accordion-icon-rotate m-auto w-100 row-design py-3 flex-wrap d-flex flex-row align-content-center border-t"
          id="requestsHeader{{ i }}"
          data-bs-toggle="collapse"
          href="#requests{{ i }}"
          aria-expanded="true"
          attr.aria-controls="requests{{ i }}"
        >
          <div class="col-xl-11 col-lg-11 col-md-11 col-10">
            <h5 class="text-truncate dark-text d-inline ps-2">{{ group.name }}</h5>
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-2 d-flex justify-content-end float-end pe-2">
            <a class="p-0 my-auto ms-auto float-end pe-2" style="max-height: 75px">
              <i class="icon float-end"></i>
            </a>
          </div>
        </a>
        <div
          id="requests{{ i }}"
          role="tabpanel"
          attr.aria-labelledby="requestsHeader{{ i }}"
          class="border show"
          style="overflow: hidden !important; background-color: var(--complementary-color1)"
        >
          <div class="card-content">
            <div class="card-body px-2">
              <div class="border-l border-r border-t m-0 p-0">
                <div class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto">
                  <div
                    class="d-flex flex-row my-auto ps-0"
                    [ngClass]="
                      datahubService.isDOSubmissionsView()
                        ? 'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-11'
                        : 'col-xl-5 col-lg-5 col-md-12 col-12'
                    "
                  >
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.do_submissions.table_header.request_name') }}
                    </span>
                  </div>
                  <div
                    class="justify-content-start my-auto d-none d-md-block"
                    [ngClass]="{ 'col-xl-2 col-lg-3 col-md-3 col-2': datahubService.isDOSubmissionsView() }"
                    *ngIf="datahubService.isDOSubmissionsView()"
                  >
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.do_submissions.table_header.number_of_data_reporters') }}
                    </span>
                  </div>
                  <div
                    class="d-none d-xl-flex justify-content-start my-auto"
                    [ngClass]="datahubService.isDOSubmissionsView() ? 'col-xl-2 col-2' : 'col-xl-3 col-3'"
                  >
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.do_submissions.table_header.created_date') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-6 justify-content-start my-auto d-none d-sm-block">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.data_request.title.deadline') }}
                    </span>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-3 col-2 d-none d-md-block justify-content-start my-auto">
                    <span class="my-auto text-truncate bold">
                      {{ locale('locale_key.pages.datahub.data_entries.table_header.status') }}
                    </span>
                  </div>
                </div>
                <ng-container *ngFor="let rg of group.requestGroups">
                  <div [attr.id]="'groupRgDiv_' + rg.id">
                    <div
                      class="d-flex flex-row py-3 px-2 mx-auto w-100 border-b bg-white cursor my-auto"
                      [ngClass]="{ 'selected-row': checkSelectRg(rg) }"
                      (click)="selectRg(rg)"
                    >
                      <div
                        class="d-flex flex-row my-auto ps-0"
                        [ngClass]="
                          datahubService.isDOSubmissionsView()
                            ? 'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-11'
                            : 'col-xl-5 col-lg-5 col-md-12 col-12'
                        "
                      >
                        <h5
                          class="text-bold-400 my-auto text-wrap"
                          [ngClass]="{ 'ps-3': !screenSize.isSmallerSize() || !screenSize.isXSmallSize() }"
                        >
                          {{ rg.title }}
                        </h5>
                      </div>
                      <div
                        class="d-flex flex-row justify-content-start my-auto d-none d-md-flex"
                        [ngClass]="{ 'col-xl-2 col-md-3 col-2': datahubService.isDOSubmissionsView() }"
                        *ngIf="datahubService.isDOSubmissionsView()"
                      >
                        <div class="icon-circle-dark me-2 my-auto">
                          <span class="text-white">{{ rg.requestCount }}</span>
                        </div>
                        <span class="my-auto text-truncate">
                          {{
                            rg.requestCount == 1
                              ? locale('locale_key.pages.datahub.button.data_reporter')
                              : locale('locale_key.general.user_role.data_reporters')
                          }}
                        </span>
                      </div>
                      <div
                        class="d-none d-xl-flex justify-content-start my-auto"
                        [ngClass]="datahubService.isDOSubmissionsView() ? 'col-xl-2 col-2' : 'col-xl-3 col-3'"
                      >
                        <span class="my-auto">{{ rg.getDate() | date: 'dd MMM, yyyy' }}</span>
                      </div>
                      <div
                        class="col-xl-2 col-lg-3 col-md-3 col-sm-5 col-2 justify-content-start my-auto d-none d-sm-block"
                      >
                        <h6
                          class="badge due w-50"
                          [ngClass]="rg.getDeadlineClass()"
                          style="min-width: max-content !important"
                        >
                          <ng-container>{{ rg?.dueDate | date: 'dd MMM, yyyy' }}</ng-container>
                        </h6>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-2 d-none d-sm-flex justify-content-start my-auto">
                        <h6
                          class="badge mt-1"
                          [ngClass]="{
                            'badge-turquoise': rg.status == 'CREATED',
                            'badge-orange': rg.status == 'IN_PROGRESS',
                            'badge-green': rg.status == 'APPROVED'
                          }"
                        >
                          {{
                            (rg.status == 'CREATED'
                              ? locale('locale_key.general.state.sent')
                              : rg.status == 'APPROVED'
                                ? locale('locale_key.general.state.completed')
                                : locale('locale_key.general.state.in_progress')
                            ) | titlecase
                          }}
                        </h6>
                      </div>
                      <div
                        class="d-flex justify-content-end float-end pe-0"
                        [ngClass]="
                          datahubService.isDOSubmissionsView() ? 'col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 ' : 'd-none'
                        "
                        *ngIf="datahubService.isDOSubmissionsView()"
                      >
                        <div class="btn-group my-auto">
                          <ng-container *ngIf="!screenSize.isXSmallSize()">
                            <i
                              class="la la-arrow-right fs-4 my-auto mb-0 pb-0 mx-2"
                              (click)="showRgResult(rg)"
                              *ngIf="anyRequestApproved(rg)"
                            ></i>
                          </ng-container>
                          <div
                            class="description-btn d-flex justify-content-center"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="la la-ellipsis-h fs-2 my-auto" style="rotate: 90deg"></i>
                          </div>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="updateDetailsData(rg)">
                              <i class="la la-info me-1"></i>
                              {{ locale('locale_key.general.buttons.show_details') }}
                            </button>
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openRequestGroup(rg)">
                              <i class="la la-envelope me-1"></i>
                              {{ locale('locale_key.pages.inventory.button.show_in_request') }}
                            </button>
                            <ng-container *ngIf="screenSize.isXSmallSize()">
                              <button
                                class="dropdown-item ms-0 ps-0"
                                type="button"
                                (click)="showRgResult(rg)"
                                *ngIf="anyRequestApproved(rg)"
                              >
                                <i class="la la-arrow-right fs-5 my-auto mb-0 pb-0 mx-1"></i>
                                {{ locale('locale_key.general.buttons.open') }}
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
