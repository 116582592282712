<div class="modal-header px-3" style="border-radius: 0px; border: none">
  <h4 class="modal-title mt-2 text-uppercase" id="myModalLabel99">
    {{ locale('locale_key.pages.data_request.details.title') }}
  </h4>
</div>
<div class="m-0 p-0">
  <div class="details">
    <div class="modal-body p-2 mb-2" style="height: 70vh; overflow-y: scroll !important; overflow-x: hidden">
      <div class="content clearfix">
        <div style="overflow-x: hidden">
          <div class="row">
            <div class="col-4 text-center">
              <i class="las la-envelope mb-3" style="font-size: 4rem"></i>
            </div>
            <div class="col-8 text-start">
              <h3 class="text-start mb-0 pb-3 ps-3 mt-3">{{ requestGroup?.title }}</h3>
            </div>
          </div>
          <div class="mt-4">
            <h4 class="ms-3 mt-4 mb-0">{{ locale('locale_key.pages.data_request.details.description.title') }}</h4>
            <div class="ms-3 multiline">{{ requestGroup?.description }}</div>
          </div>
          <div class="ms-4">
            <div class="mt-4 row">
              <span class="" [ngClass]="!request ? 'col-7' : 'col-6'">
                {{ locale('locale_key.general.state.created') }}
              </span>
              <div class="" [ngClass]="!request ? 'col-5' : 'col-6'">
                <span class="" [ngClass]="{ 'ms-2': request }">{{ locale('locale_key.heappening.deadline') }}</span>
              </div>
            </div>
            <div class="mt-0 row">
              <span class="" [ngClass]="!request ? 'col-7' : 'col-6'">
                {{ requestGroup?.date | date: 'dd MMM, yyyy' }}
              </span>
              <div class="" [ngClass]="!request ? 'col-5' : 'col-6'">
                <p [ngClass]="{ 'ms-2': request }">
                  <span class="badge" [ngClass]="getDeadline(requestGroup?.dueDate)">
                    {{ requestGroup?.dueDate | date: 'dd MMM, yyyy' }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!request">
            <div class="ms-3">
              <div class="mt-4 row">
                <span class="col-7">{{ locale('locale_key.general.user_role.data_reporter') }}</span>
                <span class="col-5">{{ locale('locale_key.general.user_information.user_status') }}</span>
              </div>
              <div class="mt-0 row" *ngFor="let request of requestGroup.requests">
                <div class="col-7">
                  <div class="d-flex flex-row text-truncate">
                    <span class="circle-icon ms-0 me-1" style="width: 20px; height: 20px">
                      <i class="las la-user text-white fs-4"></i>
                    </span>
                    <span class="text-truncate">{{ request.assigneeFirstName + ' ' + request.assigneeLastName }}</span>
                  </div>
                </div>
                <div class="col-5">
                  <p
                    class="badge"
                    [ngClass]="{
                      'badge-turquoise': request.status == 'CREATED',
                      'badge-orange': request.status == 'IN_PROGRESS',
                      'badge-green': request.status == 'APPROVED',
                      'badge-gray': request.status == 'CLOSED'
                    }"
                  >
                    {{
                      (request.status == 'CREATED'
                        ? locale('locale_key.general.state.sent')
                        : request.status == 'IN_PROGRESS'
                          ? locale('locale_key.general.state.in_progress')
                          : request.status == 'APPROVED'
                            ? locale('locale_key.general.state.completed')
                            : request.status == 'CLOSED'
                              ? locale('locale_key.general.state.closed')
                              : ''
                      ) | titlecase
                    }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="request">
            <div class="row mt-4">
              <div class="col-6 text-center">
                <div class="circle-icon me-4 ms-4"><i class="las la-user font-large-2 text-white"></i></div>
              </div>
              <div class="col-6 text-start">
                <p class="text-start m-0 ps-3 text-truncate">
                  {{ request.assigneeFirstName + ' ' + request.assigneeLastName }}
                </p>
                <p class="text-start m-0 ps-3 text-truncate text-muted">
                  {{
                    request.supplierCompanyName ? request.supplierCompanyName : stateService.activeWorkspace.companyName
                  }}
                </p>
                <p
                  class="badge ms-3"
                  [ngClass]="{
                    'badge-turquoise': request.status == 'CREATED',
                    'badge-orange': request.status == 'IN_PROGRESS',
                    'badge-green': request.status == 'APPROVED',
                    'badge-gray': request.status == 'CLOSED'
                  }"
                >
                  {{
                    (request.status == 'CREATED'
                      ? locale('locale_key.general.state.sent')
                      : request.status == 'IN_PROGRESS'
                        ? locale('locale_key.general.state.in_progress')
                        : request.status == 'APPROVED'
                          ? locale('locale_key.general.state.completed')
                          : request.status == 'CLOSED'
                            ? locale('locale_key.general.state.closed')
                            : ''
                    ) | titlecase
                  }}
                </p>
              </div>
            </div>
          </ng-container>
          <div class="ms-3">
            <div class="d-flex mt-3 flex-row justify-content-between">
              <div class="d-flex flex-row">
                <a (click)="intervals = !intervals">
                  <h5 class="pe-2 ms-0 ps-0">{{ locale('locale_key.pages.data_request.details.title.recurrence') }}</h5>
                </a>
                <i
                  class="la la-info-circle dropdown-toggle info-popover-arrow hide-info-caret"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div class="dropdown-menu arrow p-2 info-popover">
                  <p>{{ locale('locale_key.pages.data_request.create_wizard.title.deadline_frequency.info') }}</p>
                </div>
              </div>
              <span class="ms-4 me-auto" *ngIf="isRecurring(requestGroup) && !isEditingRecurrence">
                <a class="underline dark-text" (click)="showIntervals()">
                  {{
                    morePeriods
                      ? locale('locale_key.pages.data_request.details.button.see_less')
                      : locale('locale_key.pages.data_request.details.button.see_more')
                  }}
                </a>
              </span>
              <i
                class="la la-edit cursor ms-auto fs-4 pt-1 me-2"
                (click)="editRecurrence()"
                *ngIf="isRecurring(requestGroup) && !isEditingRecurrence"
              ></i>
            </div>

            <div class="border mx-0" *ngIf="isEditingRecurrence">
              <div
                class="nav nav-underline nav-tabs px-1 w-100"
                style="width: 80%; border-bottom: 2px solid var(--gray) !important; position: sticky; top: 0"
              >
                <ul
                  class="nav nav-underline nav-tabs no-hover-bg nav-justified"
                  style="width: 100%; border-bottom: none !important"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active text-start"
                      id="baseIcon-tab1"
                      data-bs-toggle="tab"
                      aria-controls="tabIcon1"
                      href="#tabIcon1"
                      aria-expanded="true"
                    >
                      {{ locale('locale_key.pages.data_request.details.title.fixed_intervals') }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content w-100 px-2">
                <div
                  role="tabpanel"
                  class="tab-pane active"
                  id="tabIcon1"
                  aria-expanded="true"
                  aria-labelledby="baseIcon-tab1"
                >
                  <div class="row pt-3 pb-2">
                    <div class="col-5 me-0 pe-0">
                      <p class="pt-1">
                        {{
                          locale('locale_key.pages.data_request.create_wizard.deadline_frequency.title.next_deadline')
                        }}
                      </p>
                    </div>
                    <div class="col-7 ms-0 ps-0">
                      <input
                        type="date"
                        [(ngModel)]="nextDeadline"
                        class="form-control w-100"
                        (change)="nextDeadlineChanged()"
                      />
                      <span *ngIf="showDeadlineError" class="ms-3" style="color: #ff4961; font-size: 80%">
                        {{ deadlineErrorMsg }}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col-5 me-0 pe-0">
                      <p class="pt-1">
                        {{
                          locale('locale_key.pages.data_request.create_wizard.deadline_frequency.title.send_request')
                        }}
                      </p>
                    </div>
                    <div class="col-7 ms-0 ps-0">
                      <div class="row px-0 mx-0">
                        <input
                          type="number"
                          [(ngModel)]="sendOutIntervalValue"
                          (change)="sendOutChanged()"
                          class="form-control"
                          style="display: inline-block; width: 28%"
                        />
                        <select
                          [(ngModel)]="sendOutIntervalUnit"
                          (change)="sendOutChanged()"
                          class="form-select form-control d-inline-block ms-1"
                          style="width: 70%"
                        >
                          <option value="DAY">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.reminder.type.days_before_the_deadline'
                              )
                            }}
                          </option>
                          <option value="WEEK">
                            {{
                              locale('locale_key.pages.data_request.details.reminder.type.weeks_before_the_deadline')
                            }}
                          </option>
                          <option value="MONTH">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.months_before_the_deadline'
                              )
                            }}
                          </option>
                          <option value="YEAR">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.years_before_the_deadline'
                              )
                            }}
                          </option>
                        </select>
                        <span *ngIf="showSendOutDayError" style="color: #ff4961; font-size: 80%">
                          {{ sendOutDayErrorMsg }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col-5 me-0 pe-0">
                      <p class="pt-1">
                        {{ locale('locale_key.pages.data_request.create_wizard.deadline_frequency.title.repeat') }}
                      </p>
                      <p class="pt-1" *ngIf="selectedRepeatPreset == 'CUSTOM'">
                        {{ locale('locale_key.pages.data_request.after_every') }}
                      </p>
                      <p class="pt-1" *ngIf="selectedRepeatPreset == 'CUSTOM' && selectedCustomRepeatUnit == 'WEEKLY'">
                        {{ locale('locale_key.pages.data_request.on') }}
                      </p>
                    </div>
                    <div class="col-7 ms-0 ps-0">
                      <div class="row px-0 mx-0 mb-2">
                        <select
                          class="form-select form-control"
                          style="width: 100%"
                          [(ngModel)]="selectedRepeatPreset"
                          (change)="repeatTypeChanged()"
                        >
                          <option [value]="repeatPreset.value" *ngFor="let repeatPreset of repeatPresets">
                            {{ repeatPreset.human }}
                          </option>
                          <option value="CUSTOM">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom'
                              )
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="row px-0 mx-0 mb-2" *ngIf="selectedRepeatPreset == 'CUSTOM'">
                        <input
                          type="number"
                          [(ngModel)]="selectedCustomRepeatValue"
                          (change)="repeatValueChanged()"
                          class="form-control"
                          style="display: inline-block; width: 28%"
                        />
                        <select
                          [(ngModel)]="selectedCustomRepeatUnit"
                          (change)="repeatValueChanged()"
                          class="form-select form-control d-inline-block ms-1 me-1"
                          style="width: 68%"
                        >
                          <option value="DAILY">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.days'
                              )
                            }}
                          </option>
                          <option value="WEEKLY">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.weeks'
                              )
                            }}
                          </option>
                          <option value="MONTHLY">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.months'
                              )
                            }}
                          </option>
                          <option value="YEARLY">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.years'
                              )
                            }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="row px-0 mx-0 mb-2"
                        *ngIf="selectedRepeatPreset == 'CUSTOM' && selectedCustomRepeatUnit == 'MONTHLY'"
                      >
                        <select
                          [(ngModel)]="selectedCustomRepeatMonthType"
                          class="form-control d-inline-block me-2"
                          (change)="repeatValueChanged()"
                        >
                          <option
                            [value]="monthlyRepeatPreset"
                            *ngFor="let monthlyRepeatPreset of customRepeatMonthlyPresets"
                          >
                            {{ monthlyRepeatPreset.human }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="row px-0 mx-0 mb-2"
                        *ngIf="selectedRepeatPreset == 'CUSTOM' && selectedCustomRepeatUnit == 'WEEKLY'"
                      >
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('MONDAY') }"
                          (click)="setCustomRepeatDayOfWeek('MONDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          M
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('TUESDAY') }"
                          (click)="setCustomRepeatDayOfWeek('TUESDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          T
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('WEDNESDAY') }"
                          (click)="setCustomRepeatDayOfWeek('WEDNESDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          W
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('THURSDAY') }"
                          (click)="setCustomRepeatDayOfWeek('THURSDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          T
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('FRIDAY') }"
                          (click)="setCustomRepeatDayOfWeek('FRIDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          F
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('SATURDAY') }"
                          (click)="setCustomRepeatDayOfWeek('SATURDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          S
                        </button>
                        <button
                          class="col-1 d-flex align-items-center justify-content-center me-1"
                          [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('SUNDAY') }"
                          (click)="setCustomRepeatDayOfWeek('SUNDAY')"
                          style="border: 1px solid lightgray; height: 30px; width: 30px"
                        >
                          S
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row pb-2">
                    <div class="col-5 me-0 pe-0">
                      <p class="pt-1">
                        {{ locale('locale_key.pages.data_request.create_wizard.deadline_frequency.title.end_repeat') }}
                      </p>
                    </div>
                    <div class="col-7 ms-0 ps-0">
                      <div class="px-0 mx-0">
                        <input type="radio" name="never" [(ngModel)]="selectedRecurrenceEnds" value="NEVER" />
                        <label for="never" class="px-2">
                          {{ locale('locale_key.pages.data_request.create_wizard.deadline_frequency.option.never') }}
                        </label>
                        <input type="radio" name="date" [(ngModel)]="selectedRecurrenceEnds" value="ON_DATE" />
                        <label class="px-2" for="date">
                          {{ locale('locale_key.pages.data_request.create_wizard.deadline_frequency.option.on_date') }}
                        </label>
                        <br />
                        <div class="d-flex flex-row ps-0">
                          <input
                            type="radio"
                            name="x"
                            [(ngModel)]="selectedRecurrenceEnds"
                            value="AFTER_MAX_OCCURENCES"
                            style="margin-left: 1px"
                          />
                          <label for="x" class="px-2">
                            {{
                              locale(
                                'locale_key.pages.data_request.create_wizard.deadline_frequency.option.after_x_occurrences'
                              )
                            }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-auto pb-3">
                    <div class="gray-bg p-3 w-100" *ngIf="selectedRecurrenceEnds == 'NEVER'">
                      <div class="col">
                        <ng-container *ngFor="let occurence of neverOccurrences; let idx = index">
                          <div>
                            <label for="date-input" class="mb-0 pb-0">
                              {{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}
                            </label>
                            <div class="d-flex">
                              <input
                                type="text"
                                [(ngModel)]="occurence.NAME"
                                (ngModelChange)="neverOccurrenceUpdated(occurence)"
                                class="form-control mb-1"
                                id="requesting-period-name"
                                placeholder="Name of requesting period"
                              />
                              <i
                                *ngIf="isLastNeverOccurence(idx)"
                                class="la la-trash-o cursor my-auto"
                                (click)="removeLastNeverOccurrence()"
                              ></i>
                            </div>
                          </div>
                        </ng-container>
                        <p class="mt-0 pt-0">
                          <a (click)="addNeverOccurence()" class="mt-0 pt-0">
                            <i class="la la-plus"></i>
                            {{ locale('locale_key.pages.data_request.button.add_name') }}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div class="text-center mx-auto" *ngIf="selectedRecurrenceEnds == 'ON_DATE'">
                      <div class="mx-auto">
                        <input
                          type="date"
                          [min]="nextDeadline"
                          [(ngModel)]="maxDateUI"
                          (change)="maxDateChanged()"
                          id="date-input"
                          class="mx-auto"
                        />
                        <span *ngIf="showMaxDateError" style="color: #ff4961; font-size: 80%">
                          {{ maxDateErrorMsg }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="gray-bg p-2 w-100 mt-3 mx-0"
                      *ngIf="selectedRecurrenceEnds == 'ON_DATE' && onDateOccurrences.length > 0"
                    >
                      <div class="col">
                        <ng-container *ngFor="let occurence of onDateOccurrences; let idx = index">
                          <div>
                            <label for="date-input" class="mb-0 pb-0">
                              {{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}
                            </label>
                            <div class="d-flex">
                              <input
                                type="text"
                                [(ngModel)]="occurence.NAME"
                                (ngModelChange)="onDateOccurrenceUpdated(occurence)"
                                class="form-control mb-1"
                                id="requesting-period-name"
                                placeholder="Name of requesting period"
                              />
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="text-center mx-auto" *ngIf="selectedRecurrenceEnds == 'AFTER_MAX_OCCURENCES'">
                      <div class="mt-3">
                        <input
                          type="number"
                          [min]="1"
                          id="occurrences-input"
                          class="w-25"
                          [(ngModel)]="maxOccurrences"
                          (change)="maxOccurrencesChanged()"
                        />
                        <label for="occurrences-input">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.deadline_frequency.option.recurrences')
                          }}
                        </label>
                      </div>
                    </div>
                    <div class="gray-bg p-2 w-100 mt-3 mx-0" *ngIf="selectedRecurrenceEnds == 'AFTER_MAX_OCCURENCES'">
                      <div class="col text-start" *ngFor="let occurence of occurrences">
                        <label for="date-input" class="mb-0 pb-0">
                          {{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}
                        </label>
                        <input
                          type="text"
                          [(ngModel)]="occurence.NAME"
                          (ngModelChange)="occurenceUpdated(occurence)"
                          class="form-control mb-3"
                          id="requesting-period-name"
                          placeholder="Name of requesting period"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row p-3">
                    <div class="ball-pulse loader-primary" *ngIf="updatingRecurrence">
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                    </div>
                    <ng-container *ngIf="!updatingRecurrence">
                      <button
                        type="button"
                        class="btn secondary-button custom-button me-2 ms-auto"
                        (click)="cancelEditingRecurrence()"
                      >
                        {{ locale('locale_key.general.buttons.close') }}
                      </button>
                      <button type="button" class="btn primary-button custom-button" (click)="startUpdateRecurrence()">
                        {{ locale('locale_key.general.buttons.confirm') }}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="border mx-0" *ngIf="isRecurring(requestGroup) && !isEditingRecurrence">
              <div class="m-0 p-0" *ngIf="morePeriods">
                <h6 class="ms-2 mt-3">{{ locale('locale_key.pages.data_request.title.previous_request_periods') }}</h6>
                <ng-container *ngFor="let period of getPreviousRequestPeriods(requestGroup)">
                  <div class="ms-4 mt-3">
                    <p class="mb-0 pb-0">{{ period.title }}</p>
                    <p class="mt-0 pt-0">
                      <b>{{ period.start }} - {{ period.end }}</b>
                    </p>
                  </div>
                </ng-container>
                <h6 class="ms-2 mt-3">{{ locale('locale_key.pages.data_request.title.current_request_period') }}</h6>
                <ng-container *ngFor="let period of getCurrentRequestPeriod(requestGroup)">
                  <div class="ms-4 mt-3">
                    <p class="mb-0 pb-0">{{ period.title }}</p>
                    <p class="mt-0 pt-0">
                      <b>{{ period.start }} - {{ period.end }}</b>
                    </p>
                  </div>
                </ng-container>
              </div>
              <h6 class="ms-2 mt-3">{{ locale('locale_key.pages.data_request.title.upcoming_request_periods') }}</h6>
              <ng-container *ngFor="let period of getUpcomingRequestPeriods(requestGroup)">
                <div class="ms-4 mt-3">
                  <p class="mb-0 pb-0">{{ period.title }}</p>
                  <p class="mt-0 pt-0">
                    <b>{{ period.start }} - {{ period.end }}</b>
                  </p>
                </div>
              </ng-container>
              <div class="m-0 p-0" *ngIf="morePeriods">
                <h6 class="ms-2 mt-3">{{ locale('locale_key.pages.data_request.details.title.end_recurrence') }}</h6>
                <ng-container *ngIf="requestGroup.recurrence.ends != 'NEVER'">
                  <ng-container *ngFor="let period of getLastRequestPeriod(requestGroup)">
                    <div class="ms-4 mt-3">
                      <p class="mb-0 pb-0">{{ period.title }}</p>
                      <p class="mt-0 pt-0">
                        <b>{{ period.start }} - {{ period.end }}</b>
                      </p>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="ms-4 mt-3" *ngIf="requestGroup.recurrence.ends == 'NEVER'">
                  <p class="mt-0 pt-0">
                    <b>{{ locale('locale_key.pages.data_request.details.title.doesnt_end') }}</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="recrrence_buttons d-flex flex-column">
              <button
                type="button"
                *ngIf="!isRecurring(requestGroup) && !isEditingRecurrence"
                class="btn tertiary-button mx-auto"
                (click)="editRecurrence()"
              >
                {{ locale('locale_key.pages.data_request.details.button.set_recurrence') }}
              </button>
              <button
                type="button"
                *ngIf="isRecurring(requestGroup) && isEditingRecurrence"
                class="btn btn-outline-danger me-auto mt-3"
                (click)="startStopRecurrence()"
              >
                {{ locale('locale_key.pages.data_request.details.button.stop_recurrence') }}
              </button>
            </div>
            <div class="col pt-3 mt-3 border-t mx-0 px-0">
              <div class="d-flex flex-row justify-content-between">
                <div class="d-flex">
                  <h5 class="mb-0 pb-2">{{ locale('locale_key.pages.data_request.create_wizard.title.reminder') }}</h5>
                </div>
                <a (click)="editReminder()" *ngIf="hasReminders(requestGroup) && !isEditingReminder">
                  <i class="la la-edit cursor ms-auto fs-4 pt-1 me-2"></i>
                </a>
              </div>
              <div class="mt-0 p-1 border" *ngIf="isEditingReminder">
                <div class="row ps-2">
                  <div class="form-group">
                    <br />
                    <label>{{ locale('locale_key.pages.data_request.create_wizard.button.send_reminders') }}</label>
                  </div>
                </div>
                <ng-container *ngFor="let reminder of reminders; let i = index">
                  <div class="d-flex flex-row">
                    <i class="la la-bell me-2 pt-2"></i>
                    <select [(ngModel)]="reminder.preset" class="form-select form-control" style="width: 220px">
                      <ng-container *ngIf="reminder.type === 'BEFORE_DEADLINE'">
                        <option value="WEEK_4">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.4_weeks_before_deadline')
                          }}
                        </option>
                        <option value="WEEK_2">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.2_weeks_before_deadline')
                          }}
                        </option>
                        <option value="WEEK_1">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.1_week_before_deadline')
                          }}
                        </option>
                        <option value="DAY_3">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.3_days_before_deadline')
                          }}
                        </option>
                        <option value="DAY_1">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.1_day_before_deadline')
                          }}
                        </option>
                        <option value="CUSTOM">
                          {{
                            locale(
                              'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom'
                            )
                          }}
                        </option>
                      </ng-container>
                      <ng-container *ngIf="reminder.type === 'AFTER_DEADLINE'">
                        <option value="WEEK_4">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.4_weeks_after_deadline')
                          }}
                        </option>
                        <option value="WEEK_2">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.2_weeks_after_deadline')
                          }}
                        </option>
                        <option value="WEEK_1">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.1_weeks_after_deadline')
                          }}
                        </option>
                        <option value="DAY_3">
                          {{
                            locale('locale_key.pages.data_request.create_wizard.reminder.type.3_days_after_deadline')
                          }}
                        </option>
                        <option value="DAY_1">
                          {{ locale('locale_key.pages.data_request.create_wizard.reminder.type.1_day_after_deadline') }}
                        </option>
                        <option value="CUSTOM">
                          {{
                            locale(
                              'locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom'
                            )
                          }}
                        </option>
                      </ng-container>
                    </select>
                    <i class="la la-trash-o cursor ms-2 pt-2" (click)="removeReminder(i)"></i>
                  </div>
                  <div class="d-flex flex-row pb-2">
                    <ng-container *ngIf="reminder.preset === 'CUSTOM'">
                      <div class="d-flex flex-row mx-0 px-0 pt-2 ms-1 ps-1">
                        <input
                          [(ngModel)]="reminder.value"
                          (change)="reminderValueChanged({ idx: i, value: reminder.value })"
                          type="number"
                          [min]="1"
                          class="form-control ms-3"
                          style="display: inline-block; max-width: 100px"
                        />
                        <select [(ngModel)]="reminder.unit" class="form-select form-control ms-1" style="width: auto">
                          <ng-container *ngIf="reminder.type === 'BEFORE_DEADLINE'">
                            <option value="DAY">
                              {{
                                locale(
                                  'locale_key.pages.data_request.create_wizard.reminder.type.days_before_the_deadline'
                                )
                              }}
                            </option>
                            <option value="WEEK">
                              {{
                                locale('locale_key.pages.data_request.details.reminder.type.weeks_before_the_deadline')
                              }}
                            </option>
                          </ng-container>
                          <ng-container *ngIf="reminder.type === 'AFTER_DEADLINE'">
                            <option value="DAY">
                              {{
                                locale(
                                  'locale_key.pages.data_request.create_wizard.reminder.type.days_after_the_deadline'
                                )
                              }}
                            </option>
                            <option value="WEEK">
                              {{
                                locale(
                                  'locale_key.pages.data_request.create_wizard.reminder.type.weeks_after_the_deadline'
                                )
                              }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="row ms-3">
                  <a class="me-3" (click)="addReminder('BEFORE_DEADLINE')">
                    <b><i class="la la-plus me-2"></i></b>
                    {{ locale('locale_key.pages.data_request.create_wizard.button.add_reminder') }}
                  </a>
                  <a class="me-3" (click)="addReminder('AFTER_DEADLINE')">
                    <b><i class="la la-plus me-2"></i></b>
                    {{ locale('locale_key.pages.data_request.create_wizard.button.add_reminder_for_overdue_request') }}
                  </a>
                </div>
                <div class="d-flex flex-row ms-3 mt-4">
                  <input [(ngModel)]="sendReminderToCreator" type="checkbox" />
                  <label class="ms-2 pt-0">
                    {{
                      locale('locale_key.pages.data_request.create_wizard.option.send_a_copy_of_reminder_email_to_me')
                    }}
                  </label>
                </div>
                <div class="row p-3">
                  <button
                    type="button"
                    class="btn secondary-button custom-button me-2 ms-auto"
                    (click)="cancelEditingReminder()"
                  >
                    {{ locale('locale_key.general.buttons.cancel') }}
                  </button>
                  <button
                    type="button"
                    class="btn primary-button custom-button"
                    *ngIf="!updatingReminder"
                    (click)="startScheduleReminder()"
                  >
                    {{ locale('locale_key.general.buttons.confirm') }}
                  </button>
                  <div class="ball-pulse loader-primary" *ngIf="updatingReminder">
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                  </div>
                </div>
              </div>
              <div class="mt-0 p-3 border" *ngIf="hasReminders(requestGroup) && !isEditingReminder">
                <p *ngIf="getLastSentReminder(requestGroup).length > 0">
                  {{ locale('locale_key.pages.data_request.details.title.last_reminder_sent') }}
                </p>
                <ng-container *ngFor="let reminder of getLastSentReminder(requestGroup)">
                  <h6>
                    <b>
                      <i class="la la-bell"></i>
                      {{ reminder }}
                    </b>
                  </h6>
                </ng-container>

                <p *ngIf="getScheduledReminders(requestGroup).length > 0">
                  {{ locale('locale_key.pages.data_request.details.title.scheduled_reminders') }}
                </p>
                <ng-container *ngFor="let reminder of getScheduledReminders(requestGroup)">
                  <h6>
                    <b>
                      <i class="la la-bell"></i>
                      {{ reminder }}
                    </b>
                  </h6>
                </ng-container>
              </div>
            </div>
            <div class="ms-3 mt-3">
              <div class="col border-b pb-3 text-center d-flex">
                <div class="ball-pulse loader-primary" *ngIf="isLoading">
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                </div>
                <ng-container *ngIf="!isLoading">
                  <button
                    type="button"
                    class="btn tertiary-button mx-auto"
                    (click)="editReminder()"
                    *ngIf="!hasReminders(requestGroup)"
                  >
                    {{ locale('locale_key.pages.data_request.details.title.schedule_reminder') }}
                  </button>
                  <button
                    type="button"
                    class="btn tertiary-button mx-auto"
                    *ngIf="requestGroup.recurrence"
                    (click)="startRemindNow()"
                  >
                    {{ locale('locale_key.pages.data_request.details.button.remind_now') }}
                  </button>
                </ng-container>
              </div>
              <div clas="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #stopRecurrence>
  <div class="modal-header alert-modal-header">
    <div
      class="icon-background d-inline mt-0"
      style="
        height: 18px;
        width: 18px;
        background-color: var(--orange);
        border-radius: 50%;
        border: 2px solidtext-white;
      "
    >
      <i
        class="las la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -2px;
          top: -1px;
        "
      ></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 me-auto mb-0" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.details.button.stop_recurrence') }}
    </h4>

    <button type="button" class="btn close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.data_request.stop_recurrence_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="cancelStopRecurrence()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="confirmStopRecurrence()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #remindNowModal>
  <div class="modal-header alert-modal-header">
    <div
      class="icon-background d-inline mt-0"
      style="
        height: 18px;
        width: 18px;
        background-color: var(--orange);
        border-radius: 50%;
        border: 2px solidtext-white;
      "
    >
      <i
        class="las la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -1px;
          top: -1px;
        "
      ></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 me-auto mb-0" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.details.button.remind_now') }}
    </h4>

    <button type="button" class="btn close" aria-label="Close" (click)="cancelRemindNow()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.data_request.remind_now_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="cancelRemindNow()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="confirmRemindNow()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #scheduleReminderModal>
  <div class="modal-header alert-modal-header">
    <div
      class="icon-background d-inline mt-0"
      style="
        height: 18px;
        width: 18px;
        background-color: var(--orange);
        border-radius: 50%;
        border: 2px solidtext-white;
      "
    >
      <i
        class="las la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -1px;
          top: -1px;
        "
      ></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 me-auto mb-0" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.details.title.scheduled_reminders') }}
    </h4>

    <button type="button" class="btn close" aria-label="Close" (click)="cancelScheduleReminder()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.data_request.schedule_reminder_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary" *ngIf="updatingReminder">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!updatingReminder">
      <button type="button" class="btn secondary-button custom-button" (click)="cancelScheduleReminder()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="confirmScheduleReminder()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #updateRecurrenceModal>
  <div class="modal-header alert-modal-header">
    <div
      class="icon-background d-inline mt-0"
      style="
        height: 18px;
        width: 18px;
        background-color: var(--orange);
        border-radius: 50%;
        border: 2px solidtext-white;
      "
    >
      <i
        class="las la-exclamation-circle text-white p-0"
        style="
          font-size: 1.5rem;
          background-color: transparent;
          border-radius: 50%;
          position: relative;
          left: -1px;
          top: -1px;
        "
      ></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 me-auto mb-0" id="myModalLabel12">
      {{ locale('locale_key.pages.data_request.edit_recurrence_modal.title') }}
    </h4>

    <button type="button" class="btn close" aria-label="Close" (click)="cancelUpdateRecurrence()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.data_request.edit_recurrence_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary" *ngIf="updatingRecurrence">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!updatingRecurrence">
      <button type="button" class="btn secondary-button custom-button" (click)="cancelUpdateRecurrence()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="confirmUpdateRecurrence()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
    </ng-container>
  </div>
</ng-template>
