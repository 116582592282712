import { DataGridRow } from '../model/DataGridRow'
import { DataGridTableData } from '../model/DataGridTableData'
import { DataGridTableMode } from '../model/DataGridTableMode'
import { DataGridObserverInterface } from '../service/DataGridObserverInterface'
import { DataGridServiceInterface } from '../service/DataGridServiceInterface'

export class DataGridIntegrationService implements DataGridServiceInterface {
  database: DataGridTableData = new DataGridTableData()
  observer: DataGridObserverInterface | undefined
  modified: boolean = false
  mode = DataGridTableMode.EXPORT_EXCEL_TABLE_DATA
  hasPagination = false

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode(): string {
    return this.mode
  }

  getMainTableData(): DataGridTableData {
    return this.database
  }

  hasMainTablePagination(): boolean {
    return false
  }

  loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData> {
    return new Promise<DataGridTableData>((resolve) => {
      resolve(this.database)
    })
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    this.modified = modified
  }

  hasDataModifiedElseWhere(): boolean {
    return this.modified
  }

  saveReferenceTable(table: DataGridTableData) {}

  getReferenceTables() {
    return [this.database]
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {}

  getNoOfMinRows(): number {
    return 15
  }
}
