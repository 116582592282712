import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { TemplateRef } from '@angular/core'
import { AbstractProjectInfoFe } from 'src/app/model/project/AbstractProjectInfoFe'
import { CCProjectInfoFe } from 'src/app/model/project/CCProjectInfoFe'
import { PROJECT_TYPE } from 'src/app/model/project/ProjectTypeFe'
import { CSRDProjectInfoFe } from 'src/app/model/project/CSRDProjectInfoFe'
import _ from 'lodash'

export const enum PROJECTS_PAGES {
  'CSRD_SETUP' = 'CSRD_SETUP',
  'CSRD_OVERVIEW' = 'CSRD_OVERVIEW',
  'VIEW_PROJECTS' = 'VIEW_PROJECTS'
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('addProjectModal') addProjectModal: TemplateRef<any>
  @ViewChild('duplicateProjectModal', { static: true }) duplicateProjectModal: TemplateRef<any>
  @ViewChild('duplicateCSRDProjectModal', { static: true }) duplicateCSRDProjectModal: TemplateRef<any>
  @ViewChild('duplicatingProjectModal', { static: true }) duplicatingProjectModal: TemplateRef<any>
  @ViewChild('deleteProjectModal', { static: true }) deleteProjectModal: TemplateRef<any>
  @ViewChild('cancelProjectDuplicationModal') cancelProjectDuplicationModal: TemplateRef<any>

  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingData: boolean = false
  projects: AbstractProjectInfoFe[]
  ccProjects: CCProjectInfoFe[]
  csrdProjects: CSRDProjectInfoFe[]
  projectToDelete: AbstractProjectInfoFe
  deletingProject: boolean = false
  projectToDuplicate: AbstractProjectInfoFe

  selectedProject: AbstractProjectInfoFe
  createNewProject: boolean = false
  createNewCSRDProject: boolean = false
  projectType = PROJECT_TYPE.CSRD
  activePage = PROJECTS_PAGES.VIEW_PROJECTS
  activeCsrdProject: CSRDProjectInfoFe

  pageToolbar = [
    [
      {
        shortLabel: this.slocale('Project'),
        longLabel: this.slocale('Project'),
        tooltip: this.slocale('Project'),
        icon: 'la la-plus',
        visible: () => true,
        actionName: 'add_project'
      }
    ]
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.info"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     icon: "la la-info",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ]
  ]

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  private async getProjects() {
    this.projects = await this.stateService.getProjectInfos()
    this.ccProjects = _.filter(this.projects, { type: PROJECT_TYPE.CC }).map((project) =>
      CCProjectInfoFe.fromTransfer(project)
    )
    this.csrdProjects = _.filter(this.projects, { type: PROJECT_TYPE.CSRD }).map((project) =>
      CSRDProjectInfoFe.fromTransfer(project)
    )
  }

  async ngOnInit(): Promise<void> {
    // TODO
    this.loadingData = true
    await this.getProjects()

    this.stateService.projectsUpdated.subscribe(async () => {
      await this.getProjects()
    })

    this.loadingData = false
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'add_project':
        this.openModal(this.addProjectModal, 'modal-lg')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  openProjectModal(project: any): void {
    this.selectedProject = project
    this.modalRef = this.modalService.show(this.addProjectModal)
  }

  duplicateProject(): void {}

  openModal(templateRef: TemplateRef<any>, size: string = '') {
    this.modalService.show(templateRef, { class: size })
  }

  close() {
    this.modalService.hide()
    this.createNewProject = false
  }

  createProject() {
    this.createNewProject = true
    this.modalService.hide()
  }

  selectProject(project) {
    this.selectedProject = project
  }

  startCreateNewCSRDProject() {
    this.activePage = PROJECTS_PAGES.CSRD_SETUP
    this.createNewCSRDProject = true
    this.activeCsrdProject = null
    this.modalService.hide()
  }

  showProjectsList() {
    let show = false
    switch (this.activePage) {
      case PROJECTS_PAGES.VIEW_PROJECTS:
        show = true
        break
    }
    return show
  }

  switchProjectsPage(page: PROJECTS_PAGES) {
    this.activePage = page
  }

  startDeleteProject(project: AbstractProjectInfoFe) {
    this.projectToDelete = project
    this.openModal(this.deleteProjectModal, 'modal-lg')
  }

  async confirmDeleteProject() {
    this.deletingProject = true
    await this.stateService.deleteProject(this.projectToDelete)
    this.modalService.hide()
    this.deletingProject = false
  }

  getDeleteProjectHeader() {
    return `Delete project '${this.projectToDelete.name}'?`
  }

  viewCsrdProject(project: CSRDProjectInfoFe) {
    this.activeCsrdProject = project
    this.stateService.updateActiveProject(this.activeCsrdProject)
    this.activePage = PROJECTS_PAGES.CSRD_OVERVIEW
    this.activeCsrdProject.definition.isStarted = true
  }

  resumeCSRDSetup(project: CSRDProjectInfoFe) {
    this.activeCsrdProject = project
    this.stateService.updateActiveProject(this.activeCsrdProject)
    this.activePage = PROJECTS_PAGES.CSRD_SETUP
  }

  startDuplicateProject(project: CSRDProjectInfoFe) {
    const csrdProject = { ...project, id: null }
    this.activeCsrdProject = csrdProject
    this.stateService.updateActiveProject(this.activeCsrdProject)
    this.openModal(this.duplicateCSRDProjectModal, 'modal-lg')
  }

  confirmDuplicateProject() {
    this.activePage = PROJECTS_PAGES.CSRD_SETUP
    this.modalService.hide()
  }

  viewNewCsrdProject(project: CSRDProjectInfoFe) {
    this.activeCsrdProject = project
    this.stateService.updateActiveProject(this.activeCsrdProject)
    this.activePage = PROJECTS_PAGES.CSRD_OVERVIEW
  }

  selectNewCSRDProject() {
    this.projectType = PROJECT_TYPE.CSRD
  }
}
