import { Component } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { DataGridTableData } from '../../model/DataGridTableData'
import { DataService } from '../../service/data.service'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class NestedParentHandlerModalComponent {
  type!: string
  table!: DataGridTableData
  childTable!: DataGridTableData
  selectedChildRowValues: string[] = []

  public contentAdded = new Subject<boolean>()
  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService
  ) {}

  closeModal(): void {
    this.modalRef.hide()
    document.body.classList.remove('modal-open')
  }
}
