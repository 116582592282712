export class Locale {
  constructor(
    public en: string,
    public sv: string,
    public fi: string,
    public da: string,
    public no: string,
    public de: string,
    public nl: string,
    public pl: string,
    public es: string,
    public it: string,
    public fr: string,
    public pt: string,
    public he: string,
    public ja: string,
    public zh: string,
    public hi: string
  ) {}
}

export class Language {
  constructor(
    public code: string,
    public label: string,
    public icon: string
  ) {}
}

export const ENGLISH = new Language('en', 'English', 'fi fi-us')
export const SWEDISH = new Language('sv', 'Swedish', 'fi fi-se')
export const FINNISH = new Language('fi', 'Finnish', 'fi fi-fi')
export const DANISH = new Language('da', 'Danish', 'fi fi-dk')
export const NORWEGIAN = new Language('no', 'Norwegian', 'fi fi-no')
export const GERMAN = new Language('de', 'German', 'fi fi-de')
export const DUTCH = new Language('nl', 'Dutch', 'fi fi-nl')
export const POLISH = new Language('pl', 'Polish', 'fi fi-pl')
export const SPANISH = new Language('es', 'Spanish', 'fi fi-es')
export const ITALIAN = new Language('it', 'Italian', 'fi fi-it')
export const FRENCH = new Language('fr', 'French', 'fi fi-fr')
export const PORTUGUESE = new Language('pt', 'Portuguese', 'fi fi-pt')
export const HEBREW = new Language('he', 'Hebrew', 'fi fi-il')
export const JAPANESE = new Language('ja', 'Japanese', 'fi fi-jp')
export const CHINESE = new Language('zh', 'Chinese', 'fi fi-cn')
export const HINDI = new Language('hi', 'Hindi', 'fi fi-in')
