<div class="category-switcher">
  <div class="title">{{ locale('locale_key.data_category.change_category_view_data_points') }}</div>
  <div class="chosen-categories" [class.disabled]="isDisabled" (click)="startChangeCategory()">
    <div class="chosen-category">
      <i class="icon" [class]="dcIcons[selectedDataCategory.level_1.key]"></i>
      <span class="name">{{ resolveLabel(selectedDataCategory.level_1.label) }}</span>
    </div>
    <div class="seperator">/</div>
    <div class="chosen-category">
      <i class="icon" [class]="dcIcons[selectedDataCategory.level_1.key]"></i>
      <span class="name">{{ resolveLabel(selectedDataCategory.level_2.label) }}</span>
    </div>
    <div class="seperator">/</div>
    <div class="chosen-category">
      <i class="icon" [class]="dcIcons[selectedDataCategory.level_1.key]"></i>
      <span class="name">{{ resolveLabel(selectedDataCategory.level_3.label) }}</span>
    </div>
    <div class="arrow">
      <i class="la la-angle-down"></i>
    </div>
  </div>
  <div class="datapoint" *ngIf="showDatapoint">
    {{ resolveLabel(selectedDatapoint.label) }}
  </div>
</div>

<!-- MODALS -->
<ng-template #categorySelectorModal>
  <div class="accordion accordion-flush" style="overflow-y: auto; max-height: calc(80vh)">
    <div
      *ngFor="let level_1 of dataCategories.deployed.level_1; let isFirst = first"
      class="accordion-item level_1_container"
    >
      <div class="accordion-header">
        <div
          class="accordion-button"
          [class.collapsed]="!isFirst"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#' + level_1.key"
          style="background: var(--grey-table-header, #e6e6e6); color: #374659"
        >
          <i class="icon" [class]="dcIcons[level_1.key]"></i>
          <span class="level_1_label">{{ resolveLabel(level_1.label) }}</span>
        </div>
      </div>
      <div [id]="level_1.key" class="accordion-collapse collapse" [class.show]="isFirst">
        <div class="accordion-body">
          <div
            *ngFor="let level_2 of dataCategories.deployed.level_2[level_1.key]"
            class="level_2_container d-flex"
            style="margin-bottom: 15px"
          >
            <div
              class="level_2_header"
              style="
                width: 130px;
                margin-right: 10px;
                color: var(--primary-colors-primary, #374659);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-wrap: nowrap;
              "
            >
              {{ resolveLabel(level_2.label) }} :
            </div>
            <div class="level_3_container" style="width: 100%; display: flex">
              <div
                *ngFor="let level_3 of dataCategories.deployed.level_3[level_2.key]"
                class="level_3_box"
                (click)="changeCategory({ level_1, level_2, level_3 })"
                style="padding: 4px 6px; margin-right: 10px; height: fit-content"
                [style.background]="selectedDataCategory.level_3.key == level_3.key ? '#59BCCF' : '#F1F4F4'"
              >
                <i class="icon" [class]="dcIcons[level_1.key]" style="margin-right: 7px"></i>
                <span class="level_3_label">{{ resolveLabel(level_3.label) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
