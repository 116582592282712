export class ValidationMessages {
  public static username = [{ message: 'Username is required.' }]
  public static password = [
    { message: 'Password is required.' },
    {
      type: 'minlength',
      message:
        'Password must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters.'
    },
    { type: 'pattern', message: 'Password and Confirm Password does not match.' }
  ]
  public static confirmpassword = [{ message: 'Confirm password is required.' }]
  public static verificationCode = [
    { message: 'Please enter a code' },
    { type: 'pattern', message: 'Please enter a valid code' }
  ]
}
