import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { KpiDefFe } from '../model/kpi/KpiDefFe'
import { DashboardFe } from '../model/dashboard/DashboardFe'
import { TableDataFe } from '../model/schema/TableDataFe'
import { EntityFe } from '../model/taxonomy/EntityFe'
import { RecoverPasswordFe } from '../model/org/RecoverPasswordFe'
import { ChangePasswordFe } from '../model/org/ChangePasswordFe'
import { HttpHeaders } from '@angular/common/http'
import { Manager_RegisterFe } from '../model/invitation/Manager_RegisterFe'
import { Contact_RegisterFe } from '../model/data-suppliers/company/Contact_RegisterFe'
import { Partner_RegisterFe } from '../model/data-suppliers/company/Partner_RegisterFe'
import { ManagerInvitation_AddFe } from '../model/invitation/ManagerInvitation_AddFe'
import { ManagerInvitation_Reject } from '../model/invitation/ManagerInvitation_Reject'
import { ContactInvitation_AddFe } from '../model/data-suppliers/company/ContactInvitation_AddFe'
import { ContactInvitation_RejectFe } from '../model/data-suppliers/company/ContactInvitation_RejectFe'
import { PartnerInvitation_AddFe } from '../model/data-suppliers/company/PartnerInvitation_AddFe'
import { PartnerInvitation_CheckExistingUserCompaniesFe } from '../model/data-suppliers/company/PartnerInvitation_CheckExistingUserCompaniesFe'
import { Partner_RegisterReuseExistedUserCompanyIdFe } from '../model/data-suppliers/company/Partner_RegisterReuseExistedUserCompanyIdFe'
import { PartnerInvitation_CheckIdClashFe } from '../model/data-suppliers/company/PartnerInvitation_CheckIdClashFe'
import { Partner_RegisterNonExistedCompanyFe } from '../model/data-suppliers/company/Partner_RegisterNonExistedCompanyFe'
import { Partner_RegisterWithIdClashResolutionFe } from '../model/data-suppliers/company/Partner_RegisterWithIdClashResolutionFe'
import { Company_AddFe } from '../model/invitation/Company_AddFe'
import { RequestGroup_AddFe } from '../model/data-suppliers/request/add/RequestGroup_AddFe'
import { SubmitMessageActivity_AddFe } from '../model/data-suppliers/timeline/add/SubmitMessageActivityFe_AddFe'
import { CloseRequestActivity_AddFe } from '../model/data-suppliers/timeline/add/CloseRequestActivity_AddFe'
import { AcceptSubmissionActivity_AddFe } from '../model/data-suppliers/timeline/add/AcceptSubmissionActivity_AddFe'
import { RejectSubmissionActivity_AddFe } from '../model/data-suppliers/timeline/add/RejectSubmissionActivity_AddFe'
import { LoginUserFe } from '../model/org/LoginUserFe'
import { SubmitAnswersActivity_AddFe } from '../model/data-suppliers/timeline/add/SubmitAnswersActivity_AddFe'
import { InvitationLinkFe } from '../model/email/InvitationLinkFe'
import { SharedDashboardFe } from '../model/shared/SharedDashboardFe'
import { SharedDashboard_AddFe } from '../model/shared/add/SharedDashboard_AddFe'
import { RecipientInfoFe } from '../model/dashboard/RecipientInfoFe'
import { RecipientInfo_DeleteFe } from '../model/shared/edit/RecipientInfo_DeleteFe'
import { RecipientInfo_AddFe } from '../model/shared/add/RecipientInfo_AddFe'
import { RecipientInfo_ModifyFe } from '../model/shared/edit/RecipientInfo_ModifyFe'
import { VisitorUserFe } from '../model/org/VisitorUserFe'
import { Dashboard_AddFe } from '../model/dashboard/add/Dashboard_AddFe'
import { UserCredential } from 'firebase/auth'
import { CompanyFe } from '../model/data-suppliers/company/CompanyFe'
import { Admin_AddFe } from '../model/user/Admin_AddFe'
import { CustomerSuccessFe } from '../model/user/CustomerSuccessFe'
import { CustomerSuccess_AddFe } from '../model/user/CustomerSuccess_AddFe'
import { CustomerSuccess_UpdateFe } from '../model/user/CustomerSuccess_UpdateFe'
import { SignInMethodUpdateFe } from '../model/signInMethod/SignInMethodUpdateFe'
import { RequestFolder_AddFe } from '../model/data-suppliers/request/add/RequestFolder_AddFe'
import { RequestFolderFe } from '../model/data-suppliers/request/RequestFolderFe'
import { ContactFe } from '../model/user/ContactFe'
import { DirectEntryInfoFe } from '../model/directEntry/DirectEntryInfoFe'
import { AutomatedFeedInfoFe } from '../model/automatedFeed/AutomatedFeedInfoFe'
import { RecentlyOpenedItemFe } from '../model/datahub/RecentlyOpenedItemFe'
import { InsightDefFe } from '../model/insight/InsightDefFe'
import { RequestGroup_UpdateFe } from '../model/data-suppliers/request/RequestGroup_UpdateFe'
import { UnitFe } from '../components/unit-systems/model/UnitFe'
import { CustomEmissionFactorFe } from '../model/emissions/CustomEmissionFactorFe'
import { TaxonomyInfoFe } from '../model/taxonomy/TaxonomyInfoFe'
import { AbstractProjectInfoFe } from '../model/project/AbstractProjectInfoFe'
import { AbstractProjectInfoAddFe } from '../model/project/add/AbstractProjectInfoAddFe'

export const InterceptorSkipAuth = 'X-Skip-AUTH'
export const InterceptorSkipAuthHeader = new HttpHeaders({
  'X-Skip-AUTH': ''
})

@Injectable({
  providedIn: 'root'
})
export class RouterFe {
  workspaceId: string

  constructor(private http: HttpClient) {}

  async login(email: string, password: string, googleUserCreds: UserCredential): Promise<any> {
    return this.http.post<LoginUserFe>('/api/auth/login', { email, password, googleUserCreds }, {}).toPromise()
  }

  async isLoggedIn(tokenHeader: HttpHeaders): Promise<any> {
    return this.http.get<boolean>('/api/auth/isLoggedIn', { headers: tokenHeader }).toPromise()
  }

  async getUserInfo(tokenHeader: HttpHeaders): Promise<any> {
    return this.http.get<any>('/api/auth/userinfo', { headers: tokenHeader }).toPromise()
  }

  async getSignupInfo(uuid: string): Promise<any> {
    return this.http.get<any[]>(`/api/auth/getSignupInfo/${uuid}`, { headers: InterceptorSkipAuthHeader }).toPromise()
  }

  async signup(signupInfo): Promise<boolean> {
    return this.http.post<any>('/api/auth/signup', signupInfo, { headers: InterceptorSkipAuthHeader }).toPromise()
  }

  async changePassword(userObj: ChangePasswordFe): Promise<boolean> {
    return this.http.post<boolean>('/api/auth/password/change', userObj).toPromise()
  }

  async changeLanguage(langCode: string): Promise<boolean> {
    return this.http.get<boolean>(`/api/session/language/set/${langCode}`).toPromise()
  }

  async changeProfileEmailLanguage(profileLanguage: string, emailLanguage: string): Promise<boolean> {
    return this.http
      .put<boolean>(`/api/session/languages`, {
        profileLanguage: profileLanguage,
        emailLanguage: emailLanguage
      })
      .toPromise()
  }

  async pulse(lastPulse: String): Promise<any> {
    // commenting this out to verify its affect on big query costs
    return false
    //return this.http.get<any[]>(`/api/session/pulse/${lastPulse}`).toPromise()
  }

  async changeEmailLanguage(langCode: string): Promise<boolean> {
    return this.http
      .put<boolean>(`/api/email/language`, {
        workspaceId: this.workspaceId,
        langCode: langCode
      })
      .toPromise()
  }

  async getCompanyEmailLanguage(activeWorkspace: string): Promise<any> {
    return this.http.get<any>(`/api/email/language/${activeWorkspace}`).toPromise()
  }

  async getEmailInvitationLang(email: string): Promise<any> {
    return this.http.get<any>(`/api/email/invitation/language/${email}`).toPromise()
  }

  async getAllEmailTemplates(): Promise<any> {
    return this.http.post<any>('/api/email/list/templates', { workspaceId: this.workspaceId }).toPromise()
  }

  async resetAllTemplates(): Promise<any> {
    return this.http.delete<any>(`/api/email/delete/templates/${this.workspaceId}`).toPromise()
  }

  async resetTemplate(templateName: string): Promise<any> {
    return this.http.delete<any>(`/api/email/delete/template/${this.workspaceId}/${templateName}`).toPromise()
  }

  async saveTemplate(fileName: string, langCode: string, template: string): Promise<any> {
    return this.http
      .post<any>('/api/email/template', {
        workspaceId: this.workspaceId,
        fileName: fileName,
        langCode: langCode,
        template: template
      })
      .toPromise()
  }

  async initResetPassword(email: string): Promise<boolean> {
    return this.http.post<boolean>('/api/auth/password/initreset', { email }).toPromise()
  }

  async isResetPasswordValid(uuid: string): Promise<boolean> {
    return this.http.get<boolean>(`/api/auth/password/resetvalid/${uuid}`).toPromise()
  }

  async resetPassword(userObj: RecoverPasswordFe): Promise<boolean> {
    return this.http.post<any>('/api/auth/password/reset', userObj).toPromise()
  }

  /*   async listCompanies(): Promise<CompanyInfoFe[]> {
    return this.http.get<any[]>(`/api/company/list`).toPromise();
  } */

  async listAllNotifications(): Promise<any[]> {
    //    return this.http.get<any>('api/notification/all');
    return []
  }

  async getNotificationOverview(): Promise<any> {
    //    return this.http.get<any>('api/notification/overview').toPromise();
    return { count: 0, notifications: [] }
  }

  async getExternalRequestsNotifications(): Promise<any[]> {
    //    return this.http.get<any>('api/notification/externalrequests');
    return []
  }

  async getFileNotifications(): Promise<any[]> {
    //    return this.http.get<any>('api/notification/files');
    return []
  }

  async getDeletedNotifications(): Promise<any[]> {
    //    return this.http.get<any>('api/notification/deleted');
    return []
  }

  async markNotificationDeleted(notification: any): Promise<boolean> {
    //    return this.http.delete<any>('api/notification/markdelete/' + notification.id);
    return true
  }

  async markNotificationRead(notification: any): Promise<boolean> {
    //    return this.http.get<any>('api/notification/markread/' + notification.id);
    return true
  }

  async deleteNotification(notification: any): Promise<boolean> {
    //    return this.http.delete<any>('api/notification/delete/' + notification.id);
    return true
  }

  async changeWorkspace(workspaceId: string, workspaceRole: string, googleUserCreds: any): Promise<boolean> {
    let changed = await this.http
      .post<any>(`/api/session/activeworkspace/set/${workspaceId}/${workspaceRole}`, { googleUserCreds })
      .toPromise()
    if (changed) {
      this.setWorkspaceId(workspaceId)
      return true
    } else {
      return false
    }
  }

  setWorkspaceId(id: string) {
    this.workspaceId = id
  }

  async getOrganizationInfos(): Promise<any> {
    let orgInfos = await this.http.get<any>(`/api/organizationInfo/${this.workspaceId}`).toPromise()
    return orgInfos
  }

  async updateNewOrganizationInfo(orgInfo): Promise<boolean> {
    let updated = await this.http.post<boolean>(`/api/organizationInfo/update/${this.workspaceId}`, orgInfo).toPromise()
    return updated
  }

  async deployNewOrganizationInfo(): Promise<any> {
    let orgInfos = await this.http.get<any>(`/api/organizationInfo/deploy/${this.workspaceId}`).toPromise()
    return orgInfos
  }

  async readOrganizationTables(): Promise<TableDataFe[]> {
    let tableDatas: TableDataFe[] = await this.http
      .get<any>(`/api/organizationtables/read/${this.workspaceId}`)
      .toPromise()
    return tableDatas
  }

  async updateOrganizatonTables(diffOrgTables: TableDataFe[]): Promise<TableDataFe[]> {
    let done = await this.http
      .post<any>(`/api/organizationtables/update/${this.workspaceId}`, diffOrgTables)
      .toPromise()
    return done
  }

  getGlobalDataTaxonomy(): Observable<any[]> {
    return this.http.get<any[]>(`/api/taxonomy/global`)
  }

  async getCompanyDataTaxonomyInfos(): Promise<any> {
    return this.http.get<any[]>(`/api/taxonomy/list/${this.workspaceId}`).toPromise()
  }

  async updateNewTaxonomyInfo(entities: TaxonomyInfoFe): Promise<boolean> {
    let updated = await this.http.post<boolean>(`/api/taxonomy/update/${this.workspaceId}`, entities).toPromise()
    return updated
  }

  async deployNewTaxonomyInfo(companyId = this.workspaceId): Promise<any> {
    let orgInfos = await this.http.get<any>(`/api/taxonomy/deploy/${companyId}`).toPromise()
    return orgInfos
  }

  addKpiDefinition(kpi: KpiDefFe): Observable<any> {
    return this.http.post<any>(`/api/kpiDefinition/add`, kpi)
  }

  updateKpiDefinition(kpi): Observable<any> {
    return this.http.post<any>(`/api/kpiDefinition/update`, kpi)
  }

  deleteKpiDefinition(id): Observable<boolean> {
    return this.http.delete<any>(`/api/kpiDefinition/delete/${id}`)
  }

  getUserKpisData(): Observable<any[]> {
    return this.http.get<any[]>(`/api/userKpisData`)
  }

  async getUserKpiData(kpiIds: string[]): Promise<any> {
    let kpiIdsStr = kpiIds.join(',')
    return await this.http.get<any[]>(`/api/userKpiData/${kpiIdsStr}`).toPromise()
  }

  async getSharedDashboardKpiData(email: string): Promise<any> {
    return await this.http.get<any[]>(`/api/sharedDashboardKpisData/${email}`).toPromise()
  }

  addInsightDef(insightDef: InsightDefFe): Observable<any> {
    return this.http.post<any>(`/api/insights/add`, insightDef)
  }

  updateInsightDef(insightDef): Observable<any> {
    return this.http.post<any>(`/api/insights/update`, insightDef)
  }

  deleteInsightDef(id): Observable<boolean> {
    return this.http.delete<any>(`/api/insights/delete/${id}`)
  }

  getInsights(): Observable<any[]> {
    return this.http.get<any[]>(`/api/insights/list`)
  }

  async getInsight(kpiIds: string[]): Promise<any> {
    let kpiIdsStr = kpiIds.join(',')
    return await this.http.get<any[]>(`/api/insights/list/${kpiIdsStr}`).toPromise()
  }

  async getSharedDashboardInsights(email: string): Promise<any> {
    return await this.http.get<any[]>(`/api/sharedDashboardInsights/${email}`).toPromise()
  }

  getEmissionDatabases(): Observable<any[]> {
    return this.http.get<any[]>(`/api/emissions/db/list/${this.workspaceId}`)
  }

  listDashboards(): Observable<DashboardFe[]> {
    return this.http.get<any[]>(`/api/dashboard/list/${this.workspaceId}`)
  }

  getDashboard(dashboardSharedId: string, companyId: string): Observable<DashboardFe[]> {
    return this.http.get<any[]>(`/api/dashboard/get/${companyId}/${dashboardSharedId}`)
  }

  addDashboard(dashboard: Dashboard_AddFe): Promise<boolean> {
    return this.http.post<boolean>(`/api/dashboard/add`, dashboard).toPromise()
  }

  updateDashboard(dashboard: DashboardFe): Observable<boolean> {
    return this.http.post<boolean>(`/api/dashboard/update`, dashboard)
  }

  deleteDashboard(id): Observable<boolean> {
    return this.http.delete<any>(`/api/dashboard/delete/${this.workspaceId}/${id}`)
  }

  async addSharedDashboard(dashboard: SharedDashboard_AddFe): Promise<boolean> {
    return this.http.post<boolean>(`/api/shared/dashboard/add`, dashboard).toPromise()
  }

  async deleteSharedDashboard(dashboardSharedId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/shared/dashboard/delete`, { objectSharedId: dashboardSharedId }).toPromise()
  }

  async listSharedDashboards(): Promise<SharedDashboardFe[]> {
    return this.http.get<SharedDashboardFe[]>(`/api/shared/dashboard/list`).toPromise()
  }

  async listDashboardRecipients(): Promise<RecipientInfoFe[]> {
    return this.http.get<RecipientInfoFe[]>(`/api/shared/dashboard/recipient/list`).toPromise()
  }

  async verifySharedDashboardEmail(objectSharedId: string, email: string): Promise<boolean> {
    return this.http
      .get<boolean>(`/api/shared/dashboard/verify/${objectSharedId}/${email}`, { headers: InterceptorSkipAuthHeader })
      .toPromise()
  }

  async generateSharedDashboardVerificationCode(email: string): Promise<boolean> {
    return this.http
      .get<boolean>(`/api/auth/code/generate/${email}`, { headers: InterceptorSkipAuthHeader })
      .toPromise()
  }

  async verifySharedDashboardVerificationCode(em: string, enteredCode: string): Promise<VisitorUserFe> {
    return this.http
      .post<VisitorUserFe>(
        `/api/auth/code/verify`,
        { email: em, verificationCode: enteredCode },
        { headers: InterceptorSkipAuthHeader }
      )
      .toPromise()
  }

  async isVisitorValid(tokenHeader: HttpHeaders): Promise<any> {
    return this.http.get<boolean>('/api/auth/visitor/isValid', { headers: tokenHeader }).toPromise()
  }

  async getVisitorInfo(tokenHeader: HttpHeaders): Promise<any> {
    return this.http.get<any>('/api/auth/visitorinfo', { headers: tokenHeader }).toPromise()
  }

  async logoutVisitor(): Promise<any> {
    return this.http.get<any>('/api/auth/logoutVisitor').toPromise()
  }

  async deleteSharedDashboardRecipient(recipient: RecipientInfo_DeleteFe, objectSharedId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/shared/dashboard/recipient/delete/${objectSharedId}`, recipient).toPromise()
  }

  async updateSharedDashboardRecipient(
    recipients: (RecipientInfo_AddFe | RecipientInfo_ModifyFe)[],
    objectSharedId: string
  ): Promise<boolean> {
    return this.http.post<boolean>(`/api/shared/dashboard/recipient/update/${objectSharedId}`, recipients).toPromise()
  }

  searchEmissions(searchSentence: string, selectedDatabases): Observable<any[]> {
    return this.http.post<any[]>(`/api/emissions/search/` + searchSentence, selectedDatabases)
  }

  suggestionsForEmissionSearch(searchSentence: string): Observable<any[]> {
    return this.http.get<any[]>(`/api/emissions/searchsuggestions/` + searchSentence)
  }

  async checkExistingUserCompanies(details: PartnerInvitation_CheckExistingUserCompaniesFe): Promise<any[]> {
    return this.http.post<any[]>('/api/invitation/partner/checkExistingUserCompanies', details).toPromise()
  }

  async registerExistingCompany(details: Partner_RegisterReuseExistedUserCompanyIdFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/partner/reuseExistedUserCompany', details).toPromise()
  }

  async registerNonExistingCompany(details: Partner_RegisterNonExistedCompanyFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/partner/nonExistedCompany', details).toPromise()
  }

  async registerAfterClashResolution(details: Partner_RegisterWithIdClashResolutionFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/partner/idClashResolution', details).toPromise()
  }

  async checkIdClash(details: PartnerInvitation_CheckIdClashFe): Promise<any> {
    return this.http.post<any>('/api/invitation/partner/checkIdClashing', details).toPromise()
  }

  async registerManager(register: Manager_RegisterFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/manager', register).toPromise()
  }

  async registerPartner(register: Partner_RegisterFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/partner', register).toPromise()
  }

  async registerContact(register: Contact_RegisterFe): Promise<boolean> {
    return this.http.post<boolean>('/api/register/contact', register).toPromise()
  }

  async deactivateContact(contact: ContactFe): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/partnership/deactivate/contact`, {
        affiliationId: contact.affiliationId,
        supplierCompanyId: contact.affiliationCompanyId
      })
      .toPromise()
  }

  async addManagerInvitation(invitation: ManagerInvitation_AddFe): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/manager/add`, invitation).toPromise()
  }

  async addContactInvitation(invitation: ContactInvitation_AddFe): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/contact/add`, invitation).toPromise()
  }

  async addPartnerInvitation(invitation: PartnerInvitation_AddFe): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/partner/add`, invitation).toPromise()
  }

  async getManagerInvitationExtendedByUUID(uuid: string): Promise<any> {
    return this.http
      .get<any[]>(`/api/invitation/manager/get/${uuid}`, { headers: InterceptorSkipAuthHeader })
      .toPromise()
  }

  async getContactInvitationExtendedByUUID(uuid: string): Promise<any> {
    return this.http.get<any>(`/api/invitation/contact/get/${uuid}`, { headers: InterceptorSkipAuthHeader }).toPromise()
  }

  async checkExistedContactEmailForPartnerInvitation(uuid: string): Promise<any> {
    return this.http
      .get<any>(`/api/invitation/partner/checkExistedContactEmail/${uuid}`, { headers: InterceptorSkipAuthHeader })
      .toPromise()
  }

  async getContactInvitations(): Promise<any[]> {
    return this.http.get<any>(`/api/invitation/contact/list`).toPromise()
  }

  async getPartnerInvitations(): Promise<any[]> {
    return this.http.get<any>(`/api/invitation/partner/list`).toPromise()
  }

  async acceptManagerInvitation(invitationId: string): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/invitation/manager/accept/${invitationId}`, invitationId, {
        headers: InterceptorSkipAuthHeader
      })
      .toPromise()
  }

  async acceptContactInvitation(invitationId: string): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/invitation/contact/accept/${invitationId}`, invitationId, {
        headers: InterceptorSkipAuthHeader
      })
      .toPromise()
  }

  async acceptPartnerInvitation(invitationId: string): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/invitation/partner/accept/${invitationId}`, invitationId, {
        headers: InterceptorSkipAuthHeader
      })
      .toPromise()
  }

  async rejectManagerInvitation(invitation: ManagerInvitation_Reject): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/invitation/manager/reject/${invitation.uuid}`, invitation, {
        headers: InterceptorSkipAuthHeader
      })
      .toPromise()
  }

  async rejectContactInvitation(invitation: ContactInvitation_RejectFe): Promise<boolean> {
    return this.http
      .post<boolean>(`/api/invitation/contact/reject/${invitation.uuid}`, invitation, {
        headers: InterceptorSkipAuthHeader
      })
      .toPromise()
  }

  async rejectPartnerInvitation(uuid: string, rejectionReason: string, rejectionExplanation: string): Promise<boolean> {
    return this.http
      .post<boolean>(
        `/api/invitation/partner/reject/${uuid}`,
        { rejectionExplanation, rejectionReason },
        { headers: InterceptorSkipAuthHeader }
      )
      .toPromise()
  }

  async cancelManagerInvitation(invitationId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/manager/cancel/${invitationId}`, invitationId).toPromise()
  }

  async cancelContactInvitation(invitationId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/contact/cancel/${invitationId}`, invitationId).toPromise()
  }

  async cancelPartnerInvitation(invitationId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/invitation/partner/cancel/${invitationId}`, invitationId).toPromise()
  }

  async getContacts(): Promise<any[]> {
    return this.http.get<any[]>('/api/partnership/contact/list').toPromise()
  }

  async getPartners(): Promise<any[]> {
    return this.http.get<any[]>('/api/partnership/supplier/list').toPromise()
  }

  async addManagementCompany(company: Company_AddFe): Promise<any> {
    let companyId = await this.http.post<any>(`/api/company/management/add`, company).toPromise()
    return companyId
  }

  async addRequestFolder(requestFolder: RequestFolder_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestFolder/add`, requestFolder).toPromise()
  }

  async getRequestFolders(): Promise<any[]> {
    return this.http.get<any[]>(`/api/requestFolder/list`).toPromise()
  }

  async updateRequestFolder(requestFolder: RequestFolder_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestFolder/update`, requestFolder).toPromise()
  }

  async deleteRequestFolder(requestFolder: RequestFolderFe): Promise<any> {
    return this.http.get<any[]>(`/api/requestFolder/delete/${requestFolder.id}`).toPromise()
  }

  async addRequestGroup(requestGroup: RequestGroup_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/add`, requestGroup).toPromise()
  }

  async updateRequestGroupQuestionnaire(requestGroup: RequestGroup_UpdateFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/update`, requestGroup).toPromise()
  }

  async getRequestGroups(): Promise<any[]> {
    return this.http.get<any[]>(`/api/requestGroup/list`).toPromise()
  }

  async deleteDraftRequestGroup(rgId: string): Promise<any> {
    return this.http.get<any[]>(`/api/requestGroup/deleteDraft/${rgId}`).toPromise()
  }

  async updateRecurrence(requestGroup: RequestGroup_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/recurrence/update`, requestGroup).toPromise()
  }

  async updateReminder(requestGroup: RequestGroup_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/reminder/update`, requestGroup).toPromise()
  }

  async remindNow(requestGroup: RequestGroup_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/remindnow`, requestGroup).toPromise()
  }

  async stopRecurrence(requestGroup: RequestGroup_AddFe): Promise<any> {
    return this.http.post<any>(`/api/requestGroup/recurrence/stop`, requestGroup).toPromise()
  }

  async getAllRequests(): Promise<any[]> {
    return this.http.get<any[]>(`/api/request/list`).toPromise()
  }

  async getAllRequestActivities(): Promise<any[]> {
    return this.http.get<any[]>(`/api/requestActivity/list`).toPromise()
  }

  async submitRequestMessage(activity: SubmitMessageActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/request/submitMessage', activity).toPromise()
  }

  async closeRequest(activity: CloseRequestActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/request/close', activity).toPromise()
  }

  async acceptQuestionnaire(activity: AcceptSubmissionActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/questionnaire/answer/accept', activity).toPromise()
  }

  async rejectQuestionnaire(activity: RejectSubmissionActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/questionnaire/answer/reject', activity).toPromise()
  }

  async markAllRequestActivitiesRead(requestId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/requestActivity/markRead/${requestId}`, null).toPromise()
  }

  async getTasks(): Promise<any[]> {
    return this.http.get<any[]>(`/api/task/list`).toPromise()
  }

  async getAllTaskActivities(): Promise<any[]> {
    return this.http.get<any[]>(`/api/taskActivity/list`).toPromise()
  }

  async markAllTaskActivitiesRead(managementCompanyId: string, taskId: string): Promise<boolean> {
    return this.http.post<boolean>(`/api/taskActivity/markRead/${managementCompanyId}/${taskId}`, null).toPromise()
  }

  async submitTaskMessage(activity: SubmitMessageActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/task/submitMessage', activity).toPromise()
  }

  async submitAnswers(activity: SubmitAnswersActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/task/submitAnswers', activity).toPromise()
  }

  async updateDraftAnswer(activity: SubmitAnswersActivity_AddFe): Promise<boolean> {
    return this.http.post<boolean>('/api/task/updateDraft', activity).toPromise()
  }

  async generateInvitation(): Promise<InvitationLinkFe> {
    let link = await this.http.get<any>(`/api/invitation/generateurl`).toPromise()
    return link
  }

  async listAllFiles(comapnyId: string): Promise<any[]> {
    return this.http.get<any>(`/api/file/list/${comapnyId}`).toPromise()
  }

  async uploadExplorerFile(comapnyId: string, selectedFile: any): Promise<any> {
    let uploaded = await this.http.post<any>(`/api/file/explorer/upload/${comapnyId}`, selectedFile).toPromise()
    return uploaded
  }

  async uploadAttachmentFile(comapnyId: string, selectedFile: any): Promise<any> {
    let uploaded = await this.http.post<any>(`/api/file/attachment/upload/${comapnyId}`, selectedFile).toPromise()
    return uploaded
  }

  async uploadDataTableFile(comapnyId: string, selectedFile: any): Promise<any> {
    let uploaded = await this.http.post<any>(`/api/file/data-table/upload/${comapnyId}`, selectedFile).toPromise()
    return uploaded
  }

  downloadExplorerFile(fileName: string): Observable<Blob> {
    return this.http.get<any>(`/api/file/explorer/download/${this.workspaceId}/${fileName}`, {
      responseType: 'blob' as 'json'
    })
  }

  async downloadDataTableFile(companyId: string, fileName: string): Promise<Blob> {
    let file = await this.http
      .get<any>(`/api/file/data-table/download/${companyId}/${fileName}`, { responseType: 'blob' as 'json' })
      .toPromise()
    return file
  }

  async downloadAttachmentFile(companyId: string, fileName: string): Promise<Blob> {
    return await this.http
      .get<any>(`/api/file/attachment/download/${companyId}/${fileName}`, { responseType: 'blob' as 'json' })
      .toPromise()
  }

  async deleteExplorerFile(fileName: string): Promise<any> {
    return await this.http.delete<any>(`/api/file/explorer/delete/${this.workspaceId}/${fileName}`).toPromise()
  }

  async downloadLogoFile(companyId: string, fileName: string): Promise<Blob> {
    return await this.http
      .get<any>(`/api/file/logo/download/${companyId}/${fileName}`, { responseType: 'blob' as 'json' })
      .toPromise()
  }

  async listAutomatedFeedInfos(): Promise<any[]> {
    let stageTableInfos = await this.http.get<any>(`/api/automatedFeedInfo/list`).toPromise()
    return stageTableInfos
  }

  async deleteAutomatedFeedInfo(info: AutomatedFeedInfoFe): Promise<boolean> {
    let deleted: boolean = await this.http.get<any>(`/api/automatedFeedInfo/delete/${info.id}`).toPromise()
    return deleted
  }

  async readWholeAutomatedFeedTable(automatedFeedId: string): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http.get<any>(`/api/automatedFeedTable/read/${automatedFeedId}`).toPromise()
    return tableData
  }

  async readAutomatedFeedTable(automatedFeedId: string, pageSize: number, pageNumber: number): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http
      .get<any>(`/api/automatedFeedTable/read/${automatedFeedId}/${pageSize}/${pageNumber}`)
      .toPromise()
    return tableData
  }

  async connecAutomatedFeedTableToPipeline(id: string): Promise<boolean> {
    let done = await this.http.get<any>(`/api/automatedFeedTable/connect/${id}`).toPromise()
    return done
  }

  async disconnectAutomatedFeedTableFromPipeline(id: string): Promise<boolean> {
    let done = await this.http.get<any>(`/api/automatedFeedTable/disconnect/${id}`).toPromise()
    return done
  }

  async updateAutomatedFeedTable(stageTable: TableDataFe, stageTableInfoId: String): Promise<boolean | any> {
    let done = await this.http.post<any>(`/api/stagetable/update/${stageTableInfoId}`, stageTable).toPromise()
    return done
  }

  async listStageTableInfos(): Promise<any[]> {
    let stageTableInfos = await this.http.get<any>(`/api/directEntryInfo/list`).toPromise()
    return stageTableInfos
  }

  async initStageDataEntry(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let done: boolean = await this.http.post<any>(`/api/directEntryInfo/init`, stageTableInfo).toPromise()
    return done
  }

  async copyStageDataEntry(srcStageTableId: string, destStageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let done: boolean = await this.http
      .post<any>(`/api/directEntryInfo/copy/${srcStageTableId}`, destStageTableInfo)
      .toPromise()
    return done
  }

  async updateStageTableInfo(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let updated: boolean = await this.http.post<any>(`/api/directEntryInfo/update`, stageTableInfo).toPromise()
    return updated
  }

  async deleteStageDataEntry(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let deleted: boolean = await this.http.get<any>(`/api/directEntryInfo/delete/${stageTableInfo.id}`).toPromise()
    return deleted
  }

  async readWholeMasterTable(entityKey: string): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http.get<any>(`/api/mastertable/read/${entityKey}`).toPromise()
    return tableData
  }

  async readMasterTable(
    entityKey: string,
    pageSize: number,
    pageNo: number,
    rowIdsThatItShouldHave: string[] = []
  ): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http
      .post<any>(`/api/mastertable/read/${entityKey}/${pageSize}/${pageNo}`, { rowIdsThatItShouldHave })
      .toPromise()
    return tableData
  }

  async getMasterTableRowsForKpiRow(kpiDefId: string, kpiId: string, kpiRowId: string): Promise<TableDataFe> {
    let tableData = await this.http
      .get<TableDataFe>(`/api/mastertable/readForKpi/${kpiDefId}/${kpiId}/${kpiRowId}`)
      .toPromise()
    return tableData
  }

  async getMasterTableRowsForInsightKpiRow(
    insightDefId: string,
    kpiId: string,
    kpiRowId: string
  ): Promise<TableDataFe[]> {
    let tableData = await this.http
      .get<TableDataFe[]>(`/api/mastertable/readForInsightKpi/${insightDefId}/${kpiId}/${kpiRowId}`)
      .toPromise()
    return tableData
  }

  async readWholeStageTable(stageTableId: string): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http.get<any>(`/api/stagetable/read/${stageTableId}`).toPromise()
    return tableData
  }

  async readStageTable(stageTableId: string, pageSize: number, pageNumber: number): Promise<TableDataFe> {
    let tableData: TableDataFe = await this.http
      .get<any>(`/api/stagetable/read/${stageTableId}/${pageSize}/${pageNumber}`)
      .toPromise()
    return tableData
  }

  async connecStageTableToPipeline(stageTableId: string): Promise<boolean> {
    let done = await this.http.get<any>(`/api/stagetable/connect/${stageTableId}`).toPromise()
    return done
  }

  async disconnectStageTableFromPipeline(stageTableId: string): Promise<boolean> {
    let done = await this.http.get<any>(`/api/stagetable/disconnect/${stageTableId}`).toPromise()
    return done
  }

  async updateStageTable(stageTable: TableDataFe, stageTableInfoId: String): Promise<boolean | any> {
    let done = await this.http.post<any>(`/api/stagetable/update/${stageTableInfoId}`, stageTable).toPromise()
    return done
  }

  async updateMasterTable(masterTable: TableDataFe): Promise<boolean | any> {
    let done = await this.http.post<any>(`/api/mastertable/update`, masterTable).toPromise()
    return done
  }

  async updateReadActivities(): Promise<any> {
    let companies = await this.http.get<any>(`/api/workspace/update`).toPromise()
    return companies
  }

  async connectRequestTableToPipeline(activity: any): Promise<boolean> {
    let done = await this.http.post<any>(`/api/requestActivity/connect`, activity).toPromise()
    return done
  }

  async disconnectRequestTableFromPipeline(activity: any): Promise<boolean> {
    let done = await this.http.post<any>(`/api/requestActivity/disconnect`, activity).toPromise()
    return done
  }

  async getEmails(): Promise<any> {
    let emails = await this.http.get<any>(`/api/email/list`).toPromise()
    return emails
  }

  async getDistinctColumnValues(colName: string, tableName: string): Promise<any> {
    let value = await this.http.get<any>(`/api/table/columnValues/${colName}/${tableName}`).toPromise()
    return value
  }

  async getManagementCompanyById(id: string): Promise<any> {
    let companies = await this.http.get<any>(`/api/company/management/get/${id}`).toPromise()
    return companies
  }

  async getAllManagementCompanies(): Promise<any> {
    let companies = await this.http.get<any>(`/api/admin/company/list`).toPromise()
    return companies
  }

  async getAllContacts(): Promise<any> {
    let contacts = await this.http.get<any>(`/api/admin/contacts/list`).toPromise()
    return contacts
  }

  async getAllManagers(): Promise<any> {
    let managers = await this.http.get<any>(`/api/admin/managers/list`).toPromise()
    return managers
  }

  async getAllAdmins(): Promise<any> {
    let managers = await this.http.get<any>(`/api/admin/list`).toPromise()
    return managers
  }

  async getAllCustomerSuccess(): Promise<any> {
    let managers = await this.http.get<any>(`/api/admin/cs/list`).toPromise()
    return managers
  }

  async getAllContactInvitations(): Promise<any> {
    let invitations = await this.http.get<any>(`/api/admin/contactInvitation/list`).toPromise()
    return invitations
  }

  async getAllManagerInvitations(): Promise<any> {
    let invitations = await this.http.get<any>(`/api/admin/managerInvitation/list`).toPromise()
    return invitations
  }

  async loginAs(workspaceId: string, workspaceRole: string, affiliationId: string): Promise<boolean> {
    let changed = await this.http
      .get<any>(`/api/admin/loginAs/${workspaceId}/${workspaceRole}/${affiliationId}`)
      .toPromise()
    if (changed) {
      this.setWorkspaceId(workspaceId)
      return true
    } else {
      return false
    }
  }

  async deactivateAff(affiliationId: string): Promise<any> {
    return this.http.post<any>(`/api/admin/deactivateAff/`, { affiliationId }).toPromise()
  }

  async deactivateAllCSAffs(userId: string): Promise<any> {
    return this.http.post<any>(`/api/admin/deactivateCSAffs/`, { userId }).toPromise()
  }

  async deactivateAllCSAff(affiliationId: string): Promise<any> {
    return this.http.post<any>(`/api/admin/deactivateCSAffs/`, { affiliationId }).toPromise()
  }

  async setMainManager(affiliationId: string, isMainManager: boolean): Promise<any> {
    return this.http.post<any>(`/api/admin/mainManager/${isMainManager}`, { affiliationId }).toPromise()
  }

  async updateCsSignInSettings(signInSettings: SignInMethodUpdateFe): Promise<any> {
    return this.http.post<any>(`/api/admin/updateSignInSettings/cs`, signInSettings).toPromise()
  }

  async updateAdminSignInSettings(signInSettings: SignInMethodUpdateFe): Promise<any> {
    return this.http.post<any>(`/api/admin/updateSignInSettings/admin`, signInSettings).toPromise()
  }

  async updateAffSignInSettings(signInSettings: SignInMethodUpdateFe): Promise<any> {
    return this.http.post<any>(`/api/admin/updateSignInSettings/aff`, signInSettings).toPromise()
  }

  async updateCompanySignInSettings(signInSettings: SignInMethodUpdateFe): Promise<any> {
    return this.http.post<any>(`/api/admin/updateSignInSettings/company`, signInSettings).toPromise()
  }

  async addAdmin(admin: Admin_AddFe): Promise<string> {
    return this.http.post<any>(`/api/admin/add/`, admin).toPromise()
  }

  async addCS(cs: CustomerSuccess_AddFe): Promise<string> {
    return this.http.post<any>(`/api/admin/cs/add/`, cs).toPromise()
  }

  async updateCS(cs: CustomerSuccess_UpdateFe): Promise<string> {
    return this.http.post<any>(`/api/admin/cs/update/`, cs).toPromise()
  }

  async copyTaxonomyDataToNewCompany(newCompanyId: string, companyId: string): Promise<void> {
    return this.http.post<any>(`/api/admin/copy/taxonomy`, { newCompanyId, companyId }).toPromise()
  }

  async copyKPIsToNewCompany(newCompanyId: string, companyId: string): Promise<void> {
    return this.http.post<any>(`/api/admin/copy/kpis`, { newCompanyId, companyId }).toPromise()
  }

  async copyDashboardsToNewCompany(newCompanyId: string, companyId: string): Promise<void> {
    return this.http.post<any>(`/api/admin/copy/dashboards`, { newCompanyId, companyId }).toPromise()
  }

  async copyDraftRequestsToNewCompany(newCompanyId: string, companyId: string): Promise<void> {
    return this.http.post<any>(`/api/admin/copy/draftRequests`, { newCompanyId, companyId }).toPromise()
  }

  async updateRecentlyOpenedItems(items: RecentlyOpenedItemFe[]) {
    return this.http.post<any>(`/api/company/update/recentlyOpened`, { items }).toPromise()
  }

  async fetchRecentlyOpenedItems(): Promise<any> {
    return this.http.get<any[]>(`/api/company/get/recentlyOpened`).toPromise()
  }

  async getUnits(companyId): Promise<any> {
    return this.http.get<any[]>(`/api/units/list/${companyId}`).toPromise()
  }

  async addUnit(unit: UnitFe): Promise<any> {
    return this.http.post<any>(`/api/units/add`, unit).toPromise()
  }

  async deleteUnit(unit: UnitFe): Promise<any> {
    const encodedSymbol = encodeURIComponent(unit.symbol)
    return this.http.post<any>(`/api/units/delete/${encodedSymbol}`, unit).toPromise()
  }

  async editUnit({ unit, oldUnitSymbol }): Promise<any> {
    const encodedSymbol = encodeURIComponent(oldUnitSymbol)
    return this.http.post<any>(`/api/units/edit/${encodedSymbol}`, unit).toPromise()
  }

  async getGlobalEmissionFactors(): Promise<any> {
    return this.http.post<any[]>(`/api/emission/list`, {}).toPromise()
  }

  async getGlobalDatabases(): Promise<any> {
    return this.http.post<any[]>(`/api/emission/database/list`, {}).toPromise()
  }

  async getCustomEmissionFactors(companyId: string): Promise<any> {
    return this.http.post<any[]>(`/api/emission/list/${companyId}`, {}).toPromise()
  }

  async addEmissionFactor(ef: CustomEmissionFactorFe): Promise<any> {
    return this.http.post<any[]>(`/api/emission/add`, ef).toPromise()
  }

  async getProjectInfos(): Promise<any[]> {
    return this.http.get<any[]>(`/api/project/list`).toPromise()
  }

  async addCSRDProjectInfo(project: AbstractProjectInfoAddFe): Promise<any[]> {
    return this.http.post<any[]>(`/api/project/csrd/add`, project).toPromise()
  }

  async addCCProjectInfo(project: AbstractProjectInfoAddFe): Promise<any[]> {
    return this.http.post<any[]>(`/api/project/cc/add`, project).toPromise()
  }

  async deleteProject(id): Promise<any> {
    return this.http.delete<any>(`/api/project/delete/${id}`).toPromise()
  }

  async updateCsrdProjectInfo(project: AbstractProjectInfoFe): Promise<any> {
    return this.http.post<any>(`/api/project/csrd/update`, project).toPromise()
  }
}
