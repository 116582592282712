export class StringUtilFe {
  public static escapeCharacters(str: any) {
    let newStr = str.replace(/\\"/g, '/"').replace(/\\'/g, "/'")
    return newStr
  }

  public static convertStringToObj(jsonString: string): any {
    jsonString = this.parseString(jsonString)
    let parsedObject = JSON.parse(jsonString)
    return parsedObject
  }

  public static parseString(jsonString: string): string {
    let escapedJSONString = jsonString
      .replace(/\n/g, '\\n')
      .replace(/\/"/g, '\\"')
      .replace(/\r/g, '\\r')
      .replace(/\t/g, '\\t')
    return escapedJSONString
  }

  public static trimEmail(email: string): string {
    let em = email.trim().toLowerCase()
    return em
  }
}
