import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { LoginServiceFe } from './LoginServiceFe'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardFe implements CanActivate {
  constructor(private loginService: LoginServiceFe) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.loginService.checkLogin()
    return true
  }
}
