<div class="card-body dataExtractor">
  <div #screen *ngIf="!inProgress && isMainViewer()">
    <ngx-extended-pdf-viewer
      [src]="pdfSrc"
      [useBrowserLocale]="true"
      height="100%"
      [showHandToolButton]="true"
      [textLayer]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      *ngIf="pdfBlob"
    ></ngx-extended-pdf-viewer>
    <div class="img-container overflow-auto text-center p-1" style="max-height: unset" *ngIf="imgBlob">
      <img class="basic-cropper main-demo-image" [src]="imgSrc" alt="Picture" style="max-width: 100%" />
    </div>
  </div>
  <div *ngIf="!inProgress && isCropperViewer()" style="position: relative">
    <image-cropper
      format="png"
      backgroundColor="#405369"
      class="p-0"
      [resizeToWidth]="700"
      [resizeToHeight]="500"
      [onlyScaleDown]="true"
      [maintainAspectRatio]="false"
      [cropper]="initialCropperSize"
      (imageLoaded)="cropperLoaded()"
      [imageFile]="imgFileToBeCropped"
      (imageCropped)="setCroppedImage($event)"
      [alignImage]="'center'"
    ></image-cropper>
    <div class="action-buttons">
      <button
        type="button"
        class="btn btn-add mr-1 mb-25 white custom-button d-inline"
        (click)="imgExtractor.setMainViewer()"
      >
        Back
      </button>
      <button
        type="button"
        class="btn btn-add mr-1 mb-25 white custom-button d-inline"
        (click)="convertCroppedToText()"
      >
        Crop
      </button>
    </div>
  </div>
  <div *ngIf="!inProgress && isExtractorViewer()" style="position: relative">
    <div [ngStyle]="{ visibility: isLoading ? 'hidden' : 'visible' }">
      <div class="app-wrapper" id="app-wrapper">
        <div
          #contentWrapper
          class="content-wrapper p-0"
          [ngClass]="{ grabbing: isDragging, draggable: isDraggable && !isDragging && currentCol != 0 }"
        >
          <a
            [id]="'addBtn' + i"
            (click)="addNewColumn()"
            class="addBtn"
            data-bs-toggle="tooltip"
            data-placement="right"
            title="Add column to the right"
            container="body"
            containerClass="tooltipClass"
            *ngFor="let col of this.cols; let i = index"
          >
            <i class="las la-plus-square" style="font-size: 1.5rem; color: white; background-color: #405369"></i>
          </a>
          <ng-container *ngFor="let col of this.cols; let i = index">
            <a
              [id]="'delBtn' + i"
              (click)="delCol()"
              class="delBtn"
              data-bs-toggle="tooltip"
              data-placement="bottom"
              title="Delete this column"
              container="body"
              containerClass="tooltipClass"
              *ngIf="this.cols.length != 1"
            >
              <i class="las la-minus-square" style="font-size: 1.5rem; color: white; background-color: #405369"></i>
            </a>
          </ng-container>
          <!-- <div class="backdrop"></div> -->
          <div class="content">
            <div class="originalImg-overlay"></div>
            <img [src]="screenshot" id="originalImg" />
            <img [src]="croppedImgSrc" id="croppedImg" style="z-index: 3" />
            <i
              class="la la-info-circle dropdown-toggle info-popover-arrow mb-1"
              id="popover"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
            <div class="dropdown-menu arrow p-50 info-popover">
              <p>
                A table with the highlighted columns are automatically detected in the cropped picture. Add, remove or
                adjust the columns if they are not correct before mapping the data.
              </p>
            </div>
          </div>
          <canvas #canvasContent id="canvas" class="canvas-content"></canvas>
        </div>
      </div>
      <div class="action-buttons mt-1 pt-2">
        <button
          type="button"
          class="btn btn-add mr-1 mb-25 white custom-button d-inline"
          (click)="imgExtractor.setCropperViewer()"
        >
          Back
        </button>
        <button type="button" class="btn btn-add mr-1 mb-25 white custom-button d-inline" (click)="mapToTaxonomy()">
          Map
        </button>
      </div>
    </div>
    <div class="loader-primary" *ngIf="isLoading" style="color: #6b6f82">
      <div>reading and detecting the data</div>
      <div class="ball-pulse">
        <div style="background-color: #6b6f82"></div>
        <div style="background-color: #6b6f82"></div>
        <div style="background-color: #6b6f82"></div>
      </div>
    </div>
  </div>
  <div *ngIf="inProgress">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
