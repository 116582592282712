import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { VisitorServiceFe } from './VisitorServiceFe'

@Injectable({
  providedIn: 'root'
})
export class VisitorAuthGuardFe implements CanActivate {
  constructor(private visitorService: VisitorServiceFe) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.visitorService.checkValidity()
    return true
  }
}
