import { DateUtil } from 'src/app/utils/DateUtil'
import { AbstractSignInMethodFe } from '../signInMethod/AbstractSignInMethodFe'
import { SignInMethodConversionFe } from '../signInMethod/SignInMethodConversionFe'
import { AffiliationRoleFe } from '../user/AffiliationRoleFe'
import { RecentlyOpenedItemFe } from '../datahub/RecentlyOpenedItemFe'

export class AccessibleCompanyFe {
  userId: string
  companyId: string
  companyName: string
  companyType: string
  affiliationId: string | null
  affiliationRole = AffiliationRoleFe.SUSTAINABILITY_MANAGER
  noOfUnreadActivities: number = 0
  noOfUpcomingDeadlines: number = 0
  noOfToReviewRequests: number = 0
  signInMethods: AbstractSignInMethodFe[]
  mfaEnabled: boolean

  recentlyOpenedItems: RecentlyOpenedItemFe[] = []
  lastLoggedAt: Date | null
  accessReceivedAt: Date | null

  constructor(
    userId: string,
    companyId: string,
    companyName: string,
    companyType: string,
    affiliationId: string | null,
    affiliationRole: string | null,
    noOfUnreadActivities: number,
    noOfUpcomingDeadlines: number,
    noOfToReviewRequests: number,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    this.userId = userId
    this.companyId = companyId
    this.companyName = companyName
    this.companyType = companyType
    this.affiliationId = affiliationId
    this.affiliationRole = affiliationRole
    this.noOfUnreadActivities = noOfUnreadActivities
    this.noOfUpcomingDeadlines = noOfUpcomingDeadlines
    this.noOfToReviewRequests = noOfToReviewRequests
    this.signInMethods = signInMethods
    this.mfaEnabled = mfaEnabled
  }

  public static fromTransfer(transfer: any): AccessibleCompanyFe {
    let signInMethods = transfer['signInMethods']?.map((signInMethod) =>
      SignInMethodConversionFe.fromTransfer(signInMethod)
    )
    let recentlyOpenedItems = transfer.recentlyOpenedItems
      ? transfer.recentlyOpenedItems.map((item) => RecentlyOpenedItemFe.fromTransfer(item))
      : []
    let company = new AccessibleCompanyFe(
      transfer.userId,
      transfer.companyId,
      transfer.companyName,
      transfer.companyType,
      transfer.affiliationId,
      transfer.affiliationRole,
      transfer.noOfUnreadActivities,
      transfer.noOfUpcomingDeadlines,
      transfer.noOfToReviewRequests,
      transfer['signInMethods'],
      transfer.mfaEnabled
    )
    return company
  }

  getAccessReceivedAt() {
    let date = new Date(this.accessReceivedAt)
    return DateUtil.toString3(date)
  }

  requireGoogleLogin() {}

  requireMicrosoftLogin() {}
}

export const ADMIN_WORKSPACE = new AccessibleCompanyFe(
  'ADMIN',
  'ADMIN',
  'Admin',
  'ADMIN',
  'ADMIN',
  'ADMIN',
  0,
  0,
  0,
  [],
  false
)
