<div class="unit_selector">
  <ng-container *ngIf="mode == 'filter' && filterBy == 'symbol'">
    <div class="dropdown symbolFilterDropdown">
      <div class="symbolSelector" data-bs-toggle="dropdown" data-bs-auto-close="outside">
        <div class="search">
          <i class="search_icon las la-search"></i>
          <input
            [(ngModel)]="symbolSelectorInput"
            type="text"
            placeholder="{{ locale('locale_key.pages.unit-selector.search') }}"
            (keyup)="filterUnitsByMeasurementTypeForSymbolSelect()"
          />
          <i
            *ngIf="enableClearFilteredSymbol()"
            class="clearSelectedUnit_icon las la-times"
            (click)="clearFilteredSymbol()"
          ></i>
        </div>
      </div>
      <div class="dropdown-menu unit-selector-dropdown-menu">
        <div class="symbolSelector">
          <div class="units_list">
            <div class="measurementType" *ngIf="tracked.filteredCustomUnits.length > 0">
              <div class="heading">{{ locale('locale_key.pages.unit-selector.custom') }}</div>
              <div class="unitSystem">
                <div class="heading">{{ locale('locale_key.general.all') }}</div>
                <div class="units">
                  <ng-container *ngFor="let unit of tracked.filteredCustomUnits">
                    <div class="unit">
                      <div class="name" (click)="selectUnit(unit)">
                        <span class="label">{{ unit?.name }}</span>
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                      <ng-container *ngTemplateOutlet="prefixesTemplate; context: { unit: unit }"></ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="measurementType" *ngFor="let measurementType of tracked.filteredUnitsByMeasurementType">
              <div class="heading">{{ measurementType.name }}</div>
              <div class="unitSystem" *ngFor="let system of measurementType.systems">
                <div class="heading">
                  {{ system.name }}
                </div>
                <div class="units">
                  <ng-container *ngFor="let unit of system.units">
                    <div class="unit">
                      <div class="name" (click)="selectUnit(unit)">
                        <span class="label">{{ unit?.name }}</span>
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                      <ng-container *ngTemplateOutlet="prefixesTemplate; context: { unit: unit }"></ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="create_custom_unit_cta">
            {{ locale('locale_key.pages.unit-selector.not_found') }},
            <span class="cta" (click)="startUnitEditor()">
              {{ locale('locale_key.pages.unit-editor.create_custom_unit') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <unit-editor #unitEdtiorModalForMeasurementTypeFilter></unit-editor>
  </ng-container>

  <ng-container *ngIf="mode == 'filter' && filterBy == 'system'">
    <div class="dropdown systemFiltersDropdown">
      <div data-bs-toggle="dropdown" data-bs-auto-close="outside">
        <div class="filter system">
          <i class="filter_icon las la-filter"></i>
          <input type="text" placeholder="{{ locale('locale_key.pages.unit-selector.filter_by_unit_system') }}" />
        </div>
      </div>
      <div class="dropdown-menu unit-selector-dropdown-menu">
        <div class="systemFilters">
          <div class="clear_filter_control" (click)="clearFilter()">
            {{ locale('locale_key.pages.unit-selector.clear_filter') }}
          </div>
          <div class="filter_list">
            <div class="system_filter" *ngFor="let system of getSystems()">
              <input
                type="checkbox"
                [checked]="filter.has(system)"
                (change)="updateFilter({ term: system, evt: $event })"
              />
              {{ getSystemName(system) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="mode == 'filter' && filterBy == 'measurementType'">
    <div class="dropdown measurementTypeFiltersDropdown">
      <div data-bs-toggle="dropdown" data-bs-auto-close="outside">
        <div class="filter measurementType">
          <i class="filter_icon las la-filter"></i>
          <input type="text" placeholder="{{ locale('locale_key.pages.unit-selector.filter_by_measurement_type') }}" />
        </div>
      </div>
      <div class="dropdown-menu unit-selector-dropdown-menu">
        <div class="measurementTypeFilters">
          <div class="filter_list">
            <div class="clear_filter_control" (click)="clearFilter()">
              {{ locale('locale_key.pages.unit-selector.clear_filter') }}
            </div>
            <div class="measurementTypeFilter" *ngFor="let measurementType of getMeasurementTypes()">
              <input
                type="checkbox"
                [checked]="filter.has(measurementType)"
                (change)="updateFilter({ term: measurementType, evt: $event })"
              />
              {{ getMeasureTypeName(measurementType) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <unit-editor #unitEdtiorModalForMeasurementTypeFilter></unit-editor>
  </ng-container>

  <ng-container *ngIf="mode == 'select' && selectBy == 'symbol'">
    <div class="dropdown symbolSelectorDropdown">
      <div data-bs-toggle="dropdown" data-bs-auto-close="outside">
        <div
          [class]="'highlight-dropdown-menu select_cta source_' + source"
          [ngClass]="{ 'highlight-dropdown-menu': isDropdownHighlighted }"
        >
          <ng-container *ngIf="isHeader">
            <span class="label bold">{{ locale('locale_key.add_data_point.unit.title') }}</span>
          </ng-container>
          <ng-container *ngIf="!isHeader">
            <span class="label" *ngIf="isSelectedUnitEmpty()">
              {{ locale('locale_key.pages.unit-selector.select_unit') }}
            </span>

            <span class="label truncate" *ngIf="!isSelectedUnitEmpty()">
              {{ selectedUnitContainer.selectedUnit.name }}
              <span class="symbol">({{ getSymbol(selectedUnitContainer.selectedUnit) }})</span>
            </span>
          </ng-container>
          <span class="caret">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
              <path
                d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                stroke="#6B6E80"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="dropdown-menu unit-selector-dropdown-menu" [ngClass]="source">
        <div class="symbolSelector">
          <div class="search">
            <i class="search_icon las la-search"></i>
            <input
              [(ngModel)]="symbolSelectorInput"
              type="text"
              placeholder="{{ locale('locale_key.pages.units.search_by_unit_name') }}"
              (keyup)="filterUnitsByMeasurementTypeForSymbolSelect()"
            />
            <i class="clearSelectedUnit_icon las la-times" (click)="clearSelectedUnit()"></i>
          </div>
          <div *ngIf="!viewMoreUnits" class="simple_unit_list">
            <ng-container *ngFor="let unit of getCommonUnits()">
              <div class="unit">
                <div class="name" (click)="selectUnit(unit)">
                  <span class="label">{{ unit?.name }}</span>
                  <span class="symbol">({{ getSymbol(unit) }})</span>
                </div>
                <ng-container *ngTemplateOutlet="prefixesTemplate; context: { unit: unit }"></ng-container>
              </div>
            </ng-container>
          </div>
          <div class="view_more_units_cta" [ngClass]="{ hidden: viewMoreUnits }" (click)="enableMoreUnits()">
            {{ locale('locale_key.pages.units.view_mroe_units') }}
          </div>
          <div *ngIf="viewMoreUnits" class="units_list">
            <div class="measurementType" *ngIf="tracked.filteredCustomUnits.length > 0">
              <div class="heading">{{ locale('locale_key.pages.unit-selector.custom') }}</div>
              <div class="unitSystem">
                <div class="heading">{{ locale('locale_key.general.all') }}</div>
                <div class="units">
                  <ng-container *ngFor="let unit of tracked.filteredCustomUnits">
                    <div class="unit">
                      <div class="name" (click)="selectUnit(unit)">
                        <span class="label">{{ unit?.name }}</span>
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                      <ng-container *ngTemplateOutlet="prefixesTemplate; context: { unit: unit }"></ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="measurementType" *ngFor="let measurementType of tracked.filteredUnitsByMeasurementType">
              <div class="heading">{{ measurementType.name }}</div>
              <div class="unitSystem" *ngFor="let system of measurementType.systems">
                <div class="heading">
                  {{ system.name }}
                </div>
                <div class="units">
                  <ng-container *ngFor="let unit of system.units">
                    <div class="unit">
                      <div class="name" (click)="selectUnit(unit)">
                        <span class="label">{{ unit?.name }}</span>
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                      <ng-container *ngTemplateOutlet="prefixesTemplate; context: { unit: unit }"></ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="viewMoreUnits" class="create_custom_unit_cta">
            {{ locale('locale_key.pages.unit-selector.not_found') }},
            <span class="cta" (click)="startUnitEditor()">
              {{ locale('locale_key.pages.unit-editor.create_custom_unit') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <unit-editor #unitEdtiorModalForMeasurementTypeFilter (unitCreated)="selectUnit($event)"></unit-editor>
  </ng-container>

  <ng-container *ngIf="mode == 'select' && selectBy == 'measurementType'">
    <div class="dropdown measurementTypeSelectorDropdown">
      <div data-bs-toggle="dropdown" data-bs-auto-close="outside">
        <div class="select_cta" [class.source_unit_editor_modal]="source == 'unit_editor_modal'">
          <span class="label" *ngIf="isSelectedMeasurementTypeEmpty()">
            {{ locale('locale_key.pages.unit-selector.select_measurement_type') }}
          </span>
          <span class="label" *ngIf="!isSelectedMeasurementTypeEmpty()">
            {{ selectedMeasurementTypeContainer.selectedMeasurementType.name }}
          </span>
          <span class="caret">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
              <path
                d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                stroke="#6B6E80"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="dropdown-menu unit-selector-dropdown-menu">
        <div class="measurementTypeSelector">
          <div class="search">
            <i class="search_icon las la-search"></i>
            <input
              [(ngModel)]="measurementTypeSelectorInput"
              type="text"
              placeholder="{{ locale('locale_key.pages.units.search_by_measurement_type') }}"
              (keyup)="filterMeasurementTypesSelect()"
            />
            <i class="clearSelectedMeasurementType_icon las la-times" (click)="clearSelectedMeasurementType()"></i>
          </div>
          <div class="measurementTypes_list">
            <div
              class="measurementType"
              (click)="selectMeasurementType(measurementType)"
              *ngFor="let measurementType of tracked.filteredMeasurementTypes"
            >
              {{ measurementType.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #prefixesTemplate let-unit="unit">
    <div class="prefixes">
      <div class="btn-group dropright" *ngIf="unit?.prefixList?.length > 0">
        <div class="prefixDropdown" data-bs-toggle="dropdown">
          <i class="caret las la-angle-right"></i>
        </div>
        <div class="dropdown-menu">
          <div class="prefixList">
            <div class="prefixHeader">
              {{ locale('locale_key.pages.units.related_units_to', { unit: unit?.name, symbol: getSymbol(unit) }) }}
            </div>
            <div class="prefixBody">
              <ng-container *ngFor="let prefix of unit?.prefixList">
                <div class="prefix" (click)="selectUnit(prefix)">
                  {{ prefix.name }} (
                  <b>{{ getSymbol(prefix) }}</b>
                  )
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
