import { QUESTION_TYPE } from '../../request/questionnaire/QuestionTypeFe'
import { AnswerFe } from './AnswerFe'

export class AnswerTextFe extends AnswerFe {
  text: string

  constructor(id: string, sectionId: string, questionId: string, comments: string | null, text: string) {
    super(id, sectionId, questionId, QUESTION_TYPE.TEXT, comments)
    this.text = text
  }

  public static fromTransfer(transfer: any): AnswerTextFe {
    let answer = new AnswerTextFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.text
    )
    return answer
  }

  public hasAnyValue(): boolean {
    return this.text?.length > 0
  }
}
